import Phaser from 'phaser'
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import Button from '~/components/buttons/Button';
import ProgressBar from '~/components/progressBar/ProgressBar';
import BlobService from '~/services/BlobService';

const grayProgressBarColor = 0xb48dc7;
const progressBarAlpha = 0.4;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0x262637;
const backgroundAlpha = 1;
const radius = 7;

const progressBarHeight = 22;
const levelBarWidth = 492;
const grayTextColor = 0x75758B;
const paneWidth = 566;

export default class UpgradeBlobContentPane extends FixWidthSizer
{
    private _table;
    private _scale;
    private _upgradeOptions;
    private _blobInfo;

	constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any)
	{
        const conf = config ? config : {
            width: paneWidth * paneScale,
            space: {
                left: 32, right: 19, top: 31, bottom: 0,
                item: 0, line: 0
            },
            align: 0,
            sizerEvents: true
        };
		super(scene, x, y, conf);

        this._scale = paneScale;
        this._blobInfo = BlobService.getInstance().getBlobInfoSync();

        this.createComponent(scene);
    }

    createComponent(scene: Phaser.Scene) {
        const blobInfo = BlobService.getInstance().getBlobInfoSync();
        this._upgradeOptions= [
            {
                key: 'defense_per_hour',
                titleText: 'Defense Per Hour',
                description: 'Your Blob will add to your defensive HP during an attack on your Homebase.',
                iconImageKey: 'lock_icon',
                progressMin: 0,
                progressMax: 500,
                progressValue: blobInfo.defense_per_hour,
                isAddDisabled: blobInfo.unapplied_points === 0,
                isRemoveDisabled: blobInfo.defense_per_hour_points === 0
            },
            {
                key: 'machine_multiplier',
                titleText: 'Machine Multiplier',
                description: 'Your Blob will use its secret, mysterious powers to boost the HP of all of your Homebase\'s machines.',
                iconImageKey: 'battery_icon',
                progressMin: 1,
                progressMax: 2,
                progressValue: blobInfo.machine_multiplier,
                isAddDisabled: blobInfo.unapplied_points === 0,
                isRemoveDisabled: blobInfo.machine_multiplier_points === 0
            }
        ];

        this.add(this.createTable(scene, this._upgradeOptions, 0, 0, this._scale).layout(), {
            padding: { left: 0, right: 0, top: 0, bottom: 0},
            key: `options_table`
        });


        this.layout();
    }

    createTable(scene, data, x, y, paneScale) {
        var table = scene.rexUI.add.gridTable({
            x: x,
            y: y,
            width: scene.applyScale(paneWidth - 51),
            height: scene.applyScale(552),
            items: data,

            table: {
              cellWidth: scene.applyScale(paneWidth - 51),
              cellHeight: scene.applyScale(276),
              columns: 1,
              clamplTableOXY: false,
              reuseCellContainer: true,
              scrollMode: 0,
              mask: {
                padding: 0,
              }
            },

            space: {

            },

            mouseWheelScroller: {
                focus: true,
                speed: 0.2
            },

            createCellContainerCallback: function (cell) {
                var scene = cell.scene,
                    width = cell.width,
                    height = cell.height,
                    item = cell.item,
                    index = cell.index;

                var sizer = scene.rexUI.add.fixWidthSizer({
                    x: 0,
                    y: 0,
                    width: scene.applyScale(paneWidth - 51),
                    align: 0,
                }).setOrigin(0, 0);
        
                // Title Text
                sizer.add(scene.add.bitmapText(0, 0, 'cc_outline', item.titleText.toUpperCase(), scene.applyScale(22), 0).setOrigin(0), {
                    padding: { left: 0, right: 0, top: 0, bottom: 0},
                    key: `${item.key}_title_text`
                }).addNewLine();
                
                // Description
                sizer.add(
                    scene.add.bitmapText(0, 0, 'cc_outline', item.description, scene.applyScale(22), 0)
                        .setOrigin(0)
                        .setMaxWidth(scene.applyScale(paneWidth - 60))
                        .setTint(grayTextColor), 
                    {
                        padding: { left: 0, right: 0, top: scene.applyScale(9), bottom: 0},
                        key: `${item.key}_desc_text`
                    }
                ).addNewLine();
        
                // ProgressBar
                const pointsProgressBar = new ProgressBar(
                    scene,
                    0,
                    0,
                    scene.getScale(),
                    {
                        rtl: false,
                        title: {
                            text: undefined,
                            fontStyle: undefined
                        },
                        icon: {
                            key: item.iconImageKey,
                            space: 8,
                            scale: 1
                        },
                        progressBar: {
                            text: item.progressValue,
                            fontStyle: undefined,
                            width: levelBarWidth,
                            height: progressBarHeight,
                            radius: radius,
                            color: grayProgressBarColor,
                            alpha: progressBarAlpha,
                            backgroundColor: backgroundColor,
                            backgroundAlpha: backgroundAlpha,
                            minValue: item.progressMin,
                            maxValue: item.progressMax,
                            progressValue: item.progressValue,
                            glare: {
                                height: glareHeight,
                                width: levelBarWidth - glareOffset,
                                radius: glareRadius,
                                color: glareColor,
                                alpha: glareAlpha
                            }
                        }
                    }
                );
                
                sizer.add(scene.add.existing(pointsProgressBar), {
                    padding: { left: 0, right: 0, top: scene.applyScale(16), bottom: 0},
                    key: `${item.key}_desc_text`
                });        
                
                // Buttons
                const addPointsButton = new Button(scene, 0, 0, 'add_points_button', false)
                    .setScale(scene.getScale())
                    .setAlpha(item.isAddDisabled ? 0.6 : 1)
                    .setDisabled(item.isAddDisabled);
                addPointsButton.onClick().subscribe(pointer => {
                    
                });
        
                sizer.add(scene.add.existing(addPointsButton), {
                    padding: { left: 0, right: scene.applyScale(10), top: scene.applyScale(20), bottom: 0},
                    key: `${item.key}_add_points_button`
                });
                
                const removePointsButton = new Button(scene, 0, 0, 'remove_points_button', false)
                    .setScale(scene.getScale())
                    .setAlpha(item.isRemoveDisabled ? 0.6 : 1)
                    .setDisabled(item.isRemoveDisabled);
                removePointsButton.onClick().subscribe(pointer => {
                
                });
        
                sizer.add(scene.add.existing(removePointsButton), {
                    padding: { left: 0, right: 0, top: scene.applyScale(20), bottom: 0},
                    key: `${item.key}_remove_points_button`
                });

                if (index < data.length - 1) {
                    sizer.add(scene.add.image(0, 0, 'blob_option_divider_line'), {
                        padding: { left: 0, right: 0, top: scene.applyScale(30), bottom: 0},
                        key: `${item.key}_divider`
                    });
                }
        
                return sizer.layout();
            }
        });

        table.setItems(data).scrollToTop();

        this._table = table;
        return table;
    }

    applyScale(length) {
        return length * this._scale;
    }
}

Phaser.GameObjects.GameObjectFactory.register('upgradeBlobContentPane', function (x: number, y: number) {
	// @ts-ignore
    return this.displayList.add(new UpgradeBlobContentPane(this.scene, x, y));
});