import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { Base_Raid_Assets } from '~/utils/AssetLoader';

const modalWidth = 494;
const modalHeight = 400;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const okayButtonImageKey = 'okay_button';

export default class MessageScene extends Phaser.Scene {
  private _message;
  private _title;
  private _launchedFromScene;
  private _scale;

  init(data) {
    this._message = data.message;
    this._title = data.title;
    this._launchedFromScene = data.scene;
  }

  constructor() {
    super({
      key: 'MessageScene',
    });
  }

  preload() {
    this.load.image('okay_button', Base_Raid_Assets.okay_button);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100); // 100 extra to account for button

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add background to sizer
    sizer.addBackground(modalBg);

    // Add Title
    const title = this.add.bitmapText(0, 0, 'cc_outline', this._title, this.applyScale(26), 1).setOrigin(0.5, 0.5);
    title.setMaxWidth(350);
    sizer.add(title, {
      padding: { left: this.applyScale(70), right: this.applyScale(70), top: this.applyScale(50), bottom: this.applyScale(0) },
      key: 'message',
    });

    // Add Blob
    sizer.add(this.add.sprite(0, 0, 'party_blob').setOrigin(0.5, 0.5), {
      padding: { left: this.applyScale(100), right: this.applyScale(100), top: this.applyScale(-40), bottom: this.applyScale(20) },
      key: 'blob_image',
    });

    // Add Message
    const message = this.add.bitmapText(0, 0, 'cc_outline', this._message, this.applyScale(22), 1).setOrigin(0.5, 0.5);
    message.setMaxWidth(350);
    sizer.add(message, {
      padding: { left: 0, right: 0, top: 0, bottom: this.applyScale(60) },
      key: 'message',
    });

    sizer.layout();

    // Add Okay Button
    const okayButton = new Button(this, modalBg.getTopCenter().x, modalBg.getBottomLeft().y, okayButtonImageKey, false)
      .setScale(this._scale)
      .setOrigin(0.5, 0.5);

    okayButton.onClick().subscribe(pointer => {
      this.scene.resume(this._launchedFromScene);
      this.scene.stop();
    });

    // Add key bindings
    this.input.keyboard.on('keydown-ENTER', event => {
      this.scene.resume(this._launchedFromScene);
      this.scene.stop();
    });

    this.add.existing(okayButton);
  }

  private applyScale(length) {
    return length * this._scale;
  }
}
