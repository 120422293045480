import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

// Pane Details
const paneHeight = 632;
const paneWidth = 259;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

// Feed Details
const feedHeight = 580;

// Table Item Details
const itemWidth = 239,
  itemHeight = 60,
  itemRadius = 13,
  itemColor = 0x262637,
  itemAlpha = 1,
  itemGlareHeight = 5,
  itemGlareRadius = 4,
  itemGlareColor = 0xffffff,
  itemGlareAlpha = 0.08;

export default class BattleRoyaleFeedPane extends OverlapSizer {
  private _scale: number;

  // UI Elements
  private _background;
  private _battleFeedPane;
  private _title;
  private _feedTable;
  private _feedData;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._feedData = data;
    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    this._background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(this._background, {
      key: 'background',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Info Panel
    this._battleFeedPane = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Title
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Kill Feed', scene.applyScale(22), 0);

    this._battleFeedPane.add(this._title, {
      key: `details-title`,
      align: 'center-top',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(20),
      expand: false,
    });

    this.add(this._battleFeedPane, {
      key: 'info-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._feedTable = this.createFeedTable(this.scene, this._feedData, x, y, this.scale).layout();
    this._battleFeedPane.add(this._feedTable, {
      key: 'feed-table',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(60),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private createFeedTable(scene, data, x, y, scale) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(paneWidth),
        height: scene.applyScale(feedHeight - 20),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(itemWidth),
          cellHeight: this.applyScale(itemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            message = cell.item;

          const player1 = message.player1;
          const player2 = message.player2;

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(10),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(itemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alpha
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )
            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth - 15), // width
                scene.applyScale(itemGlareHeight), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'center-top',
                offsetY: scene.applyScale(7),
                expand: false,
              },
            )

            .add(
              scene.add
                .bitmapText(0, 0, 'cc_outline', message.feed, scene.applyScale(16), 1)
                .setMaxWidth(scene.applyScale(itemWidth - 15))
                .setCharacterTint(scene.getTintIndex(player1, message.feed, false), player1.length, true, scene.getTintColor(player1))
                .setCharacterTint(scene.getTintIndex(player2, message.feed, false), player2.length, true, scene.getTintColor(player2))
                .setCharacterTint(scene.getTintIndex(player2, message.feed, true), player2.length, true, scene.getTintColor(player2)),
              {
                key: 'item_feed',
                align: 'center-top',
                offsetY: scene.applyScale(20),
                offsetX: scene.applyScale(0),
                expand: false,
              },
            );
          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  public updateFeed(feedData: any) {
    this._feedData = feedData;
    this._feedTable.setItems();
    this._feedTable.setItems(feedData);
    this._feedTable.scrollToBottom();
  }
}

Phaser.GameObjects.GameObjectFactory.register('battleRoyaleFeedPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattleRoyaleFeedPane(this.scene, x, y));
});
