import Phaser, { Scene } from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import StakingService from '~/services/StakingService';

const textStyle = {
  fontFamily: 'ccwhatchamacallitbold',
  fontSize: '16px',
  fontStyle: 'normal',
  color: '#FFFFFF',
  shadow: {
    offsetX: 2,
    offsetY: 2,
    color: '#000001',
  },
};

const paneWidth = 685;
const paneHeight = 108;

const goldProgressBarColor = 0xdea827;
const pinkProgressBarColor = 0xde2795;
const blueProgressBarColor = 0x00a6d3;
const grayProgressBarColor = 0x9a9a9a;
const blackProgressBarColor = 0x23232e;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 0.4;
const progressBarHoverAlpha = 0.8;
const radius = 7;

const progressBarHeight = 22;
const stardustBarWidth = 140;
const neonGoldBarWidth = 215;
const spaceElixirBarWidth = 215;

const stardustMin = 0;
const stardustMax = 5000;
const neonGoldMin = 0;
const neonGoldMax = 250000;
const spaceElixirMin = 0;
const spaceElixirMax = 250000;
const darkMatterMin = 0;
const darkMatterMax = 1000;

const tintColor = 0xcdcdcd;

const resources = {
  space_elixir: 'space_elixir',
  neon_gold: 'neon_gold',
  dark_matter: 'dark_matter',
  stardust: 'stardust',
};

export default class ResourcesComponent extends FixWidthSizer {
  private _userData;
  private _userMachines;
  private _scale;
  private _spaceElixirProgressBar;
  private _neonGoldProgressBar;
  private _darkMatterProgressBar;
  private _stardustProgressBar;
  private _hpProgressBar;
  private _dpsProgressBar;
  private _allowProgressBarClicks;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, userMachines: any, allowClicks: boolean, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: scene.applyScale(250),
          height: scene.applyScale(300),
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 1,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._userData = data;
    this._userMachines = userMachines;
    this._allowProgressBarClicks = allowClicks || false;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    const userMachines = MachineService.getInstance().getUserMachinesSync();

    if (this._spaceElixirProgressBar) {
      this._spaceElixirProgressBar.destroy();
    }

    if (this._neonGoldProgressBar) {
      this._neonGoldProgressBar.destroy();
    }

    if (this._darkMatterProgressBar) {
      this._darkMatterProgressBar.destroy();
    }

    if (this._stardustProgressBar) {
      this._stardustProgressBar.destroy();
    }

    if (this._hpProgressBar) {
      this._hpProgressBar.destroy();
    }

    if (this._dpsProgressBar) {
      this._dpsProgressBar.destroy();
    }

    // ADD PROGRESS BARS

    // Space Elixir Progress Bar
    this._spaceElixirProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      1,
      {
        rtl: true,
        title: {
          text: '',
          fontStyle: textStyle,
        },
        icon: {
          key: 'space_elixir_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: this._userData.space_elixir.toLocaleString('en-us'),
          fontStyle: textStyle,
          width: spaceElixirBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: blueProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: spaceElixirMin,
          maxValue: this.getStorageCapacity(userMachines, resources.space_elixir),
          progressValue: this._userData.space_elixir,
          glare: {
            height: glareHeight,
            width: spaceElixirBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );

    this.add(this.scene.add.existing(this._spaceElixirProgressBar), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'spaceElixirProgressBar',
    });

    // Neon Gold Progress Bar
    this._neonGoldProgressBar = new ProgressBar(this.scene, 0, 0, 1, {
      rtl: true,
      title: {
        text: '',
        fontStyle: textStyle,
      },
      icon: {
        key: 'neon_gold_icon',
        space: 8,
        scale: 1,
      },
      progressBar: {
        text: this._userData.neon_gold.toLocaleString('en-us'),
        fontStyle: textStyle,
        width: neonGoldBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: goldProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: neonGoldMin,
        maxValue: this.getStorageCapacity(userMachines, resources.neon_gold),
        progressValue: this._userData.neon_gold,
        glare: {
          height: glareHeight,
          width: neonGoldBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    this.add(this.scene.add.existing(this._neonGoldProgressBar), {
      padding: { left: 0, right: 0, top: scene.applyScale(15), bottom: 0 },
      key: 'neonGoldProgressBar',
    });

    // Stardust Progress Bar
    this._stardustProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      1,
      {
        rtl: true,
        title: {
          text: '',
          fontStyle: textStyle,
        },
        icon: {
          key: 'stardust_icon_44',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: Math.floor(this._userData.stardust).toLocaleString('en-us'),
          fontStyle: textStyle,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: pinkProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: stardustMin,
          maxValue: stardustMax,
          progressValue: this._userData.stardust,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );

    this.add(this.scene.add.existing(this._stardustProgressBar), {
      padding: { left: 0, right: 0, top: scene.applyScale(15), bottom: 0 },
      key: 'stardustProgressBar',
    });

    // Dark Matter Progress Bar
    this._darkMatterProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      1,
      {
        rtl: true,
        title: {
          text: '',
          fontStyle: textStyle,
        },
        icon: {
          key: 'dark_matter_icon_44',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: Math.floor(this._userData.dark_matter || 0).toLocaleString('en-us'),
          fontStyle: textStyle,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: blackProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: darkMatterMin,
          maxValue: darkMatterMax,
          progressValue: this._userData.dark_matter || 0,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );

    this.add(this.scene.add.existing(this._darkMatterProgressBar), {
      padding: { left: 0, right: 0, top: scene.applyScale(15), bottom: 0 },
      key: 'darkMatterProgressBar',
    });

    // HP Progress Bar
    this._hpProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      1,
      {
        rtl: true,
        title: {
          text: '',
          fontStyle: textStyle,
        },
        icon: {
          key: 'hp_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: this.getTotalHPText(this._userMachines),
          fontStyle: textStyle,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: grayProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: 0,
          maxValue: 10,
          progressValue: 0,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
    this.add(this.scene.add.existing(this._hpProgressBar), {
      padding: { left: 0, right: 0, top: scene.applyScale(15), bottom: 0 },
      key: 'hpProgressBar',
    });

    // DPS Progress Bar
    this._dpsProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      1,
      {
        rtl: true,
        title: {
          text: '',
          fontStyle: textStyle,
        },
        icon: {
          key: 'dps_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: this.getTotalDPSText(this._userMachines),
          fontStyle: textStyle,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: grayProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: 0,
          maxValue: 10,
          progressValue: 0,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
    this.add(this.scene.add.existing(this._dpsProgressBar), {
      padding: { left: 0, right: 0, top: scene.applyScale(15), bottom: 0 },
      key: 'dpsProgressBar',
    });

    // ADD CLICK EVENTS
    if (this._allowProgressBarClicks) {
      // Space Elixir Bar Events
      this._spaceElixirProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._spaceElixirProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._spaceElixirProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._spaceElixirProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._spaceElixirProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }
          this.scene.scene.get('HomebaseScene').input.enabled = false;
          this.scene.scene.launch('ResourceModalScene', { resource: 'space_elixir' });
          this.scene.scene.pause('HudScene');
        });

      // Neon Gold Bar Events
      this._neonGoldProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._neonGoldProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._neonGoldProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._neonGoldProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._neonGoldProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }
          this.scene.scene.get('HomebaseScene').input.enabled = false;
          this.scene.scene.launch('ResourceModalScene', { resource: 'neon_gold' });
          this.scene.scene.pause('HudScene');
        });

      // Stardust Bar Events
      this._stardustProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._stardustProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._stardustProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._stardustProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._stardustProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }
          StakingService.getInstance()
            .getStakingInfo(undefined, undefined, 0, 30)
            .then(res => {
              this.scene.scene.get('HomebaseScene').input.enabled = false;
              this.scene.scene.launch('ResourceModalScene', { resource: 'stardust' });
              this.scene.scene.pause('HudScene');
            });
        });

      // Dark Matter Bar Events
      this._darkMatterProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._darkMatterProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._darkMatterProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._darkMatterProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._darkMatterProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }
          this.scene.scene.get('HomebaseScene').input.enabled = false;
          this.scene.scene.launch('ResourceModalScene', { resource: 'dark_matter' });
          this.scene.scene.pause('HudScene');
        });

      // HP Bar Events
      this._hpProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._hpProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._hpProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._hpProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._hpProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }

          this.scene.scene.get('HomebaseScene').input.enabled = false;
          this.scene.scene.launch('HpModalScene', { resource: 'hp' });
          this.scene.scene.pause('HudScene');
        });

      // HP Bar Events
      this._dpsProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.sound.play('hud-button-hover');
            this._dpsProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._dpsProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._dpsProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._dpsProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }

          this.scene.scene.get('HomebaseScene').input.enabled = false;
          this.scene.scene.launch('HpModalScene', { resource: 'dps' });
          this.scene.scene.pause('HudScene');
        });
    }

    this.layout();
  }

  private getTotalHPText(userMachines) {
    if (userMachines && userMachines.length > 1) {
      var totalHp = 0;

      userMachines.forEach(mach => {
        if (mach.hitpoints && mach.hitpoints > 0) {
          totalHp += mach.hitpoints;
        }
      });

      return totalHp.toLocaleString('en-us');
    } else {
      return '0';
    }
  }

  private getTotalDPSText(userMachines) {
    if (userMachines && userMachines.length > 1) {
      var totalDps = 0;

      userMachines.forEach(mach => {
        if (mach.dps && mach.dps > 0) {
          totalDps += mach.dps;
        }
      });

      return totalDps.toLocaleString('en-us');
    } else {
      return '0';
    }
  }

  private getStorageCapacity(userMachines, resource) {
    var storageTotal = 0;
    const storageProperty =
      resource === resources.neon_gold ? 'storage_neon_gold' : resource === resources.space_elixir ? 'storage_space_elixir' : 'stardust';

    if (storageProperty === 'stardust') return 0;

    userMachines.forEach(mach => {
      if (mach[storageProperty] && mach[storageProperty] > 0) {
        storageTotal = storageTotal + mach[storageProperty];
      }
    });

    return storageTotal;
  }

  public updateProgressBars(userMachines, userData) {
    // Neon Gold Bar
    this._neonGoldProgressBar?.setProgress(this.scene, userData.neon_gold);
    this._neonGoldProgressBar?.updateMaxValue(this.scene, this.getStorageCapacity(userMachines, resources.neon_gold));

    // Stardust Bar
    this._stardustProgressBar?.setProgress(this.scene, userData.stardust);

    // Space Elixir Bar
    this._spaceElixirProgressBar?.setProgress(this.scene, userData.space_elixir);
    this._spaceElixirProgressBar?.updateMaxValue(this.scene, this.getStorageCapacity(userMachines, resources.space_elixir));

    // HP Bar
    this._hpProgressBar?.setProgressBarText(this.getTotalHPText(userMachines));

    // DPS Bar
    this._dpsProgressBar?.setProgressBarText(this.getTotalDPSText(userMachines));
  }
}

Phaser.GameObjects.GameObjectFactory.register('ResourcesComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ResourcesComponent(this.scene, x, y));
});
