import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { OverlapSizer, FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import ShopService from '~/services/ShopService';
import ShopItemComponent from '../item_shop/shopItemComponent';
import ShopMachineComponent from '../machines_shop/shopMachineComponent';
import ProgressBar from '~/components/progressBar/ProgressBar';

const modalWidth = 1004;
const modalHeight = 506;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const subtitleTint = 0xa3a3b9;

const backButtonImageKey = 'shop_back_button';

const itemTypes = {
  machine: 'machine',
  shop_item: 'shop_item',
};

// Progress bar consts
const goldProgressBarColor = 0xdea827;
const pinkProgressBarColor = 0xde2795;
const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 0.4;
const radius = 7;

const progressBarHeight = 22;
const stardustBarWidth = 448;
const neonGoldBarWidth = 448;
const spaceElixirBarWidth = 448;

const stardustMin = 0;
const neonGoldMin = 0;
const spaceElixirMin = 0;

const buyButtonImage = 'shop_buy_button';
const comingSoonButtonImage = 'shop_coming_soon_button';
const soldOutButtonImage = 'shop_sold_out_button';

const sizerItemLeftPadding = 77;
const titleBottomPadding = 8;
const costLabelBottomPadding = 8;
const progBarTopPadding = 7;
const lowerDividerTopPadding = 20;
const lowerDividerBottomPadding = 20;
const descriptionLabelBottomPadding = 7;

export default class ShopItemInfoContentPane extends OverlapSizer {
  private _item: any;
  private _type: any;
  private _backButton: any;
  private _itemComponent: any;
  private _itemTitle: any;
  private _costSubtitle: any;
  private _descriptionTitle: any;
  private _descriptionText: any;
  private _stardustProgressBar: any;
  private _neonGoldProgressBar: any;
  private _spaceElixirProgressBar: any;
  private _darkMatterProgressBar: any;
  private _buyButton: any;
  private _calcLabel: any;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, item: any, type: string, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: modalWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._item = item;
    this._type = type;

    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    this.add(background, {
      key: 'info_scene_bg',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Back Button
    this._backButton = new Button(this.scene, 0, 0, backButtonImageKey, false).setScale(this._scale);
    this.scene.add.existing(this._backButton);

    this._backButton.onClick().subscribe(pointer => {
      this.backButtonHandler();
    });

    this.add(this._backButton, {
      key: 'item_info_back_button',
      align: 'left-top',
      offsetX: this.applyScale(33),
      offsetY: this.applyScale(27),
      expand: false,
    });

    // Item Component
    switch (type) {
      case itemTypes.machine:
        this._itemComponent = new ShopMachineComponent(scene, 0, 0, this._item, scene.getScale(), false);
        scene.add.existing(this._itemComponent);
        break;
      case itemTypes.shop_item:
        this._itemComponent = new ShopItemComponent(
          scene,
          0,
          0,
          this._item,
          scene.getScale(),
          false,
          this._item.type === 'refill' ? 'Calculating...' : undefined,
        );
        scene.add.existing(this._itemComponent);
        break;
      default:
        break;
    }

    if (this._itemComponent) {
      this.add(this._itemComponent, {
        key: 'item_info_item_component',
        align: 'left-top',
        offsetX: this.applyScale(38),
        offsetY: this.applyScale(102),
        expand: false,
      });
    }

    // Upper Divider
    const upperDivider = this.scene.add.image(0, 0, 'shop_item_info_divider_long');

    this.add(upperDivider, {
      key: 'upper_divider',
      align: 'left-top',
      offsetX: this.applyScale(260),
      offsetY: this.applyScale(102),
      expand: false,
    });

    // Sizer for dynamic height content
    var sizer = this.scene.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(spaceElixirBarWidth + 10),
      height: this.applyScale(350),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Item Title
    this._itemTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getDisplayName(), this.applyScale(28), 0);

    sizer
      .add(this._itemTitle, {
        key: 'item_title',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: 0, bottom: this.applyScale(titleBottomPadding) },
      })
      .addNewLine();

    // Cost Subtitle
    this._costSubtitle = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getCostSubtitle(), this.applyScale(16), 0);
    this._costSubtitle.setTint(subtitleTint);

    sizer
      .add(this._costSubtitle, {
        key: 'cost_subtitle',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: 0, bottom: 0 },
      })
      .addNewLine();

    if (this._item.type === 'refill') {
      this._calcLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Calculating...', this.applyScale(20), 0);
      this._calcLabel.setTint(subtitleTint);

      sizer.add(this._calcLabel, {
        key: 'calc_label',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: this.applyScale(progBarTopPadding), bottom: 0 },
      });

      ShopService.getInstance()
        .getRefillCost(this._item.refillCurrencyId, this._item.refillPercentage)
        .then(res => {
          this._item.dark_matter_cost = res.dark_matter_cost || 0;
          this._item.stardust_cost = this._item.cost = res.stardust_cost || 0;
          this._item.neon_gold_cost = res.neon_gold_cost || 0;
          this._item.space_elixir_cost = res.space_elixir_cost || 0;

          this._calcLabel.setAlpha(0);
          sizer.remove(this._calcLabel, true);

          this._itemComponent.updateLabelText(this._item.stardust_cost.toLocaleString('en-us'));
          this.addProgressBars(sizer);
          this.layout();
        });
    } else {
      this.addProgressBars(sizer);
    }

    // Lower Divider
    const lowerDivider = this.scene.add.image(0, 0, 'shop_item_info_divider_short');

    sizer.add(lowerDivider, {
      key: 'lower_divider',
      padding: { left: 0, right: 0, top: this.applyScale(lowerDividerTopPadding), bottom: this.applyScale(lowerDividerBottomPadding) },
    });

    // Description Title
    this._descriptionTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Description', this.applyScale(16), 0);
    this._descriptionTitle.setTint(subtitleTint);

    sizer
      .add(this._descriptionTitle, {
        key: 'description_title',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: 0, bottom: this.applyScale(descriptionLabelBottomPadding) },
      })
      .addNewLine();

    // Description Text Area
    this._descriptionText = this.scene.rexUI.add
      .textArea({
        x: 0,
        y: 0,
        width: this.applyScale(550),
        height: this.applyScale(76),
        text: this.scene.add.bitmapText(0, 0, 'cc_outline', this.getDescription(), this.applyScale(16), 0),
        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },
        scroller: true,
      })
      .layout();

    this._descriptionText.setText(this.getDescription());

    sizer.add(this._descriptionText, {
      key: 'description_text',
      padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: 0, bottom: 0 },
    });

    // Add sizer
    this.add(sizer, {
      key: 'prog_sizer',
      align: 'left-top',
      offsetX: this.applyScale(288),
      offsetY: this.applyScale(122),
      expand: false,
    });

    if (this._type === itemTypes.shop_item) {
      if (this._item.count > 0) {
        // Buy Button
        this._buyButton = new Button(scene, 0, 0, buyButtonImage, false).setScale(scene.getScale());
        scene.add.existing(this._buyButton);

        this._buyButton.onClick().subscribe(pointer => {
          this._buyButton.disableInteractive();
          if (this._item.count > 0) {
            switch (this._item.type) {
              case 'whitelist':
                this.scene.scene.start('ShopWalletScene', {
                  item: this._item,
                });
                break;
              case 'discord_item':
                this.scene.scene.start('ShopDiscordScene', {
                  item: this._item,
                });
                break;
              case 'refill':
                ShopService.getInstance()
                  .purchaseResourceRefill(this._item.refillCurrencyId, this._item.refillPercentage)
                  .then(res => {
                    this.scene.scene.start('ShopPurchaseResultScene', {
                      item: this._item,
                      itemType: 'shop_item',
                      purchaseResult: res,
                    });
                  });
                break;
              case 'other':
                if (this._item.shop_item_id === 11) {
                  // Battle Pass Purchase
                  this.scene.scene.stop('ShopModalScene');
                  this.scene.scene.stop('ShopItemInfoScene');
                  this.scene.scene.stop('HomebaseScene');
                  this.scene.scene.stop('HudScene');
                  this.scene.scene.start('BattlePassBuyScene');
                } else {
                  ShopService.getInstance()
                    .purchaseOther(this._item.shop_item_id)
                    .then(res => {
                      this.scene.scene.start('ShopPurchaseResultScene', {
                        item: this._item,
                        itemType: 'shop_item',
                        purchaseResult: res,
                      });
                    });
                }
                break;
              case 'reward':
                ShopService.getInstance()
                  .purchaseRewardBox(this._item.reward_type_id)
                  .then(res => {
                    this.scene.scene.start('ShopRewardScene', {
                      item: this._item,
                      itemType: 'resource_box',
                      purchaseResult: res,
                    });
                  });
            }
          }
        });
      } else {
        this._buyButton = scene.add.image(0, 0, soldOutButtonImage).setScale(scene.getScale());
      }
    } else if (this._type === itemTypes.machine) {
      // Buy Button
      this._buyButton = new Button(scene, 0, 0, buyButtonImage, false).setScale(scene.getScale());
      scene.add.existing(this._buyButton);

      this._buyButton.onClick().subscribe(pointer => {
        this.scene.scene.start('ShopPurchaseConfirmScene', {
          item: this._item,
          isUpgrade: false,
        });
      });
    } else {
      this._buyButton = scene.add.image(0, 0, comingSoonButtonImage).setScale(scene.getScale());
    }

    this.add(this._buyButton, {
      key: 'buy_button',
      align: 'right-bottom',
      offsetX: this.applyScale(-33),
      offsetY: this.applyScale(-36),
      expand: false,
    });

    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  public hidePane() {
    this._backButton.setAlpha(0);
    this._itemComponent.setAlpha(0);
    //this.table.disableInteractive();
  }

  private backButtonHandler() {
    this.scene.scene.resume('ShopModalScene');
    this.scene.scene.stop('ShopItemInfoScene');
  }

  private getDisplayName() {
    if (this._type === itemTypes.shop_item) {
      if (this._item.type === 'whitelist') {
        return this._item.name.slice(-2) === 'WL' ? this._item.name : `${this._item.name} WL`;
      } else {
        return this._item.name;
      }
    }

    return this._item.display_name || 'Unknown Item';
  }

  private getCostSubtitle() {
    if (this._type === itemTypes.machine) {
      return 'Cost to build';
    }

    return 'Cost to buy';
  }

  private getDescription() {
    if (this._type === itemTypes.shop_item) {
      switch (this._item.type) {
        case 'whitelist':
          return `Reserve a spot on the ${this.getDisplayName()}\n\nSpots remaining: ${this._item.count}`;
        default:
          return this._item.description;
      }
    }

    return this._item.description;
  }

  private addProgressBars(sizer: FixWidthSizer) {
    var progBarCount = 0;
    var sizerItemIndex = 4;

    // Space Elixir
    const spaceElixirCost = this.getSpaceElixirCost();
    if (spaceElixirCost > 0) {
      progBarCount++;

      this._spaceElixirProgressBar = new ProgressBar(this.scene, 0, 0, this._scale, {
        rtl: false,
        title: {
          text: '',
          fontStyle: null,
        },
        icon: {
          key: 'space_elixir_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: spaceElixirCost.toLocaleString('en-us'),
          fontStyle: null,
          width: spaceElixirBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: blueProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: spaceElixirMin,
          maxValue: spaceElixirCost > 0 ? spaceElixirCost : 100,
          progressValue: spaceElixirCost,
          glare: {
            height: glareHeight,
            width: spaceElixirBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      });
      this.scene.add.existing(this._spaceElixirProgressBar);

      sizer.add(this._spaceElixirProgressBar, {
        key: 'space_elixir_bar',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: this.applyScale(progBarTopPadding), bottom: 0 },
        index: sizerItemIndex,
      });

      sizerItemIndex++;
    }

    // Neon Gold
    const neonGoldCost = this.getNeonGoldCost();
    if (neonGoldCost > 0) {
      progBarCount++;

      this._neonGoldProgressBar = new ProgressBar(this.scene, 0, 0, this._scale, {
        rtl: false,
        title: {
          text: '',
          fontStyle: null,
        },
        icon: {
          key: 'neon_gold_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: neonGoldCost.toLocaleString('en-us'),
          fontStyle: null,
          width: neonGoldBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: goldProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: neonGoldMin,
          maxValue: neonGoldCost > 0 ? neonGoldCost : 100,
          progressValue: neonGoldCost,
          glare: {
            height: glareHeight,
            width: neonGoldBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      });
      this.scene.add.existing(this._neonGoldProgressBar);

      sizer.add(this._neonGoldProgressBar, {
        key: 'neon_gold_bar',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: this.applyScale(progBarTopPadding), bottom: 0 },
        index: sizerItemIndex,
      });

      sizerItemIndex++;
    }

    // Dark Matter
    const darkMatterCost = this.getDarkMatterCost();
    if (darkMatterCost > 0) {
      this._darkMatterProgressBar = new ProgressBar(this.scene, 0, 0, this._scale, {
        rtl: false,
        title: {
          text: '',
          fontStyle: null,
        },
        icon: {
          key: 'dark_matter_icon_44',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: darkMatterCost.toLocaleString('en-us'),
          fontStyle: null,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: pinkProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: stardustMin,
          maxValue: darkMatterCost > 0 ? darkMatterCost : 100,
          progressValue: darkMatterCost,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      });
      this.scene.add.existing(this._darkMatterProgressBar);

      sizer.add(this._darkMatterProgressBar, {
        key: 'dark_matter_bar',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: this.applyScale(progBarTopPadding), bottom: 0 },
        index: sizerItemIndex,
      });

      sizerItemIndex++;
    }

    // Stardust
    const stardustCost = this.getStardustCost();
    if (stardustCost > 0 || progBarCount === 0) {
      progBarCount++;

      this._stardustProgressBar = new ProgressBar(this.scene, 0, 0, this._scale, {
        rtl: false,
        title: {
          text: '',
          fontStyle: null,
        },
        icon: {
          key: 'stardust_icon_44',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: stardustCost.toLocaleString('en-us'),
          fontStyle: null,
          width: stardustBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: pinkProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: stardustMin,
          maxValue: stardustCost > 0 ? stardustCost : 100,
          progressValue: stardustCost,
          glare: {
            height: glareHeight,
            width: stardustBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      });
      this.scene.add.existing(this._stardustProgressBar);

      sizer.add(this._stardustProgressBar, {
        key: 'stardust_bar',
        padding: { left: this.applyScale(sizerItemLeftPadding), right: 0, top: this.applyScale(progBarTopPadding), bottom: 0 },
        index: sizerItemIndex,
      });

      sizerItemIndex++;
    }

    sizer.layout();
  }

  private getSpaceElixirCost() {
    if (this._type === itemTypes.machine) {
      return this._item.cost_space_elixir;
    } else {
      return this._item.currency_id === 1 ? this._item.cost : 0;
    }
  }

  private getNeonGoldCost() {
    if (this._type === itemTypes.machine) {
      return this._item.cost_neon_gold;
    } else {
      return this._item.currency_id === 2 ? this._item.cost : 0;
    }
  }

  private getStardustCost() {
    if (this._type === itemTypes.machine) {
      return this._item.cost_stardust;
    } else {
      return this._item.currency_id === 3 ? this._item.cost : 0;
    }
  }

  private getDarkMatterCost() {
    if (this._type === itemTypes.machine) {
      return this._item.cost_dark_matter;
    } else {
      return this._item.currency_id === 4 ? this._item.cost : 0;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('shopItemInfoContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ShopItemInfoContentPane(this.scene, x, y));
});
