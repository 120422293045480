import Phaser from 'phaser';
import util from 'util';
import Button from '~/components/buttons/Button';
import ShopItemInfoContentPane from './shopItemInfoPane';

const modalWidth = 1004;

export default class ShopItemInfoScene extends Phaser.Scene {
  private _scale;
  private _item;
  private _type;
  private _pane;
  private _sizer;

  init(data: any) {
      this._scale = data.scale;
      this._item = data.item;
      this._type = data.type;
  }

  constructor() {
    super({
      key: 'ShopItemInfoScene'
    });
  }

  preload() {
  }

  create() {
    if (this.game.input.touch && this.game.input.touch.preventDefault) {
        this.game.input.touch.preventDefault = false;
        this.game.input.touch.enabled = true;
    }

    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        width: modalWidth * this._scale, 
        space: {
            left: 0, right: 0, top: 0, bottom: 0,
            item: 0, line: 0
        },
        align: 0,
        sizerEvents: true
    });

    this._pane = new ShopItemInfoContentPane(this, 0, 0, this._item, this._type, this._scale);
    this.add.existing(this._pane);

    this._sizer.add(this._pane, {
        key: 'info_pane',
        align: 'left-top',
        offsetX: 0,
        offsetY: this.applyScale(37),
        expand: false
    });

    this._sizer.layout();
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private destroy() {

  }
}