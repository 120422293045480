import ApiProxyService from './ApiProxyService';
import ConfigurationService from './ConfigurationService';
import util from 'util';
import NFTService from './NFTService';
import WalletConnectService from './WalletConnectService';

const noMember = {
  id: 0,
  nft_collection: 'Star Wolvez Generative',
  display_name: 'None Selected       ',
  image_key: 'starwolf_silhouette',
  image_thumbnail_url: '',
  show_yield: false,
  show_hp: false,
  disabled: true,
  hp: 900,
};
export default class SquadService {
  private static _instance: SquadService = new SquadService();

  private _squad;
  private _traits;
  private _randomSquad;
  private _config: any;

  constructor() {
    if (SquadService._instance) {
      throw new Error('Error: Instantiation failed: Use SquadService.getInstance() instead of new.');
    }
    SquadService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): SquadService {
    return SquadService._instance;
  }

  private setSquad(value): void {
    this._squad = value;
  }

  private setRandomSquad(value): void {
    this._randomSquad = value;
  }

  public getSquad(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._squad && !forceUpdate) {
        return resolve(this._squad);
      } else {
        return resolve(this.fetchSquad());
      }
    });
  }

  public getSquadSync() {
    return this._squad;
  }

  private fetchSquad() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('squad', [wallet, 'mining'], [])
      .then(res => {
        return this.enrichSquadData(res.squad).then(enrichedSquad => {
          this._squad = enrichedSquad;
          this._traits = this.generateTraitsList(this._squad);
          return this._squad;
        });
      });
  }

  public getSquadFromMarketplace() {
    return this.fetchSquadFromMarketplace();
  }

  private fetchSquadFromMarketplace() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('squad', [wallet, 'mining'], [])
      .then(res => {
        return this.enrichSquadDataFromMarketplace(res.squad).then(enrichedSquad => {
          this._squad = enrichedSquad;
          this._traits = this.generateTraitsList(this._squad);
          return this._squad;
        });
      });
  }

  public getTraitsSync() {
    return this._traits;
  }

  private enrichSquadData(squadData) {
    return NFTService.getInstance()
      .getNFTs(squadData.map(sm => sm.marketplace_nft_id))
      .then(res => {
        if (squadData && squadData.length > 0) {
          const enrichedSquad = squadData.map(squadMember => {
            var nftData = res.nfts.find(nft => {
              return nft.id === squadMember.marketplace_nft_id;
            });
            if (nftData) {
              return Object.assign({}, { position: squadMember.position, squad_type_id: squadMember.squad_type_id }, nftData);
            }
            return undefined;
          });

          var squadArray: any[] = [];

          enrichedSquad.forEach(sqMem => {
            if (sqMem) {
              squadArray[sqMem.position] = sqMem;
            }
          });

          for (var i = 0; i < 3; i++) {
            if (!squadArray[i]) {
              squadArray[i] = Object.assign({}, noMember, { position: i });
            }
          }

          return squadArray;
        } else {
          return [Object.assign({}, noMember), Object.assign({}, noMember), Object.assign({}, noMember)];
        }
      });
  }

  private enrichSquadDataFromMarketplace(squadData) {
    return NFTService.getInstance()
      .getNFTsFromMarketplace(squadData.map(sm => sm.marketplace_nft_id))
      .then(res => {
        if (squadData && squadData.length > 0) {
          const enrichedSquad = squadData.map(squadMember => {
            var nftData = res.nfts.find(nft => {
              return nft.id === squadMember.marketplace_nft_id;
            });
            if (nftData) {
              return Object.assign({}, { position: squadMember.position, squad_type_id: squadMember.squad_type_id }, nftData);
            }
            return undefined;
          });

          var squadArray: any[] = [];

          enrichedSquad.forEach(sqMem => {
            if (sqMem) {
              squadArray[sqMem.position] = sqMem;
            }
          });

          for (var i = 0; i < 3; i++) {
            if (!squadArray[i]) {
              squadArray[i] = Object.assign({}, noMember, { position: i });
            }
          }

          return squadArray;
        } else {
          return [Object.assign({}, noMember), Object.assign({}, noMember), Object.assign({}, noMember)];
        }
      });
  }

  private generateTraitsList(squad) {
    var traits: Array<Trait> = new Array<Trait>();
    squad.forEach(nft => {
      if (nft.traits !== undefined) {
        nft.traits.forEach(trait => {
          traits.push({ collection: nft.nft_collection, id: nft.nft_id, type: trait.trait_type, trait: trait.value });
        });
      }
    });
    return traits;
  }

  public saveSquad(currentSquad) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('squad', [wallet, 'mining'], currentSquad)
      .then(res => {
        return res;
      });
  }

  getPFPImageKey() {
    return this._squad[0].image_key;
  }

  getPFPUrl() {
    return this._squad[0].image_thumbnail_url;
  }

  getSquadHP() {
    var damage = 0;
    if (this._squad) {
      damage =
        this._squad
          .map(sm => {
            return sm.hp;
          })
          .reduce((previous, current) => {
            return previous + current;
          }) || 1500;
    }
    return damage;
  }
}

export interface SquadMember {
  collection: string;
  hp: number;
  traits: any;
}

export interface Trait {
  collection: string;
  id: string;
  type: string;
  trait: string;
}
