import Phaser from 'phaser';
import _ from 'lodash';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import TrainingTroopComponent from '~/components/troops/trainingTroopComponent';
import TroopService from '~/services/TroopService';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import SquadService from '~/services/SquadService';

const noTroopsMessage = 'You currently do not have any available troops.';

const paneHeight = 594;
const paneWidth = 1066;

const tableHeight = 605;
const tableWidth = 1063;

const progressBarRadius = 7;
const progressBarHeight = 22;
const progressBarWidth = 238;
const progressBarBackgroundColor = 0xb48dc7;
const progressBarBackgroundAlpha = 0.4;
const progressBarAlpha = 1;
const progressBarMin = 0;
const grayProgressBarColor = 0x9a9a9a;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;

const machineUnitIds = {
  ars: 2,
  healer: 4,
  mercenary: 1,
  super_swole: 3,
};

export default class ArmyContentPane extends FixWidthSizer {
  private _userMachineId: any;
  private _troops: any;
  private _squad: any;
  private _troopGroups: any;
  private _title: Phaser.GameObjects.BitmapText;
  private _capacityProgressBar: any;
  private _table: any;
  private _noTroopsLabel: any;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, userMachineId: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._userMachineId = 0; //userMachineId;

    // Title
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Trained Army', this.applyScale(22), 0).setOrigin(0, 0);

    this.add(this._title, {
      key: 'army_pane_title',
      padding: { left: this.applyScale(27), right: 0, top: this.applyScale(27), bottom: 0 },
    });

    // Capacity Progress Bar
    this._capacityProgressBar = this.createCapacityProgressBar(scene);

    this.add(this._capacityProgressBar, {
      key: 'capacity_progress_bar',
      padding: { left: this.applyScale(774), right: 0, top: this.applyScale(-32), bottom: 0 },
    });

    // Populate troops
    this._troops = TroopService.getInstance()
      .getTroopsByMachineSync(this._userMachineId)
      ?.filter(troop => troop.level > 0);
    this._squad = SquadService.getInstance().getSquadSync();

    // Table
    if (this._troops === undefined || this._troops.length === 0) {
      // Show squad if available
      if (this._squad && this._squad.length > 0) {
        // Create table
        this._table = this.createTable(this.scene, this._squad, 0, 0).layout();

        this.add(this._table, {
          key: 'army_pane_table',
          padding: { left: this.applyScale(2), right: 0, top: this.applyScale(10), bottom: 0 },
        });
      } else {
        // Show no troops pane
        this.createNoTroopsPane(this.scene, 0, 0);
      }
    } else {
      this._table = this.createTable(scene, this._squad.concat(this.createTroopGroups()), x, y).layout();

      this.add(this._table, {
        padding: { left: this.applyScale(2), right: 0, top: this.applyScale(10), bottom: 0 },
        key: 'army_pane_table',
      });
    }

    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  createTroopGroups() {
    this._troopGroups = [];

    this._troops.forEach(troop => {
      var existingGroup = this._troopGroups.find(group => group.machine_unit_id === troop.machine_unit_id && group.base_level === troop.base_level);

      if (existingGroup) {
        existingGroup.count += 1;
      } else {
        this._troopGroups.push(Object.assign({}, troop, { count: 1 }));
      }
    });

    return _.sortBy(this._troopGroups, ['machine_unit_id', 'level']);
  }

  createNoTroopsPane(scene: Phaser.Scene, x: number, y: number) {
    this._noTroopsLabel = scene.add
      .bitmapText(x - this.applyScale(507), y + this.applyScale(33), 'cc_outline', noTroopsMessage, this.applyScale(20), 0)
      .setOrigin(0, 0);

    this._noTroopsLabel.setAlpha(0.4);

    this.add(this._noTroopsLabel, {
      padding: { left: this.applyScale(27), right: this.applyScale(600), top: this.applyScale(10), bottom: 0 },
      key: 'no_troops_label',
    });
  }

  private createCapacityProgressBar(scene: Phaser.Scene) {
    return new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: '',
        },
        icon: {
          key: 'capacity_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: this.getCapacityText(),
          width: progressBarWidth,
          height: progressBarHeight,
          radius: progressBarRadius,
          color: grayProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: progressBarBackgroundColor,
          backgroundAlpha: progressBarBackgroundAlpha,
          minValue: progressBarMin,
          maxValue: this.getMaxCapacity(),
          progressValue: this.getTroopTotal(),
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
  }

  private getCapacityText() {
    return `${this.getTroopTotal()} / ${this.getMaxCapacity()}`;
  }

  private getMaxCapacity() {
    return MachineService.getInstance().getCadetDormsCapacity();
  }

  private getTroopTotal() {
    return TroopService.getInstance().getTroopCapacityUsed();
  }

  updateCapacityBar() {
    this._capacityProgressBar.updateMaxValue(this.scene, this.getMaxCapacity());
    this._capacityProgressBar.setProgress(this.scene, this.getTroopTotal());
    this._capacityProgressBar.setProgressBarText(this.getCapacityText());
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(172 * data.length > tableWidth ? tableWidth : 172 * data.length + 11),
        height: this.applyScale(tableHeight),
        items: [data[0]],
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(172),
          cellHeight: this.applyScale(230),
          columns: 6,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        space: {
          table: {
            left: this.applyScale(23),
            right: 0,
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          function getDisplayName(item: any) {
            if (item.nft_collection === 'Star Wolvez Genesis') {
              return `Genesis Wolf #${item.nft_id}`;
            }

            if (item.nft_collection) {
              return item.display_name;
            }

            if (item.machine_unit_id) {
              switch (item.machine_unit_id) {
                case 1:
                  return `Mercenary LV. ${item.level}`;
                case 2:
                  return `ARS LV. ${item.level}`;
                case 3:
                  return `Super Swole LV. ${item.level}`;
                case 4:
                  return `Healer LV. ${item.level}`;
              }
            }

            return 'Unknown LV. 0';
          }

          function getDamageText(item: any) {
            return item.machine_unit_id === machineUnitIds.healer ? `${item.heal_per_second || 0} HEAL PER SEC` : `${item.dps || 0} DMG PER SEC`;
          }

          var sizer = scene.rexUI.add.fixWidthSizer({
            width: scene.applyScale(149),
            space: {
              left: 0,
              right: scene.applyScale(23),
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
          });

          if (item) {
            var troopImage = new TrainingTroopComponent(scene, 0, 0, item, scene.getScale());

            const troopLabel = scene.add.bitmapText(0, 0, 'cc_outline', getDisplayName(item).toUpperCase(), scene.applyScale(16), 0);

            const damageLabel = scene.add.bitmapText(0, 0, 'cc_outline', getDamageText(item), scene.applyScale(16), 0);
            damageLabel.alpha = 0.4;

            sizer.add(troopImage, {
              padding: { left: 0, right: 0, top: 0, bottom: 0 },
              key: 'troop_image',
            });

            sizer.add(troopLabel, {
              padding: { left: scene.applyScale(2), right: 0, top: scene.applyScale(24), bottom: 0 },
              key: 'troop_label',
            });

            sizer.add(damageLabel, {
              padding: { left: scene.applyScale(2), right: 0, top: scene.applyScale(-1), bottom: 0 },
              key: 'damage_label',
            });
          }

          return sizer.layout();
        },
      })
      .setOrigin(0, 0.5);

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }

  public updateTroopsList() {
    TroopService.getInstance()
      .getTroopsByMachine(this._userMachineId, true)
      .then(res => {
        if (!res) return;

        this._troops = res.length > 0 ? res.filter(troop => troop.time_until_next_level === 0) : res;

        if (this._troops.length === 0) {
          if (this._table) {
            // Remove table
            this._table.setAlpha(0);
            this.remove(this._table, true);
            this._table = undefined;

            // Show squad if available
            if (this._squad && this._squad.length > 0) {
              // Create table
              this._table = this.createTable(this.scene, this._squad, 0, 0).layout();

              this.add(this._table, {
                key: 'army_pane_table',
                padding: { left: this.applyScale(2), right: 0, top: this.applyScale(10), bottom: 0 },
              });
            } else {
              // Show no troops pane
              this.createNoTroopsPane(this.scene, 0, 0);
            }
          }
        } else {
          if (this._table) {
            // Table might need to get smaller, destroy it if it exists
            this._table.setAlpha(0);
            this.remove(this._table, true);
            this._table = undefined;
          } else {
            // Remove no troops pane if it exists
            this._noTroopsLabel.setAlpha(0);
            this.remove(this._noTroopsLabel, true);
            this._noTroopsLabel = undefined;
          }

          // Create table
          this._table = this.createTable(this.scene, this._squad.concat(this.createTroopGroups()), 0, 0).layout();

          this.add(this._table, {
            key: 'army_pane_table',
            padding: { left: this.applyScale(2), right: 0, top: this.applyScale(10), bottom: 0 },
          });
        }
        this.layout();
      });
  }

  public hidePane() {
    this._title.setAlpha(0);
    this._capacityProgressBar.setAlpha(0);
    this._noTroopsLabel?.setAlpha(0);
    //this.table.disableInteractive();
  }
}

Phaser.GameObjects.GameObjectFactory.register('armyContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ArmyContentPane(this.scene, x, y));
});
