import Phaser from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import TroopService from '~/services/TroopService';
import ProgressBar from '~/components/progressBar/ProgressBar';
import TrainableTroopsContentPane from './trainableTroopsContentPane';
import TrainingTroopsContentPane from './trainingTroopsContentPane';
import MachineService from '~/services/MachineService';

const paneHeight = 594;
const paneWidth = 1066;

const progressBarRadius = 7;
const progressBarHeight = 22;
const progressBarWidth = 238;
const progressBarBackgroundColor = 0xb48dc7;
const progressBarBackgroundAlpha = 0.4;
const progressBarAlpha = 1;
const progressBarMin = 0;
const grayProgressBarColor = 0x9a9a9a;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;

export default class ArmyTrainingContentPane extends FixWidthSizer {
  private _machine: any;
  private _title: Phaser.GameObjects.BitmapText;
  private _capacityProgressBar: any;
  private _trainingTroopsPane: any;
  private _trainableTroopsPane: any;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, machine: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._machine = machine;

    // Title
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Training Area', this.scene.applyScale(22), 0).setOrigin(0, 0);

    this.add(this._title, {
      key: 'army_training_pane_title',
      padding: { left: this.scene.applyScale(27), right: 0, top: this.scene.applyScale(27), bottom: 0 },
    });

    // Capacity Progress Bar
    this._capacityProgressBar = this.createCapacityProgressBar(scene);

    this.add(this._capacityProgressBar, {
      key: 'capacity_progress_bar',
      padding: { left: this.scene.applyScale(774), right: 0, top: this.scene.applyScale(-32), bottom: 0 },
    });

    // Training Troops Pane
    this._trainingTroopsPane = new TrainingTroopsContentPane(this.scene, 0, 0, this._scale, this._machine.user_machine_id);
    this.add(this.scene.add.existing(this._trainingTroopsPane), {
      key: 'training_troops_pane',
      padding: { left: this.scene.applyScale(2), right: 0, top: this.scene.applyScale(10), bottom: 0 },
    });

    // Trainable Troops Pane
    this._trainableTroopsPane = new TrainableTroopsContentPane(this.scene, 0, 0, this._machine, this._scale);
    this.add(this.scene.add.existing(this._trainableTroopsPane), {
      key: 'trainable_troops_pane',
      padding: { left: this.scene.applyScale(29), right: 0, top: this.scene.applyScale(20), bottom: 0 },
    });

    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  private createCapacityProgressBar(scene: Phaser.Scene) {
    return new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: '',
        },
        icon: {
          key: 'capacity_icon',
          space: 8,
          scale: 1,
        },
        progressBar: {
          text: this.getCapacityText(),
          width: progressBarWidth,
          height: progressBarHeight,
          radius: progressBarRadius,
          color: grayProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: progressBarBackgroundColor,
          backgroundAlpha: progressBarBackgroundAlpha,
          minValue: progressBarMin,
          maxValue: this.getMaxCapacity(),
          progressValue: this.getTroopTotal(),
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
  }

  private getCapacityText() {
    return `${this.getTroopTotal()} / ${this.getMaxCapacity()}`;
  }

  private getMaxCapacity() {
    return MachineService.getInstance().getCadetDormsCapacity();
  }

  private getTroopTotal() {
    return TroopService.getInstance().getTroopCapacityUsed();
  }

  updateCapacityBar() {
    this._capacityProgressBar.updateMaxValue(this.scene, this.getMaxCapacity());
    this._capacityProgressBar.setProgress(this.scene, this.getTroopTotal());
    this._capacityProgressBar.setProgressBarText(this.getCapacityText());
  }

  public updateTraineeList() {
    this._trainingTroopsPane.updateTraineeList();
  }

  public hidePane() {
    this._title.setAlpha(0);
    this._capacityProgressBar.setAlpha(0);
  }
}

Phaser.GameObjects.GameObjectFactory.register('armyTrainingContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ArmyTrainingContentPane(this.scene, x, y));
});
