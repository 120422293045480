import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import ProgressBar from '~/components/progressBar/ProgressBar';
import StakingService from '~/services/StakingService';
import { boostDamagePerHour, createMiningTraitsDisplayList, getMiningTraits } from '~/utils/TraitUtils';

// Pane Details
const paneHeight = 400;
const paneWidth = 689;
const paneRadius = 16;
const paneColor = 0x262637;
const paneAlpha = 1;

const sizerBgColour = 0x383d57;

// Squad Display

export default class MiningAsteroidSquadPane extends OverlapSizer {
  private _playerData;
  private _squad;
  private _traits;
  private _nfts;
  private _rarity;
  private _nft_boost;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._playerData = data;
    this._squad = data.squadMembers;
    this._traits = data.traits;
    this._nfts = data.nfts;
    this._rarity = data.rarity;
    this._nft_boost = data.nft_boost;
    this._scale = paneScale;

    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    const miningTraits = getMiningTraits(this._traits);
    const damagePerHour = boostDamagePerHour(
      this._squad.reduce((acc, wolf) => acc + wolf.hp, 0),
      this._rarity,
      miningTraits,
    );

    const potentialNetDamage = Math.round(damagePerHour * this.getDamageMultiplyer());

    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Squad Panel
    var squadPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(120),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // const squadPanelBg = scene.add.rexRoundRectangle(
    //     0, // x
    //     0, // y
    //     scene.applyScale(paneWidth), // width
    //     scene.applyScale(120), // height
    //     scene.applyScale(0), // radius
    //     0xdea827, // fillColor
    //     paneAlpha, // alpha
    // )

    // squadPanel.add(squadPanelBg, {
    //     key: "squad-panel-background",
    //     align: "center",
    //     offsetX: 0,
    //     offsetY: 0,
    //     expand: false,
    // })

    // Your Squad Label
    const squadLabel = scene.add.bitmapText(0, 0, 'cc_outline', 'Your Squad', scene.applyScale(18), 1);
    squadPanel.add(squadLabel, {
      key: 'squad-label',
      align: 'left-top',
      offsetX: this.applyScale(16),
      offsetY: this.applyScale(16),
      expand: false,
    });

    var sm1 = this.createSquadMemberImage(scene, this._playerData.squadMembers[0]);
    squadPanel.add(sm1, {
      key: 'squad-member-1',
      align: 'left-top',
      offsetX: this.applyScale(20),
      offsetY: this.applyScale(50),
      expand: false,
    });

    var sm2 = this.createSquadMemberImage(scene, this._playerData.squadMembers[1]);
    squadPanel.add(sm2, {
      key: 'squad-member-2',
      align: 'left-top',
      offsetX: this.applyScale(80),
      offsetY: this.applyScale(50),
      expand: false,
    });

    var sm3 = this.createSquadMemberImage(scene, this._playerData.squadMembers[2]);
    squadPanel.add(sm3, {
      key: 'squad-member-3',
      align: 'left-top',
      offsetX: this.applyScale(140),
      offsetY: this.applyScale(50),
      expand: false,
    });

    var damageLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getDamageMultiplyerLabel(), this.applyScale(24), 1);
    squadPanel.add(damageLabel, {
      key: 'damage-label',
      align: 'right-top',
      offsetX: this.applyScale(-16),
      offsetY: this.applyScale(50),
      expand: false,
    });

    var wolfCountLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getWolfCountLabel(), this.applyScale(18), 1).setTint(0xa3a3b9);

    squadPanel.add(wolfCountLabel, {
      key: 'wolf-count-label',
      align: 'right-top',
      offsetX: this.applyScale(-16),
      offsetY: this.applyScale(80),
      expand: false,
    });

    const squadDivider = this.scene.add.image(0, 0, 'mining_divider').setScale(this._scale);

    squadPanel.add(squadDivider, {
      key: 'squad-divider',
      align: 'left-bottom',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.add(squadPanel, {
      key: 'squad-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Damage Panel
    var damagePanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(80),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    var dmhProgressBar = new ProgressBar(scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: `Damage per hour: ${damagePerHour.toLocaleString('en-us')}`,
        align: 'left',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: '',
        textAlign: 'left',
        fontStyle: null,
        width: this.applyScale(315),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 100,
        glare: {
          width: this.applyScale(315 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    damagePanel.add(dmhProgressBar, {
      key: 'dph-progressbar',
      align: 'left-top',
      offsetX: this.applyScale(20),
      offsetY: this.applyScale(30),
      expand: false,
    });

    var pndProgressBar = new ProgressBar(scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: `Potential net damage: ${potentialNetDamage.toLocaleString('en-us')}`,
        align: 'left',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: '',
        textAlign: 'left',
        fontStyle: null,
        width: this.applyScale(315),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 100,
        glare: {
          width: this.applyScale(315 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    damagePanel.add(pndProgressBar, {
      key: 'pnd-progressbar',
      align: 'right-top',
      offsetX: this.applyScale(-20),
      offsetY: this.applyScale(30),
      expand: false,
    });

    const damageDivider = this.scene.add.image(0, 0, 'mining_divider').setScale(this._scale);

    damagePanel.add(damageDivider, {
      key: 'damage-divider',
      align: 'left-bottom',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.add(damagePanel, {
      key: 'damage-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(120),
      expand: false,
    });

    // Traits Pane
    var traitsPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight - 80 - 120),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    const traitsList = createMiningTraitsDisplayList(this._traits);
    const table = this.createTraitsTable(this.scene, traitsList, x, y, this.scale).layout();
    traitsPanel.add(table, {
      key: 'traits-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.add(traitsPanel, {
      key: 'traits-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(80 + 120),
      expand: false,
    });

    this.layout();
  }

  private createSquadMemberImage(scene, squadMember) {
    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, this.getImageKey(squadMember));

    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 3;
    smImageData.destroy();

    var squadMemberImage = this.scene.add.rexCircleMaskImage(0, 0, this.getImageKey(squadMember), '', rectConfig);

    squadMemberImage.displayHeight = scene.applyScale(48);
    squadMemberImage.scaleX = squadMemberImage.scaleY;

    return squadMemberImage;
  }

  private getImageKey(squadMem): string {
    if (squadMem.image_thumbnail_url && squadMem.image_thumbnail_url.length > 0 && squadMem.image_key && squadMem.image_key.length > 0) {
      return squadMem.image_key;
    } else {
      return 'starwolf_silhouette';
    }
  }

  private createTraitsTable(scene, data, x, y, scale) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(paneWidth),
        height: this.applyScale(paneHeight - 200),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(paneWidth),
          cellHeight: this.applyScale(60),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            trait = cell.item;

          // Sizer variables
          const componentWidth = paneWidth,
            componentHeight = 60,
            componentRadius = 0,
            componentColor = 0x262637,
            componentAlpha = 1;

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(componentWidth),
              height: scene.applyScale(componentHeight),
              space: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                item: 0,
                line: 0,
              },
              align: 0,
            })
            // Background
            .add(
              scene.add.rexRoundRectangle(
                0, // x
                0, // y
                scene.applyScale(componentWidth),
                scene.applyScale(componentHeight),
                scene.applyScale(componentRadius),
                componentColor,
                componentAlpha,
              ),
              {
                key: 'trait_cell_bg',
                align: 'center',
              },
            )

            // Special Trait Label
            .add(scene.add.bitmapText(0, 0, 'cc_outline', trait.trait?.toUpperCase() + ' #' + trait.id, scene.applyScale(18), 0), {
              key: `special-trait-label`,
              align: 'left-top',
              offsetX: scene.applyScale(20),
              offsetY: scene.applyScale(20),
              expand: false,
            })

            // Special Trait Description Label
            .add(scene.add.bitmapText(0, 0, 'cc_outline', trait.description?.toString(), scene.applyScale(16), 0).setAlpha(0.4), {
              key: `special-trait-description-label`,
              align: 'right-top',
              offsetX: scene.applyScale(-20),
              offsetY: scene.applyScale(20),
              expand: false,
            })

            // Divider
            .add(scene.add.image(0, 0, 'mining_divider').setScale(scale), {
              key: 'damage-divider',
              align: 'left-bottom',
              offsetX: scene.applyScale(0),
              offsetY: scene.applyScale(0),
              expand: false,
            });

          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  private getDamageMultiplyer() {
    return this._nft_boost;
  }

  private getDamageMultiplyerLabel() {
    const prefix = this.getDamageMultiplyer().toString() + 'x';
    const suffix = 'dmg';

    return prefix + ' ' + suffix;
  }

  private getWolfCountLabel() {
    var data = StakingService.getInstance().getStakingInfoSync();

    const wolfCount = data.sw_genesis_count + data.sw_generative_count;

    if (wolfCount === 1) return 'You own 1 wolf';
    return `You own ${wolfCount} wolvez`;
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('miningAsteroidSquadPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new MiningAsteroidSquadPane(this.scene, x, y));
});
