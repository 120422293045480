import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import NFTService from '~/services/NFTService';
import ScaleService from '~/services/ScaleService';
import WalletConnectService from '~/services/WalletConnectService';
import { Login_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { StartScene } from '~/utils/SceneLoader';

//const LoginAudio = require('url:../../../public/assets/audios/login-button.wav');

const desktopHeight = 982;
const desktopWidth = 1512;
const mobileHeight = 932;
const mobileWidth = 430;

export default class LoginScene extends Phaser.Scene {
  private walletConnectService: WalletConnectService;
  private nftService: NFTService;
  private _scale;

  // UI
  private _isDesktop = true;
  private _walletConnectButton;
  private _googleConnectButton;
  private _appleConnectButton;

  constructor() {
    super({
      key: 'LoginScene',
    });

    this.walletConnectService = WalletConnectService.getInstance();
    this.nftService = NFTService.getInstance();
  }

  preload() {
    LoadImageAssets(this, Login_Assets);

    //this.load.audio('login-button', LoginAudio);
    this.walletConnectService.onWalletConnected(this.handleWalletFound, this);
    this.walletConnectService.onWalletConnectClosed(this.handleWalletConnectClosed, this);

    if (!this.sys.game.device.os.desktop) {
      this._isDesktop = false;
    }
  }

  create() {
    this.createComponent(this._isDesktop);
  }

  createComponent(isDesktop: boolean) {
    if (isDesktop) {
      this._scale = ScaleService.getInstance().getScale(desktopHeight, desktopWidth, 1.3);
      this.createDesktop();
    } else {
      this._scale = ScaleService.getInstance().getMobileScale(mobileWidth, mobileHeight);
      this.createMobile();
    }
  }

  createDesktop() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: this.applyScale(desktopWidth),
      height: this.applyScale(desktopHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Images
    var swLogo = this.add.image(0, 0, 'login_sw_logo').setScale(this._scale);
    sizer.add(swLogo, {
      key: 'login-logo',
      align: 'left-top',
      offsetX: this.applyScale(210),
      offsetY: this.applyScale(115),
      expand: false,
    });

    var sw_game = this.add.image(0, 0, 'login_game_logo').setScale(this._scale);
    sizer.add(sw_game, {
      key: 'login-game-logo',
      align: 'left-top',
      offsetX: this.applyScale(464),
      offsetY: this.applyScale(233),
      expand: false,
    });

    this._walletConnectButton = new Button(this, 0, 0, 'connect_wallet_button', true, 1.05).setScale(this._scale);
    this._walletConnectButton.onClick().subscribe(pointer => {
      this._walletConnectButton.disableInteractive();
      this.input.setDefaultCursor('default');
      this.walletConnectService.connectWallet();
    });
    this.add.existing(this._walletConnectButton);

    sizer.add(this._walletConnectButton, {
      key: 'wallet-button',
      align: 'left-top',
      offsetX: this.applyScale(183),
      offsetY: this.applyScale(385),
      expand: false,
    });

    var dividerLeft = this.add.image(0, 0, 'login_divider').setScale(this._scale);
    sizer.add(dividerLeft, {
      key: 'login-divider-left',
      align: 'left-top',
      offsetX: this.applyScale(183),
      offsetY: this.applyScale(490),
      expand: false,
    });

    var orImage = this.add.image(0, 0, 'or_image').setScale(this._scale);
    sizer.add(orImage, {
      key: 'login-or-image',
      align: 'left-top',
      offsetX: this.applyScale(358),
      offsetY: this.applyScale(475),
      expand: false,
    });

    var dividerRight = this.add.image(0, 0, 'login_divider').setScale(this._scale);
    sizer.add(dividerRight, {
      key: 'login-divider-right',
      align: 'left-top',
      offsetX: this.applyScale(402),
      offsetY: this.applyScale(490),
      expand: false,
    });

    this._googleConnectButton = new Button(this, 0, 0, 'google_login_button', true, 1.05).setScale(this._scale);
    this._googleConnectButton.disableInteractive();
    this._googleConnectButton.onClick().subscribe(pointer => {});
    this.add.existing(this._googleConnectButton);

    sizer.add(this._googleConnectButton, {
      key: 'google-button',
      align: 'left-top',
      offsetX: this.applyScale(183),
      offsetY: this.applyScale(536),
      expand: false,
    });

    this._appleConnectButton = new Button(this, 0, 0, 'apple_login_button', true, 1.05).setScale(this._scale);
    this._appleConnectButton.onClick().subscribe(pointer => {});
    this._appleConnectButton.disableInteractive();
    this.add.existing(this._appleConnectButton);

    sizer.add(this._appleConnectButton, {
      key: 'apple-button',
      align: 'left-top',
      offsetX: this.applyScale(183),
      offsetY: this.applyScale(612),
      expand: false,
    });

    var disclaimer = this.add.image(0, 0, 'disclaimer_image').setScale(this._scale);
    sizer.add(disclaimer, {
      key: 'login-disclaimer',
      align: 'left-top',
      offsetX: this.applyScale(227),
      offsetY: this.applyScale(738),
      expand: false,
    });

    var loginPoster = this.add.image(0, 0, this.randomizeLoginPoster()).setScale(this._scale);
    sizer.add(loginPoster, {
      key: 'login-background',
      align: 'left-top',
      offsetX: this.applyScale(746),
      offsetY: this.applyScale(10),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  createMobile() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: this.applyScale(mobileWidth),
      height: this.applyScale(mobileHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Images
    var swLogo = this.add.image(0, 0, 'login_sw_logo_mobile').setScale(this._scale);
    sizer.add(swLogo, {
      key: 'login-logo',
      align: 'left-top',
      offsetX: this.applyScale(89),
      offsetY: this.applyScale(108),
      expand: false,
    });

    var sw_game = this.add.image(0, 0, 'login_game_logo').setScale(this._scale);
    sizer.add(sw_game, {
      key: 'login-game-logo',
      align: 'left-top',
      offsetX: this.applyScale(264),
      offsetY: this.applyScale(201),
      expand: false,
    });

    this._walletConnectButton = new Button(this, 0, 0, 'connect_wallet_button', true, 1.05).setScale(this._scale);
    this._walletConnectButton.onClick().subscribe(pointer => {
      this._walletConnectButton.disableInteractive();
      this.input.setDefaultCursor('default');
      this.walletConnectService.connectWallet();
    });
    this.add.existing(this._walletConnectButton);

    sizer.add(this._walletConnectButton, {
      key: 'wallet-button',
      align: 'left-top',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(390),
      expand: false,
    });

    var dividerLeft = this.add.image(0, 0, 'login_divider').setScale(this._scale);
    sizer.add(dividerLeft, {
      key: 'login-divider-left',
      align: 'left-top',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(495),
      expand: false,
    });

    var orImage = this.add.image(0, 0, 'or_image').setScale(this._scale);
    sizer.add(orImage, {
      key: 'login-or-image',
      align: 'left-top',
      offsetX: this.applyScale(193),
      offsetY: this.applyScale(475),
      expand: false,
    });

    var dividerRight = this.add.image(0, 0, 'login_divider').setScale(this._scale);
    sizer.add(dividerRight, {
      key: 'login-divider-right',
      align: 'left-top',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(495),
      expand: false,
    });

    this._googleConnectButton = new Button(this, 0, 0, 'google_login_button', true, 1.05).setScale(this._scale);
    this._googleConnectButton.disableInteractive();
    this._googleConnectButton.onClick().subscribe(pointer => {});
    this.add.existing(this._googleConnectButton);

    sizer.add(this._googleConnectButton, {
      key: 'google-button',
      align: 'left-top',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(541),
      expand: false,
    });

    this._appleConnectButton = new Button(this, 0, 0, 'apple_login_button', true, 1.05).setScale(this._scale);
    this._appleConnectButton.onClick().subscribe(pointer => {});
    this._appleConnectButton.disableInteractive();
    this.add.existing(this._appleConnectButton);

    sizer.add(this._appleConnectButton, {
      key: 'apple-button',
      align: 'left-top',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(617),
      expand: false,
    });

    var disclaimer = this.add.image(0, 0, 'disclaimer_image').setScale(this._scale);
    sizer.add(disclaimer, {
      key: 'login-disclaimer',
      align: 'left-top',
      offsetX: this.applyScale(65),
      offsetY: this.applyScale(743),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  randomizeLoginPoster() {
    var poster = 'login_poster_';
    const randomDecimal = Math.random();
    const randomNumber = Math.floor(randomDecimal * 6) + 1;
    poster += '' + randomNumber;
    return poster;
  }

  applyScale(length) {
    return this._scale * length;
  }

  handleWalletFound() {
    WalletConnectService.getInstance().stopWalletConnectListener();
    //this.scene.scene.sound.play('login-button');
    StartScene(this, 'LoadingScene');
  }

  handleWalletConnectClosed() {
    this._walletConnectButton.setInteractive();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Login_Assets));
  }
}
