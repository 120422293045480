import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { Mining_Assets } from '~/utils/AssetLoader';

const width = 494;
const height = 325;
const radius = 50;

export default class MiningNotEnoughResourcesDialogScene extends Phaser.Scene {
  private _scale;

  constructor() {
    super({
      key: 'MiningNotEnoughResourcesDialogScene',
    });
  }

  preload() {
    this.load.image('mining_not_enough_resources_dialog_got_it_button', Mining_Assets.mining_not_enough_resources_dialog_got_it_button);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    // Overlay
    this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c).setOrigin(0).setAlpha(0.5);

    const background = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2,
        this.applyScale(width),
        this.applyScale(height),
        this.applyScale(radius),
        0x350926,
      )
      .setStrokeStyle(1, 0x3d4361);

    const gotItButton = new Button(
      this,
      this.sys.canvas.width / 2,
      background.getBottomLeft().y,
      'mining_not_enough_resources_dialog_got_it_button',
      false,
    ).setScale(this._scale);

    gotItButton.onClick().subscribe(() => {
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });

    this.add.existing(gotItButton);

    this.rexUI.add
      .sizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        orientation: 'y',
        width: this.applyScale(width),
        height: this.applyScale(height),
        space: { item: this.applyScale(32) },
      })
      .addBackground(background)
      .addSpace()
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'No Asteroids for you right now.', this.applyScale(22), 1).setMaxWidth(300))
      .add(
        this.add
          .bitmapText(
            0,
            0,
            'cc_outline',
            'There are either currently no available Asteroids or you do not have the requisite resources to launch any Mining Missions.',
            this.applyScale(18),
            1,
          )
          .setTint(0xbfaac6)
          .setMaxWidth(300),
      )
      .addSpace()
      .layout();
  }

  private applyScale(length: number) {
    return length * this._scale;
  }
}
