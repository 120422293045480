import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import BlobService from '~/services/BlobService';
import { Blob_Hud_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets } from '~/utils/AssetManager';

const HUD_DISABLED = true;

const statusPaneConfig = {
  height: 351,
  width: 254,
  fillColor: 0x350926,
  alpha: 0.9,
  radius: 26,
};

const levelPaneConfig = {
  height: 440,
  width: 254,
  fillColor: 0x350926,
  alpha: 0.9,
  radius: 26,
};

const buttonPaneConfig = {
  height: 108,
  width: 512,
  fillColor: 0x1c1c2c,
  alpha: 1,
  radius: 26,
};

const grayTextConfig = {
  size: 18,
  color: 0x75758b,
};

const whiteTextConfig = {
  smallSize: 18,
  largeSize: 28,
  headingSize: 24,
  color: 0xffffff,
};

const statLabelWidth = 222;

const blobActions = {
  giveFood: 1,
  giveMedicine: 2,
  pet: 3,
  putToSleep: 4,
};

export default class BlobHudScene extends Phaser.Scene {
  private _blobInfo;
  private _statusPane;
  private _levelPane;
  private _buttonPane;

  private _giveFoodButton;
  private _giveMedicineButton;
  private _putToSleepButton;
  private _petButton;
  private _assignPointButton;

  constructor() {
    super('BlobHudScene');
  }

  preload() {
    LoadImageAssets(this, Blob_Hud_Assets);
  }

  create() {
    this._blobInfo = BlobService.getInstance().getBlobInfoSync();
    // Status Pane
    this.createStatusPane();

    // Level Pane
    this.createLevelPane();

    // Button Pane
    this.createButtonPane();
  }

  private createStatusPane() {
    var bg = this.add.rexRoundRectangle(
      0,
      0,
      statusPaneConfig.width, // width
      statusPaneConfig.height, // height
      statusPaneConfig.radius, // radius
      statusPaneConfig.fillColor, // fillColor
      statusPaneConfig.alpha, // alpha
    );

    this._statusPane = this.rexUI.add
      .fixWidthSizer({
        x: this.sys.canvas.width / 2 - (306 + statusPaneConfig.width), // x
        y: this.sys.canvas.height > 800 ? 130 : 80, // y
        width: statusPaneConfig.width,
        height: statusPaneConfig.height,
        align: 0,
      })
      .setOrigin(0, 0)
      .addBackground(bg);

    // Heading
    var heading = this.add
      .bitmapText(
        this._statusPane.getTopLeft().x + statusPaneConfig.width / 2,
        this._statusPane.getTopLeft().y,
        'cc_outline',
        'YOUR BLOB',
        whiteTextConfig.headingSize,
        0,
      )
      .setOrigin(0.5, 0.5);

    // Status
    this._statusPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'STATUS', grayTextConfig.size, 0).setTint(grayTextConfig.color), {
        padding: { left: 16, right: 0, top: 27, bottom: 0 },
        key: 'status_label',
      })
      .addNewLine();

    this._statusPane
      .add(
        this.add.bitmapText(
          0,
          0,
          'cc_outline',
          HUD_DISABLED ? 'COMING SOON' : this._blobInfo.status === '' ? 'Idle' : this._blobInfo.status,
          whiteTextConfig.largeSize,
          0,
        ),
        {
          padding: { left: 16, right: 0, top: 8, bottom: 0 },
          key: 'status_value',
        },
      )
      .addNewLine();

    this._statusPane.add(this.add.image(0, 0, 'blob_hud_divider_line'), {
      padding: { left: 0, right: 0, top: 25, bottom: 0 },
      key: 'upper_divider',
    });

    // Stats
    this._statusPane.add(this.createBlobStat('food', this._blobInfo.food_value, 60), {
      padding: { left: 16, right: 0, top: 25, bottom: 0 },
      key: 'food_stat',
    });

    this._statusPane.add(this.createBlobStat('health', this._blobInfo.health_value, 60), {
      padding: { left: 16, right: 0, top: 15, bottom: 0 },
      key: 'health_stat',
    });

    this._statusPane.add(this.createBlobStat('happiness', this._blobInfo.happiness_value, 60), {
      padding: { left: 16, right: 0, top: 15, bottom: 0 },
      key: 'happiness_stat',
    });

    this._statusPane.add(this.createBlobStat('energy', this._blobInfo.energy_value, 60), {
      padding: { left: 16, right: 0, top: 15, bottom: 0 },
      key: 'energy_stat',
    });

    this._statusPane.add(this.add.image(0, 0, 'blob_hud_divider_line'), {
      padding: { left: 0, right: 0, top: 25, bottom: 0 },
      key: 'lower_divider',
    });

    // Daily Actions
    this._statusPane.add(this.createBlobStat('daily actions', this._blobInfo.actions_remaining, this._blobInfo.actions_allowed_per_day), {
      padding: { left: 16, right: 0, top: 15, bottom: 0 },
      key: 'daily_actions_stat',
    });

    this._statusPane.layout();
  }

  private createLevelPane() {
    // Background
    var bg = this.add.rexRoundRectangle(
      0,
      0,
      levelPaneConfig.width, // width
      levelPaneConfig.height, // height
      levelPaneConfig.radius, // radius
      levelPaneConfig.fillColor, // fillColor
      levelPaneConfig.alpha, // alpha
    );

    this._levelPane = this.rexUI.add
      .fixWidthSizer({
        x: this.sys.canvas.width / 2 + 306, // x
        y: this.sys.canvas.height > 800 ? 130 : 80, // y
        width: levelPaneConfig.width,
        height: levelPaneConfig.height,
        align: 0,
      })
      .setOrigin(0, 0)
      .addBackground(bg);

    // Heading
    var heading = this.add
      .bitmapText(
        this._levelPane.getTopLeft().x + levelPaneConfig.width / 2,
        this._levelPane.getTopLeft().y,
        'cc_outline',
        'LEVEL',
        whiteTextConfig.headingSize,
        0,
      )
      .setOrigin(0.5, 0.5);

    // Level Area
    this._levelPane.add(this.createLevelLabel(HUD_DISABLED ? 0 : this._blobInfo.level), {
      padding: { left: 16, right: 0, top: 27, bottom: 0 },
      key: 'level_label',
    });

    // Divider
    this._levelPane.add(this.add.image(0, 0, 'blob_hud_divider_line'), {
      padding: { left: 0, right: 0, top: 25, bottom: 0 },
      key: 'level_divider',
    });

    // Defense
    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'DEFENSE PER HR', grayTextConfig.size, 0).setTint(grayTextConfig.color), {
        padding: { left: 16, right: 0, top: 25, bottom: 0 },
        key: 'defense_label',
      })
      .addNewLine();

    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', `${HUD_DISABLED ? 0 : this._blobInfo.defense_per_hour}HP`, whiteTextConfig.largeSize, 0), {
        padding: { left: 16, right: 0, top: 3, bottom: 0 },
        key: 'defense_value',
      })
      .addNewLine();

    // Machine Multiplier
    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'MACHINE MULTIPLIER', grayTextConfig.size, 0).setTint(grayTextConfig.color), {
        padding: { left: 16, right: 0, top: 18, bottom: 0 },
        key: 'machine_multi_label',
      })
      .addNewLine();

    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', `x${HUD_DISABLED ? 0 : this._blobInfo.machine_multiplier}`, whiteTextConfig.largeSize, 0), {
        padding: { left: 16, right: 0, top: 3, bottom: 0 },
        key: 'mm_value',
      })
      .addNewLine();

    // Points
    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'AVAILABLE POINTS', grayTextConfig.size, 0).setTint(grayTextConfig.color), {
        padding: { left: 16, right: 0, top: 18, bottom: 0 },
        key: 'points_label',
      })
      .addNewLine();

    this._levelPane
      .add(this.add.bitmapText(0, 0, 'cc_outline', `${HUD_DISABLED ? 0 : this._blobInfo.unapplied_points}`, whiteTextConfig.largeSize, 0), {
        padding: { left: 16, right: 0, top: 3, bottom: 0 },
        key: 'points_value',
      })
      .addNewLine();

    // Button
    this._assignPointButton = new Button(this, 0, 0, 'assign_points_button', false).setDisabled(HUD_DISABLED).setAlpha(HUD_DISABLED ? 0.6 : 1);

    this._assignPointButton.onClick().subscribe(pointer => {
      this.scene.pause('HomebaseScene');
      this.scene.pause('BlobHudScene');
      this.scene.launch('UpgradeBlobModalScene');
    });

    this._levelPane.add(this.add.existing(this._assignPointButton), {
      padding: { left: 16, right: 0, top: 22, bottom: 0 },
      key: 'assign_points_button',
    });

    this._levelPane.layout();
  }

  private createButtonPane() {
    var bg = this.add.rexRoundRectangle(
      0,
      0,
      buttonPaneConfig.width, // width
      buttonPaneConfig.height, // height
      buttonPaneConfig.radius, // radius
      buttonPaneConfig.fillColor, // fillColor
      buttonPaneConfig.alpha, // alpha
    );

    this._buttonPane = this.rexUI.add
      .fixWidthSizer({
        x: this.sys.canvas.width / 2, // x
        y: this.sys.canvas.height - (this.sys.canvas.height > 800 ? 138 : 98), // y
        width: buttonPaneConfig.width,
        height: buttonPaneConfig.height,
        space: {
          left: 26,
          right: 26,
          top: 29,
          bottom: 29,
          item: 13,
          line: 0,
        },
        align: 2,
      })
      .addBackground(bg);

    var buttonsEnabled = HUD_DISABLED ? 0 : this._blobInfo.status === '';
    var buttonAlpha = buttonsEnabled ? 1 : 0.6;

    this._giveFoodButton = new Button(this, 0, 0, 'give_food_button', false).setAlpha(buttonAlpha).setDisabled(!buttonsEnabled);
    this._giveFoodButton.onClick().subscribe(pointer => {
      BlobService.getInstance()
        .performAction(blobActions.giveFood)
        .then(res => {
          if (res.success) {
            BlobService.getInstance().setBlobInfo(res.blob);
            this.scene.restart();
          }
        });
    });

    this._giveMedicineButton = new Button(this, 0, 0, 'give_medication_button', false).setAlpha(buttonAlpha).setDisabled(!buttonsEnabled);
    this._giveMedicineButton.onClick().subscribe(pointer => {
      BlobService.getInstance()
        .performAction(blobActions.giveMedicine)
        .then(res => {
          if (res.success) {
            BlobService.getInstance().setBlobInfo(res.blob);
            this.scene.restart();
          }
        });
    });

    this._putToSleepButton = new Button(this, 0, 0, 'put_to_sleep_button', false).setAlpha(buttonAlpha).setDisabled(!buttonsEnabled);
    this._putToSleepButton.onClick().subscribe(pointer => {
      BlobService.getInstance()
        .performAction(blobActions.putToSleep)
        .then(res => {
          if (res.success) {
            BlobService.getInstance().setBlobInfo(res.blob);
            this.scene.restart();
          }
        });
    });

    this._petButton = new Button(this, 0, 0, 'pet_button', false).setAlpha(buttonAlpha).setDisabled(!buttonsEnabled);
    this._petButton.onClick().subscribe(pointer => {
      BlobService.getInstance()
        .performAction(blobActions.pet)
        .then(res => {
          if (res.success) {
            BlobService.getInstance().setBlobInfo(res.blob);
            this.scene.restart();
          }
        });
    });

    this._buttonPane.add(this.add.existing(this._giveFoodButton), { key: 'give_food_button' });
    this._buttonPane.add(this.add.existing(this._giveMedicineButton), { key: 'give_medication_button' });
    this._buttonPane.add(this.add.existing(this._putToSleepButton), { key: 'put_to_sleep_button' });
    this._buttonPane.add(this.add.existing(this._petButton), { key: 'pet_button' });

    this._buttonPane.layout();
  }

  private createBlobStat(labelText: string, progressValue: number, maxValue: number) {
    var sizer = this.rexUI.add.overlapSizer(0, 0, {
      width: statLabelWidth,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', `${labelText.toUpperCase()}`, grayTextConfig.size, 0).setTint(grayTextConfig.color), {
      key: `${labelText}_label`,
      align: 'left-top',
      expand: false,
    });

    sizer.add(
      this.add.bitmapText(0, 0, 'cc_outline', `${HUD_DISABLED ? 0 : progressValue} / ${HUD_DISABLED ? 0 : maxValue}`, whiteTextConfig.smallSize, 0),
      {
        key: `${labelText}_value`,
        align: 'right-top',
        expand: false,
      },
    );

    return sizer.layout();
  }

  private createLevelLabel(level: number) {
    var sizer = this.rexUI.add.overlapSizer(0, 0, {
      width: statLabelWidth,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'LEVEL', grayTextConfig.size, 0).setTint(grayTextConfig.color), {
      key: `level_label`,
      align: 'left-top',
      expand: false,
    });

    sizer.add(this.add.image(0, 0, 'blob_hud_image'), {
      key: `blob_level_image`,
      align: 'right-top',
      expand: false,
    });

    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', `${level}`, whiteTextConfig.largeSize, 0), {
      key: `level_value`,
      align: 'left-bottom',
      expand: false,
    });

    return sizer.layout();
  }
}
