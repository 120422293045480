import ApiProxyService from './ApiProxyService';
import ConfigurationService from './ConfigurationService';

const noMember = {
  id: 0,
  nft_collection: 'Star Wolvez Generative',
  display_name: 'None Selected       ',
  image_key: 'starwolf_silhouette',
  hp: 0,
  show_yield: false,
  show_hp: false,
  disabled: true,
};

export default class OpponentService {
  private static _instance: OpponentService = new OpponentService();

  private _squad;
  private _nfts;
  private _traits;
  private _userData;
  private _opponent;
  private _config: any;

  constructor() {
    if (OpponentService._instance) {
      throw new Error('Error: Instantiation failed: Use OpponentService.getInstance() instead of new.');
    }
    OpponentService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): OpponentService {
    return OpponentService._instance;
  }

  public getOpponent(wallet, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._opponent && !forceUpdate) {
        return resolve(this._opponent);
      } else {
        return resolve(this.fetchOpponent(wallet));
      }
    });
  }

  private fetchOpponent(wallet) {
    return this.getUserData(wallet, true).then(userData => {
      this._opponent = userData;
      return this.getSquad(wallet, true).then(squad => {
        this._opponent.squadMembers = squad;
        return this._opponent;
      });
    });
  }

  public getOpponentSync() {
    return this._opponent;
  }

  public getTraitsSync() {
    return this._traits;
  }

  public getUserData(wallet, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._userData && !forceUpdate) {
        return resolve(this._userData);
      } else {
        return resolve(this.fetchUserData(wallet));
      }
    });
  }

  public getUserDataSync() {
    return this._userData;
  }

  private fetchUserData(wallet) {
    return ApiProxyService.getInstance()
      .get('user', [wallet], [])
      .then(res => {
        this._userData = res;
        return res;
      });
  }

  private setSquad(value): void {
    this._squad = value;
  }

  public getSquad(wallet, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._squad && !forceUpdate) {
        return resolve(this._squad);
      } else {
        return resolve(this.fetchSquad(wallet));
      }
    });
  }

  public getSquadSync() {
    return this._squad;
  }

  private fetchSquad(wallet) {
    return ApiProxyService.getInstance()
      .get('squad', [wallet, 'mining'], [])
      .then(res => {
        return this.enrichSquadData(wallet, res.squad).then(enrichedSquad => {
          this._squad = enrichedSquad;
          this._traits = this.generateTraitsList(this._squad);
          return this._squad;
        });
      });
  }

  private enrichSquadData(wallet, squadData) {
    return this.getNFTs(wallet, squadData, true).then(nfts => {
      if (squadData && squadData.length > 0) {
        const enrichedSquad = squadData.map(squadMember => {
          var nftData = nfts.find(nft => {
            return nft.id === squadMember.marketplace_nft_id;
          });
          if (nftData) {
            return Object.assign({}, { position: squadMember.position, squad_type_id: squadMember.squad_type_id }, nftData);
          }
          return undefined;
        });

        var squadArray: any[] = [];

        enrichedSquad.forEach(sqMem => {
          if (sqMem) {
            squadArray[sqMem.position] = sqMem;
          }
        });

        for (var i = 0; i < 3; i++) {
          if (!squadArray[i]) {
            squadArray[i] = Object.assign({}, noMember, { position: i });
          }
        }

        return squadArray;
      } else {
        return [Object.assign({}, noMember), Object.assign({}, noMember), Object.assign({}, noMember)];
      }
    });
  }

  public getNFTs(wallet, squad, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._nfts && this._nfts.length > 0 && !forceUpdate) {
        return resolve(this._nfts);
      } else {
        return resolve(this.fetchNFTs(wallet, squad));
      }
    });
  }

  private fetchNFTs(wallet, squad) {
    var promises: any[] = [];

    squad.forEach(squadMember => {
      promises.push(ApiProxyService.getInstance().get('nfts', [wallet, squadMember.marketplace_nft_id], []));
    });

    this._nfts = [];

    return Promise.all(promises).then(responses => {
      responses.forEach(res => {
        this._nfts.push(this.enrichImageData(res.nfts[0]));
      });
      return this._nfts;
    });
  }

  private enrichImageData(nft: any) {
    nft.image_key = `nft_${nft.id}`;
    return nft;
  }

  private generateTraitsList(squad) {
    var traits: Array<Trait> = new Array<Trait>();
    squad.forEach(nft => {
      if (nft.traits !== undefined) {
        nft.traits.forEach(trait => {
          traits.push({ collection: nft.nft_collection, id: nft.nft_id, type: trait.trait_type, trait: trait.value });
        });
      }
    });
    return traits;
  }
}

export interface Trait {
  collection: string;
  id: string;
  type: string;
  trait: string;
}
