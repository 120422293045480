import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { Reward_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const subTextColor = 0xbfaac6;

const modalHeight = 325;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const dashboardHeight = 900;
const dashboardWidth = 1068;
const dashboardRadius = 25;
const pausedShadeColor = 0x1c1c1c;
const pausedShadeAlpha = 0.5;

export default class PenaltyScene extends Phaser.Scene {
  private cancelButton: Button;
  private confirmButton: Button;

  constructor() {
    super({
      key: 'PenaltyScene',
    });
  }

  preload() {
    LoadImageAssets(this, Reward_Assets);
  }
  create() {
    // Add Shade
    const pausedShade = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.sys.canvas.width, // width
      this.sys.canvas.height, // height
      0, // radius
      pausedShadeColor, // fillColor
      pausedShadeAlpha, // alpha
    );

    // Add Window
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      modalWidth, // width
      modalHeight, // height
      modalRadius, // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 90, 'cc_outline', 'Are you sure you\nwant to Open All?', 26, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign();
    this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 - 5,
        'cc_outline',
        'Please note that 20% of the\nresources that you will have earned\nwill be penalized.',
        20,
        0,
      )
      .setOrigin(0.5, 0)
      .setCenterAlign()
      .setTint(subTextColor);

    // Buttons
    this.confirmButton = new Button(this, this.sys.canvas.width / 2 + 100, modalBg.getBottomLeft().y, 'reward_confirm_button', true, 1.05);
    this.confirmButton.onClick().subscribe(pointer => {
      this.scene.start('DailyChestModalScene', {
        item: null,
        text: 'Opening daily chests...',
        type: 'all',
      });
    });
    this.add.existing(this.confirmButton);

    this.cancelButton = new Button(this, this.sys.canvas.width / 2 - 100, modalBg.getBottomLeft().y, 'reward_cancel_button', true, 1.05);
    this.cancelButton.onClick().subscribe(pointer => {
      this.scene.resume('ObjectivesModalScene');
      this.scene.stop();
    });
    this.add.existing(this.cancelButton);
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Reward_Assets));
  }
}
