import Phaser from 'phaser';
import UserService from '~/services/UserService';
import { Loading_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets } from '~/utils/AssetManager';

export default class LoadingScene extends Phaser.Scene {
  constructor() {
    super({
      key: 'LoadingScene',
    });
  }

  preload() {
    const ignoreList = ['party_blob'];
    LoadImageAssets(this, Loading_Assets, ignoreList);
  }

  create() {
    this.createComponent();

    UserService.getInstance()
      .getUserData(true)
      .then(userData => {
        if (userData && !userData.msg) {
          // Get data and start dashboard
          UserService.getInstance()
            .recordLogin()
            .then(res => {
              if (res && res.success) {
                this.scene.launch('HomebaseLoadingScene');
              } else {
                // Show a failed login screen
                this.scene.start('ErrorScene');
              }
            });
        } else {
          this.scene.start('NameScene');
        }
      })
      .catch(err => {
        // Something went wrong, prompt user to try again
        this.scene.start('ErrorScene');
      });
  }

  createComponent() {
    // Set Background
    //document.body.style.backgroundImage = "url('../assets/sw_bg.png')";
    document.body.style.backgroundRepeat = 'repeat';
    document.body.style.backgroundSize = 'auto';

    // this.cameras.main.fadeIn(500, 0, 0, 0);
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_bar');

    const text1 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'loading_text_1');
    const text2 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'loading_text_2');
    const text3 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'loading_text_3');
    const text4 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'loading_text_4');
    text2.alpha = 0;
    text3.alpha = 0;
    text4.alpha = 0;

    const prog1 = this.add.image(this.sys.canvas.width / 2 - 122, this.sys.canvas.height / 2 + 50, 'loading_prog_1');
    const prog2 = this.add.image(this.sys.canvas.width / 2 - 73, this.sys.canvas.height / 2 + 50, 'loading_prog_2');
    const prog3 = this.add.image(this.sys.canvas.width / 2 - 34, this.sys.canvas.height / 2 + 50, 'loading_prog_3');
    const prog4 = this.add.image(this.sys.canvas.width / 2 - 13, this.sys.canvas.height / 2 + 50, 'loading_prog_4');
    const prog5 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_5');
    prog1.alpha = 0;
    prog2.alpha = 0;
    prog3.alpha = 0;
    prog4.alpha = 0;
    prog5.alpha = 0;

    this.time.addEvent({
      delay: 250,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog1,
            alpha: 1,
            duration: 250,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 500,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: [text1, prog1],
            alpha: 0,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );

        this.tweens.add(
          {
            targets: [text2, prog2],
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 1000,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: text2,
            alpha: 0,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );

        this.tweens.add(
          {
            targets: [text3, prog3],
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 1500,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: text3,
            alpha: 0,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );

        this.tweens.add(
          {
            targets: [text4, prog4],
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 2000,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog5,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });
  }
}
