import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import BossFightServer from '../../../services/BossFightServerService';
import Button from '~/components/buttons/Button';
import BossFightBattlePane from './bossFightBattlePane';
import { BF_Scene_Assets } from '~/utils/AssetLoader';
import BossFightDetailsPane from './bossFightDetailsPane';
import { GameState } from '../../../../../API/BossFight/src/types/IBossFightState';
import { getNextBossFightCountdown } from '~/utils/TimeUtils';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const width = 1512;
const height = 982;

export default class BossFightScene extends Phaser.Scene {
  private _scale;
  private _server!: BossFightServer;
  private _bossFightBattlePane;
  private _bossFightDetailPane;
  private _backButton;

  // Data to pass to Battle Pane
  private _battleData;

  constructor() {
    super({
      key: 'BossFightScene',
    });
  }

  preload() {
    this._server = BossFightServer.getInstance();
    this._battleData = this._server.getRoomMetaData();

    LoadImageAssets(this, BF_Scene_Assets);
  }

  async create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    // Checks if Boss Fight Server is Alive
    if (this._server.isServerAlive()) {
      // Gathers room data from server before connecting

      switch (this._battleData.gameState) {
        case GameState.PreGame:
          // Game Starts in less than 30 minutes
          //if (getNextBossFightCountdown() < 100000) {
          if (getNextBossFightCountdown() < 600) {
            // Allow Players to Join the game
            await this.joinGame();
          } else {
            // Display Scene to Notify Players to check back later
            this.scene.stop();
            this.scene.start('BossFightLobbyClosedScene');
          }

          break;
        case GameState.PreRound:
        case GameState.Round:
        case GameState.ProcessResults:
        case GameState.DisplayResults:
          await this.joinGame();
          break;
        case GameState.Finished:
          break;
      }

      this.createComponent();
    } else {
      // show UI to notify users game server is done
      this.scene.start('BossFightServerUnavailableScene');
    }
  }

  public enableBossFightListeners() {
    this._server.onGameStateChanged(this.handleGameStateChanged, this);
    this._server.onParticipantsUpdated(this.handleParticipantsUpdated, this);
    this._server.onParticipantAttacking(this.handleParticipantAttacking, this);
  }

  public onTabbedOut() {
    this._server.disableBossFightListeners();
    this._bossFightDetailPane.stopTimer();
  }

  public onTabbedIn() {
    this.enableBossFightListeners();
    this._bossFightDetailPane.updateDetailPane(this._server.getBattleData());
    this._bossFightBattlePane.updateBattlePane(this._server.getBattleData());
    this.handleParticipantsUpdated();
  }

  async joinGame() {
    await this._server.join();
    this.enableBossFightListeners();
  }

  createComponent() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: width * this._scale,
      height: height * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Battle Pane
    this._bossFightBattlePane = new BossFightBattlePane(this, 0, 0, this._battleData, this._scale);
    sizer.add(this._bossFightBattlePane, {
      key: 'bossfight-pane',
      align: 'left-top',
      offsetX: this.applyScale(336),
      offsetY: this.applyScale(77),
      expand: false,
    });

    // Detail Pane
    this._bossFightDetailPane = new BossFightDetailsPane(this, 0, 0, this._battleData, this._scale);
    sizer.add(this._bossFightDetailPane, {
      key: 'bossfight-detail-pane',
      align: 'left-top',
      offsetX: this.applyScale(906),
      offsetY: this.applyScale(77),
      expand: false,
    });

    // Add Back Button
    this._backButton = new Button(this, 0, 0, 'bossfight_back_button', true, 1.05).setScale(this._scale);

    this._backButton.onClick().subscribe(pointer => {
      this.onBackButtonPressed();
    });

    this.add.existing(this._backButton);

    sizer.add(this._backButton, {
      key: 'back-button',
      align: 'left-top',
      offsetX: this.applyScale(24),
      offsetY: this.applyScale(896),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  destroy() {}

  onBackButtonPressed() {
    this._server.disableBossFightListeners();
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop();
  }

  createSquadMemberImage(scene, imageKey) {
    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);

    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 3;
    smImageData.destroy();

    var squadMemberImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

    squadMemberImage.displayHeight = scene.applyScale(48);
    squadMemberImage.scaleX = squadMemberImage.scaleY;

    return squadMemberImage;
  }

  // Boss Fight Listeners

  handleGameStateChanged() {
    this._bossFightDetailPane.updateDetailPane(this._server.getBattleData());
    this._bossFightBattlePane.updateBattlePane(this._server.getBattleData());
    this._bossFightBattlePane.updateParticipantsList(this._server.getBattleData());

    if (this._server.isDisplayResults()) {
      this._server.disableBossFightListeners();

      if (this._server.isBossDefeated()) {
        this.scene.pause('BossFightScene');
        this.scene.launch('BossFightVictoryScene', {
          payout: this._server.getParticipantPayout(),
        });
      } else {
        this.scene.pause('BossFightScene');
        this.scene.launch('BossFightDefeatScene', {
          payout: this._server.getParticipantPayout(),
        });
      }
    }
  }

  handleParticipantsUpdated() {
    this._battleData = this._server.getBattleData();
    var texturesToLoad = this.getTextureLoadingList();

    if (texturesToLoad.length > 0) {
      this.load.once('complete', () => {
        this._bossFightBattlePane.updateParticipantsList(this._server.getBattleData());
      });

      texturesToLoad.forEach(texture => {
        if (!this.textures.exists(texture.key)) {
          this.load.image(texture.key, texture.url);
        }
      });

      this.load.start();
    } else {
      this._bossFightBattlePane.updateParticipantsList(this._server.getBattleData());
    }
  }

  getTextureLoadingList() {
    var texturesToLoad: { key: string; url: string }[] = [];
    this._battleData.participants?.forEach(participant => {
      if (!this.textures.exists(participant.playerImageKey)) {
        texturesToLoad.push({
          key: participant.playerImageKey,
          url: participant.playerImageUrl,
        });
      }
    });
    return texturesToLoad;
  }

  handleParticipantAttacking(change: any) {
    this._bossFightBattlePane.setPlayerAttacking(change);
  }

  getGameState() {
    return this._server.getBattleData().gameState;
  }

  killTimer() {
    this._bossFightDetailPane.killTimer();
    //this.scene.remove(this._bossFightDetailPane)
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BF_Scene_Assets));

    // Unload Player PFPs
    if (this._battleData.participants) {
      const playerImageKeys: string[] = this._battleData.participants.map(participant => participant.playerImageKey);
      UnloadImages(this, playerImageKeys);
    }
  }
}
