import Phaser from 'phaser';
import _ from 'lodash';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import TroopService from '~/services/TroopService';
import TrainingTroopComponent from '~/components/troops/trainingTroopComponent';

const noTroopsMessage = 'You currently do not have any troops in training. Train some below!';

const paneWidth = 1063;
const paneHeight = 210;

const machineUnitIds = {
  ars: 2,
  healer: 4,
  mercenary: 1,
  super_swole: 3,
};

export default class TrainingTroopsContentPane extends OverlapSizer {
  private _troopData: any;
  private _table: any;
  private _userMachineID: number;
  private _noTroopsLabel: any;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, userMachineId: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          height: paneHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._userMachineID = userMachineId;

    const allTroops = TroopService.getInstance().getTroopsByMachineSync(this._userMachineID) || [];
    this._troopData = allTroops.length > 0 ? allTroops.filter(troop => troop.time_until_next_level !== 0) : [];

    console.log(`TroopData: ${util.inspect(this._troopData)}`);

    if (this._troopData.length === 0) {
      this.createNoTroopsPane(scene, x, y);
    } else {
      this.indexTroopData();
      this._table = this.createTable(scene, this._troopData, x, y).layout();

      this.add(this._table, {
        key: 'army_pane_table',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });
    }

    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  createNoTroopsPane(scene: Phaser.Scene, x: number, y: number) {
    this._noTroopsLabel = scene.add.bitmapText(0, 0, 'cc_outline', noTroopsMessage, this.applyScale(20), 0).setOrigin(0, 0);
    this._noTroopsLabel.setAlpha(0.4);

    this.add(this._noTroopsLabel, {
      key: 'no_troops_label',
      align: 'left-top',
      offsetX: scene.applyScale(24),
      offsetY: scene.applyScale(0),
      expand: false,
    });
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(172 * data.length > 1063 ? 1063 : 172 * data.length - 1 + 23),
        height: this.applyScale(210),
        items: [data[0]],
        scrollMode: 1,

        table: {
          cellWidth: this.applyScale(172),
          cellHeight: this.applyScale(210),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        space: {
          left: this.applyScale(23),
          table: {
            left: this.applyScale(0),
            right: this.applyScale(0),
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          function getDisplayName(item: any) {
            switch (item.machine_unit_id) {
              case 1:
                return `Mercenary LV. ${item.base_level}`;
              case 2:
                return `ARS LV. ${item.base_level}`;
              case 3:
                return `Super Swole LV. ${item.base_level}`;
              case 4:
                return `Healer LV. ${item.base_level}`;
              default:
                return 'Unknown LV. 0';
            }
          }

          function getDamageText(item: any) {
            return item.time_until_next_level
              ? 'TRAINING'
              : item.machine_unit_id === machineUnitIds.healer
              ? `${item.heal_per_second || 0} HEAL PER SEC`
              : `${item.dps || 0} DMG PER SEC`;
          }

          var sizer = scene.rexUI.add.fixWidthSizer({
            width: scene.applyScale(172),
            space: {
              left: 0,
              right: scene.applyScale(5),
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
          });

          if (item && item.machine_unit_id) {
            var troopImage = new TrainingTroopComponent(scene, 0, 0, item, scene.getScale());
            const troopLabel = scene.add.bitmapText(0, 0, 'cc_outline', getDisplayName(item).toUpperCase(), scene.applyScale(16), 0);
            const damageLabel = scene.add.bitmapText(0, 0, 'cc_outline', getDamageText(item), scene.applyScale(16), 0);
            damageLabel.alpha = 0.4;

            sizer.add(scene.add.existing(troopImage), {
              padding: { left: 0, right: 12, top: 0, bottom: 0 },
              key: 'troop_image',
            });

            sizer.add(troopLabel, {
              padding: { left: scene.applyScale(2), right: 0, top: scene.applyScale(24), bottom: 0 },
              key: 'troop_label',
            });

            sizer.addNewLine();

            sizer.add(damageLabel, {
              padding: { left: scene.applyScale(2), right: 0, top: scene.applyScale(-1), bottom: 0 },
              key: 'damage_label',
            });
          }

          return sizer.layout();
        },
      })
      .setOrigin(0, 0.5);

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }

  private indexTroopData() {
    const sortedTroops = _.sortBy(this._troopData, ['machine_unit_id']);

    this._troopData.forEach(troop => {
      const firstTroopByType = sortedTroops.find(str => str.machine_unit_id === troop.machine_unit_id);
      troop.indexByType = firstTroopByType.user_machine_unit_id === troop.user_machine_unit_id ? 0 : 1;
    });

    this._troopData = _.sortBy(this._troopData, 'indexByType');
  }

  updateTraineeList() {
    TroopService.getInstance()
      .getTroopsByMachine(this._userMachineID, true)
      .then(res => {
        if (!res) return;

        this._troopData = res.length > 0 ? res.filter(troop => troop.time_until_next_level !== 0) : res;

        if (this._troopData.length === 0) {
          if (this._table) {
            // Remove table
            this._table.setAlpha(0);
            this.remove(this._table, true);
            this._table = undefined;

            // Show no troops pane
            this.createNoTroopsPane(this.scene, 0, 0);
          }
        } else {
          if (this._table) {
            // Table might need to get smaller, destroy it if it exists
            this._table.setAlpha(0);
            this.remove(this._table, true);
            this._table = undefined;
          } else {
            // Remove no troops pane if it exists
            this._noTroopsLabel.setAlpha(0);
            this.remove(this._noTroopsLabel, true);
            this._noTroopsLabel = undefined;
          }

          this.indexTroopData();

          // Create table
          this._table = this.createTable(this.scene, this._troopData, 0, 0).layout();

          this.add(this._table, {
            key: 'army_pane_table',
            align: 'left-top',
            offsetX: 0,
            offsetY: 0,
            expand: false,
          });
        }
        this.layout();
      });
  }
}

Phaser.GameObjects.GameObjectFactory.register('trainingTroopsContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new TrainingTroopsContentPane(this.scene, x, y));
});
