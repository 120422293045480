import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import { GameState } from '~/../../API/BossFight/src/types/IBossFightState';
import ProgressBar from '~/components/progressBar/ProgressBar';
import { bossRarityImageMapper, shortenName } from '~/utils/GameUtils';

// Pane Details
const paneHeight = 972;
const paneWidth = 540;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

const itemWidth = 500,
  itemHeight = 73,
  itemRadius = 13,
  itemColor = 0x262637,
  itemAlpha = 1;

const itemGlareHeight = 7,
  itemGlareRadius = 4,
  itemGlareColor = 0xffffff,
  itemGlareAlpha = 0.08;

const progressBarWidth = 463;
const progressBarHeight = 22;
const progressBarRadius = 7;
const progressBarColor = 0xff5831;
const progressBarBackgroundColor = 0x383d57;
const progressBarBackgroundAlpha = 0.4;
const glareHeight = 6;
const glareColor = 0xffffff;
const glareAlpha = 0.14;
const glareOffset = 6;
const glareRadius = 3;

export default class BossFightBattlePane extends OverlapSizer {
  private _scale: number;
  private _hpProgressBar;
  private _participantsText;
  private _stateImage;
  private _participantTable;
  private _bossFightImage;

  // { bossMaxHP, bossRemainingHP, participants}
  private _battleData;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._battleData = data;
    this._scale = paneScale;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Battle Panel
    var battlePanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(120),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    const bossFightBadge = this.scene.add.image(0, 0, 'bossfight_badge').setScale(this._scale);

    battlePanel.add(bossFightBadge, {
      key: 'bossfight_badge',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(-55),
      expand: false,
    });

    // Add Title Text
    const bossFightTitle = this.scene.add.image(0, 0, 'bossfight_title_text').setScale(this._scale);

    battlePanel.add(bossFightTitle, {
      key: 'bossfight_title_text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(55),
      expand: false,
    });

    const bossFightDividerTop = this.scene.add.image(0, 0, 'bossfight_divider').setScale(this._scale);

    battlePanel.add(bossFightDividerTop, {
      key: 'bf-divider-top',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(165),
      expand: false,
    });

    // Add Boss Image
    this._bossFightImage = this.scene.add.image(0, 0, bossRarityImageMapper[this._battleData.rarity]).setScale(this._scale);

    battlePanel.add(this._bossFightImage, {
      key: 'bossfight_boss',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(166),
      expand: false,
    });

    const bossFightDividerBottom = this.scene.add.image(0, 0, 'bossfight_divider').setScale(this._scale);

    battlePanel.add(bossFightDividerBottom, {
      key: 'bf-divider-bottom',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(166 + this._bossFightImage.height + 1),
      expand: false,
    });

    // HP Progress Bar
    this._hpProgressBar = new ProgressBar(scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'bossfight_hp_icon',
        space: 8,
        scale: 1,
      },
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text:
          this._battleData.gameState == 0
            ? 'Currently Unknown...'
            : this._battleData.bossRemainingHP.toLocaleString('en-us') + ' of ' + this._battleData.bossMaxHP.toLocaleString('en-us'),
        textAlign: 'left',
        fontStyle: null,
        width: progressBarWidth,
        height: progressBarHeight,
        radius: progressBarRadius,
        color: progressBarColor,
        backgroundColor: progressBarBackgroundColor,
        backgroundAlpha: progressBarBackgroundAlpha,
        minValue: 0,
        maxValue: this._battleData.bossMaxHP,
        progressValue: this._battleData.bossRemainingHP,
        glare: {
          width: progressBarWidth - glareOffset,
          height: glareHeight,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    battlePanel.add(this._hpProgressBar, {
      key: 'hp-progressbar',
      align: 'left-top',
      offsetX: this.applyScale(30),
      offsetY: this.applyScale(140),
      expand: false,
    });

    // Participants Text
    this._participantsText = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getParticipantsText(this._battleData), scene.applyScale(18), 0);
    battlePanel.add(this._participantsText, {
      key: 'participants-text',
      align: 'left-top',
      offsetX: this.applyScale(30),
      offsetY: this.applyScale(166 + this._bossFightImage.height + 1 + 20),
      expand: false,
    });

    // Status Image
    this._stateImage = this.scene.add.image(0, 0, this.getBossFightGameStatusImage(this._battleData.gameState)).setScale(this._scale);

    battlePanel.add(this._stateImage, {
      key: 'status-image',
      align: 'right-top',
      offsetX: this.applyScale(-30),
      offsetY: this.applyScale(166 + this._bossFightImage.height + 1 + 20),
      expand: false,
    });

    this._participantTable = this.createPlayerTable(this.scene, this._battleData.participants, x, y, this.scale).layout();
    battlePanel.add(this._participantTable, {
      key: 'traits-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(166 + this._bossFightImage.height + 1 + 80),
      expand: false,
    });

    this.add(battlePanel, {
      key: 'battle-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private createPlayerTable(scene, data, x, y, scale) {
    var tableHeight = paneHeight - (166 + this._bossFightImage.height + 1 + 100);
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(paneWidth),
        height: scene.applyScale(tableHeight),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(itemWidth),
          cellHeight: this.applyScale(itemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            participant = cell.item;

          const participantName = shortenName(participant.playerName);

          const rectConfig = {
            maskType: 'roundRectangle',
            radius: scene.applyScale(40),
          };

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(20),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(itemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alpha
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )
            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth - 15), // width
                scene.applyScale(itemGlareHeight), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'center-top',
                offsetY: scene.applyScale(7),
                expand: false,
              },
            )
            .add(
              scene.rexUI.add.badgeLabel({
                x: 0,
                y: 0,
                space: { left: 0, right: -5, top: 0, bottom: 0 },
                main: scene.rexUI.add.label({
                  orientation: 'x',
                  rtl: false,
                  icon: scene.createSquadMemberImage(scene, participant.playerImageKey).setScale(scene.applyScale(0.3)),
                  iconWidth: scene.applyScale(38),
                  iconHeight: scene.applyScale(38),
                  text: scene.add.bitmapText(0, 0, 'cc_outline', participantName, scene.applyScale(20), 0).setAlpha(participant.inactive ? 0.4 : 1),
                  space: {
                    icon: scene.applyScale(10),
                    right: scene.applyScale(22),
                  },
                }),
                //rightCenter: participant.hasBattlePass ? scene.add.image(0, 0, 'battle_pass_icon') : undefined,
              }),
              {
                key: 'item_name_label',
                align: 'left-top',
                offsetX: scene.applyScale(21),
                offsetY: scene.applyScale(24),
                expand: false,
              },
            )

            // Attacking Status
            .add(
              scene.add
                .bitmapText(
                  0,
                  0,
                  'cc_outline',
                  scene.getGameState() == (GameState.PreGame || GameState.PreRound) && participant.isAttacking
                    ? 'Ready'
                    : scene.getGameState() == GameState.Round && participant.isAttacking == true
                    ? 'Attacking'
                    : '',
                  scene.applyScale(20),
                  0,
                )
                .setTint(scene.getGameState() == (GameState.PreRound || GameState.PreGame) ? '0XF5C957' : '0X2FE2A2'),
              {
                key: `status_label`,
                align: 'right-top',
                offsetX: scene.applyScale(-185),
                offsetY: scene.applyScale(32),
                expand: false,
              },
            )

            // .add(
            //     scene.rexUI.add.label({
            //         orientation: 'x',
            //         rtl: false,
            //         text: scene.add.bitmapText(0, 0, 'cc_outline', "Atacking...", scene.applyScale(20), 0).setTint(0X2FE2A2),
            //     }),
            //     {
            //         key: 'status_label',
            //         align: 'left-top',
            //         offsetX: scene.applyScale(170),
            //         offsetY: scene.applyScale(24),
            //         expand: false
            //     }
            // )

            // Damage per round
            .add(scene.add.bitmapText(0, 0, 'cc_outline', participant.damage.toLocaleString('en-us') + ' dmg per turn', scene.applyScale(18), 0), {
              key: `damage_label`,
              align: 'right-top',
              offsetX: scene.applyScale(-21),
              offsetY: scene.applyScale(32),
              expand: false,
            });

          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getGameState() {
    return this._battleData.getGameState();
  }

  public updateBattlePane(battleData) {
    this._battleData = battleData;

    // use gamestate to update correctly

    this._hpProgressBar.setProgressBarText(
      this._battleData.gameState == 0
        ? 'Currently Unknown...'
        : this._battleData.bossRemainingHP.toLocaleString('en-us') + ' of ' + this._battleData.bossMaxHP.toLocaleString('en-us'),
    );

    // Update Participant Number
    this._participantsText.setText(this.getParticipantsText(this._battleData));

    // Updated HP Progress bar based on gamestate
    this.updateHPProgressBar(this._battleData);

    // Set State Image
    this._stateImage.setTexture(this.getBossFightGameStatusImage(this._battleData.gameState));

    // Update Boss Rarity Image
    this._bossFightImage.setTexture(bossRarityImageMapper[this._battleData.rarity]);
  }

  public updateParticipantsList(battleData) {
    this._battleData = battleData;

    this._participantTable.setItems();

    // Sort Participants List by active or not
    this._participantTable.setItems(this._battleData.participants);

    // Update Participant Number
    this._participantsText.setText(this.getParticipantsText(this._battleData));
  }

  public setPlayerAttacking(player: any) {
    this._participantTable.items.forEach(item => {
      if (player.playerId === item.playerId) {
        item.isAttacking = true;
        this._participantTable.refresh();
        return;
      }
    });

    // Update Participant Number
    this._participantsText.setText(player.attackingCount + ' participants are fighting...');
  }

  private getParticipantsText(battleData) {
    switch (battleData.gameState) {
      case GameState.PreGame:
        return battleData.participantCount + ' participants have joined...';
      case GameState.ProcessResults:
      case GameState.DisplayResults:
        return battleData.activeParticipants + ' participants fought';
      default:
        var length = battleData.gameState == 0 ? battleData.activeParticipants : battleData.attackingCount;
        return length + ' participants are fighting...';
    }
  }

  private getBossFightGameStatusImage(gameState) {
    return gameState == GameState.PreGame ? 'bossfight_status_not_started' : 'bossfight_status_in_progress';
  }

  private updateHPProgressBar(battleData) {
    if (battleData.gameState == GameState.PreGame) {
      this._hpProgressBar.updateMaxValue(this, battleData.bossMaxHP);
      this._hpProgressBar.setProgressBarText('Currently Unknown...');
    } else {
      this._hpProgressBar.setProgress(this, battleData.bossRemainingHP);
      this._hpProgressBar.setProgressBarText(
        this._battleData.bossRemainingHP.toLocaleString('en-us') + ' of ' + this._battleData.bossMaxHP.toLocaleString('en-us'),
      );
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('bossFightBattlePane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightBattlePane(this.scene, x, y));
});
