import Phaser from 'phaser';
import { FixWidthSizer, OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import ConfigurationService from '~/services/ConfigurationService';
import MachineService from '~/services/MachineService';
import TroopService from '~/services/TroopService';
import Button from '../buttons/Button';
import ProgressBar from '../progressBar/ProgressBar';

const cellWidth = 64;

const upgradeProgressBarColor = 0xd3007f;
const activeProgressBarColor = 0x32d96c; //0x21e968; //0xDEA827;
const coolDownProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.22;
const glareRadius = 2;
const glareHeight = 4;
const glareOffset = 8;
const backgroundColor = 0x0b0b1f;
const backgroundAlpha = 0.4;
const radius = 7;

const progressBarHeight = 14;
const progressBarWidth = 105;

const progressMin = 0;
const progressUpdateDelay = 30; //seconds

const tintColor = 0xcdcdcd;
const machineDestroyedTintColor = 0x202020;

const currencyColors = {
  neon_gold: 0xffe100,
  space_elixir: 0x00d4f7,
  stardust: 0xedffa6,
};

const machineTypes = {
  mainframe: 'mainframe',
  space_elixir_storage: 'space_elixir_storage',
  space_elixir_mine: 'space_elixir_mine',
  neon_gold_storage: 'neon_gold_storage',
  neon_gold_mine: 'neon_gold_mine',
  star_lab: 'star_laboratory',
  mother_wolf_statue: 'mother_wolf_statue',
  little_lemon_tree: 'little_lemon_tree',
  space_elixir_accelerator: 'space_elixir_accelerator',
  neon_gold_accelerator: 'neon_gold_accelerator',
  kuma_ancestor_shrine: 'kuma_ancestor_shrine',
};

const progressBarTypes = {
  upgrade: 'upgrade',
  active: 'active',
  cool_down: 'cool_down',
  none: 'none',
};

const trainingMachineIds = [
  // 1033, // Barracks
  // 1034, // Cadet Dorms
  1035, // Mercenary Wolvez Hideout
  1036, // Anti-Rat Squad Department
  1037, // Super Swole Squad Department
  1038, // Medical Academy
];

const troopsMachineIds = [1034];

const starLabActiveTime = 1800;
const starLabMachineID = 1007;

export default class HomebaseMachine extends OverlapSizer {
  public _machine;
  private _machineImage;
  private _machineLevel;
  private _machineInfoSpacing;
  private _destroyedBolt;
  private _progressBar;
  private _progressBarType;
  private _progressMax;
  private _progressLabel;
  private _updateTimeEvent;
  private _cellX;
  private _cellY;
  private _dialog;
  private _scene;
  private _isMaxLevel;
  private _buildProgressMultiplier;
  private _config;

  constructor(
    scene: Phaser.Scene,
    cellX: number,
    cellY: number,
    machineData: any,
    buildProgressMultiplier: number,
    widthInTiles: number,
    config?: any,
  ) {
    let x = cellWidth * cellX + (cellWidth * widthInTiles) / 2;
    let y = cellWidth * cellY + cellWidth;

    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: cellWidth,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          machine_info_spacing: {
            left: 10,
            right: 10,
            top: 5,
            bottom: 10,
          },
        };

    super(scene, x, y, conf);

    this._scene = scene;
    this._cellX = cellX;
    this._cellY = cellY;
    this._machine = machineData;
    this._machineInfoSpacing = conf.machine_info_spacing;
    this._machineLevel = machineData.level || 1;
    this._progressBarType = this.getProgressBarType();
    this._progressMax = this.getProgressMax();
    this._isMaxLevel = !this._machine.next_level_build_total_time || this._machine.next_level_build_total_time <= 0;
    this._buildProgressMultiplier = buildProgressMultiplier;
    this._config = ConfigurationService.getInstance().getConfig();

    this.createComponent(scene, x, y);
  }

  createComponent(scene: Phaser.Scene, x: number, y: number) {
    var self = this;
    // Machine Image
    this._machineImage = this.getMachineImage(scene);

    this.add(this._machineImage, {
      key: 'machine_image',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    })
      .setInteractive({ cursor: 'pointer' })
      .on(
        Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
        pointer => {
          this._machineImage.setAlpha(0.7);
        },
        this,
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
        this._machineImage.setAlpha(1);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
        this._machineImage.setAlpha(1);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
        this._machineImage.setAlpha(0.7);
        if (pointer.getDistance() > 16) {
          return;
        }
        if (!this._dialog) {
          this.createDialog(scene, this._machineImage.getTopCenter().x, this._machineImage.getTopCenter().y - 3);
        }
      });

    // Lightning Bolt
    this._destroyedBolt = this._scene.add.image(0, 0, 'starwolvez_bolt_logo').setScale(0.5).setAlpha(0);
    this.add(this._destroyedBolt, {
      key: 'bolt_image',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Progress Bar
    this.handleProgressBar(scene);

    scene.input.setDraggable(this);

    this.layout();

    this.alignBottom(y);

    // this.drawBounds(scene.add.graphics(), 0xff0000);
  }

  private getMachineImage(scene: Phaser.Scene) {
    var machineAni = this._config.machines.find(mach => {
      var level = this._machine.level === 0 ? 1 : this._machine.level;

      return mach.key === this._machine.key && mach.level === level;
    });

    if (!machineAni) {
      // No config found for this machine/level.  Use highest level image available.
      var matchingMachines = this._config.machines
        .filter(mach => {
          return mach.key === this._machine.key;
        })
        ?.sort((a, b) => {
          if (a.level < b.level) {
            return 1;
          }
          if (a.level > b.level) {
            return -1;
          }
          return 0;
        });

      if (matchingMachines.length > 0) {
        machineAni = matchingMachines[0];
      }
    }

    if (machineAni.type === 'animation') {
      var keyWithLevel = `${machineAni.key}_level_${machineAni.level}`;

      var animationConfig = {
        key: `${keyWithLevel}_animation`,
        frames: scene.anims.generateFrameNumbers(keyWithLevel, {
          start: 0,
          end: machineAni.numFrames - 1,
          first: 0,
        }),
        frameRate: machineAni.frameRate,
        repeat: machineAni.repeat,
        repeatDelay: machineAni.repeatDelay,
      };

      scene.anims.create(animationConfig);
      return scene.physics.add.sprite(0, 0, keyWithLevel).setOrigin(0.5, 1).play(`${keyWithLevel}_animation`);
    } else {
      // Applies Skin
      if (this._machine.has_skin === 1) {
        return scene.physics.add.sprite(0, 0, machineAni.imageKey + '_skin').setOrigin(0.5, 1);
      }
      return scene.physics.add.sprite(0, 0, machineAni.imageKey).setOrigin(0.5, 1);
    }
  }

  private handleProgressBar(scene: Phaser.Scene) {
    switch (this._progressBarType) {
      case progressBarTypes.upgrade:
        this.createProgressBar(scene, progressBarTypes.upgrade);

        this._updateTimeEvent = scene.time.addEvent({
          delay: progressUpdateDelay * 1000,
          callback: this.updateTimeHandler,
          callbackScope: this,
          loop: true,
        });

        this.updateProgress(this._machine.star_lab_adjusted_time_until_next_level, progressBarTypes.upgrade);
        break;
      case progressBarTypes.active:
        this.createProgressBar(scene, progressBarTypes.active);

        this._updateTimeEvent = scene.time.addEvent({
          delay: progressUpdateDelay * 1000,
          callback: this.updateActiveTimeHandler,
          callbackScope: this,
          loop: true,
        });
        break;
      case progressBarTypes.cool_down:
        this._machine.cool_down_time_remaining = this.getRemainingCoolDownTime(this._machine);
        this.createProgressBar(scene, progressBarTypes.cool_down);

        this._updateTimeEvent = scene.time.addEvent({
          delay: progressUpdateDelay * 1000,
          callback: this.updateCoolDownTimeHandler,
          callbackScope: this,
          loop: true,
        });
        break;
      default:
        return;
    }
  }

  private getProgressBarType() {
    const slatunl = this._machine.star_lab_adjusted_time_until_next_level;
    const tui = this._machine.time_until_inactive;
    const cdt = this._machine.cool_down_time;

    const isUpgrade = this._machine.star_lab_adjusted_time_until_next_level && this._machine.star_lab_adjusted_time_until_next_level > 0;
    const isActive = this._machine.time_until_inactive && this._machine.time_until_inactive > 0;
    const isCoolDown = this._machine.cool_down_time && this.getRemainingCoolDownTime(this._machine) > 0;

    return isActive
      ? progressBarTypes.active
      : isCoolDown
      ? progressBarTypes.cool_down
      : isUpgrade
      ? progressBarTypes.upgrade
      : progressBarTypes.none;
  }

  private getProgressMax() {
    switch (this._progressBarType) {
      case progressBarTypes.upgrade:
        return this._machine.next_level_build_total_time;
      case progressBarTypes.active:
        return starLabActiveTime;
      case progressBarTypes.cool_down:
        return this._machine.cool_down_time;
      default:
        return;
    }
  }

  private createProgressBar(scene, type) {
    let config;
    switch (type) {
      case progressBarTypes.upgrade:
        config = {
          progressValue: this._progressMax - parseInt(this._machine.star_lab_adjusted_time_until_next_level),
          titleText: this._machine.star_lab_adjusted_time_until_next_level,
          color: upgradeProgressBarColor,
        };
        break;
      case progressBarTypes.active:
        config = {
          progressValue: this._machine.time_until_inactive,
          titleText: this._machine.time_until_inactive,
          color: activeProgressBarColor,
        };
        break;
      case progressBarTypes.cool_down:
        config = {
          progressValue: this._machine.cool_down_time_remaining,
          titleText: this._machine.cool_down_time_remaining,
          color: coolDownProgressBarColor,
        };
        break;
    }

    if (!config) {
      return;
    }

    // Progress Bar
    this._progressBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: this.secondsToTime(config.titleText),
          fontStyle: null,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: config.color,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressMin,
          maxValue: this._progressMax,
          progressValue: config.progressValue,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
    scene.add.existing(this._progressBar);
    this._progressBar.setDepth(this.depth);

    this.add(this._progressBar, {
      key: 'machine_progress_bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: -25,
      expand: false,
    }).layout();
  }

  private secondsToTime(seconds: number) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var hDisplay = h > 0 ? `${h}H` : '';
    var mDisplay = m > 0 ? `${m}M` : '';
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    if (h === 0 && m === 0 && s > 0) {
      return '< 1m';
    }
    return h > 0 ? `${hDisplay} ${mDisplay}` : mDisplay;
  }

  updateTimeHandler() {
    const timeReduction = progressUpdateDelay * this._buildProgressMultiplier;
    this._machine.star_lab_adjusted_time_until_next_level = this._machine.star_lab_adjusted_time_until_next_level - timeReduction;

    if (this._machine.star_lab_adjusted_time_until_next_level <= 0) {
      this._updateTimeEvent.remove(false); // false = don't call this function again
      this.removeProgressBar();
      this.scene.scene.get('HomebaseScene').updateMachines();
    } else {
      let progressValue = this._machine.star_lab_adjusted_time_until_next_level;
      this.updateProgress(progressValue, progressBarTypes.upgrade);
    }
  }

  updateActiveTimeHandler() {
    this._machine.time_until_inactive = this._machine.time_until_inactive - progressUpdateDelay;
    if (this._machine.time_until_inactive <= 0) {
      this._updateTimeEvent.remove(false); // false = don't call this function again
      this.removeProgressBar();
      this.scene.scene.get('HomebaseScene').updateMachines(true);
    } else {
      let progressValue = this._machine.time_until_inactive;
      this.updateProgress(progressValue, progressBarTypes.active);
    }
  }

  updateCoolDownTimeHandler() {
    this._machine.cool_down_time_remaining = this._machine.cool_down_time_remaining - progressUpdateDelay;
    if (this._machine.cool_down_time_remaining <= 0) {
      this._updateTimeEvent.remove(false); // false = don't call this function again
      this.removeProgressBar();
      this.scene.scene.get('HomebaseScene').updateMachines(true);
    } else {
      let progressValue = this._machine.cool_down_time_remaining;
      this.updateProgress(progressValue, progressBarTypes.cool_down);
    }
  }

  updateProgressText(type) {
    let value = type === progressBarTypes.upgrade ? this._machine.star_lab_adjusted_time_until_next_level : this._machine.time_until_inactive;
    switch (type) {
      case progressBarTypes.upgrade:
        value = this._machine.star_lab_adjusted_time_until_next_level;
        break;
      case progressBarTypes.active:
        value = this._machine.time_until_inactive;
        break;
      case progressBarTypes.cool_down:
        value = this._machine.cool_down_time_remaining;
        break;
      default:
        return;
    }
    this._progressBar.setTitle(this.secondsToTime(value));
  }

  updateProgress(progressValue, type) {
    this._progressBar.setProgress(this._scene, progressValue > 0 ? progressValue : 1);
    this.updateProgressText(type);
  }

  private getRemainingCoolDownTime(machine: any) {
    const cdt = machine.cool_down_time;
    const now = Math.floor(Date.now() / 1000);
    const la = machine.last_activated;

    const elapsedTime = now - la;
    var remainingTime = cdt - elapsedTime;

    // Account for length of time starLab is active
    if (machine.machine_id === starLabMachineID) {
      remainingTime = remainingTime + starLabActiveTime;
    }

    return remainingTime > 0 ? remainingTime : 0;
  }

  createDialog(scene, x, y) {
    this._dialog = scene.rexUI.add
      .dialog({
        x: x,
        y: y,
        //width: 200,

        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 16, 0x201425).setAlpha(0.7).setStrokeStyle(2, 0x000000, 0.9).setInteractive(),

        title: this.createTitle(scene, this.getMachineTitle()),

        //content: this.createContent(scene),//this.createTitle(scene, `Level: ${this._machine.level}\n\nYield: N/A\n\nStorage: N/A`, 18, 0.4),

        //description: this.createTitle(scene, 'Yield: N/A\nStorage: N/A', 18, 0.4),

        actions: this.getActions(
          scene,
          this._machine.key === machineTypes.star_lab ||
            this._machine.key === machineTypes.neon_gold_accelerator ||
            this._machine.key === machineTypes.space_elixir_accelerator,
          this._machine.key === machineTypes.little_lemon_tree ||
            this._machine.key === machineTypes.mother_wolf_statue ||
            this._machine.key === machineTypes.kuma_ancestor_shrine,
        ),

        // actions: [
        //     this.createButton(scene, 'Action0'),
        //     this.createButton(scene, 'Action1')
        // ],

        space: {
          left: this._machineInfoSpacing.left,
          right: this._machineInfoSpacing.right,
          top: this._machineInfoSpacing.top,
          bottom: this._machineInfoSpacing.bottom,

          title: 10,
          // titleLeft: 30,
          //titleBottom: 20,
          //content: 20,
          //contentLeft: 10,
          //description: 20,
          //descriptionLeft: 10,
          // descriptionRight: 20,
          //descriptionBottom: 20,
          //choices: 20,
          actions: 10,

          //toolbarItem: 5,
          //choice: 15,
          action: 10,
        },

        expand: {
          title: false,
          // content: false,
          // description: true,
          choices: false,
          actions: false,
        },

        align: {
          title: 'center',
          content: 'left',
          description: 'left',
          choices: 'center',
          actions: 'center', // 'center'|'left'|'right'
        },

        click: {
          mode: 'release',
        },
      })
      .setOrigin(0.5, 1)
      .setDepth(1000)
      //.setDraggable('background')   // Draggable-background
      .layout()
      // .drawBounds(this.add.graphics(), 0xff0000)
      .popUp(200);

    var tween = scene.tweens.add({
      targets: this._dialog,
      scaleX: 1,
      scaleY: 1,
      ease: 'Bounce', // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 200,
      repeat: 0, // -1: infinity
      yoyo: false,
    });

    scene.input.on(
      'pointerdown',
      function (pointer) {
        if (this._dialog && !this._dialog.isInTouching(pointer)) {
          this._dialog.scaleDownDestroy(100);
          this._dialog = undefined;
        }
      },
      this,
    );
  }

  getMachineTitle() {
    if (this._machine.key === 'tournament_one_competitor_trophy' || this._machine.key === 'discord_airdrop_trophy') {
      return this._machine.display_name.toUpperCase();
    }
    return `${this._machine.display_name.toUpperCase()} (LEVEL ${this._machine.level})`;
  }

  createButton(scene: Phaser.Scene, text: string, wrap?: any) {
    var background = scene.rexUI.add.roundRectangle(0, 0, 100, 40, 10, 0x6501b4);
    var textObj = scene.add.bitmapText(0, 0, 'cc_outline', text, 20);
    if (wrap) {
      textObj = scene.rexUI.wrapExpandText(textObj);
    }

    const label = scene.rexUI.add.label({
      width: 40, // Minimum width of round-rectangle
      height: 40, // Minimum height of round-rectangle

      background: background,

      text: textObj,
      //expandTextWidth: wrap,
      align: 'center',

      space: {
        left: 10,
        right: 10,
        top: 12,
        bottom: 10,
      },
    });

    if (this._machine.star_lab_adjusted_time_until_next_level && this._machine.star_lab_adjusted_time_until_next_level > 0) {
      label.setText('Upgrading...');
      label.disableInteractive();
    } else {
      label.setInteractive({ cursor: 'pointer' });
    }

    return label;
  }

  protected getActions(scene: Phaser.Scene, canActivate: Boolean, canClaim: Boolean) {
    let actions: Array<Button> = [];

    // Info
    actions.push(this.createInfoButton(scene));

    if (this._machine.star_lab_adjusted_time_until_next_level > 0) {
      // Speed Up
      actions.push(this.createSpeedUpButton(scene));
    } else {
      // Upgrade
      actions.push(this.createUpgradeButton(scene));
    }

    // Activate
    if (canActivate) {
      actions.push(this.createActivateButton(scene));
    }

    // Claim
    if (canClaim) {
      actions.push(this.createClaimButton(scene));
    }

    // Train
    if (trainingMachineIds.includes(this._machine.machine_id)) {
      actions.push(this.createTrainButton(scene));
    }

    // Troops
    if (troopsMachineIds.includes(this._machine.machine_id)) {
      actions.push(this.createTroopsButton(scene));
    }

    return actions;
  }

  private createInfoButton(scene: Phaser.Scene) {
    var infoButton = new Button(scene, 0, 0, 'machine_info_button', false);
    scene.add.existing(infoButton);

    infoButton.onClick().subscribe(pointer => {
      this._dialog.scaleDownDestroy(10);
      this._dialog = undefined;
      scene.scene.pause('HudScene');
      scene.scene.get('HomebaseScene').input.enabled = false;
      scene.scene.launch('MachineUpgradeModalScene', {
        userMachine: this._machine,
      });
    });

    return infoButton;
  }

  private createUpgradeButton(scene: Phaser.Scene) {
    var upgradeButton = new Button(scene, 0, 0, 'machine_upgrade_button', false);
    scene.add.existing(upgradeButton);

    if (this._isMaxLevel || this._machine.star_lab_adjusted_time_until_next_level > 0 || this._machine.time_until_inactive > 0) {
      upgradeButton.setDisabled(true);
      upgradeButton.setAlpha(0.5);
    } else {
      upgradeButton.onClick().subscribe(pointer => {
        upgradeButton.setDisabled(true);
        this._dialog.scaleDownDestroy(10);
        this._dialog = undefined;
        scene.scene.pause('HudScene');
        scene.scene.get('HomebaseScene').input.enabled = false;
        scene.scene.launch('ShopPurchaseConfirmScene', {
          item: this._machine,
          isUpgrade: true,
        });
      });
    }

    return upgradeButton;
  }

  private createSpeedUpButton(scene: Phaser.Scene) {
    var speedUpButton = new Button(scene, 0, 0, 'machine_speed_up_button', false);
    scene.add.existing(speedUpButton);

    speedUpButton.onClick().subscribe(pointer => {
      speedUpButton.setDisabled(true);
      this._dialog.scaleDownDestroy(10);
      this._dialog = undefined;

      scene.scene.get('HomebaseScene').input.enabled = false;
      scene.scene.pause('HudScene');
      scene.scene.launch('MachineSpeedUpModalScene', {
        machine: this._machine,
        homebaseMachine: this,
      });
    });

    return speedUpButton;
  }

  private createActivateButton(scene: Phaser.Scene) {
    var activateButton = new Button(scene, 0, 0, 'machine_activate_button', false);
    scene.add.existing(activateButton);

    if (this._machine.time_until_inactive > 0 || this.getRemainingCoolDownTime(this._machine) > 0) {
      activateButton.setDisabled(true);
      activateButton.setAlpha(0.5);
    } else {
      activateButton.onClick().subscribe(pointer => {
        activateButton.setDisabled(true);
        this._dialog.scaleDownDestroy(10);
        this._dialog = undefined;
        MachineService.getInstance()
          .activateMachine(this._machine.user_machine_id)
          .then(res => {
            scene.scene.get('HomebaseScene').input.enabled = false;
            scene.scene.pause('HudScene');
            scene.scene.launch('ShopPurchaseResultScene', {
              purchaseResult: res,
              item: this._machine,
              itemType: 'machine_activation',
            });
          });
      });
    }

    return activateButton;
  }

  private createClaimButton(scene: Phaser.Scene) {
    var claimButton = new Button(scene, 0, 0, 'machine_claim_button', false);
    scene.add.existing(claimButton);

    if (this._machine.cool_down_time_remaining > 0) {
      claimButton.setDisabled(true);
      claimButton.setAlpha(0.5);
    } else {
      claimButton.onClick().subscribe(pointer => {
        claimButton.setDisabled(true);
        this._dialog.scaleDownDestroy(10);
        this._dialog = undefined;
        MachineService.getInstance()
          .claimResources(this._machine.user_machine_id)
          .then(res => {
            scene.scene.get('HomebaseScene').input.enabled = false;
            scene.scene.pause('HudScene');
            scene.scene.launch('ShopPurchaseResultScene', {
              purchaseResult: res,
              item: this._machine,
              itemType: 'machine_claim',
            });
          });
      });
    }

    return claimButton;
  }

  private createTrainButton(scene: Phaser.Scene) {
    var trainButton = new Button(scene, 0, 0, 'machine_train_button', false);
    scene.add.existing(trainButton);

    trainButton.onClick().subscribe(pointer => {
      trainButton.setDisabled(true);
      this._dialog.scaleDownDestroy(10);
      this._dialog = undefined;

      scene.scene.get('HomebaseScene').input.enabled = false;
      scene.scene.pause('HudScene');
      scene.scene.launch('ArmyModalScene', {
        machine: { user_machine_id: 0, machine_id: 0 },
      });
    });

    return trainButton;
  }

  private createTroopsButton(scene: Phaser.Scene) {
    var troopsButton = new Button(scene, 0, 0, 'machine_troops_button', false);
    scene.add.existing(troopsButton);

    troopsButton.onClick().subscribe(pointer => {
      troopsButton.setDisabled(true);
      this._dialog.scaleDownDestroy(10);
      this._dialog = undefined;
      TroopService.getInstance()
        .getTroopsByMachine(0, true)
        .then(res => {
          TroopService.getInstance()
            .getTrainableTroopsByMachine(0)
            .then(tt => {
              scene.scene.get('HomebaseScene').input.enabled = false;
              scene.scene.pause('HudScene');
              scene.scene.launch('ArmyModalScene', {
                machine: { user_machine_id: 0, machine_id: 0 },
                tab: 'army',
              });
            });
        });
    });

    return troopsButton;
  }

  private createTitle(scene: Phaser.Scene, text: string, fontSize?: number, textAlpha?: any, wrap?: any) {
    fontSize = fontSize || 14;
    textAlpha = textAlpha || 1;

    var textObj = scene.add.bitmapText(0, 0, 'cc_outline', text, fontSize).setAlpha(textAlpha);

    return scene.rexUI.add.label({
      width: 120, // Minimum width of round-rectangle
      text: textObj,
      expandTextWidth: wrap,
      align: 'center',

      space: {
        left: 0,
        right: 0,
        top: 8,
        bottom: 0,
      },
    });
  }

  getMachine() {
    return this._machine;
  }

  public setCell(cellX, cellY) {
    this._cellX = cellX;
    this._cellY = cellY;
  }

  private updateMachineImage(scene: Phaser.Scene, alignY: boolean) {
    // Stop sprite
    this._machineImage.stop();

    // Get Machine Image config
    var machineAni = this._config.machines.find(mach => {
      var level = this._machine.level === 0 ? 1 : this._machine.level;

      return mach.key === this._machine.key && mach.level === level;
    });

    if (!machineAni) {
      // No config found for this machine/level.  Use highest level image available.
      var fallbackConfig;
      var matchingMachines = this._config.machines
        .filter(mach => {
          return mach.key === this._machine.key;
        })
        ?.sort((a, b) => {
          if (a.level < b.level) {
            return 1;
          }
          if (a.level > b.level) {
            return -1;
          }
          return 0;
        });

      if (matchingMachines.length > 0) {
        fallbackConfig = matchingMachines[0];
      }

      if (fallbackConfig.type === 'animation') {
        this.setAnimation(scene, fallbackConfig);
      } else {
        this._machineImage.setTexture(fallbackConfig.imageKey);
      }
    } else {
      if (machineAni.type === 'animation') {
        this.setAnimation(scene, machineAni);
      } else {
        this._machineImage.setTexture(machineAni.imageKey);
      }
    }

    this.layout();

    if (alignY) {
      this.alignBottom(cellWidth * this._cellY + cellWidth);
    }

    // this.drawBounds(scene.add.graphics(), 0xffffff);
  }

  private setAnimation(scene: Phaser.Scene, machineAni: any) {
    var keyWithLevel = `${machineAni.key}_level_${machineAni.level}`;

    var animationConfig = {
      key: `${keyWithLevel}_animation`,
      frames: scene.anims.generateFrameNumbers(keyWithLevel, {
        start: 0,
        end: machineAni.numFrames - 1,
        first: 0,
      }),
      frameRate: machineAni.frameRate,
      repeat: machineAni.repeat,
      repeatDelay: machineAni.repeatDelay,
    };

    scene.anims.create(animationConfig);
    this._machineImage
      .setTexture(keyWithLevel)
      //.setOrigin(0.5, 1)
      .play(`${keyWithLevel}_animation`);
  }

  private removeProgressBar() {
    if (this._progressBar) {
      this._progressBar.setAlpha(0);
      this.remove(this._progressBar);
      this._progressBar.destroy();
      this._progressBar = undefined;
      // this.layout();
    }
  }

  handleUpdate(machineData: any, buildProgressMultiplier: number, isDragging: boolean) {
    if (machineData) {
      // Perform equality checks
      const shouldUpdateImage = this._machine.level !== machineData.level;
      var shouldCreateProgBar = false;
      var shouldRemoveProgBar = false;

      const new_slatunl = machineData.star_lab_adjusted_time_until_next_level;
      const new_tui = machineData.time_until_inactive;
      const new_cdt = this.getRemainingCoolDownTime(machineData);

      if (((new_tui && new_tui > 0) || (new_slatunl && new_slatunl > 0) || (new_cdt && new_cdt > 0)) && !this._progressBar) {
        shouldCreateProgBar = true;
      }

      if ((!new_tui || new_tui === 0) && (!new_slatunl || new_slatunl === 0) && (!new_cdt || new_cdt === 0) && this._progressBar) {
        shouldRemoveProgBar = true;
      }

      // Set new machine data
      this._machine = machineData;

      this._machineLevel = machineData.level || 1;
      this._progressBarType = this.getProgressBarType();
      this._progressMax = this.getProgressMax();
      this._buildProgressMultiplier = buildProgressMultiplier;
      this._machine.cool_down_time_remaining = this.getRemainingCoolDownTime(this._machine);

      this._isMaxLevel = !this._machine.next_level_build_total_time || this._machine.next_level_build_total_time <= 0;

      // Perform necessary updates
      if (shouldUpdateImage) {
        this.updateMachineImage(this._scene, true);
      }

      if (shouldRemoveProgBar) {
        this._progressBarType = this.getProgressBarType();
        this.removeProgressBar();
      }

      if (shouldCreateProgBar) {
        this.handleProgressBar(this._scene);
      }
    }
  }

  setDestroyed(isDestroyed: boolean) {
    if (isDestroyed) {
      this._machineImage.setTint(machineDestroyedTintColor);
      this._machineImage.stop();
      this._destroyedBolt.setAlpha(1);
    } else {
      this._machineImage.clearTint();
      this._machineImage.anims.restart();
      this._destroyedBolt.setAlpha(0);
    }

    this.layout();
  }

  removeTimeEvents() {
    this._updateTimeEvent.remove(false);
  }

  destroy() {
    if (this) {
      this.setAlpha(0);
    }
    if (this._updateTimeEvent) {
      this._updateTimeEvent.remove(false);
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register(
  'homebaseMachine',
  function (x: number, y: number, machineData: any, widthInTiles: number, config: any) {
    // @ts-ignore
    return this.displayList.add(new HomebaseMachine(this.scene, x, y, machineData, widthInTiles, config));
  },
);
