import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import HelpDeskService from '~/services/HelpDeskService';

export default class HelpdeskContentPane extends OverlapSizer {
  private _table;
  private _scale;
  private _selectedCategory;
  private _helpOptions;
  private _titleText;

  constructor(scene: Phaser.Scene, x: number, y: number, category: any, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          width: 566 * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._selectedCategory = category;
    this._helpOptions = HelpDeskService.getInstance().getHelpOptions();

    // Title Text
    this._titleText = scene.add
      .bitmapText(x - this.applyScale(259), y - this.applyScale(234), 'cc_outline', this._selectedCategory.displayName, this.applyScale(22), 0)
      .setOrigin(0);

    // Options table
    this._table = this.createTable(
      scene,
      this._helpOptions.filter(ho => {
        return ho.type === this._selectedCategory.key;
      }),
      x,
      y + this.applyScale(5),
      this._scale,
    ).layout();

    this.add(this._titleText);
    this.add(this._table);
    this.setDirty(false);
  }

  applyScale(length) {
    return length * this._scale;
  }

  updateTable(data?: any) {
    if (data) {
      this._table.setItems(data);
    }
    this._table.layout();
  }

  createTable(scene, data, x, y, paneScale) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(530),
      height: this.applyScale(392),
      items: data,

      table: {
        cellWidth: this.applyScale(265),
        cellHeight: this.applyScale(180),
        columns: 2,
        clamplTableOXY: false,
        reuseCellContainer: true,
        scrollMode: 0,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        var rectConfig = {
          maskType: 'roundRectangle',
          radius: scene.applyScale(25),
        };

        var itemBg = scene.add.rexCircleMaskImage(0, 0, item.bg_image_key, '', rectConfig);
        itemBg.displayHeight = scene.applyScale(145);
        itemBg.scaleX = itemBg.scaleY;

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(235),
            height: scene.applyScale(145),
            space: {
              left: scene.applyScale(15),
              right: scene.applyScale(0),
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .add(itemBg, {
            key: 'help_option_bg',
            align: 'center',
            expand: false,
          })
          .add(scene.add.image(0, 0, item.label_image_key).setScale(scene.getScale()), {
            key: 'help_option_label',
            align: 'center',
            expand: false,
          })
          .layout();

        if (item.enabled) {
          sizer.setInteractive({ cursor: 'pointer' });

          sizer.setChildrenInteractive({
            click: { mode: 'release', clickInterval: 100 },
            over: true,
            tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
            press: { time: 251, threshold: 9 },
            swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
            inputEventPrefix: 'child.',
          });

          sizer.on('child.over', (child, pointer, event) => {
            sizer.getElement('help_option_bg').setScale(sizer.getElement('help_option_bg').scaleX * 1.04);
            sizer.getElement('help_option_label').setScale(sizer.getElement('help_option_bg').scaleX * 1.03);
          });

          sizer.on('child.out', (child, pointer, event) => {
            sizer.getElement('help_option_bg').setScale(sizer.getElement('help_option_bg').scaleX / 1.04);
            sizer.getElement('help_option_label').setScale(sizer.getElement('help_option_bg').scaleX / 1.03);
          });
        } else {
          sizer.disableInteractive();
          sizer.setAlpha(0.3);
        }

        sizer.onClick((button, gameObject, pointer, event) => {
          // Open target url in new tab
          window.open(item.target_url, '_blank')?.focus();
        }, this);

        return scene.add.existing(sizer);
      },
    });

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }

  setCategory(selectedCategory: any) {
    console.log(util.inspect(selectedCategory));
    if (selectedCategory) {
      this._selectedCategory = selectedCategory;
      this._titleText.setText(selectedCategory.displayName);

      this._table
        .setItems(
          this._helpOptions.filter(ho => {
            return ho.type === selectedCategory.key;
          }),
        )
        .scrollToTop();
      this._table.layout();
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('helpdeskContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new HelpdeskContentPane(this.scene, x, y));
});
