import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import util from 'util';
import { Leaderboard_Assets } from '~/utils/AssetLoader';
import PVPBattleService from '~/services/PVPBattleService';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 800;
const modalWidth = 638;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

const leaderItemWidth = modalWidth - 38;
const leaderItemHeight = 73;
const leaderItemRadius = 16;
const leaderItemColor = 0x262637;
const leaderItemAlpha = 1;

const leaderItemGlareWidth = leaderItemWidth - 30;
const leaderItemGlareHeight = 7;
const leaderItemGlareRadius = 3;
const leaderItemGlareColor = 0xffffff;
const leaderItemGlareAlpha = 0.08;

const numWinsColor = 0xff8ca8;

const messageColor = 0xffffff;
const victoryIcon = 'pvp_victory_icon';
const leaderboardTitle = 'leaderboard_title';
const leaderboardDivider = 'leaderboard_divider';

const categories = {
  pvp: 'pvp',
  stardust: 'stardust',
  logins: 'logins',
  pve: 'pve',
  xp: 'xp',
  mining: 'mining',
  base_raid: 'base_raid',
};

export default class LeaderboardScene extends Phaser.Scene {
  private _leaders;
  private _scale;
  private _pvpButton;
  private _stardustButton;
  private _loginsButton;
  private _pveButton;
  private _xpButton;
  private _miningButton;
  private _baseRaidButton;
  public _selectedCategory;
  private _loadingIndicator;
  private _table;

  constructor() {
    super({
      key: 'LeaderboardScene',
    });
  }

  preload() {
    LoadImageAssets(this, Leaderboard_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100); // 100 accounts for icons and button extending beyond modal box

    if (this.game.input.touch && this.game.input.touch.preventDefault) {
      this.game.input.touch.preventDefault = false;
      this.game.input.touch.enabled = true;
    }

    this.input.topOnly = false;
    this.input.setDefaultCursor('default');
    this._selectedCategory = categories.pvp;

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    // Add Icon
    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, victoryIcon).setScale(this._scale * 0.5);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add Title Text
    sizer.add(this.add.image(0, 0, leaderboardTitle).setScale(this._scale), {
      padding: {
        left: 0,
        right: 0,
        top: this.applyScale(43),
        bottom: this.applyScale(24),
      },
      key: 'title_text',
    });

    // Add Divider
    sizer.add(this.add.image(0, 0, leaderboardDivider).setScale(this._scale), {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: this.applyScale(18),
      },
      key: 'upper_divider',
    });

    // Buttons Sizer
    var buttonSizer = this.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(modalWidth),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Pvp Button
    this._pvpButton = new Button(this, 0, 0, 'leaderboard_pvp_button', false).setOrigin(0, 0.5).setScale(this._scale);

    this._pvpButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.pvp;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.wins, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._pvpButton), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'pvp_button',
    });

    // Stardust Button
    this._stardustButton = new Button(this, 0, 0, 'leaderboard_stardust_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._stardustButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.stardust;
      this._stardustButton.setTexture('leaderboard_stardust_button');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.stardust, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._stardustButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'stardust_button',
    });

    // Logins Button
    this._loginsButton = new Button(this, 0, 0, 'leaderboard_logins_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._loginsButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.logins;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.logins, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._loginsButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'logins_button',
    });

    // Logins Button
    this._pveButton = new Button(this, 0, 0, 'leaderboard_pve_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._pveButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.pve;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(
        this,
        this._leaders.pve_wins.sort((a, b) => {
          if (a.rounds_played < b.rounds_played) {
            return 1;
          }
          if (a.rounds_played > b.rounds_played) {
            return -1;
          }
          return 0;
        }),
        0,
        0,
      ).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._pveButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'pve_button',
    });

    // XP Button
    this._xpButton = new Button(this, 0, 0, 'leaderboard_xp_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._xpButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.xp;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.xp, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._xpButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'xp_button',
    });

    // Mining Button
    this._miningButton = new Button(this, 0, 0, 'leaderboard_mining_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._miningButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.mining;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.mining, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._miningButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'mining_button',
    });

    // Base Raid Button
    this._baseRaidButton = new Button(this, 0, 0, 'leaderboard_base_raid_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._baseRaidButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.base_raid;
      this._stardustButton.setTexture('leaderboard_stardust_button_inactive');
      this._pvpButton.setTexture('leaderboard_pvp_button_inactive');
      this._loginsButton.setTexture('leaderboard_logins_button_inactive');
      this._pveButton.setTexture('leaderboard_pve_button_inactive');
      this._xpButton.setTexture('leaderboard_xp_button_inactive');
      this._miningButton.setTexture('leaderboard_mining_button_inactive');
      this._baseRaidButton.setTexture('leaderboard_base_raid_button');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(this, this._leaders.base_raid, 0, 0).layout();

      sizer.add(this._table, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'leaderboard_table',
      });

      sizer.layout();
    });

    buttonSizer.add(this.add.existing(this._baseRaidButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'base_raid_button',
    });

    // Add button sizer to main sizer
    sizer.add(this.add.existing(buttonSizer), {
      padding: { left: 0, right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'logins_button',
    });

    // Add Divider
    sizer
      .add(this.add.image(0, 0, leaderboardDivider).setScale(this._scale), {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: this.applyScale(24),
        },
        key: 'lower_divider',
      })
      .layout();

    sizer.onClickOutside(() => {
      this.unloadAssets();
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);
    sizer.add(this._loadingIndicator, {
      key: 'loading_indicator',
      padding: { left: 0, right: 0, top: this.applyScale(160), bottom: 0 },
    });

    this.input.enabled = false;

    sizer.layout();

    PVPBattleService.getInstance()
      .getLeaderData(true)
      .then(res => {
        this._leaders = res;
        this.addTableOnLoad(sizer);
        this.input.enabled = true;
      });
  }

  private addTableOnLoad(sizer) {
    // Remove Loading Indicator
    this._loadingIndicator.setAlpha(0);
    sizer.remove(this._loadingIndicator, true);
    this._loadingIndicator = undefined;

    // Add Table
    this._table = this.createTable(this, this._leaders.wins, 0, 0).layout();

    sizer.add(this._table, {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'leaderboard_table',
    });

    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      width: this.applyScale(modalWidth),
      height: scene.applyScale(80 * data.length > 534 ? 534 : 80 * data.length - 1),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: this.applyScale(modalWidth),
        cellHeight: this.applyScale(80),
        columns: 1,
        clamplTableOXY: true,
        mask: {
          padding: 2,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      space: {
        table: {
          bottom: this.applyScale(8),
        },
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        item.num_wins = item.num_wins || 0;
        item.display_name = item.display_name || 'Unknown';

        function getValueText(item) {
          switch (scene._selectedCategory) {
            case categories.pvp:
              return item.num_wins === 1 ? `1 win` : `${item.num_wins.toLocaleString('en-us')} wins`;
            case categories.logins:
              return item.num_logins === 1 ? `1 login` : `${item.num_logins.toLocaleString('en-us')} logins`;
            case categories.stardust:
              return `${Math.floor(parseFloat(item.stardust)).toLocaleString('en-us')}`;
            case categories.pve:
              return `${item.rounds_played} Rounds (${((item.win_ratio / 6) * 100).toFixed(1)}% K/D)`;
            case categories.xp:
              return `${item.xp.toLocaleString('en-us')}`;
            case categories.mining:
              return `${item.asteroids_finished.toLocaleString('en-us')}`;
            case categories.base_raid:
              return `${Math.floor(parseFloat(item.base_raid_winnings)).toLocaleString('en-us')} looted`;
          }
        }

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(modalWidth - 38),
            space: {
              left: scene.applyScale(19),
              right: 0,
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
          })
          .add(
            scene.add.rexRoundRectangle(
              0,
              0,
              scene.applyScale(leaderItemWidth), // width
              scene.applyScale(leaderItemHeight), // height
              scene.applyScale(leaderItemRadius), // radius
              leaderItemColor, // fillColor
              leaderItemAlpha, // alpha
            ),
            {
              key: 'leader_item_bg',
              align: 'center-top',
              offsetX: 0,
              offsetY: 0,
              expand: false,
            },
          )
          .add(
            scene.add.rexRoundRectangle(
              0,
              0,
              scene.applyScale(leaderItemGlareWidth), // width
              scene.applyScale(leaderItemGlareHeight), // height
              scene.applyScale(leaderItemGlareRadius), // radius
              leaderItemGlareColor, // fillColor
              leaderItemGlareAlpha, // alpha
            ),
            {
              key: 'leader_item_bg_glare',
              align: 'center-top',
              offsetX: 0,
              offsetY: 7,
              expand: false,
            },
          )
          .add(
            scene.rexUI.add.label({
              orientation: 'x',
              rtl: true,
              icon: item.has_battlepass ? scene.add.image(0, 0, 'battle_pass_icon').setScale(scene.getScale()) : undefined,
              text: scene.add.bitmapText(0, 0, 'cc_outline', item.display_name, scene.applyScale(18), 0),
              space: {
                icon: item.has_battlepass ? scene.applyScale(5) : undefined,
              },
            }),
            {
              key: 'leader_item_display_name',
              align: 'left-center',
              offsetX: 21,
              offsetY: 4,
              expand: false,
            },
          )
          .add(scene.add.bitmapText(0, 0, 'cc_outline', getValueText(item), scene.applyScale(18), 0).setTint(numWinsColor), {
            key: 'leader_item_num_wins',
            align: 'right-center',
            offsetX: -21,
            offsetY: 4,
            expand: false,
          });

        sizer.layout();

        return sizer;
      },
    });

    return table;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Leaderboard_Assets));
  }
}
