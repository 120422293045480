import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import ShopMachineComponent from '../shop_modal/machines_shop/shopMachineComponent';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import BoostService from '~/services/BoostService';

const modalWidth = 1004;
const modalHeight = 735;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const subtitleTint = 0xa3a3b9;

const starLabMachineId = 1007;
const starLabMultipliers = [
  0, // Level 0
  1.5, // Level 1
  3, // Level 2
  4.5, // Level 3
  6, // Level 4
  7.5, // Level 5
  10, // Level 6
];

// Progress bar consts
const goldProgressBarColor = 0xdea827;
const goldPreviewBarColor = 0xffd369;
const pinkProgressBarColor = 0xde2795;
const pinkPreviewBarColoar = 0xf567a8;
const blueProgressBarColor = 0x00a6d3;
const bluePreviewBarColor = 0x00e1d3;
const hpProgressBarColor = 0x9a9a9a;
const hpPreviewBarColor = 0xc2c2c2;
const dpsProgressBarColor = 0xbe5b5b;
const dpsPreviewBarColor = 0xda8c8c;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 0.4;
const radius = 7;

const progressBarHeight = 22;
const stardustBarWidth = 572;
const neonGoldBarWidth = 572;
const spaceElixirBarWidth = 572;
const shortBarWidth = 162;
const shortBarSpace = 41;
const halfBarWidth = 286;

const stardustMin = 0;
const neonGoldMin = 0;
const spaceElixirMin = 0;

const upgradeBoxWidth = 923;
const upgradeBoxHeight = 67;
const upgradeBoxRadius = 12;
const upgradeBoxColor = 0x34344c;
const upgradeBoxAlpha = 1;

const closeButtonImageKey = 'machine_info_close_button';
const upgradeButtonImageKey = 'machine_info_upgrade_button';
const speedUpButtonImageKey = 'machine_info_speed_up_button';

const textHighlightColor = 0x00ffb2;

export default class MachineUpgradeContentPane extends OverlapSizer {
  private _item: any;
  private _dynamicSizer: any;
  private _closeButton: any;
  private _itemComponent: any;
  private _itemTitle: any;
  private _costSubtitle: any;
  private _upgradeTimeSubtitle: any;
  private _descriptionTitle: any;
  private _descriptionText: any;
  private _yieldTitle: any;
  private _hasYield: boolean;
  private _storageTitle: any;
  private _hasStorage: boolean;
  private _hasHP: boolean;
  private _hasDPS: boolean;
  private _hasTroopCapacity: boolean;
  private _upgradeButton: any;
  private _upgradeMessage: any;
  private _speedUpButton: any;
  private _isMaxLevel: boolean;
  private _isUpgrading: boolean;
  private _isActive: boolean;
  private _showAdditionalBoosts: boolean;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, item: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: modalWidth * paneScale,
          height: modalHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._item = item;
    this._hasYield = item.produce_per_hour_neon_gold > 0 || item.produce_per_hour_space_elixir > 0 || item.produce_per_hour_stardust > 0;
    this._hasStorage = item.storage_neon_gold > 0 || item.storage_space_elixir > 0;
    this._hasHP = true;
    this._hasDPS = item.dps && item.dps > 0 ? true : false;
    this._hasTroopCapacity = item.machine_id === 1034; // 1034 = Cadet Dorms
    this._showAdditionalBoosts = true;
    this._isMaxLevel = !item.next_level_build_total_time || item.next_level_build_total_time <= 0;
    this._isUpgrading = item.time_until_next_level > 0;
    this._isActive = this._item.time_until_inactive > 0;

    console.log(util.inspect(item));

    // Close Button
    this._closeButton = new Button(this.scene, 0, 0, closeButtonImageKey, false).setScale(this._scale);
    this.scene.add.existing(this._closeButton);

    this._closeButton.onClick().subscribe(pointer => {
      this.closeButtonHandler();
    });

    this.add(this._closeButton, {
      key: 'machine_info_close_button',
      align: 'right-top',
      offsetX: this.applyScale(-21),
      offsetY: this.applyScale(15),
      expand: false,
    });

    // Upper Divider
    const upperDivider = this.scene.add.image(0, 0, 'machine_item_info_divider_long').setScale(this._scale);

    this.add(upperDivider, {
      key: 'upper_divider',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(73),
      expand: false,
    });

    // Item Component
    this._item.shopImageKey = `shop_machines_${this._item.key}`;
    this._itemComponent = new ShopMachineComponent(scene, 0, 0, this._item, scene.getScale(), false);
    scene.add.existing(this._itemComponent);

    if (this._itemComponent) {
      this.add(this._itemComponent, {
        key: 'machine_info_item_component',
        align: 'left-top',
        offsetX: this.applyScale(38),
        offsetY: this.applyScale(102),
        expand: false,
      });
    }

    // Item Title
    this._itemTitle = this.scene.add
      .bitmapText(0, 0, 'cc_outline', this.getDisplayName(), this.applyScale(28), 0)
      .setCharacterTint(-10, -1, false, subtitleTint);

    this.add(this._itemTitle, {
      key: 'item_title',
      align: 'left-top',
      offsetX: this.applyScale(48),
      offsetY: this.applyScale(23),
      expand: false,
    });

    // Cost Subtitle
    this._costSubtitle = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getCostSubtitle(), this.applyScale(16), 0);
    this._costSubtitle.setTint(subtitleTint);

    this.add(this._costSubtitle, {
      key: 'cost_subtitle',
      align: 'left-top',
      offsetX: this.applyScale(365),
      offsetY: this.applyScale(108),
      expand: false,
    });

    //Cost Progress Bar(s)
    this.addCostProgressBars();

    // Upgrade Time Subtitle
    this._upgradeTimeSubtitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Next upgrade build time', this.applyScale(16), 0);
    this._upgradeTimeSubtitle.setTint(subtitleTint);

    this.add(this._upgradeTimeSubtitle, {
      key: 'upgrade_time_subtitle',
      align: 'left-top',
      offsetX: this.applyScale(365),
      offsetY: this.applyScale(189),
      expand: false,
    });

    this.addUpgradeTimeProgressBar();

    // Middle Divider
    const middleDivider = this.scene.add.image(0, 0, 'machine_item_info_divider_short').setScale(this._scale);

    this.add(middleDivider, {
      key: 'middle_divider',
      align: 'left-top',
      offsetX: this.applyScale(288),
      offsetY: this.applyScale(282),
      expand: false,
    });

    // Fixed width sizer for dynamic content
    this._dynamicSizer = this.scene.rexUI.add.fixWidthSizer(0, 0, {
      x: 0, // 288
      y: 0, // 283
      width: this.applyScale(modalWidth - 300),
      height: this.applyScale(320),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    if (this._hasYield) {
      // Yield Title
      this._yieldTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getYieldText(), this.applyScale(16), 0);
      this._yieldTitle.setTint(subtitleTint);

      this._dynamicSizer.add(this._yieldTitle, {
        key: 'yield_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // Yield progress bar
      this.addYieldProgressBar();
    }

    if (this._hasStorage) {
      // Storage Title
      this._storageTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', `Storage${this._isMaxLevel ? '' : ' (& Upgrade)'}`, this.applyScale(16), 0);
      this._storageTitle.setTint(subtitleTint);

      this._dynamicSizer.add(this._storageTitle, {
        key: 'storage_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // Storage progress bar(s)
      this.addStorageProgressBars();
    }

    if (this._hasYield || this._hasStorage) {
      // Lower Divider
      const lowerDivider = this.scene.add.image(0, 0, 'machine_item_info_divider_short').setScale(this._scale);

      this._dynamicSizer.add(lowerDivider, {
        key: 'lower_divider',
        padding: { left: this.applyScale(0), right: 0, top: this.applyScale(20), bottom: 0 },
      });
    }

    if (this._hasHP) {
      // HP Title
      const hpTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'HP', this.applyScale(16), 0);
      hpTitle.setTint(subtitleTint);

      this._dynamicSizer.add(hpTitle, {
        key: 'hp_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // HP Progress Bar
      this.addHpProgressBar();
    }

    if (this._hasDPS) {
      // DPS Title
      const dpsTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Defensive Damage', this.applyScale(16), 0);
      dpsTitle.setTint(subtitleTint);

      this._dynamicSizer.add(dpsTitle, {
        key: 'dps_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // HP Progress Bar
      this.addDpsProgressBar();
    }

    if (this._hasTroopCapacity) {
      // Capacity Title
      const capacityTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Capacity', this.applyScale(16), 0);
      capacityTitle.setTint(subtitleTint);

      this._dynamicSizer.add(capacityTitle, {
        key: 'capacity_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // Capacity Progress Bar
      this.addCapacityProgressBar();
    }

    if (this._showAdditionalBoosts) {
      // Additional Boosts Title
      const additionalBoostsTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Additional Boosts', this.applyScale(16), 0);
      additionalBoostsTitle.setTint(subtitleTint);

      this._dynamicSizer.add(additionalBoostsTitle, {
        key: 'additional_boosts_title',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(20), bottom: 0 },
      });

      // Additional Boosts Text
      this.addAdditionalBoosts();
    }

    // Add dynamic sizer to parent sizer
    this.add(this._dynamicSizer.layout(), {
      key: 'dynamic_sizer',
      align: 'left-top',
      offsetX: this.applyScale(288),
      offsetY: this.applyScale(283),
      expand: false,
    });

    // Description Title
    this._descriptionTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Description', this.applyScale(16), 0);
    this._descriptionTitle.setTint(subtitleTint);

    this.add(this._descriptionTitle, {
      key: 'description_title',
      align: 'left-top',
      offsetX: this.applyScale(45),
      offsetY: this.applyScale(484),
      expand: false,
    });

    // Description Text Area
    this._descriptionText = this.scene.rexUI.add
      .textArea({
        x: 0,
        y: 0,
        width: this.applyScale(244),
        height: this.applyScale(76),
        text: this.scene.add.bitmapText(0, 0, 'cc_outline', this.getDescription(), this.applyScale(16), 0),
        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },
        scroller: true,
      })
      .layout();

    this._descriptionText.setText(this.getDescription());

    this.add(this._descriptionText, {
      key: 'description_text',
      align: 'left-top',
      offsetX: this.applyScale(45),
      offsetY: this.applyScale(512),
      expand: false,
    });

    // Upgrade box bg
    const upgradeBg = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(upgradeBoxWidth), // width
      this.applyScale(upgradeBoxHeight), // height
      this.applyScale(upgradeBoxRadius), // radius
      upgradeBoxColor, // fillColor
      upgradeBoxAlpha, // alpha
    );

    this.add(upgradeBg, {
      key: 'upgrade_bg',
      align: 'center-bottom',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(-33),
      expand: false,
    });

    // Upgrade message
    this._upgradeMessage = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getUpgradeMessage(), this.applyScale(16), 0);

    this.add(this._upgradeMessage, {
      key: 'upgrade_message',
      align: 'left-bottom',
      offsetX: this.applyScale(68),
      offsetY: this.applyScale(-57),
      expand: false,
    });

    // Upgrade/Speed Up Button
    if (this._isUpgrading) {
      this._speedUpButton = new Button(scene, 0, 0, speedUpButtonImageKey, false).setScale(scene.getScale());
      scene.add.existing(this._speedUpButton);

      this._speedUpButton.onClick().subscribe(pointer => {
        this._speedUpButton.setDisabled(true);
        MachineService.getInstance()
          .getUserMachines(true)
          .then(res => {
            var machine = res.find(mach => {
              return mach.user_machine_id === this._item.user_machine_id;
            });
            this.scene.scene.start('MachineSpeedUpModalScene', { machine: machine || this._item });
          });
      });

      this.add(this._speedUpButton, {
        key: 'speed_up_button',
        align: 'right-bottom',
        offsetX: this.applyScale(-56),
        offsetY: this.applyScale(-43),
        expand: false,
      });
    } else {
      this._upgradeButton = new Button(scene, 0, 0, upgradeButtonImageKey, false).setScale(scene.getScale());
      scene.add.existing(this._upgradeButton);

      if (this._isMaxLevel || this._isUpgrading || this._isActive) {
        this._upgradeButton.setDisabled(true);
        this._upgradeButton.setAlpha(0.5);
      } else {
        this._upgradeButton.onClick().subscribe(pointer => {
          this._upgradeButton.setDisabled(true);
          this.scene.scene.start('ShopPurchaseConfirmScene', { item: this._item, isUpgrade: true });
        });
      }

      this.add(this._upgradeButton, {
        key: 'upgrade_button',
        align: 'right-bottom',
        offsetX: this.applyScale(-56),
        offsetY: this.applyScale(-44),
        expand: false,
      });
    }

    return this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  public hidePane() {
    this._closeButton.setAlpha(0);
    this._itemComponent.setAlpha(0);
    //this.table.disableInteractive();
  }

  private closeButtonHandler() {
    this.scene.unloadAssets();
    this.scene.scene.resume('HudScene');
    this.scene.scene.get('HomebaseScene').input.enabled = true;
    this.scene.scene.stop();
  }

  private getDisplayName() {
    return `${this._item.display_name || 'Unknown Item'} (Level ${this._item.level})`;
  }

  private getCostSubtitle() {
    return 'Cost to upgrade';
  }

  private getDescription() {
    return this._item.description;
  }

  private getYieldText() {
    return this._item.machine_id === starLabMachineId
      ? `Speed Boost${this._isMaxLevel ? '' : ' (& Upgrade)'}`
      : `Yield per hour${this._isMaxLevel ? '' : ' (& Upgrade)'}`;
  }

  private getUpgradeMessage() {
    if (this._isMaxLevel) {
      return `This machine is at its maximum level.`;
    }
    if (this._isUpgrading) {
      return 'This machine is currently being upgraded...';
    }
    if (this._isActive) {
      return 'This machine is currently active...';
    }
    if (this._item.key === 'mainframe') {
      return `You will need a Level ${this._item.level} Mainframe for this upgrade.`;
    }

    console.log(this._item.next_level_barracks_level_requirement);

    if (this._item.next_level_barracks_level_requirement > 0)
      return `You will need a Level ${this._item.next_level_barracks_level_requirement} Barracks for this purchase.`;
    else return `You will need a Level ${this._item.next_level_mainframe_level_required} Mainframe for this purchase.`;
  }

  private getStarLabMultiplier() {
    return starLabMultipliers[parseInt(this._item.level)];
  }

  private addAdditionalBoosts() {
    const textArea = this.scene.rexUI.add
      .textArea({
        x: 0,
        y: 0,
        width: this.applyScale(neonGoldBarWidth),
        height: this.applyScale(38),
        text: this.scene.add.bitmapText(0, 0, 'cc_outline', this.getDescription(), this.applyScale(16), 0),
        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },
        scroller: true,
      })
      .layout();

    const boost = this.getAdditionalBoosts();
    textArea.setText(boost.upgradeText);

    if (boost.highlightStartIndex) {
      textArea.getElement('text').setCharacterTint(boost.highlightStartIndex, boost.highlightLength, true, textHighlightColor);
    }

    this._dynamicSizer.add(textArea, {
      key: 'boosts_text_area',
      padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
    });
  }

  private getAdditionalBoosts() {
    return BoostService.getInstance().getMachineBoosts(this._item);
  }

  private addUpgradeTimeProgressBar() {
    const timeProgBar = new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'time_icon',
        space: 8,
        scale: 1,
      },
      progressBar: {
        text: this._isMaxLevel ? 'N/A' : this.secondsToTime(this._item.next_level_build_total_time),
        fontStyle: null,
        fontSize: undefined,
        width: neonGoldBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: goldProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: 100,
        progressValue: 0,
        glare: {
          height: glareHeight,
          width: neonGoldBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    this.add(timeProgBar, {
      key: 'time_prog_bar',
      align: 'left-top',
      offsetX: this.applyScale(365),
      offsetY: this.applyScale(212),
      expand: false,
    });
  }

  private addCostProgressBars() {
    const spaceElixirCost = this.getSpaceElixirCost();
    const neonGoldCost = this.getNeonGoldCost();
    const stardustCost = this.getStardustCost();

    var resourceCount = 0;

    if (spaceElixirCost > 0) resourceCount++;
    if (neonGoldCost > 0) resourceCount++;
    if (stardustCost > 0) resourceCount++;

    if (resourceCount > 1 || (this._isMaxLevel && this._item.key === 'mainframe')) {
      //Draw all three progress bars
      const starBar = this.createStardustBar(stardustCost, stardustCost > 0 ? stardustCost : 1, shortBarWidth + 3, false, 16);
      const spaceBar = this.createSpaceElixirBar(spaceElixirCost, spaceElixirCost > 0 ? spaceElixirCost : 1, shortBarWidth, false, 16);
      const neonBar = this.createNeonGoldBar(neonGoldCost, neonGoldCost > 0 ? neonGoldCost : 1, shortBarWidth, false, 16);

      this.scene.add.existing(starBar);
      this.scene.add.existing(spaceBar);
      this.scene.add.existing(neonBar);

      this.add(spaceBar, {
        key: 'space_cost_bar',
        align: 'left-top',
        offsetX: this.applyScale(365),
        offsetY: this.applyScale(133),
        expand: false,
      })
        .add(neonBar, {
          key: 'neon_cost_bar',
          align: 'left-top',
          offsetX: this.applyScale(365 + shortBarWidth + shortBarSpace),
          offsetY: this.applyScale(133),
          expand: false,
        })
        .add(starBar, {
          key: 'star_cost_bar',
          align: 'left-top',
          offsetX: this.applyScale(365 + shortBarWidth * 2 + shortBarSpace * 2),
          offsetY: this.applyScale(131),
          expand: false,
        });
      if (this._isMaxLevel) {
        spaceBar.setProgressBarText('N/A');
        neonBar.setProgressBarText('N/A');
        starBar.setProgressBarText('N/A');
      }
    } else {
      var costProgBar;
      //Draw only one progress bar
      if (stardustCost > 0) {
        costProgBar = this.createStardustBar(this._item.cost_stardust, this._item.cost_stardust, stardustBarWidth);
      } else if (neonGoldCost > 0) {
        costProgBar = this.createNeonGoldBar(this._item.cost_neon_gold, this._item.cost_neon_gold, neonGoldBarWidth);
      } else if (spaceElixirCost > 0) {
        costProgBar = this.createSpaceElixirBar(this._item.cost_space_elixir, this._item.cost_space_elixir, spaceElixirBarWidth);
      } else {
        // Machine level is maxed out
        costProgBar = this.createNeonGoldBar(neonGoldCost, neonGoldCost > 0 ? neonGoldCost : 1, neonGoldBarWidth);
      }

      if (this._isMaxLevel) {
        costProgBar.setProgressBarText('N/A');
      }

      this.scene.add.existing(costProgBar);

      this.add(costProgBar, {
        key: 'cost_prog_bar',
        align: 'left-top',
        offsetX: this.applyScale(365),
        offsetY: this.applyScale(133),
        expand: false,
      });
    }
  }

  private addYieldProgressBar() {
    const spaceElixirYield = this._item.produce_per_hour_space_elixir;
    const neonGoldYield = this._item.produce_per_hour_neon_gold;
    const stardustYield = this._item.produce_per_hour_stardust;
    var resourceCount = 0;

    if (spaceElixirYield > 0) resourceCount++;
    if (neonGoldYield > 0) resourceCount++;
    if (stardustYield > 0) resourceCount++;

    if (resourceCount > 1) {
      //Draw both progress bars
      const mainBar =
        spaceElixirYield > 0
          ? this.createSpaceElixirBar(
              this._isMaxLevel ? spaceElixirYield : 7,
              this._isMaxLevel ? spaceElixirYield : 10,
              spaceElixirBarWidth,
              !this._isMaxLevel,
              undefined,
            )
          : this.createNeonGoldBar(
              this._isMaxLevel ? neonGoldYield : 7,
              this._isMaxLevel ? neonGoldYield : 10,
              neonGoldBarWidth,
              !this._isMaxLevel,
              undefined,
            );

      const sdBar = this.createStardustBar(
        this._isMaxLevel ? stardustYield : 7,
        this._isMaxLevel ? stardustYield : 10,
        stardustBarWidth,
        !this._isMaxLevel,
        undefined,
      );

      if (!this._isMaxLevel) {
        const mainYield = spaceElixirYield > 0 ? spaceElixirYield : neonGoldYield;
        const mainNextLevelYield =
          spaceElixirYield > 0 ? this._item.next_level_produce_per_hour_space_elixir : this._item.next_level_produce_per_hour_neon_gold;
        mainBar.setProgressBarText(
          `${mainYield.toLocaleString('en-us')} (+${(mainNextLevelYield - mainYield).toLocaleString('en-us')} per hour after upgrade)`,
        );

        sdBar.setProgressBarText(
          `${stardustYield.toLocaleString('en-us')} (+${(this._item.next_level_produce_per_hour_stardust - stardustYield).toLocaleString(
            'en-us',
          )} per hour after upgrade)`,
        );
      }

      this._dynamicSizer
        .add(mainBar, {
          key: 'main_yield_bar',
          padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
        })
        .add(sdBar, {
          key: 'stardust_yield_bar',
          padding: { left: this.applyScale(77), right: 0, top: this.applyScale(14), bottom: 0 },
        });
    } else {
      var yieldProgBar;

      if (neonGoldYield > 0) {
        yieldProgBar = this.createNeonGoldBar(
          this._isMaxLevel ? neonGoldYield : 7,
          this._isMaxLevel ? neonGoldYield : 10,
          neonGoldBarWidth,
          !this._isMaxLevel,
        );
        if (!this._isMaxLevel) {
          yieldProgBar.setProgressBarText(
            `${neonGoldYield.toLocaleString('en-us')} (+${(this._item.next_level_produce_per_hour_neon_gold - neonGoldYield).toLocaleString(
              'en-us',
            )} per hour after upgrade)`,
          );
        }
      } else if (spaceElixirYield > 0) {
        yieldProgBar = this.createSpaceElixirBar(
          this._isMaxLevel ? spaceElixirYield : 7,
          this._isMaxLevel ? spaceElixirYield : 10,
          spaceElixirBarWidth,
          !this._isMaxLevel,
        );
        if (!this._isMaxLevel) {
          yieldProgBar.setProgressBarText(
            `${spaceElixirYield.toLocaleString('en-us')} (+${(this._item.next_level_produce_per_hour_space_elixir - spaceElixirYield).toLocaleString(
              'en-us',
            )} per hour after upgrade)`,
          );
        }
      } else if (stardustYield > 0) {
        yieldProgBar = this.createStardustBar(
          this._isMaxLevel ? stardustYield : 7,
          this._isMaxLevel ? stardustYield : 10,
          stardustBarWidth,
          !this._isMaxLevel,
        );
        if (!this._isMaxLevel) {
          yieldProgBar.setProgressBarText(
            `${stardustYield.toLocaleString('en-us')} (+${(this._item.next_level_produce_per_hour_stardust - stardustYield).toLocaleString(
              'en-us',
            )} per hour after upgrade)`,
          );
        }
      }

      if (yieldProgBar) {
        this.scene.add.existing(yieldProgBar);

        this._dynamicSizer.add(yieldProgBar, {
          key: 'yield_prog_bar',
          padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
        });
      }
    }
  }

  private addStorageProgressBars() {
    const spaceElixirStorage = this._item.storage_space_elixir;
    const neonGoldStorage = this._item.storage_neon_gold;

    var resourceCount = 0;

    if (spaceElixirStorage > 0) resourceCount++;
    if (neonGoldStorage > 0) resourceCount++;

    if (resourceCount > 1) {
      //Draw both progress bars
      const spaceBar = this.createSpaceElixirBar(
        this._isMaxLevel ? spaceElixirStorage : 8,
        this._isMaxLevel ? spaceElixirStorage : 10,
        spaceElixirBarWidth,
        !this._isMaxLevel,
        undefined,
      );
      const neonBar = this.createNeonGoldBar(
        this._isMaxLevel ? neonGoldStorage : 8,
        this._isMaxLevel ? neonGoldStorage : 10,
        neonGoldBarWidth,
        !this._isMaxLevel,
        undefined,
      );

      if (!this._isMaxLevel) {
        spaceBar.setProgressBarText(
          `${spaceElixirStorage.toLocaleString('en-us')} (+${(this._item.next_level_storage_space_elixir - spaceElixirStorage).toLocaleString(
            'en-us',
          )} after upgrade)`,
        );
        neonBar.setProgressBarText(
          `${neonGoldStorage.toLocaleString('en-us')} (+${(this._item.next_level_storage_neon_gold - neonGoldStorage).toLocaleString(
            'en-us',
          )} after upgrade)`,
        );
      }

      this.scene.add.existing(spaceBar);
      this.scene.add.existing(neonBar);

      this._dynamicSizer
        .add(spaceBar, {
          key: 'space_storage_bar',
          padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
        })
        .add(neonBar, {
          key: 'neon_storage_bar',
          padding: { left: this.applyScale(77), right: 0, top: this.applyScale(14), bottom: 0 },
        });
    } else {
      var storageProgBar;
      //Draw only one progress bar
      if (neonGoldStorage > 0) {
        storageProgBar = this.createNeonGoldBar(
          this._isMaxLevel ? neonGoldStorage : 7,
          this._isMaxLevel ? neonGoldStorage : 10,
          neonGoldBarWidth,
          !this._isMaxLevel,
        );
        if (!this._isMaxLevel) {
          storageProgBar.setProgressBarText(
            `${neonGoldStorage.toLocaleString('en-us')} (+${(this._item.next_level_storage_neon_gold - neonGoldStorage).toLocaleString(
              'en-us',
            )} after upgrade)`,
          );
        }
      } else if (spaceElixirStorage > 0) {
        storageProgBar = this.createSpaceElixirBar(
          this._isMaxLevel ? spaceElixirStorage : 7,
          this._isMaxLevel ? spaceElixirStorage : 10,
          spaceElixirBarWidth,
          !this._isMaxLevel,
        );
        if (!this._isMaxLevel) {
          storageProgBar.setProgressBarText(
            `${spaceElixirStorage.toLocaleString('en-us')} (+${(this._item.next_level_storage_space_elixir - spaceElixirStorage).toLocaleString(
              'en-us',
            )} after upgrade)`,
          );
        }
      }

      this.scene.add.existing(storageProgBar);

      this._dynamicSizer.add(storageProgBar, {
        key: 'storage_prog_bar',
        padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
      });
    }
  }

  private addHpProgressBar() {
    const progressValue = this._item.hitpoints;

    const hpProgBar = new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'hp_icon',
        space: 8,
        scale: 1,
      },
      previewBar: {
        color: this._isMaxLevel ? undefined : hpPreviewBarColor,
        width: this._isMaxLevel ? undefined : neonGoldBarWidth * 0.8,
        alpha: this._isMaxLevel ? undefined : 1,
      },
      progressBar: {
        text: progressValue.toLocaleString('en-us'),
        fontStyle: null,
        fontSize: undefined,
        width: neonGoldBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: hpProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: this._isMaxLevel ? progressValue : 10,
        progressValue: this._isMaxLevel ? progressValue : 7,
        glare: {
          height: glareHeight,
          width: neonGoldBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    if (!this._isMaxLevel) {
      hpProgBar.setProgressBarText(
        `${progressValue.toLocaleString('en-us')} (+${(this._item.next_level_hitpoints - progressValue).toLocaleString('en-us')} after upgrade)`,
      );
    }

    this._dynamicSizer.add(hpProgBar, {
      key: 'hp_prog_bar',
      padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
    });
  }

  private addDpsProgressBar() {
    const progressValue = this._item.dps;

    const dpsProgBar = new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'dps_icon',
        space: 8,
        scale: 1,
      },
      previewBar: {
        color: this._isMaxLevel ? undefined : dpsPreviewBarColor,
        width: this._isMaxLevel ? undefined : neonGoldBarWidth * 0.8,
        alpha: this._isMaxLevel ? undefined : 1,
      },
      progressBar: {
        text: `${progressValue.toLocaleString('en-us')} per second`,
        fontStyle: null,
        fontSize: undefined,
        width: neonGoldBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: dpsProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: this._isMaxLevel ? progressValue : 10,
        progressValue: this._isMaxLevel ? progressValue : 7,
        glare: {
          height: glareHeight,
          width: neonGoldBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    if (!this._isMaxLevel) {
      dpsProgBar.setProgressBarText(
        `${progressValue.toLocaleString('en-us')} per second (+${(this._item.next_level_dps - progressValue).toLocaleString('en-us')} after upgrade)`,
      );
    }

    this._dynamicSizer.add(dpsProgBar, {
      key: 'dps_prog_bar',
      padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
    });
  }

  private addCapacityProgressBar() {
    const progressValue = MachineService.getInstance().getCadetDormCapacity(this._item.level);
    const nextLevelValue = MachineService.getInstance().getCadetDormCapacity(this._item.level + 1);
    const upgradeDifference = nextLevelValue - progressValue;

    const capacityProgBar = new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'hp_icon',
        space: 8,
        scale: 1,
      },
      previewBar: {
        color: this._isMaxLevel ? undefined : hpPreviewBarColor,
        width: this._isMaxLevel ? undefined : neonGoldBarWidth * 0.8,
        alpha: this._isMaxLevel ? undefined : 1,
      },
      progressBar: {
        text: `${progressValue.toLocaleString('en-us')}`,
        fontStyle: null,
        fontSize: undefined,
        width: neonGoldBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: hpProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: this._isMaxLevel ? progressValue : 10,
        progressValue: this._isMaxLevel ? progressValue : 7,
        glare: {
          height: glareHeight,
          width: neonGoldBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    if (!this._isMaxLevel) {
      capacityProgBar.setProgressBarText(`${progressValue.toLocaleString('en-us')} (+${upgradeDifference} after upgrade)`);
    }

    this._dynamicSizer.add(capacityProgBar, {
      key: 'capacity_prog_bar',
      padding: { left: this.applyScale(77), right: 0, top: this.applyScale(8), bottom: 0 },
    });
  }

  private createSpaceElixirBar(progressValue: number, maxValue: number, width: number, isUpgrade: boolean = false, fontSize?: number) {
    return new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'space_elixir_icon',
        space: 8,
        scale: 1,
      },
      previewBar: !isUpgrade
        ? undefined
        : {
            color: bluePreviewBarColor,
            width: width * ((progressValue + 1) / 10),
            alpha: 1,
          },
      progressBar: {
        text: progressValue.toLocaleString('en-us'),
        fontStyle: null,
        fontSize: fontSize,
        width: width,
        height: progressBarHeight,
        radius: radius,
        color: blueProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: spaceElixirMin,
        maxValue: maxValue > 0 ? maxValue : 100,
        progressValue: progressValue,
        glare: {
          height: glareHeight,
          width: width - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });
  }

  private createNeonGoldBar(progressValue: number, maxValue: number, width: number, isUpgrade: boolean = false, fontSize?: number) {
    return new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'neon_gold_icon',
        space: 8,
        scale: 1,
      },
      previewBar: !isUpgrade
        ? undefined
        : {
            color: goldPreviewBarColor,
            width: width * ((progressValue + 1) / 10),
            alpha: 1,
          },
      progressBar: {
        text: progressValue.toLocaleString('en-us'),
        fontStyle: null,
        fontSize: fontSize,
        width: width,
        height: progressBarHeight,
        radius: radius,
        color: goldProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: neonGoldMin,
        maxValue: maxValue > 0 ? maxValue : 100,
        progressValue: progressValue,
        glare: {
          height: glareHeight,
          width: width - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });
  }

  private createStardustBar(progressValue: number, maxValue: number, width: number, isUpgrade: boolean = false, fontSize?: number) {
    return new ProgressBar(this.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      icon: {
        key: 'stardust_icon_44',
        space: 5,
        scale: 1,
      },
      previewBar: !isUpgrade
        ? undefined
        : {
            color: pinkPreviewBarColoar,
            width: width * 0.8,
            alpha: 1,
          },
      progressBar: {
        text: progressValue.toLocaleString('en-us'),
        fontStyle: null,
        fontSize: fontSize,
        width: width,
        height: progressBarHeight,
        radius: radius,
        color: pinkProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: stardustMin,
        maxValue: maxValue > 0 ? maxValue : 100,
        progressValue: progressValue,
        glare: {
          height: glareHeight,
          width: width - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });
  }

  private getSpaceElixirCost() {
    return this._item.cost_space_elixir;
  }

  private getNeonGoldCost() {
    return this._item.cost_neon_gold;
  }

  private getStardustCost() {
    return this._item.cost_stardust;
  }

  private secondsToTime(seconds: number) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var hDisplay = h > 0 ? `${h}H` : '';
    var mDisplay = m > 0 ? `${m}M` : '';
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    if (h === 0 && m === 0 && s > 0) {
      return '< 1m';
    }
    return h > 0 ? `${hDisplay} ${mDisplay}` : mDisplay;
  }
}

Phaser.GameObjects.GameObjectFactory.register('machineUpgradeContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new MachineUpgradeContentPane(this.scene, x, y));
});
