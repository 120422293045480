import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';

const paneWidth = 685;
const paneHeight = 687;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

const cellHeight = 58;
const tableHeight = 597;

const roundNumberColor = 0x75758b;

const awaitingActionText = 'Awaiting action...';
const actionInvalidText = 'Action invalid';
const actionSubmittedText = 'Action submitted';
const awaitingActionColor = 0xffd600;
const actionSubmittedColor = 0x00ff94;
const actionInvalidColor = 0xff0000;

export default class PvpGamePane extends OverlapSizer {
  private _scale;
  private _table;
  private _titleText;
  private _battleType;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._battleType = data.battleType;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(paneWidth), // width
      this.applyScale(paneHeight), // height
      this.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.addBackground(background);

    // Vertical Divider
    const divider = this.scene.add.image(0, 0, 'pvp_vertical_divider').setScale(this._scale);

    this.add(divider, {
      key: 'vertical-divider',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Title Row
    this._titleText = this.scene.add.bitmapText(
      0,
      0,
      'cc_outline',
      this._battleType === 'pvp' ? 'The battle begins...' : 'Match Round 1... FIGHT!',
      this.applyScale(16),
      0,
    );

    this.add(this._titleText, {
      key: 'title-text',
      align: 'left-top',
      offsetX: this.applyScale(17),
      offsetY: this.applyScale(15),
      expand: false,
    });

    const titleDivider = this.scene.add.image(0, 0, 'pvp_game_divider').setScale(this._scale);

    this.add(titleDivider, {
      key: 'title-divider',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(45),
      expand: false,
    });

    // Actions table
    this._table = this.createTable(
      this.scene,
      [
        {
          roundNumber: 1,
          playerAction: 'Awaiting action...',
          opponentAction: 'Awaiting action...',
        },
      ],
      0, // x
      0, // y
    ).layout();

    this.add(this._table, {
      key: 'game-table',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(45),
      expand: false,
    });

    this.layout();
  }

  private createTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      width: this.applyScale(paneWidth),
      height: this.applyScale(paneHeight - 45),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: this.applyScale(paneWidth),
        cellHeight: this.applyScale(cellHeight),
        columns: 1,
        clamplTableOXY: true,
        mask: {
          padding: 2,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      space: {
        table: {
          left: 0,
          right: 0,
          bottom: 0,
        },
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        function setTextColor(rowItem) {
          const text = rowItem.text;

          if (text === awaitingActionText) {
            rowItem.setTint(awaitingActionColor);
          }
          if (text === actionInvalidText) {
            rowItem.setTint(actionInvalidColor);
          }
          if (text === actionSubmittedText) {
            rowItem.setTint(actionSubmittedColor);
          }

          return rowItem;
        }

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(paneWidth),
            height: height,
            space: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
          })
          .add(scene.add.bitmapText(0, 0, 'cc_outline', item.roundNumber, scene.applyScale(14), 0).setTint(roundNumberColor), {
            key: 'round-number-text',
            align: 'left-top',
            offsetX: scene.applyScale(17),
            offsetY: scene.applyScale(12),
            expand: false,
          })
          .add(setTextColor(scene.add.bitmapText(0, 0, 'cc_outline', item.playerAction, scene.applyScale(14), 0)), {
            key: 'player-action',
            align: 'left-top',
            offsetX: scene.applyScale(17),
            offsetY: scene.applyScale(33),
            expand: false,
          })
          .add(setTextColor(scene.add.bitmapText(0, 0, 'cc_outline', item.opponentAction, scene.applyScale(14), 0)), {
            key: 'opponent-action',
            align: 'left-top',
            offsetX: scene.applyScale(360),
            offsetY: scene.applyScale(33),
            expand: false,
          })
          .add(scene.add.image(0, 0, 'pvp_game_divider').setScale(scene.applyScale(1)), {
            key: 'row-divider',
            align: 'center-bottom',
            offsetX: 0,
            offsetY: 0,
            expand: false,
          });

        return sizer;
      },
    });

    return table;
  }

  public addRound(round: number) {
    var roundData = {
      roundNumber: round,
      playerAction: awaitingActionText,
      opponentAction: awaitingActionText,
    };
    var currentItems = this._table.items;
    if (roundData) {
      currentItems.push(roundData);
    }
    this._table.setItems(currentItems).layout().scrollToBottom(); //Refreshes the table UI
  }

  public updateExistingRound(updatedRoundData) {
    var currentItems = this._table.items;
    if (updatedRoundData) {
      currentItems.pop();
      currentItems.push(updatedRoundData);
    }
    this._table.setItems(currentItems); //Refreshes the table UI
  }

  public clearGameplayLog() {
    this._table.items = [];
    this._table.items.push({
      roundNumber: 1,
      playerAction: 'Awaiting action...',
      opponentAction: 'Awaiting action...',
    });
    this._table.setItems(this._table.items); //Refreshes the table UI
  }

  public updateTitle(text: string) {
    this._titleText.text = text;
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('pvpGamePane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new PvpGamePane(this.scene, x, y));
});
