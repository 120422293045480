import Phaser, { Scene } from 'phaser';
import { Subscription, unsubscribe } from 'rxjs';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import RoundImageButton from '../../../components/buttons/RoundImageButton';
import util from 'util';

const componentWidth = 261;
const componentHeight = 49;
const componentRadius = 12;
const componentColor = 0x262637;
const componentAlpha = 1;

export default class PvPSquadMemberComponent extends OverlapSizer {
  private _squadMember;
  private _squadMemberImage;
  private _scale;
  private _squadMemberLabel;
  private _hpLabel;
  private _yieldLabel;
  private _useOverScale;
  private _overScale;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          width: paneScale * componentWidth,
          height: paneScale * componentHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._squadMember = data;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(componentWidth), // width
      this.applyScale(componentHeight), // height
      this.applyScale(componentRadius), // radius
      componentColor, // fillColor
      componentAlpha, // alpha
    );

    this.addBackground(background);

    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, this.getImageKey(this._squadMember));
    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 8;

    this._squadMemberImage = this.scene.add.rexCircleMaskImage(0, 0, this.getImageKey(this._squadMember), '', rectConfig);
    this._squadMemberImage.displayHeight = this.applyScale(33);
    this._squadMemberImage.scaleX = this._squadMemberImage.scaleY;
    smImageData.destroy();

    this.add(this._squadMemberImage, {
      key: `squad-member-image`,
      align: 'left-center',
      offsetX: scene.applyScale(9),
      offsetY: 0,
      expand: false,
    });

    // Squad Member Label
    this._squadMemberLabel = scene.add.bitmapText(0, 0, 'cc_outline', this.getDisplayName().toUpperCase(), this.applyScale(15), 0).setOrigin(0, 0.5);

    this.add(this._squadMemberLabel, {
      key: `squad-member-label`,
      align: 'left-top',
      offsetX: scene.applyScale(52),
      offsetY: scene.applyScale(7),
      expand: false,
    });

    // Squad Memmber HP Label
    this._hpLabel = scene.add.bitmapText(0, 0, 'cc_outline', `HP: ${this._squadMember.hp?.toString() || '0'}`, this.applyScale(14), 0);
    this._hpLabel.alpha = 0.4;

    this.add(this._hpLabel, {
      key: `squad-member-hp-label`,
      align: 'left-bottom',
      offsetX: scene.applyScale(52),
      offsetY: scene.applyScale(-7),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getImageKey(squadMem): string {
    if (squadMem.image_thumbnail_url && squadMem.image_thumbnail_url.length > 0 && squadMem.image_key && squadMem.image_key.length > 0) {
      return squadMem.image_key;
    } else {
      return 'starwolf_silhouette';
    }
  }

  private getDisplayName() {
    if (this._squadMember.display_name.length === 0) {
      return 'Mystery Wolf ???';
    }
    if (this._squadMember.nft_collection) {
      switch (this._squadMember.nft_collection) {
        case 'Star Wolvez Genesis':
          return `Genesis Wolf #${this._squadMember.nft_id}`;
        case 'GalacticApes':
          return `Galactic Ape #${parseInt(this._squadMember.nft_id) + 1}`;
        case 'Satoshi Runners Genesis':
          return `Satoshi Run... #${this._squadMember.nft_id}`;
        default:
          return this._squadMember.display_name;
      }
    }
    return this._squadMember.display_name;
  }
}

Phaser.GameObjects.GameObjectFactory.register('pvpSquadMemberComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new PvPSquadMemberComponent(this.scene, x, y, data));
});
