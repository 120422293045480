import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { BossFight_Assets } from '~/utils/AssetLoader';

const modalHeight = 527;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class HudBossFightAlertScene extends Phaser.Scene {
    
    private _scale;
    private _laterButton
    private _discordButton
    private _bossFightImage
    private _bossFightTitle
    private _bossFightText1
    private _bossFightText2

    constructor() {
        super({
            key: 'HudBossFightMissedAlertScene'
        });
    }

    preload() {        
        this.load.image("bossfight_notification_image", BossFight_Assets.bossfight_notification_image);
        this.load.image("bossfight_notification_later_button", BossFight_Assets.bossfight_notification_later_button);
        this.load.image("bossfight_go_to_discord_button", BossFight_Assets.bossfight_go_to_discord_button);
        this.load.image("bossfight_battle_missed_image", BossFight_Assets.bossfight_battle_missed_image);
    }

    create() {
        this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
        this.createComponent()
    }

    createComponent() {

        var sizer = this.rexUI.add.overlapSizer(
            this.sys.canvas.width / 2,
            this.sys.canvas.height / 2,
            {
				width: modalWidth * this._scale,
                height: modalHeight * this._scale,
                space: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    item: 0,
                    line: 0,
                },
                align: 0,
                sizerEvents: true,
            },
        )

        const background = this.add.rexRoundRectangle(
            0, // x
            0, // y
            this.applyScale(modalWidth), // width
            this.applyScale(modalHeight), // height
            this.applyScale(modalRadius), // radius
            modalColor, // fillColor
            modalAlpha, // alpha
        ).setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

        sizer.add(background, {
            key: "background",
            align: "center",
            offsetX: 0,
            offsetY: 0,
            expand: false,
        })

        // Add Boss Image
        this._bossFightImage = this.add.image(
            0,
            0,
            "bossfight_notification_image"
        ).setScale(this._scale)

        sizer.add(this._bossFightImage, {
            key: "bossfight_image",
            align: "center-top",
            offsetX: 0,
            offsetY: 0,
            expand: false,
        })

        // Add Boss Title
        this._bossFightTitle = this.add.image(
            0,
            0,
            "bossfight_battle_missed_image"
        ).setScale(this._scale)

        sizer.add(this._bossFightTitle, {
            key: "bossfight_title_image",
            align: "center-middle",
            offsetX: 0,
            offsetY: 0,
            expand: false,
        })

        // Text 1
        this._bossFightText1 = this.add.bitmapText(
            0, 
            0, 
            'cc_outline',
            "To get alerted in\n Discord, react to the\n message in the\n following channel...", 
            this.applyScale(22), 
            1
        )
        sizer.add( this._bossFightText1, {
            key: "bossfight-text-1",
            align: "center-top",
            offsetX: 0,
            offsetY: this.applyScale(325),
            expand: false,
        })
       
        // Add Later Button
		this._laterButton = new Button(
			this, 
			0,
			0, 
			'bossfight_notification_later_button',
			true,
			1.05 
		).setScale(this._scale)

		this._laterButton.onClick().subscribe(pointer => {
			this.scene.resume('HomebaseScene');
            this.scene.resume('HudScene');
            this.scene.stop();
		})

		this.add.existing(this._laterButton)

		sizer.add(this._laterButton, {
			key: 'later-button',
			align: 'left-top',
			offsetX: this.applyScale(61),
			offsetY: this.applyScale(497),
			expand: false
		})

        // Add Later Button
		this._discordButton = new Button(
			this, 
			0,
			0, 
			'bossfight_go_to_discord_button',
			true,
			1.05 
		).setScale(this._scale)

		this._discordButton.onClick().subscribe(pointer => {
            // This somehow needs to launch discord
		})

		this.add.existing(this._discordButton)

		sizer.add(this._discordButton, {
			key: 'discord-button',
			align: 'left-top',
			offsetX: this.applyScale(211),
			offsetY: this.applyScale(497),
			expand: false
		})

        sizer.layout();
    }

    applyScale(length) {
        return length * this._scale;
    }

    

}