import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { PVP_Game_Over_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 400;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const victoryMessage = 'You won the\nfollowing resources:';
const drawMessage = 'Your entry fee\nhas been returned:';

export default class PvpRewardsScene extends Phaser.Scene {
  private _rewards;
  private _isDraw;
  private _scale;

  init(data) {
    this._rewards = data.rewards;
    this._isDraw = this._rewards.isDraw;
  }

  constructor() {
    super({
      key: 'PvpRewardsScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 300); // 300 accounts for icons and button extending beyond modal box
  }

  preload() {
    LoadImageAssets(this, PVP_Game_Over_Scene_Assets);
  }

  create() {
    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Icon
    this.add
      .image(this.sys.canvas.width / 2, modalBg.getTopLeft().y - this.applyScale(80), this._isDraw ? 'pvp_draw_icon' : 'pvp_victory_icon')
      .setScale(this._scale);

    // Add Icon Text
    this.add
      .image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, this._isDraw ? 'pvp_draw_icon_label' : 'pvp_victory_icon_label')
      .setScale(this._scale);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(215),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this._isDraw ? drawMessage : victoryMessage, this.applyScale(24), 1), {
      padding: { left: this.applyScale(2), right: 0, top: 0, bottom: this.applyScale(27) },
      key: 'title_text',
    });

    // Add Fee Labels
    const space_elixir_label = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', this._rewards?.space_elixir?.toLocaleString('en-us') || '0');
    const neon_gold_label = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', this._rewards?.neon_gold?.toLocaleString('en-us') || '0');
    const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', this._rewards?.stardust?.toLocaleString('en-us') || '0');

    sizer.add(this.add.existing(space_elixir_label), {
      padding: { left: this.applyScale(1), right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'space_elixir_label',
    });

    sizer.add(this.add.existing(neon_gold_label), {
      padding: { left: this.applyScale(1), right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'neon_gold_label',
    });

    sizer.add(this.add.existing(stardust_label), {
      padding: { left: this.applyScale(1), right: 0, top: 0, bottom: 0 },
      key: 'stardust_label',
    });

    sizer.layout();

    // Add Return Home Button
    const returnHomeButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, 'pvp_return_home_button', true, 1.05).setScale(
      this._scale,
    );
    returnHomeButton.onClick().subscribe(pointer => {
      UserService.getInstance()
        .getUserData(true)
        .then(res => {
          this.unloadAssets();
          this.scene.start('HomebaseScene');
          this.scene.launch('HudScene');
          this.scene.stop();
        });
    });
    this.add.existing(returnHomeButton);
  }

  private applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Game_Over_Scene_Assets));
  }
}
