import ApiProxyService from './ApiProxyService';
import util from 'util';

const starLabBoosts = [
  0, // Level 0
  1.5, // Level 1
  3, // Level 2
  4.5, // Level 3
  6, // Level 4
  7.5, // Level 5
  10, // Level 6
];

const gasaEmbassyBoosts = [
  0, // Level 0
  5, // Level 1
  10, // Level 2
  15, // Level 3
  20, // Level 4
  30, // Level 5
  45, // Level 6
];

const goohBoosts = [
  0, // Level 0
  20, // Level 1
  40, // Level 2
  60, // Level 3
  80, // Level 4
  100, // Level 5
];

const kingmakerBoosts = [
  0, // Level 0
  1.15, // Level 1
  1.25, // Level 2
  1.5, // Level 3
];

const kumaShrineBoosts = [
  0, // Level 0
  15000, // Level 1
  50000, // Level 2
  100000, // Level 3
];

const littleLemonBoosts = [
  0, // Level 0
  15000, // Level 1
  50000, // Level 2
  100000, // Level 3
];

const acceleratorBoosts = [
  0, // Level 0
  2, // Level 1
  2.5, // Level 2
  3, // Level 3
  3.5, // Level 4
  4, // Level 5
  5, // Level 6
];

const metaverseHQBoosts = [
  0, // Level 0
  2.5, // Level 1
  5, // Level 2
  7.5, // Level 3
  10, // Level 4
];

const savedSoulsBoosts = [
  0, // Level 0
  5, // Level 1
  7.5, // Level 2
  10, // Level 3
];

const shieldBatteryBoosts = [
  0, // Level 0
  1, // Level 1
  1, // Level 2
  1, // Level 3
  2, // Level 4
  2, // Level 5
  2, // Level 6
  4, // Level 7
  4, // Level 8
  4, // Level 9
  6, // Level 10
];

const photonShieldBoosts = [
  0, // Level 0
  1, // Level 1
  2, // Level 2
  3, // Level 3
  4, // Level 4
  5, // Level 5
  6, // Level 6
  7, // Level 7
  8, // Level 8
  9, // Level 9
  10, // Level 10
];

const dimensionXBoosts = [
  0, // Level 0
  5, // Level 1
  10, // Level 2
  15, // Level 3
];

const zenGardenBoosts = [
  0, // Level 0
  5, // Level 1
  10, // Level 2
  15, // Level 3
];

export default class BoostService {
  private static _instance: BoostService = new BoostService();

  private _boostInfo: any = [];

  constructor() {
    if (BoostService._instance) {
      throw new Error('Error: Instantiation failed: Use BoostService.getInstance() instead of new.');
    }
    BoostService._instance = this;
    this.setBoostInfo();
  }

  public static getInstance(): BoostService {
    return BoostService._instance;
  }

  private getStarLabBoost = starLab => {
    return {
      boostValue: starLabBoosts[parseInt(starLab.level)],
      extraHighlightLength: 1,
    };
  };

  private getGoohBoost = goohRefinery => {
    return {
      boostValue: goohBoosts[parseInt(goohRefinery.level)],
      extraHighlightLength: 1,
    };
  };

  private getKingmakerBoost = kingmaker => {
    return {
      boostValue: kingmakerBoosts[parseInt(kingmaker.level)],
      extraHighlightLength: 1,
    };
  };

  private getAcceleratorBoost = accelerator => {
    return {
      boostValue: acceleratorBoosts[parseInt(accelerator.level)],
      extraHighlightLength: 1,
    };
  };

  private getGasaEmbassyBoost = gasaEmbassy => {
    return {
      boostValue: gasaEmbassyBoosts[parseInt(gasaEmbassy.level)],
      extraHighlightLength: 1,
    };
  };

  private getMVHQBoost = metaverse => {
    return {
      boostValue: metaverseHQBoosts[parseInt(metaverse.level)],
      extraHighlightLength: 1,
    };
  };

  private getShieldBatteryBoost = shieldBattery => {
    return {
      boostValue: shieldBatteryBoosts[parseInt(shieldBattery.level)],
      extraHighlightLength: 1,
    };
  };

  private getKumaShrineBoost = kumaShrine => {
    return {
      boostValue: kumaShrineBoosts[parseInt(kumaShrine.level)],
      extraHighlightLength: 1,
    };
  };

  private getLittleLemonBoost = littleLemon => {
    return {
      boostValue: littleLemonBoosts[parseInt(littleLemon.level)],
      extraHighlightLength: 1,
    };
  };

  private getPhotonShieldBoost = photonShield => {
    return {
      boostValue: photonShieldBoosts[parseInt(photonShield.level)],
      extraHighlightLength: 1,
    };
  };

  private getSavedSoulsBoost = savedSoul => {
    return {
      boostValue: savedSoulsBoosts[parseInt(savedSoul.level)],
      extraHighlightLength: 1,
    };
  };

  private getDimensionXBoost = dimX => {
    return {
      boostValue: dimensionXBoosts[parseInt(dimX.level)],
      extraHighlightLength: 1,
    };
  };

  private getZenGardenBoost = zen_garden => {
    return {
      boostValue: zenGardenBoosts[parseInt(zen_garden.level)],
      extraHighlightLength: 1,
    };
  };

  private setBoostInfo(): void {
    this._boostInfo = [
      {
        machine_id: 1007,
        key: 'star_laboratory',
        shopText: 'Increases Building Speed for 30 mins.',
        upgradeText: 'Increases Building Speed by ${value}x for 30 mins.',
        highlightStartIndex: 28,
        valueFunc: this.getStarLabBoost,
      },
      {
        machine_id: 1013,
        key: 'gasa_embassy',
        shopText: 'Yields 5 Stardust passively per day.',
        upgradeText: 'Yields ${value} Stardust passively per day.',
        highlightStartIndex: 7,
        valueFunc: this.getGasaEmbassyBoost,
      },
      {
        machine_id: 1019,
        key: 'gooh_refinery',
        shopText: 'Increases the Stardust output of GASA Embassies.',
        upgradeText: 'Increases the Stardust output of GASA Embassies by ${value}%.',
        highlightStartIndex: 51,
        valueFunc: this.getGoohBoost,
      },
      {
        machine_id: 1020,
        key: 'alchemist_lab',
        shopText: 'Increases the amount of active builders by 1.',
        upgradeText: 'Increases the amount of active builders by 1.',
        highlightStartIndex: 43,
        highlightLength: 1,
      },
      {
        machine_id: 1021,
        key: 'brawler_cage',
        shopText: 'Increases the rewards gained from PVP, PVE, Boss Battles, and Battle Royale by 10%.',
        upgradeText: 'Increases the rewards gained from PVP, PVE, Boss Battles, and Battle Royale by 10%.',
        highlightStartIndex: 78,
        highlightLength: 3,
      },
      {
        machine_id: 1022,
        key: 'mother_wolf_statue',
        shopText: 'Allows you to claim 5 Stardust every 12 hours.',
        upgradeText: 'Allows you to claim 5 Stardust every 12 hours.',
        highlightStartIndex: 20,
        highlightLength: 1,
      },
      {
        machine_id: 1029,
        key: 'kaiju_kingmaker',
        shopText: 'Multiplies the damage of your squad in Boss Battles.',
        upgradeText: 'Multiplies the damage of your squad in Boss Battles by ${value}x.',
        highlightStartIndex: 55,
        valueFunc: this.getKingmakerBoost,
      },
      {
        machine_id: 1030,
        key: 'little_lemon_tree',
        shopText: 'Allows you to claim Neon Gold every 12 hours.',
        upgradeText: 'Allows you to claim Neon Gold every 12 hours.',
        highlightStartIndex: 20,
        highlightLength: 9,
      },
      {
        machine_id: 1030,
        key: 'little_lemon_tree',
        shopText: 'Allows you to claim 15,000 Neon Gold every 12 hours.',
        upgradeText: 'Allows you to claim ${value} Neon Gold every 12 hours',
        highlightStartIndex: 20,
        valueFunc: this.getLittleLemonBoost,
      },
      {
        machine_id: 1031,
        key: 'neon_gold_accelerator',
        shopText: 'Boosts your Neon Gold production for 2 hours (Level 6 by 3 hours).',
        upgradeText: 'Boosts your Neon Gold production by ${value}x for 2 hours (Level 6 by 3 hours).',
        highlightStartIndex: 36,
        valueFunc: this.getAcceleratorBoost,
      },
      {
        machine_id: 1032,
        key: 'space_elixir_accelerator',
        shopText: 'Boosts your Space Elixir production for 2 hours (Level 6 by 3 hours).',
        upgradeText: 'Boosts your Space Elixir production by ${value}x for 2 hours (Level 6 by 3 hours).',
        highlightStartIndex: 39,
        valueFunc: this.getAcceleratorBoost,
      },
      {
        machine_id: 1039,
        key: 'mvhq',
        shopText: 'Reduces machine build & upgrade time and cost by 2.5%.',
        upgradeText: 'Reduces machine build & upgrade time and cost by ${value}%.',
        highlightStartIndex: 49,
        valueFunc: this.getMVHQBoost,
      },
      {
        machine_id: 1040,
        key: 'kuma_ancestor_shrine',
        shopText: 'Allows you to claim 15,000 Space Elixir every 12 hours.',
        upgradeText: 'Allows you to claim ${value} Space Elixir every 12 hours',
        highlightStartIndex: 20,
        valueFunc: this.getKumaShrineBoost,
      },
      {
        machine_id: 1043,
        key: 'shield_battery',
        shopText: 'Activates your photon shield for 1h.',
        upgradeText: 'Activates your photon shield for ${value}h.',
        highlightStartIndex: 33,
        valueFunc: this.getShieldBatteryBoost,
      },
      {
        machine_id: 1044,
        key: 'photon_shield',
        shopText: 'Boosts the HP of your homebase by 1%.',
        upgradeText: 'Boosts the HP of your homebase by ${value}%.',
        highlightStartIndex: 34,
        valueFunc: this.getPhotonShieldBoost,
      },
      {
        machine_id: 1045,
        key: 'saved_souls',
        shopText: 'Increases the rewards gained from Base Raids by 5%',
        upgradeText: 'Increases the rewards gained from Base Raids by ${value}%',
        highlightStartIndex: 48,
        valueFunc: this.getSavedSoulsBoost,
      },
      {
        machine_id: 1046,
        key: 'dimension_x',
        shopText: 'Boosts Mining Mission efficiency by 5%.',
        upgradeText: 'Boosts Mining Mission efficiency by ${value}%.',
        highlightStartIndex: 36,
        valueFunc: this.getDimensionXBoost,
      },
      {
        machine_id: 1048,
        key: 'zen_garden',
        shopText: 'Increase Base Raiding Cooldown by 5%.',
        upgradeText: 'Increase Base Raiding Cooldown by ${value}%.',
        highlightStartIndex: 34,
        valueFunc: this.getZenGardenBoost,
      },
      {
        machine_id: 1053,
        key: 'nine_lives_lounge',
        shopText: 'Recover resources from failed mining missions once every 24 hrs.',
        upgradeText: 'Recover resources from failed mining missions once every 24 hrs.',
      },
    ];
  }

  private setDynamicBoostText(machine, boost) {
    const value = boost.valueFunc(machine);
    const dynamicBoost = JSON.parse(JSON.stringify(boost));

    dynamicBoost.upgradeText = boost.upgradeText.replace('${value}', value.boostValue);
    dynamicBoost.highlightLength = value.boostValue.toString().length + value.extraHighlightLength;

    return dynamicBoost;
  }

  public getBoostInfoSync() {
    return this._boostInfo;
  }

  public getMachineBoosts(machine) {
    const boost = this._boostInfo.find(boost => boost.machine_id === machine.machine_id);

    if (!boost) {
      return {
        shopText: 'This machine has no additional boosts currently.',
        upgradeText: 'This machine has no additional boosts currently.',
      };
    }

    if (boost.valueFunc !== undefined) {
      return this.setDynamicBoostText(machine, boost);
    }

    return boost;
  }
}
