import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';

// Model Details
const modalHeight = 494;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x7d57e8;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

// Image Background Details
const imageWidth = 116;
const imageHeight = 146;
const imageRadius = 7;
const imageColor = 0xb48dc7;
const imageBorderAlpha = 0.4;
const imageBorderColor = 0xffffff;
const imageBorderWidth = 1;

export default class BattlePassClaimResultScene extends Phaser.Scene {
  private _scale;
  private _claimResult;
  private _reward;
  private _itemImage;

  private _sizer;
  private _title;

  init(data) {
    this._claimResult = data.claimResult;
    this._reward = data.reward;
  }

  constructor() {
    super({
      key: 'BattlePassClaimResultScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50); // 50 accounts for button extending beyond modal box
  }

  preload() {}

  create() {
    this.createComponent();
  }

  createComponent() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Sizer for content
    this._sizer = this.rexUI.add.overlapSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    var imageSizer = this.createImageComponent();
    this._sizer.add(imageSizer, {
      key: 'image-sizer',
      align: 'left-top',
      offsetX: this.applyScale(189),
      offsetY: this.applyScale(44),
      expand: false,
    });

    // Text Section
    this._title = this.add.bitmapText(0, 0, 'cc_outline', this.getTitleText(), this.applyScale(22), 1).setAlpha(0.8);
    this._sizer.add(this.add.existing(this._title), {
      key: 'title',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(238),
      expand: false,
    });

    // Add Description Text
    this._sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this._reward.rewardDescription, this.applyScale(24), 1).setMaxWidth(300), {
      key: 'desc_text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(280),
      expand: false,
    });

    // Add Return Home Button
    const returnHomeButton = new Button(this, 0, 0, 'squad_confirm_button', false).setScale(this._scale);
    returnHomeButton.onClick().subscribe(pointer => {
      // return to battle pass scene
      this.scene.stop();
      this.scene.stop('BattlePassItemClaimScene');
      this.scene.get('BattlePassScene').input.enabled = true;
      this.scene.resume('BattlePassScene');
      this.scene.get('BattlePassScene').refreshBattlepassData(this._reward.level);
    });

    this._sizer.add(this.add.existing(returnHomeButton), {
      key: 'claim-button',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(398),
      expand: false,
    });

    this._sizer.layout();
  }

  createImageComponent() {
    var imageSizer = this.rexUI.add.overlapSizer({
      x: 0,
      y: 0,
      width: this.applyScale(imageWidth),
      height: this.applyScale(imageHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    const imageBackground = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(imageWidth), // width
      this.applyScale(imageHeight), // height
      this.applyScale(imageRadius), // radius
      imageColor, // fillColor
    );
    imageBackground.setStrokeStyle(imageBorderWidth, imageBorderColor, imageBorderAlpha);

    imageSizer.add(imageBackground, {
      key: 'image-bg',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._itemImage = this.add.image(0, 0, this._reward.imageKey).setScale(this._scale);

    imageSizer.add(this._itemImage, {
      key: 'bp-image',
      align: 'center',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    return imageSizer;
  }

  private getTitleText() {
    if (this._claimResult.success) {
      return 'Successfully Claimed:';
    }
    return 'Failed';
  }

  private applyScale(length) {
    return length * this._scale;
  }
}
