import Phaser from 'phaser';
import SquadMemberComponent from '~/scenes/modals/squad_modal/squadMemberComponent';
import { FixWidthSizer, OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import SquadService from '~/services/SquadService';
import util from 'util';
import CollectionService from '~/services/CollectionService';
import NFTService from '~/services/NFTService';
import { UnloadImages } from '~/utils/AssetManager';
import PaginationControls from '~/components/pagination/paginationControls';
import LoadingIndicator from '~/components/loading/loadingIndicator';

const noMember = {
  id: 0,
  nft_collection: 'Star Wolvez Generative',
  display_name: 'None Selected       ',
  image_key: 'starwolf_silhouette',
  show_yield: false,
  show_hp: false,
  disabled: true,
};

const paneHeight = 805;
const paneWidth = 566;

const pageSize = 30;

export default class SquadContentPane extends FixWidthSizer {
  private table: any;
  private _title: Phaser.GameObjects.BitmapText;
  private _squadMember1;
  private _squadMember2;
  private _squadMember3;
  private _nftData;
  private _squadData;
  private _squadMembers;
  private _inactiveMembers;
  private _squadMemberComponent1;
  private _squadMemberComponent2;
  private _squadMemberComponent3;
  private _table;
  private _scale;
  private _availableCollections;
  private _selectedCollection;
  private _noAvailableNftsLabel;
  private _pagingControls;
  private _currentPage;
  private _totalNftCount;
  private _loadingIndicator;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          height: paneHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };
    super(scene, x, y, conf);

    this._selectedCollection = data.selectedCollection;
    this._squadData = SquadService.getInstance().getSquadSync();
    this._availableCollections = CollectionService.getInstance().getCollections();
    this._currentPage = 1;
    this._nftData = this.filterNFTs(data.nfts);
    this._totalNftCount = data.totalNftCount;

    this._scale = paneScale;

    console.log('adding title');
    // Title
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Active Squad', this.applyScale(22), 0).setOrigin(0);

    this.add(this._title, {
      key: 'title',
      padding: { left: 0, right: 0, top: scene.applyScale(27), bottom: 0 },
    });

    this._squadMembers = this._squadData.map(sqMem => {
      sqMem.show_yield = sqMem.id === 0 ? false : false;
      sqMem.show_hp = sqMem.id === 0 ? false : true;
      sqMem.disabled = sqMem.id === 0 ? true : false;
      return sqMem;
    });

    this._inactiveMembers = this.getInactiveMembers();

    // Active Squad Components
    var squadMemberSizer = this.scene.rexUI.add.sizer({ width: scene.applyScale(566) });

    this._squadMemberComponent1 = new SquadMemberComponent(scene, 0, 0, this._squadMembers[0], this._scale, false);
    this._squadMemberComponent2 = new SquadMemberComponent(scene, 0, 0, this._squadMembers[1], this._scale, false);
    this._squadMemberComponent3 = new SquadMemberComponent(scene, 0, 0, this._squadMembers[2], this._scale, false);

    this._squadMemberComponent1.onClick().subscribe(pointer => {
      if (this._squadMembers[0].display_name === noMember.display_name) {
        return;
      }

      this.removeSquadMember(scene, 0, this._scale);
    });

    this._squadMemberComponent2.onClick().subscribe(pointer => {
      if (this._squadMembers[1].display_name === noMember.display_name) {
        return;
      }

      this.removeSquadMember(scene, 1, this._scale);
    });

    this._squadMemberComponent3.onClick().subscribe(pointer => {
      if (this._squadMembers[2].display_name === noMember.display_name) {
        return;
      }

      this.removeSquadMember(scene, 2, this._scale);
    });

    squadMemberSizer
      .add(scene.add.existing(this._squadMemberComponent1.layout()), {
        key: 'sm_comp_1',
        padding: { left: scene.applyScale(0), right: 0, top: 0, bottom: 0 },
      })
      .addSpace();

    squadMemberSizer
      .add(scene.add.existing(this._squadMemberComponent2.layout()), {
        key: 'sm_comp_2',
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
      })
      .addSpace();

    squadMemberSizer.add(scene.add.existing(this._squadMemberComponent3.layout()), {
      key: 'sm_comp_3',
      padding: { left: 0, right: scene.applyScale(35), top: 0, bottom: 0 },
    });

    this.add(squadMemberSizer, {
      key: 'sm_sizer',
      padding: { left: 0, right: 0, top: scene.applyScale(12), bottom: 0 },
    });

    // Divider
    const divider = scene.add.image(0, 0, 'squad_divider_line').setScale(this._scale);

    this.add(divider, {
      key: 'divider',
      padding: { left: 0, right: 0, top: scene.applyScale(8), bottom: 0 },
    });

    // Substitutions Label
    var subsTitle = scene.add.bitmapText(0, 0, 'cc_outline', 'Your Available NFTs', this.applyScale(22), 0).setOrigin(0);

    this.add(subsTitle, {
      key: 'subsTitle',
      padding: { left: 0, right: 0, top: scene.applyScale(22), bottom: 0 },
    }).addNewLine();

    // Pagination Controls
    if (this._totalNftCount > pageSize) this.addPagingButtons(scene);

    // Inactive squad table
    this.createTable(scene, this._inactiveMembers, 0, 0, this._scale);
  }

  applyScale(length) {
    return length * this._scale;
  }

  createTable(scene, data, x, y, paneScale) {
    this._table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(531),
      height: this._totalNftCount > pageSize ? this.applyScale(paneHeight - 359 - 30) : this.applyScale(paneHeight - 359),
      items: data,

      table: {
        cellWidth: this.applyScale(177),
        cellHeight: this.applyScale(225),
        columns: 3,
        clamplTableOXY: false,
        reuseCellContainer: true,
        scrollMode: 0,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        var sqComp = new SquadMemberComponent(scene, x, y, item, scene.getScale());

        sqComp.onClick().subscribe(pointer => {
          scene.addSquadMember(item);
        });

        return scene.add.existing(sqComp);
      },
    });

    this._table.setItems(data).scrollToTop();
    this._table.layout();

    this.add(this._table, {
      key: 'inactive_members_table',
      padding: { left: 0, right: 0, top: scene.applyScale(12), bottom: 0 },
    }).layout();
  }

  private addPagingButtons(scene: Phaser.Scene) {
    this._pagingControls = new PaginationControls(scene, 0, 0, { totalCount: this._totalNftCount, pageSize: pageSize }, 501, this._scale);

    this.add(this._pagingControls, {
      key: 'paging_controls',
      padding: { left: scene.applyScale(15), right: 0, top: scene.applyScale(7), bottom: scene.applyScale(0) },
    });
  }

  private addNoNftsLabel(scene: Phaser.Scene) {
    this._noAvailableNftsLabel = scene.add
      .bitmapText(0, 0, 'cc_outline', 'You have none of this collection to assign to a squad.', this.applyScale(20), 0)
      .setOrigin(0)
      .setAlpha(0.4);

    this.add(this._noAvailableNftsLabel, {
      key: 'no_nfts_label',
      padding: { left: 0, right: 0, top: scene.applyScale(20), bottom: 0 },
    }).layout();
  }

  private addSquadMember(scene, item, scale) {
    if (this._squadMembers[0].display_name === noMember.display_name) {
      this._squadMembers[0] = Object.assign({}, item, { show_hp: true, show_yield: false });
      this.refreshSquadMembers(this._squadMemberComponent1, 0);
      return;
    }
    if (this._squadMembers[1].display_name === noMember.display_name) {
      this._squadMembers[1] = Object.assign({}, item, { show_hp: true, show_yield: false });
      this.refreshSquadMembers(this._squadMemberComponent2, 1);
      return;
    }
    if (this._squadMembers[2].display_name === noMember.display_name) {
      this._squadMembers[2] = Object.assign({}, item, { show_hp: true, show_yield: false });
      this.refreshSquadMembers(this._squadMemberComponent3, 2);
      return;
    }
  }

  private removeSquadMember(scene, index, scale) {
    this._squadMembers[index] = Object.assign({}, noMember);
    this.refreshSquadMembers(this[`_squadMemberComponent${index + 1}`], index);
    return;
  }

  private filterNFTs(data: any) {
    return data.filter(nft =>
      this._availableCollections
        .flatMap(col => {
          return col.collectionSlugs;
        })
        .includes(nft.nft_slug),
    );
  }

  private sortByCollection(nftData) {
    return nftData.sort((a, b) => {
      if (a.nft_collection === 'Star Wolvez Genesis' || a.nft_collection === 'Star Wolvez Generative') {
        return -1;
      }
      if (b.nft_collection === 'Star Wolvez Genesis' || b.nft_collection === 'Star Wolvez Generative') {
        return 1;
      }
      return 0;
    });
  }

  private refreshSquadMembers(component, index, forceUpdate = false) {
    component.setSquadMemberData(this._squadMembers[index]);
    this.updateInactiveMembers();
  }

  private updateInactiveMembers() {
    this._inactiveMembers = this.getInactiveMembers();

    this._table?.setItems(this._inactiveMembers);
  }

  private getInactiveMembers() {
    if (this._squadMembers) {
      return this.sortByCollection(this._nftData)
        .filter(nft => {
          return !this._squadMembers.map(sm => sm.id).includes(nft.id);
        })
        .filter(inactiveNft => {
          return this._selectedCollection.collectionSlugs.includes(inactiveNft.nft_slug);
        })
        .map(inSqMem => {
          inSqMem.show_hp = true;
          inSqMem.show_yield = false;
          inSqMem.disabled = this.checkSquadFull();
          return inSqMem;
        });
    } else {
      return this._nftData
        .sortByCollection(this._nftData)
        .filter(inactiveNft => {
          return this._selectedCollection.collectionSlugs.includes(inactiveNft.nft_slug);
        })
        .map(inSqMem => {
          inSqMem.show_hp = true;
          inSqMem.show_yield = false;
          inSqMem.disabled = this.checkSquadFull();
          return inSqMem;
        });
    }
  }

  private fetchNftData(startIndex) {
    return NFTService.getInstance()
      .getNFTs(undefined, this._selectedCollection.collectionSlugs, startIndex, pageSize)
      .then(res => {
        const nfts = res.nfts;
        const squadMemsNotInData = this._squadMembers.filter(sm => !nfts.map(nft => nft.id).includes(sm.id));

        this._nftData = squadMemsNotInData && squadMemsNotInData.length > 0 ? nfts.concat(squadMemsNotInData) : nfts;
        this._totalNftCount = res.max_count_returned;

        return this._nftData;
      });
  }

  updatePage(pageNum: number) {
    this._currentPage = pageNum;
    this.refreshPane(true);
  }

  setCollection(selectedCollection: any) {
    if (selectedCollection) {
      // Update collection and inactive members
      this._selectedCollection = selectedCollection;
      this._currentPage = 1;

      // Refresh Content Pane
      this.refreshPane();
    }
  }

  refreshPane(isPageUpdate?: boolean) {
    const sqMemIds = this._squadMembers.map(sm => sm.marketplace_nft_id);
    const oldNfts = this._nftData.filter(nft => sqMemIds.includes(nft.marketplace_nft_id));

    // Remove Table
    this.removeTable();

    // Remove 'No NFTs label' if it exists
    this.removeNoNftsLabel();

    // Remove pagination controls if only 1 page of data
    if (!isPageUpdate) {
      this.removePaginationButtons();
    }

    // Add loading indicator
    this._loadingIndicator = new LoadingIndicator(this.scene, 0, 0, 0.8 * this._scale);
    this.add(this._loadingIndicator, {
      key: 'loading_indicator',
      padding: { left: this.applyScale(200), top: this.applyScale(100), right: 0, bottom: 0 },
    }).layout();

    this.fetchNftData((this._currentPage - 1) * pageSize).then(() => {
      this._inactiveMembers = this.getInactiveMembers();

      // Load new nft images and remove old nft images
      this.scene.scene.launch('NftPageLoaderScene', {
        originating_scene: 'SquadModalScene',
        nfts: this._inactiveMembers,
        old_nfts: oldNfts,
      });
    });
  }

  onPageLoaded() {
    this._loadingIndicator.setAlpha(0);
    this.remove(this._loadingIndicator, true);
    this._loadingIndicator = undefined;

    this._pagingControls?.enableButtons();

    if (!this._inactiveMembers || this._inactiveMembers.length === 0) {
      // Show 'No NFTs' label
      this.addNoNftsLabel(this.scene);
      return;
    }

    if (this._totalNftCount > pageSize && !this._pagingControls) {
      this.addPagingButtons(this.scene);
    }
    // Create Table
    this.createTable(this.scene, this._inactiveMembers, 0, 0, this._scale);
  }

  private removeTable() {
    // Remove table if it exists
    this._table?.setAlpha(0);
    this.remove(this._table, true);
    this._table = undefined;
  }

  private removeNoNftsLabel() {
    if (this._noAvailableNftsLabel) {
      this._noAvailableNftsLabel.setAlpha(0);
      this.remove(this._noAvailableNftsLabel, true);
      this._noAvailableNftsLabel = undefined;
    }
  }

  private removePaginationButtons() {
    if (this._pagingControls) {
      this._pagingControls.setAlpha(0);
      this.remove(this._pagingControls, true);
      this._pagingControls = undefined;
    }
  }

  private checkSquadFull() {
    if (this._squadMembers[0].id === noMember.id || this._squadMembers[1].id === noMember.id || this._squadMembers[2].id === noMember.id) {
      return false;
    }

    return true;
  }

  private setNftData(nftData: any) {
    if (nftData) {
      this._nftData = nftData;
    }
  }

  private getCurrentSquad() {
    return this._squadMembers;
  }
}

Phaser.GameObjects.GameObjectFactory.register('squadContentPane', function (x: number, y: number, playersData: any) {
  // @ts-ignore
  return this.displayList.add(new SquadContentPane(this.scene, x, y, playersData));
});
