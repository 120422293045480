import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

// Rank
const height = 43;
const width = 308;
const radius = 11;
const backgroundColor = 0x383d57;
const alpha = 100;

export default class BattlePassXPComponent extends OverlapSizer {
  private _scale;

  // UI
  private _background;
  private _divider;
  private _text;
  private _xp;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(width),
      height: scene.applyScale(height),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Background
    this._background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(width), // width
      scene.applyScale(height), // height
      scene.applyScale(radius), // radius
      backgroundColor, // fillColor
      alpha, // alpha
    );

    this.add(this._background, {
      key: 'background',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._divider = scene.add.image(0, 0, 'battlepass_xp_component_divider').setScale(this._scale);

    this.add(this._divider, {
      key: 'bp-xp-divider',
      align: 'left-top',
      offsetX: this.applyScale(6),
      offsetY: this.applyScale(3),
      expand: false,
    });

    // Battle Pass XP Label
    var label = scene.rexUI.add.label({
      orientation: 'x',
      //rtl: this.rtl,
      text: scene.add.bitmapText(0, 0, 'cc_outline', 'Battle Pass Level', scene.applyScale(16), 0),
    });

    this.add(label, {
      key: 'bp-label',
      align: 'left-top',
      offsetX: this.applyScale(9),
      offsetY: this.applyScale(14),
      expand: false,
    });

    // XP Label
    var xpLabel = scene.rexUI.add.label({
      orientation: 'x',
      //rtl: this.rtl,
      text: scene.add.bitmapText(0, 0, 'cc_outline', data, scene.applyScale(16), 0),
    });

    this.add(xpLabel, {
      key: 'bp-xp-label',
      align: 'left-top',
      offsetX: this.applyScale(243),
      offsetY: this.applyScale(14),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassXPComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new battlePassXPComponent(this.scene, x, y, data));
});
