import Phaser from 'phaser';
import Button from '../../../components/buttons/Button';
import util from 'util';
import ScaleService from '~/services/ScaleService';
import { Blob_Hud_Assets } from '~/utils/AssetLoader';
import SquadContentPane from '../squad_modal/squadContentPane';
import UpgradeBlobContentPane from './upgradeBlobContentPane';
import BlobService from '~/services/BlobService';

const modalHeight = 642;
const modalWidth = 814;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

const spineWidth = 248;
const spineColor = 0xe6e6ff;
const spineAlpha = 0.05;

const grayTextColor = 0x75758B;

export default class UpgradeBlobModalScene extends Phaser.Scene {
  private _blobInfo;
  private _confirmButton;
  private _upgradeBlobContentPane;
  private _scale;

  constructor() {
      super({
          key: 'UpgradeBlobModalScene'
      });
  }

  preload() {
    this.load.image("add_points_button", Blob_Hud_Assets.add_points_button);
    this.load.image("remove_points_button", Blob_Hud_Assets.remove_points_button);
    this.load.image("blob_option_divider_line", Blob_Hud_Assets.blob_option_divider_line);
    this.load.image("lock_icon", Blob_Hud_Assets.lock_icon);
    this.load.image("battery_icon", Blob_Hud_Assets.battery_icon);
  }

  create() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this._blobInfo = BlobService.getInstance().getBlobInfoSync();

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        space: {
            left: 0, right: 0, top: 0, bottom: 0,
            item: 0, line: 0
        },
        align: 0,
        sizerEvents: true
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
        0, // x
        0, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale(modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
    );

    const spineBackground = this.add.rexRoundRectangle(
        0, // x
        0, // y
        this.applyScale(spineWidth), // width
        this.applyScale(modalHeight),// height
        {
          tl: this.applyScale(modalRadius),
          tr: 0,
          bl: this.applyScale(modalRadius),
          br: 0
        }, // radius
        spineColor, // fillColor
        spineAlpha, // alpha
    );

    // Title text
    const titleText = this.add.bitmapText(0, 0, 'cc_outline', 'UPGRADE BLOB', this.applyScale(22), 0).setOrigin(0);

    // Available Points
    const availablePointsText = this.add.bitmapText(0, 0, 'cc_outline', 'AVAILABLE POINTS', this.applyScale(20), 0)
      .setOrigin(0)
      .setTint(grayTextColor);

    const apValue = this.add.bitmapText(0, 0, 'cc_outline', this._blobInfo.unapplied_points, this.applyScale(28), 0).setOrigin(0);

    // Confirm Button
    this._confirmButton = new Button(this.scene.scene, 0, 0, 'squad_confirm_button', false).setScale(this._scale);
    this.add.existing(this._confirmButton);

    this._confirmButton.onClick().subscribe(pointer => {
        this.confirmButtonClickHandler(pointer);
    });

    this._upgradeBlobContentPane = new UpgradeBlobContentPane(
        this,
        0,
        0,
        this._scale
    );
    this.add.existing(this._upgradeBlobContentPane);

    sizer.add(
        background,
        {
          key: 'background',
          align: 'center',
          offsetX: 0,
          offsetY: 0,
          expand: false
        }
    );
    sizer.add(
        spineBackground,
        {
            key: 'spineBackground',
            align: 'center',
            offsetX: this.applyScale(-(modalWidth / 2 - (spineWidth / 2))),
            offsetY: 0,
            expand: false
        }
    );
    sizer.add(
        titleText,
        {
            key: 'titleText',
            align: 'left-top',
            offsetX: this.applyScale(18),
            offsetY: this.applyScale(31),
            expand: false
        }
    );
    sizer.add(
        availablePointsText,
        {
            key: 'apText',
            align: 'left-top',
            offsetX: this.applyScale(18),
            offsetY: this.applyScale(468),
            expand: false
        }
    );
    sizer.add(
        apValue,
        {
            key: 'apValue',
            align: 'left-top',
            offsetX: this.applyScale(18),
            offsetY: this.applyScale(494),
            expand: false
        }
    );
    sizer.add(
        this._confirmButton,
        {
            key: 'confirmButton',
            align: 'left-bottom',
            offsetX: this.applyScale(14),
            offsetY: this.applyScale(-18),
            expand: false
        }
    );
    sizer.add(
      this._upgradeBlobContentPane,
        {
            key: 'blob-content-pane',
            align: 'left-top',
            offsetX: this.applyScale(248),
            offsetY: this.applyScale(0),
            expand: false
        }
    );

    sizer.layout();
    
    sizer.onClickOutside(() => {
      this.scene.resume('HomebaseScene');
      this.scene.resume('BlobHudScene');
      this.scene.stop();
    });
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  getContentPane() {
    return this._upgradeBlobContentPane;
  }

  destroy() {

  }

  private confirmButtonClickHandler(this, pointer) {
    this.scene.resume('HomebaseScene');
    this.scene.resume('BlobHudScene');
    this.scene.stop();
  }
}