import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { Reward_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const dashboardHeight = 595;
const dashboardWidth = 914;
const dashboardRadius = 25;
const pausedShadeColor = 0x1c1c1c;
const pausedShadeAlpha = 0.5;

const modalHeight = 278;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const rewardContainerHeight = 43;
const rewardContainerWidth = 213;
const rewardContainerRadius = 11;
const rewardContainerColor = 0x201425;
const rewardContainerAlpha = 0.7;
const rewardContainerBorderColor = 0x383d57;
const rewardContainerBorderWidth = 1;

const rewardGlareHeight = 6;
const rewardGlareWidth = 203;
const rewardGlareRadius = 4;
const rewardGlareColor = 0xffffff;
const rewardGlareAlpha = 0.14;

const currencyColors = {
  neon_gold: 0xffe100,
  space_elixir: 0x00d4f7,
  stardust: 0xedffa6,
};

export default class RewardModalScene extends Phaser.Scene {
  private reward;
  private rewardCurrency;

  init(data) {
    this.reward = data.reward;
    this.rewardCurrency = data.rewardCurrency;
  }

  constructor(reward: any) {
    super({
      key: 'RewardModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, Reward_Assets);
  }

  create() {
    this.createComponent();
  }

  createComponent() {
    // Add Shade
    const pausedShade = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      dashboardWidth, // width
      dashboardHeight, // height
      dashboardRadius, // radius
      pausedShadeColor, // fillColor
      pausedShadeAlpha, // alpha
    );

    // Add Window
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      modalWidth, // width
      modalHeight, // height
      modalRadius, // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add Icon
    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, `${this.rewardCurrency}_icon_large`);

    // Add Text
    const text1 = this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 45, 'cc_outline', 'You earned:', 28, 1)
      .setOrigin(0.5, 0.5);

    // Add Reward Container
    const rewardContainer = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2, // x
        this.sys.canvas.height / 2 + 25, // y
        rewardContainerWidth, // width
        rewardContainerHeight, // height
        rewardContainerRadius, // radius
        rewardContainerColor, // fillColor
        rewardContainerAlpha, // alpha
      )
      .setStrokeStyle(rewardContainerBorderWidth, rewardContainerBorderColor, rewardContainerAlpha);

    // Add Reward Container Glare
    const rewardContainerGlare = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2 + 12, // y
      rewardGlareWidth, // width
      rewardGlareHeight, // height
      rewardGlareRadius, // radius
      rewardGlareColor, // fillColor
      rewardGlareAlpha, // alpha
    );

    // Add Reward Label
    const rewardLabel = this.rexUI.add
      .label({
        x: this.sys.canvas.width / 2,
        y: this.sys.canvas.height / 2 + 28,
        width: 200,
        height: 30,
        orientation: 'x',
        rtl: false,
        icon: this.add.image(0, 0, `${this.rewardCurrency}_icon`).setScale(0.5),
        text: this.add.bitmapText(0, 0, 'cc_outline', this.reward.toLocaleString('en-us'), 20, 0),
        space: {
          icon: 8,
        },
        align: 'center',
      })
      .layout();

    // Add Confirm Button
    const confirmButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, 'reward_confirm_button', true, 1.05);
    confirmButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.resume('ObjectivesModalScene');
      this.scene.stop();
    });
    this.add.existing(confirmButton);
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Reward_Assets));
  }
}
