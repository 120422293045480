import Button from '~/components/buttons/Button';
import MiningService, { Asteroid } from '~/services/MiningService';
import ScaleService from '~/services/ScaleService';
import { Mining_Cancel_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

type Data = {
  asteroidId: Asteroid['asteroid_id'];
};

const width = 494;
const height = 325;
const radius = 50;

export default class MiningCancelDialogScene extends Phaser.Scene {
  private _scale;
  private _asteroidId;

  constructor() {
    super({
      key: 'MiningCancelDialogScene',
    });
  }

  preload() {
    LoadImageAssets(this, Mining_Cancel_Scene_Assets);
  }

  init(data: Data) {
    this._asteroidId = data.asteroidId;
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    // Overlay
    this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c).setOrigin(0).setAlpha(0.5);

    const background = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2,
        this.applyScale(width),
        this.applyScale(height),
        this.applyScale(radius),
        0x350926,
      )
      .setStrokeStyle(1, 0x3d4361);

    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      background.getBottomLeft().y,
      'mining_cancel_dialog_cancel_button',
      false,
    ).setScale(this._scale);

    cancelButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.stop();
    });

    this.add.existing(cancelButton);

    const confirmButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(94),
      background.getBottomLeft().y,
      'mining_cancel_dialog_confirm_button',
      false,
    ).setScale(this._scale);

    confirmButton.onClick().subscribe(() => {
      MiningService.getInstance()
        .cancelMiningAsteroid(this._asteroidId)
        .then(() => {
          MiningService.getInstance()
            .getMiningStatus()
            .then(() => {
              this.scene.get('MiningStatusScene').unloadAssets();
              this.scene.stop('MiningStatusScene');
              this.scene.start('HomebaseScene');
              this.unloadAssets();
              this.scene.stop();
            });
        });
    });

    this.add.existing(confirmButton);

    this.rexUI.add
      .sizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        orientation: 'y',
        width: this.applyScale(width),
        height: this.applyScale(height),
        space: { item: this.applyScale(32) },
      })
      .addBackground(background)
      .addSpace()
      .add(this.add.bitmapText(0, 0, 'cc_outline', 'Are you sure you want to cancel?', this.applyScale(22), 1).setMaxWidth(200))
      .add(
        this.add
          .bitmapText(
            0,
            0,
            'cc_outline',
            'Please note that only 80% of the resources that you have spent to launch this Mission will be refunded.',
            this.applyScale(18),
            1,
          )
          .setTint(0xbfaac6)
          .setMaxWidth(300),
      )
      .addSpace()
      .layout();
  }

  private applyScale(length: number) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Mining_Cancel_Scene_Assets));
  }
}
