import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { BR_Failed_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 450;
const modalWidth = 387;

export default class BattleRoyaleFailedScene extends Phaser.Scene {
  private _scale;
  private _resultBadge;
  private _resultTitle;
  private _text;
  private _returnHomeButton;
  private _badgeName;
  private _badgeTitle;
  private _winnerPFP;

  init(data: any) {
    this._badgeName = 'bossfight_defeat_badge';
    this._badgeTitle = 'bossfight_defeat_title';
  }

  constructor() {
    super({
      key: 'BattleRoyaleFailedScene',
    });
  }

  preload() {
    LoadImageAssets(this, BR_Failed_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.sys.game.canvas.width * 2, // width
      this.sys.game.canvas.height * 2, // height
      0, // radius
      0x000000, // fillColor
      0.73, // alpha
    );

    this.add.existing(background);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Add Result Badge
    this._resultBadge = this.add.image(0, 0, this._badgeName).setScale(this._scale);

    sizer.add(this._resultBadge, {
      key: 'battleroyale_badge',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Add Victory Title
    this._resultTitle = this.add.image(0, 0, this._badgeTitle).setScale(this._scale);

    sizer.add(this._resultTitle, {
      key: 'battleroyale_title',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(130),
      expand: false,
    });

    // Text
    this._text = this.add.bitmapText(
      0,
      0,
      'cc_outline',
      'Battle Royale Failed to start\ndue to insufficient players\nregistered.',
      this.applyScale(28),
      1,
    );

    sizer.add(this._text, {
      key: 'battleroyale-text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(250),
      expand: false,
    });

    // Add Return Home Button
    this._returnHomeButton = new Button(this, 0, 0, 'bossfight_victory_return_home_button', true, 1.05).setScale(this._scale);

    this._returnHomeButton.onClick().subscribe(pointer => {
      this.destroy();
    });

    this.add.existing(this._returnHomeButton);

    sizer.add(this._returnHomeButton, {
      key: 'return-home-button',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  private destroy() {
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop('BattleRoyaleScene');
    this.scene.stop();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Failed_Scene_Assets));
  }
}
