import { Schema, ArraySchema } from "@colyseus/schema"
import NanoTimer from "nanotimer"
import Action from "./Action"
import PVPPlayer from "./PVPPlayer"

export enum GameState {
    GameEmpty,
    WaitingForOpponent,
    Playing,
    Tie,
    IdleTie,
    Player1Win,
    Player2Win,
}

export enum RoundState {
    Start,
    Complete,
}

export enum Rank {
    Undefined,
    Bronze,
    Silver,
    Gold,
    Platinum,
    Diamond,
}

export interface IPVPState extends Schema {
    players: ArraySchema<PVPPlayer>
    gameState: GameState
    roundState: RoundState
    rank: Rank
    round: number
    battleId: number
    countDown: number
    timer: NanoTimer
    isBotMatch: boolean
    botTimer: number
}

export default IPVPState
