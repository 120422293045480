import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import MachineService from '~/services/MachineService';
import ShopMachineComponent from './shopMachineComponent';

const modalWidth = 1008;
const modalHeight = 513;
const shopItemHeight = 361;
const shopItemWidth = 273;

const cellHeight = shopItemHeight;
const cellWidth = shopItemWidth;

const filterKeys = {
  all: 0,
  administrative: 1,
  resources: 2,
  cosmetics: 3,
  research: 4,
  holderExclusive: 5,
  army: 6,
};

const allButtonImageKey = 'shop_all_button';
const administrativeButtonImageKey = 'shop_administrative_button';
const researchButtonImageKey = 'shop_research_button';
const resourcesButtonImageKey = 'shop_resources_button';
const cosmeticsButtonImageKey = 'shop_cosmetics_button';
const armyButtonImageKey = 'shop_army_button';
const holderExclusiveButtonImageKey = 'shop_holder_exclusive_button';

const disabledButtonAlpha = 0.4;

const doublePointMachineKeys = ['neon_gold_mine', 'neon_gold_storage', 'space_elixir_mine', 'space_elixir_storage'];

export default class MachinesContentPane extends OverlapSizer {
  private _machinesData: any;
  private _filteredData: any;
  private _allButton: any;
  private _administrativeButton: any;
  private _researchButton: any;
  private _resourcesButton: any;
  private _cosmeticsButton: any;
  private _armyButton: any;
  private _holderExclusiveButton: any;
  private _table: any;
  private _scale: number;
  private _selectedFilter: number;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: modalWidth * paneScale,
          height: modalHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._machinesData = MachineService.getInstance().getAvailableMachinesSync();
    this._selectedFilter = filterKeys.all;

    this.enrichMachinesData();
    this.sortMachinesData();

    // Buttons Sizer
    var buttonSizer = this.scene.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(modalWidth - 70),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Buttons
    this._allButton = new Button(this.scene, 0, 0, allButtonImageKey, false).setScale(this._scale);
    this._allButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.all);
    });
    buttonSizer.add(this.scene.add.existing(this._allButton), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'machines_all_button',
    });

    this._administrativeButton = new Button(this.scene, 0, 0, `${administrativeButtonImageKey}_inactive`, false).setScale(this._scale);
    this._administrativeButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.administrative);
    });
    buttonSizer.add(this.scene.add.existing(this._administrativeButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_administrative_button',
    });

    this._researchButton = new Button(this.scene, 0, 0, `${researchButtonImageKey}_inactive`, false).setScale(this._scale);
    this._researchButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.research);
    });
    buttonSizer.add(this.scene.add.existing(this._researchButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_research_button',
    });

    this._resourcesButton = new Button(this.scene, 0, 0, `${resourcesButtonImageKey}_inactive`, false).setScale(this._scale);
    this._resourcesButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.resources);
    });
    buttonSizer.add(this.scene.add.existing(this._resourcesButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_resources_button',
    });

    this._cosmeticsButton = new Button(this.scene, 0, 0, `${cosmeticsButtonImageKey}_inactive`, false).setScale(this._scale);
    this._cosmeticsButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.cosmetics);
    });
    buttonSizer.add(this.scene.add.existing(this._cosmeticsButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_cosmetics_button',
    });

    this._armyButton = new Button(this.scene, 0, 0, `${armyButtonImageKey}_inactive`, false).setScale(this._scale);
    this._armyButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.army);
    });
    buttonSizer.add(this.scene.add.existing(this._armyButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_army_button',
    });

    this._holderExclusiveButton = new Button(this.scene, 0, 0, `${holderExclusiveButtonImageKey}_inactive`, false).setScale(this._scale);
    this._holderExclusiveButton.onClick().subscribe(pointer => {
      this.handleFilterClick(filterKeys.holderExclusive);
    });
    buttonSizer.add(this.scene.add.existing(this._holderExclusiveButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'machines_holder_exclusive_button',
    });

    this.add(buttonSizer, {
      key: 'button_sizer',
      align: 'left-top',
      offsetX: this.applyScale(35),
      offsetY: this.applyScale(35),
      expand: false,
    });

    // Table
    this.addTable(scene, this._machinesData);

    this.layout();

    //this._table.drawBounds(scene.add.graphics(), 0x00ff00);
  }

  applyScale(length) {
    return length * this._scale;
  }

  public hidePane() {
    this._allButton.setAlpha(0);
    this._administrativeButton.setAlpha(0);
    this._researchButton.setAlpha(0);
    this._resourcesButton.setAlpha(0);
    this._armyButton.setAlpha(0);
    this._holderExclusiveButton.setAlpha(0);
    //this.table.disableInteractive();
  }

  private addTable(scene, data) {
    // Table
    this._table = this.createTable(scene, data, 0, 0).layout();

    this.add(this._table, {
      key: 'machines_table',
      align: 'left-top',
      offsetX: this.applyScale(4), // accounts for modal border
      offsetY: this.applyScale(110),
      expand: false,
    });
  }

  handleFilterClick(selectedFilter: number) {
    if (selectedFilter === this._selectedFilter) {
      return;
    }

    this.updateFilterButtons(selectedFilter);

    if (selectedFilter === filterKeys.all) {
      this._filteredData = this._machinesData;
    } else {
      this._filteredData = this._machinesData.filter(machine => {
        return machine.machine_type_id === selectedFilter;
      });
    }

    this.remove(this._table, true);

    if (this._filteredData.length > 0) {
      this.addTable(this.scene, this._filteredData);
      this.layout();
    }

    this._selectedFilter = selectedFilter;
  }

  updateFilterButtons(selectedFilter: number) {
    this._allButton.setTexture(selectedFilter === filterKeys.all ? allButtonImageKey : `${allButtonImageKey}_inactive`);
    this._administrativeButton.setTexture(
      selectedFilter === filterKeys.administrative ? administrativeButtonImageKey : `${administrativeButtonImageKey}_inactive`,
    );
    this._researchButton.setTexture(selectedFilter === filterKeys.research ? researchButtonImageKey : `${researchButtonImageKey}_inactive`);
    this._resourcesButton.setTexture(selectedFilter === filterKeys.resources ? resourcesButtonImageKey : `${resourcesButtonImageKey}_inactive`);
    this._cosmeticsButton.setTexture(selectedFilter === filterKeys.cosmetics ? cosmeticsButtonImageKey : `${cosmeticsButtonImageKey}_inactive`);
    this._armyButton.setTexture(selectedFilter === filterKeys.army ? armyButtonImageKey : `${armyButtonImageKey}_inactive`);
    this._holderExclusiveButton.setTexture(
      selectedFilter === filterKeys.holderExclusive ? holderExclusiveButtonImageKey : `${holderExclusiveButtonImageKey}_inactive`,
    );
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(cellWidth * data.length > modalWidth - 8 ? modalWidth - 8 : cellWidth * data.length - 1),
        height: this.applyScale(cellHeight),
        items: data,
        scrollMode: 1,

        table: {
          cellWidth: this.applyScale(cellWidth),
          cellHeight: this.applyScale(cellHeight),
          columns: 1,
          clamplTableOXY: false,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.4,
        },

        space: {
          table: {
            left: this.applyScale(23),
            right: 0,
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          var machineComponent = new ShopMachineComponent(scene, 0, 0, item, scene.getScale());
          scene.add.existing(machineComponent);

          return machineComponent;
        },
      })
      .setOrigin(0, 0.5);

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }

  private enrichMachinesData() {
    var tempData = this._machinesData.map(mach => {
      return Object.assign({}, mach, { numberAvailable: this.getNumberOfMachinesAvailable(mach), maxNumber: this.getMaxNumberOfMachines(mach) });
    });

    this._machinesData = tempData;
  }

  private getNumberOfMachinesAvailable(shopMachine: any) {
    var max = this.getMaxNumberOfMachines(shopMachine);

    var totalUserMachines = 0;
    MachineService.getInstance()
      .getUserMachinesSync()
      .filter(machine => machine.key === shopMachine.key)
      .forEach(mach => {
        if (mach.level > 10 && doublePointMachineKeys.includes(mach.key)) {
          totalUserMachines += 2;
        } else {
          totalUserMachines++;
        }
      });

    return max - totalUserMachines < 0 ? 0 : max - totalUserMachines;
  }

  private getMaxNumberOfMachines(shopMachine: any) {
    var max = shopMachine.max_allowed_from_mf_level;
    return max && max > 0 ? max : 1;
  }

  private sortMachinesData() {
    this._machinesData.sort((a, b) => {
      if (!a.key) return -1;
      if (!b.key) return 1;
      if (a.numberAvailable > 0 && b.numberAvailable === 0) return -1;
      if (a.numberAvailable > 0 && b.numberAvailable > 0) return 1;
      if (a.numberAvailable === 0 && b.numberAvailable === 0) return 1;
      if (a.numberAvailable === 0 && b.numberAvailable > 0) return 1;
      return 1;
    });
  }
}

Phaser.GameObjects.GameObjectFactory.register('machinesContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new MachinesContentPane(this.scene, x, y));
});
