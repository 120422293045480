import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';
import BattlePassItemComponent from '../../components/battlepass/battlePassItemComponent';

// Pane Details
const paneHeight = 350;
const paneWidth = 1512;
const paneRadius = 26;
const paneColor = 0x053165;
const paneAlpha = 0.9;

// Table Details
const tableHeight = 257;
const tableWidth = 1380;
const itemWidth = 116;

// Scroll Distance
const nextScrollDistance = -1390.11;
const previousScrollDistance = 1390.11;

export default class BattlePassLevelsPane extends OverlapSizer {
  private _scale: number;
  private _levelsTable;
  private _nextButton;
  private _previousButton;
  private _rowIndex = -1;

  private _claimedRewardLevel;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._claimedRewardLevel = data.claimedLevel;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Sizer
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Levels Table
    this._levelsTable = this.createLevelsTable(this.scene, data, x, y, this.scale).layout();

    // Determines if a reward has been claimed and return levels table index of said claimed reward
    this._rowIndex = this.calculateRowIndex(this._levelsTable.startRowIndex);
    this._levelsTable.scrollToRow(this._rowIndex);
    this._levelsTable.setScrollerEnable(false);

    sizerPanel.add(this._levelsTable, {
      key: 'levels-panel',
      align: 'left-top',
      offsetX: this.applyScale(79),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Buttons
    this._nextButton = new Button(scene, 0, 0, 'battlepass_next_page_button_active', false).setScale(this._scale);
    this._nextButton.onClick().subscribe(pointer => {
      if (!(this._rowIndex + 10 > 99)) {
        this._rowIndex += 9.999;
        this._levelsTable.scrollToRow(this._rowIndex);
      }
    });

    sizerPanel.add(scene.add.existing(this._nextButton), {
      key: 'next-button',
      align: 'left-top',
      offsetX: this.applyScale(1279),
      offsetY: this.applyScale(290),
      expand: false,
    });

    this._previousButton = new Button(scene, 0, 0, 'battlepass_previous_page_button_active', false).setScale(this._scale);
    this._previousButton.onClick().subscribe(pointer => {
      if (!(this._rowIndex - 10 < -1)) {
        this._rowIndex -= 9.999;
        this._levelsTable.scrollToRow(this._rowIndex);
      }
    });

    sizerPanel.add(scene.add.existing(this._previousButton), {
      key: 'previous-button',
      align: 'left-top',
      offsetX: this.applyScale(1050),
      offsetY: this.applyScale(290),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private createLevelsTable(scene, data, x, y, scale) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(tableWidth),
        height: this.applyScale(tableHeight),
        items: data.rewards,
        scrollMode: 1,

        table: {
          cellWidth: this.applyScale(116 + 23),
          cellHeight: this.applyScale(257),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },
        // mouseWheelScroller: {
        //   focus: true,
        //   speed: 0.2,
        // },
        space: {
          table: {
            left: this.applyScale(0),
            right: this.applyScale(0),
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          var sizer = scene.rexUI.add.fixWidthSizer({
            width: scene.applyScale(149),
            space: {
              left: 0,
              right: scene.applyScale(23),
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
          });

          var itemComponent = new BattlePassItemComponent(
            scene,
            0,
            0,
            { item: item, level: data.level, battlepassXP: data.battlepassXP },
            scene.getScale(),
          );
          sizer.add(itemComponent, {
            padding: { left: 0, right: 0, top: 0, bottom: 0 },
            key: 'item_component',
          });

          return sizer;
        },
      })
      .setOrigin(0, 0.5);

    table.setItems(data.rewards).scrollToTop();

    return table;
  }

  private calculateRowIndex(startRowIndex: number) {
    var rowIndex = startRowIndex;

    if (this._claimedRewardLevel <= 10) {
      rowIndex = 0;
    } else if (this._claimedRewardLevel > 10 && this._claimedRewardLevel <= 20) {
      rowIndex = 9.999;
    } else if (this._claimedRewardLevel > 20 && this._claimedRewardLevel <= 30) {
      rowIndex = 9.999 * 2;
    } else if (this._claimedRewardLevel > 30 && this._claimedRewardLevel <= 40) {
      rowIndex = 9.999 * 3;
    } else if (this._claimedRewardLevel > 40 && this._claimedRewardLevel <= 50) {
      rowIndex = 9.999 * 4;
    } else if (this._claimedRewardLevel > 50 && this._claimedRewardLevel <= 60) {
      rowIndex = 9.999 * 5;
    } else if (this._claimedRewardLevel > 60 && this._claimedRewardLevel <= 70) {
      rowIndex = 9.999 * 6;
    } else if (this._claimedRewardLevel > 70 && this._claimedRewardLevel <= 80) {
      rowIndex = 9.999 * 7;
    } else if (this._claimedRewardLevel > 80 && this._claimedRewardLevel <= 90) {
      rowIndex = 9.999 * 8;
    } else if (this._claimedRewardLevel > 90 && this._claimedRewardLevel <= 100) {
      rowIndex = 9.999 * 9;
    }

    return rowIndex;
  }

  public hidePane() {
    this._levelsTable.setAlpha(0);
    this._levelsTable.disableInteractive();
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassLevelsPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattlePassLevelsPane(this.scene, x, y));
});
