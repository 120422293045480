import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';

// Pane Details
const paneHeight = 600;
const paneWidth = 500;
const paneRadius = 25;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

// Table Details
const itemWidth = 470,
  itemHeight = 73,
  itemRadius = 13,
  itemColor = 0x463851,
  itemAlpha = 0.3;

// Item Details
const itemGlareHeight = 7,
  itemGlareRadius = 4,
  itemGlareColor = 0xffffff,
  itemGlareAlpha = 0.08;

const categories = {
  placing: 'placings',
  kills: 'kills',
  evades: 'evades',
  lazes: 'lazes',
};

export default class BattleRoyaleResultsLeadersPane extends OverlapSizer {
  private _scale: number;

  // UI Elements
  private _placingsButton;
  private _killsButton;
  private _evadesButton;
  private _lazesButton;
  public _selectedCategory;
  private _table;
  private _returnHome;

  // Data
  private _participants;
  private _placings;
  private _kills;
  private _evades;
  private _lazes;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._participants = data;

    // Split participants data into sorted lists per category
    this._placings = this.getPlacingsData(this._participants);
    this._kills = this.getKillsData(this._participants);
    this._evades = this.getEvadesData(this._participants);
    this._lazes = this.getLazesData(this._participants);

    this._scale = paneScale;

    scene.input.topOnly = false;
    scene.input.setDefaultCursor('default');
    this._selectedCategory = categories.placing;

    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Add Sizer for content
    var sizer = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Pane Divider
    const dividerLeft = scene.add.image(0, 0, 'battleroyale_results_pane_divider').setScale(this._scale);

    sizer.add(dividerLeft, {
      key: 'br-divider-left',
      align: 'left-top',
      offsetX: this.applyScale(1),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Add Results Text
    var resultsText = scene.add.bitmapText(0, 0, 'cc_outline', 'Leaderboard', scene.applyScale(30), 0);
    sizer.add(resultsText, {
      key: `details-title`,
      align: 'center-top',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(20),
      expand: false,
    });

    // Add Divider Top
    const dividerTop = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

    sizer.add(dividerTop, {
      key: 'br-divider-top',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(70),
      expand: false,
    });

    // Buttons Sizer
    var buttonSizer = scene.rexUI.add.fixWidthSizer({
      width: this.applyScale(paneWidth),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Placings Button
    this._placingsButton = new Button(scene, 0, 0, 'battleroyale_placing_button', false).setOrigin(0, 0.5).setScale(this._scale);

    this._placingsButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.placing;
      this._killsButton.setTexture('battleroyale_kills_button_inactive');
      this._placingsButton.setTexture('battleroyale_placing_button');
      this._evadesButton.setTexture('battleroyale_evades_button_inactive');
      this._lazesButton.setTexture('battleroyale_suicides_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._placings, 0, 0, this.scale).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._placingsButton), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'placings_button',
    });

    // Kills Button
    this._killsButton = new Button(scene, 0, 0, 'battleroyale_kills_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._killsButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.kills;
      this._killsButton.setTexture('battleroyale_kills_button');
      this._placingsButton.setTexture('battleroyale_placing_button_inactive');
      this._evadesButton.setTexture('battleroyale_evades_button_inactive');
      this._lazesButton.setTexture('battleroyale_suicides_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._kills, 0, 0, this.scale).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._killsButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'kills_button',
    });

    // Evades Button
    this._evadesButton = new Button(scene, 0, 0, 'battleroyale_evades_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._evadesButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.evades;
      this._killsButton.setTexture('battleroyale_kills_button_inactive');
      this._placingsButton.setTexture('battleroyale_placing_button_inactive');
      this._evadesButton.setTexture('battleroyale_evades_button');
      this._lazesButton.setTexture('battleroyale_suicides_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._evades, 0, 0, this.scale).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._evadesButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'evades_button',
    });

    // Lazes Button
    this._lazesButton = new Button(scene, 0, 0, 'battleroyale_suicides_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._lazesButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.lazes;
      this._killsButton.setTexture('battleroyale_kills_button_inactive');
      this._placingsButton.setTexture('battleroyale_placing_button_inactive');
      this._evadesButton.setTexture('battleroyale_evades_button_inactive');
      this._lazesButton.setTexture('battleroyale_suicides_button');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._lazes, 0, 0, this.scale).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._lazesButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'lazes_button',
    });

    sizer.add(scene.add.existing(buttonSizer), {
      padding: { left: 0, right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'button_sizer',
    });

    sizer.add(buttonSizer, {
      key: 'br-button-sizer',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(100),
      expand: false,
    });

    this.add(sizer, {
      key: 'battle-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Add Divider Bottom
    const dividerBottom = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

    sizer.add(dividerBottom, {
      key: 'br-divider-bottom',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(170),
      expand: false,
    });

    // Add Table
    this._table = this.createTable(scene, this._placings, 0, 0, this.scale).layout();
    sizer.add(this._table, {
      key: 'results-table',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(190),
      expand: false,
    });

    // Return Home Button
    this._returnHome = new Button(scene, 0, 0, 'bossfight_victory_return_home_button', true, 1.05).setScale(this._scale);

    this._returnHome.onClick().subscribe(pointer => {
      scene.destroy();
    });

    sizer.add(scene.add.existing(this._returnHome), {
      key: 'return-home-button',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: scene.applyScale(-10),
      expand: false,
    });

    this.layout();
  }

  createTable(scene, data, x, y, scale) {
    var tableHeight = (itemHeight + 7) * 4;
    var table = scene.rexUI.add
      .gridTable({
        width: scene.applyScale(paneWidth),
        height: scene.applyScale(tableHeight),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(itemWidth),
          cellHeight: this.applyScale(itemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            participant = cell.item;

          const participantName = participant.playerName;

          const rectConfig = {
            maskType: 'roundRectangle',
            radius: scene.applyScale(40),
          };

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(20),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(itemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alpha
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )
            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth - 15), // width
                scene.applyScale(itemGlareHeight), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'center-top',
                offsetY: scene.applyScale(7),
                expand: false,
              },
            )
            .add(
              scene.rexUI.add.label({
                orientation: 'x',
                rtl: false,
                icon: scene.createSquadMemberImage(scene, participant.playerImageKey).setScale(scene.applyScale(0.3)),
                iconWidth: scene.applyScale(38),
                iconHeight: scene.applyScale(38),
                text: scene.add.bitmapText(0, 0, 'cc_outline', participantName, scene.applyScale(20), 0).setAlpha(participant.inactive ? 0.4 : 1),
                space: {
                  icon: scene.applyScale(10),
                  right: scene.applyScale(10),
                },
                //width: scene.applyScale(130)
              }),
              {
                key: 'item_name_label',
                align: 'left-top',
                offsetX: scene.applyScale(21),
                offsetY: scene.applyScale(24),
                expand: false,
              },
            )

            // Value Display
            .add(scene.add.bitmapText(0, 0, 'cc_outline', participant.value, scene.applyScale(18), 0), {
              key: `value_label`,
              align: 'right-top',
              offsetX: scene.applyScale(-21),
              offsetY: scene.applyScale(32),
              expand: false,
            });

          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  private applyScale(length) {
    return length * this._scale;
  }

  getPlacingsData(participants: any) {
    var placings: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      placings.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.placing,
      });
    });

    // sort list by placing
    placings.sort((participant1, participant2) => participant1.value - participant2.value);
    return placings;
  }

  getKillsData(participants: any) {
    var kills: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      if (participant.kills !== 0) {
        kills.push({
          playerName: participant.playerName,
          playerImageKey: participant.playerImageKey,
          value: participant.kills,
        });
      }
    });

    kills.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return kills;
  }

  getEvadesData(participants: any) {
    var evades: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      if (participant.evades !== 0) {
        evades.push({
          playerName: participant.playerName,
          playerImageKey: participant.playerImageKey,
          value: participant.evades,
        });
      }
    });

    evades.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return evades;
  }

  getLazesData(participants: any) {
    var lazes: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      if (participant.suicides !== 0) {
        lazes.push({
          playerName: participant.playerName,
          playerImageKey: participant.playerImageKey,
          value: participant.lazes,
        });
      }
    });

    lazes.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return lazes;
  }

  public getPlayerFromParticipants(results: any, walletId: string) {
    var player = undefined;
    results.forEach(participant => {
      if (participant.playerId == walletId) {
        player = participant;
      }
    });
    return player;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battleRoyaleResultsLeadersPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattleRoyaleResultsLeadersPane(this.scene, x, y));
});
