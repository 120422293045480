import { Client, Room } from 'colyseus.js';
import Phaser from 'phaser';
import IBattleRoyaleState, { GameState } from '~/../../API/BattleRoyale/src/types/IBattleRoyaleState';
import BattleRoyaleState from '~/../../API/BattleRoyale/src/game/BattleRoyaleState';
import ConfigurationService from './ConfigurationService';
import BRParticipant from '~/../../API/BattleRoyale/src/types/BRParticipant';
import WalletConnectService from './WalletConnectService';
import BRMessage from '../../../API/BattleRoyale/src/types/BRMessage';

export default class BattleRoyaleServerService {
  private static _instance: BattleRoyaleServerService = new BattleRoyaleServerService();
  private client: Client;
  private events: Phaser.Events.EventEmitter;
  private room?: Room<IBattleRoyaleState>;
  private _config: any;
  private _isLocalServer = false;
  private _isServerAlive = false;

  // Includes both Participants and Viewers
  private _roomMetaData;
  private _metaPlayerList;
  private _initialParticipantLength = 0;
  private _initialMessageLength = 0;

  constructor() {
    if (BattleRoyaleServerService._instance) {
      throw new Error('Error: Instantiation failed: Use BattleRoyaleServerService.getInstance() instead of new.');
    }
    BattleRoyaleServerService._instance = this;

    if (this._isLocalServer) {
      try {
        this.client = new Client('ws://localhost:8004');
      } catch (error) {
        console.log('Battle Royale Server Error');
      }
    } else {
      this._config = ConfigurationService.getInstance().getConfig();
      const wsEndpoint = this._config.websockets['battleroyale'];
      if (wsEndpoint) {
        this.client = new Client(`${this._config.websockets.base}${wsEndpoint.path}`);
      }
    }
    this.events = new Phaser.Events.EventEmitter();
  }

  public static getInstance(): BattleRoyaleServerService {
    return BattleRoyaleServerService._instance;
  }

  join(playerData: any, isParticipant: boolean) {
    return this.client
      .join<IBattleRoyaleState>('battleroyale', {
        player: playerData,
        wallet: WalletConnectService.getInstance().getWalletAddress(),
        isParticipant: isParticipant,
      })
      .then(room => {
        this.room = room;

        this.room.state.listen('gameState', value => {
          this.events.emit('gamestate-update');
        });

        this.room.state.participants.onAdd = () => {
          if (this.room?.state?.participants) {
            if (this.room.state.participants?.length > this._initialParticipantLength) {
              this.events.emit('participants-updated');
              this._initialParticipantLength = this.room.state.participants?.length;
            }
          }
        };

        this.room.state.messages.onAdd = () => {
          if (this.room?.state?.messages) {
            if (this.room?.state?.messages.length > this._initialMessageLength) {
              this.events.emit('message-received');
              this._initialMessageLength = this.room.state.messages.length;
            }
          }
        };
      });
  }

  leave() {
    this._initialMessageLength = 0;
    this._initialParticipantLength = 0;
    this.room?.leave();
    this.events.removeAllListeners();
  }

  disableBattleRoyaleListeners() {
    this._initialMessageLength = 0;
    this._initialParticipantLength = 0;
    this.events.removeAllListeners();
  }

  // Listeners
  onParticipantsUpdated(cb: (state: BattleRoyaleState) => void, context?: any) {
    this.events.on('participants-updated', cb, context);
  }

  onMessageRecieved(cb: (state: BattleRoyaleState) => void, context?: any) {
    this.events.on('message-received', cb, context);
  }

  onGameStateChanged(cb: (state: BattleRoyaleState) => void, context?: any) {
    this.events.on('gamestate-update', cb, context);
  }

  getRoomData() {
    var hasGameStarted = false;
    var isParticipant = false;
    var isViewer = false;
    var countDown = 0;
    var participantList: Array<BRParticipant> = [];
    var viewerList: Array<BRParticipant> = [];
    var gameState: GameState;
    var previousResults: Array<BRParticipant> = [];
    var messages: Array<BRMessage> = [];
    var pfpData: { playerImageKey: string; playerImageUrl: string }[] = [];

    if (this._roomMetaData) {
      this._metaPlayerList = this._roomMetaData.metadata?.participants;

      // Participants already in-game
      this._roomMetaData.metadata?.participants.forEach(participant => {
        participantList.push(participant);
        pfpData.push({ playerImageKey: participant.playerImageKey, playerImageUrl: participant.playerImageUrl });
        if (participant.playerId === WalletConnectService.getInstance().getWalletAddress()) {
          isParticipant = participant.isParticipant;
        }
      });

      // Stores Viewers
      viewerList = this._roomMetaData.metadata?.viewers;
      viewerList.forEach(viewer => {
        this._metaPlayerList.push(viewer);
        if (viewer.playerId === WalletConnectService.getInstance().getWalletAddress()) {
          isViewer = viewer.isViewer;
        }
      });

      previousResults = this._roomMetaData.metadata?.previousResults;

      // Countdown till game starts
      countDown = this._roomMetaData.metadata?.countDown;

      // GameState
      gameState = this.getGameStateEnum(this._roomMetaData.metadata?.gameState);

      if (this._roomMetaData.metadata?.gameState !== 0) {
        hasGameStarted = true;
      }
    }

    return {
      participants: participantList,
      viewers: viewerList,
      countDown: countDown,
      isParticipant: isParticipant,
      isViewer: isViewer,
      hasGameStarted: hasGameStarted,
      gameState: gameState,
      previousResults: previousResults,
      messages: messages,
      pfpData: pfpData,
    };
  }

  getGameStateEnum(number: Number) {
    switch (number) {
      case 0:
        return GameState.PreGame;
      case 1:
        return GameState.BattleRoyale;
      case 2:
        return GameState.BRIntermission;
      case 4:
        return GameState.GameOver;
      case 5:
        return GameState.Failed;
    }
    return GameState.PreGame;
  }

  async isServerAvailable() {
    return this.client
      .getAvailableRooms('battleroyale')
      .then(room => {
        this._roomMetaData = room[0];
        this._isServerAlive = true;
      })
      .catch(err => {
        //console.log("BR Server not started")
      });
  }

  async updateMetaData() {
    return this.client
      .getAvailableRooms('battleroyale')
      .then(room => {
        this._roomMetaData = room[0];
        this._initialParticipantLength = this._roomMetaData.metadata?.participants.length;
      })
      .catch(err => {
        //console.log("BR Server not started")
      });
  }

  isServerAlive() {
    return this._isServerAlive;
  }

  // Meta Participant List Functions
  getPlayerFromMetaParticipants(walletId: string) {
    var user = undefined;
    this._metaPlayerList.forEach(participant => {
      if (participant.playerId === walletId) {
        user = participant;
      }
    });

    return user;
  }

  getGameState() {
    return this.room?.state.gameState;
  }

  getMessages() {
    return this.room?.state.messages;
  }

  getUserFromParticipantList(name: string) {
    var user;
    this.room?.state.participants.forEach(participant => {
      if (participant.playerName === name) {
        user = participant;
      }
    });

    return user;
  }

  getParticipantsList() {
    return this.room?.state.participants;
  }

  getBattleData() {
    return {
      countDown: this.room?.state.countDown,
      gameState: this.room?.state.gameState,
      round: this.room?.state.round,
      participantsRemaining: this.room?.state.participantsRemaining,
      messages: this.room?.state.messages,
      participants: this.room?.state.participants,
    };
  }

  battleRoyaleStarted() {
    return this.room?.state.gameState === GameState.BattleRoyale;
  }

  hasPlayerJoinedGame(walletId: string) {
    var found = false;

    this.room?.state.participants.forEach(participant => {
      if (participant.playerId === walletId) {
        found = true;
      }
    });

    this.room?.state.viewers.forEach(participant => {
      if (participant.playerId === walletId) {
        found = true;
      }
    });

    return found;
  }

  getRoomInformation() {
    this.client
      .getAvailableRooms('battleroyale')
      .then(rooms => {
        rooms.forEach(room => {});
      })
      .catch(e => {
        console.error(e);
      });
  }

  convertSecondsToCountdown(seconds: number) {
    seconds = Number(seconds);
    var hDisplay = Math.floor(seconds / 3600) + 'H ';
    var mDisplay = Math.floor((seconds % 3600) / 60) + 'M ';
    var sDisplay = Math.floor((seconds % 3600) % 60) + 'S';
  }

  public getPlayerFromParticipants(results: any, walletId: string) {
    var player = undefined;
    results.forEach(participant => {
      if (participant.playerId == walletId) {
        player = participant;
      }
    });
    return player;
  }

  getPlayerImageKeys() {
    var imageKeys: string[] = [];

    // gathers metadata keys
    this._metaPlayerList.forEach(participant => {
      imageKeys.push(participant.playerImageKey);
    });

    // gather participant key
    this.room?.state.participants.forEach(participant => {
      if (!imageKeys.includes(participant.playerImageKey)) {
        imageKeys.push(participant.playerImageKey);
      }
    });

    return imageKeys;
  }
}
