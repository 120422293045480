import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import BattleRoyaleServerService from '~/services/BattleRoyaleServerService';
import ScaleService from '~/services/ScaleService';
import { BR_Alert_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 527;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class HudBattleRoyaleAlertScene extends Phaser.Scene {
  private _scale;
  private _laterButton;
  private _readyUpButton;
  private _hasPlayerJoined;
  private _battleRoyaleImage;
  private _battleRoyaleTitle;
  private _battleRoyaleText1;
  private _battleRoyaleText2;

  constructor() {
    super({
      key: 'HudBattleRoyaleAlertScene',
    });
  }

  init(data: any) {
    this._hasPlayerJoined = data.hasPlayerJoined;
  }

  preload() {
    LoadImageAssets(this, BR_Alert_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.sys.game.canvas.width * 2, // width
      this.sys.game.canvas.height * 2, // height
      0, // radius
      0x000000, // fillColor
      0.73, // alpha
    );

    this.add.existing(background);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    const notificationBg = this.add
      .rexRoundRectangle(
        0, // x
        0, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale(modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
      )
      .setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    sizer.add(notificationBg, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Add Boss Image
    this._battleRoyaleImage = this.add.image(0, 0, 'hud_battle_royale_background').setScale(this._scale);

    sizer.add(this._battleRoyaleImage, {
      key: 'battleroyale_image',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Add Boss Title
    this._battleRoyaleTitle = this.add.image(0, 0, 'hud_battle_royale_text').setScale(this._scale);

    sizer.add(this._battleRoyaleTitle, {
      key: 'battleroyale_title_image',
      align: 'center-middle',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Text 1
    this._battleRoyaleText1 = this.add.bitmapText(0, 0, 'cc_outline', 'Show your true grit\nand strength', this.applyScale(22), 1);
    sizer.add(this._battleRoyaleText1, {
      key: 'battleroyale-text-1',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(325),
      expand: false,
    });

    // Text 2
    this._battleRoyaleText2 = this.add
      .bitmapText(0, 0, 'cc_outline', 'A winner-takes-all battle arena,\nwhere you can come away with\nall the goods...', this.applyScale(18), 1)
      .setTint(0xbfaac6);

    sizer.add(this._battleRoyaleText2, {
      key: 'bossfight-text-2',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(390),
      expand: false,
    });

    // Add Later Button
    this._laterButton = new Button(this, 0, 0, 'bossfight_notification_later_button', true, 1.05).setScale(this._scale);

    this._laterButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });

    this.add.existing(this._laterButton);

    sizer.add(this._laterButton, {
      key: 'later-button',
      align: 'left-top',
      offsetX: this.applyScale(89),
      offsetY: this.applyScale(497),
      expand: false,
    });

    // Add Join Game Button
    var buttonImage = this._hasPlayerJoined ? 'hud_battle_royale_view_button' : 'hud_battle_royale_ready_up_button';
    this._readyUpButton = new Button(this, 0, 0, buttonImage, true, 1.05).setScale(this._scale);

    this._readyUpButton.onClick().subscribe(pointer => {
      this.unloadAssets();

      BattleRoyaleServerService.getInstance()
        .updateMetaData()
        .then(() => {
          this.scene.get('HomebaseScene').scene.stop();
          this.scene.get('HudScene').scene.stop();
          this.scene.start('BattleRoyaleScene');
        });
    });

    this.add.existing(this._readyUpButton);

    sizer.add(this._readyUpButton, {
      key: 'ready-up-button',
      align: 'left-top',
      offsetX: this.applyScale(239),
      offsetY: this.applyScale(497),
      expand: false,
    });

    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Alert_Scene_Assets));
  }
}
