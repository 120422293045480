import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

// Rank
const rankHeight = 48;
const rankWidth = 116;
const rankRadius = 7;
const unlockedBackgroundColor = 0x3dff50;
const lockedBackgroundColor = 0xb48dc7;
const rankAlpha = 60;
const rankUnlockedBorderColor = 0x383d57;
const rankLockedBorderColor = 0xffffff;
const rankLockedBorderColorAlpha = 40;

export default class BattlePassItemRankComponent extends OverlapSizer {
  private _scale;

  // Rank
  private _rankBackground;
  private _rankNumber;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    var level = data.item.level;
    var unlocked = data.level >= level ? true : false;

    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(rankWidth),
      height: scene.applyScale(rankHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Rank
    this._rankBackground = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(rankWidth), // width
      scene.applyScale(rankHeight), // height
      scene.applyScale(rankRadius), // radius
      unlocked ? unlockedBackgroundColor : lockedBackgroundColor, // fillColor
      rankAlpha, // alpha
    );

    this.add(this._rankBackground, {
      key: 'rank-background',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Rank Text
    this._rankNumber = scene.add.bitmapText(0, 0, 'cc_outline', level, this.applyScale(22), 1);

    sizerPanel.add(this._rankNumber, {
      key: 'rank-text',
      align: 'center',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassItemRankComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new BattlePassItemRankComponent(this.scene, x, y, data));
});
