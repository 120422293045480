import { Schema, ArraySchema } from '@colyseus/schema'
import BossFighter from './BossFighter'

export enum GameState {
	PreGame,
	PreRound,
	Round,
	ProcessResults,
	DisplayResults,
	Finished,
}

export interface IBossFightState extends Schema
{
	gameState: GameState
	gameStateMessage: string
	//numberOfMembers: number
	//numOfHordePercent: number
	rarity: string
	hp: number
	bossMaxHP: number
	bossXP: number
	attempt: number
	remaining: number
	countDown: number
	startHour: number
	attackingPlayers: ArraySchema<string>
	participants: ArraySchema<BossFighter>
	battleId: number
	attackingCount: number
}

export default IBossFightState