import { Client, Room } from 'colyseus.js';
import Phaser from 'phaser';
import PVEState from '~/../../API/PVE/src/game/PVEState';
import IPVEState, { GameState, MatchRoundState, Rank } from '../../../API/PVE/src/types/IPVEState';
import ConfigurationService from './ConfigurationService';
import WalletConnectService from './WalletConnectService';
import BotService from '~/services/BotService';
import { isPVPTournament } from '~/utils/GameUtils';
import MachineService from './MachineService';

export default class PVEServerService {
  private static _instance: PVEServerService = new PVEServerService();
  private client: Client;
  private events: Phaser.Events.EventEmitter;
  private room?: Room<IPVEState>;
  private _config: any;
  private _isLocalServer = false;

  constructor() {
    if (PVEServerService._instance) {
      throw new Error('Error: Instantiation failed: Use PVEServerService.getInstance() instead of new.');
    }
    PVEServerService._instance = this;

    if (this._isLocalServer) {
      this.client = new Client('ws://localhost:8002');
    } else {
      this._config = ConfigurationService.getInstance().getConfig();
      const wsEndpoint = this._config.websockets['pve'];
      if (wsEndpoint) {
        this.client = new Client(`${this._config.websockets.base}${wsEndpoint.path}`);
      }
    }
    this.events = new Phaser.Events.EventEmitter();
  }

  public static getInstance(): PVEServerService {
    return PVEServerService._instance;
  }

  join(playerData: any) {
    // gets player total pve health
    if (!playerData || !playerData.squadMembers) {
      return Promise.reject();
    }

    var hp = 300;
    if (!isPVPTournament) {
      hp =
        playerData.squadMembers
          .map(sm => {
            return sm.hp;
          })
          .reduce((previous, current) => {
            return previous + current;
          }) || 300;
    }

    // Adds 10% Payout for Brawler Cage
    playerData.brawlerCage = MachineService.getInstance().ownsBrawlerCage();

    return this.client
      .create<IPVEState>('pve', {
        player: playerData,
        maxHealth: hp,
        wallet: WalletConnectService.getInstance().getWalletAddress(),
      })
      .then(room => {
        this.room = room;

        this.room.state.listen('matchRoundState', value => {
          this.handleMatchRoundStateUpdate(value);
        });

        this.room.state.listen('round', () => {
          this.events.emit('round-complete');
        });

        this.room.state.listen('gameState', value => {
          this.handleGameStateUpdate(value);
        });

        this.room.state.players.onAdd = (player, key) => {
          player.listen('actionValid', () => {
            this.actionSubmitted(player.wallet, player.actionValid);
          });
        };

        return Promise.resolve(this.room);
      });
  }

  joinBot() {
    // Create a PVE Bot to add to the game. Default to my dev account wallet currently
    var _botData = BotService.getInstance().getBotSync();
    _botData.squadMembers = BotService.getInstance().getBotSquadSync();

    // gets Bot total pve health
    if (!_botData || !_botData.squadMembers) {
      return Promise.reject();
    }

    var hp = 300;
    if (!isPVPTournament) {
      hp =
        _botData.squadMembers
          .map(sm => {
            return sm.hp;
          })
          .reduce((previous, current) => {
            return previous + current;
          }) || 300;
    }

    return this.client.join<IPVEState>('pve', {
      player: _botData,
      maxHealth: hp,
    });
  }

  actionSubmitted(wallet: string, actionValid: boolean) {
    this.events.emit('action-submitted', {
      submitterWallet: wallet,
      isActionValid: actionValid,
    });
  }

  leave() {
    this.room?.leave();
    this.events.removeAllListeners();
  }

  getRoom() {
    return this.room;
  }

  getGameState() {
    return this.room?.state;
  }

  getPVPRoomRank(playerLevel: number) {
    switch (true) {
      case playerLevel <= 10:
        return Rank.Bronze;
      case playerLevel > 10 && playerLevel <= 30:
        return Rank.Silver;
      case playerLevel > 30 && playerLevel <= 50:
        return Rank.Gold;
      case playerLevel > 50 && playerLevel <= 70:
        return Rank.Platinum;
      case playerLevel > 70:
        return Rank.Diamond;
    }
    return Rank.Undefined;
  }

  handleGameStateUpdate(state: number) {
    var player = this.room?.state.players.at(0);
    var bot = this.room?.state.players.at(1);
    switch (state) {
      case GameState.Player1Win: // Apply Traits
        this.events.emit('game-over', {
          state: state,
          winningWallet: player?.wallet,
          neonGoldBoost: this.room?.state.neonGold,
          spaceElixirBoost: this.room?.state.spaceElixir,
          starDust: this.room?.state.starDust,
        });
        break;
      case GameState.Player2Win:
        this.events.emit('game-over', {
          state: state,
          winningWallet: player?.wallet,
          neonGoldBoost: this.room?.state.neonGold,
          spaceElixirBoost: this.room?.state.spaceElixir,
          starDust: this.room?.state.starDust,
        });
        break;
      case GameState.Tie:
        this.events.emit('game-over', {
          state: state,
          neonGoldBoost: this.room?.state.neonGold,
          spaceElixirBoost: this.room?.state.spaceElixir,
          starDust: this.room?.state.starDust,
        });
        break;
      case GameState.Playing:
        this.events.emit('game-started', GameState.Playing);
        break;
    }
  }

  handleMatchRoundStateUpdate(state: number) {
    switch (state) {
      case MatchRoundState.Start:
        this.events.emit('match-round-start');
        break;
      case MatchRoundState.Complete:
        this.events.emit('match-round-complete');
        break;
    }
  }

  playAction(action: string) {
    if (!this.room) {
      return;
    }
    this.room.send('play-action', {
      wallet: WalletConnectService.getInstance().getWalletAddress(),
      playerAction: action,
    });
  }

  startNextMatchRound() {
    if (!this.room) {
      return;
    }
    this.room.send('match-continue', {});
  }

  onMatchRoundStart(cb: (matchRoundState: number) => void, context?: any) {
    this.events.on('match-round-start', cb, context);
  }

  onMatchRoundComplete(cb: (matchRoundState: number) => void, context?: any) {
    this.events.on('match-round-complete', cb, context);
  }

  onGameStarted(cb: (state: PVEState) => void, context?: any) {
    this.events.on('game-started', cb, context);
  }

  onRoundComplete(cb: (state: PVEState) => void, context?: any) {
    this.events.on('round-complete', cb, context);
  }

  onGameOver(cb: (gameState: number) => void, context?: any) {
    this.events.on('game-over', cb, context);
  }

  onActionSubmitted(cb: (state: PVEState) => void, context?: any) {
    this.events.on('action-submitted', cb, context);
  }
}
