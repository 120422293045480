import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import BattlePassRaffleRocksComponent from '~/components/battlepass/battlePassRaffleRocksComponent';
import BattlePassWeeklyRaffleComponent from '~/components/battlepass/battlePassWeeklyRaffleComponent';
import Button from '~/components/buttons/Button';

// Pane Details
const paneHeight = 443;
const paneWidth = 855;

export default class BattlePassWeeklyRafflePane extends OverlapSizer {
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Sizer
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    var raffleComponent = new BattlePassWeeklyRaffleComponent(scene, 0, 0, {}, scene.getScale());
    sizerPanel.add(raffleComponent, {
      key: 'raffle-component',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // RaffleRocks
    var rocksComponent = new BattlePassRaffleRocksComponent(scene, 0, 0, {}, scene.getScale());
    sizerPanel.add(rocksComponent, {
      key: 'rocks-component',
      align: 'left-top',
      offsetX: this.applyScale(323),
      offsetY: this.applyScale(34),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public hidePane() {
    //this._levelsTable.setAlpha(0);
    //this._levelsTable.disableInteractive();
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassWeeklyRafflePane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattlePassWeeklyRafflePane(this.scene, x, y));
});
