import Phaser, { Scene } from 'phaser';
import { Subscription } from 'rxjs';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import RoundImageButton from '~/components/buttons/RoundImageButton';
import util from 'util';
import CostLabel from '~/components/labels/costLabel';

const componentWidth = 250;
const componentHeight = 357;
const componentRadius = 16;
const componentBorderWidth = 2;
const componentBorderColor = 0x382b42;
const componentBorderAlpha = 1;
const componentBorderOverColor = 0x544163;

export default class ShopItemComponent extends OverlapSizer {
  private _item;
  private _itemImage;
  private _scale;
  private _itemLabel;
  private _infoCircle;
  private _infoLetter;
  private _costLabel;
  private _costText;
  private _itemBorder;
  private _isButton;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, isButton?: boolean, costText?: string, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * componentWidth,
          height: paneScale * componentHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._item = data;
    this._isButton = isButton === undefined ? true : isButton;
    this._costText = costText ? costText : this._item?.cost?.toLocaleString('en-us') || 'N/A';
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Item Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: componentRadius,
    };

    if (this._isButton) {
      this._itemImage = new RoundImageButton(scene, 0, 0, `shop_${this._item.key}_image`, rectConfig, false).setScale(scene.getScale());
      scene.add.existing(this._itemImage);

      this._itemImage.onClick().subscribe(pointer => {
        if (pointer.getDistance() > 20) {
          return;
        }
        scene.scene.pause('ShopModalScene');
        scene.scene.launch('ShopItemInfoScene', { item: this._item, type: 'shop_item', scale: this._scale });
      });

      this.add(this._itemImage, {
        key: 'item_image',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });
    } else {
      this._itemImage = scene.add.rexCircleMaskImage(0, 0, `shop_${this._item.key}_image`, '', rectConfig).setScale(scene.getScale());

      this.add(this._itemImage, {
        key: 'item_image',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });
    }

    // Item Border
    this._itemBorder = scene.add
      .rexRoundRectangle(
        0, // x
        0, // y
        scene.applyScale(componentWidth), // width
        scene.applyScale(componentHeight), // height
        scene.applyScale(componentRadius), // radius
        0x000000, // fillColor
        0, // alpha
      )
      .setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderColor, componentBorderAlpha);

    if (this._isButton) {
      this._itemBorder
        .setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
          this._itemBorder.setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderOverColor, componentBorderAlpha);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
          this._itemBorder.setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderColor, componentBorderAlpha);
        });
    }

    this.add(this._itemBorder, {
      key: 'item_border',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    if (this._isButton) {
      // Item Label
      this._itemLabel = scene.add
        .bitmapText(0, 0, 'cc_outline', this.getDisplayName(), this.applyScale(22), 0)
        .setMaxWidth(this.applyScale(componentWidth - 70))
        .setOrigin(0, 0.5);

      this.add(this._itemLabel, {
        key: `item-label`,
        align: 'left-top',
        offsetX: scene.applyScale(21),
        offsetY: scene.applyScale(19),
        expand: false,
      });

      // Info Circle
      this._infoCircle = scene.add.image(0, 0, 'shop_item_info_button').setScale(scene.getScale());

      this.add(this._infoCircle, {
        key: `info-circle`,
        align: 'right-top',
        offsetX: scene.applyScale(-18),
        offsetY: scene.applyScale(12),
        expand: false,
      });

      this._infoLetter = scene.add.image(0, 0, 'shop_item_info_letter').setScale(scene.getScale());

      this.add(this._infoLetter, {
        key: `info-letter`,
        align: 'right-top',
        offsetX: scene.applyScale(-28),
        offsetY: scene.applyScale(19),
        expand: false,
      });
    }

    // Cost Label
    this._costLabel = new CostLabel(scene, x, y, scene.getScale(), this.getCurrency(), this._costText, 213);
    scene.add.existing(this._costLabel);

    this.add(this._costLabel, {
      key: `cost_label`,
      align: 'center-bottom',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(-15),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getDisplayName() {
    if (this._item.type === 'whitelist') {
      return this._item.name.slice(-2) === 'WL' ? this._item.name : `${this._item.name} WL`;
    } else {
      return this._item.name;
    }
  }

  private getCurrency() {
    switch (this._item.currency_id) {
      case 1:
        return 'space_elixir';
      case 2:
        return 'neon_gold';
      case 4:
        return 'dark_matter';
      default:
        return 'stardust';
    }
  }

  updateLabelText(newText: string) {
    this._costLabel.updateLabelText(newText);
  }
}

Phaser.GameObjects.GameObjectFactory.register('shopItemComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new ShopItemComponent(this.scene, x, y, data));
});
