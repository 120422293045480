import Phaser from 'phaser';
import util from 'util';
import ScaleService from '~/services/ScaleService';
import { Homebase_Assets, Shop_Assets } from '~/utils/AssetLoader';
import MachineInfoContentPane from './machineInfoContentPane';

const modalWidth = 1008;
const modalHeight = 735;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x6262DE;
const modalBorderWidth = 3;

export default class MachineInfoModalScene extends Phaser.Scene {
  private _scale;
  private _machineInfoContentPane;
  private _userMachine;
  private _sizer;

  init(data: any) {
    this._userMachine = data.userMachine;
  }

  constructor() {
    super({
      key: 'MachineInfoModalScene'
    });
  }

  preload() {
    this.load.image('machine_info_close_button', Homebase_Assets.machine_info_close_button);
    this.load.image('shop_buy_button', Shop_Assets.shop_buy_button);
    this.load.image('machine_info_speed_up_button', Homebase_Assets.machine_info_speed_up_button);
    this.load.image('machine_item_info_divider_long', Homebase_Assets.machine_item_info_divider_long);
    this.load.image('machine_item_info_divider_short', Homebase_Assets.machine_item_info_divider_short);
  }

  create() {
    const self = this;
    if (this.game.input.touch && this.game.input.touch.preventDefault) {
        this.game.input.touch.preventDefault = false;
        this.game.input.touch.enabled = true;
    }

    this.input.dragDistanceThreshold = 16;
    this.input.dragTimeThreshold = 300;

    this.input.topOnly = true;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        height: this.applyScale(597),
        width: this.applyScale(modalWidth),
        space: {
            left: 0, right: 0, top: 0, bottom: 0,
            item: 0, line: 0
        },
        align: 0,
        sizerEvents: true
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
        0, // x
        0, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale( modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
    );
    background.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    this._sizer.add(background, {
        key: 'shop_bg',
        align: 'center-bottom',
        offsetX: 0,
        offsetY: 0,
        expand: false
    });

    this._machineInfoContentPane = new MachineInfoContentPane(this, this.sys.canvas.width / 2, this.sys.canvas.height / 2, this._userMachine, this._scale);
    this.add.existing(this._machineInfoContentPane);

    this._sizer.add(this._machineInfoContentPane, {
        key: 'machine_info_content_pane',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        expand: false
    });

    this._sizer.layout();

    this._sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this._machineInfoContentPane = undefined;
      this.scene.stop();
      // this.scene.resume('HomebaseScene');
      // this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('ShopModalScene');
    });
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private destroy() {
    this._machineInfoContentPane.destroy();
  }
}