import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';
import PVEServer from '~/services/PVEServerService';
import PvpPlayerPane from '../pvp/pvpPlayerPane';
import PvpButtonPane from '../pvp/pvpButtonPane';
import PvpGamePane from '../pvp/pvpGamePane';
import BotService from '~/services/BotService';
import WalletConnectService from '~/services/WalletConnectService';
import { PVP_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { GameState } from '~/../../API/PVP/src/types/IPVPState';

const modalHeight = 822;
const modalWidth = 1393;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

export default class PVEScene extends Phaser.Scene {
  private _playerContentPane;
  private _botContentPane;
  private _gamePane;
  private _buttonPane;
  private _scale: number;
  private _player;
  private _bot;
  private _gameState;

  constructor() {
    super({
      key: 'PvEScene',
    });
  }

  preload() {
    // Image Loading
    LoadImageAssets(this, PVP_Scene_Assets);

    BotService.getInstance()
      .getBotSync()
      .squadMembers.forEach(sm => {
        if (sm.image_key && sm.image_thumbnail_url && sm.image_thumbnail_url.length > 0) {
          this.load.image(sm.image_key, sm.image_thumbnail_url);
        }
      });

    this._gameState = PVEServer.getInstance().getGameState();
  }

  create() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Player Pane
    this._player = UserService.getInstance().getUserDataSync();
    this._player.squadMembers = SquadService.getInstance().getSquadSync();
    this._player.traits = SquadService.getInstance().getTraitsSync();
    this._player.health = 300;

    this._playerContentPane = new PvpPlayerPane(this, 0, 0, this._player, this._scale);
    this.add.existing(this._playerContentPane);

    sizer.add(this._playerContentPane, {
      key: 'player-content-pane',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Game Pane
    this._gamePane = new PvpGamePane(this, 0, 0, { battleType: 'pve' }, this._scale);
    this.add.existing(this._gamePane);

    sizer.add(this._gamePane, {
      key: 'game-content-pane',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Bot Pane
    this._bot = BotService.getInstance().getBotSync();
    this._bot.traits = BotService.getInstance().getTraitsSync();
    this._bot.health = 300;
    this._bot.isBot = true;

    this._botContentPane = new PvpPlayerPane(this, 0, 0, this._bot, this._scale);
    this.add.existing(this._botContentPane);

    sizer.add(this._botContentPane, {
      key: 'opponent-content-pane',
      align: 'right-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Button Pane
    this._buttonPane = new PvpButtonPane(this, 0, 0, { serverType: 'pve' }, this._scale);
    this.add.existing(this._buttonPane);

    sizer.add(this._buttonPane, {
      key: 'button-content-pane',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Layout the Scene
    sizer.layout();

    // PvE Server Interaction
    PVEServer.getInstance().onRoundComplete(this.handleRoundComplete, this);
    PVEServer.getInstance().onGameOver(this.handleGameOver, this);
    PVEServer.getInstance().onActionSubmitted(this.handleActionSubmitted, this);

    // Match State Listening
    PVEServer.getInstance().onMatchRoundStart(this.handleMatchRoundStart, this);
    PVEServer.getInstance().onMatchRoundComplete(this.handleMatchRoundComplete, this);

    // Interacts with PVP Surrender Scene
    this.scene.get('PvpSurrenderScene').events.on('surrender_cancel_event', () => {
      var state = PVEServer.getInstance().getGameState();
      if (state) {
        var user = state.players.find(player => {
          return player.wallet === WalletConnectService.getInstance().getWalletAddress();
        });
        this._buttonPane.updateEnabled(user);
      } else {
        console.log('Failed to get game state');
      }
    });

    // Interacts with PVE Match Round Complete Scene
    this.scene.get('PveRewardsScene').events.on('pve_continue_event', () => {
      PVEServer.getInstance().startNextMatchRound();
    });
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private handleActionSubmitted(result: any) {
    const gameState = PVEServer.getInstance().getGameState();
    var actionStatus = result.isActionValid ? 'Action submitted' : 'Action invalid';

    if (result.isActionValid) {
      if (result.submitterWallet === WalletConnectService.getInstance().getWalletAddress()) {
        // disable buttons as action is valid
        this._buttonPane.disableButtons();

        this._gamePane.updateExistingRound({
          roundNumber: gameState?.round + 1,
          playerAction: actionStatus,
          opponentAction: 'Awaiting action...',
        });
      } else {
        // bot
        this._gamePane.updateExistingRound({
          roundNumber: gameState?.round + 1,
          playerAction: 'Awaiting action...',
          opponentAction: actionStatus,
        });
      }
    }
  }

  private handleRoundComplete() {
    this._gameState = PVEServer.getInstance().getGameState();
    if (this._gameState.round != 0) {
      this.updateUIState(this._gameState);
    }
  }

  private handleMatchRoundStart() {
    // Update all the front end UI with the new match data
    this._gamePane.updateTitle('Match Round ' + (this._gameState.matchRound + 1) + '... FIGHT!');
    this._gamePane.clearGameplayLog();
    this.updateUIState(this._gameState);
  }

  private handleMatchRoundComplete() {
    this.scene.launch('PveRewardsScene', {
      matchRound: this._gameState.matchRound,
      rewards: {
        neonGold: this._gameState.neonGold,
        spaceElixir: this._gameState.spaceElixir,
        starDust: this._gameState.starDust,
      },
    });
  }

  private updateUIState(state: PVEState) {
    var user;
    var bot;

    state.players.forEach(player => {
      if (player.wallet === WalletConnectService.getInstance().getWalletAddress()) {
        user = player;
      } else {
        bot = player;
      }
    });

    // Update Button Pane
    this._buttonPane.updateEnabled(user);

    // Update Player and Opponent Panes
    if (state.round == 0) {
      this._playerContentPane.resetProgressBars(user.health, user.actionPoints);
      this._botContentPane.resetProgressBars(bot.health, bot.actionPoints);
    } else {
      this._playerContentPane.updateProgressBars(user.health, user.actionPoints);
      this._botContentPane.updateProgressBars(bot.health, bot.actionPoints);
    }

    // updates current round data IF round isn't 0
    if (state.round != 0) {
      this._gamePane.updateExistingRound({
        roundNumber: state.round,
        playerAction: user.roundAction,
        opponentAction: bot.roundAction,
      });

      // create next round
      this._gamePane.addRound(state.round + 1);
    }

    // if player is stunned auto submit their stunned action.
    //if (user.stunned) {PVPServer.getInstance().playAction('pvpstunned')}
  }

  private handleGameOver(result: any) {
    this._gameState = PVEServer.getInstance().getGameState();
    PVEServer.getInstance().leave();
    this.scene.start('PveGameOverScene', {
      isDraw: this._gameState.gameState === GameState.Tie ? true : false,
      roundsWon: this._gameState.matchRound,
      rewards: {
        neon_gold: result.neonGoldBoost,
        space_elixir: result.spaceElixirBoost,
        stardust: result.starDust,
      },
    });
    this.unloadAssets();
    this.scene.stop();
  }

  destroy() {}

  public unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Scene_Assets));

    // Unload Bot PFPs
    const botImageKeys: string[] = BotService.getInstance()
      .getBotSync()
      .squadMembers.map(sm => sm.image_key);
    UnloadImages(this, botImageKeys);
  }
}
