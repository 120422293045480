import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import UserService from '~/services/UserService';
import ObjectivesHeaderContentPane from './objectivesHeaderContentPane';
import ObjectivesListContentPane from './objectivesListContentPane';

const paneWidth = 660;
const paneHeight = 595;
const stakingPaneHeight = 473;

const headerPaneOffsetY = 27;
const objectiveListPaneOffsetY = 80;
const dividerOffsetY = 70;

export default class ObjectivesContentPane extends OverlapSizer {
  private _userData;
  private _objectiveHeaderContentPane;
  private _objectiveListContentPane;
  private _parentSizer;
  private _scale;
  private _objectiveType;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, objectiveType: string, parentSizer?: any, config?: any) {
    const conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneWidth * paneScale,
          height: paneHeight * paneScale,
          scrollMode: 0,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
        };

    super(scene, conf);

    this._scale = paneScale;
    this._userData = UserService.getInstance().getUserDataSync();
    this._parentSizer = parentSizer || this;
    this._objectiveType = objectiveType;

    // this.layout();
    this.createComponent();
  }

  createComponent() {
    const scene = this.scene;

    // Objectives Header Pane
    this._objectiveHeaderContentPane = scene.add.existing(new ObjectivesHeaderContentPane(scene, 0, 0, this._scale, this._objectiveType, paneWidth));

    this._parentSizer
      .add(this._objectiveHeaderContentPane, {
        key: 'objective_header_content_pane',
        align: 'left-top',
        offsetX: scene.applyScale(251),
        offsetY: scene.applyScale(headerPaneOffsetY),
        expand: true,
      })
      .layout();

    // Divider
    this.add(scene.add.image(0, 0, 'objectives_divider_line'), {
      key: 'staking_divider',
      align: 'left-top',
      offsetX: scene.applyScale(-3),
      offsetY: scene.applyScale(dividerOffsetY),
      expand: false,
    }).setScale(this._scale);

    // Objectives List Pane
    this._objectiveListContentPane = new ObjectivesListContentPane(scene, 0, 0, this._scale, this._objectiveType, paneWidth);

    this.add(this._objectiveListContentPane, {
      key: 'objective_list_content_pane',
      align: 'left-top',
      offsetX: 0,
      offsetY: scene.applyScale(objectiveListPaneOffsetY),
      expand: false,
    });

    // Layout Sizer
    this.layout();
  }

  getObjectiveHeaderPane() {
    return this._objectiveHeaderContentPane;
  }

  getObjectiveListPane() {
    return this._objectiveListContentPane;
  }

  destroy() {
    this._parentSizer.remove(this._objectiveHeaderContentPane, true);
    this._objectiveHeaderContentPane = undefined;
    this.remove(this._objectiveListContentPane, true);
    this._objectiveListContentPane = undefined;
  }
}

Phaser.GameObjects.GameObjectFactory.register('objectivesContentPane', function (scene: Phaser.Scene, x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ObjectivesContentPane(scene, x, y));
});
