import Phaser, { Textures } from 'phaser';
import Button from '~/components/buttons/Button';
import ObjectiveService from '~/services/ObjectiveService';
import util from 'util';
import StakingService from '~/services/StakingService';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import _ from 'lodash';
import DashboardNftComponent from '~/components/dashboard/dashboardNftComponent';
import PaginationControls from '~/components/pagination/paginationControls';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import NFTService from '~/services/NFTService';

const refillTextColor = 0xa3a3b9;
const stardustTextColor = 0xff6eb4;

const currencyColors = {
  neon_gold: 0xffe100,
  space_elixir: 0x00d4f7,
  stardust: 0xff87ac,
};

const defaultTableHeight = 479;

const itemWidth = 1014,
  itemHeight = 107,
  itemRadius = 8,
  itemColor = 0x262637,
  itemAlpha = 1;

const collectionSlugs = {
  sw_genesis: 'starwolves',
  sw_generative: 'starwolvez-generative',
  ga_generative_new: 'gapes-nft',
  ga_generative_old: 'galacticapes',
  ga_genesis: 'galacticapesgenesis',
  ga_monkes: 'galacticmonkes',
};

const itemTypes = {
  nft: 'nft',
  default: 'default',
  header: 'header',
};

const defaultCollectionImage = {
  sw_genesis: 'sw_genesis_empty',
  sw_generative: 'sw_generative_empty',
  ga_generative: 'gape_generative_empty',
  ga_genesis: 'gape_genesis_empty',
  ga_monkes: 'gape_monke_empty',
};

const collectionUrls = {
  sw_genesis: 'https://opensea.io/collection/starwolves',
  sw_generative: 'https://opensea.io/collection/starwolvez-generative',
  ga_generative: 'https://opensea.io/collection/galacticapes',
  ga_genesis: 'https://opensea.io/collection/galacticapesgenesis',
  ga_monkes: 'https://opensea.io/collection/galacticmonkes',
};

const stakingWaitPeriod = 60 * 60 * 24 * 7; // 7 days in seconds
const pageSize = 30;

export default class StakingWolvezContentPane extends FixWidthSizer {
  private _nftData: any;
  private _stakingInfo: any;
  private _allNfts;
  private _tableData: any;
  private _table: any;
  private _title: any;
  private _paneWidth: number;
  private _paneHeight: number;
  private _tableHeight: number;
  private _pagingControls;
  private _currentPage;
  private _totalNftCount;
  private _loadingIndicator;
  private _parentSizer;
  private _scale: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    paneScale: number,
    paneWidth?: number,
    paneHeight?: number,
    parentSizer?: any,
    config?: any,
  ) {
    const conf = config
      ? config
      : {
          width: (paneWidth || 1062) * paneScale,
          height: (paneHeight || 473) * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);
    const self = this;

    this._scale = paneScale;
    this._paneWidth = paneWidth || 660;
    this._paneHeight = paneHeight || defaultTableHeight;
    this._tableHeight = this._paneHeight;
    this._parentSizer = parentSizer;

    var data = StakingService.getInstance().getStakingInfoSync();

    this._currentPage = 1;
    this._nftData = data.nfts;
    this._stakingInfo = _.omit(data, ['nfts']);
    this._totalNftCount = Object.keys(this._stakingInfo)
      .map(coll => this._stakingInfo[coll])
      .reduce((prev, curr, i) => {
        return prev + curr;
      });

    this.buildAndEnrichData();

    scene.events.emit('set_open_all_enabled');

    // UI Elements
    if (this._tableData && this._tableData.length > 0) {
      // Pagination Controls
      if (this._totalNftCount > pageSize) this.addPagingButtons(scene);

      // Table
      const table = this.createTable(scene, this._tableData, x, y);
    }
  }

  public hidePane() {
    this._title.setAlpha(0);
    this._table.setAlpha(0);
    this._table.disableInteractive();
  }

  public showPane() {
    this._title.clearAlpha();
    this._table.clearAlpha();
    this._table.setInteractive();
  }

  public updateTable(forceUpdate = true) {
    if (forceUpdate) {
      StakingService.getInstance()
        .getStakingInfo(undefined, undefined, (this._currentPage - 1) * pageSize, pageSize, true)
        .then(data => {
          this._nftData = data.nfts;
          this.buildAndEnrichData();
          this._table.setItems(this._tableData);
          this.scene.events.emit('set_open_all_enabled');
        });
    } else {
      this._table.setItems(this._tableData);
    }
  }

  createTable(scene, data, x, y) {
    var cellHeight = itemHeight + 12;
    var headerHeight = 40;
    var numHeaders = data.filter(itm => itm.itemType === itemTypes.header).length;
    var numNftRows = data.length - numHeaders;

    var tableHeight = scene.applyScale(
      cellHeight * numNftRows + headerHeight * numHeaders >= this._tableHeight
        ? this._tableHeight
        : cellHeight * numNftRows + headerHeight * numHeaders - 1,
    );

    tableHeight = tableHeight < 0 ? 0 : tableHeight;

    if (this._pagingControls && cellHeight * numNftRows + headerHeight * numHeaders >= this._tableHeight) {
      tableHeight = this._tableHeight - scene.applyScale(46);
    }

    this._table = scene.rexUI.add
      .gridTable({
        width: scene.applyScale(this._paneWidth),
        height: tableHeight,
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: scene.applyScale(this._paneWidth - 32),
          cellHeight: scene.applyScale(cellHeight),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        space: {
          table: {
            top: scene.applyScale(this._pagingControls ? 0 : 20),
            left: scene.applyScale(9),
            bottom: scene.applyScale(20),
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          if (item.itemType === itemTypes.nft) {
            var displayName;

            switch (item.collection_slug) {
              case collectionSlugs.sw_generative:
                displayName = `Star Wolf #${item.token_id}`;
                break;
              case collectionSlugs.sw_genesis:
                displayName = `Genesis Wolf #${item.token_id}`;
                break;
              case collectionSlugs.ga_generative_new:
                displayName = `Galactic Ape #${item.token_id}`;
                break;
              case collectionSlugs.ga_generative_old:
                displayName = `Galactic Ape #${item.token_id}`;
                break;
              case collectionSlugs.ga_genesis:
                var id = item.name.split(' ')[1];
                displayName = `Genesis Ape ${id}`;
                break;
              case collectionSlugs.ga_monkes:
                displayName = `Galactic Monke #${item.token_id}`;
                break;
            }

            var sizer = scene.rexUI.add
              .overlapSizer({
                width: width,
                space: {
                  left: scene.applyScale(7),
                  right: 0,
                  top: 0,
                  bottom: 0,
                  item: 0,
                  line: 0,
                },
                align: 0,
              })
              .add(
                scene.add.rexRoundRectangle(
                  0,
                  0,
                  scene.applyScale(width), // width
                  scene.applyScale(itemHeight), // height
                  scene.applyScale(itemRadius), // radius
                  itemColor, // fillColor
                  itemAlpha, // alpha
                ),
                {
                  key: 'staking_item_bg',
                  align: 'center',
                },
              )
              .add(scene.add.existing(new DashboardNftComponent(scene, 0, 0, item, scene.getScale())), {
                key: 'item-pfp',
                align: 'left-top',
                offsetX: scene.applyScale(7),
                offsetY: scene.applyScale(8),
                expand: false,
              })
              .add(scene.add.bitmapText(0, 0, 'cc_outline', displayName, scene.applyScale(16), 1), {
                key: 'title-text',
                align: 'left-top',
                offsetX: scene.applyScale(108),
                offsetY: scene.applyScale(11),
                expand: false,
              })
              .add(scene.add.bitmapText(0, 0, 'cc_outline', `Held for: ${item.days_held} days`, scene.applyScale(12), 1).setTint(refillTextColor), {
                key: 'held-text',
                align: 'left-top',
                offsetX: scene.applyScale(108),
                offsetY: scene.applyScale(44),
                expand: false,
              })
              .add(
                scene.add
                  .bitmapText(0, 0, 'cc_outline', `Net Yield: ${item.stardust_earned.toLocaleString('en-us')}`, scene.applyScale(12), 1)
                  .setTint(refillTextColor),
                {
                  key: 'total-yield-text',
                  align: 'left-top',
                  offsetX: scene.applyScale(108),
                  offsetY: scene.applyScale(62),
                  expand: false,
                },
              )
              .add(
                scene.add
                  .bitmapText(0, 0, 'cc_outline', `Daily Yield: ${item.daily_yield.toLocaleString('en-us')}`, scene.applyScale(12), 1)
                  .setTint(refillTextColor),
                {
                  key: 'daily-yield-text',
                  align: 'left-top',
                  offsetX: scene.applyScale(108),
                  offsetY: scene.applyScale(80),
                  expand: false,
                },
              );

            function handleClaimClick(scene, sizer, item, x, y) {
              scene.scene.pause();
              scene.scene.launch('DailyChestModalScene', {
                item: item,
                text: 'Opening daily chest...',
                type: 'nft',
              });
            }

            function addStakingActionDisplay(scene: Phaser.Scene, sizer, item, x, y) {
              if (!item.staking_reward_info) {
                const claimButton = new Button(scene, 0, 0, 'daily_chest_button', false).setScale(scene.getScale());
                claimButton.onClick().subscribe(pointer => {
                  handleClaimClick(scene, sizer, item, x, y);
                });
                sizer.add(scene.add.existing(claimButton), {
                  key: `claim_button`,
                  align: 'right-bottom',
                  offsetX: scene.applyScale(-10),
                  offsetY: scene.applyScale(-10),
                  expand: false,
                });
              } else {
                addCompletedDisplay(scene, sizer, item, x, y);
              }
            }

            function addCompletedDisplay(scene: Phaser.Scene, sizer, item, x, y) {
              sizer.add(
                scene.rexUI.add.label({
                  height: scene.applyScale(24),
                  orientation: 'x',
                  rtl: true,
                  icon: scene.add.image(0, 0, `${item.staking_reward_info.currency_name}_icon`).setScale(scene.applyScale(0.65)),
                  text: scene.add
                    .bitmapText(0, 0, 'cc_outline', item.staking_reward_info.currency_reward_value.toLocaleString('en-US'), scene.applyScale(18), 0)
                    .setCharacterTint(
                      0,
                      item.staking_reward_info.currency_reward_value.toLocaleString('en-US').length,
                      true,
                      currencyColors[item.staking_reward_info.currency_name],
                    ),
                  space: {
                    icon: 8,
                  },
                }),
                {
                  key: 'reward_label',
                  align: 'right-center',
                  offsetX: scene.applyScale(-21),
                  offsetY: scene.applyScale(0),
                  expand: false,
                },
              );
            }

            if (item.isStaked) {
              addStakingActionDisplay(scene, sizer, item, x, y);
            }

            return sizer.layout();
          } else if (item.itemType === itemTypes.header) {
            cell.height = scene.applyScale(40);

            const collectionName = scene.add.bitmapText(0, 0, 'cc_outline', item.collection_name, scene.applyScale(22), 1);

            var sizer = scene.rexUI.add.overlapSizer({
              width: width,
              space: {
                left: scene.applyScale(7),
                right: 0,
                top: 0,
                bottom: 0,
                item: 0,
                line: 0,
              },
              align: 0,
            });

            var titleSizer = scene.rexUI.add
              .fixWidthSizer({
                width: scene.applyScale(600),
                align: 0,
              })
              .add(scene.add.existing(collectionName), {
                key: 'collection-text',
                padding: { right: scene.applyScale(10), left: 0, top: 0, bottom: 0 },
              })
              .add(scene.add.bitmapText(0, 0, 'cc_outline', item.count, scene.applyScale(22), 1).setTint(refillTextColor), {
                key: 'count-text',
                padding: { right: 0, left: 0, top: 0, bottom: 0 },
              })
              .layout();

            sizer.add(titleSizer, {
              key: 'title_sizer',
              align: 'left-top',
              offsetX: 0,
              offsetY: 0,
              expand: false,
            });

            sizer.add(
              scene.rexUI.add.label({
                x: 0,
                y: 0,
                orientation: 'x',
                rtl: true,
                icon: scene.add.image(0, 0, 'objective_stardust_icon').setScale(scene.getScale()),
                text: scene.add.bitmapText(0, 0, 'cc_outline', `${item.perDay} per day`, scene.applyScale(18), 1).setTint(stardustTextColor),
                space: {
                  icon: scene.applyScale(8),
                  left: scene.applyScale(0),
                  right: scene.applyScale(0),
                  top: scene.applyScale(0),
                  bottom: scene.applyScale(0),
                },
                align: 'right',
              }),
              {
                key: 'stake_reward_label',
                align: 'right-top',
                offsetX: 0,
                offsetY: 0,
                expand: false,
              },
            );

            return sizer.layout();
          } else {
            var rectConfig = {
              maskType: 'roundRectangle',
              radius: 0,
            };

            var itemImageData = new Phaser.GameObjects.Image(scene, 0, 0, item.image_key);
            rectConfig.radius = itemImageData.width / 4;

            var itemImage = scene.add.rexCircleMaskImage(0, 0, item.image_key, '', rectConfig);
            itemImage.displayHeight = scene.applyScale(90);
            itemImage.scaleX = itemImage.scaleY;

            itemImageData.destroy();

            var sizer = scene.rexUI.add
              .overlapSizer({
                width: width,
                space: {
                  left: scene.applyScale(7),
                  right: 0,
                  top: 0,
                  bottom: 0,
                  item: 0,
                  line: 0,
                },
                align: 0,
              })
              .add(
                scene.add.rexRoundRectangle(
                  0,
                  0,
                  scene.applyScale(width), // width
                  scene.applyScale(itemHeight), // height
                  scene.applyScale(itemRadius), // radius
                  itemColor, // fillColor
                  itemAlpha, // alpha
                ),
                {
                  key: 'staking_item_bg',
                  align: 'center',
                },
              )
              .add(scene.add.existing(itemImage.setDepth(100)), {
                key: 'item-pfp',
                align: 'left-top',
                offsetX: scene.applyScale(7),
                offsetY: scene.applyScale(8),
                expand: false,
              })
              .add(scene.add.bitmapText(0, 0, 'cc_outline', item.collection_name, scene.applyScale(16), 1), {
                key: 'title-text',
                align: 'left-top',
                offsetX: scene.applyScale(108),
                offsetY: scene.applyScale(11),
                expand: false,
              })
              .add(
                scene.add
                  .bitmapText(0, 0, 'cc_outline', `Daily Yield: ${item.daily_yield?.toLocaleString('en-us')}`, scene.applyScale(12), 1)
                  .setTint(refillTextColor),
                {
                  key: 'daily-yield-text',
                  align: 'left-top',
                  offsetX: scene.applyScale(108),
                  offsetY: scene.applyScale(80),
                  expand: false,
                },
              );

            function addBuyButton(scene: Phaser.Scene, sizer, item, x, y) {
              const buyButton = new Button(scene, 0, 0, 'buy_one_button', false).setScale(scene.getScale());
              buyButton.onClick().subscribe(pointer => {
                window.open(item.url, '_blank')?.focus();
              });
              sizer.add(scene.add.existing(buyButton), {
                key: `buy_button`,
                align: 'right-bottom',
                offsetX: scene.applyScale(-10),
                offsetY: scene.applyScale(-10),
                expand: false,
              });
            }

            addBuyButton(scene, sizer, item, 0, 0);
            return sizer.layout();
          }
        },
      })
      .setOrigin(0.5, 0);

    this._table.setItems(data).scrollToTop();

    this.add(this._table, {
      padding: { left: 0, right: 0, top: this._pagingControls ? scene.applyScale(13) : 0, bottom: 0 },
      key: 'staking_table',
    });

    this._parentSizer?.layout();
  }

  private addPagingButtons(scene: Phaser.Scene) {
    this._pagingControls = new PaginationControls(
      scene,
      0,
      0,
      { totalCount: this._totalNftCount, pageSize: pageSize },
      this._paneWidth - 65,
      this._scale,
    );

    this.add(this._pagingControls, {
      key: 'paging_controls',
      padding: { left: scene.applyScale(33), right: 0, bottom: scene.applyScale(0), top: scene.applyScale(10) },
    });
  }

  private buildAndEnrichData() {
    this._allNfts = [];
    while (this._tableData && this._tableData.length > 0) this._tableData.pop();
    this._tableData = undefined;

    const local_sw_genesis_count = this._nftData.filter(nft => nft.collection_slug === collectionSlugs.sw_genesis).length;
    const local_sw_generative_count = this._nftData.filter(nft => nft.collection_slug === collectionSlugs.sw_generative).length;
    const local_ga_genesis_count = this._nftData.filter(nft => nft.collection_slug === collectionSlugs.ga_genesis).length;
    const local_ga_generative_count = this._nftData.filter(
      nft => nft.collection_slug === collectionSlugs.ga_generative_old || nft.collection_slug === collectionSlugs.ga_generative_new,
    ).length;
    const local_ga_monkes_count = this._nftData.filter(nft => nft.collection_slug === collectionSlugs.ga_monkes).length;

    const numPages = this._totalNftCount === 0 ? 1 : Math.ceil(this._totalNftCount / pageSize);

    if (local_sw_genesis_count > 0 || (this._currentPage === numPages && this._stakingInfo.sw_genesis_count === 0)) {
      this.processCollection('Star Wolvez Genesis', collectionSlugs.sw_genesis, defaultCollectionImage.sw_genesis, 50, collectionUrls.sw_genesis);
    }
    if (local_sw_generative_count > 0 || (this._currentPage === numPages && this._stakingInfo.sw_generative_count === 0)) {
      this.processCollection('Star Wolvez', collectionSlugs.sw_generative, defaultCollectionImage.sw_generative, 5, collectionUrls.sw_generative);
    }
    if (local_ga_genesis_count > 0 || (this._currentPage === numPages && this._stakingInfo.ga_genesis_count === 0)) {
      this.processCollection('Galactic Apes Genesis', collectionSlugs.ga_genesis, defaultCollectionImage.ga_genesis, 8, collectionUrls.ga_genesis);
    }
    if (local_ga_generative_count > 0 || (this._currentPage === numPages && this._stakingInfo.ga_generative_count === 0)) {
      this.processCollection(
        'Galactic Apes',
        [collectionSlugs.ga_generative_new, collectionSlugs.ga_generative_old],
        defaultCollectionImage.ga_generative,
        2,
        collectionUrls.ga_generative,
      );
    }
    if (local_ga_monkes_count > 0 || (this._currentPage === numPages && this._stakingInfo.galactic_monkes === 0)) {
      this.processCollection('Galactic Monkes', collectionSlugs.ga_monkes, defaultCollectionImage.ga_monkes, 1, collectionUrls.ga_monkes);
    }
    // Combine all collections for table data
    this._tableData = this._allNfts;
  }

  private processCollection(collectionName: string, collectionSlug: string | string[], defaultImage: string, dailyYield: number, url: string) {
    var filteredNfts: any[] = [];

    if (typeof collectionSlug === 'string') {
      filteredNfts = this._nftData.filter(nft => nft.collection_slug === collectionSlug);
    } else if (Array.isArray(collectionSlug)) {
      filteredNfts = this._nftData.filter(nft => collectionSlug.includes(nft.collection_slug));
    }

    filteredNfts.forEach(fn => (fn.daily_yield = dailyYield));

    let perDay = 0;
    let count = 0;
    const si = this._stakingInfo;

    const switchSlug = Array.isArray(collectionSlug) ? collectionSlug[0] : collectionSlug;

    switch (switchSlug) {
      case collectionSlugs.sw_generative:
        count = si.sw_generative_count;
        perDay = si.sw_generative_count * 5;
        break;
      case collectionSlugs.sw_genesis:
        count = si.sw_genesis_count;
        perDay = si.sw_genesis_count * 50;
        break;
      case collectionSlugs.ga_generative_old:
        count = si.ga_generative_count;
        perDay = si.ga_generative_count * 2;
        break;
      case collectionSlugs.ga_generative_new:
        count = si.ga_generative_count;
        perDay = si.ga_generative_count * 2;
        break;
      case collectionSlugs.ga_genesis:
        count = si.ga_genesis_count;
        perDay = si.ga_genesis_count * 8;
        break;
      case collectionSlugs.ga_monkes:
        count = si.galactic_monkes;
        perDay = si.galactic_monkes * 1;
        break;
    }

    filteredNfts.forEach(nft => {
      nft.itemType = itemTypes.nft;
      nft.daily_yield = dailyYield;
      if (this.getTimeBeforeStaked(nft) === 0) {
        nft.isStaked = true;
      } else {
        nft.isStaked = false;
      }
    });

    if (filteredNfts.length !== 0) {
      this.sortCollectionByUnstaked(filteredNfts);

      const header = { collection_name: collectionName, itemType: itemTypes.header, count: count, perDay: perDay };
      filteredNfts.unshift(header);
    } else {
      filteredNfts[0] = {
        collection_name: collectionName,
        itemType: itemTypes.header,
        count: count,
        perDay: perDay,
      };

      filteredNfts[1] = { collection_name: collectionName, itemType: itemTypes.default, daily_yield: dailyYield, image_key: defaultImage, url: url };
    }

    this._allNfts = this._allNfts.concat(filteredNfts);
  }

  private sortCollectionByUnstaked(data: any) {
    data.sort((a, b) => {
      const timeA = this.getTimeBeforeStaked(a);
      const timeB = this.getTimeBeforeStaked(b);

      if (timeA > timeB) {
        return -1;
      }
      if (timeB > timeA) {
        return 1;
      }
      return 1;
    });
  }

  private getTimeBeforeStaked(nft: any) {
    const now = Math.floor(Date.now() / 1000);

    const timeSpan = !nft.purchased_time ? 0 : now - nft.purchased_time;
    const timeRemaining = stakingWaitPeriod - timeSpan;

    return timeRemaining >= 0 ? timeRemaining : 0;
  }

  setWolvezData(nftData: any) {
    if (nftData) {
      this._nftData = nftData;
    }
  }

  private fetchNftData(startIndex) {
    return StakingService.getInstance()
      .getStakingInfo(undefined, Object.values(collectionSlugs), startIndex, pageSize, true)
      .then(res => {
        this._nftData = res.nfts;

        return this._nftData;
      });
  }

  updatePage(pageNum: number) {
    this._currentPage = pageNum;
    this.refreshPane(true);
  }

  refreshPane(isPageUpdate?: boolean) {
    const oldNfts = this._nftData.filter(nft => nft !== undefined);

    // Remove Table
    this.removeTable();

    // Remove pagination controls if only 1 page of data
    if (!isPageUpdate) {
      this.removePaginationButtons();
    }

    // Add loading indicator
    this._loadingIndicator = new LoadingIndicator(this.scene, 0, 0, 0.8 * this._scale);
    this.add(this._loadingIndicator, {
      key: 'loading_indicator',
      padding: { left: this.scene.applyScale(255), right: 0, bottom: 0, top: this.scene.applyScale(100) },
    });

    this._parentSizer?.layout();

    this.fetchNftData((this._currentPage - 1) * pageSize).then(() => {
      // Load new nft images and remove old nft images
      this.scene.scene.launch('NftPageLoaderScene', {
        originating_scene: 'ObjectivesModalScene',
        nfts: this._nftData,
        old_nfts: oldNfts,
      });
    });
  }

  onPageLoaded() {
    this._loadingIndicator.setAlpha(0);
    this.remove(this._loadingIndicator, true);
    this._loadingIndicator = undefined;

    this._pagingControls?.enableButtons();

    if (this._totalNftCount > pageSize && !this._pagingControls) {
      this.addPagingButtons(this.scene);
    }

    // Create Table
    this.buildAndEnrichData();
    this.createTable(this.scene, this._tableData, 0, 0);
  }

  private removeTable() {
    // Remove table if it exists
    this._table?.setAlpha(0);
    this.remove(this._table, true);
    this._table = undefined;
  }

  private removePaginationButtons() {
    if (this._pagingControls) {
      this._pagingControls.setAlpha(0);
      this.remove(this._pagingControls, true);
      this._pagingControls = undefined;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('stakingWolvezContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new StakingWolvezContentPane(this.scene, x, y));
});
