import Phaser, { Scene } from 'phaser';
import { Subscription, unsubscribe } from 'rxjs';
import NFTService from '~/services/NFTService';
import SquadService from '~/services/SquadService';
import RoundImageButton from '../buttons/RoundImageButton';
import Button from '../buttons/Button';
import util from 'util';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import BossFightServer from '~/services/BossFightServerService';
import LoadingIndicator from '../loading/loadingIndicator';

const textStyle = {
  fontFamily: 'ccwhatchamacallitbold',
  fontSize: '16px',
  fontStyle: 'normal',
  color: '#FFFFFF',
  shadow: {
    offsetX: 2,
    offsetY: 2,
    color: '#000001',
  },
};

const goldProgressBarColor = 0xdea827;
const pinkProgressBarColor = 0xde2795;
const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 0.4;
const radius = 7;

const progressBarHeight = 22;
const levelBarWidth = 215;
const stardustBarWidth = 150;
const neonGoldBarWidth = 175;
const spaceElixirBarWidth = 175;

const panelWidth = 860;

const levelMin = 0;
const levelMax = 10;
const stardustMin = 0;
const stardustMax = 600;
const neonGoldMin = 0;
const neonGoldMax = 50000;
const spaceElixirMin = 0;
const spaceElixirMax = 50000;

const squadComponentWidth = 254;
const squadComponentHeight = 100;
const squadComponentRadius = 26;
const squadComponentFill = 0x350926;
const squadComponentAlpha = 1;

export default class SquadComponent extends OverlapSizer {
  private _squadData: any;
  private _nftData;
  private _squadMembers;

  private _squadMember1;
  private _squadMember2;
  private _squadMember3;

  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale?: number, config?: any) {
    let conf = {
      width: squadComponentWidth * (paneScale || 1),
      height: squadComponentHeight * (paneScale || 1),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    };

    super(scene, x, y, conf);

    let displayConfig = config
      ? config
      : {
          display_bg: true,
          allow_clicks: true,
        };

    this._scale = paneScale;

    if (displayConfig.display_bg) {
      const squadComponentBg = scene.add.rexRoundRectangle(
        0, // x
        0, // y
        squadComponentWidth, // width
        squadComponentHeight, // height
        scene.applyScale(squadComponentRadius), // radius
        squadComponentFill, // fillColor
        squadComponentAlpha, // alpha
      );
      this.addBackground(squadComponentBg);
    }

    this._squadData = SquadService.getInstance().getSquadSync();
    this.setSquadMembers(this._squadData);
    this.createComponent(scene, x, y, displayConfig);

    this.layout();
  }

  private createComponent(scene: Phaser.Scene, x: number, y: number, config: any) {
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
      allowClicks: config.allow_clicks,
    };

    // Squad Member 1
    const sm1ImageData = new Phaser.GameObjects.Image(scene, 0, 0, this._squadMembers[0].image_key);
    const sm1ImageWidth = sm1ImageData.width;
    rectConfig.radius = sm1ImageWidth / 4;

    this._squadMember1 = new RoundImageButton(scene, 0, 0, this._squadMembers[0].image_key, rectConfig, false);
    this._squadMember1.displayHeight = 66;
    this._squadMember1.scaleX = this._squadMember1.scaleY;
    this._squadMember1.setScale(this._squadMember1.scaleY * this._scale);

    sm1ImageData.destroy();

    if (config.allow_clicks) {
      this._squadMember1.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => this.scene.sound.play('hud-button-hover'));

      this._squadMember1.onClick().subscribe(pointer => {
        this.squadMemberClickHandler(pointer);
      });
    }

    scene.add.existing(this._squadMember1);

    this.add(this._squadMember1, {
      key: 'squad_member_1',
      align: 'center',
      offsetX: scene.applyScale(-80),
      offsetY: 0,
      expand: false,
    });

    // Squad Member 2
    const sm2ImageData = new Phaser.GameObjects.Image(scene, 0, 0, this._squadMembers[1].image_key);
    const sm2ImageWidth = sm2ImageData.width;
    rectConfig.radius = sm2ImageWidth / 4;

    this._squadMember2 = new RoundImageButton(scene, 0, 0, this._squadMembers[1].image_key, rectConfig, false);
    this._squadMember2.displayHeight = 66;
    this._squadMember2.scaleX = this._squadMember2.scaleY;
    this._squadMember2.setScale(this._squadMember2.scaleY * this._scale);

    sm2ImageData.destroy();

    if (config.allow_clicks) {
      this._squadMember2.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => this.scene.sound.play('hud-button-hover'));

      this._squadMember2.onClick().subscribe(pointer => {
        this.squadMemberClickHandler(pointer);
      });
    }

    scene.add.existing(this._squadMember2);

    this.add(this._squadMember2, {
      key: 'squad_member_2',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Squad Member 3
    const sm3ImageData = new Phaser.GameObjects.Image(scene, 0, 0, this._squadMembers[2].image_key);
    const sm3ImageWidth = sm3ImageData.width;
    rectConfig.radius = sm3ImageWidth / 4;

    this._squadMember3 = new RoundImageButton(scene, 0, 0, this._squadMembers[2].image_key, rectConfig, false);
    this._squadMember3.displayHeight = 66;
    this._squadMember3.scaleX = this._squadMember3.scaleY;
    this._squadMember3.setScale(this._squadMember3.scaleY * this._scale);

    sm3ImageData.destroy();

    if (config.allow_clicks) {
      this._squadMember3.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => this.scene.sound.play('hud-button-hover'));

      this._squadMember3.onClick().subscribe(pointer => {
        this.squadMemberClickHandler(pointer);
      });
    }

    scene.add.existing(this._squadMember3);

    this.add(this._squadMember3, {
      key: 'squad_member_3',
      align: 'center',
      offsetX: scene.applyScale(80),
      offsetY: 0,
      expand: false,
    });

    return this.layout();
  }

  public updateSquad(forceUpdate = true) {
    if (forceUpdate) {
      SquadService.getInstance()
        .getSquad(true)
        .then(res => {
          this.setSquadMembers(res);
          this.updateImages();
          BossFightServer.getInstance().updateParticipantData();
        });
    } else {
      this.setSquadMembers(SquadService.getInstance().getSquadSync());
      this.updateImages();
    }
  }

  private setSquadMembers(squadData) {
    this._squadMembers = squadData.map(sqMem => {
      sqMem.show_yield = false;
      sqMem.show_hp = false;
      return sqMem;
    });
  }

  private updateImages() {
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    // Squad Member 1
    const sm1ImageData = new Phaser.GameObjects.Image(this.scene, 0, 0, this._squadMembers[0].image_key);
    const sm1ImageWidth = sm1ImageData.width;
    rectConfig.radius = sm1ImageWidth / 4;

    this._squadMember1.setTexture(this._squadMembers[0].image_key, rectConfig);
    this._squadMember1.displayHeight = 66;
    this._squadMember1.scaleX = this._squadMember1.scaleY;
    this._squadMember1.setScale(this._squadMember1.scaleY * this._scale);

    sm1ImageData.destroy();

    // Squad Member 2
    const sm2ImageData = new Phaser.GameObjects.Image(this.scene, 0, 0, this._squadMembers[1].image_key);
    const sm2ImageWidth = sm2ImageData.width;
    rectConfig.radius = sm2ImageWidth / 4;

    this._squadMember2.setTexture(this._squadMembers[1].image_key, rectConfig);
    this._squadMember2.displayHeight = 66;
    this._squadMember2.scaleX = this._squadMember2.scaleY;
    this._squadMember2.setScale(this._squadMember2.scaleY * this._scale);

    sm2ImageData.destroy();

    // Squad Member 3
    const sm3ImageData = new Phaser.GameObjects.Image(this.scene, 0, 0, this._squadMembers[2].image_key);
    const sm3ImageWidth = sm3ImageData.width;
    rectConfig.radius = sm3ImageWidth / 4;

    this._squadMember3.setTexture(this._squadMembers[2].image_key, rectConfig);
    this._squadMember3.displayHeight = 66;
    this._squadMember3.scaleX = this._squadMember3.scaleY;
    this._squadMember3.setScale(this._squadMember3.scaleY * this._scale);

    sm3ImageData.destroy();
  }

  private squadMemberClickHandler(this, pointer) {
    this.scene.scene.pause('HomebaseScene');
    this.scene.scene.pause('HudScene');
    this.scene.scene.launch('SquadModalScene');
  }
}

Phaser.GameObjects.GameObjectFactory.register('squadComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new SquadComponent(this.scene, x, y));
});
