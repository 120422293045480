import ApiProxyService from "./ApiProxyService";
import WalletConnect from "./WalletConnectService";
import util from 'util';

export default class BlobService {

    private static _instance:BlobService = new BlobService();

    private _blobInfo: any = [];

    constructor() {
        if(BlobService._instance){
            throw new Error("Error: Instantiation failed: Use BlobService.getInstance() instead of new.");
        }
        BlobService._instance = this;
    }

    public static getInstance():BlobService
    {
        return BlobService._instance;
    }

    public setBlobInfo(value):void
    {
        this._blobInfo = value;
    }

    public getBlobInfo(forceUpdate: boolean = false)
    {
        return new Promise((resolve, reject) => {
            if (this._blobInfo && !forceUpdate) {
                return resolve(this._blobInfo);
            } else {
                return resolve(this.fetchBlobInfo());
            }
        });
    }

    public getBlobInfoSync() {
        return this._blobInfo;
    }

    public performAction(actionId) {
        const wallet = WalletConnect.getInstance().getWalletAddress();
        return ApiProxyService.getInstance().post('blobs', [wallet, 'action', 'start', actionId], {})
            .then(res => {
                return res;
            });
    }

    private fetchBlobInfo() {
        const wallet = WalletConnect.getInstance().getWalletAddress();
        return ApiProxyService.getInstance().get('blobs', [wallet], [])
            .then(res => {
                this._blobInfo = res.blob;
                return res;
            });
    }
}