import Phaser from 'phaser';

export default class ErrorScene extends Phaser.Scene {
  
  constructor() {
    super({
      key: 'ErrorScene'
    });
  }

  create() {
    // this.cameras.main.fadeIn(500, 0, 0, 0);
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 120, 'cc_outline', 'Oops! Something went wrong.\nPlease refresh and try again.', 26, 0).setOrigin(0.5, 0).setCenterAlign();
    this.add.bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 100, 'cc_outline', '*If the problem persists please notify the team in discord.', 17, 0).setOrigin(0.5, 0).setCenterAlign();
    
    this.add.sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 20, 'party_blob').setOrigin(0.5, 0.5); 
  }
}