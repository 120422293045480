import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import { Base_Raid_Next_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 270;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BaseRaidConfirmNextBaseLoadScene extends Phaser.Scene {
  private _scale;
  private _isFirstBaseView;
  private _confirmButton;

  constructor() {
    super({
      key: 'BaseRaidConfirmNextBaseLoadScene',
    });
  }

  init(data: any) {
    if (data) {
      this._isFirstBaseView = data.isFirst;
    } else {
      this._isFirstBaseView = false;
    }
  }

  preload() {
    LoadImageAssets(this, Base_Raid_Next_Scene_Assets);
  }

  create() {
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    const modalBg = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2, // x
        this.sys.canvas.height / 2, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale(modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
      )
      .setInteractive({ cursor: 'default' })
      .setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2 - this.applyScale(10),
      width: this.applyScale(350),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add Title Text
    sizer.add(
      this.add
        .bitmapText(0, 0, 'cc_outline', 'Viewing another base will cost the following resources:', this.applyScale(24), 1)
        .setMaxWidth(this.applyScale(350)),
      {
        key: 'title_text',
        padding: { left: 0, right: 0, top: this.applyScale(20), bottom: 0 },
      },
    );

    sizer.add(this.add.existing(new RewardLabel(this, 0, 0, this._scale, 'neon_gold', '100')), {
      key: 'cost_label',
      padding: { left: 0, right: 0, top: this.applyScale(30), bottom: 0 },
    });

    // Add Cancel Button
    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      false,
    ).setScale(this._scale);
    cancelButton.onClick().subscribe(pointer => {
      this.cancelButtonClickHandler();
    });
    this.add.existing(cancelButton);

    // Add Confirm Button
    this._confirmButton = new Button(this, this.sys.canvas.width / 2 + this.applyScale(75), modalBg.getBottomLeft().y, 'pvp_confirm_button', false)
      .setScale(this._scale)
      .setInteractive({ cursor: 'pointer' });

    this._confirmButton.onClick().subscribe(pointer => {
      this.confirmButtonClickHandler();
    });

    this.add.existing(this._confirmButton);

    // Add key bindings
    this.input.keyboard.on('keydown-ENTER', event => {
      this.confirmButtonClickHandler();
    });

    this.input.keyboard.on('keydown-ESC', event => {
      this.cancelButtonClickHandler();
    });

    sizer.layout();
  }

  cancelButtonClickHandler() {
    if (this._isFirstBaseView) {
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    } else {
      this.scene.get('BaseRaidDetailsScene').input.enabled = true;
      this.scene.get('BaseRaidHudScene').input.enabled = true;
      this.scene.stop();
    }
    this.unloadAssets();
  }

  confirmButtonClickHandler() {
    this._confirmButton.disableInteractive();

    if (this._isFirstBaseView) {
      this.scene.stop();
      this.scene.stop('HomebaseScene');
      this.scene.stop('HudScene');
      this.scene.start('BaseRaidLoadingScene', { next: 0 });
    } else {
      this.scene.stop();
      this.scene.stop('BaseRaidDetailsScene');
      this.scene.stop('BaseRaidHudScene');
      this.scene.start('BaseRaidLoadingScene', { next: 1 });
    }
    this.unloadAssets();
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Base_Raid_Next_Scene_Assets));
  }
}
