import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';

const imageHeight = 250; // same as imageWidth
const imageMargin = 10;

const itemHeight = imageHeight + 70;
const itemWidth = imageHeight + imageMargin * 2;

const imageBorderColor = 0xffffff;
const imageBorderWidth = 1;
const imageBorderAlpha = 0.7;

const imageAreaFillColor = 0x000000;
const imageAreaFillAlpha = 0;
const imageAreaBorderWidth = 1;
const imageAreaBorderColor = 0x34333d;
const imageAreaBorderAlpha = 1;

const labelFillColor = 0x1d1a2c;
const labelFillAlpha = 0.7;
const labelBorderRadius = 7;
const labelBorderWidth = 1;
const labelBorderColor = 0x2b2c3b;
const labelBorderAlpha = 1;
const labelMargin = 20;

const ShopItemImageKeys = [
  { shop_item_id: 15, image_key: 'nm8_chip' },
  { shop_item_id: 16, image_key: 'bk55_chip' },
  { shop_item_id: 20, image_key: 'fur69_chip' },
  { shop_item_id: 29, image_key: 'fur70_chip' },
  { shop_item_id: 22, image_key: 'artifact_0002' },
  { shop_item_id: 23, image_key: 'artifact_0003' },
  { shop_item_id: 27, image_key: 'artifact_0004' },
  { shop_item_id: 24, image_key: 'artifact_0005' },
  { shop_item_id: 25, image_key: 'artifact_0006' },
  { shop_item_id: 26, image_key: 'artifact_0007' },
  { shop_item_id: 28, image_key: 'artifact_0008' },
  { shop_item_id: 36, image_key: 'artifact_0009' },
  { shop_item_id: 30, image_key: 'artifact_0010' },
  { shop_item_id: 35, image_key: 'artifact_0011' },
];

export default class PackItemComponent extends OverlapSizer {
  private _item;
  private _itemImage;
  private _itemLabel;
  private _veil;
  private _isFirst;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, isFirst: boolean, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * itemWidth,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._item = data;
    this._isFirst = isFirst;
    return this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    if (!data.loot_pack_reward_id) {
      return this.layout();
    }

    // Add Item Image
    this.addItemImage(scene);

    // Add Image Area Border
    this.addImageAreaBorder(scene);

    // Add Label
    this.addItemLabel(scene);

    if (this._isFirst) {
      this.addItemVeil(scene);

      // Add a little delay before reveal sound on first pack item
      scene.time.addEvent({
        delay: 500,
        loop: false,
        callback: () => {
          scene.playPackItemRevealSound();
        },
      });
    } else {
      // Play sound immediately if not first pack item
      scene.playPackItemRevealSound();
    }

    return this.layout();
  }

  private addItemImage(scene: Phaser.Scene) {
    // Add Image
    const imageKey = this.getItemImageKey();

    var imageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);
    var imageWidth = imageData.width;

    const rectConfig = {
      maskType: 'roundRectangle',
      radius: Math.ceil(imageWidth / 10),
      strokeColor: imageBorderColor,
      strokeWidth: this.applyScale(imageBorderWidth),
    };

    this._itemImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);
    this._itemImage.displayWidth = this.applyScale(imageHeight);
    this._itemImage.scaleY = this._itemImage.scaleX;
    imageData.destroy();

    this.add(this._itemImage, {
      key: 'item_image',
      align: 'left-top',
      offsetX: this.applyScale(imageMargin),
      offsetY: this.applyScale(imageMargin),
      expand: false,
    });
  }

  private addImageAreaBorder(scene: Phaser.Scene) {
    // Add Image Area Border
    const imageAreaBorder = scene.add.rexRoundRectangle(
      0,
      0,
      this.applyScale(itemWidth), // width
      this._itemImage.displayHeight + this.applyScale(imageMargin * 2), // height
      this.applyScale(itemWidth / 8), // radius
      imageAreaFillColor, // fillColor
      imageAreaFillAlpha, // alpha
    );

    imageAreaBorder.setStrokeStyle(this.applyScale(imageAreaBorderWidth), imageAreaBorderColor, imageAreaBorderAlpha);

    this.add(imageAreaBorder, {
      key: 'image_area_border',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });
  }

  private addItemLabel(scene: Phaser.Scene) {
    const labelBg = scene.add.rexRoundRectangle(
      0,
      0,
      this.applyScale(itemWidth), // width
      this.applyScale(itemHeight), // height
      this.applyScale(labelBorderRadius), // radius
      labelFillColor, // fillColor
      labelFillAlpha, // alpha
    );

    labelBg.setStrokeStyle(this.applyScale(labelBorderWidth), labelBorderColor, labelBorderAlpha);

    this._itemLabel = scene.rexUI.add
      .label({
        x: 0,
        y: 0,
        height: 30,
        orientation: 'x',
        rtl: false,
        icon: undefined,
        background: labelBg,
        text: scene.add.bitmapText(0, 0, 'cc_outline', this._item.reward_name.toUpperCase(), this.applyScale(20), 0),
        space: {
          icon: this.applyScale(8),
          left: this.applyScale(14),
          right: this.applyScale(14),
          top: this.applyScale(6),
          bottom: this.applyScale(3),
        },
        align: 'center',
      })
      .layout();

    this.add(this._itemLabel, {
      key: 'item_label',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this._itemImage.displayHeight + this.applyScale(imageMargin * 2 + labelMargin),
      expand: false,
    });
  }

  private addItemVeil(scene: Phaser.Scene) {
    this._veil = scene.add.image(0, 0, 'pack_item_veil');
    this._veil.displayWidth = imageHeight + 30;
    this._veil.displayHeight = this._itemImage.displayHeight + 80;

    this.add(this._veil, {
      key: 'image_veil',
      align: 'left-top',
      offsetX: this.applyScale(imageMargin - 15),
      offsetY: this.applyScale(imageMargin - 15),
      expand: false,
    });

    this.addDissolveEffect(scene);
  }

  private getItemImageKey() {
    // NFT
    if (this._item.nft_id && this._item.nft_id > 0) {
      return this._item.nft_image_url;
    }

    // Shop Item
    if (this._item.shop_item_id && this._item.shop_item_id > 0) {
      return this.getShopItemImageKey();
    }

    // Currency
    if (this._item.currency_id && this._item.currency_id > 0) {
      return this.getCurrencyImageKey();
    }
  }

  private getShopItemImageKey() {
    const shopItem = ShopItemImageKeys.find(si => si.shop_item_id === this._item.shop_item_id);

    return shopItem ? shopItem.image_key : 'wolf_silhouette';
  }

  private getCurrencyImageKey() {
    switch (this._item.currency_id) {
      case 1:
        return 'neon_gold_pack_reward';
      case 2:
        return 'space_elixir_pack_reward';
      case 3:
        return 'stardust_pack_reward';
      default:
        return 'dark_matter_pack_reward';
    }
  }

  private addDissolveEffect(scene: Phaser.Scene) {
    // Use Dissolve PostFXPipeline for first item reveal
    var postFxPlugin = scene.plugins.get('rexDissolvePipeline');
    var postFxPipeline = postFxPlugin.add(this._veil, {
      // toTexture: nextKey,
      // toFrame: frameName,
      resizeMode: 2,
      noiseX: 20,
      noiseY: 10,
      noiseZ: 20,
      // fromEdgeStart: 0.01,
      // fromEdgeWidth: 0.05,
      // toEdgeStart: 0.01,
      // toEdgeWidth: 0.05,
      progress: 0,
      name: 'rexDissolvePostFx',
    });

    var tweenTask;

    tweenTask = scene.tweens
      .add({
        targets: postFxPipeline,
        resizeMode: 1,
        progress: 1,
        ease: 'Quad', // 'Cubic', 'Elastic', 'Bounce', 'Back'
        duration: 3000,
        repeat: 0, // -1: infinity
        yoyo: false,
      })
      .on(
        'complete',
        function () {
          postFxPipeline.setProgress(0);
          this._veil.setAlpha(0);
          tweenTask = undefined;
          scene.revealAdditionalItems(scene);
        },
        this,
      );
  }

  public addGlimmer(scene) {
    // Image glimmer
    const numImageStars = 3;

    for (let i = 0; i < numImageStars; i++) {
      const x = Phaser.Math.Between(this._itemImage.getTopLeft().x, this._itemImage.getTopRight().x);
      const y = Phaser.Math.Between(this._itemImage.getTopLeft().y, this._itemImage.getBottomLeft().y);
      this.addStar(scene, x, y);
    }

    // Label glimmer
    const numLabelStars = 2;

    for (let i = 0; i < numLabelStars; i++) {
      const x = Phaser.Math.Between(this._itemLabel.getTopLeft().x, this._itemLabel.getTopRight().x);
      const y = Phaser.Math.Between(this._itemLabel.getTopLeft().y, this._itemLabel.getBottomLeft().y);
      this.addStar(scene, x, y);
    }
  }

  private addStar(scene, x, y) {
    let maxRad = Phaser.Math.Between(20, 50);

    var starBase = scene.add.star(x, y, 8, maxRad / 5, maxRad, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starBase.setAlpha(0.5);

    var starMiddle = scene.add.star(x, y, 8, (maxRad * 0.6) / 5, maxRad * 0.6, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starMiddle.setAlpha(0.5);

    var starTop = scene.add.star(x, y, 8, (maxRad * 0.2) / 5, maxRad * 0.2, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starTop.setAlpha(0.5);

    scene.tweens.add({
      targets: [starBase, starMiddle, starTop],
      scale: 0.1,
      duration: 1500,
      ease: 'Quad',
      yoyo: true,
      repeat: -1,
    });
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('packItemComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new PackItemComponent(this.scene, x, y, data));
});
