import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import ProgressBar from '../progressBar/ProgressBar';

const imageHeight = 90;

const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0x440d31;
const backgroundAlpha = 1;
const radius = 7;
const progressBarBorderColor = 0x383d57;
const progressBarBorderWidth = 1;

const progressBarHeight = 18;
const progressBarWidth = imageHeight - 12;
const progressBarMin = 0;
const stakingWaitPeriod = 60 * 60 * 24 * 7;  // 7 days in seconds

export default class DashboardNftComponent extends OverlapSizer {
  private _nft;
  private _nftImage;
  private _scale;
  private _progressBar;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 90,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);
    
    this._scale = paneScale;
    this._nft = data;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // if (!data.id) {
    //   return this.layout();
    // }
    // NFT Image
    var imageData = new Phaser.GameObjects.Image(scene, 0, 0, this._nft.image_key);
    var imageWidth = imageData.width;

    const rectConfig = {
      maskType: 'roundRectangle',
      radius: Math.ceil(imageWidth / 8),
    };

    this._nftImage = scene.add.rexCircleMaskImage(0, 0, this._nft.image_key, '', rectConfig);
    this._nftImage.displayHeight = scene.applyScale(imageHeight);
    this._nftImage.scaleX = this._nftImage.scaleY;
    imageData.destroy();

    this.add(this._nftImage, {
      key: 'nft_image',
      align: 'center',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(0),
      expand: false,
    });

    // Progress Bar
    let progressValue = this.getTimeBeforeStaked();

    if (progressValue > 0) {
      this._progressBar = this.createProgressBar(scene, progressValue);

      this.add(this._progressBar, {
        key: 'progress_bar',
        align: 'center-bottom',
        offsetX: 0,
        offsetY: this.applyScale(-4),
        expand: false,
      });
    }

    return this.layout();
  }

  private createProgressBar(scene, progressValue) {
    // Progress Bar
    const progBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: this.secondsToTime(progressValue),
          offset: 2,
          fontSize: 14,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: blueProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressBarMin,
          maxValue: stakingWaitPeriod,
          progressValue: progressValue > 0 ? progressValue : 1,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
        border: {
          alpha: backgroundAlpha,
          color: progressBarBorderColor,
          width: progressBarBorderWidth,
        },
      },
    );

    return scene.add.existing(progBar);
  }

  private getTimeBeforeStaked() {
    const now = Math.floor(Date.now() / 1000);

    const timeSpan = !this._nft.purchased_time ? 0 : now - this._nft.purchased_time;
    const timeRemaining = stakingWaitPeriod - timeSpan;

    return timeRemaining >= 0 ? timeRemaining : 0;
  }

  private secondsToTime(seconds: number) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds - d * 24 * 3600) / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    var dDisplay = d > 0 ? `${d}D` : '';
    var hDisplay = h > 0 ? `${h}H` : '';
    var mDisplay = m > 0 ? `${m}M` : '';

    return d > 0 ? `${dDisplay} ${hDisplay} LEFT` : `${hDisplay} ${mDisplay} LEFT`;
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('dashboardNftComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new DashboardNftComponent(this.scene, x, y, data));
});
