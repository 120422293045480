import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import UserService from '~/services/UserService';
import StakingHeaderContentPane from '~/scenes/modals/objectives_modal/stakingHeaderContentPane';
import StakingWolvezContentPane from '~/scenes/modals/objectives_modal/stakingWolvezContentPane';

const imageKeys = {
  openDailyChestButton: 'open_daily_chest_button',
};

const paneWidth = 660;
const paneHeight = 595;
const stakingPaneHeight = 473;

const wolvezPaneOffsetY = 27;
const stakingPaneOffsetY = 119;
const dividerOffsetY = 115;

export default class ObjectivesStakingPane extends OverlapSizer {
  private _userData;
  private _stakingHeaderContentPane;
  private _stakingContentPane;
  private _parentSizer;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, parentSizer?: any, config?: any) {
    const conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneWidth * paneScale,
          height: paneHeight * paneScale,
          scrollMode: 0,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
        };

    super(scene, conf);

    this._scale = paneScale;
    this._userData = UserService.getInstance().getUserDataSync();
    this._parentSizer = parentSizer || this;

    // this.layout();
    this.createComponent();
  }

  createComponent() {
    const scene = this.scene;

    // Staking Header Pane
    this._stakingHeaderContentPane = scene.add.existing(new StakingHeaderContentPane(scene, 0, 0, this._scale, paneWidth));

    this._parentSizer
      .add(this._stakingHeaderContentPane, {
        key: 'staking_header_content_pane',
        align: 'left-top',
        offsetX: scene.applyScale(251),
        offsetY: scene.applyScale(wolvezPaneOffsetY),
        expand: true,
      })
      .layout();

    // Divider
    this.add(scene.add.image(0, 0, 'objectives_divider_line'), {
      key: 'staking_divider',
      align: 'left-top',
      offsetX: scene.applyScale(-3),
      offsetY: scene.applyScale(dividerOffsetY),
      expand: false,
    }).setScale(this._scale);

    // Staking Content Pane
    this._stakingContentPane = new StakingWolvezContentPane(scene, 0, 0, this._scale, paneWidth, stakingPaneHeight, this);
    //this._stakingContentPane = new StakingContentPane(scene, 0, 0, this._scale, paneWidth, stakingPaneHeight);

    this.add(this._stakingContentPane, {
      key: 'staking_content_pane',
      align: 'left-top',
      offsetX: 0,
      offsetY: scene.applyScale(stakingPaneOffsetY),
      expand: false,
    });

    // Layout Sizer
    this.layout();
  }

  getStakingPane() {
    return this._stakingContentPane;
  }

  getWolvezPane() {
    return this._stakingHeaderContentPane;
  }

  setOpenAllEnabled() {
    this._stakingHeaderContentPane.setOpenAllEnabled();
  }

  updatePage(pageNum: number) {
    this._stakingContentPane.updatePage(pageNum);
  }

  onPageLoaded() {
    this._stakingContentPane.onPageLoaded();
  }

  destroy() {
    this._parentSizer.remove(this._stakingHeaderContentPane, true);
    this._stakingHeaderContentPane = undefined;
    this.remove(this._stakingContentPane, true);
    this._stakingContentPane = undefined;
  }
}

Phaser.GameObjects.GameObjectFactory.register('objectivesStakingPane', function (scene: Phaser.Scene, x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ObjectivesStakingPane(scene, x, y));
});
