import Button from '~/components/buttons/Button';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MiningService, { Asteroid, asteroidRarityColorMapper, asteroidRarityLabelMapper } from '~/services/MiningService';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import { Mining_Status_Scene_Assets } from '~/utils/AssetLoader';
import { convertSecondsToCountdown } from '~/utils/GameUtils';
import { getSquadNetHP } from '~/utils/squad';
import { boostDamagePerHour, getMiningTraits } from '~/utils/TraitUtils';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

type Data = {
  asteroidId: Asteroid['asteroid_id'];
};

const width = 1044;
const height = 731;
const radius = 16;
const color = 0x1c1c2c;

export default class MiningStatusScene extends Phaser.Scene {
  private _scale!: number;
  private _squad;
  private _asteroidId!: number;
  private _timeRemainingInterval;
  private _traits;

  constructor() {
    super({
      key: 'MiningStatusScene',
    });
  }

  preload() {
    LoadImageAssets(this, Mining_Status_Scene_Assets);
  }

  init(data: Data) {
    this._asteroidId = data.asteroidId;
    this._squad = SquadService.getInstance().getSquadSync();
    this._traits = SquadService.getInstance().getTraitsSync();
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    const asteroids = MiningService.getInstance().getMiningStatusSync();
    const asteroid = asteroids.find(asteroid => asteroid.asteroid_id === this._asteroidId);
    if (!asteroid) return this.destroy();

    const squadNetHP = getSquadNetHP(this._squad);
    const miningTraits = getMiningTraits(this._traits);

    let rarity = 'Common';

    switch (asteroid.asteroid_rarity_id) {
      case 2:
        rarity = 'Rare';
        break;
      case 3:
        rarity = 'Epic';
        break;
      case 4:
        rarity = 'Legendary';
        break;
      case 5:
        rarity = 'Mythical';
        break;
    }

    const damagePerHour = boostDamagePerHour(squadNetHP, rarity, miningTraits);

    const potentialNetDamage = Math.round(damagePerHour * asteroid.nft_boost);

    // @ts-ignore
    const modalBackground = this.add.rexRoundRectangle(0, 0, this.applyScale(width), this.applyScale(height), this.applyScale(radius), color);

    const timeRemainingLabel = this.add.bitmapText(0, 0, 'cc_outline', 'Time remaining', this.applyScale(16), 1).setTint(0xa3a3b9);

    const startTime = new Date(asteroid.user_mining_started_time * 1000).getTime();
    const endTime = new Date(asteroid.estimated_mine_time_finished * 1000).getTime();

    let nowSeconds = Math.floor(Date.now() / 1000);
    let startSeconds = Math.floor(startTime / 1000);
    let endSeconds = Math.floor(endTime / 1000);
    let remainingSeconds = Math.floor(endSeconds - nowSeconds);

    const timeRemainingProgressBar = new ProgressBar(this, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: convertSecondsToCountdown(remainingSeconds),
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(313),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: startSeconds,
        maxValue: endSeconds,
        progressValue: startSeconds + remainingSeconds,
        glare: {
          width: this.applyScale(313 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    this._timeRemainingInterval = setInterval(() => {
      try {
        if (remainingSeconds <= 0) return clearInterval(this._timeRemainingInterval);

        remainingSeconds--;

        timeRemainingProgressBar.setProgressBarText(convertSecondsToCountdown(remainingSeconds));
        timeRemainingProgressBar.setProgressValue(this, startSeconds + remainingSeconds);
      } catch (error) {
        return clearInterval(this._timeRemainingInterval);
      }
    }, 1000);

    this.add.existing(timeRemainingProgressBar);

    const timeRemainingSizer = this.rexUI.add
      .sizer(0, 0, {
        orientation: 'y',
      })
      .add(timeRemainingLabel)
      .add(timeRemainingProgressBar)
      .layout();

    const damagePerHourProgressBar = new ProgressBar(this, 0, 0, this._scale, {
      title: {
        text: `Damage per hour: ${potentialNetDamage.toLocaleString('en-us')}`,
      },
      icon: undefined,
      border: {
        width: this.applyScale(1),
        color: 0x383d57,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: this.applyScale(313),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 100,
        glare: {
          height: this.applyScale(6),
          width: this.applyScale(313 - 10),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    this.add.existing(damagePerHourProgressBar);

    const asteroidHPRemainingProgressBar = new ProgressBar(this, 0, 0, this._scale, {
      title: {
        text: `Asteroid HP remaining: ${asteroid.asteroid_hp.toLocaleString('en-us')}`,
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: this.applyScale(313),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0xed0072,
        backgroundColor: 0xed0072,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: asteroid.asteroid_starting_hp,
        progressValue: asteroid.asteroid_hp,
        glare: {
          width: this.applyScale(313 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    this.add.existing(asteroidHPRemainingProgressBar);

    const backToHomebaseButton = new Button(this.scene.scene, 0, 0, 'mining_back_to_homebase_button').setScale(this._scale);

    this.add.existing(backToHomebaseButton);

    backToHomebaseButton.onClick().subscribe(() => {
      this.destroy();
    });

    const cancelButton = new Button(this.scene.scene, 0, 0, 'mining_cancel_mission_button').setScale(this._scale);

    this.add.existing(cancelButton);

    cancelButton.onClick().subscribe(() => {
      this.scene.launch('MiningCancelDialogScene', {
        asteroidId: this._asteroidId,
      });
    });

    // @ts-ignore
    this.rexUI.add
      .sizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
        orientation: 'y',
        width: this.applyScale(width),
        height: this.applyScale(height),
        space: { item: this.applyScale(31) },
      })
      .addBackground(modalBackground)
      .addSpace()
      .add(this.add.rexCircleMaskImage(0, 0, 'mining_paw').setScale(this._scale), {
        expand: false,
      })
      .add(
        this.add
          .bitmapText(
            0,
            0,
            'cc_outline',
            ['You are currently mining', `a ${asteroidRarityLabelMapper[asteroid.asteroid_rarity_id]} Asteroid`],
            this.applyScale(24),
            1,
          )
          .setWordTint(asteroidRarityLabelMapper[asteroid.asteroid_rarity_id], 1, false, asteroidRarityColorMapper[asteroid.asteroid_rarity_id])
          .setTint(0xa3a3b9),
        {
          align: 'center',
        },
      )
      .add(timeRemainingSizer)
      .add(damagePerHourProgressBar)
      .add(asteroidHPRemainingProgressBar)
      .add(
        this.add
          .bitmapText(0, 0, 'cc_outline', 'You can come back to check your\nprogress any time during the mine.', this.applyScale(16), 1)
          .setTint(0xa3a3b9),
        {
          align: 'center',
        },
      )
      .addSpace()
      .add(
        this.rexUI.add
          .sizer({
            x: 0,
            y: 0,
            width: this.applyScale(width),
            space: {
              left: this.applyScale(29),
              right: this.applyScale(29),
              bottom: this.applyScale(29),
              top: this.applyScale(29),
            },
          })
          .add(cancelButton, { align: 'left' })
          .addSpace()
          .add(backToHomebaseButton, { align: 'right' })
          .layout(),
      )
      .layout();
  }

  private applyScale(length: number) {
    return length * this._scale;
  }

  private destroy() {
    this.scene.start('HomebaseScene');
    this.unloadAssets();
    this.scene.stop();
  }

  public unloadAssets() {
    UnloadImages(this, Object.keys(Mining_Status_Scene_Assets));
  }
}
