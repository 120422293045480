import Phaser, { Scene } from 'phaser';
import { Subscription } from 'rxjs';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import RoundImageButton from '~/components/buttons/RoundImageButton';
import CostLabel from '~/components/labels/costLabel';

const componentWidth = 250;
const componentHeight = 361;
const componentRadius = 16;
const componentColor = 0x262637;
const componentAlpha = 1;
const componentBorderWidth = 2;
const componentBorderColor = 0x382b42;
const componentBorderAlpha = 1;
const componentBorderOverColor = 0x544163;

export default class ShopMachineComponent extends OverlapSizer {
  private _machine;
  private _machineImage;
  private _scale;
  private _machineLabel;
  private _infoCircle;
  private _infoLetter;
  private _costLabel;
  private _countLabel;
  private _itemBorder;
  private _isButton;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, isButton?: boolean, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * componentWidth,
          height: paneScale * componentHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._machine = data;
    this._isButton = isButton === undefined ? true : isButton;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Return blank if no item
    if (!data.key) {
      // Item Border
      this._itemBorder = scene.add.rexRoundRectangle(
        0, // x
        0, // y
        scene.applyScale(componentWidth), // width
        scene.applyScale(componentHeight), // height
        scene.applyScale(componentRadius), // radius
        0x000000, // fillColor
        0, // alpha
      );

      this.add(this._itemBorder, {
        key: 'machine_border',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });

      return this.layout();
    }

    // Machine Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: componentRadius,
    };

    if (this._isButton) {
      this._machineImage = new RoundImageButton(scene, 0, 0, this._machine.shopImageKey, rectConfig, false).setScale(scene.getScale());
      scene.add.existing(this._machineImage);

      this._machineImage.onClick().subscribe(pointer => {
        if (pointer.getDistance() > 20) {
          return;
        }
        scene.scene.pause('ShopModalScene');
        scene.scene.launch('MachineInfoModalScene', { userMachine: this._machine });
      });

      if (this._machine.numberAvailable === 0) {
        this._machineImage.disableInteractive();
      }

      this.add(this._machineImage, {
        key: 'machine_image',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });
    } else {
      this._machineImage = scene.add.rexCircleMaskImage(0, 0, this._machine.shopImageKey, '', rectConfig).setScale(scene.getScale());

      this.add(this._machineImage, {
        key: 'machine_image',
        align: 'left-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      });
    }

    // Item Border
    this._itemBorder = scene.add
      .rexRoundRectangle(
        0, // x
        0, // y
        scene.applyScale(componentWidth), // width
        scene.applyScale(componentHeight), // height
        scene.applyScale(componentRadius), // radius
        0x000000, // fillColor
        0, // alpha
      )
      .setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderColor, componentBorderAlpha);

    if (this._isButton && this._machine.numberAvailable > 0) {
      this._itemBorder
        .setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
          this._itemBorder.setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderOverColor, componentBorderAlpha);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
          this._itemBorder.setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderColor, componentBorderAlpha);
        });
    }

    this.add(this._itemBorder, {
      key: 'machine_border',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    if (this._isButton) {
      // Machine Label
      this._machineLabel = scene.add
        .bitmapText(0, 0, 'cc_outline', this.getDisplayName(), this.applyScale(22), 0)
        .setOrigin(0, 0.5)
        .setMaxWidth(scene.applyScale(175));

      this.add(this._machineLabel, {
        key: `machine-label`,
        align: 'left-top',
        offsetX: scene.applyScale(21),
        offsetY: scene.applyScale(19),
        expand: false,
      });

      // Info Circle
      this._infoCircle = scene.add.image(0, 0, 'shop_item_info_button');

      this.add(this._infoCircle, {
        key: `info-circle`,
        align: 'right-top',
        offsetX: scene.applyScale(-18),
        offsetY: scene.applyScale(12),
        expand: false,
      });

      this._infoLetter = scene.add.image(0, 0, 'shop_item_info_letter');

      this.add(this._infoLetter, {
        key: `info-letter`,
        align: 'right-top',
        offsetX: scene.applyScale(-28),
        offsetY: scene.applyScale(19),
        expand: false,
      });
    }

    const currency = this._machine.cost_stardust > 0 ? 'stardust' : this._machine.cost_neon_gold > 0 ? 'neon_gold' : 'space_elixir';

    // Cost Label
    this._costLabel = new CostLabel(scene, x, y, scene.getScale(), currency, this._machine[`cost_${currency}`] || 'N/A', 146);
    scene.add.existing(this._costLabel);

    this.add(this._costLabel, {
      key: `cost_label`,
      align: 'left-bottom',
      offsetX: scene.applyScale(19),
      offsetY: scene.applyScale(-15),
      expand: false,
    });

    // Count Label
    this._countLabel = new CostLabel(scene, x, y, scene.getScale(), undefined, this.getCountText(), 61);
    scene.add.existing(this._countLabel);

    this.add(this._countLabel, {
      key: `count_label`,
      align: 'right-bottom',
      offsetX: scene.applyScale(-19),
      offsetY: scene.applyScale(-15),
      expand: false,
    });

    if (this._machine.numberAvailable === 0 && this._isButton) {
      this.setAlpha(0.5);
      this.disableInteractive();
    }
    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getDisplayName() {
    if (this._machine.display_name) {
      switch (this._machine.display_name) {
        case 'Space Elixir Mine':
          return 'Space Elixir\nMine';
        case 'Neon Gold Storage':
          return 'Neon Gold\nStorage';
        case 'Space Elixir Storage':
          return 'Space Elixir\nStorage';
        default:
          return this._machine.display_name;
      }
    }
    return this._machine.display_name;
  }

  private getCountText() {
    return `${this._machine.numberAvailable < 0 ? 0 : this._machine.numberAvailable}/${this._machine.maxNumber}`;
  }
}

Phaser.GameObjects.GameObjectFactory.register('shopMachineComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new ShopMachineComponent(this.scene, x, y, data));
});
