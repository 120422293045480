import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { BR_Server_Unavailable_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const subTextColor = 0xbfaac6;

const modalHeight = 325;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BattleRoyaleServerUnavailableScene extends Phaser.Scene {
  private cancelButton: Button;

  constructor() {
    super({
      key: 'BattleRoyaleServerUnavailableScene',
    });
  }

  preload() {
    LoadImageAssets(this, BR_Server_Unavailable_Scene_Assets);
  }

  create() {
    // Add Window
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      modalWidth, // width
      modalHeight, // height
      modalRadius, // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 120, 'cc_outline', 'Oops! Battle Royale server is down.', 26, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign();
    this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 80, 'cc_outline', '*Please notify the team in discord.', 20, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign()
      .setTint(subTextColor);

    // blob animation
    var animationConfig = {
      key: 'blob_animation',
      frames: this.anims.generateFrameNumbers('party_blob', {
        start: 0,
        end: 7,
        first: 7,
      }),
      frameRate: 15,
      repeat: -1,
    };

    this.anims.create(animationConfig);
    this.add
      .sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 40, 'party_blob')
      .setOrigin(0.5, 0.5)
      .play('blob_animation');

    // Buttons
    this.cancelButton = new Button(this, modalBg.getBottomCenter().x, modalBg.getBottomLeft().y, 'bossfight_victory_return_home_button', true, 1.05);
    this.cancelButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.stop('BattleRoyaleScene');
      this.scene.stop();
      this.scene.start('HomebaseScene');
    });
    this.add.existing(this.cancelButton);
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Server_Unavailable_Scene_Assets));
  }
}
