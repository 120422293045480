import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';
import PVPServer from '~/services/PVPServerService';
import PvpPlayerPane from './pvpPlayerPane';
import PvpButtonPane from './pvpButtonPane';
import PvpGamePane from './pvpGamePane';
import PVPState from '../../../../../API/PVP/src/game/PVPState';
import OpponentService from '~/services/OpponentService';
import WalletConnectService from '~/services/WalletConnectService';
import BotService from '~/services/BotService';
import { PVP_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { GameState } from '../../../../../API/PVP/src/types/IPVPState';

const modalHeight = 822;
const modalWidth = 1393;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

export default class PvPScene extends Phaser.Scene {
  private _confirmButton;
  private _playerContentPane;
  private _opponentContentPane;
  private _gamePane;
  private _buttonPane;
  private _scale: number;
  private _player;
  private _opponent;
  private _gameState;
  private _battle_id;

  constructor() {
    super({
      key: 'PvPScene',
    });
  }

  init(data: any) {
    this._battle_id = data.battle_id;
  }

  preload() {
    this._gameState = PVPServer.getInstance().getGameState();

    // Image Loading
    LoadImageAssets(this, PVP_Scene_Assets);

    if (this._gameState.isBotMatch) {
      BotService.getInstance()
        .getBotSync()
        .squadMembers.forEach(sm => {
          if (sm.image_key && sm.image_thumbnail_url && sm.image_thumbnail_url.length > 0) {
            this.load.image(sm.image_key, sm.image_thumbnail_url);
          }
        });
    } else {
      OpponentService.getInstance()
        .getOpponentSync()
        .squadMembers.forEach(sm => {
          if (sm.image_key && sm.image_thumbnail_url && sm.image_thumbnail_url.length > 0) {
            this.load.image(sm.image_key, sm.image_thumbnail_url);
          }
        });
    }
  }

  create() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Player Pane
    this._player = UserService.getInstance().getUserDataSync();
    this._player.squadMembers = SquadService.getInstance().getSquadSync();
    this._player.traits = SquadService.getInstance().getTraitsSync();
    this._playerContentPane = new PvpPlayerPane(this, 0, 0, this._player, this._scale);
    this.add.existing(this._playerContentPane);

    sizer.add(this._playerContentPane, {
      key: 'player-content-pane',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Game Pane
    this._gamePane = new PvpGamePane(this, 0, 0, { battleType: 'pvp' }, this._scale);
    this.add.existing(this._gamePane);

    sizer.add(this._gamePane, {
      key: 'game-content-pane',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Opponent Pane
    this._opponent = this._gameState.isBotMatch ? BotService.getInstance().getBotSync() : OpponentService.getInstance().getOpponentSync();
    this._opponent.traits = this._gameState.isBotMatch ? BotService.getInstance().getTraitsSync() : OpponentService.getInstance().getTraitsSync();
    this._opponent.health = 1000;

    this._opponentContentPane = new PvpPlayerPane(this, 0, 0, this._opponent, this._scale);
    this.add.existing(this._opponentContentPane);

    sizer.add(this._opponentContentPane, {
      key: 'opponent-content-pane',
      align: 'right-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Button Pane
    this._buttonPane = new PvpButtonPane(this, 0, 0, { serverType: 'pvp' }, this._scale);
    this.add.existing(this._buttonPane);

    sizer.add(this._buttonPane, {
      key: 'button-content-pane',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Layout the Scene
    sizer.layout();

    // PvP Server Interaction
    PVPServer.getInstance().onRoundComplete(this.handleRoundComplete, this);
    PVPServer.getInstance().onGameOver(this.handleGameOver, this);
    PVPServer.getInstance().onTimerCountdown(this.handleTimer, this);
    PVPServer.getInstance().onActionSubmitted(this.handleActionSubmitted, this);

    this.scene.get('PvpSurrenderScene').events.on('surrender_cancel_event', () => {
      var state = PVPServer.getInstance().getGameState();
      if (state) {
        var user = state.players.find(player => {
          return player.wallet === WalletConnectService.getInstance().getWalletAddress();
        });
        this._buttonPane.updateEnabled(user);
      } else {
        console.log('Failed to get game state');
      }
    });
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private handleActionSubmitted(result: any) {
    const gameState = PVPServer.getInstance().getGameState();
    var actionStatus = result.isActionValid ? 'Action submitted' : 'Action invalid';

    if (result.isActionValid) {
      if (result.submitterWallet === WalletConnectService.getInstance().getWalletAddress()) {
        // disable buttons as action is valid
        this._buttonPane.disableButtons();

        this._gamePane.updateExistingRound({
          roundNumber: gameState?.round + 1,
          playerAction: actionStatus,
          opponentAction: 'Awaiting action...',
        });
      } else {
        // opponent
        this._gamePane.updateExistingRound({
          roundNumber: gameState?.round + 1,
          playerAction: 'Awaiting action...',
          opponentAction: actionStatus,
        });
      }
    }
  }

  private handleRoundComplete() {
    this._gameState = PVPServer.getInstance().getGameState();
    this.updateUIState(this._gameState);
  }

  private updateUIState(state: PVPState) {
    var user;
    var opponent;

    state.players.forEach(player => {
      if (player.wallet === WalletConnectService.getInstance().getWalletAddress()) {
        user = player;
      } else {
        opponent = player;
      }
    });

    // Update Player and Opponent Panes
    this._playerContentPane.updateProgressBars(user.health, user.actionPoints);
    this._opponentContentPane.updateProgressBars(opponent.health, opponent.actionPoints);

    // Update Button Pane
    this._buttonPane.updateEnabled(user);

    // updates current round data
    this._gamePane.updateExistingRound({
      roundNumber: state.round,
      playerAction: user.roundAction,
      opponentAction: opponent.roundAction,
    });

    // create next round
    this._gamePane.addRound(state.round + 1);

    // if player is stunned auto submit their stunned action.
    //if (user.stunned) {PVPServer.getInstance().playAction('pvpstunned')}
  }

  private handleGameOver(result: any) {
    PVPServer.getInstance().leave();

    if (result.state == GameState.IdleTie) {
      this.scene.start('PvpGameOverScene', { isWinner: false });
      this.unloadAssets();
      this.scene.stop();
      return;
    }

    if (result.state == GameState.Tie) {
      this.scene.start('PvpRewardsScene', {
        rewards: {
          isDraw: true,
          neon_gold: 250,
          space_elixir: 250,
          stardust: 5,
        },
      });
      this.unloadAssets();
      this.scene.stop();
    } else if (result.winningWallet === WalletConnectService.getInstance().getWalletAddress()) {
      this.scene.start('PvpRewardsScene', {
        rewards: {
          isDraw: false,
          neon_gold: result.neonGoldBoost,
          space_elixir: result.spaceElixirBoost,
          stardust: result.starDust,
        },
      });
      this.unloadAssets();
      this.scene.stop();
    } else {
      this.scene.start('PvpGameOverScene', { isWinner: false });
      this.unloadAssets();
      this.scene.stop();
    }
  }

  private handleTimer(countdown: number) {
    // This method can contain the round countdown timer
    // countdown is received from the game server and when it hits 0 the player is idle
  }

  destroy() {}

  public unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Scene_Assets));

    // Unload Opponent PFPs
    if (this._gameState.isBotMatch) {
      const botImageKeys: string[] = BotService.getInstance()
        .getBotSync()
        .squadMembers.map(sm => sm.image_key);
      UnloadImages(this, botImageKeys);
    } else {
      const opponentImageKeys: string[] = OpponentService.getInstance()
        .getOpponentSync()
        .squadMembers.map(sm => sm.image_key);
      UnloadImages(this, opponentImageKeys);
    }
  }
}
