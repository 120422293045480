import Phaser, { Scene } from 'phaser';
import { Subscription, unsubscribe } from 'rxjs';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import Button from '~/components/buttons/Button';
import PVPServer from '~/services/PVPServerService';
import PVPState from '~/../../API/src/games/pvp/PVPState';
import PVPPlayer from '~/../../API/src/types/pvp/PVPPlayer';
import PVEServer from '~/services/PVEServerService';

const paneWidth = 685;
const paneHeight = 108;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

const heavyOffset = 29;
const lightOffset = 153;
const defendOffset = 269;
const restoreOffset = 367;
const healOffset = 471;
const surrenderOffset = 550;

const buttonMargin = 13;
const outerMargin = 40;

export default class PvpButtonPane extends OverlapSizer {
  private _scale;
  private _heavyAttackButton;
  private _lightAttackButton;
  private _defendButton;
  private _restoreButton;
  private _healButton;
  private _surrenderButton;
  private _serverType;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._serverType = data.serverType;
    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(paneWidth), // width
      this.applyScale(paneHeight), // height
      this.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.addBackground(background);

    // Heavy Attack Button
    this._heavyAttackButton = new Button(scene, 0, 0, 'pvp_heavy_attack_button', false).setScale(this._scale);
    this._heavyAttackButton.setDisabledTexture('pvp_heavy_attack_button_disabled');

    this._heavyAttackButton.onClick().subscribe(pointer => {
      this.handleAction('pvpheavy');
    });

    this.scene.add.existing(this._heavyAttackButton);

    this.add(this._heavyAttackButton, {
      key: 'heavy-attack-button',
      align: 'left-center',
      offsetX: this.applyScale(heavyOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Light Attack Button
    this._lightAttackButton = new Button(scene, 0, 0, 'pvp_light_attack_button', false).setScale(this._scale);
    this._lightAttackButton.setDisabledTexture('pvp_light_attack_button_disabled');

    this._lightAttackButton.onClick().subscribe(pointer => {
      this.handleAction('pvplight');
    });

    this.scene.add.existing(this._lightAttackButton);

    this.add(this._lightAttackButton, {
      key: 'light-attack-button',
      align: 'left-center',
      offsetX: this.applyScale(lightOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Defend Button
    this._defendButton = new Button(scene, 0, 0, 'pvp_defend_button', false).setScale(this._scale);
    this._defendButton.setDisabledTexture('pvp_defend_button_disabled');

    this._defendButton.onClick().subscribe(pointer => {
      this.handleAction('pvpdefend');
    });

    this.scene.add.existing(this._defendButton);

    this.add(this._defendButton, {
      key: 'defend-button',
      align: 'left-center',
      offsetX: this.applyScale(defendOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Restore Button
    this._restoreButton = new Button(scene, 0, 0, 'pvp_restore_button', false).setScale(this._scale);
    this._restoreButton.setDisabledTexture('pvp_restore_button_disabled');

    this._restoreButton.onClick().subscribe(pointer => {
      this.handleAction('pvpheal');
    });

    this.scene.add.existing(this._restoreButton);

    this.add(this._restoreButton, {
      key: 'restore-button',
      align: 'left-center',
      offsetX: this.applyScale(restoreOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Heal Button
    this._healButton = new Button(scene, 0, 0, 'pvp_heal_button', false).setScale(this._scale);
    this._healButton.setDisabledTexture('pvp_heal_button_disabled');
    // disable heal button to start game as player is already at max health
    this._healButton.setDisabled(true);

    this._healButton.onClick().subscribe(pointer => {
      this.handleAction('pvpuberheal');
    });

    this.scene.add.existing(this._healButton);

    this.add(this._healButton, {
      key: 'heal-button',
      align: 'left-center',
      offsetX: this.applyScale(healOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Surrender Button
    this._surrenderButton = new Button(scene, 0, 0, 'pvp_surrender_button', false).setScale(this._scale);
    this._surrenderButton.setDisabledTexture('pvp_surrender_button_disabled');

    this._surrenderButton.onClick().subscribe(pointer => {
      this.handleAction('pvp69');
    });

    this.scene.add.existing(this._surrenderButton);

    this.add(this._surrenderButton, {
      key: 'surrender-button',
      align: 'left-center',
      offsetX: this.applyScale(surrenderOffset),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private handleAction(action: string) {
    this.disableButtons();
    if (action === 'pvp69') {
      // 'pvp69' === 'surrender' ???
      this.scene.scene.launch('PvpSurrenderScene', {
        serverType: this._serverType,
      });
    } else {
      if (this._serverType === 'pvp') {
        PVPServer.getInstance().playAction(action);
      } else {
        PVEServer.getInstance().playAction(action);
      }
    }
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public disableButtons() {
    this._heavyAttackButton.setDisabled(true);
    this._lightAttackButton.setDisabled(true);
    this._defendButton.setDisabled(true);
    this._restoreButton.setDisabled(true);
    this._healButton.setDisabled(true);
    this._surrenderButton.setDisabled(true);
  }

  public updateEnabled(player: PVPPlayer) {
    // Round complete. Enable and Disable based on players action points
    this._heavyAttackButton.setDisabled(this.getButtonDisabled(player, 'heavy_attack'));
    this._lightAttackButton.setDisabled(this.getButtonDisabled(player, 'light_attack'));
    this._defendButton.setDisabled(this.getButtonDisabled(player, 'defend'));
    this._restoreButton.setDisabled(this.getButtonDisabled(player, 'restore'));
    this._healButton.setDisabled(this.getButtonDisabled(player, 'heal'));
    this._surrenderButton.setDisabled(this.getButtonDisabled(player, 'surrender'));
  }

  private getButtonDisabled(player: PVPPlayer, action_name: string) {
    // Individual button disabled logic
    switch (action_name) {
      case 'heavy_attack':
        return player.actionPoints < 50 ? true : false;
      case 'light_attack':
        return player.actionPoints < 20 ? true : false;
      case 'defend':
        return player.actionPoints < 20 ? true : false;
      case 'restore':
        return false;
      case 'heal':
        if (player.health === player.maxHealth) {
          return true;
        }
        return player.actionPoints < 50 ? true : false;
      case 'surrender':
        return false;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('pvpButtonPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new PvpButtonPane(this.scene, x, y));
});
