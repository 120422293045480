import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';
import TimerProgressBarComponent from '~/components/missions/timerProgressBarComponent';
import { getNextBattleRoyaleCountdown } from '~/utils/TimeUtils';

// Pane Details
const paneHeight = 290;
const paneWidth = 259;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

export default class BattleRoyaleDetailsPane extends OverlapSizer {
  private _scale: number;
  private _scene;

  // UI Elements
  private _background;
  private _battleInfoPanel;
  private _title;
  private _countDownProgressBar;
  private _gameText;
  private _readyUpButton;
  private _intervalTimer;
  private _battleData;
  private _player;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._battleData = data;
    this._player = data.player;

    this._scale = paneScale;
    this._scene = scene;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    this._background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(this._background, {
      key: 'background',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Info Panel
    this._battleInfoPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Attacking Status
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Round 1' + ' Launches in:', scene.applyScale(18), 0);

    this._battleInfoPanel.add(this._title, {
      key: `details-title`,
      align: 'left-top',
      offsetX: scene.applyScale(27),
      offsetY: scene.applyScale(29),
      expand: false,
    });

    // Timer Progress Bar
    var countdown = getNextBattleRoyaleCountdown();
    this._countDownProgressBar = new TimerProgressBarComponent(scene, 0, 0, this._scale, countdown);

    if (!this._battleData.isParticipant) {
      this.startCountDownUI(countdown);
    }

    this._battleInfoPanel.add(this._countDownProgressBar, {
      key: 'progress_bar',
      align: 'left-top',
      offsetX: this.applyScale(27),
      offsetY: this.applyScale(66),
      expand: false,
    });

    // Game Text
    this._gameText = scene.add
      .bitmapText(0, 0, 'cc_outline', 'Fight it out against other squads in a no holds barred RNG tournament.', scene.applyScale(18), 0)
      .setMaxWidth(scene.applyScale(172));

    this._battleInfoPanel.add(this._gameText, {
      key: `game-text`,
      align: 'left-top',
      offsetX: scene.applyScale(27),
      offsetY: scene.applyScale(121),
      expand: false,
    });

    // Ready Up Button
    this._readyUpButton = new Button(scene, 0, 0, 'battleroyale_ready_up_button', false).setScale(this._scale);

    this.handleReadyButtonState();

    this._readyUpButton.onClick().subscribe(pointer => {
      this.stopTimer();
      scene.scene.pause('BattleRoyaleScene');
      scene.scene.launch('BattleRoyaleEntryScene');
    });

    this._battleInfoPanel.add(scene.add.existing(this._readyUpButton), {
      key: 'ready-up-button',
      align: 'left-bottom',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(32),
      expand: false,
    });

    this.add(this._battleInfoPanel, {
      key: 'info-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  public updateButton(joined: boolean) {
    this._readyUpButton.setDisabled(joined);
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private startCountDownUI(countDown: number) {
    // Implements Countdown
    var remainingSeconds = countDown;

    // Resets Countdown Bar Values
    this._countDownProgressBar.roundTimerReset(this, remainingSeconds);

    this._intervalTimer = setInterval(() => {
      try {
        if (remainingSeconds <= 0) {
          this._countDownProgressBar.setBattleRoyaleLive();

          // Player has joined as Viewer after initial countdown
          if (this._scene.handleCountdownFinished()) {
            this._readyUpButton.setDisabledTexture('bossfight_sit_out_button');
            this._readyUpButton.setDisabled(true);
          }

          return clearInterval(this._intervalTimer);
        }
        remainingSeconds--;
        this._countDownProgressBar.updateDisplay(this, remainingSeconds);
      } catch (error) {
        return clearInterval(this._intervalTimer);
      }
    }, 1000);
  }

  public updateDetailPane(battleData: any) {
    this._battleData = battleData;

    if (this._battleData.gameState === 0) {
      this.startCountDownUI(getNextBattleRoyaleCountdown());
    }

    if (this._battleData.gameState === 1) {
      this._title.setText('Round ' + this._battleData.round + ' is:');
      this._countDownProgressBar.setBattleRoyaleLive();
    }

    // Update Countdown UI
    if (this._battleData.gameState === 2) {
      // 2 = GameState.BRIntermission
      this._title.setText('Round ' + this._battleData.round + ' will start in: ');
      this.startCountDownUI(battleData.countDown);
    }

    if (this._battleData.gameState === 4) {
      // GameState.GameOver
      this._title.setText('Battle Complete. Displaying Results');
      this.startCountDownUI(battleData.countDown);
    }
  }

  handleReadyButtonState() {
    this._readyUpButton.setDisabledTexture('battleroyale_you_are_ready_button');

    if (this._battleData.hasGameStarted && this._player == undefined) {
      this._readyUpButton.setDisabledTexture('bossfight_sit_out_button');
      this._readyUpButton.setDisabled(true);
    }

    if (this._battleData.hasGameStarted && this._player !== undefined && this._player.isViewer) {
      // update this .joined to isParticipant
      this._readyUpButton.setDisabledTexture('bossfight_sit_out_button');
      this._readyUpButton.setDisabled(true);
    }

    if (this._player !== undefined && this._player.isParticipant) {
      this._readyUpButton.setDisabled(true);
    }
  }

  public updateReadyButtonViewer() {
    this._readyUpButton.setDisabledTexture('bossfight_sit_out_button');
    this._readyUpButton.setDisabled(true);
  }

  public stopTimer() {
    clearInterval(this._intervalTimer);
  }
}

Phaser.GameObjects.GameObjectFactory.register('battleRoyaleDetailPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattleRoyaleDetailsPane(this.scene, x, y));
});
