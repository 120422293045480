import config from '../../config/config.js';

export default class ConfigurationService {

    private static _instance:ConfigurationService = new ConfigurationService();

    private _configuration: any = [];

    constructor() {
        if(ConfigurationService._instance){
            throw new Error("Error: Instantiation failed: Use ConfigurationService.getInstance() instead of new.");
        }
        ConfigurationService._instance = this;
        this._configuration = config.config;
    }

    public static getInstance():ConfigurationService
    {
        return ConfigurationService._instance;
    }

    private setConfiguration(value):void
    {
        this._configuration = value;
    }

    public getConfig()
    {
        return this._configuration;
    }
}