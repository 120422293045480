import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import BattleRoyaleService from '~/services/BattleRoyaleServerService';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';
import { BR_Entry_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 455;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 90;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const spaceElixirFee = 800;
const neonGoldFee = 500;
const stardustFee = 3;

export default class BattleRoyaleEntryScene extends Phaser.Scene {
  private _scale;
  private _userData;

  constructor() {
    super({
      key: 'BattleRoyaleEntryScene',
    });
  }

  preload() {
    // Images
    LoadImageAssets(this, BR_Entry_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);
    this._userData = UserService.getInstance().getUserDataSync();
    this._userData.squadMembers = SquadService.getInstance().getSquadSync();

    this.createComponent();
  }

  createComponent() {
    // Add Background
    // const modalBg = this.add.rexRoundRectangle(
    //     this.sys.canvas.width / 2, // x
    //     this.sys.canvas.height / 2, // y
    //     this.applyScale(modalWidth), // width
    //     this.applyScale(modalHeight), // height
    //     this.applyScale(modalRadius), // radius
    //     modalColor, // fillColor
    //     modalAlpha, // alpha
    // );
    // modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.sys.game.canvas.width * 2, // width
      this.sys.game.canvas.height * 2, // height
      0, // radius
      0x000000, // fillColor
      0.73, // alpha
    );

    this.add.existing(background);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Add Icons
    const spaceElixirImage = this.add.image(0, 0, 'battleroyale_space_elixir_icon').setScale(this._scale);

    sizer.add(spaceElixirImage, {
      key: 'spaceelixir_image',
      align: 'left-top',
      offsetX: this.applyScale(156),
      offsetY: this.applyScale(-28),
      expand: false,
    });

    const neonGoldImage = this.add.image(0, 0, 'battleroyale_neon_gold_icon').setScale(this._scale);

    sizer.add(neonGoldImage, {
      key: 'neongold_image',
      align: 'left-top',
      offsetX: this.applyScale(215),
      offsetY: this.applyScale(-28),
      expand: false,
    });

    const starDustImage = this.add.image(0, 0, 'battleroyale_stardust_icon').setScale(this._scale);

    sizer.add(starDustImage, {
      key: 'stardust_image',
      align: 'left-top',
      offsetX: this.applyScale(281),
      offsetY: this.applyScale(-28),
      expand: false,
    });

    // Add Title Text
    const title = this.add.bitmapText(0, 0, 'cc_outline', 'Cost to enter:', this.applyScale(26), 1);

    sizer.add(title, {
      key: 'title',
      align: 'left-top',
      offsetX: this.applyScale(161),
      offsetY: this.applyScale(66),
      expand: false,
    });

    // Add Note
    const note = this.add
      .bitmapText(0, 0, 'cc_outline', 'Note: Rewards for winning\nwill always be significantly\nhigher than costs to enter.', this.applyScale(20), 1)
      .setAlpha(0.6);

    sizer.add(note, {
      key: 'note',
      align: 'left-top',
      offsetX: this.applyScale(126),
      offsetY: this.applyScale(319),
      expand: false,
    });

    // Add Fee Labels
    const spaceElixirRewardLabel = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', spaceElixirFee.toLocaleString('en-us'));

    sizer.add(spaceElixirRewardLabel, {
      key: 'spaceelixir_label',
      align: 'left-top',
      offsetX: this.applyScale(140),
      offsetY: this.applyScale(119),
      expand: false,
    });

    const neonGoldRewardLabel = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', neonGoldFee.toLocaleString('en-us'));

    sizer.add(neonGoldRewardLabel, {
      key: 'neongold_label',
      align: 'left-top',
      offsetX: this.applyScale(140),
      offsetY: this.applyScale(180),
      expand: false,
    });

    const stardustRewardLabel = new RewardLabel(this, 0, 0, this._scale, 'stardust', stardustFee.toLocaleString('en-us'));

    sizer.add(stardustRewardLabel, {
      key: 'stardust_label',
      align: 'left-top',
      offsetX: this.applyScale(140),
      offsetY: this.applyScale(241),
      expand: false,
    });

    // Add Cancel Button
    const cancelButton = new Button(this, 0, 0, 'pvp_cancel_button', true, 1.05).setScale(this._scale);

    cancelButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.resume('BattleRoyaleScene', { joined: false });
      this.scene.stop();
    });

    this.add.existing(cancelButton);

    sizer.add(cancelButton, {
      key: 'cancel-button',
      align: 'left-top',
      offsetX: this.applyScale(89),
      offsetY: this.applyScale(424),
      expand: false,
    });

    // Add Confirm Button
    const confirmButton = new Button(this, 0, 0, 'pvp_confirm_button', true, 1.05).setScale(this._scale);

    confirmButton.onClick().subscribe(pointer => {
      BattleRoyaleService.getInstance().join(this._userData, true);
      this.unloadAssets();
      this.scene.resume('BattleRoyaleScene', { joined: true });
      this.scene.stop();
    });

    this.add.existing(confirmButton);

    sizer.add(confirmButton, {
      key: 'confirm-button',
      align: 'left-top',
      offsetX: this.applyScale(238),
      offsetY: this.applyScale(424),
      expand: false,
    });

    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Entry_Scene_Assets));
  }
}
