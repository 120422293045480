import { OverlapSizer } from "phaser3-rex-plugins/templates/ui/ui-components";
import { Asteroid } from "~/services/MiningService";

import AsteroidComponent from "./asteroidComponent";
import MiningModalScene from "./MiningModalScene";

const modalWidth = 1008;

export default class AsteroidContentPane extends OverlapSizer {
    private _scale: number;

    constructor(
        scene: MiningModalScene,
        x: number,
        y: number,
        asteroids: Asteroid[],
        scale: number
    ) {
        const config = {
            width: scale * modalWidth,
            align: 0,
            sizerEvents: true
        };
        super(scene, x, y, config);

        this._scale = scale;

        if (asteroids && asteroids.length > 0) {
            this.addTable(scene, asteroids);
        }

        this.layout();
    }

    private addTable(scene: MiningModalScene, asteroids: Asteroid[]) {
        const table = this.createTable(scene, asteroids).layout();

        this.add(table, {
            key: "asteroid_table",
            align: "left-top",
            offsetX: 0,
            offsetY: 0,
            expand: false
        });
    }

    private createTable(scene: MiningModalScene, asteroids: Asteroid[]) {
        // @ts-ignore
        const table = scene.rexUI.add
            .gridTable({
                width: this.applyScale(
                    273 * asteroids.length > modalWidth - 8
                        ? modalWidth - 8
                        : 273 * asteroids.length - 1
                ),
                height: this.applyScale(413),
                items: asteroids,
                scrollMode: 1,
                table: {
                    cellWidth: this.applyScale(273),
                    cellHeight: this.applyScale(413),
                    columns: 1,
                    clamplTableOXY: false,
                    mask: {
                        padding: 2
                    }
                },
                mouseWheelScroller: {
                    focus: true,
                    speed: 0.4
                },
                space: {
                    table: {
                        left: this.applyScale(23),
                        right: 0
                    }
                },
                createCellContainerCallback: function (cell) {
                    const cellScene: MiningModalScene = cell.scene;
                    const cellItem: Asteroid = cell.item;
                    const cellScale = cellScene.getScale();

                    const asteroidComponent = new AsteroidComponent(
                        cellScene,
                        0,
                        0,
                        cellItem,
                        cellScale
                    );

                    cellScene.add.existing(asteroidComponent);

                    return asteroidComponent;
                }
            })
            .setOrigin(0, 0.5);

        table.setItems(asteroids).scrollToTop();

        return table;
    }

    applyScale(length: number) {
        return length * this._scale;
    }
}

Phaser.GameObjects.GameObjectFactory.register(
    "asteroidContentPane",
    function (x: number, y: number) {
        // @ts-ignore
        return this.displayList.add(new AsteroidContentPane(this.scene, x, y));
    }
);
