import Phaser from "phaser"
import { Subject, Observable } from "rxjs"
import CircleMaskImage from "phaser3-rex-plugins/plugins/circlemaskimage.js"

const defaultOverScale = 1.01
const useOverScaleDefault = true
const clickAlpha = 0.9
const tintColor = 0xcdcdcd

declare global {
    interface IRoundImageButton
        extends Phaser.GameObjects.GameObject,
            Phaser.GameObjects.Components.Transform {
        onClick(): Observable<Phaser.Input.Pointer>

        setUpTexture(texture: string): this
        setDownTexture(texture: string): this
        setOverTexture(texture: string): this
        setDisabledTexture(texture: string): this
        setOverScale(scale: number): this
        setUseOverScaling(useOverGrowth: boolean): this
        getWidth(): number
        getHeight(): number
        getTopLeft()
        getTopRight()
        getBottomLeft()
        getBottomRight()
        displayHeight
        displayWidth
        setDisabled(disabled: boolean): this
    }
}

export default class RoundImageButton extends CircleMaskImage {
    private upTexture: string
    private downTexture: string
    private overTexture: string
    private disabledTexture: string
    private overScale: number
    private useOverScaling: boolean
    private clickSubject: Subject<Phaser.Input.Pointer> = new Subject()

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        texture: string,
        config: any,
        useOverScaling?: boolean,
        overScale?: number,
    ) {
        super(scene, x, y, texture, "", config)

        this.upTexture = texture
        this.downTexture = texture
        this.overTexture = texture
        this.disabledTexture = texture
        this.useOverScaling =
            useOverScaling === undefined || useOverScaling === null
                ? useOverScaleDefault
                : useOverScaling
        this.overScale =
            overScale === undefined || overScale === null
                ? defaultOverScale
                : overScale

        if (config?.allowClicks !== false) {
            this.setInteractive({ cursor: "pointer" })
                .on(
                    Phaser.Input.Events.GAMEOBJECT_POINTER_UP,
                    this.handleUp,
                    this,
                )
                .on(
                    Phaser.Input.Events.GAMEOBJECT_POINTER_OUT,
                    this.handleOut,
                    this,
                )
                .on(
                    Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN,
                    this.handleDown,
                    this,
                )
                .on(
                    Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
                    this.handleOver,
                    this,
                )
        }
    }

    destroy(fromScene: boolean = false) {
        this.clickSubject.complete()

        super.destroy(fromScene)
    }

    onClick() {
        return this.clickSubject.asObservable()
    }

    getWidth() {
        return this.displayWidth
    }

    getHeight() {
        return this.displayHeight
    }

    setUpTexture(texture: string) {
        this.upTexture = texture
        return this
    }

    setDownTexture(texture: string) {
        this.downTexture = texture
        return this
    }

    setOverTexture(texture: string) {
        this.overTexture = texture
        return this
    }

    setDisabledTexture(texture: string) {
        this.disabledTexture = texture
        return this
    }

    setDisabled(disabled: boolean) {
        if (disabled) {
            this.setTexture(this.disabledTexture)
            this.disableInteractive()
            return this
        }

        this.setTexture(this.upTexture)
        this.setInteractive()

        return this
    }

    setOverScale(scale: number) {
        this.overScale = scale
        return this
    }

    setUseOverScaling(useOverGrowth: boolean) {
        this.useOverScaling = useOverGrowth
        return this
    }

    private handleUp(pointer: Phaser.Input.Pointer) {
        this.handleOver(pointer)
        this.clickSubject.next(pointer)
    }

    private handleOut(pointer: Phaser.Input.Pointer) {
        this.handleDown(pointer)
    }

    private handleDown(pointer: Phaser.Input.Pointer) {
        if (this.useOverScaling) {
            this.setScale(this.scaleX / this.overScale)
        } else {
            this.setAlpha(1)
            this.clearTint()
        }
    }

    private handleOver(pointer: Phaser.Input.Pointer) {
        if (this.useOverScaling) {
            this.setScale(this.scaleX * this.overScale)
        } else {
            this.setTint(tintColor)
        }
    }
}

Phaser.GameObjects.GameObjectFactory.register(
    "roundImageButton",
    function (
        x: number,
        y: number,
        texture: any,
        config: any,
        useOverScaling?: boolean,
        overScale?: number,
    ) {
        // @ts-ignore
        return this.displayList.add(
            new RoundImageButton(
                this.scene,
                x,
                y,
                texture,
                config,
                useOverScaling,
                overScale,
            ),
        )
    },
)
