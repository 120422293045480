import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import ShopService from '~/services/ShopService';
import ShopItemComponent from './shopItemComponent';

const modalWidth = 1008;
const shopItemHeight = 361;
const shopItemWidth = 250;

const cellHeight = shopItemHeight;
const cellWidth = shopItemWidth + 20;

const tabs = [
  {
    key: 'all',
    image_key: 'shop_all_button',
  },
  {
    key: 'special_items',
    image_key: 'shop_special_items_button',
  },
  {
    key: 'nfts',
    image_key: 'shop_nfts_button',
  },
];

export default class ItemShopContentPane extends OverlapSizer {
  private _itemsData: any;
  private _rewardBoxData: any;
  private _refillResourceData: any;
  private _table: any;
  private _scale: number;
  private _filteredData: any;
  private _selectedFilter: any;
  private _buttonSizer: any;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: modalWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;

    // Basic Item Data
    this._itemsData = ShopService.getInstance().getShopItemsSync();

    // Reward Box Data
    this._rewardBoxData = ShopService.getInstance()
      .getRewardBoxesSync()
      .map(box => {
        //box.type = 'other';
        switch (box.key) {
          case 'common_resource_box':
            box.name = 'Common Daily Resource Box';
            break;
          case 'epic_resource_box':
            box.name = 'Epic Daily Resource Box';
            break;
          case 'legendary_resource_box':
            box.name = 'Legendary Daily Resource Box';
            break;
        }
        return box;
      });
    this._itemsData = this._itemsData.concat(this._rewardBoxData);

    // Buttons Sizer
    this._buttonSizer = this.scene.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(modalWidth - 70),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Buttons
    tabs.forEach(item => {
      const tabButton = new Button(this.scene, 0, 0, item.key === 'all' ? item.image_key : `${item.image_key}_inactive`, false).setScale(this._scale);

      tabButton.onClick().subscribe(pointer => {
        this.handleFilterClick(item.key);
      });

      this._buttonSizer.add(this.scene.add.existing(tabButton), {
        padding: { left: 0, right: this.applyScale(15), top: 0, bottom: 0 },
        key: `item_shop_${item.key}_button`,
      });
    });

    this.add(this._buttonSizer, {
      key: 'button_sizer',
      align: 'left-top',
      offsetX: this.applyScale(35),
      offsetY: this.applyScale(35),
      expand: false,
    });

    // Table
    this.addTable(scene, this._itemsData);

    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  public hidePane() {
    this._buttonSizer.setAlpha(0);
  }

  private addTable(scene, data) {
    if (data) {
      // Table
      this._table = this.createTable(scene, data, 0, 0).layout();

      this.add(this._table, {
        key: 'item_shop_table',
        align: 'left-top',
        offsetX: this.applyScale(4), // accounts for modal border
        offsetY: this.applyScale(110),
        expand: false,
      });
    }
  }

  handleFilterClick(selectedFilter: any) {
    if (selectedFilter === this._selectedFilter) {
      return;
    }

    this.updateFilterButtons(selectedFilter);

    if (selectedFilter === 'all') {
      this._filteredData = this._itemsData;
    } else {
      this._filteredData = this._itemsData.filter(item => {
        return item.category === selectedFilter;
      });
    }

    this.remove(this._table, true);

    if (this._filteredData.length > 0) {
      this.addTable(this.scene, this._filteredData);
      this.layout();
    }

    this._selectedFilter = selectedFilter;
  }

  updateFilterButtons(selectedFilter: any) {
    tabs.forEach(tab => {
      var button = this._buttonSizer.getElement(`item_shop_${tab.key}_button`);
      if (button) {
        button.setTexture(selectedFilter === tab.key ? tab.image_key : `${tab.image_key}_inactive`);
      }
    });
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(cellWidth * data.length > modalWidth ? modalWidth - 8 : cellWidth * data.length - 1),
        height: this.applyScale(cellHeight),
        items: data,
        scrollMode: 1,

        table: {
          cellWidth: this.applyScale(cellWidth),
          cellHeight: this.applyScale(cellHeight),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.4,
        },

        space: {
          table: {
            left: this.applyScale(23),
            right: 0,
          },
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            width = cell.width,
            height = cell.height,
            item = cell.item,
            index = cell.index;

          var imageData = new Phaser.GameObjects.Image(scene, 0, 0, item.image_key);
          var imageWidth = imageData.width;

          var shopItemComponent = new ShopItemComponent(scene, 0, 0, item, scene.getScale(), true, undefined);
          scene.add.existing(shopItemComponent);

          return shopItemComponent;
        },
      })
      .setOrigin(0, 0.5);

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }
}

Phaser.GameObjects.GameObjectFactory.register('itemShopContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ItemShopContentPane(this.scene, x, y));
});
