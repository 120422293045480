import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { PVP_Game_Over_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 360;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const victoryMessage = 'The spoils of your\nvictory are awaiting\nyou at the Homebase';
const defeatMessage = 'You were not\nsuccessful this time,\ndear wolf, but there\nwill be many more\nopportunities ahead...';
const messageColor = 0x75758b;

export default class PvpGameOverScene extends Phaser.Scene {
  private _isWinner;
  private _scale;

  init(data) {
    this._isWinner = data.isWinner;
  }

  constructor() {
    super({
      key: 'PvpGameOverScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 300); // 220 accounts for icons and button extending beyond modal box
  }

  preload() {
    LoadImageAssets(this, PVP_Game_Over_Scene_Assets);
  }

  create() {
    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.setScale(modalWidth), // width
      this.setScale(modalHeight), // height
      this.setScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.setScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Icon
    this.add
      .image(this.sys.canvas.width / 2, modalBg.getTopLeft().y - this.setScale(80), this._isWinner ? 'pvp_victory_icon' : 'pvp_defeat_icon')
      .setScale(this._scale);

    // Add Icon Text
    this.add
      .image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, this._isWinner ? 'pvp_victory_icon_label' : 'pvp_defeat_icon_label')
      .setScale(this._scale);

    // Add Text
    const text1 = this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 - this.setScale(10),
        'cc_outline',
        this._isWinner ? victoryMessage : defeatMessage,
        this.setScale(24),
        1,
      )
      .setOrigin(0.5, 0.5)
      .setTint(messageColor);

    // Add Return Home Button
    const returnHomeButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, 'pvp_return_home_button', true, 1.05).setScale(
      this._scale,
    );
    returnHomeButton.onClick().subscribe(pointer => {
      UserService.getInstance()
        .getUserData(true)
        .then(res => {
          this.unloadAssets();
          this.scene.start('HomebaseScene');
          this.scene.launch('HudScene');
          this.scene.stop();
        });
    });
    this.add.existing(returnHomeButton);
  }

  private setScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Game_Over_Scene_Assets));
  }
}
