import Phaser from 'phaser';
import BattleRoyaleServerService from '~/services/BattleRoyaleServerService';
import ScaleService from '~/services/ScaleService';
import WalletConnectService from '~/services/WalletConnectService';
import BattleRoyaleResultsPlayerPane from './battleRoyaleResultsPlayerPane';
import BattleRoyaleResultsPayoutPane from './battleRoyaleResultsPayoutPane';
import BattleRoyaleResultsLeadersPane from './battleRoyaleResultsLeadersPane';
import { BR_Results_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 600;
const modalWidth = 1000;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BattleRoyaleResultsScene extends Phaser.Scene {
  private _scale;
  private _participants;
  private _player;

  // UI Elements
  private _playerResultsPane;
  private _playerPayoutPane;
  private _leadersPane;

  init(data: any) {
    this._participants = data.participants;
  }

  constructor() {
    super({
      key: 'BattleRoyaleResultsScene',
    });
  }

  preload() {
    // Loads PFPs
    this._participants.forEach(participant => {
      this.load.image(participant.playerImageKey, participant.playerImageUrl);
    });

    LoadImageAssets(this, BR_Results_Scene_Assets);
  }

  create() {
    // Gathers Player Data to be displayed
    this._player = BattleRoyaleServerService.getInstance().getPlayerFromParticipants(
      this._participants,
      WalletConnectService.getInstance().getWalletAddress(),
    );

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Player Details Pane
    this._playerResultsPane = new BattleRoyaleResultsPlayerPane(this, 0, 0, this._player, this._scale);

    sizer.add(this._playerResultsPane, {
      key: 'br-player-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Add Player Payout Component
    this._playerPayoutPane = new BattleRoyaleResultsPayoutPane(this, 0, 0, this._player, this._scale);
    sizer.add(this._playerPayoutPane, {
      key: 'br-payout-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(300),
      expand: false,
    });

    // Add Leaders Results Component
    this._leadersPane = new BattleRoyaleResultsLeadersPane(this, 0, 0, this._participants, this._scale);
    sizer.add(this._leadersPane, {
      key: 'br-leaders-pane',
      align: 'left-top',
      offsetX: this.applyScale(500),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  createSquadMemberImage(scene, imageKey) {
    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);

    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 3;
    smImageData.destroy();

    var squadMemberImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

    squadMemberImage.displayHeight = scene.applyScale(48);
    squadMemberImage.scaleX = squadMemberImage.scaleY;

    return squadMemberImage;
  }

  destroy() {
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Results_Scene_Assets));

    // Unload Player PFPs
    const playerImageKeys: string[] = this._participants.map(participant => participant.playerImageKey);
    UnloadImages(this, playerImageKeys);
  }
}
