export function secondsToTime(seconds: number) {
  seconds = Number(seconds);
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  var hDisplay = h > 0 ? `${h}h` : '';
  var mDisplay = m > 0 ? `${m}m` : '';
  var sDisplay = s > 0 ? `${s}s` : '0s';

  return h > 0 ? `${hDisplay} ${mDisplay} ${sDisplay}` : m > 0 ? `${mDisplay} ${sDisplay}` : `${sDisplay}`;
}

export function getNextBossFightCountdown() {
  var startDate = new Date();
  startDate.setUTCMinutes(59);
  startDate.setUTCSeconds(59);
  startDate.setUTCMilliseconds(0);

  var startingHour = 0;
  var utcHour = new Date().getUTCHours();

  // 8 Games Per Day
  // if (utcHour >= 0 && utcHour <= 2) {
  //   startingHour = 2;
  // } else if (utcHour > 2 && utcHour <= 5) {
  //   startingHour =  5;
  // } else if (utcHour > 5 && utcHour <= 8) {
  //   startingHour =  8;
  // } else if (utcHour > 8 && utcHour <= 11) {
  //   startingHour =  11;
  // } else if (utcHour > 11 && utcHour <= 14) {
  //   startingHour =  14;
  // } else if (utcHour > 14 && utcHour <= 17) {
  //   startingHour =  17;
  // } else if (utcHour > 17 && utcHour <= 20) {
  //   startingHour =  20;
  // } else if (utcHour > 20 && utcHour <= 23) {
  //   startingHour =  23;
  // }

  // 4 Games Per Day
  if (utcHour >= 0 && utcHour <= 5) {
    startingHour = 5;
  } else if (utcHour > 5 && utcHour <= 11) {
    startingHour = 11; // Reset Time
  } else if (utcHour > 11 && utcHour <= 17) {
    startingHour = 17;
  } else if (utcHour > 17 && utcHour <= 23) {
    startingHour = 23;
  }

  startDate.setUTCHours(startingHour);

  var timetarget = startDate.getTime(); // this number is currently the time stamp for midnight in UTC time for todays date
  var timenow = new Date().getTime();
  var offsetmilliseconds = timetarget - timenow;
  var milliseconds = Math.floor(offsetmilliseconds / 1000);
  return milliseconds as number;
}

export function getNextBattleRoyaleCountdown() {
  var startDate = new Date();
  startDate.setUTCMinutes(59);
  startDate.setUTCSeconds(59);
  startDate.setUTCMilliseconds(0);

  var startingHour = 0;
  var utcHour = new Date().getUTCHours();

  // 6 Games Per Day
  if (utcHour >= 0 && utcHour < 3) {
    startingHour = 2;
  } else if (utcHour >= 3 && utcHour < 7) {
    startingHour = 6;
  } else if (utcHour >= 7 && utcHour < 11) {
    startingHour = 10;
  } else if (utcHour >= 11 && utcHour < 15) {
    startingHour = 14;
  } else if (utcHour >= 15 && utcHour < 19) {
    startingHour = 18;
  } else if (utcHour >= 18 && utcHour < 23) {
    startingHour = 22;
  } else if (utcHour >= 23) {
    startingHour = 2;
    startDate.setUTCDate(startDate.getUTCDate() + 1)
  }

  startDate.setUTCHours(startingHour);

  var timetarget = startDate.getTime(); // this number is currently the time stamp for midnight in UTC time for todays date
  var timenow = new Date().getTime();
  var offsetmilliseconds = timetarget - timenow;
  var milliseconds = Math.floor(offsetmilliseconds / 1000);
  return milliseconds as number;
}

export function getUnixNowTime() {
  return Math.floor(Date.now() / 1000);
}

export function getTimeRemainingFromEndTime(unixEndTime: number) {
  return unixEndTime - getUnixNowTime();
}
