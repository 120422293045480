export default {
    setVAlign(align) {
        this.wrapConfig.vAlign = align;
        return this;
    },

    setHAlign(align) {
        this.wrapConfig.hAlign = align;
        return this;
    }
}