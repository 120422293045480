import { Client, Room } from 'colyseus.js';
import Phaser from 'phaser';
import IBossFightState, { GameState } from '~/../../API/BossFight/src/types/IBossFightState';
import BossFightState from '~/../../API/BossFight/src/game/BossFightState';
import ConfigurationService from './ConfigurationService';
import WalletConnectService from './WalletConnectService';
import UserService from './UserService';
import SquadService from './SquadService';
import MachineService from './MachineService';
import { getNextBossFightCountdown } from '~/utils/TimeUtils';

export default class BossFightServerService {
  private static _instance: BossFightServerService = new BossFightServerService();
  private client: Client;
  private events: Phaser.Events.EventEmitter;
  private room?: Room<IBossFightState>;
  private _config: any;
  private _isLocalServer = false;
  private _isServerAlive = false;

  private _roomMetaData;
  private _initialLength = 0;

  constructor() {
    if (BossFightServerService._instance) {
      throw new Error('Error: Instantiation failed: Use BossFightServerService.getInstance() instead of new.');
    }
    BossFightServerService._instance = this;

    if (this._isLocalServer) {
      this.client = new Client('ws://localhost:8003');
    } else {
      this._config = ConfigurationService.getInstance().getConfig();
      const wsEndpoint = this._config.websockets['bossfight'];
      if (wsEndpoint) {
        this.client = new Client(`${this._config.websockets.base}${wsEndpoint.path}`);
      }
    }
    this.events = new Phaser.Events.EventEmitter();
  }

  public static getInstance(): BossFightServerService {
    return BossFightServerService._instance;
  }

  async isServerAvailable() {
    await this.client
      .getAvailableRooms('bossfight')
      .then(room => {
        this._roomMetaData = room[0];
        this._isServerAlive = true;
      })
      .catch(err => {
        console.log('Boss Fight Server not started');
      });
  }

  isServerAlive() {
    return this._isServerAlive;
  }

  getRoomMetaData() {
    var rarity = '';
    var gameState: GameState;
    var participantCount = 0;
    var bossMaxHP = 0;
    var bossRemainingHP = 0;
    var attackingCount = 0;

    if (this._roomMetaData) {
      participantCount = this._roomMetaData.metadata?.participantCount;
      gameState = this._roomMetaData.metadata?.gameState;
      rarity = this._roomMetaData.metadata?.rarity;
      bossMaxHP = this._roomMetaData.metadata?.bossMaxHP;
      bossRemainingHP = this._roomMetaData.metadata?.bossRemainingHP;
      attackingCount = this._roomMetaData.metadata?.attackingCount;
    }

    return {
      participantCount: participantCount,
      gameState: gameState,
      rarity: rarity,
      bossMaxHP: bossMaxHP,
      bossRemainingHP: bossRemainingHP,
      attackingCount: attackingCount,
    };
  }

  async join() {
    return this.client
      .join<IBossFightState>('bossfight', {
        player: this.getBossFighterData(),
      })
      .then(room => {
        this.room = room;

        this.room.state.listen('gameState', value => {
          this.events.emit('gamestate-update');
        });

        this.room.state.participants.onAdd = () => {
          if (this.room?.state?.participants) {
            if (this.room.state.participants?.length > this._initialLength) {
              this.events.emit('participants-updated');
              this._initialLength = this.room.state.participants?.length;
            }
          }
        };

        this.room.state.participants.onRemove = () => {
          if (this.room?.state?.participants) {
            this._initialLength = this.room.state.participants?.length;
          }
          this.events.emit('participants-updated');
        };

        this.room.onMessage('attacking_state_changed', change => {
          this.events.emit('attacking_state_changed', change);
        });
      });
  }

  leave() {
    this.room?.leave();
    this._initialLength = 0;
    this.events.removeAllListeners();
  }

  disableBossFightListeners() {
    this._initialLength = 0;
    this.events.removeAllListeners();
  }

  // setPlayerInactive(inActive: boolean) {
  //     if (!this.room) {
  // 		return
  // 	}
  //     this.room.send("player-inactive", { inActive: inActive, wallet: WalletConnectService.getInstance().getWalletAddress() })
  // }

  playerReady() {
    if (!this.room) {
      return;
    }
    this.room.send('player-ready', { playerName: this.getBossFighterData().name, wallet: this.getBossFighterData().wallet });
  }

  isPlayerReady(playerId: string) {
    var isReady = false;
    this.room?.state.participants.forEach(participant => {
      if (participant.playerId.toLowerCase() === playerId.toLowerCase()) {
        isReady = participant.isAttacking;
      }
    });
    return isReady;
  }

  onGameStateChanged(cb: (state: BossFightState) => void, context?: any) {
    this.events.on('gamestate-update', cb, context);
  }

  onParticipantsUpdated(cb: (state: BossFightState) => void, context?: any) {
    this.events.on('participants-updated', cb, context);
  }

  onAttackersUpdated(cb: (state: BossFightState) => void, context?: any) {
    this.events.on('attackers-updated', cb, context);
  }

  onParticipantAttacking(cb: (change: any) => void, context?: any) {
    this.events.on('attacking_state_changed', cb, context);
  }

  getBattleData() {
    var data = {
      attempt: this.room?.state.attempt,
      countDown: this.room?.state.countDown,
      rarity: this.room?.state.rarity,
      bossMaxHP: this.room?.state.bossMaxHP,
      bossRemainingHP: this.room?.state.remaining,
      gameState: this.room?.state.gameState,
      participantCount: this.room?.state.participants.length,
      participants: this.room?.state.participants,
      activeParticipants: this.getActiveParticipantsNumber(),
      attackingCount: this.room?.state.attackingCount,
    };
    return data;
  }

  getCountDown() {
    if (this.room?.state.gameState === GameState.PreGame) {
      return getNextBossFightCountdown();
    }
    return this.room?.state.countDown as number;
  }

  getParticipantPayout() {
    var payout = {
      xp: 0,
      spaceElixir: 0,
      neonGold: 0,
      starDust: 0,
    };

    this.room?.state.participants.forEach(participant => {
      if (participant.playerId.toLowerCase() === WalletConnectService.getInstance().getWalletAddress().toLowerCase()) {
        payout.spaceElixir = participant.payout.spaceElixir;
        payout.neonGold = participant.payout.neonGold;
        payout.starDust = participant.payout.starDust;
        payout.xp = 500;
      }
    });

    return payout;
  }

  getActiveParticipantsNumber() {
    var activeParticipants = 0;
    this.room?.state.participants.forEach(participant => {
      if (!participant.inactive) {
        activeParticipants++;
      }
    });
    return activeParticipants;
  }

  isBossDefeated() {
    return (this.room?.state.remaining as number) <= 0;
  }

  showBossFightNotification() {
    return this.room?.state.gameState == GameState.PreGame && this.room?.state.countDown < 600;
  }

  isPreGame() {
    return this.room?.state.gameState == GameState.PreGame && this.room?.state.countDown < 300;
  }

  isFightActive() {
    return (
      this.room?.state.gameState == GameState.PreRound ||
      this.room?.state.gameState == GameState.Round ||
      this.room?.state.gameState == GameState.ProcessResults ||
      this.room?.state.gameState == GameState.DisplayResults
    );
  }

  isDisplayResults() {
    return this.room?.state.gameState == GameState.DisplayResults;
  }

  getGameState() {
    return this.room?.state;
  }

  getBossRarity() {
    return this.room?.state.rarity;
  }

  convertSecondsToCountdown(seconds: number) {
    seconds = Number(seconds);
    var hDisplay = Math.floor(seconds / 3600) + 'H ';
    var mDisplay = Math.floor((seconds % 3600) / 60) + 'M ';
    var sDisplay = Math.floor((seconds % 3600) % 60) + 'S';
    console.log(hDisplay + mDisplay + sDisplay);
  }

  outputPlayerList() {
    this.room?.state.participants.forEach(participant => {
      console.log(participant.playerName);
    });
  }

  updateParticipantData() {
    if (!this.room) {
      return;
    }

    if (getNextBossFightCountdown() < 600) {
      this.room.send('player-updated', { playerData: this.getBossFighterData() });
    }
  }

  getBossFighterData() {
    var bossFighter: BossFighter = {
      wallet: UserService.getInstance().getUserDataSync().wallet,
      name: UserService.getInstance().getUserDataSync().name,
      imageKey: SquadService.getInstance().getPFPImageKey(),
      imageUrl: SquadService.getInstance().getPFPUrl(),
      damage: SquadService.getInstance().getSquadHP(),
      brawlerCage: MachineService.getInstance().ownsBrawlerCage(),
      kaijuMultiplier: MachineService.getInstance().getKaijuMachineMultiplier(),
      has_battlepass: UserService.getInstance().getUserDataSync().has_battlepass,
      storage: MachineService.getInstance().getStorageCapacities(),
      traits: SquadService.getInstance().getTraitsSync(),
    };

    return bossFighter;
  }
}

interface BossFighter {
  wallet: string;
  name: string;
  imageKey: string;
  imageUrl: string;
  damage: number;
  kaijuMultiplier: number;
  brawlerCage: boolean;
  has_battlepass: number;
  storage: {};
  traits: {};
}
