import Phaser, { Scene } from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

const paneWidth = 685;
const paneHeight = 108;
const tintColor = 0xcdcdcd;

const resources = {
  space_elixir: 'space_elixir',
  neon_gold: 'neon_gold',
  stardust: 'stardust',
};

const titleSpace = 11;
const itemSpace = 6;
const groupSpace = 24;

export default class RaidLootInfoComponent extends FixWidthSizer {
  private _lootData;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: scene.applyScale(110),
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._lootData = data;

    return this.createComponent(scene);
  }

  private createComponent(scene: Phaser.Scene): RaidLootInfoComponent {
    // Available Loot Label
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', 'Available Loot:', scene.applyScale(16), 0), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'available_loot_label',
    });

    // SE
    this.add(
      this.scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, `space_elixir_icon`).setScale(0.65 * this._scale),
        text: scene.add.bitmapText(0, 0, 'cc_outline', this._lootData.attacker_possible_loot.space_elixir, scene.applyScale(18), 0),
        space: {
          icon: scene.applyScale(8),
        },
      }),
      {
        padding: { left: 0, right: 0, top: scene.applyScale(titleSpace), bottom: 0 },
        key: 'available_loot_se',
      },
    );

    // NG
    this.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, 'neon_gold_icon').setScale(0.65 * this._scale),
        text: scene.add.bitmapText(0, 0, 'cc_outline', this._lootData.attacker_possible_loot.neon_gold, scene.applyScale(18), 0),
        space: {
          icon: scene.applyScale(8),
        },
      }),
      {
        padding: { left: 0, right: 0, top: scene.applyScale(itemSpace), bottom: 0 },
        key: 'available_loot_ng',
      },
    );

    // Win Bonus Label
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', 'Win Bonus:', scene.applyScale(16), 0), {
      padding: { left: 0, right: 0, top: scene.applyScale(groupSpace), bottom: 0 },
      key: 'win_bonus_label',
    });

    // XP
    this.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, 'trophy_icon_small').setScale(this._scale),
        text: scene.add.bitmapText(0, 0, 'cc_outline', '500xp', scene.applyScale(18), 0),
        space: {
          icon: scene.applyScale(8),
        },
      }),
      {
        padding: { left: 0, right: 0, top: scene.applyScale(titleSpace), bottom: 0 },
        key: 'win_bonus_xp',
      },
    );

    // SD
    /*
    this.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, 'stardust_icon').setScale(0.65),
        text: scene.add.bitmapText(0, 0, 'cc_outline', '15', 18, 0),
        space: {
          icon: 8,
        },
      }),
      {
        padding: { left: 0, right: 0, top: itemSpace, bottom: 0 },
        key: 'win_bonus_sd',
      },
    );
    */

    // Defeat Label
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', 'Defeat:', scene.applyScale(16), 0), {
      padding: { left: 0, right: 0, top: scene.applyScale(groupSpace), bottom: 0 },
      key: 'defeat_label',
    });

    // XP
    this.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, 'trophy_icon_small').setScale(this._scale),
        text: scene.add.bitmapText(0, 0, 'cc_outline', '-500xp', scene.applyScale(18), 0),
        space: {
          icon: scene.applyScale(8),
        },
      }),
      {
        padding: { left: 0, right: 0, top: scene.applyScale(titleSpace), bottom: 0 },
        key: 'defeat_xp',
      },
    );

    return this.layout();
  }
}

Phaser.GameObjects.GameObjectFactory.register('raidLootInfoComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new RaidLootInfoComponent(this.scene, x, y));
});
