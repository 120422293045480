import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import WalletConnectService from '~/services/WalletConnectService';
import ScaleService from '~/services/ScaleService';
import ShopService from '~/services/ShopService';
import UserService from '~/services/UserService';

const modalHeight = 360;
const modalWidth = 860;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;
const componentRadius = 16;
const subtitleTint = 0x75758B;
const messageColor = 0xffffff;
const cancelButtonImage = 'reward_cancel_button';

const inputTextWidth = 760;
const inputTextHeight = 50;

export default class ShopWalletScene extends Phaser.Scene {
    private _wallet;
    private _item;
    private _scale;
    private _itemImage;
    private _inputText;
    private _confirmButton;

    init(data){
        this._item = data.item;
    }

    constructor() {
        super({
            key: 'ShopWalletScene'
        });

        this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50);  // 50 accounts for button extending beyond modal box
    }

    preload() {
    }

    create() {
        // Background Shade
        const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
        shade.setOrigin(0);
        shade.setAlpha(0.5);

        // Add Background
        const modalBg = this.add.rexRoundRectangle(
            this.sys.canvas.width / 2, // x
            this.sys.canvas.height / 2, // y
            this.applyScale(modalWidth), // width
            this.applyScale(modalHeight), // height
            this.applyScale(modalRadius), // radius
            modalColor, // fillColor
            modalAlpha, // alpha
        );
        modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

        // Add Sizer for content
        var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - this.applyScale(0), {
            width: this.applyScale(760),
            height:this.applyScale(300),
            space: {
                left: 0, right: 0, top: this.applyScale(15), bottom: 0,
                item: 0, line: 0
            },
            align: 0
        });

        // Add Title Text
        sizer.add(this.add.bitmapText(
            0,
            0,
            'cc_outline',
            this.getTitleText(),
            this.applyScale(28),
            1
        ).setMaxWidth(400),
            {
                key: 'title_text',
                align: 'center-top',
                offsetX: 0,
                offsetY: this.applyScale(15),
                expand: false
            }
        );

        // Wallet Input Text
        const inputTextConfig = {
            x: 0,
            y: 0,
            width: undefined,
            height: undefined,
            type: 'textarea',    // 'text'|'password'|'textarea'|'number'|'color'|...
            id: undefined,
            text: undefined,
            maxLength: 42,
            minLength: undefined,    
            placeholder: 'Enter Wallet Address',
            tooltip: undefined,
            readOnly: false,
            spellCheck: false,
            autoComplete: 'off',
            align: 'center',
            paddingLeft: undefined,
            paddingRight: undefined,
            paddingTop: `${this.applyScale(9)}px`,
            paddingBottom: undefined,
            fontFamily: 'ccwhatchamacallitbold',
            fontSize: `${this.applyScale(22)}px`,
            color: '#ffffff',
            border: `${this.applyScale(2)}px solid #383D57`,
            backgroundColor: '#440D31',
            borderColor: '#383D57',
            outline: 'none',
            direction: 'ltr',
            selectAll: false
        };

        this._inputText = this.add.rexInputText(0, 0, this.applyScale(inputTextWidth), this.applyScale(inputTextHeight), inputTextConfig);
        this._inputText.setText(WalletConnectService.getInstance().getWalletAddress());
        this._wallet = this._inputText.text;

        this._inputText.on('textchange', (inputText, e) => {
            this._wallet = inputText.text;
            this._confirmButton.setDisabled(!WalletConnectService.getInstance().isEthAddress(inputText.text));
        }, this);

        sizer.add(this._inputText, {
            key: 'input_text',
            align: 'center-top',
            offsetX: 0,
            offsetY: this.applyScale(175),
            expand: false
        });

        sizer.layout();
        
        // Add Cancel Button
        const cancelButton = new Button(this, this.sys.canvas.width / 2 - this.applyScale(119), modalBg.getBottomLeft().y, 'cancel_button', false)
            .setScale(this._scale);

        cancelButton.onClick().subscribe(pointer => {
            this.scene.resume('ShopModalScene');
            this.scene.stop();
        });

        this.add.existing(cancelButton);

        // Add Confirm Button
        this._confirmButton = new Button(this, this.sys.canvas.width / 2 + this.applyScale(80), modalBg.getBottomLeft().y, 'name_btn_enabled', false)
            .setScale(this._scale)
            .setDisabledTexture('name_btn_disabled');

            this._confirmButton.onClick().subscribe(pointer => {
                ShopService.getInstance().purchaseWL(this._item.shop_item_id, this._wallet).then(res => {
                    this.scene.start('ShopPurchaseResultScene', { purchaseResult: res, item: this._item });
                });
            });

        this.add.existing(this._confirmButton);
    }

    private getTitleText() {
        return `Please enter the wallet address you would like submitted for the ${this.getDisplayName()}.`;
    }

    private getDisplayName() {
        if (this._item.type === 'whitelist') {
            return this._item.name.slice(-2) === 'WL' ? this._item.name : `${this._item.name} WL`;
        } else {
            return this._item.name;
        }
    }

    private applyScale(length) {
        return length * this._scale;
    }
}