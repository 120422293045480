import ConfigurationService from './ConfigurationService';
import Phaser from 'phaser';
import util from 'util';

export default class ApiProxyService {
  private static _instance: ApiProxyService = new ApiProxyService();

  private _config: any = {};

  constructor() {
    if (ApiProxyService._instance) {
      throw new Error('Error: Instantiation failed: Use ApiProxyService.getInstance() instead of new.');
    }
    ApiProxyService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): ApiProxyService {
    return ApiProxyService._instance;
  }

  private setConfig(value): void {
    this._config = value;
  }

  public getConfig() {
    return this._config;
  }

  public get(endpoint: string, pathParams, queryParams) {
    document.getElementsByTagName('canvas')[0].style.cursor = 'wait';
    var url = this.getUrl(endpoint);
    url = this.addPathParams(url, pathParams);
    url = this.addQueryParams(url, queryParams);

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        document.getElementsByTagName('canvas')[0].style.cursor = 'default';
        return data;
      })
      .catch(err => {
        document.getElementsByTagName('canvas')[0].style.cursor = 'default';
        console.log(`Error fetching data from ${url}`);
      });
  }

  public post(endpoint: string, pathParams, body, queryParams?) {
    var url = this.getUrl(endpoint);
    url = this.addPathParams(url, pathParams);
    if (queryParams) {
      url = this.addQueryParams(url, queryParams);
    }
    
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(`Error fetching data from ${url}`);
      });
  }

  private getUrl(endpoint: string) {
    const endpointObj = this._config.api.endpoints[endpoint];
    if (endpointObj && endpointObj.path) {
      return `${this._config.api.base}${endpointObj.path}`;
    }
    return this._config.api.base;
  }

  private addPathParams(url: string, pathParams) {
    if (pathParams.length > 0) {
      pathParams.forEach(param => {
        url = url + param?.toString() + '/';
      });
    }
    return url;
  }

  private addQueryParams(url: string, queryParams) {
    var symbol = '?';

    if (queryParams && queryParams.length > 0) {
      queryParams.forEach(param => {
        url = url + symbol + param?.toString();
        symbol = '&';
      });
    }

    return url;
  }
}
