import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import { BF_Defeat_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 701;
const modalWidth = 314;

export default class BossFightDefeatScene extends Phaser.Scene {
  private _scale;
  private _defeatBadge;
  private _defeatTitle;
  private _text;
  private _resourceText;
  private _returnHomeButton;
  private _viewResultsButton;
  private _spaceElixirBar;
  private _neonGoldBar;

  private _payout;

  init(data: any) {
    this._payout = data.payout;
  }

  constructor() {
    super({
      key: 'BossFightDefeatScene',
    });
  }

  preload() {
    LoadImageAssets(this, BF_Defeat_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.sys.game.canvas.width * 2, // width
      this.sys.game.canvas.height * 2, // height
      0, // radius
      0x000000, // fillColor
      0.73, // alpha
    );

    this.add.existing(background);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Add Defeat Badge
    this._defeatBadge = this.add.image(0, 0, 'bossfight_defeat_badge').setScale(this._scale);

    sizer.add(this._defeatBadge, {
      key: 'bossfight_defeat_badge',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Add Defeat Title
    this._defeatTitle = this.add.image(0, 0, 'bossfight_defeat_title').setScale(this._scale);

    sizer.add(this._defeatTitle, {
      key: 'bossfight_defeat_title',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(145),
      expand: false,
    });

    // Text
    this._text = this.add.bitmapText(
      0,
      0,
      'cc_outline',
      'You were not\nsuccessful this time,\ndear wolf, but there\nwill be many more\nopportunities ahead...',
      this.applyScale(24),
      1,
    );

    sizer.add(this._text, {
      key: 'bossfight-text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(240),
      expand: false,
    });

    // Text
    this._resourceText = this.add.bitmapText(0, 0, 'cc_outline', 'You got:', this.applyScale(24), 1);

    sizer.add(this._resourceText, {
      key: 'payout-text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(400),
      expand: false,
    });

    // Reward Labels
    this._spaceElixirBar = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', this._payout.spaceElixir.toLocaleString('en-us'));

    sizer.add(this._spaceElixirBar, {
      key: 'bossfight-se-bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(440),
      expand: false,
    });

    this._neonGoldBar = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', this._payout.neonGold.toLocaleString('en-us'));

    sizer.add(this._neonGoldBar, {
      key: 'bossfight-ng-bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(500),
      expand: false,
    });

    // Add Return Home Button
    this._returnHomeButton = new Button(this, 0, 0, 'bossfight_defeat_return_home_button', true, 1.05).setScale(this._scale);

    this._returnHomeButton.onClick().subscribe(pointer => {
      this.destroy();
    });

    this.add.existing(this._returnHomeButton);

    sizer.add(this._returnHomeButton, {
      key: 'return-home-button',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(650),
      expand: false,
    });

    // Add View Results Button
    this._viewResultsButton = new Button(this, 0, 0, 'battleroyale_view_results_button', true, 1.05).setScale(this._scale);

    this._viewResultsButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.get('BossFightScene').unloadAssets();
      this.scene.launch('BossFightResultsScene');
      this.scene.stop('BossFightScene');
      this.scene.stop();
    });

    this.add.existing(this._viewResultsButton);

    sizer.add(this._viewResultsButton, {
      key: 'view-results-button',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(570),
      expand: false,
    });

    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  private destroy() {
    this.unloadAssets();
    this.scene.get('BossFightScene').unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop('BossFightScene');
    this.scene.stop();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BF_Defeat_Scene_Assets));
  }
}
