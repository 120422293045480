import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';

const componentWidth = 235;
const componentHeight = 145;
const hoverAlpha = 0.7;

export default class HelpOptionComponent extends OverlapSizer {
  private _scale;
  private _helpItem;

  constructor(scene: Phaser.Scene, x: number, y: number, helpItem: any, paneScale?: number, config?: any) {
    let conf = {
      width: componentWidth * (paneScale || 1),
      height: componentHeight * (paneScale || 1),
      space: {
        left: scene.applyScale(15),
        right: scene.applyScale(0),
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._helpItem = helpItem;

    return this.createComponent(scene, x, y, this._helpItem);
  }

  private createComponent(scene: Phaser.Scene, x: number, y: number, item: any) {
    var rectConfig = {
      maskType: 'roundRectangle',
      radius: scene.applyScale(25),
    };

    var itemBg = scene.add.rexCircleMaskImage(0, 0, item.bg_image_key, '', rectConfig);
    itemBg.displayHeight = scene.applyScale(145);
    itemBg.scaleX = itemBg.scaleY;

    this.add(itemBg, {
      key: 'help_option_bg',
      align: 'center',
      expand: false,
    });

    if (item.label_image_key) {
      this.add(scene.add.image(0, 0, item.label_image_key).setScale(this._scale), {
        key: 'help_option_label',
        align: 'center',
        expand: false,
      });
    }

    this.layout();

    if (item.enabled) {
      this.setInteractive({ cursor: 'pointer' });

      this.setChildrenInteractive({
        click: { mode: 'release', clickInterval: 100 },
        over: true,
        tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
        press: { time: 251, threshold: 9 },
        swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
        inputEventPrefix: 'child.',
      });

      this.on('child.over', (child, pointer, event) => {
        this.getElement('help_option_bg')?.setAlpha(hoverAlpha);
        this.getElement('help_option_label')?.setAlpha(hoverAlpha);
      });

      this.on('child.out', (child, pointer, event) => {
        this.getElement('help_option_bg')?.setAlpha(1);
        this.getElement('help_option_label')?.setAlpha(1);
      });
    } else {
      this.disableInteractive();
      this.setAlpha(0.3);
    }

    this.onClick((button, gameObject, pointer, event) => {
      // Open target url in new tab
      window.open(item.target_url, '_blank')?.focus();
    }, this);

    return this;
  }
}

Phaser.GameObjects.GameObjectFactory.register('helpOptionComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new HelpOptionComponent(this.scene, x, y));
});
