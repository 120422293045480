import ApiProxyService from './ApiProxyService';
import util from 'util';
import WalletConnectService from './WalletConnectService';

const resourceRefillItems = [
  {
    key: 'common_ng_refill',
    name: 'Common Neon Gold Refill',
    shopImageKey: 'shop_common_ng_refill_image',
    description: 'Refills your neon gold to 25% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 1,
    refillPercentage: 25,
    count: 1,
  },
  {
    key: 'rare_ng_refill',
    name: 'Rare Neon Gold Refill',
    shopImageKey: 'shop_rare_ng_refill_image',
    description: 'Refills your neon gold to 50% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 1,
    refillPercentage: 50,
    count: 1,
  },
  {
    key: 'epic_ng_refill',
    name: 'Epic Neon Gold Refill',
    shopImageKey: 'shop_epic_ng_refill_image',
    description: 'Refills your neon gold to 100% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 1,
    refillPercentage: 100,
    count: 1,
  },
  {
    key: 'common_se_refill',
    name: 'Common Space Elixir Refill',
    shopImageKey: 'shop_common_se_refill_image',
    description: 'Refills your space elixir to 25% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 2,
    refillPercentage: 25,
    count: 1,
  },
  {
    key: 'rare_se_refill',
    name: 'Rare Space Elixir Refill',
    shopImageKey: 'shop_rare_se_refill_image',
    description: 'Refills your space elixir to 50% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 2,
    refillPercentage: 50,
    count: 1,
  },
  {
    key: 'epic_se_refill',
    name: 'Epic Space Elixir Refill',
    shopImageKey: 'shop_epic_se_refill_image',
    description: 'Refills your space elixir to 100% of your storage capacity.',
    category: 'resource_packs',
    type: 'refill',
    currency_id: 3,
    refillCurrencyId: 2,
    refillPercentage: 100,
    count: 1,
  },
];

export default class ShopService {
  private static _instance: ShopService = new ShopService();

  private _shopItems;
  private _rewardBoxes;

  constructor() {
    if (ShopService._instance) {
      throw new Error('Error: Instantiation failed: Use ShopService.getInstance() instead of new.');
    }
    ShopService._instance = this;
  }

  public static getInstance(): ShopService {
    return ShopService._instance;
  }

  private setShopItems(value): void {
    this._shopItems = value;
  }

  public getShopItems(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._shopItems && !forceUpdate) {
        return resolve(this._shopItems);
      } else {
        return resolve(this.fetchShopItems());
      }
    });
  }

  public getShopItemsSync() {
    return this._shopItems;
  }

  private fetchShopItems() {
    return ApiProxyService.getInstance()
      .get('shop', ['available'], [])
      .then(res => {
        this._shopItems = res.items.filter(item => {
          return item.key !== 'liminal_wl';
        });
        return res.items;
      });
  }

  public getRewardBoxes(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._rewardBoxes && !forceUpdate) {
        return resolve(this._rewardBoxes);
      } else {
        return resolve(this.fetchRewardBoxes());
      }
    });
  }

  public getRewardBoxesSync() {
    return this._rewardBoxes;
  }

  private fetchRewardBoxes() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('rewards', ['boxes', wallet], [])
      .then(res => {
        this._rewardBoxes = res.chests;
        return res.chests;
      });
  }

  public purchaseWL(shop_item_id: any, walletAddress: string) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('shop', ['purchase', wallet, 'whitelist', shop_item_id], {
        preferred_user_address: walletAddress,
      })
      .then(res => {
        return res;
      });
  }

  public purchaseOther(shop_item_id: any, postBody: any = {}) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('shop', ['purchase', wallet, 'other', shop_item_id], postBody)
      .then(res => {
        return res;
      });
  }

  public purchaseRewardBox(reward_type_id: any) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('rewards', ['boxes', wallet, reward_type_id], {})
      .then(res => {
        return res;
      });
  }

  public getResourceRefillItems() {
    return resourceRefillItems;
  }

  public getRefillCost(currency_id, refillPercentage) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('resources', ['refill', wallet, currency_id, refillPercentage], [])
      .then(res => {
        console.log(`RefillCost: ${res}`);
        return res;
      });
  }

  public purchaseResourceRefill(currency_id, refillPercentage) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('resources', ['refill', wallet, currency_id, refillPercentage], {})
      .then(res => {
        console.log(`RefillPurchaseResponse: ${res}`);
        return res;
      });
  }
}
