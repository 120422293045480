import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import NFTService from '~/services/NFTService';
import ScaleService from '~/services/ScaleService';
import StakingService from '~/services/StakingService';
import UserService from '~/services/UserService';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';

const modalWidth = 494;
const modalHeight = 366;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const goldProgressBarColor = 0xdea827;
const pinkProgressBarColor = 0xde2795;
const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.14;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const borderWidth = 1;
const borderColor = 0x383d57;
const borderAlpha = 1;
const backgroundColor = 0x201425;
const backgroundAlpha = 0.4;
const radius = 11;

const progressBarHeight = 43;
const progressBarWidth = 240;
const minDaysHeldToStake = 7;

const resources = {
  space_elixir: 'space_elixir',
  neon_gold: 'neon_gold',
  stardust: 'stardust',
  dark_matter: 'dark_matter',
};

const collectionSlugs = {
  sw_genesis: 'starwolves',
  sw_generative: 'starwolvez-generative',
  ga_generative_new: 'gapes-nft',
  ga_generative_old: 'galacticapes',
  ga_genesis: 'galacticapesgenesis',
  ga_monkes: 'galacticmonkes',
};

export default class ResourceModalScene extends Phaser.Scene {
  private _resource;
  private _scale;

  init(data: any) {
    this._resource = data.resource;
  }

  constructor() {
    super({
      key: 'ResourceModalScene',
    });
  }

  preload() {}

  create() {
    this.input.setDefaultCursor('default');
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);
    const user = UserService.getInstance().getUserDataSync();
    const userMachines = MachineService.getInstance().getUserMachinesSync();

    const storageCapacity = this.getStorageCapacity(userMachines);
    const resourceTotal = this.getResourceTotal(user);
    const yieldTotal = this.getYieldTotal(userMachines);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    const resourceIcon =
      this._resource === resources.neon_gold
        ? 'neon_gold_icon_large'
        : this._resource === resources.space_elixir
        ? 'space_elixir_icon_large'
        : this._resource === resources.dark_matter
        ? 'dark_matter_icon_large'
        : 'stardust_icon_large';

    // Add Icons
    this.add.image(modalBg.getTopCenter().x, modalBg.getTopLeft().y, resourceIcon).setScale(this._scale);

    // Add Sizer for content
    var sizer = new OverlapSizer(this, {
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    });
    this.add.existing(sizer);

    // Add Title Text
    const titleText =
      this._resource === resources.neon_gold
        ? 'Neon Gold'
        : this._resource === resources.space_elixir
        ? 'Space Elixir'
        : this._resource === resources.dark_matter
        ? 'Dark Matter'
        : 'Stardust';

    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', titleText, this.applyScale(28), 1), {
      key: 'title_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(60),
      expand: false,
    });

    if (this._resource === resources.dark_matter) {
      sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Coming Soon...', this.applyScale(24), 1).setAlpha(0.7), {
        key: 'coming_soon_text',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(175),
        expand: false,
      });

      this.addCloseButton(sizer, modalBg);
      sizer.layout();

      return;
    }

    // Storage display
    sizer.add(
      this.add.bitmapText(
        0,
        0,
        'cc_outline',
        this._resource === resources.stardust ? 'Your Soft Staking Yield (Daily)' : 'Your Storage',
        this.applyScale(18),
        1,
      ),
      {
        key: 'storage_title_text',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(116),
        expand: false,
      },
    );

    const storageProgBar = new ProgressBar(this, 0, 0, this._scale, {
      rtl: false,
      title: {
        text:
          this._resource === resources.stardust
            ? this.getSDOwnerYieldText()
            : `${resourceTotal.toLocaleString('en-us')} of ${storageCapacity.toLocaleString('en-us')}`,
        fontSize: 16,
        offset: 2,
      },
      icon: undefined,
      border: {
        width: borderWidth,
        color: borderColor,
        alpha: borderAlpha,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: progressBarWidth,
        height: progressBarHeight,
        radius: radius,
        color:
          this._resource === resources.space_elixir
            ? blueProgressBarColor
            : this._resource === resources.neon_gold
            ? goldProgressBarColor
            : pinkProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: storageCapacity,
        progressValue: resourceTotal,
        glare: {
          height: glareHeight,
          width: progressBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
          offset: -15,
        },
      },
    });
    this.add.existing(storageProgBar);

    storageProgBar.setProgressBarText('');

    sizer.add(storageProgBar, {
      key: 'storage_prog_bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(142),
      expand: false,
    });

    // Yield display
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Your Machine Yield (Hourly)', this.applyScale(18), 1), {
      key: 'yield_title_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(205),
      expand: false,
    });

    const yieldProgBar = new ProgressBar(this, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: `${yieldTotal.toLocaleString('en-us')} per hour`,
        fontSize: 16,
        offset: 2,
      },
      icon: undefined,
      border: {
        width: borderWidth,
        color: borderColor,
        alpha: borderAlpha,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: progressBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: pinkProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: 1,
        progressValue: this._resource === resources.stardust ? 1 : 0,
        glare: {
          height: glareHeight,
          width: progressBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
          offset: -15,
        },
      },
    });
    this.add.existing(yieldProgBar);

    yieldProgBar.setProgressBarText('');

    sizer.add(yieldProgBar, {
      key: 'yield_prog_bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(231),
      expand: false,
    });

    if (resourceTotal > storageCapacity && this._resource !== resources.stardust) {
      // Yield paused note
      sizer.add(
        this.add
          .bitmapText(
            0,
            0,
            'cc_outline',
            'Note: You are not currently yielding rewards from mining this resource as you have exceeded your max storage.',
            this.applyScale(14),
            1,
          )
          .setMaxWidth(this.applyScale(400)),
        {
          key: 'yield_paused_text',
          align: 'center-top',
          offsetX: 0,
          offsetY: this.applyScale(290),
          expand: false,
        },
      );
    }

    sizer.layout();

    this.addCloseButton(sizer, modalBg);
  }

  private addCloseButton(sizer: any, modalBg: any) {
    // Add Cancel Button
    const closeButton = new Button(this, modalBg.getBottomCenter().x, modalBg.getBottomLeft().y, 'modal_close_button', false).setScale(this._scale);
    closeButton.onClick().subscribe(pointer => {
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
      this.scene.stop();
    });
    this.add.existing(closeButton);

    sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this.scene.stop();
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
    });
  }

  private getSDOwnerYieldText() {
    return `${StakingService.getInstance().getNetStakingYield()} per day`;
  }

  private getStorageCapacity(userMachines) {
    var storageTotal = 0;
    const storageProperty =
      this._resource === resources.neon_gold ? 'storage_neon_gold' : this._resource === resources.space_elixir ? 'storage_space_elixir' : 'stardust';

    if (storageProperty === 'stardust') return 0;

    userMachines.forEach(mach => {
      if (mach[storageProperty] && mach[storageProperty] > 0) {
        storageTotal = storageTotal + mach[storageProperty];
      }
    });

    return storageTotal;
  }

  private getResourceTotal(user) {
    return user[this._resource];
  }

  private getYieldTotal(userMachines) {
    var yieldTotal = 0.0;
    const yieldProperty =
      this._resource === resources.neon_gold
        ? 'produce_per_hour_neon_gold'
        : this._resource === resources.space_elixir
        ? 'produce_per_hour_space_elixir'
        : 'produce_per_hour_stardust';

    userMachines.forEach(mach => {
      if (mach[yieldProperty] && mach[yieldProperty] > 0) {
        yieldTotal = yieldTotal + parseFloat(mach[yieldProperty]);
      }
    });

    return yieldTotal.toFixed(this._resource === resources.stardust ? 2 : 0);
  }

  applyScale(length) {
    return length * this._scale;
  }
}
