import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { BossFight_Assets } from '~/utils/AssetLoader';

const modalHeight = 701;
const modalWidth = 314;

export default class BattleRoyaleEliminatedScene extends Phaser.Scene {
    
    private _scale;
    private _defeatBadge
    private _defeatTitle
    private _text
    private _returnHomeButton
    private _continueButton

    constructor() {
        super({
            key: 'BattleRoyaleEliminatedScene'
        });
    }

    preload() {        
        this.load.image("bossfight_defeat_badge", BossFight_Assets.bossfight_defeat_badge);
        this.load.image("bossfight_defeat_title", BossFight_Assets.bossfight_defeat_title);
        this.load.image("bossfight_defeat_return_home_button", BossFight_Assets.bossfight_defeat_return_home_button);
    }

    create() {
        this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
        this.createComponent()
    }

    createComponent() {

        const background = this.add.rexRoundRectangle(
            0, // x
            0, // y
            this.sys.game.canvas.width * 2, // width
            this.sys.game.canvas.height * 2, // height
            0, // radius
            0x000000, // fillColor
            0.73, // alpha
        )

        this.add.existing(background)
        
        var sizer = this.rexUI.add.overlapSizer(
            this.sys.canvas.width / 2,
            this.sys.canvas.height / 2,
            {
				width: modalWidth * this._scale,
                height: modalHeight * this._scale,
                space: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    item: 0,
                    line: 0,
                },
                align: 0,
                sizerEvents: true,
            },
        )

        // Add Defeat Badge
        this._defeatBadge = this.add.image(
            0,
            0,
            "bossfight_defeat_badge"
        ).setScale(this._scale)

        sizer.add(this._defeatBadge, {
            key: "bossfight_defeat_badge",
            align: "center-top",
            offsetX: 0,
            offsetY: 0,
            expand: false,
        })

        // Add Defeat Title
        this._defeatTitle = this.add.image(
            0,
            0,
            "bossfight_defeat_title"
        ).setScale(this._scale)

        sizer.add(this._defeatTitle, {
            key: "bossfight_defeat_title",
            align: "center-top",
            offsetX: 0,
            offsetY: this.applyScale(145),
            expand: false,
        })

        // Text
        this._text = this.add.bitmapText(
            0, 
            0, 
            'cc_outline',
            "You were not\nsuccessful this time,\ndear wolf, but there\nwill be many more\nopportunities ahead...", 
            this.applyScale(28),
            1
        )
        
        sizer.add(this._text, {
            key: "bossfight-text",
            align: "center-top",
            offsetX: 0,
            offsetY: this.applyScale(281),
            expand: false,
        })

        // Add Return Home Button
		this._returnHomeButton = new Button(
			this, 
			0,
			0, 
			'bossfight_defeat_return_home_button',
			true,
			1.05 
		).setScale(this._scale)

		this._returnHomeButton.onClick().subscribe(pointer => {
            this.destroy()
		})

		this.add.existing(this._returnHomeButton)

		sizer.add(this._returnHomeButton, {
			key: 'return-home-button',
			align: "center-top",
			offsetX: 0,
			offsetY: this.applyScale(542),
			expand: false
		})

        // Add Return Home Button
		this._continueButton = new Button(
			this, 
			0,
			0, 
			'bossfight_defeat_return_home_button',
			true,
			1.05 
		).setScale(this._scale)

		this._continueButton.onClick().subscribe(pointer => {
            this.scene.stop()
		})

		this.add.existing(this._continueButton)

		sizer.add(this._continueButton, {
			key: 'continue-button',
			align: "center-top",
			offsetX: 0,
			offsetY: this.applyScale(600),
			expand: false
		})

        sizer.layout();
    }

    applyScale(length) {
        return length * this._scale;
    }

    private destroy() {
        this.scene.start("HomebaseScene")
        this.scene.stop('BossFightScene');
        this.scene.stop()
    }

}