import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';
import ConfigurationService from './ConfigurationService';
import util from 'util';

const machineUnitIds = {
  ars: 2,
  healer: 4,
  mercenary: 1,
  super_swole: 3,
};

const capacityUsedPerTroop = [
  0, // None
  1, // Mercenary
  1, // ARS
  10, // Super Swole
  20, // Healer
];

export default class TroopService {
  private static _instance: TroopService = new TroopService();

  private _troopsByMachine;
  private _trainableTroopsByMachine;
  private _army;
  private _config: any;

  constructor() {
    if (TroopService._instance) {
      throw new Error('Error: Instantiation failed: Use TroopService.getInstance() instead of new.');
    }
    TroopService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
    this._troopsByMachine = {};
  }

  public static getInstance(): TroopService {
    return TroopService._instance;
  }

  private setTroopsByMachine(userMachineId, value): void {
    this._troopsByMachine[userMachineId.toString()] = value;
    if (userMachineId === 0) {
      this._army = value;
    }
  }

  private setTrainableTroopsByMachine(machineId, value): void {
    this._trainableTroopsByMachine[machineId.toString()] = value;
  }

  // Get Troops
  public getTroopsByMachine(userMachineId: number, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this._troopsByMachine) {
        this._troopsByMachine = {};
      }
      if (this._troopsByMachine[userMachineId.toString()] !== undefined && !forceUpdate) {
        return resolve(this._troopsByMachine[userMachineId.toString()]);
      } else {
        return resolve(this.fetchTroopsByMachine(userMachineId));
      }
    });
  }

  public getTroopsByMachineSync(userMachineId: number) {
    return this._troopsByMachine[userMachineId.toString()];
  }

  private fetchTroopsByMachine(userMachineId: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('machines', [wallet, 'units', userMachineId], [])
      .then(res => {
        this.setTroopsByMachine(userMachineId, res.units);
        return this._troopsByMachine[userMachineId.toString()];
      });
  }

  // Train Troops
  public trainTroops(userMachineId: number, troopLevel: number, numberOfUnits: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'units', 'purchase', userMachineId, troopLevel, numberOfUnits], [])
      .then(res => {
        return res;
      });
  }

  // Remove Troop
  public removeTroop(userMachineUnitId: any) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'units', 'remove', userMachineUnitId], [])
      .then(res => {
        return res;
      });
  }

  // Army
  public getArmy(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._army !== undefined && !forceUpdate) {
        return resolve(this._army);
      } else {
        return resolve(this.fetchArmy());
      }
    });
  }

  public getArmySync() {
    return this._army;
  }

  private fetchArmy() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('machines', [wallet, 'units', 0], [])
      .then(res => {
        this.setTroopsByMachine(0, res.units);
        return this._army;
      });
  }

  //Trainable Troops
  public getTrainableTroopsByMachine(userMachineId: number, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this._trainableTroopsByMachine) {
        this._trainableTroopsByMachine = {};
      }
      if (this._trainableTroopsByMachine[userMachineId.toString()] !== undefined && !forceUpdate) {
        return resolve(this._trainableTroopsByMachine[userMachineId.toString()]);
      } else {
        return resolve(this.fetchTrainableTroopsByMachine(userMachineId));
      }
    });
  }

  public getTrainableTroopsByMachineSync(userMachineId: number) {
    return this._trainableTroopsByMachine[userMachineId.toString()];
  }

  private fetchTrainableTroopsByMachine(machineId: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('machines', ['units', machineId], [])
      .then(res => {
        this.setTrainableTroopsByMachine(machineId, res.troops);
        return this._trainableTroopsByMachine[machineId.toString()];
      });
  }

  // Troop Count
  public getTroopCapacityUsed() {
    if (!this._army || this._army.length === 0) {
      return 0;
    }

    var capacityUsed = 0;

    this._army.forEach(troop => {
      capacityUsed += capacityUsedPerTroop[troop.machine_unit_id];
    });
    return capacityUsed;
  }

  public getTroopCapacity(machine_unit_id) {
    return capacityUsedPerTroop[machine_unit_id];
  }

  public getTroopImageKey(machine_unit_id) {
    switch (machine_unit_id) {
      case 1:
        return 'troops_mercenary';
      case 2:
        return 'troops_ars';
      case 3:
        return 'troops_super_swole';
      case 4:
        return 'troops_healer';
      default:
        return 'wolf_silhouette';
    }
  }

  public getTroopDisplayName(machine_unit_id) {
    switch (machine_unit_id) {
      case 1:
        return 'Mercenary';
      case 2:
        return 'ARS';
      case 3:
        return 'Super Swole';
      case 4:
        return 'Healer';
      default:
        return 'Unknown Soldier';
    }
  }
}
