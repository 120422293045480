import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

const costContainerHeight = 29;
const costContainerWidth = 213;
const costContainerRadius = 10;
const costContainerColor = 0x000000;
const costContainerAlpha = 0.4;
const costContainerBorderColor = 0x383d57;
const costContainerBorderWidth = 2;

const costGlareHeight = 6;
const costGlareWidth = 203;
const costGlareRadius = 3;
const costGlareColor = 0xffffff;
const costGlareAlpha = 0.17;

export default class CostLabel extends OverlapSizer {
  private _scale: number;
  private _currency: string;
  private _width: number;
  private _text: string;
  private _label: any;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, currency: any, text: string, barWidth: number, config?: any) {
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: barWidth * paneScale,
          height: costContainerHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._currency = currency;
    this._text = text;
    this._width = barWidth;

    this.createCostLabel(scene, x, y);
  }

  createCostLabel(scene: Phaser.Scene, x: number, y: number) {
    this.add(
      scene.add
        .rexRoundRectangle(
          0, // x
          0, // y
          scene.applyScale(this._width), // width
          scene.applyScale(costContainerHeight), // height
          scene.applyScale(costContainerRadius), // radius
          costContainerColor, // fillColor
          costContainerAlpha, // alpha
        )
        .setStrokeStyle(costContainerBorderWidth, costContainerBorderColor, costContainerAlpha),
      {
        key: 'cost_container',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      },
    ).add(
      scene.add.rexRoundRectangle(
        0, // x
        0, // y
        scene.applyScale(this._width - 10), // width
        scene.applyScale(costGlareHeight), // height
        scene.applyScale(costGlareRadius), // radius
        costGlareColor, // fillColor
        costGlareAlpha, // alpha
      ),
      {
        key: 'cost_glare',
        align: 'center-top',
        offsetX: 0,
        offsetY: scene.applyScale(6),
        expand: false,
      },
    );

    this._label = scene.rexUI.add.label({
      x: 0,
      y: 0,
      width: scene.applyScale(this._width - 10),
      height: scene.applyScale(27),
      orientation: 'x',
      rtl: false,
      icon: this._currency ? scene.add.image(0, 0, `${this._currency}_icon`).setScale(scene.applyScale(0.4)) : undefined,
      text: scene.add.bitmapText(0, 0, 'cc_outline', this._text.toLocaleString('en-us'), scene.applyScale(16), 0),
      space: {
        top: 1,
        icon: 6,
      },
      align: 'center',
    });

    this.add(this._label, {
      key: 'cost_text',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    }).layout();
  }

  updateLabelText(newText: string) {
    this._label.setText(newText);
  }
}

Phaser.GameObjects.GameObjectFactory.register('costLabel', function (x: number, y: number, config: any) {
  // @ts-ignore
  return this.displayList.add(new CostLabel(this.scene, x, y, config));
});
