import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import BattlePassItemRankComponent from './battlePassItemRankComponent';
import BattlePassItemImageComponent from './battlePassItemImageComponent';

const paneHeight = 320;
const paneWidth = 290;

const backgroundHeight = 286;
const backgroundWidth = 290;
const backgroundRadius = 17;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 40;
const borderWidth = 1;

export default class BattlePassWeeklyRaffleComponent extends OverlapSizer {
  private _scale;

  private _background;
  private _raffleImage;
  private _titleText;
  private _subText;
  private _countdownText;
  private _countdownBar;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(backgroundWidth), // width
      scene.applyScale(backgroundHeight), // height
      scene.applyScale(backgroundRadius), // radius
      backgroundColor, // fillColor
      backgroundAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(34),
      expand: false,
    });

    // Image
    this._raffleImage = scene.add.image(0, 0, 'battlepass_present').setScale(this._scale);

    sizerPanel.add(this._raffleImage, {
      key: 'bp-background',
      align: 'left-top',
      offsetX: this.applyScale(111),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Text
    this._titleText = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Weekly Raffle', this.applyScale(18), 0);
    sizerPanel.add(this._titleText, {
      key: 'title-text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(97),
      expand: false,
    });

    this._subText = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Every week, nothing but prizes', this.applyScale(14), 0);
    sizerPanel.add(this._subText, {
      key: 'sub-text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(140),
      expand: false,
    });

    this._countdownText = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Winners announced in', this.applyScale(14), 0);
    sizerPanel.add(this._countdownText, {
      key: 'countdown-text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(216),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassWeeklyRaffleComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new BattlePassWeeklyRaffleComponent(this.scene, x, y, data));
});
