import Phaser from 'phaser';
import OpponentService from '~/services/OpponentService';
import PVPServer from '~/services/PVPServerService';
import UserService from '~/services/UserService';
import SquadService from '~/services/SquadService';
import WalletConnectService from '~/services/WalletConnectService';
import BotService from '~/services/BotService';
import _ from 'lodash';
import { convertSecondsToMinutes } from '~/utils/GameUtils';
import Button from '~/components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { PVP_Matchmaking_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 380;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class MatchmakingScene extends Phaser.Scene {
  private server!: PVPServer;
  private _player;
  private _matchmakingText;
  private _scale;
  private _cancelButton;

  constructor() {
    super({
      key: 'MatchmakingScene',
    });
  }

  preload() {
    UserService.getInstance().getUserData();

    // Grabs Bot Data from the server incase its needed
    BotService.getInstance()
      .getBot(true)
      .then(res => {});

    // PvP
    LoadImageAssets(this, PVP_Matchmaking_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100);
    this.server = PVPServer.getInstance();
    this._player = UserService.getInstance().getUserDataSync();
    this._player.squadMembers = SquadService.getInstance().getSquadSync();

    // UI Elements
    const bg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    bg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Matchmaking Text to display
    this._matchmakingText = this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 - this.applyScale(120),
        'cc_outline',
        'Matchmaking in progress \n Please stand by...',
        this.applyScale(26),
        0,
      )
      .setOrigin(0.5, 0)
      .setCenterAlign();

    // blob animation
    var animationConfig = {
      key: 'blob_animation',
      frames: this.anims.generateFrameNumbers('party_blob', {
        start: 0,
        end: 7,
        first: 7,
      }),
      frameRate: 15,
      repeat: -1,
    };

    this.anims.create(animationConfig);
    this.add
      .sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + this.applyScale(30), 'party_blob')
      .setOrigin(0.5, 0.5)
      .play('blob_animation');

    // cancel button
    this._cancelButton = new Button(this, this.sys.canvas.width / 2, bg.getBottomLeft().y, 'cancel_button', false).setScale(this._scale);
    this._cancelButton.setVisible(false);
    this._cancelButton.onClick().subscribe(pointer => {
      this.onCancel();
    });
    this.add.existing(this._cancelButton);

    // stops cancel button from being pressed for 10 seconds
    this.time.addEvent({
      delay: 5000,
      loop: false,
      callback: () => {
        this._cancelButton.setVisible(true);
      },
    });

    return this.server.join(this._player).then(room => {
      // handles incoming server signals for PVP
      this.server.onWaitingForOpponent(this.handleWaitingForOpponent, this);
      this.server.onBotTimerCountdown(this.handleBotTimerCountdown, this);

      const handleGameStartedCurried = _.curry(this.handleGameStarted)(room?.id);
      this.server.onGameStarted(handleGameStartedCurried, this);
    });
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  onCancel() {
    this.server.leave();
    this.scene.start('HomebaseScene');
    this.unloadAssets();
    this.scene.stop();
  }

  handleGameStarted(roomId, data) {
    // Disables Cancel button so player cannot leave while a game is being started
    this._cancelButton.setDisabled(true);

    // gets opponent wallet
    var opponent;
    this.server.getGameState()?.players.forEach(player => {
      if (player.wallet !== WalletConnectService.getInstance().getWalletAddress()) {
        opponent = player.wallet;
      }
    });

    OpponentService.getInstance()
      .getOpponent(opponent, true)
      .then(opp => {
        this.scene.start('PvPScene', {
          battle_id: PVPServer.getInstance().getGameState()?.battleId,
        });
        this.unloadAssets();
        this.scene.stop();
      });
  }

  handleWaitingForOpponent() {
    // timer needs to be kicked off here
  }

  handleBotTimerCountdown(countdown: number) {
    this._matchmakingText.text = 'Matchmaking in progress \n Please stand by...\n\n' + convertSecondsToMinutes(countdown);
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Matchmaking_Scene_Assets));
  }
}
