import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import BaseRaidService from '~/services/BaseRaidService';
import ScaleService from '~/services/ScaleService';

const modalHeight = 270;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BaseRaidConfirmSurrenderScene extends Phaser.Scene {
  private _scale;

  constructor() {
    super({
      key: 'BaseRaidConfirmSurrenderScene',
    });
  }

  create() {
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    const modalBg = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2, // x
        this.sys.canvas.height / 2, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale(modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
      )
      .setInteractive({ cursor: 'default' })
      .setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2 - this.applyScale(10),
      width: this.applyScale(350),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add Title Text
    sizer.add(
      this.add
        .bitmapText(0, 0, 'cc_outline', 'Are you sure you want to surrender?  You will lose:', this.applyScale(24), 1)
        .setMaxWidth(this.applyScale(350)),
      {
        key: 'title_text',
        padding: { left: 0, right: 0, top: this.applyScale(20), bottom: 0 },
      },
    );

    sizer.add(this.add.existing(new RewardLabel(this, 0, 0, this._scale, 'xp', '-500xp')), {
      key: 'cost_label',
      padding: { left: 0, right: 0, top: this.applyScale(30), bottom: 0 },
    });

    // Add Cancel Button
    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      false,
    ).setScale(this._scale);

    cancelButton.onClick().subscribe(pointer => {
      this.cancelButtonClickHandler();
    });
    this.add.existing(cancelButton);

    // Add Confirm Button
    const confirmButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(75),
      modalBg.getBottomLeft().y,
      'pvp_confirm_button',
      false,
    ).setScale(this._scale);
    confirmButton.onClick().subscribe(pointer => {
      this.confirmButtonClickHandler();
    });
    this.add.existing(confirmButton);

    // Add key bindings
    this.input.keyboard.on('keydown-ENTER', event => {
      this.confirmButtonClickHandler();
    });

    this.input.keyboard.on('keydown-ESC', event => {
      this.cancelButtonClickHandler();
    });

    sizer.layout();
  }

  cancelButtonClickHandler() {
    this.scene.get('BaseRaidDetailsScene').input.enabled = true;
    this.scene.get('BaseRaidHudScene').input.enabled = true;
    this.scene.get('BaseRaidHudScene').input.keyboard.enabled = true;
    this.scene.stop();
  }

  confirmButtonClickHandler() {
    BaseRaidService.getInstance()
      .surrenderAttack()
      .then(res => {
        if (res.success) {
          BaseRaidService.getInstance()
            .viewAttack()
            .then(attackRes => {
              const resultData = {
                isAttacker: true,
                neon_gold: attackRes.attacker_possible_loot.neon_gold,
                space_elixir: attackRes.attacker_possible_loot.space_elixir,
                stardust: attackRes.attacker_possible_loot.stardust,
                xp: attackRes.is_win ? attackRes.attacker_win_xp : attackRes.attacker_lose_xp,
                isVictory: attackRes.is_win,
              };

              this.scene.get('BaseRaidHudScene').input.keyboard.enabled = true;
              this.scene.stop('BaseRaidDetailsScene');
              this.scene.stop('BaseRaidHudScene');
              this.scene.start('BaseRaidResultScene', resultData);
            });
        } else {
          this.scene.get('BaseRaidHudScene').input.keyboard.enabled = true;
          this.scene.stop();
          this.scene.stop('BaseRaidDetailsScene');
          this.scene.stop('BaseRaidHudScene');
          this.scene.start('ErrorScene');
        }
      });
  }

  applyScale(length) {
    return length * this._scale;
  }
}
