import Phaser from 'phaser';
import _ from 'lodash';
import util from 'util';
import Button from '../../../components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { Base_Raid_History_Scene_Assets } from '~/utils/AssetLoader';
import BaseRaidService from '~/services/BaseRaidService';
import RaidHistoryItemComponent from '~/components/baseRaid/RaidHistoryItemComponent';
import UserService from '~/services/UserService';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalWidth = 1014;
const modalHeight = 600;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x6262de;
const modalBorderWidth = 3;

const tableWidth = modalWidth - 30;
const tableHeight = modalHeight - 25;
const cellHeight = 83 + 11;

const tabHeight = 70;

const tabs = {
  defense_log: 'defense_log',
  attack_log: 'attack_log',
};

export default class BaseRaidHistoryScene extends Phaser.Scene {
  private _scale;

  private _startTab;
  private _defenseLogTab;
  private _attackLogTab;
  private _selectedTab;

  private _table;
  private _noOtherRaidsLabel;
  private _backToHomebaseButton;
  private _goRaidingButton;

  private _raidHistory;
  private _defenseHistory;
  private _attackHistory;

  private _loadingIndicator;
  private _sizer;

  init(data: any) {
    this._startTab = data.tab || tabs.defense_log;
  }

  constructor() {
    super({
      key: 'BaseRaidHistoryScene',
    });
  }

  preload() {
    LoadImageAssets(this, Base_Raid_History_Scene_Assets);
  }

  create() {
    const self = this;

    if (this.game.input.touch && this.game.input.touch.preventDefault) {
      this.game.input.touch.preventDefault = false;
      this.game.input.touch.enabled = true;
    }

    this.input.dragDistanceThreshold = 16;
    this.input.dragTimeThreshold = 300;

    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 200);

    const userAddress = UserService.getInstance().getUserDataSync().wallet;

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      height: this.applyScale(modalHeight + tabHeight + 84), // Add height for tabs and buttons
      width: this.applyScale(modalWidth),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    background.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    this._sizer.add(background, {
      key: 'base_raid_history_modal_bg',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(tabHeight),
      expand: false,
    });

    // Tabs
    this._defenseLogTab = new Button(this.scene.scene, 0, 0, 'defense_log_tab', false).setOrigin(1, 1).setScale(this._scale);
    this._attackLogTab = new Button(this.scene.scene, 0, 0, 'attack_log_tab_inactive', false).setOrigin(0, 1).setScale(this._scale);

    this._defenseLogTab.onClick().subscribe(pointer => {
      this.defenseLogTabClickHandler(pointer);
    });

    this._attackLogTab.onClick().subscribe(pointer => {
      this.attackLogTabClickHandler(pointer);
    });

    this.add.existing(this._defenseLogTab);
    this.add.existing(this._attackLogTab);

    this._sizer.add(this._defenseLogTab, {
      key: 'defense_log_tab',
      align: 'center-top',
      offsetX: this.applyScale(-85),
      offsetY: 0,
      expand: false,
    });

    this._sizer.add(this._attackLogTab, {
      key: 'attack_log_tab',
      align: 'center-top',
      offsetX: this.applyScale(85), // 250 + 138 + 14
      offsetY: 0,
      expand: false,
    });

    // Button Sizer
    const buttonSizer = this.rexUI.add.overlapSizer(0, 0, {
      height: this.applyScale(62),
      width: this.applyScale(modalWidth),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Back To Home Button
    this._backToHomebaseButton = new Button(this, 0, 0, 'back_to_homebase_button', false).setScale(this._scale);

    this._backToHomebaseButton.onClick().subscribe(pointer => {
      this.backToHomebaseButtonClickHandler(pointer);
    });

    buttonSizer.add(this.add.existing(this._backToHomebaseButton), {
      key: 'back_to_homebase_button',
      align: 'left-center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Go Raiding Button
    this._goRaidingButton = new Button(this, 0, 0, 'go_raiding_button', false).setScale(this._scale);

    this._goRaidingButton.onClick().subscribe(pointer => {
      this.goRaidingButtonButtonClickHandler(pointer);
    });

    buttonSizer.add(this.add.existing(this._goRaidingButton), {
      key: 'go_raiding_button',
      align: 'right-center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    this._sizer.add(buttonSizer.layout(), {
      key: 'button_sizer',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer.add(this._loadingIndicator, {
      key: 'loading_indicator',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(250),
      expand: false,
    });

    this._sizer.layout();

    BaseRaidService.getInstance()
      .getRaidHistory()
      .then(res => {
        this._raidHistory = res.base_raid_results;

        const allPfpInfo = this._raidHistory.flatMap(raid => {
          return [
            { name: raid.attacker_name, pfp_url: raid.attacker_pfp },
            { name: raid.defender_name, pfp_url: raid.defender_pfp },
          ];
        });

        const uniquePfps = _.uniqBy(allPfpInfo, 'name');

        uniquePfps.forEach(pfpInfo => {
          if (pfpInfo.name && pfpInfo.pfp_url) {
            this.load.image(`${pfpInfo.name}_pfp`, pfpInfo.pfp_url);
          }
        });

        this._defenseHistory = this._raidHistory.filter(raid => {
          return raid.defender_user_address.toLowerCase() === userAddress.toLowerCase();
        });

        this._attackHistory = this._raidHistory.filter(raid => {
          return raid.attacker_user_address.toLowerCase() === userAddress.toLowerCase();
        });

        this._loadingIndicator.setAlpha(0);
        this._sizer.remove(this._loadingIndicator, true);
        this._loadingIndicator = undefined;

        if (this._startTab === tabs.defense_log) {
          this.defenseLogTabClickHandler(self);
        } else {
          this.attackLogTabClickHandler(self);
        }
      });

    //this._sizer.drawBounds(this.add.graphics(), 0x00ff00);
    //this._sizer.pushIntoBounds();
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private backToHomebaseButtonClickHandler(pointer: any) {
    if (pointer.getDistance() > 20) {
      return;
    }

    this._selectedTab = '';
    this.scene.start('HomebaseScene');
    this.unloadAssets();
    this.scene.stop();
  }

  private goRaidingButtonButtonClickHandler(pointer: any) {
    if (pointer.getDistance() > 20) {
      return;
    }

    this._selectedTab = '';
    this.scene.start('BaseRaidLoadingScene');
    this.unloadAssets();
    this.scene.stop();
  }

  private defenseLogTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.defense_log) {
      return;
    }

    this._selectedTab = tabs.defense_log;
    this._defenseLogTab.setTexture('defense_log_tab');
    this._attackLogTab.setTexture('attack_log_tab_inactive');

    this.removeNoOtherRaidsLabel();
    this.createTable(this, this._defenseHistory, 'defense');

    if (this._defenseHistory.length < 6) {
      this.addNoOtherRaidsLabel(this._defenseHistory);
    }

    this._sizer.layout();
  }

  private attackLogTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.attack_log) {
      return;
    }

    this._selectedTab = tabs.attack_log;
    this._attackLogTab.setTexture('attack_log_tab');
    this._defenseLogTab.setTexture('defense_log_tab_inactive');

    this.removeNoOtherRaidsLabel();
    this.createTable(this, this._attackHistory, 'attack');

    if (this._attackHistory.length < 6) {
      this.addNoOtherRaidsLabel(this._attackHistory);
    }

    this._sizer.layout();
  }

  private createTable(scene, data, logType) {
    if (this._table) this.removeTable();
    if (!data || data.length === 0) return;

    var table = scene.rexUI.add.gridTable({
      width: scene.applyScale(tableWidth),
      height: this.applyScale(this.getTableHeight(data)),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: scene.applyScale(tableWidth),
        cellHeight: scene.applyScale(cellHeight),
        columns: 1,
        clamplTableOXY: true,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      space: {
        table: {},
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        return scene.add.existing(new RaidHistoryItemComponent(scene, 0, 0, item, logType, scene.getScale()));
      },
    });

    this._table = table;

    this._sizer
      .add(this._table, {
        key: 'base_raid_history_table',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(93),
        expand: false,
      })
      .layout();
  }

  private getTableHeight(data: any) {
    return cellHeight * data.length > tableHeight ? tableHeight : cellHeight * data.length - 1;
  }

  private removeTable() {
    this._table.setAlpha(0);
    this._sizer.remove(this._table);
    this._table = undefined;
  }

  private addNoOtherRaidsLabel(raidData: any) {
    this._noOtherRaidsLabel = this.rexUI.add.label({
      orientation: 'y',
      rtl: false,
      icon: this.add.image(0, 0, 'hp_icon').setScale(this._scale),
      text: this.add.bitmapText(0, 0, 'cc_outline', 'NO OTHER RAIDS TO REPORT', this.applyScale(16), 0).setAlpha(0.3),
      space: {
        icon: 4,
      },
    });

    const currentTableHeight = this.getTableHeight(raidData);
    const yOffset = tabHeight + currentTableHeight + (modalHeight - currentTableHeight) / 2 - 38; // Half label height + table padding

    this._sizer.add(this._noOtherRaidsLabel, {
      key: 'no_other_raids_label',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(yOffset),
      expand: false,
    });
  }

  private removeNoOtherRaidsLabel() {
    if (this._noOtherRaidsLabel) {
      this._noOtherRaidsLabel.setAlpha(0);
      this._noOtherRaidsLabel.destroy();
      this._noOtherRaidsLabel = undefined;
    }
  }

  private destroy() {
    this._defenseLogTab.destroy();
    this._attackLogTab.destroy();
    this._selectedTab.destroy();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Base_Raid_History_Scene_Assets));
  }
}
