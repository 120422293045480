import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';
import { PVP_Entry_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 455;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const spaceElixirFee = 250;
const neonGoldFee = 250;
const stardustFee = 5;

export default class PvpEntryScene extends Phaser.Scene {
  private _scale;

  constructor(reward: any) {
    super({
      key: 'PvpEntryScene',
    });
  }

  preload() {
    LoadImageAssets(this, PVP_Entry_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add Icons
    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, 'neon_gold_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 - this.applyScale(74), modalBg.getTopLeft().y, 'space_elixir_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 + this.applyScale(80), modalBg.getTopLeft().y, 'stardust_icon_large').setScale(this._scale);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(249),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Cost to enter:', this.applyScale(28), 1), {
      padding: { left: this.applyScale(42), right: 0, top: 0, bottom: this.applyScale(27) },
      key: 'title_text',
    });

    // Add Fee Labels
    const space_elixir_label = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', spaceElixirFee.toLocaleString('en-us'));
    const neon_gold_label = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', neonGoldFee.toLocaleString('en-us'));
    const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', stardustFee.toLocaleString('en-us'));

    sizer.add(this.add.existing(space_elixir_label), {
      padding: { left: this.applyScale(21), right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'space_elixir_label',
    });

    sizer.add(this.add.existing(neon_gold_label), {
      padding: { left: this.applyScale(21), right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'neon_gold_label',
    });

    sizer.add(this.add.existing(stardust_label), {
      padding: { left: this.applyScale(21), right: 0, top: 0, bottom: this.applyScale(35) },
      key: 'stardust_label',
    });

    // Add Note
    sizer.add(
      this.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          'Note: Rewards for winning\nwill always be significantly\nhigher than costs to enter.',
          this.applyScale(20),
          1,
        )
        .setAlpha(0.6),
      {
        padding: { left: this.applyScale(9), right: 0, top: 0, bottom: 0 },
        key: 'note_text',
      },
    );

    sizer.layout();

    // Add Cancel Button
    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      true,
      1.05,
    ).setScale(this._scale);
    cancelButton.onClick().subscribe(pointer => {
      this.scene.launch('HomebaseScene');
      this.unloadAssets();
      this.scene.stop();
    });
    this.add.existing(cancelButton);

    // Add Confirm Button
    const confirmButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(75),
      modalBg.getBottomLeft().y,
      'pvp_confirm_button',
      true,
      1.05,
    ).setScale(this._scale);
    confirmButton.onClick().subscribe(pointer => {
      this.scene.start('MatchmakingScene');
      this.unloadAssets();
      this.scene.stop();
    });
    this.add.existing(confirmButton);

    var userData = UserService.getInstance().getUserDataSync();
    var squadData = SquadService.getInstance().getSquadSync();

    if (
      userData.space_elixir < spaceElixirFee ||
      userData.neon_gold < neonGoldFee ||
      userData.stardust < stardustFee ||
      !squadData ||
      squadData.length === 0 ||
      squadData.filter(sm => {
        return sm.hp > 0;
      }).length === 0
    ) {
      confirmButton.setDisabled(true);
      confirmButton.setAlpha(0.5);
    }
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Entry_Scene_Assets));
  }
}
