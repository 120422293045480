import util from 'util';

export function LoadImageAssets(scene, assetObject, ignoreList?) {
  if (assetObject) {
    var keys = Object.keys(assetObject);

    if (ignoreList) {
      keys = Object.keys(assetObject).filter(key => !ignoreList.includes(key));
    }

    keys.forEach(assetName => {
      scene.load.image(assetName, assetObject[assetName]);
    });
    return;
  }
}

export function LoadImages(scene, assetObject, imageKeys, ignoreList?) {
  if (imageKeys && imageKeys.length > 0) {
    var keys = imageKeys;

    if (ignoreList) {
      keys = imageKeys.filter(key => !ignoreList.includes(key));
    }

    console.log(`Unloading ${keys.toString()}`);

    keys.forEach(assetName => {
      scene.load.image(assetName, assetObject[assetName]);
    });
  }
}

export function UnloadImages(scene: Phaser.Scene, keys) {
  console.log(`Unloading ${keys.toString()}`);

  keys.forEach(key => {
    scene.textures.remove(key);
  });
}

export function UnloadSounds(scene, keys) {
  console.log(`Unloading ${keys.toString()}`);

  keys.forEach(key => {
    scene.cache.audio.remove(key);
  });
}

export function UnloadJson(scene, keys) {
  console.log(`Unloading ${keys.toString()}`);

  keys.forEach(key => {
    scene.cache.json.remove(key);
  });
}
