import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import PVPServerService from '~/services/PVPServerService';
import PVEServerService from '~/services/PVEServerService';
import ScaleService from '~/services/ScaleService';

const modalHeight = 320;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const confirmationMessage = 'Are you sure you\nwant to surrender?';
const messageColor = 0x75758b;

export default class PvpSurrenderScene extends Phaser.Scene {
  private _scale;
  private _serverType;

  constructor() {
    super({
      key: 'PvpSurrenderScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight); // 220 accounts for icons and button extending beyond modal box
  }

  preload() {}

  init(data: any) {
    this._serverType = data.serverType;
  }

  create() {
    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Text
    const text1 = this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 - this.applyScale(85),
        'cc_outline',
        confirmationMessage,
        this.applyScale(24),
        1,
      )
      .setOrigin(0.5, 0.5)
      .setTint(messageColor);

    // Add Blob image
    this.add.sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'party_blob').setOrigin(0.5, 0.5);

    // Add Cancel Button
    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      false,
    ).setScale(this._scale);
    cancelButton.onClick().subscribe(pointer => {
      this.scene.stop();
      this.events.emit('surrender_cancel_event', {});
    });
    this.add.existing(cancelButton);

    // Add Confirm Button
    const confirmButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(75),
      modalBg.getBottomLeft().y,
      'pvp_confirm_button',
      false,
    ).setScale(this._scale);
    confirmButton.onClick().subscribe(pointer => {
      if (this._serverType === 'pvp') {
        PVPServerService.getInstance().playAction('pvp69');
        this.scene.stop();
        this.scene.get('PvPScene').unloadAssets();
      } else {
        PVEServerService.getInstance().playAction('pvp69');
        this.scene.stop();
      }
    });
    this.add.existing(confirmButton);
  }

  private applyScale(length) {
    return length * this._scale;
  }
}
