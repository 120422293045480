import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import Button from '~/components/buttons/Button';
import { BP_Buy_Success_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import UserService from '~/services/UserService';

const width = 1512;
const height = 982;

export default class BattlePassBuySuccessScene extends Phaser.Scene {
  private _scale;

  // Buttons
  private _homebaseButton;

  // Images
  private _backgroundImage;
  private _successText;
  private _successInfoText;
  private _seasonCard;

  constructor() {
    super({
      key: 'BattlePassBuySuccessScene',
    });
  }

  preload() {
    LoadImageAssets(this, BP_Buy_Success_Scene_Assets);
  }

  create() {
    // Set Background Color
    this.setBackgroundColour('#5631FF');
    this.createComponent();
  }

  createComponent() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    this._backgroundImage = this.add
      .image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'battlepass_buy_success_background')
      .setScale(this._scale);

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: width * this._scale,
      height: height * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Buttons
    this._homebaseButton = new Button(this, 0, 0, 'battlepass_homebase_button', true, 1.05).setScale(this._scale);
    this._homebaseButton.onClick().subscribe(async pointer => {
      this.setBackgroundColour('#0f0f1f');
      this.unloadAssets();
      this.scene.stop();
      await UserService.getInstance()
        .getUserData(true)
        .then(res => {
          this.scene.start('BattlePassScene', {
            userData: UserService.getInstance().getUserDataSync(),
          });
        });
    });

    this.add.existing(this._homebaseButton);

    sizer.add(this._homebaseButton, {
      key: 'back-button',
      align: 'left-top',
      offsetX: this.applyScale(581),
      offsetY: this.applyScale(845),
      expand: false,
    });

    //Images
    this._successText = this.add.image(0, 0, 'battlepass_success_text').setScale(this._scale);

    sizer.add(this._successText, {
      key: 'bp-success-text',
      align: 'left-top',
      offsetX: this.applyScale(581),
      offsetY: this.applyScale(86),
      expand: false,
    });

    this._successInfoText = this.add.image(0, 0, 'battlepass_success_info_text').setScale(this._scale);

    sizer.add(this._successInfoText, {
      key: 'bp-success-info-text',
      align: 'left-top',
      offsetX: this.applyScale(592),
      offsetY: this.applyScale(204),
      expand: false,
    });

    this._seasonCard = this.add.image(0, 0, 'battlepass_season_image').setScale(this._scale);

    sizer.add(this._seasonCard, {
      key: 'bp-season-card',
      align: 'left-top',
      offsetX: this.applyScale(497),
      offsetY: this.applyScale(240),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  setBackgroundColour(colour: string) {
    document.body.style.backgroundImage = 'none';
    document.body.style.backgroundColor = colour;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BP_Buy_Success_Scene_Assets));
  }
}
