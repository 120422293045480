import Phaser from 'phaser';
import StakingService from '~/services/StakingService';
import util from 'util';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { Loading_Assets } from '~/utils/AssetLoader';

const modalHeight = 325;
const modalWidth = 494;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const dashboardHeight = 595;
const dashboardWidth = 914;
const dashboardRadius = 25;
const pausedShadeColor = 0x1c1c1c;
const pausedShadeAlpha = 0.5;

export default class DailyChestModalScene extends Phaser.Scene {
  private item: any;
  private text: string;
  private type: string; // 'nft' || 'wallet' || 'all'

  init(data) {
    this.item = data.item;
    this.text = data.text;
    this.type = data.type;
  }

  constructor() {
    super({
      key: 'DailyChestModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, Loading_Assets);
  }

  create() {
    // Add Shade
    const pausedShade = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      dashboardWidth, // width
      dashboardHeight, // height
      dashboardRadius, // radius
      pausedShadeColor, // fillColor
      pausedShadeAlpha, // alpha
    );

    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      modalWidth, // width
      modalHeight, // height
      modalRadius, // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, 'chest_icon_large');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_bar');

    const text1 = this.add.bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 40, 'cc_outline', this.text, 28, 1).setOrigin(0.5, 0.5);

    const prog1 = this.add.image(this.sys.canvas.width / 2 - 122, this.sys.canvas.height / 2 + 50, 'loading_prog_1');
    const prog2 = this.add.image(this.sys.canvas.width / 2 - 73, this.sys.canvas.height / 2 + 50, 'loading_prog_2');
    const prog3 = this.add.image(this.sys.canvas.width / 2 - 34, this.sys.canvas.height / 2 + 50, 'loading_prog_3');
    const prog4 = this.add.image(this.sys.canvas.width / 2 - 13, this.sys.canvas.height / 2 + 50, 'loading_prog_4');
    const prog5 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_5');
    prog1.alpha = 0;
    prog2.alpha = 0;
    prog3.alpha = 0;
    prog4.alpha = 0;
    prog5.alpha = 0;

    this.time.addEvent({
      delay: 250,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog1,
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 500,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog2,
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 750,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog3,
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 1000,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog4,
            alpha: 1,
            duration: 500,
            ease: 'Power2',
          },
          this,
        );

        switch (this.type) {
          case 'nft':
            StakingService.getInstance()
              .claimNftStakingReward(this.item.marketplace_nft_id)
              .then(res => {
                this.tweens.add(
                  {
                    targets: prog5,
                    alpha: 1,
                    duration: 500,
                    ease: 'Power2',
                  },
                  this,
                );

                // Start RewardModalScene with response data
                this.scene.start('RewardModalScene', {
                  reward: res.nft_staking_reward_info.currency_reward_value,
                  rewardCurrency: res.nft_staking_reward_info.currency_name,
                });
              });
            break;
          case 'wallet':
            // Do stuff
            break;
          case 'all':
            StakingService.getInstance()
              .claimAllStakingRewards()
              .then(res => {
                this.tweens.add(
                  {
                    targets: prog5,
                    alpha: 1,
                    duration: 500,
                    ease: 'Power2',
                  },
                  this,
                );

                this.scene.resume('ObjectivesModalScene');
                this.unloadAssets();
                this.scene.stop();
              });
            break;
        }
      },
    });
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Loading_Assets));
  }
}
