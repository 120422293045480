import Phaser from 'phaser';
import { Shared_Assets } from '~/utils/AssetLoader';

export default class LoadingIndicator extends Phaser.GameObjects.Sprite {
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, scale?: number) {
    super(scene, x, y, 'party_blob');

    this._scale = scale || 1;

    return this.createIndicator(scene);
  }

  createIndicator(scene: Phaser.Scene) {
    // blob animation
    var animationConfig = {
      key: 'blob_animation',
      frames: scene.anims.generateFrameNumbers('party_blob', {
        start: 0,
        end: 7,
        first: 7,
      }),
      frameRate: 15,
      repeat: -1,
    };

    scene.anims.create(animationConfig);
    this.setOrigin(0.5, 0.5).setScale(this._scale).play('blob_animation');

    return scene.add.existing(this);
  }
}

Phaser.GameObjects.GameObjectFactory.register('loadingIndicator', function (x: number, y: number, config: any) {
  // @ts-ignore
  return this.displayList.add(new LoadingIndicator(this.scene, x, y, config));
});
