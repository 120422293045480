import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import { GameState } from '~/../../API/BossFight/src/types/IBossFightState';
import Button from '~/components/buttons/Button';
import TimerProgressBarComponent from '~/components/missions/timerProgressBarComponent';
import ProgressBar from '~/components/progressBar/ProgressBar';
import BossFightService from '~/services/BossFightServerService';
import WalletConnectService from '~/services/WalletConnectService';
import UserService from '~/services/UserService';
import { convertSecondsToCountdown } from '~/utils/GameUtils';
import { getNextBossFightCountdown } from '~/utils/TimeUtils';

// Pane Details
const paneHeight = 290;
const paneWidth = 259;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

const bossFightRarityColorMapper = {
  Common: 0x38384e,
  Rare: 0x039be5,
  Epic: 0xb803e5,
  Legendary: 0xffa000,
};

export default class BossFightDetailsPane extends OverlapSizer {
  private _scale: number;
  private _title;
  private _countDownProgressBar;
  private _gameText;
  private _readyUpButton;
  private _battleInfoPanel;
  private _background;
  private _intervalTimer;
  private _player;

  // { bossMaxHP, bossRemainingHP, participants}
  private _battleData;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._battleData = data;
    this._player = data.player;
    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    this._background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(this._background, {
      key: 'background',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Info Panel
    this._battleInfoPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Attacking Status
    this._title = scene.add.bitmapText(0, 0, 'cc_outline', this.getTitle(this._battleData.gameState), scene.applyScale(20), 0);
    this._battleInfoPanel.add(this._title, {
      key: `details-title`,
      align: 'left-top',
      offsetX: scene.applyScale(27),
      offsetY: scene.applyScale(29),
      expand: false,
    });

    // Timer Progress Bar
    this._countDownProgressBar = new TimerProgressBarComponent(scene, 0, 0, this._scale, getNextBossFightCountdown());

    // Implements Countdown
    this.startCountDownUI();

    this._battleInfoPanel.add(this._countDownProgressBar, {
      key: 'progress_bar',
      align: 'left-top',
      offsetX: this.applyScale(27),
      offsetY: this.applyScale(66),
      expand: false,
    });

    // Game Text
    this._gameText = scene.add.bitmapText(0, 0, 'cc_outline', this.getGameText(this._battleData), scene.applyScale(17), 0);

    //this.setRarityWordColor(this._battleData)

    this._battleInfoPanel.add(this._gameText, {
      key: `game-text`,
      align: 'left-top',
      offsetX: scene.applyScale(27),
      offsetY: scene.applyScale(121),
      expand: false,
    });

    this._readyUpButton = new Button(scene, 0, 0, 'bossfight_ready_up_button', false).setScale(this._scale);
    this._readyUpButton.setDisabledTexture('bossfight_you_are_ready_button');

    this.setButtonStateAndVisibility(this._battleData);

    this._readyUpButton.onClick().subscribe(pointer => {
      this._readyUpButton.setDisabled(true);
      BossFightService.getInstance().playerReady();
    });

    this._battleInfoPanel.add(scene.add.existing(this._readyUpButton), {
      key: 'ready-up-button',
      align: 'left-bottom',
      offsetX: this.applyScale(21),
      offsetY: this.applyScale(32),
      expand: false,
    });

    this.add(this._battleInfoPanel, {
      key: 'info-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public updateDetailPane(battleData) {
    this._battleData = battleData;

    // Update Countdown UI
    this.startCountDownUI();

    // Update Title
    this._title.setText(this.getTitle(this._battleData.gameState));

    // Set word tint if correct gamestate
    //this.setRarityWordColor(this._battleData)
    this._gameText.setText(this.getGameText(this._battleData));

    this.setButtonStateAndVisibility(battleData);
  }

  private setRarityWordColor(battleData) {
    switch (battleData.gameState) {
      case GameState.PreGame:
        this._gameText.setWordTint(battleData.rarity, 1, false, bossFightRarityColorMapper[this._battleData.rarity]);
        break;
      case GameState.Round:
      case GameState.PreGame:
      case GameState.ProcessResults:
      case GameState.DisplayResults:
      case GameState.Finished:
        console.log('set Character Tint');
        this._gameText.setCharacterTint(0, -1);
        break;
    }
  }

  private setButtonStateAndVisibility(battleData) {
    var isPlayerReady = BossFightService.getInstance().isPlayerReady(WalletConnectService.getInstance().getWalletAddress());

    switch (battleData.gameState) {
      case GameState.PreRound:
        this._readyUpButton.setVisible(true);
        this._readyUpButton.setTexture('bossfight_ready_up_button');
        this._readyUpButton.setDisabledTexture('bossfight_you_are_ready_button');
        this._readyUpButton.setDisabled(isPlayerReady);
        break;
      case GameState.Round:
        if (!isPlayerReady) {
          this._readyUpButton.setDisabledTexture('bossfight_sit_out_button');
          this._readyUpButton.setDisabled(true);
        }
        break;
      case GameState.PreGame:
        this._readyUpButton.setTexture('bossfight_ready_up_button');
        this._readyUpButton.setDisabledTexture('bossfight_you_are_ready_button');
        this._readyUpButton.setDisabled(false);
        this._readyUpButton.setVisible(false);
        break;
      case GameState.ProcessResults:
      case GameState.DisplayResults:
      case GameState.Finished:
        this._readyUpButton.setVisible(false);
        break;
    }
  }

  private getTitle(gameState) {
    switch (gameState) {
      case GameState.PreGame:
        return 'Launches in...';
      case GameState.PreRound:
        return 'Time left to ready up:';
      case GameState.Round:
        return 'Time left:';
      case GameState.DisplayResults:
        return 'Time left:';
      case GameState.ProcessResults:
        return 'Time left:';
      case GameState.Finished:
        break;
    }
  }

  private getGameText(battleData) {
    switch (battleData.gameState) {
      case GameState.PreGame:
        return (
          'A ' +
          battleData.rarity +
          ' pack of mutant\nvermin have appeared.\n\nYou must team up with\nother Wolf packs to\novercome these\nsavage beasts...'
        );
      case GameState.PreRound:
        return 'Round ' + battleData.attempt + ' is coming!';
      case GameState.Round:
        return 'Round ' + battleData.attempt + ' is underway!';
      case GameState.ProcessResults:
        return 'Boss Fight has completed.\nWe are processing the results';
      case GameState.DisplayResults:
        return 'Boss Fight results displayed.';
      case GameState.Finished:
        break;
    }
  }

  private startCountDownUI() {
    var countdown =
      BossFightService.getInstance().isFightActive() == true ? BossFightService.getInstance().getCountDown() : getNextBossFightCountdown();

    // Implements Countdown
    var remainingSeconds = countdown;

    // Resets Countdown Bar Values
    this._countDownProgressBar.roundTimerReset(this, remainingSeconds);

    clearInterval(this._intervalTimer);
    this._intervalTimer = setInterval(() => {
      //var _timeRemainingInterval = setInterval(() => {
      try {
        if (remainingSeconds <= 0) return clearInterval(this._intervalTimer);
        remainingSeconds--;
        this._countDownProgressBar.updateDisplay(this, remainingSeconds);
      } catch (error) {
        return clearInterval(this._intervalTimer);
      }
    }, 1000);
  }

  public stopTimer() {
    clearInterval(this._intervalTimer);
  }
}

Phaser.GameObjects.GameObjectFactory.register('bossFightDetailPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightDetailsPane(this.scene, x, y));
});
