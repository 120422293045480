import BaseRaidService from '~/services/BaseRaidService';
import util from 'util';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { Base_Raid_Loading_Scene_Assets } from '~/utils/AssetLoader';

export default class BaseRaidLoadingScene extends Phaser.Scene {
  private _next: number;

  constructor() {
    super({
      key: 'BaseRaidLoadingScene',
    });
  }

  init(data) {
    this._next = data.next || 0;
    console.log(`data = ${data.next}`);
  }

  preload() {
    LoadImageAssets(this, Base_Raid_Loading_Scene_Assets);
  }

  create() {
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_bar');

    const text1 = this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 90, 'cc_outline', 'Finding a base with\njuicy resources...', 26, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign();

    const progress1 = this.add.image(this.sys.canvas.width / 2 - 122, this.sys.canvas.height / 2 + 50, 'loading_prog_1').setAlpha(0);
    const progress2 = this.add.image(this.sys.canvas.width / 2 - 73, this.sys.canvas.height / 2 + 50, 'loading_prog_2').setAlpha(0);
    const progress3 = this.add.image(this.sys.canvas.width / 2 - 34, this.sys.canvas.height / 2 + 50, 'loading_prog_3').setAlpha(0);
    const progress4 = this.add.image(this.sys.canvas.width / 2 - 13, this.sys.canvas.height / 2 + 50, 'loading_prog_4').setAlpha(0);
    const progress5 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_5').setAlpha(0);

    this.time.addEvent({
      delay: 500,
      loop: false,
      callback: () =>
        this.tweens.add({
          targets: progress1,
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        }),
    });

    this.time.addEvent({
      delay: 1250,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: [progress2],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      delay: 2000,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: [progress3],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      delay: 2750,
      loop: false,
      callback: () =>
        this.tweens.add({
          targets: [progress4],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        }),
    });

    this.time.addEvent({
      delay: 3500,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: progress5,
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      //delay: 4250,
      delay: 100,
      loop: false,
      callback: () => {
        // Get current base raid status
        BaseRaidService.getInstance()
          .viewAttack()
          .then(attackRes => {
            // Remove log statement after testing
            console.log(util.inspect(attackRes));

            // Check base raid state
            if (attackRes.base_raid_id === 0 || attackRes.completed) {
              // UI State 1: No base raid ongoing or results to show, user can attack
              BaseRaidService.getInstance()
                .getRandomBase(this._next)
                .then(data => {
                  if (data.success) {
                    this.scene.start('BaseRaidDetailsScene', data);
                  } else {
                    this.scene.start('HomebaseScene');
                    this.scene.launch('MessageScene', {
                      message: 'There are no bases currently available to raid.  Check back later.',
                      title: 'Raids Unavailable',
                      scene: this,
                    });
                  }
                  this.unloadAssets();
                });
            } else {
              // UI State 2: Base raid is ongoing
              this.scene.start('BaseRaidDetailsScene', Object.assign({}, attackRes, { is_attacking: true }));
              this.unloadAssets();
            }
          });
      },
    });
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Base_Raid_Loading_Scene_Assets));
  }
}
