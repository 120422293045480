import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import TroopService from '~/services/TroopService';
import ProgressBar from '../progressBar/ProgressBar';

const componentWidth = 264;
const componentHeight = 70;

const imageHeight = 50;
const imageWidth = 50;
const imageRadius = 9;

const fontColor = 0xffffff;
const secondaryFontAlpha = 0.3;

const grayProgressBarColor = 0x8c8c8c;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0x65526f;
const backgroundAlpha = 0.4;
const radius = 7;
const progressBarBorderColor = 0x383d57;
const progressBarBorderWidth = 1;

const progressBarHeight = 16;
const progressBarWidth = 175;
const progressBarMin = 0;

export default class RaidUnitComponent extends OverlapSizer {
  private _scale;
  private _unit;

  constructor(scene: Phaser.Scene, x: number, y: number, unit: any, paneScale?: number, config?: any) {
    console.log(JSON.stringify(unit.shopImageKey));

    let conf = {
      width: componentWidth * (paneScale || 1),
      height: componentHeight * (paneScale || 1),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._unit = unit; // { display_name, level, image_key, max_hp, current_hp }

    this.createComponent(scene, x, y);
  }

  private createComponent(scene: Phaser.Scene, x: number, y: number) {
    // Unit Image
    this.addUnitImage();

    // Unit Title/Level Sizer
    const titleSizer = scene.rexUI.add.fixWidthSizer(0, 0, {
      width: scene.applyScale(180),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Unit Title
    titleSizer.add(scene.add.bitmapText(0, 0, 'cc_outline', this.getDisplayName(), scene.applyScale(16), 0).setMaxWidth(scene.applyScale(140)), {
      key: 'title_text',
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    });

    // Unit Level
    titleSizer.add(
      scene.add.bitmapText(0, 0, 'cc_outline', this.getLevelText(), scene.applyScale(16), 0).setMaxWidth(scene.applyScale(40)).setAlpha(0.3),
      {
        key: 'level_text',
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
      },
    );

    this.add(titleSizer.layout(), {
      key: 'title_sizer',
      align: 'left-top',
      offsetX: scene.applyScale(74),
      offsetY: this.scene.applyScale(17),
      expand: false,
    });

    // Progress Bar
    this.add(this.createProgressBar(scene), {
      key: 'unit_prog_bar',
      align: 'left-top',
      offsetX: scene.applyScale(74),
      offsetY: this.scene.applyScale(42),
      expand: false,
    });

    return this.layout();
  }

  private addUnitImage() {
    const imageKey = this.getImageKey();

    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    if (this._unit.machine_unit_id) {
      // Troop

      // Image Data
      const imageData = new Phaser.GameObjects.Image(this.scene, 0, 0, imageKey);
      const imageWidth = imageData.width;
      rectConfig.radius = imageWidth / 5;

      // Image
      const unitImage = this.scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

      // Scale Image
      unitImage.displayHeight = imageHeight;
      unitImage.scaleX = unitImage.scaleY;
      unitImage.setScale(this._scale * unitImage.scaleY);
      imageData.destroy();

      this.add(unitImage, {
        key: 'unit_image',
        align: 'left-top',
        offsetX: this.scene.applyScale(11),
        offsetY: this.scene.applyScale(11),
        expand: false,
      });
    } else {
      // Machine
      const baseTexture = this.scene.textures.get(imageKey);
      const frameName = `${imageKey}_centered`;
      baseTexture.add(`${imageKey}_full`, 0, 0, 0, 250, 361);
      baseTexture.add(frameName, 0, 25, 86, 200, 200);

      const unitImage = this.scene.add.rexCircleMaskImage(0, 0, imageKey, frameName, {
        maskType: 'roundRectangle',
        radius: this.scene.applyScale(30),
        origin: { x: 0.5, y: 0.5 },
      });

      unitImage.displayHeight = imageHeight;
      unitImage.scaleX = unitImage.scaleY;
      unitImage.setScale(unitImage.scaleY * this._scale);

      this.add(unitImage, {
        key: 'unit_image',
        align: 'left-top',
        offsetX: this.scene.applyScale(11),
        offsetY: this.scene.applyScale(11),
        expand: false,
      });
    }
  }

  private createProgressBar(scene) {
    const progressValue = 0;
    const maxValue = 100;

    // Progress Bar
    const progBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      this._scale,
      {
        rtl: false,
        title: {
          text: '',
          offset: 2,
          fontSize: 20,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: grayProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressBarMin,
          maxValue: this._unit.initial_hitpoints === undefined ? 1 : this._unit.initial_hitpoints,
          progressValue: this._unit.initial_hitpoints === undefined ? 1 : this._unit.hitpoints,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
        border: {
          alpha: backgroundAlpha,
          color: progressBarBorderColor,
          width: progressBarBorderWidth,
        },
      },
    );

    return scene.add.existing(progBar);
  }

  private getImageKey() {
    if (this._unit.machine_id) {
      // Machine
      return this._unit.shopImageKey;
    } else {
      // Troop
      return TroopService.getInstance().getTroopImageKey(this._unit.machine_unit_id);
    }
  }

  private getDisplayName() {
    if (this._unit && this._unit.display_name) {
      return this._unit.display_name.length > 16
        ? `${this._unit.display_name.toUpperCase().substring(0, 14)}... `
        : `${this._unit.display_name.toUpperCase()} `;
    } else {
      return 'UNKNOWN ';
    }
  }

  private getLevelText() {
    if (this._unit && this._unit.level) {
      return `LV. ${this._unit.level}`;
    } else {
      return 'LV. 0';
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('raidUnitComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new RaidUnitComponent(this.scene, x, y));
});
