import Phaser from 'phaser';
import CollectionService from '~/services/CollectionService';
import NFTService from '~/services/NFTService';

export default class NftLoaderScene extends Phaser.Scene {
  private _originatingScene;
  private _nfts;

  constructor() {
    super('NftLoaderScene');
  }

  init(data: any) {
    this._originatingScene = data.originating_scene;
    this._nfts = data.nfts;
  }

  preload() {
    this._nfts.forEach(visibleNft => {
      this.load.image(visibleNft.image_key, visibleNft.image_thumbnail_url);
    });
  }

  create() {
    this.scene.get(this._originatingScene).onLoaded();
    this.scene.stop();
  }
}
