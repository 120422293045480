import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';
import ConfigurationService from './ConfigurationService';
import util from 'util';

const sw_yield = 5;
const sw_genesis_yield = 50;
const ga_yield = 2;
const ga_genesis_yield = 8;
const gm_yield = 1;

export default class StakingService {
  private static _instance: StakingService = new StakingService();

  private _stakingInfo;
  private _netStakingYield = 0;
  private _config: any;

  constructor() {
    if (StakingService._instance) {
      throw new Error('Error: Instantiation failed: Use StakingService.getInstance() instead of new.');
    }
    StakingService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): StakingService {
    return StakingService._instance;
  }

  public getStakingInfo(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._stakingInfo && !forceUpdate) {
        return resolve(this._stakingInfo);
      } else {
        return resolve(this.fetchStakingInfo(nftIds, collection_slugs, startIndex, numRequested));
      }
    });
  }

  public claimNftStakingReward(nftId) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('staking', [wallet, 'nft', nftId], {})
      .then(res => {
        return res;
      });
  }

  public claimAllStakingRewards() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('staking', [wallet, 'nft', 'all'], {})
      .then(res => {
        return res;
      });
  }

  public getStakingInfoSync() {
    return this._stakingInfo;
  }

  private fetchStakingInfo(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();

    const queryParams = nftIds && nftIds.length > 0 ? [`ids=${nftIds.toString()}`] : [];
    if (collection_slugs) queryParams.push(`collection_slug=${collection_slugs.toString()}`);
    if (startIndex !== undefined && startIndex !== null) queryParams.push(`start_index=${startIndex}`);
    if (numRequested !== undefined && numRequested !== null) queryParams.push(`count=${numRequested}`);

    return ApiProxyService.getInstance()
      .get('staking', [wallet], queryParams)
      .then(res => {
        this._stakingInfo = {
          ...res,
          nfts: this.enrichImageData(res),
        };

        this.setNetStakingYield();
        return this._stakingInfo;
      });
  }

  private enrichImageData(data: any) {
    const nfts = data?.nfts?.map(nft => {
      nft.image_key = `nft_${nft.marketplace_nft_id}`;
      return nft;
    });

    data.nfts = nfts;
    return data.nfts;
  }

  public getNetStakingYield() {
    return this._netStakingYield;
  }

  private setNetStakingYield() {
    this._netStakingYield = 0;

    this._netStakingYield += this._stakingInfo.sw_generative_count * sw_yield;
    this._netStakingYield += this._stakingInfo.sw_genesis_count * sw_genesis_yield;
    this._netStakingYield += this._stakingInfo.ga_generative_count * ga_yield;
    this._netStakingYield += this._stakingInfo.ga_genesis_count * ga_genesis_yield;
    this._netStakingYield += this._stakingInfo.galactic_monkes * gm_yield;
  }
}
