export default class TweenHelper {
  static flashElement(scene, element, repeat = true, easing = 'Linear', overallDuration = 1500, visiblePauseDuration = 500, delay = 0) {
    if (scene && element) {
      let flashDuration = overallDuration - visiblePauseDuration / 2;

      scene.tweens.timeline({
        tweens: [
          {
            targets: element,
            duration: 0,
            alpha: 0,
            ease: easing,
            delay: delay,
          },
          {
            targets: element,
            duration: flashDuration,
            alpha: 1,
            ease: easing,
          },
          {
            targets: element,
            duration: visiblePauseDuration,
            alpha: 1,
            ease: easing,
          },
          {
            targets: element,
            duration: flashDuration,
            alpha: 0,
            ease: easing,
            onComplete: () => {
              if (repeat === true) {
                this.flashElement(scene, element, true, easing, overallDuration, visiblePauseDuration);
              }
            },
          },
        ],
      });
    }
  }

  static fadeElement(scene, element, alpha = 1, easing = 'Power2', duration = 1000, delay = 0) {
    if (scene && element) {
      scene.tweens.add(
        {
          targets: element,
          alpha: alpha,
          duration: duration,
          ease: easing,
          delay: delay,
        },
        scene,
      );
    }
  }
}
