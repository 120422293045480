import { Asteroid } from '~/services/MiningService';
import { Mining_Loading_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

type Data = {
  asteroid: Asteroid;
};

export default class MiningLoadingScene extends Phaser.Scene {
  private _asteroid!: Asteroid;

  constructor() {
    super({
      key: 'MiningLoadingScene',
    });
  }

  init(data: Data) {
    this._asteroid = data.asteroid;
  }

  preload() {
    LoadImageAssets(this, Mining_Loading_Scene_Assets);
  }

  create() {
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_bar');

    const text1 = this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 90, 'cc_outline', 'Loading Asteroid\ntracking software...', 26, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign();

    const text2 = this.add
      .bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 90, 'cc_outline', 'Deploying Asteroid\nscanner...', 26, 0)
      .setOrigin(0.5, 0)
      .setCenterAlign()
      .setAlpha(0);

    const progress1 = this.add.image(this.sys.canvas.width / 2 - 122, this.sys.canvas.height / 2 + 50, 'loading_prog_1').setAlpha(0);
    const progress2 = this.add.image(this.sys.canvas.width / 2 - 73, this.sys.canvas.height / 2 + 50, 'loading_prog_2').setAlpha(0);
    const progress3 = this.add.image(this.sys.canvas.width / 2 - 34, this.sys.canvas.height / 2 + 50, 'loading_prog_3').setAlpha(0);
    const progress4 = this.add.image(this.sys.canvas.width / 2 - 13, this.sys.canvas.height / 2 + 50, 'loading_prog_4').setAlpha(0);
    const progress5 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_5').setAlpha(0);

    this.time.addEvent({
      delay: 500,
      loop: false,
      callback: () =>
        this.tweens.add({
          targets: progress1,
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        }),
    });

    this.time.addEvent({
      delay: 1250,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: [progress2],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      delay: 2000,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: text1,
          alpha: 0,
          duration: 1000,
          ease: 'Power2',
        });

        this.tweens.add({
          targets: [text2, progress3],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      delay: 2750,
      loop: false,
      callback: () =>
        this.tweens.add({
          targets: [progress4],
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        }),
    });

    this.time.addEvent({
      delay: 3500,
      loop: false,
      callback: () => {
        this.tweens.add({
          targets: progress5,
          alpha: 1,
          duration: 1000,
          ease: 'Power2',
        });
      },
    });

    this.time.addEvent({
      delay: 4250,
      loop: false,
      callback: () => {
        this.scene.start('MiningDetailsScene', {
          asteroid: this._asteroid,
        });
      },
    });
  }
}
