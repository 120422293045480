import Phaser from 'phaser';
import BossFightServerService from '~/services/BossFightServerService';
import ScaleService from '~/services/ScaleService';
import { BF_Results_Scene_Assets } from '~/utils/AssetLoader';
import BossFightResultsPlayerPane from './bossFightResultsPlayerPane';
import BossFightResultsLeadersPane from './bossFightResultsLeadersPane';
import BossFightResultsPayoutPane from './bossFightResultsPayoutPane';
import WalletConnectService from '~/services/WalletConnectService';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 600;
const modalWidth = 1000;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BossFightResultsScene extends Phaser.Scene {
  private _scale;
  private _participants;
  private _player;

  // UI Elements
  private _sizer;
  private _playerResultsPane;
  private _playerPayoutPane;
  private _leadersPane;

  init(data: any) {
    this._participants = [
      {
        playerId: '0x07Bc74Bb5273bB29338cB89db90d867136bBfcfA',
        playerName: '!howldoiplay',
        playerImageKey: 'nft_328036289',
        playerImageUrl:
          'https://lh3.googleusercontent.com/ykHwoVKjNupwrDOLhYMoRBcVkVXLqE03pTBNSE57ehsZO7O4MIJegy9c5C-xzt4KXiVaChTAkJeJg8a_0-LbrMFw7JvmRHmSzFG9ow=s128',
        clientId: 'bgI1N9OTU',
        placing: 1,
        roundsParticiapted: 6,
        damage: 13703,
        damageDealt: 82218,
        level: 3968,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.45,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 47500000,
          neonGoldStorageTotal: 47500000,
          spaceElixir: 1438250,
          neonGold: 1438250,
          starDust: 11,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0xeB9BA435B89f701D45b2f3782207d4E824466783',
        playerName: 'Dragon',
        playerImageKey: 'nft_328036246',
        playerImageUrl:
          'https://lh3.googleusercontent.com/JaD5FLiuSt9c6EJwACAVzr0WMbnzbI40YLCLUC65PD3OMI2tYt9_IpxKRPaG-Fu89cGh9B53SS-ZE97Q46GoEW7eRFAx-cFa6MOi_po=s128',
        clientId: 'd8BSWTOf0',
        placing: 2,
        roundsParticiapted: 5,
        damage: 13703,
        damageDealt: 68515,
        level: 2854,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.45,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 45000000,
          neonGoldStorageTotal: 43500000,
          spaceElixir: 1141250,
          neonGold: 1106875,
          starDust: 9.16,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0x4D3f9df696d8EFD93e82e33566d037cbb3362EfD',
        playerName: 'WHITTY',
        playerImageKey: 'nft_328036302',
        playerImageUrl:
          'https://lh3.googleusercontent.com/GkK-uOqBZot_rKNeQ1wHSmgMKDZKXa86ED4MAL9dc3fCdaPByVLwl1GaowzfBZfTrYW5h4pHJef_21kk1k5RrXKhyLHXB5Lj0BEC9-U=s128',
        clientId: 'XPuDEYIG2',
        placing: 3,
        roundsParticiapted: 6,
        damage: 10733,
        damageDealt: 64398,
        level: 6229,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.35,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 13740000,
          neonGoldStorageTotal: 13250000,
          spaceElixir: 509850,
          neonGold: 496375,
          starDust: 11,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0x831BDF2378d5Af57F50e19F6a28500969B1b1c51',
        playerName: 'Tonimeng',
        playerImageKey: 'nft_274267157',
        playerImageUrl:
          'https://lh3.googleusercontent.com/vgdiQog8pa4nPkay9NpINNJgYHrGm-RIh9fuAwJ9QePvxjpSPM5kQ113e6g9ALHL7I18r29XD6VOnAIYUSVeX5usJmzogdRBwJzOYw=s128',
        clientId: 'eEK1tvOcc',
        placing: 4,
        roundsParticiapted: 6,
        damage: 10733,
        damageDealt: 64398,
        level: 13534,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.35,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 47500000,
          neonGoldStorageTotal: 47500000,
          spaceElixir: 1438250,
          neonGold: 1438250,
          starDust: 11,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0x71190CAcB8Dc40d288625B87Ab2a56fD8641AaC3',
        playerName: 'yolaEric',
        playerImageKey: 'nft_304651248',
        playerImageUrl:
          'https://lh3.googleusercontent.com/63fXyxO7hTgOCx6TK6CI_ihxrhfHivGumhtkXsaZnqZ4K1_Bhg9zVDxRM9BZopqsqomUkVhKOR9OkHw1F9pNb78D2cBZgSNrABgAdA=s128',
        clientId: 'RYp-SaJgU',
        placing: 5,
        roundsParticiapted: 6,
        damage: 8832,
        damageDealt: 52992,
        level: 4535,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.27,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 34400000,
          neonGoldStorageTotal: 34400000,
          spaceElixir: 1078000,
          neonGold: 1078000,
          starDust: 11,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0x28e15B0eB947333fBc71A83F4A20c2A95AcCcf6a',
        playerName: 'OneShot',
        playerImageKey: 'nft_236484847',
        playerImageUrl:
          'https://lh3.googleusercontent.com/TzPEquoszOCO2lTJWNeD-hqtv_bk9NpJ_IrXZYPsabReKRQIPtfqczrzPdxIcc32kOxfqjBfs65CVaJLA_mJjDjMlhpAgRUD1xGT=s128',
        clientId: 'V01F1z7Mi',
        placing: 6,
        roundsParticiapted: 5,
        damage: 9135,
        damageDealt: 45675,
        level: 1863,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.45,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 28500000,
          neonGoldStorageTotal: 28900000,
          spaceElixir: 763125,
          neonGold: 772291,
          starDust: 9.16,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0xA786bAED9d87aAa039C7C97B166A419d9F42eAa6',
        playerName: 'Lukeh',
        playerImageKey: 'nft_317575408',
        playerImageUrl:
          'https://lh3.googleusercontent.com/ySKhT1Qs2zVr9G8t6qd0oZj5PBVp0Jm5BJF8sPHEN-fA9QbykGe-E8Gb88YMvTetCSOzf42RYny8uErs6z4FRMO5b7AOzZBg0HuniA=s128',
        clientId: 'BxNGBEOD7',
        placing: 7,
        roundsParticiapted: 6,
        damage: 7426,
        damageDealt: 44556,
        level: 622,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.41,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 20100000,
          neonGoldStorageTotal: 20500000,
          spaceElixir: 622500,
          neonGold: 632500,
          starDust: 10,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0x593ffa500d70b9F64B057C1D45f5a2986C71D9EC',
        playerName: 'Wrath',
        playerImageKey: 'nft_317575331',
        playerImageUrl:
          'https://lh3.googleusercontent.com/XDd-uFl1tyn51U3gG7zJWCyUxb_XzzpTKGc4IRopGrb_gzeprORGCjI4Qkbhq22SSiRjtRXdR4jqw645tRpz7ao6q2BRi_6IubDMtg=s128',
        clientId: 'tIu6B5BOu',
        placing: 8,
        roundsParticiapted: 6,
        damage: 5849,
        damageDealt: 35094,
        level: 337,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.15,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 3650000,
          neonGoldStorageTotal: 7350000,
          spaceElixir: 211250,
          neonGold: 303750,
          starDust: 10,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0x4571EB475E04608A83D8ebeC81E88DD170583a1B',
        playerName: 'Sir Linkin',
        playerImageKey: 'nft_317575304',
        playerImageUrl:
          'https://lh3.googleusercontent.com/lOnm_jtS40XKhvcwgtVN0xjiJXWDzVRtw8FvHpLFjJCt1oKW7JQuwRq2efNGismMYQ8aRnG9t56ABQExTd5bO5bFS0OmnJk-lSpCkvA=s128',
        clientId: 'wQQG4ORO0',
        placing: 9,
        roundsParticiapted: 2,
        damage: 13703,
        damageDealt: 27406,
        level: 4472,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.45,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 46000000,
          neonGoldStorageTotal: 46000000,
          spaceElixir: 465666,
          neonGold: 465666,
          starDust: 3.66,
        },
        squadUpdated: false,
        brawlerCage: true,
      },
      {
        playerId: '0x2C8745d428e3052fe79eA7CF9FEAb7a4CB167F67',
        playerName: 'Chewbarka',
        playerImageKey: 'nft_375138646',
        playerImageUrl:
          'https://lh3.googleusercontent.com/z_3Ep_vZvMhdQ1b8J6SKM5wDj18cbFkJNB8HzzY8akAXzsirmdeF5QiQFgIXloLi0ybVlT927M_XrbYk1LIjGPtFli121pNrSa3n-w=s128',
        clientId: 'fB0lFZXuf',
        placing: 10,
        roundsParticiapted: 6,
        damage: 3842,
        damageDealt: 23052,
        level: 932,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 10000000,
          neonGoldStorageTotal: 10000000,
          spaceElixir: 370000,
          neonGold: 370000,
          starDust: 10,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0xcBcf5f3f87aaFf8EC6C564A5FE6D34654eB61be4',
        playerName: 'Groovy Lizard',
        playerImageKey: 'nft_59725359',
        playerImageUrl:
          'https://lh3.googleusercontent.com/PEf5I1UlERY36q1kQyfFWob1ifhvkT8Exz76yHJdYDFBRhfVAdcILM17Ep74-WJ0Z2j0TPgiPMwfzkrGE9swUPxOihVed2VQlP0xY7M=s128',
        clientId: 'TClCogL8o',
        placing: 11,
        roundsParticiapted: 6,
        damage: 2417,
        damageDealt: 14502,
        level: 158,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 1650000,
          neonGoldStorageTotal: 1650000,
          spaceElixir: 140625,
          neonGold: 140625,
          starDust: 1.5,
        },
        squadUpdated: true,
        brawlerCage: false,
      },
      {
        playerId: '0xC5924ef2F2D46611bBe352A7d666bF003BD24c5b',
        playerName: 'Toyo Carolla',
        playerImageKey: 'nft_375141934',
        playerImageUrl:
          'https://lh3.googleusercontent.com/su8J_iZ0vnGmY9-_229mKECN_MX696neqEzev5eE4mEfamtcS_ztngFDbIJUXPfnXbhQDUoNsjQqmtYwOSmn9WbqVkUaoeLy0ePmyg=s128',
        clientId: 'jFczDamNJ',
        placing: 12,
        roundsParticiapted: 2,
        damage: 6823,
        damageDealt: 13646,
        level: 1007,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.43,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 33000000,
          neonGoldStorageTotal: 33500000,
          spaceElixir: 177500,
          neonGold: 179583,
          starDust: 0.5,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0x988b9ecAA893F324Bb42fbD8A24b953180d7eF51',
        playerName: 'Miniwolf',
        playerImageKey: 'nft_375141406',
        playerImageUrl:
          'https://lh3.googleusercontent.com/kGO7EvnD8Vt30nLtjCUQWwYdStnvIqLGmBdy1w8WMTJyaAySbqCmlZSuTHEgRnLG2IMSNjirUwqwrdYRQYMorT1BegOxUQtUrgG0=s128',
        clientId: 'Yj-1J08Ui',
        placing: 13,
        roundsParticiapted: 2,
        damage: 6092,
        damageDealt: 12184,
        level: 1089,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 7300000,
          neonGoldStorageTotal: 8190000,
          spaceElixir: 70417,
          neonGold: 74125,
          starDust: 0.5,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0x9F6C02E1124199C9a21D477dAD5eC93552c5E6Ff',
        playerName: 'Linked',
        playerImageKey: 'nft_373651677',
        playerImageUrl:
          'https://lh3.googleusercontent.com/0Z2rK2J5PTDuA4Jmn3jD6oNiJtx4kkoUTKottOUHSDeElyYkosq0WfbWa8kGPghBX69z_t0tKe-A5hrgDX4cWy-ymSDwGFixh9ne=s128',
        clientId: 'BLHYl_NM8',
        placing: 14,
        roundsParticiapted: 2,
        damage: 4840,
        damageDealt: 9680,
        level: 1251,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.23,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 7835000,
          neonGoldStorageTotal: 8640000,
          spaceElixir: 72646,
          neonGold: 76000,
          starDust: 0.5,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
      {
        playerId: '0x7B33918967eb8F48983cEDCAcBe4601cDa2681c3',
        playerName: 'tendies',
        playerImageKey: 'nft_373965365',
        playerImageUrl:
          'https://lh3.googleusercontent.com/cQ_ngOqPOvS6_UahBo92x7MGfmKRlnX8fj7tFNw0zCqjuDUnWvscVSO22lxheZobL0_vu6QR7FNW0K-Nnd0w5Dfmvflp0LI5Ia7nGw=s128',
        clientId: 'E9WAyJcjw',
        placing: 15,
        roundsParticiapted: 2,
        damage: 3650,
        damageDealt: 7300,
        level: 152,
        xp: 500,
        nextXpRank: 0,
        isAttacking: false,
        inactive: false,
        bossFighterTraits: {
          additionalDamagePercentageBoost: 0.08,
          starDustPercentageBoost: 0,
          offChainPercentageBoost: 0,
        },
        payout: {
          spaceElixirStorageTotal: 1473000,
          neonGoldStorageTotal: 1600000,
          spaceElixir: 46138,
          neonGold: 46667,
          starDust: 0.5,
        },
        squadUpdated: false,
        brawlerCage: false,
      },
    ];
  }

  constructor() {
    super({
      key: 'BossFightResultsScene',
    });
  }

  preload() {
    this._participants = BossFightServerService.getInstance().getBattleData().participants;
    // Loads PFPs
    this._participants.forEach(participant => {
      this.load.image(participant.playerImageKey, participant.playerImageUrl);
    });

    // Results Pane
    LoadImageAssets(this, BF_Results_Scene_Assets);
  }

  create() {
    // Gathers Player Data to be displayed
    this._player = this.getPlayerFromParticipants(this._participants);
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  createComponent() {
    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth * this._scale,
      height: modalHeight * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Player Details Pane
    this._playerResultsPane = new BossFightResultsPlayerPane(this, 0, 0, this._player, this._scale);
    this._sizer.add(this._playerResultsPane, {
      key: 'br-player-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    //Add Player Payout Component
    this._playerPayoutPane = new BossFightResultsPayoutPane(this, 0, 0, this._player.payout, this._scale);
    this._sizer.add(this._playerPayoutPane, {
      key: 'br-payout-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(300),
      expand: false,
    });

    // Add Leaders Results Component
    this._leadersPane = new BossFightResultsLeadersPane(this, 0, 0, this._participants, this._scale);
    this._sizer.add(this._leadersPane, {
      key: 'br-leaders-pane',
      align: 'left-top',
      offsetX: this.applyScale(500),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Layout the Scene
    this._sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  createSquadMemberImage(scene, imageKey) {
    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);

    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 3;
    smImageData.destroy();

    var squadMemberImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

    squadMemberImage.displayHeight = scene.applyScale(48);
    squadMemberImage.scaleX = squadMemberImage.scaleY;

    return squadMemberImage;
  }

  destroy() {
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop();
  }

  getPlayerFromParticipants(results: any) {
    var player = undefined;
    for (var i = 0; i < results.length; i++) {
      var participant = results[i];
      if (participant.playerId.toLowerCase() === WalletConnectService.getInstance().getWalletAddress().toLowerCase()) {
        player = participant;
      }
    }
    return player;
  }

  getPlayerFromParticipantsByName(participantName: string) {
    var player = undefined;
    this._participants.forEach(participant => {
      if (participant.playerName === participantName) {
        player = participant;
      }
    });
    return player;
  }

  public newPlayerSelected(participantName: string) {
    var selectedPlayer = this.getPlayerFromParticipantsByName(participantName);
    this._playerResultsPane.destroy();
    this._playerPayoutPane.destroy();

    // Player Details Pane
    this._playerResultsPane = new BossFightResultsPlayerPane(this, 0, 0, selectedPlayer, this._scale);
    this._sizer.add(this._playerResultsPane, {
      key: 'br-player-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    //Add Player Payout Component
    this._playerPayoutPane = new BossFightResultsPayoutPane(this, 0, 0, selectedPlayer?.payout, this._scale);
    this._sizer.add(this._playerPayoutPane, {
      key: 'br-payout-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(300),
      expand: false,
    });

    this._sizer.layout();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BF_Results_Scene_Assets));

    // Unload Player PFPs
    const playerImageKeys: string[] = this._participants.map(participant => participant.playerImageKey);
    UnloadImages(this, playerImageKeys);
  }
}
