import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';

// Pane Details
const paneHeight = 600;
const paneWidth = 500;
const paneRadius = 25;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

// Table Details
const itemWidth = 470,
  itemHeight = 73,
  itemRadius = 13,
  itemColor = 0x463851,
  itemAlpha = 0.3;

// Item Details
const itemGlareHeight = 7,
  itemGlareRadius = 4,
  itemGlareColor = 0xffffff,
  itemGlareAlpha = 0.08;

const categories = {
  damage: 'damage',
  rounds: 'rounds',
  spaceElixir: 'spaceElixir',
  neonGold: 'neonGold',
  starDust: 'starDust',
};

export default class BossFightResultsLeadersPane extends OverlapSizer {
  private _scale: number;

  // UI Elements
  private _damageButton;
  private _roundsButton;
  private _spaceElixirButton;
  private _neonGoldButton;
  private _starDustButton;
  public _selectedCategory;
  private _table;
  private _returnHome;

  // Data
  private _participants;
  private _damages;
  private _rounds;
  private _spaceElixirs;
  private _neonGolds;
  private _starDusts;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._participants = data;

    // Split participants data into sorted lists per category
    this._damages = this.getDamageData(this._participants);
    this._rounds = this.getRoundsData(this._participants);
    this._spaceElixirs = this.getSpaceElixirData(this._participants);
    this._neonGolds = this.getNeonGoldData(this._participants);
    this._starDusts = this.getStarDustData(this._participants);

    this._scale = paneScale;

    scene.input.topOnly = false;
    scene.input.setDefaultCursor('default');
    this._selectedCategory = categories.damage;

    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Add Sizer for content
    var sizer = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Pane Divider
    const dividerLeft = scene.add.image(0, 0, 'battleroyale_results_pane_divider').setScale(this._scale);

    sizer.add(dividerLeft, {
      key: 'br-divider-left',
      align: 'left-top',
      offsetX: this.applyScale(1),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Add Results Text
    var resultsText = scene.add.bitmapText(0, 0, 'cc_outline', 'Leaderboard', scene.applyScale(30), 0);
    sizer.add(resultsText, {
      key: `details-title`,
      align: 'center-top',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(20),
      expand: false,
    });

    // Add Divider Top
    const dividerTop = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

    sizer.add(dividerTop, {
      key: 'br-divider-top',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(70),
      expand: false,
    });

    //Buttons Sizer
    var buttonSizer = scene.rexUI.add.fixWidthSizer({
      width: this.applyScale(paneWidth),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Damage Button
    this._damageButton = new Button(scene, 0, 0, 'bossfight_damage_button', false).setOrigin(0, 0.5).setScale(this._scale);

    this._damageButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.damage;
      this._roundsButton.setTexture('bossfight_rounds_button_inactive');
      this._damageButton.setTexture('bossfight_damage_button');
      this._spaceElixirButton.setTexture('bossfight_space_elixir_button_inactive');
      this._neonGoldButton.setTexture('bossfight_neon_gold_button_inactive');
      this._starDustButton.setTexture('bossfight_star_dust_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._damages, 0, 0, this.scale, categories.damage).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._damageButton), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'placings_button',
    });

    // Rounds Button
    this._roundsButton = new Button(scene, 0, 0, 'bossfight_rounds_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._roundsButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.rounds;
      this._roundsButton.setTexture('bossfight_rounds_button');
      this._damageButton.setTexture('bossfight_damage_button_inactive');
      this._spaceElixirButton.setTexture('bossfight_space_elixir_button_inactive');
      this._neonGoldButton.setTexture('bossfight_neon_gold_button_inactive');
      this._starDustButton.setTexture('bossfight_star_dust_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._rounds, 0, 0, this.scale, categories.rounds).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._roundsButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'kills_button',
    });

    // Space Elixir Button
    this._spaceElixirButton = new Button(scene, 0, 0, 'bossfight_space_elixir_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._spaceElixirButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.spaceElixir;
      this._roundsButton.setTexture('bossfight_rounds_button_inactive');
      this._damageButton.setTexture('bossfight_damage_button_inactive');
      this._spaceElixirButton.setTexture('bossfight_space_elixir_button');
      this._neonGoldButton.setTexture('bossfight_neon_gold_button_inactive');
      this._starDustButton.setTexture('bossfight_star_dust_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._spaceElixirs, 0, 0, this.scale, categories.spaceElixir).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._spaceElixirButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'evades_button',
    });

    // Neon Gold Button
    this._neonGoldButton = new Button(scene, 0, 0, 'bossfight_neon_gold_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._neonGoldButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.neonGold;
      this._roundsButton.setTexture('bossfight_rounds_button_inactive');
      this._damageButton.setTexture('bossfight_damage_button_inactive');
      this._spaceElixirButton.setTexture('bossfight_space_elixir_button_inactive');
      this._neonGoldButton.setTexture('bossfight_neon_gold_button');
      this._starDustButton.setTexture('bossfight_star_dust_button_inactive');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._neonGolds, 0, 0, this.scale, categories.neonGold).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._neonGoldButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'neongold_button',
    });

    // Star Dust Button
    this._starDustButton = new Button(scene, 0, 0, 'bossfight_star_dust_button_inactive', false).setOrigin(0, 0.5).setScale(this._scale);

    this._starDustButton.onClick().subscribe(pointer => {
      this._selectedCategory = categories.starDust;
      this._roundsButton.setTexture('bossfight_rounds_button_inactive');
      this._damageButton.setTexture('bossfight_damage_button_inactive');
      this._spaceElixirButton.setTexture('bossfight_space_elixir_button_inactive');
      this._neonGoldButton.setTexture('bossfight_neon_gold_button_inactive');
      this._starDustButton.setTexture('bossfight_star_dust_button');

      this._table.setAlpha(0);
      sizer.remove(this._table);
      this._table.destroy();
      this._table = undefined;

      this._table = this.createTable(scene, this._starDusts, 0, 0, this.scale, categories.starDust).layout();

      sizer.add(this._table, {
        key: 'results-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(200),
        expand: false,
      });

      sizer.layout();
    });

    buttonSizer.add(scene.add.existing(this._starDustButton), {
      padding: { left: this.applyScale(15), right: 0, top: 0, bottom: 0 },
      key: 'stardust_button',
    });

    sizer.add(scene.add.existing(buttonSizer), {
      padding: { left: 0, right: 0, top: 0, bottom: this.applyScale(18) },
      key: 'button_sizer',
    });

    sizer.add(buttonSizer, {
      key: 'br-button-sizer',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(100),
      expand: false,
    });

    this.add(sizer, {
      key: 'battle-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Add Divider Bottom
    const dividerBottom = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

    sizer.add(dividerBottom, {
      key: 'br-divider-bottom',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(170),
      expand: false,
    });

    // Add Table
    this._table = this.createTable(scene, this._damages, 0, 0, this.scale, categories.damage).layout();
    sizer.add(this._table, {
      key: 'results-table',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(190),
      expand: false,
    });

    // Return Home Button
    this._returnHome = new Button(scene, 0, 0, 'bossfight_victory_return_home_button', true, 1.05).setScale(this._scale);

    this._returnHome.onClick().subscribe(pointer => {
      scene.destroy();
    });

    sizer.add(scene.add.existing(this._returnHome), {
      key: 'return-home-button',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: scene.applyScale(-10),
      expand: false,
    });

    this.layout();
  }

  createTable(scene, data, x, y, scale, category) {
    var tableHeight = (itemHeight + 7) * 4;
    var table = scene.rexUI.add
      .gridTable({
        width: scene.applyScale(paneWidth),
        height: scene.applyScale(tableHeight),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(itemWidth),
          cellHeight: this.applyScale(itemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            participant = cell.item;

          const participantName = participant.playerName;

          var value = '';
          switch (category) {
            case categories.starDust:
            case categories.rounds:
              value = participant.value.toLocaleString('en-us');
              break;
            case categories.damage:
            case categories.spaceElixir:
            case categories.neonGold:
              value = `${Math.floor(parseFloat(participant.value)).toLocaleString('en-us')}`;
          }

          const rectConfig = {
            maskType: 'roundRectangle',
            radius: scene.applyScale(40),
          };

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(20),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(itemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alpha
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )
            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth - 15), // width
                scene.applyScale(itemGlareHeight), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'center-top',
                offsetY: scene.applyScale(7),
                expand: false,
              },
            )
            .add(
              scene.rexUI.add
                .label({
                  orientation: 'x',
                  rtl: false,
                  icon: scene.createSquadMemberImage(scene, participant.playerImageKey).setScale(scene.applyScale(0.3)),
                  iconWidth: scene.applyScale(38),
                  iconHeight: scene.applyScale(38),
                  text: scene.add.bitmapText(0, 0, 'cc_outline', participantName, scene.applyScale(20), 0).setAlpha(participant.inactive ? 0.4 : 1),
                  space: {
                    icon: scene.applyScale(10),
                    right: scene.applyScale(10),
                  },
                  //width: scene.applyScale(130)
                })
                .setInteractive({ useHandCursor: true })
                .on('pointerdown', () => scene.newPlayerSelected(participantName)),
              {
                key: 'item_name_label',
                align: 'left-top',
                offsetX: scene.applyScale(21),
                offsetY: scene.applyScale(24),
                expand: false,
              },
            )

            // Value Display
            .add(scene.add.bitmapText(0, 0, 'cc_outline', value, scene.applyScale(18), 0), {
              key: `value_label`,
              align: 'right-top',
              offsetX: scene.applyScale(-21),
              offsetY: scene.applyScale(32),
              expand: false,
            });
          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  public callback() {}

  private applyScale(length) {
    return length * this._scale;
  }

  getDamageData(participants: any) {
    var damages: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      damages.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.damageDealt,
      });
    });
    // List already sorted on backend by damage dealt
    return damages;
  }

  getRoundsData(participants: any) {
    var rounds: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      rounds.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.roundsParticiapted,
      });
    });

    rounds.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return rounds;
  }

  getSpaceElixirData(participants: any) {
    var spaceElixirs: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      spaceElixirs.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.payout.spaceElixir,
      });
    });

    spaceElixirs.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return spaceElixirs;
  }

  getNeonGoldData(participants: any) {
    var neonGolds: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      neonGolds.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.payout.neonGold,
      });
    });

    neonGolds.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return neonGolds;
  }

  getStarDustData(participants: any) {
    var neonGolds: { playerName: string; playerImageKey: string; value: number }[] = [];
    participants.forEach(participant => {
      neonGolds.push({
        playerName: participant.playerName,
        playerImageKey: participant.playerImageKey,
        value: participant.payout.starDust,
      });
    });

    neonGolds.sort((participant1, participant2) => participant1.value - participant2.value).reverse();
    return neonGolds;
  }

  public getPlayerFromParticipants(results: any, walletId: string) {
    var player = undefined;
    results.forEach(participant => {
      if (participant.playerId.toLowerCase() === walletId.toLowerCase()) {
        player = participant;
      }
    });
    return player;
  }
}

Phaser.GameObjects.GameObjectFactory.register('bossFightResultsLeadersPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightResultsLeadersPane(this.scene, x, y));
});
