import { Client, Room, RoomAvailable } from 'colyseus.js';
import Phaser, { Game } from 'phaser';
import PVPState from '~/../../API/PVP/src/game/PVPState';

import IPVPState, { GameState, Rank, RoundState } from '~/../../API/PVP/src/types/IPVPState';
import ConfigurationService from './ConfigurationService';
import WalletConnectService from './WalletConnectService';
import BotService from '~/services/BotService';
import { isPVPTournament } from '~/utils/GameUtils';
import MachineService from './MachineService';

export default class PVPServerService {
  private static _instance: PVPServerService = new PVPServerService();
  private client: Client;
  private events: Phaser.Events.EventEmitter;
  private room?: Room<IPVPState>;
  private _config: any;
  private _isLocalServer = false;

  constructor() {
    if (PVPServerService._instance) {
      throw new Error('Error: Instantiation failed: Use PVPServerService.getInstance() instead of new.');
    }
    PVPServerService._instance = this;

    if (this._isLocalServer) {
      this.client = new Client('ws://localhost:8001');
    } else {
      this._config = ConfigurationService.getInstance().getConfig();
      const wsEndpoint = this._config.websockets['pvp'];
      if (wsEndpoint) {
        this.client = new Client(`${this._config.websockets.base}${wsEndpoint.path}`);
      }
    }
    this.events = new Phaser.Events.EventEmitter();
  }

  public static getInstance(): PVPServerService {
    return PVPServerService._instance;
  }

  async join(playerData: any) {
    // Prevents player from running pvp games using the same wallet
    var isDuplicateWallet = false;
    await this.client.getAvailableRooms('pvp').then(rooms => {
      rooms.forEach(room => {
        if (room.metadata?.hostWallet === playerData.wallet) {
          isDuplicateWallet = true;
        }
      });
    });

    if (isDuplicateWallet) {
      return;
    }

    // gets player total pvp health
    if (!playerData || !playerData.squadMembers) {
      return Promise.reject();
    }

    var hp = 1000;
    if (!isPVPTournament) {
      hp =
        playerData.squadMembers
          .map(sm => {
            return sm.hp;
          })
          .reduce((previous, current) => {
            return previous + current;
          }) || 1000;
    }

    // Adds 10% Payout for Brawler Cage
    playerData.brawlerCage = MachineService.getInstance().ownsBrawlerCage();

    return this.client
      .joinOrCreate<IPVPState>('pvp', {
        player: playerData,
        maxHealth: hp,
        wallet: WalletConnectService.getInstance().getWalletAddress(),
      })
      .then(room => {
        this.room = room;

        this.room.state.listen('round', () => {
          this.events.emit('round-complete');
        });

        this.room.state.listen('countDown', value => {
          this.events.emit('timer', value);
          if (value == 0) {
            var currentPlayer;
            this.room?.state.players.forEach(player => {
              if (player.wallet === WalletConnectService.getInstance().getWalletAddress()) {
                currentPlayer = player;
              }
            });

            if (currentPlayer.playerAction.playerAction === '') {
              this.playAction('pvpidle');
            }
          }
        });

        this.room.state.listen('botTimer', value => {
          this.events.emit('botTimer', value);
        });

        this.room.state.listen('isBotMatch', isBotMatch => {
          if (isBotMatch && this.room?.state.gameState === GameState.WaitingForOpponent) {
            this.joinBot();
          }
        });

        this.room.state.listen('gameState', value => {
          this.handleGameStateUpdate(value);
        });

        this.room.state.players.onAdd = (player, key) => {
          player.listen('actionValid', () => {
            this.actionSubmitted(player.wallet, player.actionValid);
          });
        };

        return Promise.resolve(this.room);
      });
  }

  joinBot() {
    // Create a PVP Bot to add to the game. Default to my dev account wallet currently
    var _botData = BotService.getInstance().getBotSync();
    _botData.squadMembers = BotService.getInstance().getBotSquadSync();

    // gets player total pvp health
    if (!_botData || !_botData.squadMembers) {
      return Promise.reject();
    }

    var hp = 1000;
    if (!isPVPTournament) {
      hp =
        _botData.squadMembers
          .map(sm => {
            return sm.hp;
          })
          .reduce((previous, current) => {
            return previous + current;
          }) || 1000;
    }

    return this.client.join<IPVPState>('pvp', {
      player: _botData,
      maxHealth: hp,
      wallet: _botData.wallet,
    });
  }

  actionSubmitted(wallet: string, actionValid: boolean) {
    this.events.emit('action-submitted', {
      submitterWallet: wallet,
      isActionValid: actionValid,
    });
  }

  leave() {
    this.room?.leave();
    this.events.removeAllListeners();
  }

  getRoom() {
    return this.room;
  }

  getGameState() {
    return this.room?.state;
  }

  getPVPRoomRank(playerLevel: number) {
    switch (true) {
      case playerLevel <= 10:
        return Rank.Bronze;
      case playerLevel > 10 && playerLevel <= 30:
        return Rank.Silver;
      case playerLevel > 30 && playerLevel <= 50:
        return Rank.Gold;
      case playerLevel > 50 && playerLevel <= 70:
        return Rank.Platinum;
      case playerLevel > 70:
        return Rank.Diamond;
    }
    return Rank.Undefined;
  }

  handleGameStateUpdate(state: number) {
    var player1 = this.room?.state.players.at(0);
    var player2 = this.room?.state.players.at(1);
    switch (state) {
      case GameState.Player1Win:
        this.events.emit('game-over', {
          state: state,
          winningWallet: player1?.wallet,
          neonGoldBoost: typeof player1?.traits.neonGoldBoostValue === 'undefined' ? 1250 : player1?.traits.neonGoldBoostValue,
          spaceElixirBoost: typeof player1?.traits.spaceElixirBoostValue === 'undefined' ? 1250 : player1?.traits.spaceElixirBoostValue,
          starDust:
            typeof player1?.traits.starDustBoostValue === 'undefined'
              ? player2?.isBot
                ? 0
                : 5
              : player2?.isBot
              ? 0
              : player1?.traits.starDustBoostValue,
        });
        break;
      case GameState.Player2Win:
        this.events.emit('game-over', {
          state: state,
          winningWallet: player2?.wallet,
          neonGoldBoost: typeof player2?.traits.neonGoldBoostValue === 'undefined' ? 1500 : player2?.traits.neonGoldBoostValue,
          spaceElixirBoost: typeof player2?.traits.spaceElixirBoostValue === 'undefined' ? 1500 : player2?.traits.spaceElixirBoostValue,
          //starDust: player2?.isBot ? 0 : 5,
          starDust:
            typeof player2?.traits.starDustBoostValue === 'undefined'
              ? player2?.isBot
                ? 0
                : 5
              : player2?.isBot
              ? 0
              : player2?.traits.starDustBoostValue,
        });
        break;
      case GameState.Tie:
        this.events.emit('game-over', { state: state });
        break;
      case GameState.IdleTie:
        this.events.emit('game-over', { state: state });
        break;
      case GameState.Playing:
        this.events.emit('game-started', GameState.Playing);
        break;
      case GameState.WaitingForOpponent:
        this.events.emit('game-waiting', GameState.WaitingForOpponent);
        break;
    }
  }

  playAction(action: string) {
    if (!this.room) {
      return;
    }
    this.room.send('play-action', {
      wallet: WalletConnectService.getInstance().getWalletAddress(),
      playerAction: action,
    });
  }

  onWaitingForOpponent(cb: (state: PVPState) => void, context?: any) {
    this.events.on('game-waiting', cb, context);
  }

  onGameStarted(cb: (state: PVPState) => void, context?: any) {
    this.events.on('game-started', cb, context);
  }

  onRoundComplete(cb: (state: PVPState) => void, context?: any) {
    this.events.on('round-complete', cb, context);
  }

  onGameOver(cb: (gameState: number) => void, context?: any) {
    this.events.on('game-over', cb, context);
  }

  onTimerCountdown(cb: (gameState: number) => void, context?: any) {
    this.events.on('timer', cb, context);
  }

  onActionSubmitted(cb: (state: PVPState) => void, context?: any) {
    this.events.on('action-submitted', cb, context);
  }

  onBotTimerCountdown(cb: (botTimer: number) => void, context?: any) {
    this.events.on('botTimer', cb, context);
  }
}

// attempt at matchmaking ranks
/*var joined = false;

		var roomsAvailable = await (await this.client.getAvailableRooms("pvp")).length;
		console.log("Rooms Available: " +  roomsAvailable)

		if (roomsAvailable != 0) { //we have rooms. need to check if a current room has the right rank to match if not create another room
			this.client.getAvailableRooms("pvp").then(rooms => {
				var joined = false;
				rooms.forEach((room) => {

					console.log("RoomId: " + room.roomId + " Metadata: " + room.metadata?.rank);
					
					if (room.metadata?.rank == 1) { // = players rank
						console.log("Found Ranked Game");
						var room1 = this.client.joinById(room.roomId)
						this.room = room1 as Room<IPVPState>;
					}

					/*if (room.metadata?.rank == 1) {
						console.log("Found Ranked Game");
						//this.client.joinById(room.roomId);

						this.client.joinById(room.roomId).then(room => {
							console.log("joinById");
							var joinedRoom = room as Room<IPVPState>;

							joinedRoom.state.onChange = (changes) => {
								changes.forEach(change => {
									const { field, value } = change
									console.log(change)
									switch (field) {
										case 'roundState':
											if (value == RoundState.Complete) {
												this.events.emit('round-complete', this.room?.state)
											}
											break;
					
										case 'gameState':
											this.handleGameStateUpdate(value)
											break;
					
										case 'countDown':
											this.events.emit("timer", value)
											if (value == 0) {
												if (this.room?.state.player1Action.playerAction == '') {
													this.playAction('pvpidle')
												} else if (this.room?.state.player2Action.playerAction == '') {
													this.playAction('pvpidle')
												}
											}
											break;
					
									}
								})

							}

							this.events.emit('game-started', GameState.Playing)
							return;

							//joinedRoom.state.gameState = GameState.Playing;

							//this.room? = room;
							//room.state.gameState = GameState.Playing;
						});
						joined = true;				
						return;
					}			
				})

				if (!joined) {
					this.createRoom(2);
				}

			})
		} else { // room size == 0 so create room
			console.log("No room available")
			this.createRoom(1);
		}*/
