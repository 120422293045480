import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import MiningService, { Reward } from '~/services/MiningService';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import { Mining_Result_Scene_Assets } from '~/utils/AssetLoader';
import { applyXPBoost, getMiningTraits } from '~/utils/TraitUtils';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 760;

export default class MiningResultScene extends Phaser.Scene {
  private _scale;
  private _reward: any;
  private _traits;

  constructor() {
    super({
      key: 'MiningResultScene',
    });
  }

  preload() {
    LoadImageAssets(this, Mining_Result_Scene_Assets);
  }

  init() {
    MiningService.getInstance().claimLastMinedReward();
    this._traits = SquadService.getInstance().getTraitsSync();
  }

  create() {
    const { height, width } = this.rexUI.viewport;

    this._reward = MiningService.getInstance().getLastMinedRewardSync();
    // Apply Boost to XP Value for UI Display only
    this._reward.xp_earned = applyXPBoost(this._reward.xp_earned, getMiningTraits(this._traits).xpBoost);
    this._scale = ScaleService.getInstance().getScale(width, height);

    const returnHomeButton = new Button(this, 0, 0, 'mining_return_home_button');

    this.add.existing(returnHomeButton);

    returnHomeButton.onClick().subscribe(() => {
      Promise.all([MiningService.getInstance().getMiningStatus(), MiningService.getInstance().getLastMinedReward()]).then(() => {
        this.scene.start('HomebaseScene');
        this.scene.launch('HudScene');
        this.unloadAssets();
        this.scene.stop();
      });
    });

    const spaceElixirLabel = new RewardLabel(
      this,
      0,
      0,
      this._scale,
      'space_elixir',
      new Number(this._reward.space_elixir_reward).toLocaleString('en-us'),
    );

    const neonGoldLabel = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', new Number(this._reward.neon_gold_reward).toLocaleString('en-us'));

    const stardustLabel = new RewardLabel(this, 0, 0, this._scale, 'stardust', new Number(this._reward.stardust_reward).toLocaleString('en-us'));

    const experienceLabel = new RewardLabel(this, 0, 0, this._scale, undefined, `${this._reward.xp_earned} xp`);

    this.rexUI.add
      .sizer(width / 2, height / 2, {
        orientation: 'y',
        width,
        height: this.applyScale(modalHeight),
        // space: { item: 85 }
      })
      .add(
        this.rexUI.add
          .sizer(0, 0, { orientation: 'y' })
          .add(this.add.image(0, 0, this._reward.mining_success ? 'mining_success_badge' : 'mining_defeat_badge'), {
            expand: false,
            align: 'center',
            padding: {
              bottom: this.applyScale(-40),
            },
          })
          .add(this.add.image(0, 0, this._reward.mining_success ? 'mining_success_label' : 'mining_defeat_label'), {
            expand: false,
            align: 'center',
          }),
        { align: 'top' },
      )
      .addSpace()
      .add(
        this.rexUI.add
          .sizer(0, 0, {
            orientation: 'y',
            space: { item: this.applyScale(18) },
          })
          .add(this.add.bitmapText(0, 0, 'cc_outline', this._reward.mining_success ? 'You mined: ' : 'Mission cost: ', 20, 1))
          .add(this.add.existing(spaceElixirLabel))
          .add(this.add.existing(neonGoldLabel))
          .add(this.add.existing(stardustLabel))
          .add(this.add.existing(experienceLabel)),
        {
          align: 'center',
        },
      )
      .addSpace()
      .add(returnHomeButton, {
        align: 'bottom',
      })
      .layout();
  }

  private applyScale(length: number) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Mining_Result_Scene_Assets));
  }
}
