import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import NFTService from '~/services/NFTService';
import ScaleService from '~/services/ScaleService';
import StakingService from '~/services/StakingService';
import UserService from '~/services/UserService';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';

const modalWidth = 494;
const modalHeight = 366;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const goldProgressBarColor = 0xdea827;
const pinkProgressBarColor = 0xde2795;
const blueProgressBarColor = 0x00a6d3;
const grayProgressBarColor = 0x9a9a9a;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.14;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const borderWidth = 1;
const borderColor = 0x383d57;
const borderAlpha = 1;
const backgroundColor = 0x201425;
const backgroundAlpha = 0.4;
const radius = 11;

const progressBarHeight = 43;
const progressBarWidth = 240;
const minDaysHeldToStake = 7;

const resources = {
  space_elixir: 'space_elixir',
  neon_gold: 'neon_gold',
  stardust: 'stardust',
};

export default class HpModalScene extends Phaser.Scene {
  private _resource;
  private _scale;

  init(data: any) {
    this._resource = data.resource;
  }

  constructor() {
    super({
      key: 'HpModalScene',
    });
  }

  preload() {}

  create() {
    this.input.setDefaultCursor('default');
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    const userMachines = MachineService.getInstance().getUserMachinesSync();

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight - 80), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    const resourceIcon = this.getResourceIcon();

    // Add Icons
    this.add.image(modalBg.getTopCenter().x, modalBg.getTopLeft().y, resourceIcon).setScale(this.getIconScale() * this._scale);

    // Add Sizer for content
    var sizer = new OverlapSizer(this, {
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight - 80),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    });
    this.add.existing(sizer);

    // HP display
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this.getResourceTitleText(), this.applyScale(18), 1), {
      key: 'hp_title_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(70),
      expand: false,
    });

    const hpProgBar = new ProgressBar(this, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: this.getProgressText(userMachines),
        fontSize: 16,
        offset: 2,
      },
      icon: undefined,
      border: {
        width: borderWidth,
        color: borderColor,
        alpha: borderAlpha,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: progressBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: grayProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: 10,
        progressValue: 0,
        glare: {
          height: glareHeight,
          width: progressBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
          offset: -15,
        },
      },
    });
    this.add.existing(hpProgBar);

    hpProgBar.setProgressBarText('');

    sizer.add(hpProgBar, {
      key: 'hp_prog_bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(98),
      expand: false,
    });

    // Tip Text
    sizer.add(
      this.add
        .bitmapText(0, 0, 'cc_outline', this.getResourceTipText(), this.applyScale(16), 1)
        .setAlpha(0.4)
        .setMaxWidth(this.applyScale(progressBarWidth)),
      {
        key: 'tip_text',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(163),
        expand: false,
      },
    );

    sizer.layout();

    // Add Close Button
    const closeButton = new Button(this, modalBg.getBottomCenter().x, modalBg.getBottomLeft().y, 'modal_close_button', false).setScale(this._scale);
    closeButton.onClick().subscribe(pointer => {
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
      this.scene.stop();
    });
    this.add.existing(closeButton);

    sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this.scene.stop();
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
    });
  }

  private getTotalHPText(userMachines) {
    if (userMachines && userMachines.length > 1) {
      var totalHp = 0;

      userMachines.forEach(mach => {
        if (mach.hitpoints && mach.hitpoints > 0) {
          totalHp += mach.hitpoints;
        }
      });

      return totalHp.toLocaleString('en-us');
    } else {
      return '0';
    }
  }

  private getTotalDPSText(userMachines) {
    if (userMachines && userMachines.length > 1) {
      var totalDps = 0;

      userMachines.forEach(mach => {
        if (mach.dps && mach.dps > 0) {
          totalDps += mach.dps;
        }
      });

      return totalDps.toLocaleString('en-us');
    } else {
      return '0';
    }
  }

  private getResourceIcon() {
    switch (this._resource) {
      case 'dps':
        return 'dps_icon_large';
      default:
        return 'hp_icon_large';
    }
  }

  private getResourceTitleText() {
    switch (this._resource) {
      case 'dps':
        return 'Your Base DPS';
      default:
        return 'Your Base HP';
    }
  }

  private getResourceTipText() {
    switch (this._resource) {
      case 'dps':
        return "TIP: Upgrading or building any defensive machine will increase your Base's DPS!";
      default:
        return "TIP: Upgrading or building any machine will increase your Base's HP!";
    }
  }

  private getProgressText(userMachines: any) {
    switch (this._resource) {
      case 'dps':
        return this.getTotalDPSText(userMachines);
      default:
        return this.getTotalHPText(userMachines);
    }
  }

  private getIconScale() {
    switch (this._resource) {
      case 'dps':
        return 0.85;
      default:
        return 1;
    }
  }

  applyScale(length) {
    return length * this._scale;
  }
}
