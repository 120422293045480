import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import Button from '~/components/buttons/Button';
import { BP_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import LevelProgressBarComponent from '~/components/hud/levelProgressBarComponent';
import BattlePassLevelsPane from './battlePassLevelsPane';
import BattlePassXPComponent from '~/components/battlepass/battlePassXPComponent';
import BattlePassWeeklyRafflePane from './battlePassWeeklyRafflePane';
import BattlePassService from '~/services/BattlePassService';
import UserService from '~/services/UserService';

const width = 1512;
const height = 982;

// Progress Bar Data
const battlePassBarHoverAlpha = 0.8;

export default class BattlePassScene extends Phaser.Scene {
  private _scale;
  private _levelProgressBar;
  private _sizer;

  // Panes
  private _battlePassLevelsPane;
  private _battlePassXP;
  private _weeklyRafflePane;

  // Buttons
  private _battlePassButton;
  private _raffleButton;
  private _homeButton;

  // Images
  private _subImage;
  private _headerText;

  // Images
  private _backgroundImage;

  // Data
  private _userData;
  private _rewards;

  init(data: any) {
    this._userData = data.userData;
  }

  constructor() {
    super({
      key: 'BattlePassScene',
    });
  }

  preload() {
    LoadImageAssets(this, BP_Scene_Assets);
  }

  async create() {
    await BattlePassService.getInstance()
      .getCurrentBattlePassRewards()
      .then(async res => {
        this._rewards = res.rewards;
        var level = this._userData.battlepass_level;

        await BattlePassService.getInstance()
          .getUnclaimedBattlePassRewards()
          .then(res => {
            this.setRewardsClaimed(res.unclaimed_rewards, this._rewards, level);
          });
      });

    // Set Background Color
    this.setBackgroundColour('#5631FF');
    this.createComponent();
  }

  createComponent() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: width * this._scale,
      height: height * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Image
    this._backgroundImage = this.add.image(0, 0, 'battlepass_background').setScale(this._scale);

    this._sizer.add(this._backgroundImage, {
      key: 'bp-background',
      align: 'left-center',
      offsetX: this.applyScale(266),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Level Progress Bar
    this.addLevelBar();

    // Text
    var _subImage = this.add.image(0, 0, 'battlepass_chapter').setScale(this._scale);

    this._sizer.add(_subImage, {
      key: 'bp-chapter',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(175),
      expand: false,
    });

    this._headerText = this.add.bitmapText(0, 0, 'cc_outline', 'SEASON 0', this.applyScale(46), 1);

    this._sizer.add(this._headerText, {
      key: 'header-text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(194),
      expand: false,
    });

    // Buttons
    this._battlePassButton = new Button(this, 0, 0, 'battlepass_active_button', true, 1.05).setScale(this._scale);
    this._battlePassButton.onClick().subscribe(pointer => {
      this.battlePassListener();
    });
    this._battlePassButton.disableInteractive();
    this.add.existing(this._battlePassButton);

    this._sizer.add(this._battlePassButton, {
      key: 'battlepass-button',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(15),
      expand: false,
    });

    // this._raffleButton = new Button(this, 0, 0, 'battlepass_raffle_inactive_button', true, 1.05).setScale(this._scale);
    // this._raffleButton.onClick().subscribe(pointer => {
    //   this.weeklyRaffleListener()
    // });
    // this._raffleButton.disableInteractive()
    // this.add.existing(this._raffleButton);

    // this._sizer.add(this._raffleButton, {
    //   key: 'raffle-button',
    //   align: 'left-top',
    //   offsetX: this.applyScale(749),
    //   offsetY: this.applyScale(20),
    //   expand: false,
    // });

    this._homeButton = new Button(this, 0, 0, 'battlepass_home_button', true, 1.05).setScale(this._scale);
    this._homeButton.onClick().subscribe(pointer => {
      this.setBackgroundColour('#0f0f1f');
      this.unloadAssets();
      this.scene.stop();
      this.scene.start('HomebaseScene');
    });
    this.add.existing(this._homeButton);

    this._sizer.add(this._homeButton, {
      key: 'home-button',
      align: 'left-top',
      offsetX: this.applyScale(18),
      offsetY: this.applyScale(898),
      expand: false,
    });

    // Panes
    this._battlePassLevelsPane = new BattlePassLevelsPane(
      this,
      0,
      0,
      { rewards: this._rewards, level: this._userData.battlepass_level, claimedLevel: undefined, battlepassXP: this._userData.battlepass_xp },
      this._scale,
    );
    this._sizer.add(this._battlePassLevelsPane, {
      key: 'bp-levels-pane',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(381),
      expand: false,
    });

    this._battlePassXP = this.createBattlePassXPComponent();
    this._sizer.add(this._battlePassXP, {
      key: 'xp-component',
      align: 'left-top',
      offsetX: this.applyScale(1185),
      offsetY: this.applyScale(15),
      expand: false,
    });

    // Layout the Scene
    this._sizer.layout();
  }

  createBattlePassXPComponent() {
    const xpComponent = new BattlePassXPComponent(this, 0, 0, this._userData.battlepass_level, this._scale);
    xpComponent
      .setInteractive({ cursor: 'pointer' })
      .on(
        Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
        pointer => {
          this.scene.scene.sound.play('hud-button-hover');
          xpComponent.setAlpha(battlePassBarHoverAlpha);
        },
        this,
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
        xpComponent.setAlpha(1);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
        xpComponent.setAlpha(1);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
        this.scene.get('BattlePassScene').input.enabled = false;
        this.scene.launch('UserBattlePassModalScene', {
          userData: this._userData,
        });
        this.scene.pause();
      });

    return xpComponent;
  }

  addLevelBar(this) {
    if (this._levelProgressBar) {
      this._levelProgressBar.destroy();
    }

    this._levelProgressBar = new LevelProgressBarComponent(this, this.applyScale(132), this.applyScale(43), this._scale, this._userData);

    // if (this._allowProgressBarClicks) {
    //   this._levelProgressBar
    //     .setInteractive({ cursor: 'pointer' })
    //     .on(
    //       Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
    //       pointer => {
    //         this.scene.scene.sound.play('hud-button-hover');
    //         this._levelProgressBar.setAlpha(progressBarHoverAlpha);
    //       },
    //       this,
    //     )
    //     .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
    //       this._levelProgressBar.setAlpha(1);
    //     })
    //     .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
    //       this._levelProgressBar.setAlpha(1);
    //     })
    //     .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
    //       this._levelProgressBar.setAlpha(progressBarHoverAlpha);
    //       if (pointer.getDistance() > 16) {
    //         return;
    //       }
    //       this.scene.get('HomebaseScene').input.enabled = false;
    //       this.scene.launch('UserLevelModalScene', {
    //         userData: this._userData,
    //       });
    //       this.scene.pause();
    //     });
    // }

    this.add.existing(this._levelProgressBar);

    this._sizer.add(this._levelProgressBar, {
      key: 'bp-level-bar',
      align: 'left-top',
      offsetX: this.applyScale(11),
      offsetY: this.applyScale(18),
      expand: false,
    });
  }

  battlePassListener() {
    if (this._battlePassLevelsPane) {
      this._battlePassLevelsPane.hidePane();
      this._sizer.remove(this._battlePassLevelsPane, true); //destroys object
    }

    if (this._weeklyRafflePane) {
      this._weeklyRafflePane.hidePane();
      this._sizer.remove(this._weeklyRafflePane, true); //destroys object
    }

    // Create new instance of Pane
    this._battlePassLevelsPane = new BattlePassLevelsPane(
      this,
      0,
      0,
      { rewards: this._rewards, level: this._userData.battlepass_level, claimedLevel: undefined, battlepassXP: this._userData.battlepass_xp },
      this._scale,
    );
    this.add.existing(this._battlePassLevelsPane);

    // Set UI to Battle Pass
    this._battlePassButton.setTexture('battlepass_active_button');
    this._battlePassButton.disableInteractive();
    this._raffleButton.setTexture('battlepass_raffle_inactive_button');
    this._raffleButton.setInteractive();

    // Update Title and Subtitle

    this._sizer
      .add(this._battlePassLevelsPane, {
        key: 'bp-levels-pane',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(381),
        expand: false,
      })
      .layout();
  }

  weeklyRaffleListener() {
    if (this._battlePassLevelsPane) {
      this._battlePassLevelsPane.hidePane();
      this._sizer.remove(this._battlePassLevelsPane, true); //destroys object
    }

    if (this._weeklyRafflePane) {
      this._weeklyRafflePane.hidePane();
      this._sizer.remove(this._weeklyRafflePane, true); //destroys object
    }

    // Create new instance of Pane
    this._weeklyRafflePane = new BattlePassWeeklyRafflePane(this, 0, 0, {}, this._scale);
    this.add.existing(this._battlePassLevelsPane);

    // Update Actiave and Inactive Textures
    this._battlePassButton.setTexture('battlepass_inactive_button');
    this._battlePassButton.setInteractive();
    this._raffleButton.setTexture('battlepass_raffle_active_button');
    this._raffleButton.disableInteractive();

    // Update Title and Subtitle

    this._sizer
      .add(this._weeklyRafflePane, {
        key: 'bp-levels-pane',
        align: 'left-top',
        offsetX: this.applyScale(328),
        offsetY: this.applyScale(309),
        expand: false,
      })
      .layout();
  }

  private setRewardsClaimed(unclaimedRewards: any, rewards: any, battlepassLevel: number) {
    var unclaimedLevels: string[] = [];
    if (unclaimedRewards.length != 0) {
      unclaimedRewards.forEach(unclaimed => {
        unclaimedLevels.push(unclaimed.level);
      });
    }

    rewards.forEach(reward => {
      if (!unclaimedLevels.includes(reward.level) && reward.level <= battlepassLevel) {
        reward.claimed = 1;
      } else {
        reward.claimed = 0;
      }
    });
  }

  public async refreshBattlepassData(rewardLevel: number) {
    UserService.getInstance()
      .getUserData(true)
      .then(async data => {
        this._userData = data;

        if (this._battlePassXP) {
          this._sizer.remove(this._battlePassXP, true); //destroys object
        }

        // Create new instance of Pane
        this._battlePassXP = this.createBattlePassXPComponent();
        this._sizer
          .add(this._battlePassXP, {
            key: 'xp-component',
            align: 'left-top',
            offsetX: this.applyScale(1185),
            offsetY: this.applyScale(15),
            expand: false,
          })
          .layout();

        await BattlePassService.getInstance()
          .getCurrentBattlePassRewards()
          .then(async res => {
            this._rewards = res.rewards;
            var level = this._userData.battlepass_level;

            await BattlePassService.getInstance()
              .getUnclaimedBattlePassRewards()
              .then(async res => {
                this.setRewardsClaimed(res.unclaimed_rewards, this._rewards, level);

                if (this._battlePassLevelsPane) {
                  this._sizer.remove(this._battlePassLevelsPane, true); //destroys object
                }

                // Create new instance of Pane
                this._battlePassLevelsPane = new BattlePassLevelsPane(
                  this,
                  0,
                  0,
                  {
                    rewards: this._rewards,
                    level: this._userData.battlepass_level,
                    claimedLevel: rewardLevel,
                    battlepassXP: this._userData.battlepass_xp,
                  },
                  this._scale,
                );

                this._sizer
                  .add(this._battlePassLevelsPane, {
                    key: 'bp-levels-pane',
                    align: 'left-top',
                    offsetX: this.applyScale(0),
                    offsetY: this.applyScale(381),
                    expand: false,
                  })
                  .layout();
              });
          });
      });
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  setBackgroundColour(colour: string) {
    document.body.style.backgroundImage = 'none';
    document.body.style.backgroundColor = colour;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BP_Scene_Assets));
  }
}
