export function convertSecondsToCountdown(seconds: number) {
    seconds = Number(seconds)
    var hDisplay = Math.floor(seconds / 3600) + "H "
    var mDisplay = Math.floor((seconds % 3600) / 60) + "M "
    var sDisplay = Math.floor((seconds % 3600) % 60) + "S"
    return hDisplay + mDisplay + sDisplay
}

export function convertSecondsToMinutes(countdown: number) {
    return (
        String(Math.floor(countdown / 60)).padStart(2, "0") +
        ":" +
        String(countdown % 60).padStart(2, "0")
    )
}

export function convertSecondsToHours(seconds: number) {
    return Math.floor(seconds / 3600) + "h"
}

export function shortenName(name: string) {
	if (name.length > 15) {
		var string = name.substring(0, 13)
		return string + "...";
	} else {
		return name
	}
}

import Prando from "prando"
const RNG = new Prando()
export function getRandomNumber(min: number, max: number) {
    return RNG.nextInt(min, max)
}

export function generateRandomBotName() {
    var adjective = ["Manically Depressed", "Ordinary, Menial", "Sesquicentennial", "Lovely Self-willed", "Nominally Servile",
    "Polite Unobtrusive", "Regular Local", "Efficient, Polite", "Dummy Human", "All-powerful Mindless",
    "Successfully Brash", "Stupid Hulking", "Independent Annoying", "Frustratingly Contrary", "Sophisticated Autonomous", "Previously Cold",
    "Ordinary Self-willed","Undifferentiated Cellular","General-purpose","Independent Eccentric","Sophisticated Stubby",
    "Intensely Inconvenient","Flexible Obedient","Berserk Fiery","Heavy-duty","Anti-social","Consciously Cold","Deeply Ambitious"]
    var names = ["Blob", "Wolf", "Bot", "Pup", "Elder", "Hybrid"]
    return adjective[getRandomNumber(0, adjective.length -1)] + " " + names[getRandomNumber(0, names.length -1)];
}

export var bossBattleTraits = [
	{"trait": "Plasma Rifle Mark I", "description": "6% additional damage per round."},
	{"trait": "Plasma Rifle Mark II", "description": "8% additional damage per round."},
	{"trait": "Plasma Rifle Mark Gold", "description": "10% additional damage per round."},
	{"trait": "Wormhole Pilot Goggles", "description": "4% increase in damage per round"},

	{"trait": "Viper Pilot Helmet", "description": "8% increase in damage per round."},
	{"trait": "Star Storm Pilot Helmet", "description": "10% increase in damage per round."},
	{"trait": "Enforcer V2 Headset", "description": "11% increase in damage per round."},
	{"trait": "Lil Fighter Helmet", "description": "12% increase in damage per round."},

	{"trait": "Enforcer Goggles", "description": "15% increase in damage per round."},
	{"trait": "Howl", "description": "3% increase in damage per turn."},
	{"trait": "Gib Rats", "description": "4% increase in damage per turn."},
	{"trait": "Brown Stash", "description": "2% increase in Stardust per successful mission."},

	{"trait": "High Elder", "description": "15% increase in damage per round."},
	{"trait": "Watch Scout Armor Green", "description": "8% increase in damage per round."},
	{"trait": "Watch Scout Armor Blue", "description": "10% increase in damage per round."},
	{"trait": "Genesis", "description": "15% additional damage per round."}
]

export var battleRoyaleTraits = [
	{"trait": "Patroller Helmet White", "description": "4% increased likelihood of successfully hiding for a round."},
	{"trait": "Goo Brain Helmet", "description": "5% increased likelihood of successfully hiding for a round."},
	{"trait": "Halo Effect Goggles Red", "description": "5% increase in successful attack."},
	{"trait": "Halo Effect Goggles White", "description": "6% increase in successful attack."},
	{"trait": "Far Sight Gear", "description": "8% increase in successful attack."},
	{"trait": "Airspeeder Goggles Green", "description": "8% increased likelihood of successfully hiding for a round."},
	{"trait": "Patroller Helmet Gold", "description": "10% increased likelihood of successfully hiding for a round."},
	{"trait": "Millenia Vision Goggles Red", "description": "10% increased likelihood of successfully hiding for a round."},
	{"trait": "Millenia Vision Goggles Green", "description": "10% increased likelihood of successfully hiding for a round."},
	{"trait": "Angry Emoji Goggles", "description": "12% increased likelihood of successfully hiding for a round."},
	{"trait": "Airspeeder Goggles Blue", "description": "12% likelihood of successfully hiding for a round."},
	{"trait": "Forest Regiment Armor", "description": "4% more chance of hiding successfully per turn"},
	{"trait": "Peacekeeper Armor", "description": "7% more chance of hiding successfully per turn"},
	{"trait": "Ambassador Armor", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Shadow Guard Armor", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Earth Fallout", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Water Fallout", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Dark Elder Body", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Young Elder Pink", "description": "10% more chance of hiding successfully per turn"},
	{"trait": "Magma Body", "description": " Heavy Attacks do 10% more damage."},
	{"trait": "Celestial Regiment Armor", "description": " 10% more chance of hiding successfully per turn"},
	{"trait": "Snow Runner Armor", "description": " 10% more chance of hiding successfully per turn"},
	{"trait": "High Elder Body", "description": " 10% more chance of hiding successfully per turn"},
	{"trait": "Chaplain of the Light", "description": " 12% more chance of hiding successfully per turn"},
	{"trait": "Chaplain of the Dark", "description": " 12% more chance of hiding successfully per turn"},
	{"trait": "Water Bearer Body", "description": " 12% more chance of hiding successfully per turn"},
	{"trait": "Skully Body", "description": " 12% more chance of hiding successfully per turn"},
	{"trait": "Sandstone Body", "description": " 12% more chance of hiding successfully per turn"}
]

export const bossRarityImageMapper = {
	"Common" : "bossfight_boss_common",
    "Rare" : "bossfight_boss_rare",
    "Epic" : "bossfight_boss_epic",
    "Legendary" : "bossfight_boss_legendary",
	"Mythical" : "bossfight_boss_mythical",
}

export var isPVPTournament = true
