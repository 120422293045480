import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

// Pane Details
const paneHeight = 300;
const paneWidth = 500;
const paneRadius = 25;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

export default class BattleRoyaleResultsPlayerPane extends OverlapSizer {
  private _scale: number;

  private _playerData;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._playerData = data;
    this._scale = paneScale;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    if (this._playerData != undefined) {
      // Add Sizer for content
      var sizer = scene.rexUI.add.overlapSizer({
        width: scene.applyScale(paneWidth),
        height: scene.applyScale(paneHeight),
        space: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          item: 0,
          line: 0,
        },
        align: 0,
      });

      // Add Results Text
      var title = scene.add.bitmapText(0, 0, 'cc_outline', this._playerData.playerName, scene.applyScale(30), 0);
      sizer.add(title, {
        key: `player-title`,
        align: 'center-top',
        offsetX: scene.applyScale(0),
        offsetY: scene.applyScale(20),
        expand: false,
      });

      // Add Divider Top
      const dividerTop = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

      sizer.add(dividerTop, {
        key: 'br-divider-top',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(70),
        expand: false,
      });

      // Player PFP and Player Name
      var imageData = new Phaser.GameObjects.Image(scene, 0, 0, this._playerData.playerImageKey);
      var imageWidth = imageData.width;

      const rectConfig = {
        maskType: 'roundRectangle',
        radius: Math.ceil(imageWidth / 8),
      };

      var image = scene.add.rexCircleMaskImage(0, 0, this._playerData.playerImageKey, '', rectConfig);
      image.displayHeight = scene.applyScale(179);
      image.scaleX = image.scaleY;
      imageData.destroy();

      sizer.add(scene.add.existing(image), {
        key: 'nft_image',
        align: 'left-top',
        offsetX: scene.applyScale(30),
        offsetY: scene.applyScale(90),
        expand: false,
      });

      // Placing
      var placing = scene.add.bitmapText(0, 0, 'cc_outline', 'Placing: ' + this.getPlacing(this._playerData), scene.applyScale(24), 0);

      sizer.add(placing, {
        key: `placing_label`,
        align: 'left-top',
        offsetX: scene.applyScale(250),
        offsetY: scene.applyScale(90),
        expand: false,
      });

      // Kills
      var kills = scene.add.bitmapText(0, 0, 'cc_outline', 'Kills: ' + this.getKillCount(this._playerData), scene.applyScale(24), 0);

      sizer.add(kills, {
        key: `kills_label`,
        align: 'left-top',
        offsetX: scene.applyScale(250),
        offsetY: scene.applyScale(120),
        expand: false,
      });

      // Evades
      var evades = scene.add.bitmapText(0, 0, 'cc_outline', 'Evades: ' + this.getEvadeCount(this._playerData), scene.applyScale(24), 0);

      sizer.add(evades, {
        key: `evades_label`,
        align: 'left-top',
        offsetX: scene.applyScale(250),
        offsetY: scene.applyScale(150),
        expand: false,
      });

      // Lazes
      var lazes = scene.add.bitmapText(0, 0, 'cc_outline', 'Lazes: ' + this.getLazesCount(this._playerData), scene.applyScale(24), 0);

      sizer.add(lazes, {
        key: `lazes_label`,
        align: 'left-top',
        offsetX: scene.applyScale(250),
        offsetY: scene.applyScale(180),
        expand: false,
      });

      // Suicides
      var suicide = scene.add.bitmapText(0, 0, 'cc_outline', 'Suicides: ' + this.getSuicides(this._playerData), scene.applyScale(24), 0);

      sizer.add(suicide, {
        key: `suisides_label`,
        align: 'left-top',
        offsetX: scene.applyScale(250),
        offsetY: scene.applyScale(210),
        expand: false,
      });

      // Reason of Death

      this.add(sizer, {
        key: 'player-panel',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(0),
        expand: false,
      });
    }

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getPlacing(playerData: any) {
    return playerData.placing;
  }

  private getKillCount(playerData: any) {
    return playerData.kills;
  }

  private getEvadeCount(playerData: any) {
    return playerData.evades;
  }

  private getLazesCount(playerData: any) {
    return playerData.lazes;
  }

  private getSuicides(playerData: any) {
    return playerData.suicides;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battleRoyaleResultsPlayerPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattleRoyaleResultsPlayerPane(this.scene, x, y));
});
