import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import TroopService from '~/services/TroopService';
import { getTimeRemainingFromEndTime, secondsToTime } from '~/utils/TimeUtils';
import Button from '../buttons/Button';
import ProgressBar from '../progressBar/ProgressBar';

const imageHeight = 149;

const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0x440d31;
const backgroundAlpha = 1;
const radius = 7;
const progressBarBorderColor = 0x383d57;
const progressBarBorderWidth = 1;

const progressBarHeight = 22;
const progressBarWidth = imageHeight - 12;
const progressBarMin = 0;
const stakingWaitPeriod = 60 * 60 * 24 * 7; // 7 days in seconds

export default class TrainingTroopComponent extends OverlapSizer {
  private _troopItem;
  private _troopImage;
  private _removeButton;
  private _countBadge;
  private _progressBar;
  private _trainTimeRemaining;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._troopItem = data;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    if (!data.machine_unit_id) {
      if (!data.id) {
        return this.layout();
      }
      // Must be squad member
      return this.createSquadComponent(scene);
    }
    // NFT Image
    var imageData = new Phaser.GameObjects.Image(scene, 0, 0, this.getImageKey());
    var imageWidth = imageData.width;

    const rectConfig = {
      maskType: 'roundRectangle',
      radius: Math.ceil(imageWidth / 8),
    };

    this._troopImage = scene.add.rexCircleMaskImage(0, 0, this.getImageKey(), '', rectConfig);
    this._troopImage.displayHeight = scene.applyScale(imageHeight);
    this._troopImage.scaleX = this._troopImage.scaleY;
    imageData.destroy();

    this.add(this._troopImage, {
      key: 'nft_image',
      align: 'center-top',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(12),
      expand: false,
    });

    // Count Badge
    if (this._troopItem.count > 1) {
      this._countBadge = scene.rexUI.add
        .badgeLabel({
          x: 0,
          y: 0,
          width: scene.applyScale(27),
          height: scene.applyScale(29),
          space: { left: 0, right: 0, top: 0, bottom: 0 },
          background: scene.add.image(0, 0, 'troop_count_circle').setScale(this._scale),
          main: scene.add.bitmapText(0, 0, 'cc_outline', `${this._troopItem.count.toLocaleString('en-us')}`, scene.applyScale(16), 0),
        })
        .layout();

      this.add(scene.add.existing(this._countBadge), {
        key: 'count_badge',
        align: 'left-top',
        offsetX: this.applyScale(-8),
        offsetY: this.applyScale(0),
        expand: false,
      });
    }

    // Remove Button
    this._removeButton = new Button(scene, 0, 0, 'remove_troop_button', false).setScale(this._scale);
    this._removeButton.onClick().subscribe(pointer => {
      this.removeButtonClickHandler(pointer);
    });
    this.add(scene.add.existing(this._removeButton), {
      key: 'remove_button',
      align: 'right-top',
      offsetX: this.applyScale(8),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Progress Bar
    if (this._troopItem.time_until_next_level && this._troopItem.indexByType === 0) {
      const trainableTroops = TroopService.getInstance().getTrainableTroopsByMachineSync(0);
      const troopInfo = trainableTroops.find(tt => {
        return tt.machine_unit_id === this._troopItem.machine_unit_id && tt.level === this._troopItem.base_level;
      });

      const maxValue = troopInfo ? troopInfo.unit_time_to_build : 0;
      this._trainTimeRemaining = getTimeRemainingFromEndTime(this._troopItem.time_until_next_level);
      this._progressBar = this.createProgressBar(scene, this._trainTimeRemaining, maxValue);

      this.add(this._progressBar, {
        key: 'progress_bar',
        align: 'center-bottom',
        offsetX: 0,
        offsetY: this.applyScale(-8),
        expand: false,
      });

      let progressBarIntervalFunction = () => {
        try {
          if (this._trainTimeRemaining <= 0) {
            clearInterval(progressBarInterval);
            scene.updateTrainingPane();
          } else {
            this._trainTimeRemaining--;

            this._trainTimeRemaining = this._trainTimeRemaining < 0 ? 0 : this._trainTimeRemaining;

            this._progressBar.setTitle(secondsToTime(this._trainTimeRemaining));
            this._progressBar.setProgressValue(this, this._trainTimeRemaining);
          }
        } catch (error) {
          clearInterval(progressBarInterval);
        }
      };

      let progressBarInterval = setInterval(progressBarIntervalFunction, 1000);
    }
    return this.layout();
  }

  private createSquadComponent(scene) {
    // NFT Image
    var imageData = new Phaser.GameObjects.Image(scene, 0, 0, this.getImageKey());
    var imageWidth = imageData.width;

    const rectConfig = {
      maskType: 'roundRectangle',
      radius: Math.ceil(imageWidth / 8),
    };

    this._troopImage = scene.add.rexCircleMaskImage(0, 0, this.getImageKey(), '', rectConfig);
    this._troopImage.displayHeight = scene.applyScale(imageHeight);
    this._troopImage.scaleX = this._troopImage.scaleY;
    imageData.destroy();

    this.add(this._troopImage, {
      key: 'nft_image',
      align: 'center-top',
      offsetX: scene.applyScale(0),
      offsetY: scene.applyScale(12),
      expand: false,
    });

    return this.layout();
  }

  private createProgressBar(scene, progressValue, maxValue) {
    // Progress Bar
    const progBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: secondsToTime(progressValue),
          offset: 2,
          fontSize: 20,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: blueProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressBarMin,
          maxValue: maxValue,
          progressValue: progressValue,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
        border: {
          alpha: backgroundAlpha,
          color: progressBarBorderColor,
          width: progressBarBorderWidth,
        },
      },
    );

    return scene.add.existing(progBar);
  }

  private getImageKey() {
    switch (this._troopItem.machine_unit_id) {
      case 1:
        return 'troops_mercenary';
      case 2:
        return 'troops_ars';
      case 3:
        return 'troops_super_swole';
      case 4:
        return 'troops_healer';
      default:
        return this._troopItem.image_key;
    }
  }

  private removeButtonClickHandler(pointer: any) {
    TroopService.getInstance()
      .removeTroop(this._troopItem.user_machine_unit_id)
      .then(res => {
        this.scene.handleTroopRemoved();
      });
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('trainingTroopComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new TrainingTroopComponent(this.scene, x, y, data));
});
