import MiningService, { Asteroid, asteroidRarityColorMapper } from '~/services/MiningService';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import { imageMapper } from '~/scenes/modals/mining_modal/asteroidComponent';
import Button from '~/components/buttons/Button';
import { convertSecondsToCountdown, convertSecondsToHours } from '~/utils/GameUtils';
import { Mining_Details_Scene_Assets } from '~/utils/AssetLoader';
import MiningAsteroidSquadPane from './miningAsteroidSquadPane';
import UserService from '~/services/UserService';
import { getMiningTraits, MiningTraits } from '~/utils/TraitUtils';
import ProgressBar from '~/components/progressBar/ProgressBar';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

type Data = {
  asteroid: Asteroid;
};

const width = 1044;
const height = 731;
const radius = 16;
const color = 0x1c1c2c;

export default class MiningDetailsScene extends Phaser.Scene {
  private _scale!: number;
  private _asteroid!: Asteroid;
  private _player;
  private _miningTraits!: MiningTraits;

  constructor() {
    super({
      key: 'MiningDetailsScene',
    });
  }

  preload() {
    LoadImageAssets(this, Mining_Details_Scene_Assets);
  }

  init(data: Data) {
    this._asteroid = data.asteroid;
    this._player = UserService.getInstance().getUserDataSync();
    this._player.nft_boost = data.asteroid.nft_boost;
    this._player.squadMembers = SquadService.getInstance().getSquadSync();
    this._player.traits = SquadService.getInstance().getTraitsSync();
    this._player.rarity = this._asteroid.asteroid_rarity_name;
    this._miningTraits = getMiningTraits(this._player.traits);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    // @ts-ignore
    // Modal Background
    const modalBackground = this.add.rexRoundRectangle(0, 0, this.applyScale(width), this.applyScale(height), this.applyScale(radius), color);

    const asteroidRaritySizer = this.createAsteroidRaritySizer();
    const asteroidDetailsSizer = this.createAsteroidDetailsSizer();
    const asteroidActionsSizer = this.createAsteroidActionsSizer();

    // @ts-ignore
    const contentContainer = this.rexUI.add
      .sizer(0, 0, {
        space: { item: this.applyScale(29) },
      })
      .add(asteroidRaritySizer)
      .add(asteroidDetailsSizer, {
        expand: true,
      })
      .layout();

    // @ts-ignore
    const containerSizer = this.rexUI.add
      .sizer(0, 0, {
        orientation: 'y',
        space: { item: this.applyScale(29) },
      })
      .add(contentContainer, {
        expand: true,
      })
      .add(asteroidActionsSizer, {
        expand: true,
      })
      .layout();

    // @ts-ignore
    this.rexUI.add
      .overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2)
      .addBackground(modalBackground)
      .add(containerSizer, {
        expand: true,
        padding: {
          left: this.applyScale(29),
          right: this.applyScale(29),
          top: this.applyScale(29),
          bottom: this.applyScale(29),
        },
      })
      .layout();
  }

  private createAsteroidRaritySizer() {
    // @ts-ignore
    const asteroidRarityBackground = this.add.rexRoundRectangle(
      0,
      0,
      this.applyScale(257),
      this.applyScale(563),
      this.applyScale(16),
      asteroidRarityColorMapper[this._asteroid.asteroid_rarity_id],
      1,
    );

    const asteroidRarityImage = this.add
      // @ts-ignore
      .rexCircleMaskImage(0, 0, imageMapper[this._asteroid.asteroid_rarity_id], '')
      .setScale(this._scale);

    const progressBar = new ProgressBar(this.scene.scene, 0, 0, this._scale, {
      title: {
        text: '',
        fontStyle: null,
      },
      progressBar: {
        text: `Time allowed: ${convertSecondsToHours(this._asteroid.mine_time)}`,
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(257 / 1.3),
        height: this.applyScale(29),
        radius: this.applyScale(7),
        alpha: 1,
        color: 0x00a6d3,
        backgroundColor: 0x000000,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 0,
        glare: {
          width: this.applyScale(257 / 1.4),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.17,
        },
      },
    });

    // @ts-ignore
    return this.rexUI.add
      .overlapSizer(0, 0, this.applyScale(257), this.applyScale(563))
      .addBackground(asteroidRarityBackground)
      .add(asteroidRarityImage, {
        align: 'center',
        expand: false,
      })
      .add(progressBar, {
        key: 'asteroid_progress_bar',
        align: 'center-bottom',
        offsetX: 0,
        offsetY: -this.applyScale(23) * 1.1,
        expand: false,
      })
      .layout();
  }

  private createAsteroidDetailsSizer() {
    const timeRequiredLabel = this.createAsteroidDetailsLabel('Estimated time required');
    const riskOfFailureLabel = this.createAsteroidDetailsLabel('Risk of failure');
    const asteroidHPLabel = this.createAsteroidDetailsLabel('Asteroid HP');
    const costToMineLabel = this.createAsteroidDetailsLabel('Cost to mine');

    const timeRequiredValue = this.createAsteroidDetailsValue(convertSecondsToCountdown(this._asteroid.estimated_mine_time_finished));
    const riskOfFailureValue = this.createAsteroidDetailsValue(this._asteroid.failure_level);
    const asteroidHPValue = this.createAsteroidDetailsValue(this._asteroid.asteroid_hp.toLocaleString('en-us'));

    const costToMineValue = this.rexUI.add
      .sizer({
        space: { item: this.applyScale(37) },
      })
      .add(
        this.rexUI.add
          .sizer({ space: { item: this.applyScale(8) } })
          .add(this.rexUI.add.sizer().add(this.add.image(0, 0, 'space_elixir_icon').setScale(0.5)), { align: 'center', expand: true })
          .add(this.add.bitmapText(0, 0, 'cc_outline', this._asteroid.mine_cost_space_elixir.toLocaleString('en-us'), this.applyScale(22), 1), {
            align: 'center',
            expand: true,
          }),
      )
      .add(
        this.rexUI.add
          .sizer({ space: { item: this.applyScale(8) } })
          .add(this.rexUI.add.sizer().add(this.add.image(0, 0, 'neon_gold_icon').setScale(0.5)), { align: 'center', expand: true })
          .add(this.add.bitmapText(0, 0, 'cc_outline', this._asteroid.mine_cost_neon_gold.toLocaleString('en-us'), this.applyScale(22), 1), {
            align: 'center',
            expand: true,
          }),
      )
      .add(
        this.rexUI.add
          .sizer({ space: { item: this.applyScale(8) } })
          .add(this.rexUI.add.sizer().add(this.add.image(0, 0, 'stardust_icon').setScale(0.5)), { align: 'center', expand: true })
          .add(this.add.bitmapText(0, 0, 'cc_outline', this._asteroid.mine_cost_stardust.toLocaleString('en-us'), this.applyScale(22), 1), {
            align: 'center',
            expand: true,
          }),
      );

    const offsetX = this.applyScale(689) / 3;

    // @ts-ignore
    const timeRequired = this.rexUI.add
      .sizer({ orientation: 'y' })
      .add(timeRequiredLabel, { align: 'left' })
      .add(timeRequiredValue, { align: 'left' })
      .layout();

    // @ts-ignore
    const riskOfFailure = this.rexUI.add
      .sizer({ orientation: 'y' })
      .add(riskOfFailureLabel, { align: 'left' })
      .add(riskOfFailureValue, { align: 'left' })
      .layout();

    // @ts-ignore
    const asteroidHP = this.rexUI.add
      .sizer({ orientation: 'y' })
      .add(asteroidHPLabel, { align: 'left' })
      .add(asteroidHPValue, { align: 'left' })
      .layout();

    // @ts-ignore
    const costToMine = this.rexUI.add
      .sizer({ orientation: 'y' })
      .add(costToMineLabel, { align: 'left' })
      .add(costToMineValue, { align: 'left' })
      .layout();

    // @ts-ignore
    const row1 = this.rexUI.add
      .overlapSizer({
        expand: true,
        width: this.applyScale(689),
      })
      .add(timeRequired)
      .add(riskOfFailure, { offsetX: offsetX })
      .add(asteroidHP, { offsetX: offsetX + offsetX });

    // @ts-ignore
    const row2 = this.rexUI.add
      .overlapSizer(0, 0, {
        expand: true,
      })
      .add(costToMine);

    // @ts-ignore
    const row3 = new MiningAsteroidSquadPane(this, 0, 0, this._player, this._scale);

    // @ts-ignore
    return this.rexUI.add
      .sizer({
        orientation: 'y',
        space: { item: this.applyScale(27) },
        width: this.applyScale(689),
      })
      .add(row1, { expand: true })
      .add(row2, { expand: true })
      .add(row3, { expand: true })
      .layout();
  }

  private createAsteroidActionsSizer() {
    // Back to Missions Button
    const backToMissionsButton = new Button(this.scene.scene, 0, 0, 'mining_back_to_missions_button').setScale(this._scale);

    this.add.existing(backToMissionsButton);

    backToMissionsButton.onClick().subscribe(() => {
      this.scene.start('HomebaseScene');
      this.unloadAssets();
      this.scene.stop();
    });

    // Launch Button
    const launchButton = new Button(this.scene.scene, 0, 0, 'mining_launch_button').setScale(this._scale);

    this.add.existing(launchButton);

    launchButton.onClick().subscribe(() => {
      const asteroidId = this._asteroid.asteroid_id;

      MiningService.getInstance()
        .startMiningAsteroid(asteroidId, this._miningTraits)
        .then(() => {
          MiningService.getInstance()
            .getMiningStatus()
            .then(() => {
              this.scene.stop();
              this.unloadAssets();
              this.scene.start('MiningStatusScene', {
                asteroidId,
              });
            });
        });
    });

    // @ts-ignore
    return this.rexUI.add
      .sizer(0, 0)
      .add(backToMissionsButton, {
        align: 'left',
      })
      .addSpace()
      .add(launchButton, {
        align: 'right',
      })
      .layout();
  }

  private createAsteroidDetailsLabel(label: string) {
    return this.add.bitmapText(0, 0, 'cc_outline', label, this.applyScale(16), 1).setTint(0xa3a3b9);
  }

  private createAsteroidDetailsValue(value: string) {
    return this.add.bitmapText(0, 0, 'cc_outline', value, this.applyScale(22));
  }

  private applyScale(length: number) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Mining_Details_Scene_Assets));
  }
}
