import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import { PVP_Entry_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 455;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class PveRewardsScene extends Phaser.Scene {
  private _scale;
  private _matchRound;
  private _roundRewards;

  constructor(reward: any) {
    super({
      key: 'PveRewardsScene',
    });
  }

  preload() {
    LoadImageAssets(this, PVP_Entry_Scene_Assets);
  }

  init(data: any) {
    this._matchRound = data.matchRound;
    this._roundRewards = data.rewards;
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Icons
    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, 'neon_gold_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 - this.applyScale(74), modalBg.getTopLeft().y, 'space_elixir_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 + this.applyScale(80), modalBg.getTopLeft().y, 'stardust_icon_large').setScale(this._scale);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(249),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Round ' + this._matchRound + ' Rewards', this.applyScale(28), 1), {
      padding: {
        left: this.applyScale(42),
        right: 0,
        top: 0,
        bottom: this.applyScale(27),
      },
      key: 'title_text',
    });

    // Add Fee Labels
    const space_elixir_label = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', this._roundRewards.spaceElixir.toLocaleString('en-us'));
    const neon_gold_label = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', this._roundRewards.neonGold.toLocaleString('en-us'));
    const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', this._roundRewards.starDust.toLocaleString('en-us'));

    sizer.add(this.add.existing(space_elixir_label), {
      padding: {
        left: this.applyScale(30),
        right: 0,
        top: 0,
        bottom: this.applyScale(18),
      },
      key: 'space_elixir_label',
    });

    sizer.add(this.add.existing(neon_gold_label), {
      padding: {
        left: this.applyScale(30),
        right: 0,
        top: 0,
        bottom: this.applyScale(18),
      },
      key: 'neon_gold_label',
    });

    sizer.add(this.add.existing(stardust_label), {
      padding: {
        left: this.applyScale(30),
        right: 0,
        top: 0,
        bottom: this.applyScale(35),
      },
      key: 'stardust_label',
    });

    // Add Note
    sizer.add(
      this.add.bitmapText(
        0,
        0,
        'cc_outline',
        'Success!\n You have won round ' + this._matchRound + '\n Select Continue to start round ' + (this._matchRound + 1),
        this.applyScale(19),
        1,
      ),
      {
        padding: {
          left: this.applyScale(2),
          right: 0,
          top: 0,
          bottom: this.applyScale(27),
        },
        key: 'note_text',
      },
    );

    sizer.layout();

    // Add Continue Button - Update UI Element & Set to middle
    const continueButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, 'pve_continue_button', false).setScale(this._scale);
    continueButton.onClick().subscribe(pointer => {
      this.unloadAssets();
      this.scene.stop();
      this.events.emit('pve_continue_event', {});
    });
    this.add.existing(continueButton);
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVP_Entry_Scene_Assets));
  }
}
