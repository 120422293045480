import Phaser, { Scene } from 'phaser'
import ConfigurationService from '~/services/ConfigurationService';
import ProgressBar from '../progressBar/ProgressBar';
import { convertSecondsToCountdown } from "~/utils/GameUtils"

// Timer Progress Bar
const progressBarWidth = 169
const progressBarHeight = 22
const progressBarRadius = 7
const progressBarColor = 0x00A6D3
const progressBarBackgroundColor = 0x440D31
const progressBarBackgroundAlpha = 0.4
const glareHeight = 6
const glareColor = 0xffffff
const glareAlpha = 0.17
const glareOffset = 6
const glareRadius = 3
const progressBarLiveColor = 0x00C170

export default class TimerProgressBarComponent extends ProgressBar
{
    private _config;

	constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, countDown: any, iconScale: number = 0.7)
	{
		super(scene, x, y, paneScale, {
            rtl: false,
            title: {
                text: "",
                    fontStyle: null,
            },
            icon: {
                key: "bossfight_clock_icon",
                space: 8,
                scale: 1,
            },
            border: {
                width: 2,
                color: 0x383d57,
            },
            progressBar: {
                text: convertSecondsToCountdown(countDown),
                textAlign: "left",
                fontStyle: null,
                width: progressBarWidth,
                height: progressBarHeight,
                radius: progressBarRadius,
                color: progressBarColor,
                backgroundColor: progressBarBackgroundColor,
                backgroundAlpha: progressBarBackgroundAlpha,
                minValue: 0, 
                maxValue: countDown,
                progressValue: countDown,
                glare: {
                    width: progressBarWidth - glareOffset,
                    height: glareHeight,
                    radius: glareRadius,
                    color: glareColor,
                    alpha: glareAlpha,
                },
            },
        });

        this._config = ConfigurationService.getInstance().getConfig();

        this.setValues(scene, countDown);
    }

    updateDisplay(scene: Phaser.Scene, countdown: any) {
        this.setProgressValue(scene, countdown,)
        this.updateProgress(scene)
        this.setProgressBarText(convertSecondsToCountdown(countdown))
        
    }

    roundTimerReset(scene: Phaser.Scene, countdown: any) {
        if (countdown) {
            this.setValues(scene, countdown);
        }
    }

    private setValues(scene: Phaser.Scene, countdown: any) {
        this.updateMaxValue(scene, countdown);        
        this.setProgressValue(scene, countdown);
        this.updateProgress(scene)
        this.setProgressBarText(convertSecondsToCountdown(countdown));
    }

    setBattleRoyaleLive() {
        this.setProgressBarText("LIVE!")
        //this.setProgressBarBackgroundColor(progressBarLiveColor)
    }
    
}

Phaser.GameObjects.GameObjectFactory.register('timerProgressBarComponent', function (x: number, y: number, userData: any) {
	// @ts-ignore
    return this.displayList.add(new TimerProgressBarComponent(this.scene, x, y, userData));
});