import Phaser from 'phaser';
import { NumberBar, OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

export default class ProgressBar extends OverlapSizer {
  private rtl: boolean;
  private title: string;
  private titleOffset: number;
  private titleFontSize: number;
  private titleTint: number;
  private titleAlign: OverlapSizer.AlignTypes;
  private titleIcon: string;
  private titleIconScale: number;
  private titleIconSpace: number;
  private iconKey: string;
  private iconSpace: number;
  private iconScale: number;
  private progressBarText: string;
  private progressBarTextAlign: OverlapSizer.AlignTypes;
  private progressBarFontSize: number;
  private progressBarWidth: number;
  private progressBarHeight: number;
  private progressBarRadius: number;
  private progressBarColor: number;
  private progressBarAlpha: number;
  private progressBarBackgroundColor: number;
  private progressBarBackgroundAlpha: number;
  private glareHeight: number;
  private glareWidth: number;
  private glareRadius: number;
  private glareColor: number;
  private glareAlpha: number;
  private glareOffset: number;
  private borderWidth: number;
  private borderColor: number;
  private borderAlpha: number;
  private previewBarColor: number;
  private previewBarWidth: number;
  private previewBarAlpha: number;
  private minValue: number;
  private maxValue: number;
  private progressValue: number;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config: any) {
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: 149 * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this.rtl = config.rtl;
    this.title = config.title.text;
    this.titleOffset = config.title.offset;
    this.titleFontSize = config.title.fontSize;
    this.titleTint = config.title.tint;
    this.titleAlign = config.title.align;
    this.titleIcon = config.title.icon;
    this.titleIconScale = config.title.iconScale;
    this.titleIconSpace = config.title.iconSpace;
    this.iconKey = config.icon ? config.icon.key : '';
    this.iconSpace = config.icon ? config.icon.space : '';
    this.iconScale = config.icon ? config.icon.scale : '';
    this.borderAlpha = config.border?.alpha;
    this.borderColor = config.border?.color;
    this.borderWidth = config.border?.width;
    this.progressBarText = config.progressBar.text;
    this.progressBarTextAlign = config.progressBar.textAlign;
    this.progressBarFontSize = config.progressBar.fontSize;
    this.progressBarWidth = config.progressBar.width;
    this.progressBarHeight = config.progressBar.height;
    this.progressBarRadius = config.progressBar.radius;
    this.progressBarColor = config.progressBar.color;
    this.progressBarAlpha = config.progressBar.alpha;
    this.progressBarBackgroundColor = config.progressBar.backgroundColor;
    this.progressBarBackgroundAlpha = config.progressBar.backgroundAlpha;
    this.minValue = config.progressBar.minValue;
    this.maxValue = config.progressBar.maxValue;
    this.progressValue = config.progressBar.progressValue;
    this.glareHeight = config.progressBar.glare.height;
    this.glareWidth = config.progressBar.glare.width;
    this.glareRadius = config.progressBar.glare.radius;
    this.glareColor = config.progressBar.glare.color;
    this.glareAlpha = config.progressBar.glare.alpha;
    this.glareOffset = config.progressBar.glare.offset;
    this.previewBarWidth = config.previewBar?.width;
    this.previewBarColor = config.previewBar?.color;
    this.previewBarAlpha = config.previewBar?.alpha;

    this.createProgressBar(scene, x, y);
  }

  createProgressBar(scene: Phaser.Scene, x: number, y: number) {
    this.add(
      scene.add
        .rexRoundRectangle(
          0,
          0,
          scene.applyScale(this.progressBarWidth), // width
          scene.applyScale(this.progressBarHeight), // height
          scene.applyScale(this.progressBarRadius), // radius
          this.progressBarBackgroundColor, // fillColor
          this.progressBarBackgroundAlpha, // alpha
        )
        .setStrokeStyle(this.borderWidth ? scene.applyScale(this.borderWidth) : 0, this.borderColor || 0x000000, this.borderAlpha || 0),
      {
        key: 'progress_bar_bg',
        align: this.iconKey === '' ? 'center' : this.rtl ? 'right-center' : 'left-center',
        offsetX: this.iconKey === '' ? 0 : this.rtl ? scene.applyScale(-25) : scene.applyScale(25),
        expand: false,
      },
    );

    if (this.previewBarColor) {
      this.add(
        scene.add.rexRoundRectangle(
          0,
          0,
          scene.applyScale(this.previewBarWidth), // width
          scene.applyScale(this.progressBarHeight), // height
          scene.applyScale(this.progressBarRadius), // radius
          this.previewBarColor, // fillColor
          this.previewBarAlpha || 1, // alpha
        ),
        {
          key: 'preview_bar',
          align: this.iconKey === '' ? 'center' : this.rtl ? 'right-center' : 'left-center',
          offsetX: this.iconKey === '' ? 0 : this.rtl ? scene.applyScale(-25) : scene.applyScale(25),
          expand: false,
        },
      );
    }

    this.add(
      scene.add
        .rexRoundRectangle(
          0,
          0,
          this.getProgressWidth(scene, this.minValue, this.maxValue, this.progressBarWidth, this.progressValue), // width
          scene.applyScale(this.progressBarHeight), // height
          scene.applyScale(this.progressBarRadius), // radius
          this.progressBarColor, // fillColor
          this.progressBarAlpha, // alpha
        )
        .setAlpha(this.progressValue > 0 ? 1 : 0),
      {
        key: 'progress_bar',
        align: this.iconKey === '' ? 'left-center' : this.rtl ? 'right-center' : 'left-center',
        offsetX: this.iconKey === '' ? 0 : this.rtl ? scene.applyScale(-25) : scene.applyScale(25),
        expand: false,
      },
    )
      .add(
        scene.add.rexRoundRectangle(
          0,
          0,
          scene.applyScale(this.glareWidth), // width
          scene.applyScale(this.glareHeight), // height
          scene.applyScale(this.glareRadius), // radius
          this.glareColor, // fillColor
          this.glareAlpha, // alpha
        ),
        {
          key: 'progress_bar_glare',
          align: this.iconKey === '' ? 'center' : this.rtl ? 'right-center' : 'left-center',
          offsetX: this.iconKey === '' ? 0 : this.rtl ? scene.applyScale(-25) : scene.applyScale(25),
          offsetY: scene.applyScale(this.glareOffset || this.progressBarHeight * -0.27),
          expand: false,
        },
      )
      .add(
        scene.rexUI.add.label({
          orientation: 'x',
          rtl: this.rtl,
          icon: this.iconKey === '' ? undefined : scene.add.image(0, 0, this.iconKey).setScale(scene.applyScale(this.iconScale)),
          text: scene.add.bitmapText(0, 0, 'cc_outline', this.progressBarText, scene.applyScale(this.progressBarFontSize || 18), 0),
          space: {
            icon: this.iconSpace,
          },
        }),
        {
          key: 'progress_bar_text',
          align: this.progressBarTextAlign ? this.progressBarTextAlign : this.rtl ? 'right-center' : 'left-center',
          offsetX: 0,
          offsetY: scene.applyScale(1),
          expand: false,
        },
      )
      .add(
        scene.rexUI.add.label({
          orientation: 'x',
          rtl: true,
          icon:
            !this.titleIcon || this.titleIcon === ''
              ? undefined
              : scene.add.image(0, 0, this.titleIcon).setScale(scene.applyScale(this.titleIconScale || 1)),
          text: scene.add
            .bitmapText(0, 0, 'cc_outline', this.title, scene.applyScale(this.titleFontSize || 18), 0)
            .setTint(this.titleTint ?? 0xffffff),
          space: {
            icon: this.titleIconSpace ? this.titleIconSpace : undefined,
          },
        }),
        {
          key: 'progress_bar_title',
          align: this.titleAlign ?? 'center',
          offsetX: this.iconKey === '' ? 0 : this.rtl ? scene.applyScale(-36) : scene.applyScale(36),
          offsetY: scene.applyScale(this.titleOffset || 1 - this.progressBarHeight * 0.67),
          expand: false,
        },
      )
      .layout();
  }

  setTitle(title: string) {
    if (title) {
      this.title = title;
      this.getElement('progress_bar_title')?.setText(title);
      this.layout();
    }
  }

  setIcon(iconImageKey: string) {
    if (iconImageKey) {
      this.iconKey = iconImageKey;
      this.getElement('progress_bar_text')?.setTexture(iconImageKey);
      this.layout();
    }
  }

  setProgressBarText(text: string) {
    if (text) {
      this.progressBarText = text;
      this.getElement('progress_bar_text')?.setText(text);
    }
  }

  setProgressBarColor(color: number) {
    if (color) {
      this.progressBarColor = color;
    }
  }

  setProgressBarBackgroundColor(color: number) {
    if (color) {
      var fillStyle = this.getElement('progress_bar_bg')?.fillStyle;
      this.getElement('progress_bar_bg')?.setFillStyle(fillStyle, color);
      //this.progressBarBackgroundColor = color
      this.layout();
    }
  }

  setProgress(scene: Phaser.Scene, progress: number) {
    if (progress || progress === 0) {
      this.progressValue = progress;
      this.updateProgress(scene);
    }
  }

  setProgressValue(scene: Phaser.Scene, progressValue: number) {
    this.progressValue = progressValue;
    this.getElement('progress_bar')?.setSize(
      this.getProgressWidth(scene, this.minValue, this.maxValue, this.progressBarWidth, this.progressValue),
      scene.applyScale(this.progressBarHeight),
    );
    this.layout();
  }

  updateMinValue(scene: Phaser.Scene, minValue: number) {
    if (minValue) {
      this.minValue = minValue;
      this.updateProgress(scene);
    }
  }

  updateMaxValue(scene: Phaser.Scene, maxValue: number) {
    if (maxValue) {
      this.maxValue = maxValue;
      this.updateProgress(scene);
    }
  }

  updateProgress(scene: Phaser.Scene) {
    this.getElement('progress_bar')?.setSize(
      this.getProgressWidth(scene, this.minValue, this.maxValue, this.progressBarWidth, this.progressValue),
      scene.applyScale(this.progressBarHeight),
    );
    this.getElement('progress_bar')?.setAlpha(this.progressValue > 0 ? 1 : 0);
    if (this.progressBarText !== '') {
      this.getElement('progress_bar_text')?.setText(Math.floor(this.progressValue).toLocaleString('en-us'));
    }
    this.layout();
  }

  private getProgressWidth(scene: Phaser.Scene, min: number, max: number, barWidth: number, progress: number) {
    if (progress && progress > 0) {
      const localProgress = progress - min;
      const range = max - min;
      const percentComplete = localProgress / range;

      const res = Math.ceil(percentComplete * barWidth);
      // console.log(`barWidth: ${barWidth}`);
      // console.log(`progressWidth: ${res}`);
      // console.log(`range: ${range}`);
      // console.log(`percentComplete: ${percentComplete}`);
      // console.log(`localProgress: ${localProgress}`);
      return scene.applyScale(res > barWidth ? barWidth : res);
    } else {
      return 0;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('progressBar', function (x: number, y: number, config: any) {
  // @ts-ignore
  return this.displayList.add(new ProgressBar(this.scene, x, y, config));
});
