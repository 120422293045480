import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';
import util from 'util';
import { MiningTraits } from '~/utils/TraitUtils';
import MachineService from './MachineService';

type Rarity = 'Common' | 'Rare' | 'Epic' | 'Legendary' | 'Mythical';

export type Asteroid = {
  asteroid_id: number;
  asteroid_rarity_id: number;
  asteroid_subrarity_id: number;
  asteroid_hp: number;
  asteroid_starting_hp: number;
  mine_time: number;
  estimated_mine_time_finished: number;
  user_mining_finished_time: number;
  user_mining_started_time: number;
  mine_cost_space_elixir: number;
  mine_cost_neon_gold: number;
  mine_cost_stardust: number;
  reward_mine_cost_multiple: string;
  reward_stardust: number;
  user_currently_mining_id: string;
  xp_reward: number;
  last_rest_time: number;
  asteroid_rarity_name: Rarity;
  nft_boost: number;
  failure_level: string;
};

export type Reward = {
  asteroid_id: number;
  neon_gold_reward: string;
  space_elixir_reward: string;
  stardust_reward: string;
  xp_earned: number;
};

export const asteroidRarityColorMapper = {
  1: 0x38384e,
  2: 0x039be5,
  3: 0xb803e5,
  4: 0xffa000,
  5: 0xc9e8ff,
};

export const asteroidRarityLabelMapper = {
  1: 'Common',
  2: 'Rare',
  3: 'Epic',
  4: 'Legendary',
  5: 'Mythical',
};

// /mining/:user_address/last[?marked=1] - returns reward info for last asteroid mined. Setting marked means info won't be returned again.

export default class MiningService {
  private static _instance: MiningService = new MiningService();
  private _miningStatus;
  private _asteroids;
  private _reward;

  constructor() {
    if (MiningService._instance) {
      throw new Error(`Error: Instatiation failed: use ${MiningService.name}.getInstance() instead of new.`);
    }
    MiningService._instance = this;
  }

  public static getInstance(): MiningService {
    return MiningService._instance;
  }

  public getAllAsteroids(): Promise<Asteroid[]> {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .get('mining', ['all'], [])
      .then(res => {
        this._asteroids = res;
        return res;
      });
  }

  public getAllAsteroidsSync() {
    return this._asteroids;
  }

  public getSixRandomAsteroids(): Promise<Asteroid[]> {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .get('mining', [address, 'picksix'], [])
      .then(res => {
        return res.asteroids;
      });
  }

  public getMiningStatus(): Promise<Asteroid[]> {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .get('mining', [address, 'status'], [])
      .then(res => {
        this._miningStatus = res.asteroids;
        return res.asteroids;
      });
  }

  public getMiningStatusSync(): Asteroid[] {
    return this._miningStatus;
  }

  public getLastMinedReward(): Promise<Reward> {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .get('mining', [address, 'last'], [])
      .then(res => {
        this._reward = res;
        return res;
      });
  }

  public getLastMinedRewardSync(): Reward {
    return this._reward;
  }

  public claimLastMinedReward() {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .get('mining', [address, 'last?marked=1'], [])
      .then(res => res);
  }

  public startMiningAsteroid(asteroidId: number, miningTraits: MiningTraits) {
    const address = WalletConnectService.getInstance().getWalletAddress();
    const dimXBoost = MachineService.getInstance().getDimensionXBoost();

    const params = {
      boost_resources: miningTraits.resourceBoost * 100,
      boost_xp: miningTraits.xpBoost * 100,
      boost_stardust: miningTraits.stardustBoost * 100,
      boost_space_elixir: miningTraits.spaceElixirBoost * 100,
      boost_neon_gold: miningTraits.neonGoldBoost * 100,
      boost_dph: miningTraits.dphBoost * 100,
      boost_dph_common: miningTraits.dphCommonBoost * 100,
      boost_dph_epic: miningTraits.dphEpicBoost * 100,
      boost_mining_efficency: miningTraits.miningEfficiencyBoost * 100 + dimXBoost,
    };
    return ApiProxyService.getInstance()
      .post('mining', [address, 'start', asteroidId], params)
      .then(res => res);
  }

  public cancelMiningAsteroid(asteroidId: number) {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance().post('mining', [address, 'cancel', asteroidId], {});
  }
}
