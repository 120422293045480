// Squad Modals
import squad_text from '../../public/assets/modals/squad_modal/squad_text.png';
import squad_confirm_button from '../../public/assets/modals/squad_modal/squad_confirm_button.png';
import squad_divider_line from '../../public/assets/modals/squad_modal/squad_divider_line.png';

export const Squad_Assets = {
  squad_text,
  squad_confirm_button,
  squad_divider_line,
};

// Objective Modals
import objectives_bg from '../../public/assets/modals/objectives_modal/objectives_bg.png';
import objectives_list_view_panel from '../../public/assets/modals/objectives_modal/list_view_panel.png';
import objectives_text from '../../public/assets/modals/objectives_modal/objectives_text.png';
import open_chest_button from '../../public/assets/modals/objectives_modal/open_chest_button.png';
import objectives_divider_line from '../../public/assets/modals/objectives_modal/objectives_divider_line.png';
import daily_chest_button from '../../public/assets/modals/objectives_modal/daily_chest_button.png';
import sw_genesis_empty from '../../public/assets/modals/objectives_modal/sw_genesis_empty.png';
import sw_generative_empty from '../../public/assets/modals/objectives_modal/sw_generative_empty.png';
import gape_genesis_empty from '../../public/assets/modals/objectives_modal/gape_genesis_empty.png';
import gape_generative_empty from '../../public/assets/modals/objectives_modal/gape_generative_empty.png';
import gape_monke_empty from '../../public/assets/modals/objectives_modal/gape_monke_empty.png';
import buy_one_button from '../../public/assets/modals/objectives_modal/buy_one_button.png';
import objective_stardust_icon from '../../public/assets/modals/objectives_modal/stardust_icon.png';

import os_button from '../../public/assets/modals/objectives_modal/os_button.png';
import objective_connect_button from '../../public/assets/modals/objectives_modal/objective_connect_button.png';
import objective_follow_button from '../../public/assets/modals/objectives_modal/objective_follow_button.png';
import objective_tweet_button from '../../public/assets/modals/objectives_modal/objective_tweet_button.png';
import objective_retweet_button from '../../public/assets/modals/objectives_modal/objective_retweet_button.png';
import objective_read_button from '../../public/assets/modals/objectives_modal/objective_read_button.png';
import objective_view_button from '../../public/assets/modals/objectives_modal/objective_view_button.png';
import objective_open_button from '../../public/assets/modals/objectives_modal/objective_open_button.png';
import objective_like_button from '../../public/assets/modals/objectives_modal/objective_like_button.png';
import open_daily_chest_button from '../../public/assets/modals/objectives_modal/open_daily_chest_button.png';
import open_all_button from '../../public/assets/modals/objectives_modal/open_all_button.png';
import open_all_button_disabled from '../../public/assets/modals/objectives_modal/open_all_button_disabled.png';

export const Objective_Assets = {
  objectives_bg,
  objectives_list_view_panel,
  objectives_text,
  open_chest_button,
  objectives_divider_line,
  daily_chest_button,
  sw_genesis_empty,
  sw_generative_empty,
  gape_genesis_empty,
  gape_generative_empty,
  gape_monke_empty,
  buy_one_button,
  objective_stardust_icon,
  os_button,
  objective_connect_button,
  objective_follow_button,
  objective_tweet_button,
  objective_retweet_button,
  objective_read_button,
  objective_view_button,
  objective_open_button,
  objective_like_button,
  open_daily_chest_button,
  open_all_button,
  open_all_button_disabled,
};

// Modal Assets
import modal_close_button from '../../public/assets/modals/modal_close_button.png';

export const Modal_Assets = {
  modal_close_button,
};

// Login Assets
import login_sw_logo from '../../public/assets/login/star_wolvez_game_logo.png';
import login_sw_logo_mobile from '../../public/assets/login/star_wolvez_game_logo_mobile.png';
import login_game_logo from '../../public/assets/login/the_game_logo.png';
import connect_wallet_button from '../../public/assets/login/connect_wallet_button.png';
import login_divider from '../../public/assets/login/login_divider.png';
import or_image from '../../public/assets/login/or_image.png';
import google_login_button from '../../public/assets/login/google_login_button.png';
import apple_login_button from '../../public/assets/login/apple_login_button.png';
import disclaimer_image from '../../public/assets/login/disclaimer_image.png';
import sw_feed_background from '../../public/assets/login/sw_feed_background.png';
import feed_title_image from '../../public/assets/login/feed_title_image.png';
import login_poster_1 from '../../public/assets/login/login_poster_1.png';
import login_poster_2 from '../../public/assets/login/login_poster_2.png';
import login_poster_3 from '../../public/assets/login/login_poster_3.png';
import login_poster_4 from '../../public/assets/login/login_poster_4.png';
import login_poster_5 from '../../public/assets/login/login_poster_5.png';
import login_poster_6 from '../../public/assets/login/login_poster_6.png';

export const Login_Assets = {
  login_sw_logo,
  login_sw_logo_mobile,
  login_game_logo,
  connect_wallet_button,
  login_divider,
  or_image,
  google_login_button,
  apple_login_button,
  disclaimer_image,
  sw_feed_background,
  feed_title_image,
  login_poster_1,
  login_poster_2,
  login_poster_3,
  login_poster_4,
  login_poster_5,
  login_poster_6,
};

// Audio Assets
import open_all_button_wav from '../../public/assets/audios/open-all-button.wav';
import hud_button_hover_wav from '../../public/assets/audios/hud-button-hover.wav';
//import login_button_wav from '../../public/assets/audios/login-button.wav';
//import mission_button_wav from '../../public/assets/audios/mission-button.wav';

// export const Audio_Assets = {
//     open_all_button_wav,
//     hud_button_hover_wav,
//     login_button_wav,
//     mission_button_wav,
// }

// Reward assets
import reward_claim_button from '../../public/assets/dashboard/reward_claim_button.png';
import reward_cancel_button from '../../public/assets/dashboard/reward_cancel_button.png';
import reward_confirm_button from '../../public/assets/dashboard/reward_confirm_button.png';

export const Reward_Assets = {
  reward_claim_button,
  reward_cancel_button,
  reward_confirm_button,
};

// Machines
import machine_info_button from '../../public/assets/homebase/machine_info_button.png';
import machine_upgrade_button from '../../public/assets/homebase/machine_upgrade_button.png';
import machine_speed_up_button from '../../public/assets/homebase/machine_speed_up_button.png';
import machine_activate_button from '../../public/assets/homebase/machine_activate_button.png';
import machine_claim_button from '../../public/assets/homebase/machine_claim_button.png';
import machine_train_button from '../../public/assets/homebase/machine_train_button.png';
import machine_troops_button from '../../public/assets/homebase/machine_troops_button.png';
import machines_mainframe_level_2 from '../../public/assets/homebase/machines/mainframe_level_2_v2.png';
import machines_mainframe_level_6 from '../../public/assets/homebase/machines/mainframe_level_6_v2.png';
import machines_mainframe_level_7 from '../../public/assets/homebase/machines/mainframe_level_7.png';
import machines_mainframe_level_8 from '../../public/assets/homebase/machines/mainframe_level_8.png';
import machines_mainframe_level_9 from '../../public/assets/homebase/machines/mainframe_level_9.png';
import machines_neon_gold_mine_level_10 from '../../public/assets/homebase/machines/neon_gold_mine_level_10.png';
import machines_neon_gold_mine_level_11 from '../../public/assets/homebase/machines/neon_gold_mine_level_11.png';
import machines_neon_gold_mine_level_12 from '../../public/assets/homebase/machines/neon_gold_mine_level_12.png';
import machines_neon_gold_mine_level_13 from '../../public/assets/homebase/machines/neon_gold_mine_level_13.png';
import machines_space_elixir_mine_level_10 from '../../public/assets/homebase/machines/space_elixir_mine_level_10.png';
import machines_space_elixir_mine_level_11 from '../../public/assets/homebase/machines/space_elixir_mine_level_11.png';
import machines_space_elixir_mine_level_12 from '../../public/assets/homebase/machines/space_elixir_mine_level_12.png';
import machines_space_elixir_mine_level_13 from '../../public/assets/homebase/machines/space_elixir_mine_level_13.png';
import machines_neon_gold_storage_level_10 from '../../public/assets/homebase/machines/neon_gold_storage_level_10.png';
import machines_neon_gold_storage_level_11 from '../../public/assets/homebase/machines/neon_gold_storage_level_11.png';
import machines_neon_gold_storage_level_12 from '../../public/assets/homebase/machines/neon_gold_storage_level_12.png';
import machines_neon_gold_storage_level_13 from '../../public/assets/homebase/machines/neon_gold_storage_level_13.png';
import machines_space_elixir_storage_level_10 from '../../public/assets/homebase/machines/space_elixir_storage_level_10.png';
import machines_space_elixir_storage_level_11 from '../../public/assets/homebase/machines/space_elixir_storage_level_11.png';
import machines_space_elixir_storage_level_12 from '../../public/assets/homebase/machines/space_elixir_storage_level_12.png';
import machines_space_elixir_storage_level_13 from '../../public/assets/homebase/machines/space_elixir_storage_level_13.png';
import machines_star_laboratory_level_1 from '../../public/assets/homebase/machines/star_laboratory_level_1.png';
import machines_star_laboratory_level_2 from '../../public/assets/homebase/machines/star_laboratory_level_2.png';
import machines_star_laboratory_level_3 from '../../public/assets/homebase/machines/star_laboratory_level_3.png';
import machines_gasa_embassy_level_1 from '../../public/assets/homebase/machines/gasa_embassy_level_1.png';
import machines_gasa_embassy_level_2 from '../../public/assets/homebase/machines/gasa_embassy_level_2.png';
import machines_gasa_embassy_level_3 from '../../public/assets/homebase/machines/gasa_embassy_level_3.png';
import machines_raijus_grave_level_1 from '../../public/assets/homebase/machines/raijus_grave_level_1.png';
import machines_linkins_armory_level_1 from '../../public/assets/homebase/machines/linkins_armory_level_1.png';
import machines_bench_press_level_1 from '../../public/assets/homebase/machines/bench_press_level_1.png';
import machines_valentines_picnic_level_1 from '../../public/assets/homebase/machines/valentines_picnic_level_1.png';
import machines_gooh_refinery_level_1 from '../../public/assets/homebase/machines/gooh_refinery_level_1_v2.png';
import machines_gooh_refinery_level_2 from '../../public/assets/homebase/machines/gooh_refinery_level_2_v2.png';
import machines_gooh_refinery_level_3 from '../../public/assets/homebase/machines/gooh_refinery_level_3.png';
import machines_alchemist_lab_level_1 from '../../public/assets/homebase/machines/alchemist_lab_level_1.png';
import machines_1st_birthday_cake_level_1 from '../../public/assets/homebase/machines/1st_birthday_cake_level_1_v2.png';
import machines_brawler_cage_level_1 from '../../public/assets/homebase/machines/brawler_cage_level_1.png';
import machines_mother_wolf_statue_level_1 from '../../public/assets/homebase/machines/mother_wolf_statue_level_1.png';
import machines_porcelain_throne_level_1 from '../../public/assets/homebase/machines/porcelain_throne_level_1.png';
import machines_others_receiver_level_1 from '../../public/assets/homebase/machines/others_receiver_level_1.png';
import machines_space_cactus_level_1 from '../../public/assets/homebase/machines/space_cactus_level_1.png';
import machines_plasma_cannon_level_1 from '../../public/assets/homebase/machines/plasma_cannon_level_1.png';
import machines_plasma_cannon_level_2 from '../../public/assets/homebase/machines/plasma_cannon_level_2.png';
import machines_plasma_cannon_level_3 from '../../public/assets/homebase/machines/plasma_cannon_level_3.png';
import machines_plasma_cannon_level_1_skin from '../../public/assets/homebase/machines/plasma_cannon_level_1_skin.png';
import machines_plasma_cannon_level_2_skin from '../../public/assets/homebase/machines/plasma_cannon_level_2_skin.png';
import machines_plasma_cannon_level_3_skin from '../../public/assets/homebase/machines/plasma_cannon_level_3_skin.png';
import machines_little_lemon_tree_level_1 from '../../public/assets/homebase/machines/little_lemon_tree_level_1.png';
import machines_little_lemon_tree_level_2 from '../../public/assets/homebase/machines/little_lemon_tree_level_2.png';
import machines_little_lemon_tree_level_3 from '../../public/assets/homebase/machines/little_lemon_tree_level_3.png';
import machines_aic_lab_level_1 from '../../public/assets/homebase/machines/aic_lab_level_1.png';
import machines_aic_lab_level_2 from '../../public/assets/homebase/machines/aic_lab_level_2.png';
import machines_aic_lab_level_3 from '../../public/assets/homebase/machines/aic_lab_level_3.png';
import machines_mvhq_level_1 from '../../public/assets/homebase/machines/mvhq_level_1.png';
import machines_mvhq_level_2 from '../../public/assets/homebase/machines/mvhq_level_2.png';
import machines_mvhq_level_3 from '../../public/assets/homebase/machines/mvhq_level_3.png';
import machines_mvhq_level_4 from '../../public/assets/homebase/machines/mvhq_level_4.png';
import machines_kaiju_kingmaker_level_1 from '../../public/assets/homebase/machines/kaiju_kingmaker_level_1.png';
import machines_kaiju_kingmaker_level_2 from '../../public/assets/homebase/machines/kaiju_kingmaker_level_2.png';
import machines_kaiju_kingmaker_level_3 from '../../public/assets/homebase/machines/kaiju_kingmaker_level_3.png';
import machines_space_elixir_accelerator_level_1 from '../../public/assets/homebase/machines/space_elixir_accelerator_level_1.png';
import machines_space_elixir_accelerator_level_2 from '../../public/assets/homebase/machines/space_elixir_accelerator_level_2.png';
import machines_space_elixir_accelerator_level_3 from '../../public/assets/homebase/machines/space_elixir_accelerator_level_3.png';
import machines_neon_gold_accelerator_level_1 from '../../public/assets/homebase/machines/neon_gold_accelerator_level_1.png';
import machines_neon_gold_accelerator_level_2 from '../../public/assets/homebase/machines/neon_gold_accelerator_level_2.png';
import machines_neon_gold_accelerator_level_3 from '../../public/assets/homebase/machines/neon_gold_accelerator_level_3.png';
import machines_mercenary_wolvez_hideout_level_1 from '../../public/assets/homebase/machines/mercenary_wolvez_hideout_level_1.png';
import machines_mercenary_wolvez_hideout_level_2 from '../../public/assets/homebase/machines/mercenary_wolvez_hideout_level_2.png';
import machines_mercenary_wolvez_hideout_level_3 from '../../public/assets/homebase/machines/mercenary_wolvez_hideout_level_3.png';
import machines_mercenary_wolvez_hideout_level_4 from '../../public/assets/homebase/machines/mercenary_wolvez_hideout_level_4.png';
import machines_kuma_ancestor_shrine_level_1 from '../../public/assets/homebase/machines/kuma_ancestor_shrine_level_1.png';
import machines_kuma_ancestor_shrine_level_2 from '../../public/assets/homebase/machines/kuma_ancestor_shrine_level_2.png';
import machines_kuma_ancestor_shrine_level_3 from '../../public/assets/homebase/machines/kuma_ancestor_shrine_level_3.png';
import machines_super_swole_squad_department_level_1 from '../../public/assets/homebase/machines/super_swole_squad_department_level_1.png';
import machines_super_swole_squad_department_level_2 from '../../public/assets/homebase/machines/super_swole_squad_department_level_2.png';
import machines_super_swole_squad_department_level_3 from '../../public/assets/homebase/machines/super_swole_squad_department_level_3.png';
import machines_medical_academy_level_1 from '../../public/assets/homebase/machines/medical_academy_level_1.png';
import machines_medical_academy_level_2 from '../../public/assets/homebase/machines/medical_academy_level_2.png';
import machines_medical_academy_level_3 from '../../public/assets/homebase/machines/medical_academy_level_3.png';
import machines_medical_academy_level_4 from '../../public/assets/homebase/machines/medical_academy_level_4.png';
import machines_antirat_squad_department_level_1 from '../../public/assets/homebase/machines/antirat_squad_department_level_1.png';
import machines_antirat_squad_department_level_2 from '../../public/assets/homebase/machines/antirat_squad_department_level_2.png';
import machines_antirat_squad_department_level_3 from '../../public/assets/homebase/machines/antirat_squad_department_level_3.png';
import machines_antirat_squad_department_level_4 from '../../public/assets/homebase/machines/antirat_squad_department_level_4.png';
import machines_laser_cannon_level_1 from '../../public/assets/homebase/machines/machines_laser_cannon_level_1.png';
import machines_laser_cannon_level_2 from '../../public/assets/homebase/machines/machines_laser_cannon_level_2.png';
import machines_laser_cannon_level_3 from '../../public/assets/homebase/machines/machines_laser_cannon_level_3.png';
import machines_laser_cannon_level_4 from '../../public/assets/homebase/machines/machines_laser_cannon_level_4.png';
import machines_photon_shield_level_1 from '../../public/assets/homebase/machines/machines_photon_shield_level_1.png';
import machines_photon_shield_level_2 from '../../public/assets/homebase/machines/machines_photon_shield_level_2.png';
import machines_photon_shield_level_3 from '../../public/assets/homebase/machines/machines_photon_shield_level_3.png';
import machines_plasma_mortar_level_1 from '../../public/assets/homebase/machines/machines_plasma_mortar_level_1.png';
import machines_plasma_mortar_level_2 from '../../public/assets/homebase/machines/machines_plasma_mortar_level_2.png';
import machines_plasma_mortar_level_3 from '../../public/assets/homebase/machines/machines_plasma_mortar_level_3.png';
import machines_shield_battery_level_1 from '../../public/assets/homebase/machines/machines_shield_battery_level_1.png';
import machines_shield_battery_level_2 from '../../public/assets/homebase/machines/machines_shield_battery_level_2.png';
import machines_shield_battery_level_3 from '../../public/assets/homebase/machines/machines_shield_battery_level_3.png';
import machines_saved_souls_level_1 from '../../public/assets/homebase/machines/saved_souls_level_1.png';
import machines_saved_souls_level_2 from '../../public/assets/homebase/machines/saved_souls_level_2.png';
import machines_saved_souls_level_3 from '../../public/assets/homebase/machines/saved_souls_level_3.png';
import machines_sunday_skirmish_trophy_level_1 from '../../public/assets/homebase/machines/machines_sunday_skirmish_trophy.png';
import machines_sunday_skirmish_trophy2_level_1 from '../../public/assets/homebase/machines/machines_sunday_skirmish_trophy2.png';
import machines_christmas_tree_2023_level_1 from '../../public/assets/homebase/machines/machines_christmas_tree_2023.png';
import machines_community_nominee_2023_level_1 from '../../public/assets/homebase/machines/machines_community_nominee_2023_level_1.png';
import machines_community_hero_2023_level_1 from '../../public/assets/homebase/machines/machines_community_hero_2023_level_1.png';
import machines_nine_lives_lounge_level_1 from '../../public/assets/homebase/machines/machines_nine_lives_lounge_level_1.png';
import machines_glfhers_arcade_level_1 from '../../public/assets/homebase/machines/machines_glfhers_arcade_level_1.png';
import machines_pirate_nation_golden_galleon_level_1 from '../../public/assets/homebase/machines/machines_pirate_nation_golden_galleon_level_1.png';
import machines_pirate_nation_wood_galleon_level_1 from '../../public/assets/homebase/machines/machines_pirate_nation_wood_galleon_level_1.png';
import machines_bp_fire_storm_tracker_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_fire_storm_tracker_cosmetic_level_1.png';
import machines_bp_ice_storm_tracker_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_ice_storm_tracker_cosmetic_level_1.png';
import machines_bp_star_wolvez_helmet_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_star_wolvez_helmet_cosmetic_level_1.png';
import machines_bp_water_cooler_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_water_cooler_cosmetic_level_1.png';
import machines_bp_blob_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_blob_cosmetic_level_1.png';
import machines_bp_piece_of_home_cosmetic_level_1 from '../../public/assets/homebase/machines/machines_bp_piece_of_home_cosmetic_level_1.png';
import machine_info_close_button from '../../public/assets/homebase/machine_info_close_button.png';
import machine_info_upgrade_button from '../../public/assets/homebase/machine_info_upgrade_button.png';
import machine_info_speed_up_button from '../../public/assets/homebase/machine_info_speed_up_button.png';
import machine_item_info_divider_long from '../../public/assets/homebase/machine_item_info_divider_long.png';
import machine_item_info_divider_short from '../../public/assets/homebase/machine_item_info_divider_short.png';
import cancel_btn_purple from '../../public/assets/modals/machines_modal/cancel_btn_purple.png';
import blob_room_door from '../../public/assets/homebase/blob_room_door.png';
import exit_room_door from '../../public/assets/homebase/exit_room_door.png';
import blob from '../../public/assets/homebase/blob_sprite.png';

export const Homebase_Assets = {
  machine_info_button,
  machine_upgrade_button,
  machine_speed_up_button,
  machine_activate_button,
  machine_claim_button,
  machine_train_button,
  machine_troops_button,
  machines_mainframe_level_2,
  machines_mainframe_level_6,
  machines_mainframe_level_7,
  machines_mainframe_level_8,
  machines_mainframe_level_9,
  machines_neon_gold_mine_level_10,
  machines_neon_gold_mine_level_11,
  machines_neon_gold_mine_level_12,
  machines_neon_gold_mine_level_13,
  machines_space_elixir_mine_level_10,
  machines_space_elixir_mine_level_11,
  machines_space_elixir_mine_level_12,
  machines_space_elixir_mine_level_13,
  machines_neon_gold_storage_level_10,
  machines_neon_gold_storage_level_11,
  machines_neon_gold_storage_level_12,
  machines_neon_gold_storage_level_13,
  machines_space_elixir_storage_level_10,
  machines_space_elixir_storage_level_11,
  machines_space_elixir_storage_level_12,
  machines_space_elixir_storage_level_13,
  machines_star_laboratory_level_1,
  machines_star_laboratory_level_2,
  machines_star_laboratory_level_3,
  machines_gasa_embassy_level_1,
  machines_gasa_embassy_level_2,
  machines_gasa_embassy_level_3,
  machines_raijus_grave_level_1,
  machines_linkins_armory_level_1,
  machines_bench_press_level_1,
  machines_valentines_picnic_level_1,
  machines_gooh_refinery_level_1,
  machines_gooh_refinery_level_2,
  machines_gooh_refinery_level_3,
  machines_alchemist_lab_level_1,
  machines_1st_birthday_cake_level_1,
  machines_brawler_cage_level_1,
  machines_porcelain_throne_level_1,
  machines_others_receiver_level_1,
  machines_space_cactus_level_1,
  machines_mother_wolf_statue_level_1,
  machines_plasma_cannon_level_1,
  machines_plasma_cannon_level_2,
  machines_plasma_cannon_level_3,
  machines_plasma_cannon_level_1_skin,
  machines_plasma_cannon_level_2_skin,
  machines_plasma_cannon_level_3_skin,
  machines_little_lemon_tree_level_1,
  machines_little_lemon_tree_level_2,
  machines_little_lemon_tree_level_3,
  machines_aic_lab_level_1,
  machines_aic_lab_level_2,
  machines_aic_lab_level_3,
  machines_mvhq_level_1,
  machines_mvhq_level_2,
  machines_mvhq_level_3,
  machines_mvhq_level_4,
  machines_kaiju_kingmaker_level_1,
  machines_kaiju_kingmaker_level_2,
  machines_kaiju_kingmaker_level_3,
  machines_space_elixir_accelerator_level_1,
  machines_space_elixir_accelerator_level_2,
  machines_space_elixir_accelerator_level_3,
  machines_neon_gold_accelerator_level_1,
  machines_neon_gold_accelerator_level_2,
  machines_neon_gold_accelerator_level_3,
  machines_laser_cannon_level_1,
  machines_laser_cannon_level_2,
  machines_laser_cannon_level_3,
  machines_laser_cannon_level_4,
  machines_photon_shield_level_1,
  machines_photon_shield_level_2,
  machines_photon_shield_level_3,
  machines_plasma_mortar_level_1,
  machines_plasma_mortar_level_2,
  machines_plasma_mortar_level_3,
  machines_shield_battery_level_1,
  machines_shield_battery_level_2,
  machines_shield_battery_level_3,
  machine_info_close_button,
  machine_info_upgrade_button,
  machine_info_speed_up_button,
  machine_item_info_divider_long,
  machine_item_info_divider_short,
  cancel_btn_purple,
  blob_room_door,
  exit_room_door,
  blob,
  machines_mercenary_wolvez_hideout_level_1,
  machines_mercenary_wolvez_hideout_level_2,
  machines_mercenary_wolvez_hideout_level_3,
  machines_mercenary_wolvez_hideout_level_4,
  machines_kuma_ancestor_shrine_level_1,
  machines_kuma_ancestor_shrine_level_2,
  machines_kuma_ancestor_shrine_level_3,
  machines_super_swole_squad_department_level_1,
  machines_super_swole_squad_department_level_2,
  machines_super_swole_squad_department_level_3,
  machines_medical_academy_level_1,
  machines_medical_academy_level_2,
  machines_medical_academy_level_3,
  machines_medical_academy_level_4,
  machines_antirat_squad_department_level_1,
  machines_antirat_squad_department_level_2,
  machines_antirat_squad_department_level_3,
  machines_antirat_squad_department_level_4,
  machines_saved_souls_level_1,
  machines_saved_souls_level_2,
  machines_saved_souls_level_3,
  machines_sunday_skirmish_trophy_level_1,
  machines_sunday_skirmish_trophy2_level_1,
  machines_christmas_tree_2023_level_1,
  machines_community_hero_2023_level_1,
  machines_community_nominee_2023_level_1,
  machines_nine_lives_lounge_level_1,
  machines_glfhers_arcade_level_1,
  machines_pirate_nation_golden_galleon_level_1,
  machines_pirate_nation_wood_galleon_level_1,
  machines_bp_fire_storm_tracker_cosmetic_level_1,
  machines_bp_ice_storm_tracker_cosmetic_level_1,
  machines_bp_star_wolvez_helmet_cosmetic_level_1,
  machines_bp_water_cooler_cosmetic_level_1,
  machines_bp_blob_cosmetic_level_1,
  machines_bp_piece_of_home_cosmetic_level_1,
};

// Hud Assets
import level_circle from '../../public/assets/hud/level_circle.png';
import level_text from '../../public/assets/hud/lv_text.png';
import level_icon from '../../public/assets/hud/level_icon.png';
import level_prog_bar from '../../public/assets/hud/level_prog_bar.png';
import level_prog_shine from '../../public/assets/hud/level_prog_shine.png';
import objectives_icon from '../../public/assets/hud/objectives_icon.png';
import objectives_rect from '../../public/assets/hud/objectives_rect.png';
import neon_gold_icon from '../../public/assets/hud/neon_gold_icon.png';
import space_elixir_icon from '../../public/assets/hud/space_elixir_icon.png';
import dark_matter_icon from '../../public/assets/hud/dark_matter_icon.png';
import dark_matter_icon_44 from '../../public/assets/hud/dark_matter_icon_44.png';
import stardust_icon from '../../public/assets/hud/stardust_pink_icon.png';
import stardust_icon_44 from '../../public/assets/hud/stardust_pink_icon_44.png';
import stardust_prog_bar from '../../public/assets/hud/stardust_prog_bar.png';
import stardust_prog_shine from '../../public/assets/hud/stardust_prog_shine.png';
import time_icon from '../../public/assets/hud/time_icon.png';
import trophy_icon_small from '../../public/assets/hud/trophy_icon_small.png';
import hp_icon from '../../public/assets/hud/hp_icon.png';
import dps_icon from '../../public/assets/hud/dps_icon.png';
import add_stardust_button from '../../public/assets/hud/add_stardust_rect.png';
import squad_rect from '../../public/assets/hud/squad_rect.png';
import shop_button from '../../public/assets/hud/shop_button.png';
import mission_rect from '../../public/assets/hud/mission_rect.png';
import mission_default_text from '../../public/assets/hud/mission_default_text.png';
import mission_btn_enabled from '../../public/assets/hud/mission_btn_enabled.png';
import view_mission_btn from '../../public/assets/hud/view_mission_btn.png';
import visit_mission_btn from '../../public/assets/hud/visit_mission_btn.png';
import mission_btn_disabled from '../../public/assets/hud/mission_btn_disabled.png';
import hud_helpdesk_button from '../../public/assets/hud/hud_helpdesk_button.png';
import hud_leaderboard_button from '../../public/assets/hud/hud_leaderboard_button.png';
import hud_rewards_button from '../../public/assets/hud/hud_rewards_button.png';
import hud_army_button from '../../public/assets/hud/hud_army_button.png';
import hud_shop_button from '../../public/assets/hud/hud_shop_button.png';
import hud_build_button from '../../public/assets/hud/hud_build_button.png';
import hud_raid_history_button from '../../public/assets/hud/hud_raid_history_button.png';
import hud_quests_button from '../../public/assets/hud/hud_quests_button.png';
import hand_pointing_7_oclock from '../../public/assets/hud/hand_pointing_7_oclock.png';
import hand_pointing_9_oclock from '../../public/assets/hud/hand_pointing_9_oclock.png';
import hand_pointing_10_oclock from '../../public/assets/hud/hand_pointing_10_oclock.png';
import close_notification_button from '../../public/assets/hud/close_notification_button.png';
import hud_battle_royale_background from '../../public/assets/hud/hud_battle_royale_background.png';
import hud_battle_royale_ready_up_button from '../../public/assets/hud/hud_battle_royale_ready_up_button.png';
import hud_battle_royale_text from '../../public/assets/hud/hud_battle_royale_text.png';
import hud_battle_royale_blue_ready_up_button from '../../public/assets/hud/hud_battle_royale_blue_ready_up_button.png';
import hud_battle_royale_blue_view_button from '../../public/assets/hud/hud_battle_royale_blue_view_button.png';
import hud_battle_royale_view_button from '../../public/assets/hud/hud_battle_royale_view_button.png';
import quests_text from '../../public/assets/hud/quests_text.png';
import leaders_text from '../../public/assets/hud/leaders_text.png';
import help_text from '../../public/assets/hud/help_text.png';
import troops_text from '../../public/assets/hud/troops_text.png';
import shop_text from '../../public/assets/hud/shop_text.png';
import build_text from '../../public/assets/hud/build_text.png';
import raid_history_text from '../../public/assets/hud/raid_history_text.png';
import your_squad_text from '../../public/assets/hud/your_squad_text.png';
import battlepass_text from '../../public/assets/hud/battlepass_text.png';
import hud_battlepass_button from '../../public/assets/hud/hud_battlepass_button.png';
import battle_pass_icon from '../../public/assets/hud/battle_pass_icon_v2.png';

export const Hud_Assets = {
  level_circle,
  level_text,
  level_icon,
  level_prog_bar,
  level_prog_shine,
  objectives_icon,
  objectives_rect,
  neon_gold_icon,
  space_elixir_icon,
  dark_matter_icon,
  dark_matter_icon_44,
  stardust_icon,
  stardust_icon_44,
  time_icon,
  hp_icon,
  dps_icon,
  trophy_icon_small,
  stardust_prog_bar,
  stardust_prog_shine,
  add_stardust_button,
  squad_rect,
  shop_button,
  mission_rect,
  mission_default_text,
  mission_btn_enabled,
  view_mission_btn,
  visit_mission_btn,
  mission_btn_disabled,
  hud_helpdesk_button,
  hud_leaderboard_button,
  hud_rewards_button,
  hud_army_button,
  hud_shop_button,
  hud_build_button,
  hud_raid_history_button,
  hud_quests_button,
  hand_pointing_7_oclock,
  hand_pointing_9_oclock,
  hand_pointing_10_oclock,
  close_notification_button,
  hud_battle_royale_background,
  hud_battle_royale_ready_up_button,
  hud_battle_royale_text,
  hud_battle_royale_blue_ready_up_button,
  hud_battle_royale_blue_view_button,
  hud_battle_royale_view_button,
  quests_text,
  leaders_text,
  help_text,
  troops_text,
  shop_text,
  build_text,
  raid_history_text,
  your_squad_text,
  battlepass_text,
  hud_battlepass_button,
  battle_pass_icon,
};

// Blob Hud Assets
import put_to_sleep_button from '../../public/assets/blob_hud/put_to_sleep_button.png';
import pet_button from '../../public/assets/blob_hud/pet_button.png';
import assign_points_button from '../../public/assets/blob_hud/assign_points_button.png';
import give_food_button from '../../public/assets/blob_hud/give_food_button.png';
import give_medication_button from '../../public/assets/blob_hud/give_medication_button.png';
import blob_hud_image from '../../public/assets/blob_hud/blob_hud_image.png';
import blob_hud_divider_line from '../../public/assets/blob_hud/blob_hud_divider_line.png';
import add_points_button from '../../public/assets/modals/upgrade_blob_modal/add_points_button.png';
import remove_points_button from '../../public/assets/modals/upgrade_blob_modal/remove_points_button.png';
import blob_option_divider_line from '../../public/assets/modals/upgrade_blob_modal/blob_option_divider_line.png';
import battery_icon from '../../public/assets/modals/upgrade_blob_modal/battery_icon.png';
import lock_icon from '../../public/assets/modals/upgrade_blob_modal/lock_icon.png';

export const Blob_Hud_Assets = {
  put_to_sleep_button,
  pet_button,
  assign_points_button,
  give_food_button,
  give_medication_button,
  blob_hud_image,
  blob_hud_divider_line,
  add_points_button,
  remove_points_button,
  blob_option_divider_line,
  battery_icon,
  lock_icon,
};

// Loading
import name_rect from '../../public/assets/loading/name_screen_rect.png';
import name_text from '../../public/assets/loading/name_screen_text.png';
import name_btn_disabled from '../../public/assets/loading/name_screen_btn_disabled.png';
import name_btn_enabled from '../../public/assets/loading/name_screen_btn_enabled.png';
import loading_rect from '../../public/assets/loading/loading_screen_rectangle.png';
import loading_prog_bar from '../../public/assets/loading/loading_screen_prog_bar.png';
import loading_prog_1 from '../../public/assets/loading/loading_screen_prog_1.png';
import loading_prog_2 from '../../public/assets/loading/loading_screen_prog_2.png';
import loading_prog_3 from '../../public/assets/loading/loading_screen_prog_3.png';
import loading_prog_4 from '../../public/assets/loading/loading_screen_prog_4.png';
import loading_prog_5 from '../../public/assets/loading/loading_screen_prog_5.png';
import loading_text_1 from '../../public/assets/loading/load_text_1.png';
import loading_text_2 from '../../public/assets/loading/load_text_2.png';
import loading_text_3 from '../../public/assets/loading/load_text_3.png';
import loading_text_4 from '../../public/assets/loading/load_text_4.png';
import homebase_loading_text from '../../public/assets/loading/homebase_loading_text.png';

export const Loading_Assets = {
  name_rect,
  name_text,
  name_btn_disabled,
  name_btn_enabled,
  loading_rect,
  loading_prog_bar,
  loading_prog_1,
  loading_prog_2,
  loading_prog_3,
  loading_prog_4,
  loading_prog_5,
  loading_text_1,
  loading_text_2,
  loading_text_3,
  loading_text_4,
  homebase_loading_text,
};

// Leaderboards
import leaderboard_icon from '../../public/assets/modals/leaderboard/leaderboard_icon.png';
import leaderboard_divider from '../../public/assets/modals/leaderboard/leaderboard_divider_v2.png';
import leaderboard_pvp_button from '../../public/assets/modals/leaderboard/leaderboard_pvp_button.png';
import leaderboard_pvp_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_pvp_button_inactive.png';
import leaderboard_stardust_button from '../../public/assets/modals/leaderboard/leaderboard_stardust_button.png';
import leaderboard_stardust_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_stardust_button_inactive.png';
import leaderboard_logins_button from '../../public/assets/modals/leaderboard/leaderboard_logins_button.png';
import leaderboard_logins_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_logins_button_inactive.png';
import leaderboard_title from '../../public/assets/modals/leaderboard/leaderboard_title.png';
import leaderboard_pve_button from '../../public/assets/modals/leaderboard/leaderboard_pve_button.png';
import leaderboard_pve_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_pve_button_inactive.png';
import leaderboard_xp_button from '../../public/assets/modals/leaderboard/leaderboard_xp_button.png';
import leaderboard_xp_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_xp_button_inactive.png';
import leaderboard_mining_button from '../../public/assets/modals/leaderboard/leaderboard_mining_button.png';
import leaderboard_mining_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_mining_button_inactive.png';
import leaderboard_base_raid_button from '../../public/assets/modals/leaderboard/leaderboard_base_raid_button.png';
import leaderboard_base_raid_button_inactive from '../../public/assets/modals/leaderboard/leaderboard_base_raid_button_inactive.png';

export const Leaderboard_Assets = {
  leaderboard_icon,
  leaderboard_divider,
  leaderboard_pvp_button,
  leaderboard_pvp_button_inactive,
  leaderboard_stardust_button,
  leaderboard_stardust_button_inactive,
  leaderboard_logins_button,
  leaderboard_logins_button_inactive,
  leaderboard_title,
  leaderboard_pve_button,
  leaderboard_pve_button_inactive,
  leaderboard_xp_button,
  leaderboard_xp_button_inactive,
  leaderboard_mining_button,
  leaderboard_mining_button_inactive,
  leaderboard_base_raid_button,
  leaderboard_base_raid_button_inactive,
  pvp_victory_icon,
};

// Help Desk Model Assets
import guide_changelog_text from '../../public/assets/modals/helpdesk_modal/guide_changelog_text.png';
import guide_game_text from '../../public/assets/modals/helpdesk_modal/guide_game_text.png';
import guide_medium_text from '../../public/assets/modals/helpdesk_modal/guide_medium_text.png';
import guide_wolf_e_dex_text from '../../public/assets/modals/helpdesk_modal/guide_wolf_e_dex_text.png';
import litepaper_1_gameplay_text from '../../public/assets/modals/helpdesk_modal/litepaper_1_gameplay_text.png';
import litepaper_2_tokenomics_text from '../../public/assets/modals/helpdesk_modal/litepaper_2_tokenomics_text.png';
import traits_text from '../../public/assets/modals/helpdesk_modal/traits_text.png';
import partners_text from '../../public/assets/modals/helpdesk_modal/partners_text.png';
import helpdesk_option_bg from '../../public/assets/modals/helpdesk_modal/helpdesk_option_bg.png';
import helpdesk_text from '../../public/assets/modals/helpdesk_modal/helpdesk_text.png';

export const HelpDesk_Assets = {
  guide_changelog_text,
  guide_game_text,
  guide_medium_text,
  guide_wolf_e_dex_text,
  litepaper_1_gameplay_text,
  litepaper_2_tokenomics_text,
  traits_text,
  partners_text,
  helpdesk_option_bg,
  helpdesk_text,
};

// Missions
import missions_text from '../../public/assets/modals/missions_modal/missions_text.png';
import pvp_button_bg from '../../public/assets/modals/missions_modal/pvp_button_bg.png';
import pvp_button_text from '../../public/assets/modals/missions_modal/pvp_button_text.png';
import battle_royale_button_bg from '../../public/assets/modals/missions_modal/battle_royale_button_bg.png';
import battle_royale_button_text from '../../public/assets/modals/missions_modal/battle_royale_button_text.png';
import boss_fight_button_bg from '../../public/assets/modals/missions_modal/boss_fight_button_bg.png';
import boss_fight_button_text from '../../public/assets/modals/missions_modal/boss_fight_button_text.png';
import pve_button_bg from '../../public/assets/modals/missions_modal/pve_button_bg.png';
import pve_button_text from '../../public/assets/modals/missions_modal/pve_button_text.png';
import base_raid_button_bg from '../../public/assets/modals/missions_modal/base_raid_button_bg.png';
import base_raid_button_text from '../../public/assets/modals/missions_modal/base_raid_button_text.png';
import mining_mission_button_bg from '../../public/assets/modals/missions_modal/mining_mission_button_bg.png';
import mining_mission_button_text from '../../public/assets/modals/missions_modal/mining_mission_button_text_v2.png';
import mining_mission_logo_image from '../../public/assets/modals/missions_modal/mining_mission_logo_image.png';

export const Missions_Assets = {
  missions_text,
  pvp_button_bg,
  pvp_button_text,
  battle_royale_button_bg,
  battle_royale_button_text,
  boss_fight_button_bg,
  boss_fight_button_text,
  pve_button_bg,
  pve_button_text,
  base_raid_button_bg,
  base_raid_button_text,
  mining_mission_button_bg,
  mining_mission_button_text,
  mining_mission_logo_image,
};

// Mining
import mining_cancel_dialog_cancel_button from '../../public/assets/missions/mining/mining_cancel_dialog_cancel_button.png';
import mining_cancel_dialog_confirm_button from '../../public/assets/missions/mining/mining_cancel_dialog_confirm_button.png';
import mining_back_to_missions_button from '../../public/assets/missions/mining/mining_back_to_missions_button.png';
import mining_launch_button from '../../public/assets/missions/mining/mining_launch_button.png';
import mining_not_enough_resources_dialog_got_it_button from '../../public/assets/missions/mining/mining_not_enough_resources_dialog_got_it_button.png';
import mining_paw from '../../public/assets/missions/mining/mining_paw.png';
import mining_cancel_mission_button from '../../public/assets/missions/mining/mining_cancel_mission_button.png';
import mining_back_to_homebase_button from '../../public/assets/missions/mining/mining_back_to_homebase_button.png';
import mining_return_home_button from '../../public/assets/missions/mining/mining_return_home_button.png';
import mining_success_badge from '../../public/assets/missions/mining/mining_success_badge.png';
import mining_defeat_badge from '../../public/assets/missions/mining/mining_defeat_badge.png';
import mining_success_label from '../../public/assets/missions/mining/mining_success_label.png';
import mining_defeat_label from '../../public/assets/missions/mining/mining_defeat_label.png';
import common_asteroid from '../../public/assets/modals/mining_modal/common_asteroid.png';
import rare_asteroid from '../../public/assets/modals/mining_modal/rare_asteroid.png';
import epic_asteroid from '../../public/assets/modals/mining_modal/epic_asteroid.png';
import legendary_asteroid from '../../public/assets/modals/mining_modal/legendary_asteroid.png';
import mythical_asteroid from '../../public/assets/modals/mining_modal/mythical_asteroid.png';
import mining_divider from '../../public/assets/missions/mining/mining_divider.png';

export const Mining_Assets = {
  mining_cancel_dialog_cancel_button,
  mining_cancel_dialog_confirm_button,
  mining_back_to_missions_button,
  mining_launch_button,
  mining_not_enough_resources_dialog_got_it_button,
  mining_paw,
  mining_cancel_mission_button,
  mining_back_to_homebase_button,
  mining_return_home_button,
  mining_success_badge,
  mining_defeat_badge,
  mining_success_label,
  mining_defeat_label,
  common_asteroid,
  rare_asteroid,
  epic_asteroid,
  legendary_asteroid,
  mythical_asteroid,
  mining_divider,
};

export const Mining_Modal_Scene_Assets = {
  common_asteroid,
  rare_asteroid,
  epic_asteroid,
  legendary_asteroid,
  mythical_asteroid,
};

export const Mining_Loading_Scene_Assets = {
  loading_rect,
  loading_prog_bar,
  loading_prog_1,
  loading_prog_2,
  loading_prog_3,
  loading_prog_4,
  loading_prog_5,
};

export const Mining_Details_Scene_Assets = {
  mining_back_to_missions_button,
  mining_launch_button,
  common_asteroid,
  rare_asteroid,
  epic_asteroid,
  legendary_asteroid,
  space_elixir_icon,
  stardust_icon,
  neon_gold_icon,
  mining_divider,
};

export const Mining_Status_Scene_Assets = {
  mining_paw,
  mining_cancel_mission_button,
  mining_back_to_homebase_button,
};

export const Mining_Cancel_Scene_Assets = {
  mining_cancel_dialog_cancel_button,
  mining_cancel_dialog_confirm_button,
};

export const Mining_Result_Scene_Assets = {
  mining_return_home_button,
  mining_success_badge,
  mining_success_label,
  mining_defeat_badge,
  mining_defeat_label,
  neon_gold_icon,
  space_elixir_icon,
  stardust_icon,
};

// Profile
import settings_text from '../../public/assets/modals/profile_modal/settings_text.png';
import settings_connect_wallet_button from '../../public/assets/modals/profile_modal/settings_connect_wallet_button.png';
import disconnect_button from '../../public/assets/modals/profile_modal/disconnect_button.png';
import disconnect_button_disabled from '../../public/assets/modals/profile_modal/disconnect_button_disabled.png';
import kick_button_disabled from '../../public/assets/modals/profile_modal/kick_button_disabled.png';
import save_and_exit_button from '../../public/assets/modals/profile_modal/save_and_exit_button.png';
import music_button_active from '../../public/assets/modals/profile_modal/music_button_active.png';
import sound_effects_button_active from '../../public/assets/modals/profile_modal/sound_effects_button_active.png';
import help_pane_divider from '../../public/assets/modals/profile_modal/help_pane_divider.png';
import help_channel_image from '../../public/assets/modals/profile_modal/help_channel_image.png';
import inter_planetary_image from '../../public/assets/modals/profile_modal/inter_planetary_image.png';

export const Profile_Assets = {
  settings_text,
  settings_connect_wallet_button,
  disconnect_button,
  disconnect_button_disabled,
  kick_button_disabled,
  save_and_exit_button,
  music_button_active,
  sound_effects_button_active,
  help_pane_divider,
  help_channel_image,
  inter_planetary_image,
};

// PVP / PVE
import pvp_heavy_attack_button from '../../public/assets/missions/pvp/pvp_heavy_attack_button.png';
import pvp_heavy_attack_button_disabled from '../../public/assets/missions/pvp/pvp_heavy_attack_button_disabled.png';
import pvp_light_attack_button from '../../public/assets/missions/pvp/pvp_light_attack_button.png';
import pvp_light_attack_button_disabled from '../../public/assets/missions/pvp/pvp_light_attack_button_disabled.png';
import pvp_defend_button from '../../public/assets/missions/pvp/pvp_defend_button.png';
import pvp_defend_button_disabled from '../../public/assets/missions/pvp/pvp_defend_button_disabled.png';
import pvp_restore_button from '../../public/assets/missions/pvp/pvp_restore_button.png';
import pvp_restore_button_disabled from '../../public/assets/missions/pvp/pvp_restore_button_disabled.png';
import pvp_heal_button from '../../public/assets/missions/pvp/pvp_heal_button.png';
import pvp_heal_button_disabled from '../../public/assets/missions/pvp/pvp_heal_button_disabled.png';
import pvp_surrender_button from '../../public/assets/missions/pvp/pvp_surrender_button.png';
import pvp_surrender_button_disabled from '../../public/assets/missions/pvp/pvp_surrender_button_disabled.png';
import pvp_player_divider from '../../public/assets/missions/pvp/pvp_player_divider.png';
import pvp_game_divider from '../../public/assets/missions/pvp/pvp_game_divider.png';
import pvp_vertical_divider from '../../public/assets/missions/pvp/pvp_vertical_divider.png';
import pvp_hp_icon from '../../public/assets/missions/pvp/pvp_hp_icon.png';
import pvp_ap_icon from '../../public/assets/missions/pvp/pvp_ap_icon.png';
import cancel_button from '../../public/assets/missions/matchmaking/cancel_button.png';
import pvp_victory_icon from '../../public/assets/missions/pvp/pvp_victory_icon.png';
import pvp_defeat_icon from '../../public/assets/missions/pvp/pvp_defeat_icon.png';
import pvp_draw_icon from '../../public/assets/missions/pvp/pvp_draw_icon.png';
import pvp_victory_icon_label from '../../public/assets/missions/pvp/pvp_victory_icon_label.png';
import pvp_defeat_icon_label from '../../public/assets/missions/pvp/pvp_defeat_icon_label.png';
import pvp_draw_icon_label from '../../public/assets/missions/pvp/pvp_draw_icon_label.png';
import pvp_return_home_button from '../../public/assets/missions/pvp/pvp_return_home_button.png';
import pvp_confirm_button from '../../public/assets/missions/pvp/pvp_confirm_button.png';
import pvp_cancel_button from '../../public/assets/missions/pvp/pvp_cancel_button.png';
import pve_gameover_bg from '../../public/assets/missions/pve/pve_gameover_bg.png';
import pve_victory_bg from '../../public/assets/missions/pve/pve_victory_bg.png';
import pve_continue_button from '../../public/assets/missions/pve/pve_continue_button.png';
import pve_opponent_pfp from '../../public/assets/missions/pve/pve_opponent_pfp.png';

export const PVP_Entry_Scene_Assets = {
  neon_gold_icon_large,
  space_elixir_icon_large,
  stardust_icon_large,
  space_elixir_icon,
  neon_gold_icon,
  stardust_icon,
  pvp_cancel_button,
  pvp_confirm_button,
};

export const PVP_Matchmaking_Scene_Assets = {
  cancel_button,
};

export const PVP_Scene_Assets = {
  cancel_button,
  pvp_return_home_button,
  pvp_confirm_button,
  pvp_cancel_button,
  pve_continue_button,
  pve_opponent_pfp,
  pve_gameover_bg,
  pve_victory_bg,
  pvp_vertical_divider,
  pvp_game_divider,
  pvp_player_divider,
  pvp_hp_icon,
  pvp_ap_icon,
  pvp_heavy_attack_button,
  pvp_heavy_attack_button_disabled,
  pvp_light_attack_button,
  pvp_light_attack_button_disabled,
  pvp_defend_button,
  pvp_defend_button_disabled,
  pvp_restore_button,
  pvp_restore_button_disabled,
  pvp_heal_button,
  pvp_heal_button_disabled,
  pvp_surrender_button,
  pvp_surrender_button_disabled,
};

export const PVP_Surrender_Scene_Assets = {
  pvp_cancel_button,
  pvp_confirm_button,
};

export const PVP_Game_Over_Scene_Assets = {
  pvp_victory_icon,
  pvp_defeat_icon,
  pvp_draw_icon,
  pvp_victory_icon_label,
  pvp_defeat_icon_label,
  pvp_draw_icon_label,
  pvp_return_home_button,
  space_elixir_icon,
  neon_gold_icon,
  stardust_icon,
};

export const PVE_Bot_Matching_Scene_Assets = {
  pve_gameover_bg,
  pve_victory_bg,
};

export const PVE_Game_Over_Scene_Assets = {
  pvp_victory_icon,
  pve_victory_bg,
  pvp_defeat_icon,
  pve_gameover_bg,
  pvp_draw_icon,
  pvp_draw_icon_label,
  pvp_return_home_button,
  space_elixir_icon,
  neon_gold_icon,
  stardust_icon,
};

// Shop
import machines_tab_active from '../../public/assets/modals/shop_modal/machines_tab_active.png';
import item_shop_tab_active from '../../public/assets/modals/shop_modal/item_shop_tab_active.png';
import resources_tab_active from '../../public/assets/modals/shop_modal/resources_tab_active.png';
import machines_tab_inactive from '../../public/assets/modals/shop_modal/machines_tab_inactive.png';
import item_shop_tab_inactive from '../../public/assets/modals/shop_modal/item_shop_tab_inactive.png';
import resources_tab_inactive from '../../public/assets/modals/shop_modal/resources_tab_inactive.png';
import shop_common_resource_box_image from '../../public/assets/modals/shop_modal/shop_items/shop_common_resource_box_image.png';
import shop_epic_resource_box_image from '../../public/assets/modals/shop_modal/shop_items/shop_epic_resource_box_image.png';
import shop_legendary_resource_box_image from '../../public/assets/modals/shop_modal/shop_items/shop_legendary_resource_box_image.png';
import shop_common_ng_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_common_ng_refill_image.png';
import shop_rare_ng_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_rare_ng_refill_image.png';
import shop_epic_ng_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_epic_ng_refill_image.png';
import shop_common_se_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_common_se_refill_image.png';
import shop_rare_se_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_rare_se_refill_image.png';
import shop_epic_se_refill_image from '../../public/assets/modals/shop_modal/shop_items/shop_epic_se_refill_image.png';
import shop_animated_408_image from '../../public/assets/modals/shop_modal/shop_items/shop_animated_408_image.png';
import shop_animated_3299_image from '../../public/assets/modals/shop_modal/shop_items/shop_animated_3299_image.png';
import shop_star_wolvez_hoodie_image from '../../public/assets/modals/shop_modal/shop_items/shop_star_wolvez_hoodie_image.png';
import shop_animation_chip_image from '../../public/assets/modals/shop_modal/shop_items/animation_chip_image.png';
import shop_bk55_chip_image from '../../public/assets/modals/shop_modal/shop_items/shop_bk55_chip_image.png';
import shop_bk55_ii_chip_image from '../../public/assets/modals/shop_modal/shop_items/shop_bk55_ii_chip_image.png';
import shop_fur_69_image from '../../public/assets/modals/shop_modal/shop_items/shop_fur_69_chip_image.png';
import shop_little_lemon_friends_image from '../../public/assets/modals/shop_modal/shop_items/shop_little_lemon_friends_image.png';
import shop_etherorc_allies_image from '../../public/assets/modals/shop_modal/shop_items/shop_etherorc_allies_image.png';
import shop_brawler_bearz_image from '../../public/assets/modals/shop_modal/shop_items/shop_brawler_bearz_image_v2.png';
import shop_back_button from '../../public/assets/modals/shop_modal/shop_back_button.png';
import shop_stake_button from '../../public/assets/modals/shop_modal/shop_stake_button.png';
import shop_buy_swap_button from '../../public/assets/modals/shop_modal/shop_buy_swap_button.png';
import shop_stake_button_inactive from '../../public/assets/modals/shop_modal/shop_stake_button_inactive.png';
import shop_buy_swap_button_inactive from '../../public/assets/modals/shop_modal/shop_buy_swap_button_inactive.png';
import shop_cosmetics_button_inactive from '../../public/assets/modals/shop_modal/shop_cosmetics_button_inactive.png';
import shop_other_button_inactive from '../../public/assets/modals/shop_modal/shop_other_button_inactive.png';
import shop_whitelist_button_inactive from '../../public/assets/modals/shop_modal/shop_whitelist_button_inactive.png';
import shop_cosmetics_button from '../../public/assets/modals/shop_modal/shop_cosmetics_button.png';
import shop_other_button from '../../public/assets/modals/shop_modal/shop_other_button.png';
import shop_whitelist_button from '../../public/assets/modals/shop_modal/shop_whitelist_button.png';
import shop_administrative_button_inactive from '../../public/assets/modals/shop_modal/shop_administrative_button_inactive.png';
import shop_army_button_inactive from '../../public/assets/modals/shop_modal/shop_army_button_inactive.png';
import shop_available_button from '../../public/assets/modals/shop_modal/shop_available_button.png';
import shop_research_button_inactive from '../../public/assets/modals/shop_modal/shop_research_button_inactive.png';
import shop_resources_button_inactive from '../../public/assets/modals/shop_modal/shop_resources_button_inactive.png';
import shop_administrative_button from '../../public/assets/modals/shop_modal/shop_administrative_button.png';
import shop_army_button from '../../public/assets/modals/shop_modal/shop_army_button.png';
import shop_available_button_inactive from '../../public/assets/modals/shop_modal/shop_available_button_inactive.png';
import shop_research_button from '../../public/assets/modals/shop_modal/shop_research_button.png';
import shop_all_button from '../../public/assets/modals/shop_modal/shop_all_button.png';
import shop_all_button_inactive from '../../public/assets/modals/shop_modal/shop_all_button_inactive.png';
import shop_holder_exclusive_button from '../../public/assets/modals/shop_modal/shop_holder_exclusive_button.png';
import shop_holder_exclusive_button_inactive from '../../public/assets/modals/shop_modal/shop_holder_exclusive_button_inactive.png';
import shop_resources_button from '../../public/assets/modals/shop_modal/shop_resources_button.png';
import shop_item_info_button from '../../public/assets/modals/shop_modal/shop_item_info_button.png';
import shop_item_info_letter from '../../public/assets/modals/shop_modal/shop_item_info_letter.png';
import shop_item_info_divider_long from '../../public/assets/modals/shop_modal/shop_item_info_divider_long.png';
import shop_item_info_stardust_progress_bar from '../../public/assets/modals/shop_modal/shop_item_info_stardust_progress_bar.png';
import shop_buy_button from '../../public/assets/modals/shop_modal/shop_buy_button.png';
import shop_coming_soon_button from '../../public/assets/modals/shop_modal/shop_coming_soon_button.png';
import shop_sold_out_button from '../../public/assets/modals/shop_modal/shop_sold_out_button.png';
import shop_nfts_button from '../../public/assets/modals/shop_modal/shop_nfts_button.png';
import shop_nfts_button_inactive from '../../public/assets/modals/shop_modal/shop_nfts_button_inactive.png';
import shop_special_items_button from '../../public/assets/modals/shop_modal/shop_special_items_button.png';
import shop_special_items_button_inactive from '../../public/assets/modals/shop_modal/shop_special_items_button_inactive.png';
import shop_resource_packs_button from '../../public/assets/modals/shop_modal/shop_resource_packs_button.png';
import shop_resource_packs_button_inactive from '../../public/assets/modals/shop_modal/shop_resource_packs_button_inactive.png';
import shop_battlepass_season_0_image from '../../public/assets/modals/shop_modal/shop_items/shop_battlepass_season_0_image.png';

import shop_machines_mainframe from '../../public/assets/modals/shop_modal/machines/shop_machines_mainframe_v2.png';
import shop_machines_neon_gold_mine from '../../public/assets/modals/shop_modal/machines/shop_machines_neon_gold_mine_v2.png';
import shop_machines_neon_gold_storage from '../../public/assets/modals/shop_modal/machines/shop_machines_neon_gold_storage_v2.png';
import shop_machines_space_elixir_mine from '../../public/assets/modals/shop_modal/machines/shop_machines_space_elixir_mine_v2.png';
import shop_machines_space_elixir_storage from '../../public/assets/modals/shop_modal/machines/shop_machines_space_elixir_storage_v2.png';
import shop_machines_star_laboratory from '../../public/assets/modals/shop_modal/machines/shop_machines_star_laboratory_v2.png';
import shop_machines_technician_bunker from '../../public/assets/modals/shop_modal/machines/shop_machines_technician_bunker_v2.png';
import shop_machines_discord_airdrop_trophy from '../../public/assets/modals/shop_modal/machines/shop_machines_discord_airdrop_trophy_v2.png';
import shop_machines_tournament_one_competitor_trophy from '../../public/assets/modals/shop_modal/machines/shop_machines_tournament_one_competitor_trophy_v2.png';
import shop_machines_stardust_mine from '../../public/assets/modals/shop_modal/machines/shop_machines_stardust_miner_v2.png';
import shop_machines_genesis_holder_trophy from '../../public/assets/modals/shop_modal/machines/shop_machines_genesis_holder_trophy_v2.png';
import shop_machines_gasa_embassy from '../../public/assets/modals/shop_modal/machines/shop_machines_gasa_embassy_v2.png';
import shop_machines_christmas_tree_trophy from '../../public/assets/modals/shop_modal/machines/shop_machines_christmas_tree.png';
import shop_machines_beer_machine from '../../public/assets/modals/shop_modal/machines/shop_machines_beer_machine.png';
import shop_machines_raijus_grave from '../../public/assets/modals/shop_modal/machines/shop_machines_raijus_grave.png';
import shop_machines_linkins_armory from '../../public/assets/modals/shop_modal/machines/shop_machines_linkins_armory.png';
import shop_machines_bench_press from '../../public/assets/modals/shop_modal/machines/shop_machines_bench_press.png';
import shop_machines_valentines_picnic from '../../public/assets/modals/shop_modal/machines/shop_machines_valentines_picnic.png';
import shop_machines_gooh_refinery from '../../public/assets/modals/shop_modal/machines/shop_machines_gooh_refinery.png';
import shop_machines_alchemist_lab from '../../public/assets/modals/shop_modal/machines/shop_machines_alchemist_lab.png';
import shop_machines_1st_birthday_cake from '../../public/assets/modals/shop_modal/machines/shop_machines_1st_birthday_cake.png';
import shop_machines_brawler_cage from '../../public/assets/modals/shop_modal/machines/shop_machines_brawler_cage.png';
import shop_machines_mother_wolf_statue from '../../public/assets/modals/shop_modal/machines/shop_machines_mother_wolf_statue.png';
import shop_machines_others_receiver from '../../public/assets/modals/shop_modal/machines/shop_machines_others_receiver.png';
import shop_machines_porcelain_throne from '../../public/assets/modals/shop_modal/machines/shop_machines_porcelain_throne.png';
import shop_machines_space_cactus from '../../public/assets/modals/shop_modal/machines/shop_machines_space_cactus.png';
import shop_machines_lightning_tower from '../../public/assets/modals/shop_modal/machines/shop_machines_lightning_tower.png';
import shop_machines_plasma_cannon from '../../public/assets/modals/shop_modal/machines/shop_machines_plasma_cannon.png';
import shop_machines_little_lemon_tree from '../../public/assets/modals/shop_modal/machines/shop_machines_little_lemon_tree.png';
import shop_machines_kaiju_kingmaker from '../../public/assets/modals/shop_modal/machines/shop_machines_kaiju_kingmaker.png';
import shop_machines_aic_lab from '../../public/assets/modals/shop_modal/machines/shop_machines_aic_lab.png';
import shop_machines_neon_gold_accelerator from '../../public/assets/modals/shop_modal/machines/shop_machines_neon_gold_accelerator.png';
import shop_machines_space_elixir_accelerator from '../../public/assets/modals/shop_modal/machines/shop_machines_space_elixir_accelerator.png';
import shop_machines_barracks from '../../public/assets/modals/shop_modal/machines/shop_machines_barracks.png';
import shop_machines_cadet_dorms from '../../public/assets/modals/shop_modal/machines/shop_machines_cadet_dorms.png';
import shop_machines_laser_cannon from '../../public/assets/modals/shop_modal/machines/shop_machines_laser_cannon.png';
import shop_machines_shield_battery from '../../public/assets/modals/shop_modal/machines/shop_machines_shield_battery.png';
import shop_machines_plasma_mortar from '../../public/assets/modals/shop_modal/machines/shop_machines_plasma_mortar.png';
import shop_machines_photon_shield from '../../public/assets/modals/shop_modal/machines/shop_machines_photon_shield.png';
import shop_machines_sunday_skirmish_trophy from '../../public/assets/modals/shop_modal/machines/shop_machines_sunday_skirmish_trophy.png';
import shop_machines_sunday_skirmish_trophy2 from '../../public/assets/modals/shop_modal/machines/shop_machines_sunday_skirmish_trophy2.png';
import shop_machines_christmas_tree_2023 from '../../public/assets/modals/shop_modal/machines/shop_machines_christmas_tree_2023.png';
import shop_machines_community_hero_2023 from '../../public/assets/modals/shop_modal/machines/shop_machines_community_hero_2023.png';
import shop_machines_community_nominee_2023 from '../../public/assets/modals/shop_modal/machines/shop_machines_community_nominee_2023.png';
import shop_machines_nine_lives_lounge from '../../public/assets/modals/shop_modal/machines/shop_machines_nine_lives_lounge.png';
import shop_machines_glfhers_arcade from '../../public/assets/modals/shop_modal/machines/shop_machines_glfhers_arcade.png';
import shop_machines_pirate_nation_golden_galleon from '../../public/assets/modals/shop_modal/machines/shop_machines_pirate_nation_golden_galleon.png';
import shop_machines_pirate_nation_wood_galleon from '../../public/assets/modals/shop_modal/machines/shop_machines_pirate_nation_wood_galleon.png';
import shop_machines_bp_blob_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_blob_cosmetic.png';
import shop_machines_bp_fire_storm_tracker_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_fire_storm_tracker_cosmetic.png';
import shop_machines_bp_ice_storm_tracker_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_ice_storm_tracker_cosmetic.png';
import shop_machines_bp_star_wolvez_helmet_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_star_wolvez_helmet_cosmetic.png';
import shop_machines_bp_water_cooler_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_water_cooler_cosmetic.png';
import shop_machines_bp_piece_of_home_cosmetic from '../../public/assets/modals/shop_modal/machines/shop_machines_bp_piece_of_home_cosmetic.png';

import shop_item_info_divider_short from '../../public/assets/modals/shop_modal/shop_item_info_divider_short.png';
import shop_machines_mvhq from '../../public/assets/modals/shop_modal/machines/shop_machines_mvhq.png';
import shop_machines_mercenary_wolvez_hideout from '../../public/assets/modals/shop_modal/machines/shop_machines_mercenary_wolvez_hideout.png';
import shop_machines_kuma_ancestor_shrine from '../../public/assets/modals/shop_modal/machines/shop_machines_kuma_ancestor_shrine.png';
import shop_machines_super_swole_squad_department from '../../public/assets/modals/shop_modal/machines/shop_machines_super_swole_squad_department.png';
import shop_machines_medical_academy from '../../public/assets/modals/shop_modal/machines/shop_machines_medical_academy.png';
import shop_machines_antirat_squad_department from '../../public/assets/modals/shop_modal/machines/shop_machines_antirat_squad_department.png';
import shop_machines_saved_souls from '../../public/assets/modals/shop_modal/machines/shop_machines_saved_souls.png';
import shop_machines_dimension_x from '../../public/assets/modals/shop_modal/machines/shop_machines_dimension_x.png';
import shop_battle_pass_image from '../../public/assets/modals/shop_modal/shop_items/shop_battle_pass_image.png';
import shop_machines_zen_garden from '../../public/assets/modals/shop_modal/machines/shop_machines_zen_garden.png';

export const Shop_Assets = {
  machines_tab_active,
  item_shop_tab_active,
  resources_tab_active,
  machines_tab_inactive,
  item_shop_tab_inactive,
  resources_tab_inactive,
  shop_common_resource_box_image,
  shop_epic_resource_box_image,
  shop_legendary_resource_box_image,
  shop_common_ng_refill_image,
  shop_rare_ng_refill_image,
  shop_epic_ng_refill_image,
  shop_common_se_refill_image,
  shop_rare_se_refill_image,
  shop_epic_se_refill_image,
  shop_animated_408_image,
  shop_animated_3299_image,
  shop_star_wolvez_hoodie_image,
  shop_animation_chip_image,
  shop_bk55_chip_image,
  shop_bk55_ii_chip_image,
  shop_fur_69_image,
  shop_little_lemon_friends_image,
  shop_etherorc_allies_image,
  shop_brawler_bearz_image,
  shop_back_button,
  shop_stake_button,
  shop_buy_swap_button,
  shop_stake_button_inactive,
  shop_buy_swap_button_inactive,
  shop_cosmetics_button_inactive,
  shop_other_button_inactive,
  shop_whitelist_button_inactive,
  shop_cosmetics_button,
  shop_other_button,
  shop_whitelist_button,
  shop_administrative_button_inactive,
  shop_army_button_inactive,
  shop_available_button,
  shop_research_button_inactive,
  shop_resources_button_inactive,
  shop_administrative_button,
  shop_army_button,
  shop_available_button_inactive,
  shop_research_button,
  shop_resources_button,
  shop_all_button,
  shop_all_button_inactive,
  shop_holder_exclusive_button,
  shop_holder_exclusive_button_inactive,
  shop_item_info_button,
  shop_item_info_letter,
  shop_item_info_divider_long,
  shop_item_info_stardust_progress_bar,
  shop_buy_button,
  shop_coming_soon_button,
  shop_sold_out_button,
  shop_nfts_button,
  shop_nfts_button_inactive,
  shop_special_items_button,
  shop_special_items_button_inactive,
  shop_resource_packs_button,
  shop_resource_packs_button_inactive,
  shop_battlepass_season_0_image,
  shop_machines_mainframe,
  shop_machines_neon_gold_mine,
  shop_machines_neon_gold_storage,
  shop_machines_space_elixir_mine,
  shop_machines_space_elixir_storage,
  shop_machines_star_laboratory,
  shop_machines_technician_bunker,
  shop_machines_discord_airdrop_trophy,
  shop_machines_stardust_mine,
  shop_machines_tournament_one_competitor_trophy,
  shop_machines_genesis_holder_trophy,
  shop_machines_gasa_embassy,
  shop_machines_christmas_tree_trophy,
  shop_machines_beer_machine,
  shop_machines_raijus_grave,
  shop_machines_linkins_armory,
  shop_machines_bench_press,
  shop_machines_valentines_picnic,
  shop_machines_gooh_refinery,
  shop_machines_alchemist_lab,
  shop_machines_1st_birthday_cake,
  shop_machines_brawler_cage,
  shop_machines_mother_wolf_statue,
  shop_machines_others_receiver,
  shop_machines_porcelain_throne,
  shop_machines_space_cactus,
  shop_machines_lightning_tower,
  shop_machines_plasma_cannon,
  shop_machines_little_lemon_tree,
  shop_machines_kaiju_kingmaker,
  shop_machines_aic_lab,
  shop_machines_neon_gold_accelerator,
  shop_machines_space_elixir_accelerator,
  shop_machines_barracks,
  shop_machines_cadet_dorms,
  shop_machines_laser_cannon,
  shop_machines_shield_battery,
  shop_machines_plasma_mortar,
  shop_machines_photon_shield,
  shop_item_info_divider_short,
  shop_machines_mvhq,
  shop_machines_mercenary_wolvez_hideout,
  shop_machines_kuma_ancestor_shrine,
  shop_machines_super_swole_squad_department,
  shop_machines_medical_academy,
  shop_machines_antirat_squad_department,
  shop_machines_saved_souls,
  shop_machines_dimension_x,
  shop_battle_pass_image,
  shop_machines_sunday_skirmish_trophy,
  shop_machines_sunday_skirmish_trophy2,
  shop_machines_zen_garden,
  shop_machines_christmas_tree_2023,
  shop_machines_community_hero_2023,
  shop_machines_community_nominee_2023,
  shop_machines_nine_lives_lounge,
  shop_machines_glfhers_arcade,
  shop_machines_pirate_nation_golden_galleon,
  shop_machines_pirate_nation_wood_galleon,
  shop_machines_bp_blob_cosmetic,
  shop_machines_bp_fire_storm_tracker_cosmetic,
  shop_machines_bp_ice_storm_tracker_cosmetic,
  shop_machines_bp_star_wolvez_helmet_cosmetic,
  shop_machines_bp_water_cooler_cosmetic,
  shop_machines_bp_piece_of_home_cosmetic,
};

// Pack Assets
import bronze_pack from '../../public/assets/packs/bronze_pack.png';
import silver_pack from '../../public/assets/packs/silver_pack.png';
import golden_pack from '../../public/assets/packs/golden_pack.png';
import rare_pack from '../../public/assets/packs/rare_pack.png';
import epic_pack from '../../public/assets/packs/epic_pack.png';
import legendary_pack from '../../public/assets/packs/legendary_pack.png';
import mythical_pack from '../../public/assets/packs/mythical_pack.png';
import space_elixir_pack_reward from '../../public/assets/packs/space_elixir_pack_reward.png';
import neon_gold_pack_reward from '../../public/assets/packs/neon_gold_pack_reward.png';
import pack_item_veil from '../../public/assets/packs/pack_item_veil.png';
import artifact_0002 from '../../public/assets/packs/artifact_0002.png';
import artifact_0003 from '../../public/assets/packs/artifact_0003.png';
import artifact_0004 from '../../public/assets/packs/artifact_0004.png';
import artifact_0005 from '../../public/assets/packs/artifact_0005.png';
import artifact_0006 from '../../public/assets/packs/artifact_0006.png';
import artifact_0007 from '../../public/assets/packs/artifact_0007.png';
import artifact_0008 from '../../public/assets/packs/artifact_0008.png';
import artifact_0009 from '../../public/assets/packs/artifact_0009.png';
import artifact_0010 from '../../public/assets/packs/artifact_0010.png';
import artifact_0011 from '../../public/assets/packs/artifact_0011.png';
import fur69_chip from '../../public/assets/packs/fur69_chip.png';
import fur70_chip from '../../public/assets/packs/fur70_chip.png';
import nm8_chip from '../../public/assets/packs/nm8_chip.png';
import bk55_chip from '../../public/assets/packs/bk55_chip.png';
import bk55_ii_chip from '../../public/assets/packs/bk55_ii_chip.png';
import stardust_pack_reward from '../../public/assets/packs/stardust_pack_reward.png';

export const Pack_Assets = {
  bronze_pack,
  silver_pack,
  golden_pack,
  rare_pack,
  epic_pack,
  legendary_pack,
  mythical_pack,
  space_elixir_pack_reward,
  neon_gold_pack_reward,
  stardust_pack_reward,
  pack_item_veil,
  artifact_0002,
  artifact_0003,
  artifact_0004,
  artifact_0005,
  artifact_0006,
  artifact_0007,
  artifact_0008,
  artifact_0009,
  artifact_0010,
  artifact_0011,
  fur69_chip,
  fur70_chip,
  nm8_chip,
  bk55_chip,
  bk55_ii_chip,
};

// Base raid
import base_raid_attack_btn from '../../public/assets/missions/base_raid/base_raid_attack_btn.png';
import base_raid_back_to_home_btn from '../../public/assets/missions/base_raid/base_raid_back_to_home_btn.png';
import base_raid_back_to_home_btn_gray from '../../public/assets/missions/base_raid/base_raid_back_to_home_btn_gray.png';
import base_raid_next_btn from '../../public/assets/missions/base_raid/base_raid_next_btn.png';
import base_raid_surrender_btn from '../../public/assets/missions/base_raid/base_raid_surrender_btn.png';
import okay_button from '../../public/assets/missions/base_raid/okay_button.png';
import raid_result_banner from '../../public/assets/missions/base_raid/raid_result_banner.png';
import oh_no_text from '../../public/assets/missions/base_raid/oh_no_text.png';
import thank_heavens_text from '../../public/assets/missions/base_raid/thank_heavens_text.png';
import attack_log_tab from '../../public/assets/modals/raid_history_modal/attack_log_tab.png';
import attack_log_tab_inactive from '../../public/assets/modals/raid_history_modal/attack_log_tab_inactive.png';
import defense_log_tab from '../../public/assets/modals/raid_history_modal/defense_log_tab.png';
import defense_log_tab_inactive from '../../public/assets/modals/raid_history_modal/defense_log_tab_inactive.png';
import back_to_homebase_button from '../../public/assets/modals/raid_history_modal/back_to_homebase_button.png';
import go_raiding_button from '../../public/assets/modals/raid_history_modal/go_raiding_button.png';

export const Base_Raid_Assets = {
  base_raid_attack_btn,
  base_raid_back_to_home_btn,
  base_raid_back_to_home_btn_gray,
  base_raid_next_btn,
  base_raid_surrender_btn,
  okay_button,
  raid_result_banner,
  oh_no_text,
  thank_heavens_text,
  attack_log_tab,
  attack_log_tab_inactive,
  defense_log_tab,
  defense_log_tab_inactive,
  back_to_homebase_button,
  go_raiding_button,
};

export const Base_Raid_Next_Scene_Assets = {
  pvp_cancel_button,
  pvp_confirm_button,
};

export const Base_Raid_Loading_Scene_Assets = {
  loading_rect,
  loading_prog_bar,
  loading_prog_1,
  loading_prog_2,
  loading_prog_3,
  loading_prog_4,
  loading_prog_5,
};

export const Base_Raid_Hud_Scene_Assets = {
  base_raid_next_btn,
  base_raid_attack_btn,
  base_raid_back_to_home_btn,
  base_raid_back_to_home_btn_gray,
  base_raid_surrender_btn,
};

export const Base_Raid_History_Scene_Assets = {
  attack_log_tab,
  attack_log_tab_inactive,
  defense_log_tab,
  defense_log_tab_inactive,
  back_to_homebase_button,
  go_raiding_button,
};

export const Base_Raid_Result_Scene_Assets = {
  okay_button,
  raid_result_banner,
  oh_no_text,
  thank_heavens_text,
};

// BossFight
import bossfight_badge from '../../public/assets/missions/boss-fight/bossfight_badge.png';
import bossfight_title_text from '../../public/assets/missions/boss-fight/bossfight_title_text.png';
import bossfight_divider from '../../public/assets/missions/boss-fight/bossfight_divider.png';
import bossfight_status_not_started from '../../public/assets/missions/boss-fight/bossfight_status_not_started.png';
import bossfight_status_in_progress from '../../public/assets/missions/boss-fight/bossfight_status_in_progress.png';
import bossfight_ready_up_button from '../../public/assets/missions/boss-fight/bossfight_ready_up_button.png';
import bossfight_you_are_ready_button from '../../public/assets/missions/boss-fight/bossfight_you_are_ready_button.png';
import bossfight_clock_icon from '../../public/assets/missions/boss-fight/bossfight_clock_icon.png';
import bossfight_hp_icon from '../../public/assets/missions/boss-fight/bossfight_hp_icon.png';
import bossfight_back_button from '../../public/assets/missions/boss-fight/bossfight_back_button.png';
import bossfight_notification_image from '../../public/assets/missions/boss-fight/bossfight_notification_image.png';
import bossfight_notification_later_button from '../../public/assets/missions/boss-fight/bossfight_notification_later_button.png';
import bossfight_notification_letsgo_button from '../../public/assets/missions/boss-fight/bossfight_notification_letsgo_button.png';
import bossfight_notification_title_text from '../../public/assets/missions/boss-fight/bossfight_notification_title_text.png';
import bossfight_battle_missed_image from '../../public/assets/missions/boss-fight/bossfight_battle_missed_image.png';
import bossfight_go_to_discord_button from '../../public/assets/missions/boss-fight/bossfight_go_to_discord_button.png';
import bossfight_defeat_badge from '../../public/assets/missions/boss-fight/bossfight_defeat_badge.png';
import bossfight_defeat_return_home_button from '../../public/assets/missions/boss-fight/bossfight_defeat_return_home_button.png';
import bossfight_defeat_title from '../../public/assets/missions/boss-fight/bossfight_defeat_title.png';
import bossfight_tweet_this_button from '../../public/assets/missions/boss-fight/bossfight_tweet_this_button.png';
import bossfight_victory_badge from '../../public/assets/missions/boss-fight/bossfight_victory_badge.png';
import bossfight_victory_return_home_button from '../../public/assets/missions/boss-fight/bossfight_victory_return_home_button.png';
import bossfight_victory_title from '../../public/assets/missions/boss-fight/bossfight_victory_title.png';
import bossfight_hud_go_to_battle_button from '../../public/assets/missions/boss-fight/bossfight_hud_go_to_battle_button.png';
import bossfight_sit_out_button from '../../public/assets/missions/boss-fight/bossfight_sit_out_button.png';
import bossfight_boss_common from '../../public/assets/missions/boss-fight/bossfight_boss_common.png';
import bossfight_boss_epic from '../../public/assets/missions/boss-fight/bossfight_boss_epic.png';
import bossfight_boss_rare from '../../public/assets/missions/boss-fight/bossfight_boss_rare.png';
import bossfight_boss_legendary from '../../public/assets/missions/boss-fight/bossfight_boss_legendary.png';
import bossfight_boss_mythical from '../../public/assets/missions/boss-fight/bossfight_boss_mythical.png';
import bossfight_damage_button from '../../public/assets/missions/boss-fight/bossfight_damage_button.png';
import bossfight_damage_button_inactive from '../../public/assets/missions/boss-fight/bossfight_damage_button_inactive.png';
import bossfight_rounds_button from '../../public/assets/missions/boss-fight/bossfight_rounds_button.png';
import bossfight_rounds_button_inactive from '../../public/assets/missions/boss-fight/bossfight_rounds_button_inactive.png';
import bossfight_space_elixir_button from '../../public/assets/missions/boss-fight/bossfight_space_elixir_button.png';
import bossfight_space_elixir_button_inactive from '../../public/assets/missions/boss-fight/bossfight_space_elixir_button_inactive.png';
import bossfight_neon_gold_button from '../../public/assets/missions/boss-fight/bossfight_neon_gold_button.png';
import bossfight_neon_gold_button_inactive from '../../public/assets/missions/boss-fight/bossfight_neon_gold_button_inactive.png';
import bossfight_star_dust_button from '../../public/assets/missions/boss-fight/bossfight_star_dust_button.png';
import bossfight_star_dust_button_inactive from '../../public/assets/missions/boss-fight/bossfight_star_dust_button_inactive.png';

export const BossFight_Assets = {
  bossfight_badge,
  bossfight_title_text,
  bossfight_divider,
  bossfight_status_not_started,
  bossfight_status_in_progress,
  bossfight_ready_up_button,
  bossfight_you_are_ready_button,
  bossfight_clock_icon,
  bossfight_hp_icon,
  bossfight_back_button,
  bossfight_notification_image,
  bossfight_notification_later_button,
  bossfight_notification_letsgo_button,
  bossfight_notification_title_text,
  bossfight_battle_missed_image,
  bossfight_go_to_discord_button,
  bossfight_defeat_badge,
  bossfight_defeat_return_home_button,
  bossfight_defeat_title,
  bossfight_tweet_this_button,
  bossfight_victory_badge,
  bossfight_victory_return_home_button,
  bossfight_victory_title,
  bossfight_hud_go_to_battle_button,
  bossfight_sit_out_button,
  bossfight_boss_common,
  bossfight_boss_epic,
  bossfight_boss_rare,
  bossfight_boss_legendary,
  bossfight_boss_mythical,
  bossfight_damage_button,
  bossfight_damage_button_inactive,
  bossfight_rounds_button,
  bossfight_rounds_button_inactive,
  bossfight_space_elixir_button,
  bossfight_space_elixir_button_inactive,
  bossfight_neon_gold_button,
  bossfight_neon_gold_button_inactive,
  bossfight_star_dust_button,
  bossfight_star_dust_button_inactive,
};

export const BF_Scene_Assets = {
  level_circle,
  level_text,
  level_icon,
  level_prog_bar,
  level_prog_shine,
  neon_gold_icon,
  space_elixir_icon,
  stardust_icon,
  stardust_prog_bar,
  stardust_prog_shine,
  bossfight_badge,
  bossfight_title_text,
  bossfight_divider,
  bossfight_status_not_started,
  bossfight_status_in_progress,
  bossfight_clock_icon,
  bossfight_ready_up_button,
  bossfight_you_are_ready_button,
  bossfight_hp_icon,
  bossfight_back_button,
  bossfight_sit_out_button,
  bossfight_boss_common,
  bossfight_boss_rare,
  bossfight_boss_epic,
  bossfight_boss_legendary,
  bossfight_boss_mythical,
};

export const BF_Defeat_Scene_Assets = {
  bossfight_defeat_badge,
  bossfight_defeat_title,
  bossfight_defeat_return_home_button,
  battleroyale_view_results_button,
};

export const BF_Lobby_Closed_Scene_Assets = {
  bossfight_victory_return_home_button,
};

export const BF_Results_Scene_Assets = {
  battleroyale_results_divider,
  battleroyale_placing_button,
  battleroyale_placing_button_inactive,
  battleroyale_kills_button,
  battleroyale_kills_button_inactive,
  battleroyale_evades_button,
  battleroyale_evades_button_inactive,
  battleroyale_suicides_button,
  battleroyale_suicides_button_inactive,
  bossfight_victory_return_home_button,
  battleroyale_results_pane_divider,
  bossfight_damage_button,
  bossfight_damage_button_inactive,
  bossfight_rounds_button,
  bossfight_rounds_button_inactive,
  bossfight_space_elixir_button,
  bossfight_space_elixir_button_inactive,
  bossfight_neon_gold_button,
  bossfight_neon_gold_button_inactive,
  bossfight_star_dust_button,
  bossfight_star_dust_button_inactive,
  neon_gold_icon,
  space_elixir_icon,
  stardust_icon,
};

export const BF_Victory_Scene_Assets = {
  bossfight_victory_badge,
  bossfight_victory_title,
  bossfight_victory_return_home_button,
  bossfight_tweet_this_button,
  battleroyale_view_results_button,
};

export const BF_Alert_Scene_Assets = {
  bossfight_notification_image,
  bossfight_notification_later_button,
  bossfight_notification_letsgo_button,
  bossfight_notification_title_text,
};

// BattleRoyale
import battleroyale_back_button from '../../public/assets/missions/battle-royale/battleroyale_back_button.png';
import battleroyale_badge from '../../public/assets/missions/battle-royale/battleroyale_badge.png';
import battleroyale_divider from '../../public/assets/missions/battle-royale/battleroyale_divider.png';
import battleroyale_game_bg from '../../public/assets/missions/battle-royale/battleroyale_game_bg.png';
import battleroyale_join_bg from '../../public/assets/missions/battle-royale/battleroyale_join_bg.png';
import battleroyale_ready_up_button from '../../public/assets/missions/battle-royale/battleroyale_ready_up_button.png';
import battleroyale_title_text from '../../public/assets/missions/battle-royale/battleroyale_title_text.png';
import battleroyale_you_are_ready_button from '../../public/assets/missions/battle-royale/battleroyale_you_are_ready_button.png';
import battleroyale_neon_gold_icon from '../../public/assets/missions/battle-royale/battleroyale_neon_gold_icon.png';
import battleroyale_space_elixir_icon from '../../public/assets/missions/battle-royale/battleroyale_space_elixir_icon.png';
import battleroyale_stardust_icon from '../../public/assets/missions/battle-royale/battleroyale_stardust_icon.png';
import battleroyale_results_divider from '../../public/assets/missions/battle-royale/battleroyale_results_divider.png';
import battleroyale_results_pane_divider from '../../public/assets/missions/battle-royale/battleroyale_results_pane_divider.png';
import battleroyale_placing_button from '../../public/assets/missions/battle-royale/battleroyale_placing_button.png';
import battleroyale_placing_button_inactive from '../../public/assets/missions/battle-royale/battleroyale_placing_button_inactive.png';
import battleroyale_kills_button from '../../public/assets/missions/battle-royale/battleroyale_kills_button.png';
import battleroyale_kills_button_inactive from '../../public/assets/missions/battle-royale/battleroyale_kills_button_inactive.png';
import battleroyale_evades_button from '../../public/assets/missions/battle-royale/battleroyale_evades_button.png';
import battleroyale_evades_button_inactive from '../../public/assets/missions/battle-royale/battleroyale_evades_button_inactive.png';
import battleroyale_suicides_button from '../../public/assets/missions/battle-royale/battleroyale_suicides_button.png';
import battleroyale_suicides_button_inactive from '../../public/assets/missions/battle-royale/battleroyale_suicides_button_inactive.png';
import battleroyale_view_results_button from '../../public/assets/missions/battle-royale/battleroyale_view_results_button.png';

export const BR_Scene_Assets = {
  battleroyale_back_button,
  battleroyale_badge,
  battleroyale_divider,
  battleroyale_game_bg,
  battleroyale_join_bg,
  battleroyale_title_text,
  bossfight_sit_out_button,
  battleroyale_results_pane_divider,
  battleroyale_ready_up_button,
  battleroyale_you_are_ready_button,
  bossfight_clock_icon,
  bossfight_victory_return_home_button,
};

export const BR_Entry_Scene_Assets = {
  battleroyale_neon_gold_icon,
  battleroyale_space_elixir_icon,
  battleroyale_stardust_icon,
  pvp_cancel_button,
  pvp_confirm_button,
  space_elixir_icon,
  neon_gold_icon,
  stardust_icon,
};

export const BR_Failed_Scene_Assets = {
  bossfight_victory_badge,
  bossfight_victory_title,
  bossfight_victory_return_home_button,
  bossfight_defeat_badge,
  bossfight_defeat_title,
  battleroyale_view_results_button,
};

export const BR_GameOver_Scene_Assets = {
  bossfight_victory_badge,
  bossfight_victory_title,
  bossfight_victory_return_home_button,
  bossfight_defeat_badge,
  bossfight_defeat_title,
  battleroyale_view_results_button,
};

export const BR_Results_Scene_Assets = {
  battleroyale_results_divider,
  battleroyale_placing_button,
  battleroyale_placing_button_inactive,
  battleroyale_kills_button,
  battleroyale_kills_button_inactive,
  battleroyale_evades_button,
  battleroyale_evades_button_inactive,
  battleroyale_suicides_button,
  battleroyale_suicides_button_inactive,
  bossfight_victory_return_home_button,
  space_elixir_icon,
  neon_gold_icon,
  stardust_icon,
};

export const BR_Server_Unavailable_Scene_Assets = {
  bossfight_victory_return_home_button,
};

export const BR_Alert_Scene_Assets = {
  hud_battle_royale_background,
  bossfight_notification_later_button,
  hud_battle_royale_ready_up_button,
  hud_battle_royale_view_button,
  hud_battle_royale_text,
};

// Army
import army_tab_active from '../../public/assets/modals/army_modal/army_tab_active.png';
import army_tab_inactive from '../../public/assets/modals/army_modal/army_tab_inactive.png';
import training_tab_active from '../../public/assets/modals/army_modal/training_tab_active.png';
import training_tab_inactive from '../../public/assets/modals/army_modal/training_tab_inactive.png';
import army_modal_close_button from '../../public/assets/modals/army_modal/army_modal_close_button.png';
import remove_troop_button from '../../public/assets/modals/army_modal/remove_troop_button.png';
import troop_count_circle from '../../public/assets/modals/army_modal/troop_count_circle.png';
import capacity_icon_small from '../../public/assets/modals/army_modal/capacity_icon_small.png';
import dps_icon_small from '../../public/assets/modals/army_modal/dps_icon_small.png';
import hps_icon_small from '../../public/assets/modals/army_modal/hps_icon_small.png';
import health_icon_small from '../../public/assets/modals/army_modal/health_icon_small.png';
import time_icon_small from '../../public/assets/modals/army_modal/time_icon_small.png';

import troops_ars from '../../public/assets/modals/army_modal/troop_images/troops_ars_v2.png';
import troops_healer from '../../public/assets/modals/army_modal/troop_images/troops_healer.png';
import troops_mercenary from '../../public/assets/modals/army_modal/troop_images/troops_mercenary.png';
import troops_super_swole from '../../public/assets/modals/army_modal/troop_images/troops_super_swole.png';

import troops_ars_small from '../../public/assets/modals/army_modal/troop_images/troops_ars_small_v2.png';
import troops_healer_small from '../../public/assets/modals/army_modal/troop_images/troops_healer_small.png';
import troops_mercenary_small from '../../public/assets/modals/army_modal/troop_images/troops_mercenary_small.png';
import troops_super_swole_small from '../../public/assets/modals/army_modal/troop_images/troops_super_swole_small.png';

import all_tab_active from '../../public/assets/modals/army_modal/all_tab_active.png';
import all_tab_inactive from '../../public/assets/modals/army_modal/all_tab_inactive.png';
import level_1_tab_active from '../../public/assets/modals/army_modal/level_1_tab_active.png';
import level_1_tab_inactive from '../../public/assets/modals/army_modal/level_1_tab_inactive.png';
import level_2_tab_active from '../../public/assets/modals/army_modal/level_2_tab_active.png';
import level_2_tab_inactive from '../../public/assets/modals/army_modal/level_2_tab_inactive.png';
import level_3_tab_active from '../../public/assets/modals/army_modal/level_3_tab_active.png';
import level_3_tab_inactive from '../../public/assets/modals/army_modal/level_3_tab_inactive.png';
import level_4_tab_active from '../../public/assets/modals/army_modal/level_4_tab_active.png';
import level_4_tab_inactive from '../../public/assets/modals/army_modal/level_4_tab_inactive.png';
import level_5_tab_active from '../../public/assets/modals/army_modal/level_5_tab_active.png';
import level_5_tab_inactive from '../../public/assets/modals/army_modal/level_5_tab_inactive.png';
import level_6_tab_active from '../../public/assets/modals/army_modal/level_6_tab_active.png';
import level_6_tab_inactive from '../../public/assets/modals/army_modal/level_6_tab_inactive.png';
import level_7_tab_active from '../../public/assets/modals/army_modal/level_7_tab_active.png';
import level_7_tab_inactive from '../../public/assets/modals/army_modal/level_7_tab_inactive.png';
import level_8_tab_active from '../../public/assets/modals/army_modal/level_8_tab_active.png';
import level_8_tab_inactive from '../../public/assets/modals/army_modal/level_8_tab_inactive.png';
import level_9_tab_active from '../../public/assets/modals/army_modal/level_9_tab_active.png';
import level_9_tab_inactive from '../../public/assets/modals/army_modal/level_9_tab_inactive.png';
import level_10_tab_active from '../../public/assets/modals/army_modal/level_10_tab_active.png';
import level_10_tab_inactive from '../../public/assets/modals/army_modal/level_10_tab_inactive.png';

import train_1_active from '../../public/assets/modals/army_modal/train_1_active.png';
import train_5_active from '../../public/assets/modals/army_modal/train_5_active.png';
import train_10_active from '../../public/assets/modals/army_modal/train_10_active.png';
import train_20_active from '../../public/assets/modals/army_modal/train_20_active.png';
import train_50_active from '../../public/assets/modals/army_modal/train_50_active.png';
import train_100_active from '../../public/assets/modals/army_modal/train_100_active.png';
import train_1_inactive from '../../public/assets/modals/army_modal/train_1_inactive.png';
import train_5_inactive from '../../public/assets/modals/army_modal/train_5_inactive.png';
import train_10_inactive from '../../public/assets/modals/army_modal/train_10_inactive.png';
import train_20_inactive from '../../public/assets/modals/army_modal/train_20_inactive.png';
import train_50_inactive from '../../public/assets/modals/army_modal/train_50_inactive.png';
import train_100_inactive from '../../public/assets/modals/army_modal/train_100_inactive.png';
import capacity_icon from '../../public/assets/modals/army_modal/capacity_icon.png';

import dashboard_divider from '../../public/assets/dashboard/dashboard_divider.png';

export const Army_Assets = {
  army_tab_active,
  army_tab_inactive,
  training_tab_active,
  training_tab_inactive,
  army_modal_close_button,
  remove_troop_button,
  troop_count_circle,
  capacity_icon_small,
  dps_icon_small,
  hps_icon_small,
  health_icon_small,
  time_icon_small,
  troops_ars,
  troops_healer,
  troops_mercenary,
  troops_super_swole,
  troops_ars_small,
  troops_healer_small,
  troops_mercenary_small,
  troops_super_swole_small,
  all_tab_active,
  all_tab_inactive,
  level_1_tab_active,
  level_1_tab_inactive,
  level_2_tab_active,
  level_2_tab_inactive,
  level_3_tab_active,
  level_3_tab_inactive,
  level_4_tab_active,
  level_4_tab_inactive,
  level_5_tab_active,
  level_5_tab_inactive,
  level_6_tab_active,
  level_6_tab_inactive,
  level_7_tab_active,
  level_7_tab_inactive,
  level_8_tab_active,
  level_8_tab_inactive,
  level_9_tab_active,
  level_9_tab_inactive,
  level_10_tab_active,
  level_10_tab_inactive,
  train_1_active,
  train_5_active,
  train_10_active,
  train_20_active,
  train_50_active,
  train_100_active,
  train_1_inactive,
  train_5_inactive,
  train_10_inactive,
  train_20_inactive,
  train_50_inactive,
  train_100_inactive,
  capacity_icon,
  dashboard_divider,
};

// Battle Pass
import battlepass_back_button from '../../public/assets/battlepass/battlepass_back_button.png';
import battlepass_background_image from '../../public/assets/battlepass/battlepass_background_image.png';
import battlepass_buy_button from '../../public/assets/battlepass/battlepass_buy_button.png';
import battlepass_buy_button_glfhers from '../../public/assets/battlepass/battlepass_buy_button_glfhers.png';
import battlepass_explore_button from '../../public/assets/battlepass/battlepass_explore_button.png';
import battlepass_season_text from '../../public/assets/battlepass/battlepass_season_text.png';
import battlepass_season_image from '../../public/assets/battlepass/battlepass_season_image.png';
import battlepass_buy_success_background from '../../public/assets/battlepass/battlepass_buy_success_background.png';
import battlepass_homebase_button from '../../public/assets/battlepass/battlepass_homebase_button.png';
import battlepass_success_info_text from '../../public/assets/battlepass/battlepass_success_info_text.png';
import battlepass_success_text from '../../public/assets/battlepass/battlepass_success_text.png';
import battlepass_background from '../../public/assets/battlepass/battlepass_background.png';
import battlepass_active_button from '../../public/assets/battlepass/battlepass_active_button.png';
import battlepass_inactive_button from '../../public/assets/battlepass/battlepass_inactive_button.png';
import battlepass_raffle_active_button from '../../public/assets/battlepass/battlepass_raffle_active_button.png';
import battlepass_raffle_inactive_button from '../../public/assets/battlepass/battlepass_raffle_inactive_button.png';
import battlepass_home_button from '../../public/assets/battlepass/battlepass_home_button.png';
import battlepass_next_page_button_active from '../../public/assets/battlepass/battlepass_next_page_button_active.png';
import battlepass_previous_page_button_active from '../../public/assets/battlepass/battlepass_previous_page_button_active.png';
import battlepass_unlocked from '../../public/assets/battlepass/battlepass_unlocked.png';
import battlepass_raffle_rock from '../../public/assets/battlepass/battlepass_raffle_rock.png';
import battlepass_chapter from '../../public/assets/battlepass/battlepass_chapter.png';
import battlepass_season from '../../public/assets/battlepass/battlepass_season.png';
import battlepass_xp_component_divider from '../../public/assets/battlepass/battlepass_xp_component_divider.png';
import battlepass_present from '../../public/assets/battlepass/battlepass_present.png';
import battlepass_assign_button from '../../public/assets/battlepass/battlepass_assign_button.png';
import battlepass_assign_all_button from '../../public/assets/battlepass/battlepass_assign_all_button.png';
import battlepass_mini_rock from '../../public/assets/battlepass/battlepass_mini_rock.png';
import battlepass_claimed_button from '../../public/assets/battlepass/battlepass_claimed_button.png';
import battlepass_claim_button_active from '../../public/assets/battlepass/battlepass_claim_button_active.png';
import battlepass_claim_button_inactive from '../../public/assets/battlepass/battlepass_claim_button_inactive.png';

// Battle Pass Level Icons
import xp_icon from '../../public/assets/battlepass/xp_icon.png';
import bp_neon_gold_icon from '../../public/assets/battlepass/bp_neon_gold_icon.png';
import bp_space_elixir_icon from '../../public/assets/battlepass/bp_space_elixir_icon.png';
import raffle_rock_icon from '../../public/assets/battlepass/raffle_rock_icon.png';
import nm8_icon from '../../public/assets/battlepass/nm8_icon.png';
import bk55_icon from '../../public/assets/battlepass/bk55_icon.png';
import bp_stardust_icon from '../../public/assets/battlepass/bp_stardust_icon.png';
import fur_chip_icon from '../../public/assets/battlepass/fur_chip_icon.png';
import bronze_pack_icon from '../../public/assets/battlepass/bronze_pack_icon.png';
import silver_pack_icon from '../../public/assets/battlepass/silver_pack_icon.png';
import gold_pack_icon from '../../public/assets/battlepass/gold_pack_icon.png';
import rare_pack_icon from '../../public/assets/battlepass/rare_pack_icon.png';
import epic_pack_icon from '../../public/assets/battlepass/epic_pack_icon.png';
import legendary_pack_icon from '../../public/assets/battlepass/legendary_pack_icon.png';
import mythical_pack_icon from '../../public/assets/battlepass/mythical_pack_icon.png';
import cadet_dorm_icon from '../../public/assets/battlepass/cadet_dorm_icon.png';
import plasma_mortor_icon from '../../public/assets/battlepass/plasma_mortor_icon.png';
import sw_hoodie_icon from '../../public/assets/battlepass/sw_hoodie_icon.png';
import blob_consmetic_icon from '../../public/assets/battlepass/blob_consmetic_icon.png';
import ice_storm_tracker_cosmetic from '../../public/assets/battlepass/ice_storm_tracker_cosmetic.png';
import fire_storm_tracker_cosmetic from '../../public/assets/battlepass/fire_storm_tracker_cosmetic.png';
import water_cooler_cosmetic_icon from '../../public/assets/battlepass/water_cooler_cosmetic_icon.png';
import gold_plasma_cannon_skin from '../../public/assets/battlepass/gold_plasma_cannon_skin.png';
import little_piece_of_home_cosmetic from '../../public/assets/battlepass/little_piece_of_home_cosmetic.png';
import star_wolvez_helmet_cosmetic from '../../public/assets/battlepass/star_wolvez_helmet_cosmetic.png';

export const BP_Scene_Assets = {
  battlepass_background,
  battlepass_active_button,
  battlepass_inactive_button,
  battlepass_raffle_active_button,
  battlepass_raffle_inactive_button,
  battlepass_home_button,
  battlepass_next_page_button_active,
  battlepass_previous_page_button_active,
  battlepass_unlocked,
  battlepass_raffle_rock,
  battlepass_chapter,
  battlepass_season,
  battlepass_xp_component_divider,
  battlepass_present,
  battlepass_assign_button,
  battlepass_assign_all_button,
  battlepass_mini_rock,
  battlepass_claim_button_active,
  battlepass_claim_button_inactive,
  battlepass_claimed_button,
  raffle_rock_icon,
  bk55_icon,
  nm8_icon,
  xp_icon,
  bp_neon_gold_icon,
  bp_space_elixir_icon,
  bp_stardust_icon,
  fur_chip_icon,
  bronze_pack_icon,
  silver_pack_icon,
  gold_pack_icon,
  rare_pack_icon,
  epic_pack_icon,
  legendary_pack_icon,
  mythical_pack_icon,
  cadet_dorm_icon,
  plasma_mortor_icon,
  sw_hoodie_icon,
  blob_consmetic_icon,
  fire_storm_tracker_cosmetic,
  ice_storm_tracker_cosmetic,
  gold_plasma_cannon_skin,
  water_cooler_cosmetic_icon,
  little_piece_of_home_cosmetic,
  star_wolvez_helmet_cosmetic,
  squad_confirm_button,
};

export const BP_Buy_Scene_Assets = {
  battlepass_back_button,
  battlepass_background_image,
  battlepass_buy_button,
  battlepass_buy_button_glfhers,
  battlepass_explore_button,
  battlepass_season_image,
  battlepass_season_text,
};

export const BP_Buy_Success_Scene_Assets = {
  battlepass_season_image,
  battlepass_buy_success_background,
  battlepass_homebase_button,
  battlepass_explore_button,
  battlepass_success_info_text,
  battlepass_success_text,
};

// Shared Assets
import starwolvez_bolt_logo from '../../public/assets/shared/starwolvez_bolt_logo.png';
import starwolf_silhouette from '../../public/assets/shared/star_wolf_silhouette_v2.png';
import chest_icon_large from '../../public/assets/shared/chest_icon_large.png';
import neon_gold_icon_large from '../../public/assets/shared/neon_gold_icon_large.png';
import space_elixir_icon_large from '../../public/assets/shared/space_elixir_icon_large.png';
import hp_icon_large from '../../public/assets/shared/hp_icon_large.png';
import dps_icon_large from '../../public/assets/shared/dps_icon_large.png';
import stardust_icon_large from '../../public/assets/shared/stardust_pink_icon_large.png';
import dark_matter_icon_large from '../../public/assets/shared/dark_matter_icon_large.png';
import wl_icon_large from '../../public/assets/shared/wl_icon_large.png';
import party_blob from '../../public/assets/shared/Partyblob.png';

export const Shared_Assets = {
  starwolvez_bolt_logo,
  starwolf_silhouette,
  chest_icon_large,
  neon_gold_icon_large,
  space_elixir_icon_large,
  hp_icon_large,
  dps_icon_large,
  stardust_icon_large,
  dark_matter_icon_large,
  wl_icon_large,
  party_blob,
};
