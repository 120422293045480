import Phaser from 'phaser';

export default class StartScene extends Phaser.Scene {
  constructor() {
    super('StartScene');
  }

  preload() {}

  create() {
    this.scene.launch('Preloader');
  }
}
