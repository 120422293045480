import Phaser from 'phaser'
import NotificationService from '~/services/NotificationService';
import RoundImageButton from '../buttons/RoundImageButton';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import UserService from '~/services/UserService';

const width = 361;
const radius = 7;
const alpha = 0.8;
const color = 0x25182b;
const borderWidth = 1;
const borderColor = 0x383d57;
const descColor = 0xb29fb8;

const glareWidth = 349;
const glareHeight = 9;
const glareRadius = 4;
const glareAlpha = 0.17;
const glareColor = 0xa0c8ed;

export default class HudNotificationComponent extends FixWidthSizer
{
    private _title: any;
    private _notificationNumber: any;
    private _description: any;
    private _closeButton: any;

	constructor(scene: Phaser.Scene, x: number, y: number, config: any)
	{
		let conf = {
            width: width,
            space: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                item: 0,
                line: 0,
            },
            align: 0,
        };
        super(scene, x, y, conf);

        this._title = config.title;
        this._description = config.description;
        this._notificationNumber = config.number;

        this.createComponent(scene, x, y);
    }

    createComponent(scene: Phaser.Scene, x: number, y: number) {
        this.addBackground(
            scene.add.rexRoundRectangle(
                0,
                0,
                0, // width
                0, // height
                radius, // radius
                color, // fillColor
                alpha, // alpha
            ).setStrokeStyle(borderWidth, borderColor, alpha)
        );

        this.add(
            scene.add.rexRoundRectangle(
                0,
                0,
                glareWidth, // width
                glareHeight, // height
                glareRadius, // radius
                glareColor, // fillColor
                glareAlpha, // alpha
            ), {
                key: 'notification_glare',
                padding: { left: 6, right: 0, top: 6, bottom: 0 },
            }
        );

        this._closeButton = new RoundImageButton(scene, 0, 0, 'close_notification_button', {allowClicks: true}, false);
        this._closeButton.onClick().subscribe(pointer => {
            NotificationService.getInstance().setNotificationClosed(this._notificationNumber).then(res => {
                UserService.getInstance().getUserData(true).then(res => {
                    scene.closeNotification(this._notificationNumber);
                });
            });
        })
        this.add(scene.add.existing(this._closeButton), {
            key: 'close_button',
            padding: { left: width - 17, right: 0, top: -30, bottom: 0 },
        });

        this.add(
            scene.add.bitmapText(0, 0, 'cc_outline', `${this._notificationNumber}. ${this._title}`, scene.applyScale(22)).setMaxWidth(width - 20),
            {
                key: 'notification_title',
                padding: { left: 12, right: 0, top: -3, bottom: 0 },
            }
        )
        .add(
            scene.add.bitmapText(0, 0, 'cc_outline', this._description, scene.applyScale(18))
                .setTint(descColor)
                .setMaxWidth(width - 20),
            {
                key: 'notification_description',
                padding: { left: 12, right: 0, top: -2, bottom: 5 },
            }
        );

        this.layout();
    }
}

Phaser.GameObjects.GameObjectFactory.register('hudNotificationComponent', function (x: number, y: number, config: any) {
	// @ts-ignore
    return this.displayList.add(new HudNotificationComponent(this.scene, x, y, config));
});
