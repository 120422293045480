import Phaser from 'phaser';
import util from 'util';
import Button from '../../../components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { Army_Assets, Hud_Assets } from '~/utils/AssetLoader';
import TroopService from '~/services/TroopService';
import ArmyContentPane from './trained_army/armyContentPane';
import ArmyTrainingContentPane from './training_area/armyTrainingContentPane';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalWidth = 1072;
const modalHeight = 771;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x6262de;
const modalBorderWidth = 3;

const tabs = {
  training: 'training',
  army: 'army',
};

export default class ArmyModalScene extends Phaser.Scene {
  private _scale;
  private _machine;
  private _startTab;
  private _armyTab;
  private _armyContentPane;
  private _trainingTab;
  private _trainingContentPane;
  private _selectedTab;
  private _closeButton;
  private _loadingIndicator;
  private _sizer;

  init(data: any) {
    this._machine = data.machine;
    this._startTab = data.tab || tabs.training;
  }

  constructor() {
    super({
      key: 'ArmyModalScene',
    });
  }

  preload() {
    // Images loaded in AssetPreloaderScene
  }

  create() {
    this.createComponent();

    this.scene.launch('AssetPreloaderScene', { originating_scene: 'ArmyModalScene', assets: Army_Assets });
  }

  createComponent() {
    const self = this;
    if (this.game.input.touch && this.game.input.touch.preventDefault) {
      this.game.input.touch.preventDefault = false;
      this.game.input.touch.enabled = true;
    }

    this.input.dragDistanceThreshold = 16;
    this.input.dragTimeThreshold = 300;

    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 70);

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      height: this.applyScale(modalHeight + 70),
      width: this.applyScale(modalWidth - 8),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    background.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    this._sizer.add(background, {
      key: 'army_modal_bg',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer.add(this._loadingIndicator, {
      key: 'loading_indicator',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(340),
      expand: false,
    });

    this._sizer.layout();

    //this._sizer.drawBounds(this.add.graphics(), 0x00ff00);
    //this._sizer.pushIntoBounds();

    this._sizer.onClickOutside((co, go, pointer) => {
      this.closeButtonClickHandler(pointer);
    });
  }

  onLoaded() {
    // Tabs
    this._trainingTab = new Button(this.scene.scene, 0, 0, 'training_tab_active', false).setOrigin(1, 1).setScale(this._scale);
    this._armyTab = new Button(this.scene.scene, 0, 0, 'army_tab_inactive', false).setOrigin(0, 1).setScale(this._scale);

    this._trainingTab.onClick().subscribe(pointer => {
      this.trainingTabClickHandler(pointer);
    });

    this._armyTab.onClick().subscribe(pointer => {
      this.armyTabClickHandler(pointer);
    });

    this.add.existing(this._trainingTab);
    this.add.existing(this._armyTab);

    this._sizer.add(this._trainingTab, {
      key: 'training_tab',
      align: 'center-top',
      offsetX: this.applyScale(-95),
      offsetY: 0,
      expand: false,
    });

    this._sizer.add(this._armyTab, {
      key: 'army_tab',
      align: 'center-top',
      offsetX: this.applyScale(95), // 250 + 138 + 14
      offsetY: 0,
      expand: false,
    });

    // Divider Line
    this._sizer.add(this.add.image(0, 0, 'dashboard_divider').setScale(this._scale), {
      key: 'divider_line',
      align: 'left-bottom',
      offsetX: this.applyScale(0), // 250 + 138 + 14
      offsetY: this.applyScale(-100),
      expand: false,
    });

    // Close Button
    this._closeButton = new Button(this, 0, 0, 'army_modal_close_button', false).setScale(this._scale);
    this._closeButton.onClick().subscribe(pointer => {
      this.closeButtonClickHandler(pointer);
    });
    this._sizer.add(this.add.existing(this._closeButton), {
      key: 'close_button',
      align: 'left-bottom',
      offsetX: this.applyScale(20), // 250 + 138 + 14
      offsetY: this.applyScale(-20),
      expand: false,
    });

    this._sizer.layout();

    TroopService.getInstance()
      .getTroopsByMachine(0, true)
      .then(res => {
        TroopService.getInstance()
          .getTrainableTroopsByMachine(0)
          .then(tt => {
            // Remove loading indicator
            this._loadingIndicator.setAlpha(0);
            this._sizer.remove(this._loadingIndicator, true);
            this._loadingIndicator = undefined;

            if (this._startTab === tabs.army) {
              this.armyTabClickHandler(self);
            } else {
              this.trainingTabClickHandler(self);
            }
          });
      });
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private closeButtonClickHandler(pointer: any) {
    if (pointer.getDistance() > 20) {
      return;
    }

    this._selectedTab = '';
    this.removePanes();

    this.unloadAssets();
    this.scene.stop();

    // this.scene.resume('HomebaseScene');
    this.scene.get('HomebaseScene').input.enabled = true;
    this.scene.resume('HudScene');
  }

  private trainingTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.training) {
      return;
    }

    this.removePanes();

    this._selectedTab = tabs.training;
    this._trainingTab.setTexture('training_tab_active');
    this._armyTab.setTexture('army_tab_inactive');

    TroopService.getInstance()
      .getArmy()
      .then(() => {
        this._trainingContentPane = new ArmyTrainingContentPane(this, 0, 0, this._machine, this._scale);
        this.add.existing(this._trainingContentPane);

        this._sizer
          .add(this._trainingContentPane, {
            key: 'training_pane',
            align: 'center-top',
            offsetX: 0,
            offsetY: this.applyScale(68),
            expand: false,
          })
          .layout();
      });

    //this._trainingContentPane.drawBounds(this.add.graphics(), 0x00ff00);
  }

  private armyTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.army) {
      return;
    }

    this.removePanes();

    this._selectedTab = tabs.army;
    this._trainingTab.setTexture('training_tab_inactive');
    this._armyTab.setTexture('army_tab_active');

    TroopService.getInstance()
      .getTroopsByMachine(this._machine.user_machine_id, true)
      .then(() => {
        TroopService.getInstance()
          .getArmy()
          .then(() => {
            this._armyContentPane = new ArmyContentPane(this, 0, 0, this._machine.user_machine_id, this._scale);
            this.add.existing(this._armyContentPane);

            this._sizer
              .add(this._armyContentPane, {
                key: 'army_pane',
                align: 'center-top',
                offsetX: 0,
                offsetY: this.applyScale(68),
                expand: false,
              })
              .layout();
          });
      });
  }

  getMachine() {
    return this._machine;
  }

  updateTrainingPane() {
    this.time.removeAllEvents();
    this._trainingContentPane.updateTraineeList();
  }

  updateCapacityBar() {
    if (this._selectedTab === tabs.army) {
      this._armyContentPane.updateCapacityBar();
    } else {
      this._trainingContentPane.updateCapacityBar();
    }
  }

  handleTroopRemoved() {
    if (this._selectedTab === tabs.army) {
      TroopService.getInstance()
        .getTroopsByMachine(this._machine.user_machine_id, true)
        .then(res => {
          TroopService.getInstance()
            .getArmy(true)
            .then(res => {
              this._armyContentPane.updateTroopsList();
              this._armyContentPane.updateCapacityBar();
            });
        });
    } else {
      TroopService.getInstance()
        .getTroopsByMachine(this._machine.user_machine_id, true)
        .then(res => {
          TroopService.getInstance()
            .getArmy(true)
            .then(res => {
              this._trainingContentPane.updateTraineeList();
              this._trainingContentPane.updateCapacityBar();
            });
        });
    }
  }

  removePanes() {
    if (this._trainingContentPane) {
      this._trainingContentPane.hidePane();
      this._sizer.remove(this._trainingContentPane, true); //destroys object
      this._trainingContentPane = null;
    }

    if (this._armyContentPane) {
      this._armyContentPane.hidePane();
      this._sizer.remove(this._armyContentPane, true); //destroys object
      this._armyContentPane = null;
    }

    this.time.removeAllEvents();
  }

  private destroy() {
    this._armyTab.destroy();
    this._trainingTab.destroy();
    this._selectedTab.destroy();
    this._armyContentPane.destroy();
    this._trainingContentPane.destroy();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Army_Assets));
  }
}
