import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';
import StakingService from '~/services/StakingService';

const minDaysHeldToStake = 7;

// Pane Details
const paneHeight = 180;

const refillTextColor = 0xa3a3b9;
const stakingYieldTextColor = 0xff6eb4;

export default class StakingHeaderContentPane extends OverlapSizer {
  private _title;
  private _openAllButton;
  private _countdownTimer;
  private _stakingYield;
  private _paneWidth: number;
  private _scale: number;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, paneWidth: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._paneWidth = paneWidth;

    this.createComponent(scene, x, y);
  }

  createComponent(scene: Phaser.Scene, x: number, y: number) {
    // Sizer
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: this.applyScale(this._paneWidth),
      height: this.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    this._title = scene.add.bitmapText(0, 0, 'cc_outline', 'Staking', this.applyScale(22), 1);

    sizerPanel.add(this._title, {
      key: 'title-text',
      align: 'left-top',
      offsetX: this.applyScale(16),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Button
    this._openAllButton = new Button(scene, 0, 0, 'open_all_button_disabled', false).setScale(this._scale);
    this._openAllButton.onClick().subscribe(pointer => {
      scene.sound.play('open-all-button');
      scene.scene.pause();
      scene.scene.launch('PenaltyScene');
    });
    this.setOpenAllEnabled();

    sizerPanel.add(scene.add.existing(this._openAllButton), {
      key: 'next-button',
      align: 'right-top',
      offsetX: this.applyScale(-16),
      offsetY: this.applyScale(-4),
      expand: false,
    });

    // Claim Countdown Timer
    this._countdownTimer = scene.add
      .bitmapText(0, 0, 'cc_outline', 'Chests refill in 0h 0m', this.applyScale(14), 1)
      .setOrigin(1, 0.5)
      .setTint(refillTextColor);

    sizerPanel.add(this._countdownTimer, {
      key: 'countdown-timer-text',
      align: 'right-top',
      offsetX: this.applyScale(-16),
      offsetY: this.applyScale(60),
      expand: false,
    });

    // Staking Yield
    this._stakingYield = scene.add
      .bitmapText(
        0,
        0,
        'cc_outline',
        `Net Staking Yield: ${StakingService.getInstance().getNetStakingYield().toLocaleString('en-us')}`,
        this.applyScale(14),
        1,
      )
      .setOrigin(1, 0.5)
      .setTint(stakingYieldTextColor);

    sizerPanel.add(this._stakingYield, {
      key: 'staking-yield-text',
      align: 'left-top',
      offsetX: this.applyScale(16),
      offsetY: this.applyScale(60),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();

    this.updateTimeHandler();

    // scene.time.addEvent({
    //   delay: 15000, //1 minute
    //   loop: true,
    //   callback: () => {
    //     this.updateTimeHandler();
    //   },
    // });
  }

  applyScale(length) {
    return this.scene.applyScale(length);
  }

  public setOpenAllEnabled() {
    const unclaimedStakes = this.getUnclaimedStakes();
    if (unclaimedStakes.length > 0) {
      // Enable Open All button
      this._openAllButton.setInteractive();
      this._openAllButton.setTexture('open_all_button');
    } else {
      // Disable Open All button
      this._openAllButton.disableInteractive();
      this._openAllButton.setTexture('open_all_button_disabled');
    }
  }

  private getUnclaimedStakes() {
    const stakingInfo = StakingService.getInstance().getStakingInfoSync();
    return stakingInfo.nfts.filter(nft => {
      return nft.days_held >= minDaysHeldToStake && !nft.staking_reward_info;
    });
  }

  private updateTimeHandler() {
    const date = new Date();
    const currentHour = date.getUTCHours();
    const currentMinute = date.getUTCMinutes();

    var hoursRemaining = 24 - currentHour;
    var minutesRemaining = 60 - currentMinute;

    if (minutesRemaining === 60) {
      minutesRemaining = 0;
    } else {
      hoursRemaining--;
    }

    this._countdownTimer.setText(`Chests refill in ${hoursRemaining}h ${minutesRemaining}m`);
  }
}

Phaser.GameObjects.GameObjectFactory.register('stakingHeaderContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new StakingHeaderContentPane(this.scene, x, y));
});
