import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';

import { Asteroid, asteroidRarityColorMapper } from '~/services/MiningService';
import RoundImageButton from '~/components/buttons/RoundImageButton';
import ProgressBar from '~/components/progressBar/ProgressBar';
import { convertSecondsToHours } from '~/utils/GameUtils';

import MiningModalScene from './MiningModalScene';

const componentWidth = 273;
const componentHeight = 413;
const componentRadius = 16;

export const imageMapper = {
  1: 'common_asteroid',
  2: 'rare_asteroid',
  3: 'epic_asteroid',
  4: 'legendary_asteroid',
  5: 'mythical_asteroid',
};

const componentAlpha = 1;

export default class AsteroidComponent extends OverlapSizer {
  private _scale: number;

  constructor(scene: MiningModalScene, x: number, y: number, asteroid: Asteroid, scale: number) {
    const config = {
      width: scale * componentWidth,
      height: scale * componentHeight,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    };
    super(scene, x, y, config);

    this._scale = scale;

    this.createComponent(scene, asteroid);
  }

  private createComponent(scene: MiningModalScene, asteroid: Asteroid) {
    // @ts-ignore
    // Background
    const background = scene.add.rexRoundRectangle(
      0,
      0,
      this.applyScale(componentWidth / 1.1),
      this.applyScale(componentHeight),
      this.applyScale(componentRadius),
      asteroidRarityColorMapper[asteroid.asteroid_rarity_id],
      componentAlpha,
    );

    this.add(background, {
      key: 'asteroid_background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    const config = {
      maskType: 'roundRectangle',
      radius: componentRadius,
    };

    const button = new RoundImageButton(scene, 0, 0, imageMapper[asteroid.asteroid_rarity_id], config, false);

    scene.add.existing(button);

    button.onClick().subscribe(observer => {
      if (observer.getDistance() > 20) return;

      scene.scene.stop('HomebaseScene');
      scene.scene.stop('HudScene');
      scene.scene.stop('MissionsModalScene');
      scene.scene.stop('MiningModalScene');
      scene.scene.get('MiningModalScene').unloadAssets();
      scene.scene.start('MiningLoadingScene', {
        asteroid,
        scale: this._scale,
      });
    });

    this.add(button, {
      key: 'asteroid_image',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    let subrarity_id = 'I';

    switch (asteroid.asteroid_subrarity_id) {
      case 3:
        subrarity_id = 'III';
        break;
      case 2:
        subrarity_id = 'II';
        break;
    }

    const label = scene.add.bitmapText(0, 0, 'cc_outline', asteroid.asteroid_rarity_name + ' - ' + subrarity_id, this.applyScale(22), 0);

    this.add(label, {
      key: 'asteroid_label',
      align: 'left-top',
      offsetX: scene.applyScale(23) * 1.3,
      offsetY: scene.applyScale(23) * 1.1,
      expand: false,
    });

    const progressBar = new ProgressBar(this.scene, 0, 0, this._scale, {
      title: {
        text: '',
        fontStyle: null,
      },
      progressBar: {
        text: `Time allowed: ${convertSecondsToHours(asteroid.mine_time)}`,
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(componentWidth / 1.3),
        height: this.applyScale(29),
        radius: this.applyScale(7),
        alpha: 1,
        color: 0x00a6d3,
        backgroundColor: 0x000000,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 0,
        glare: {
          width: this.applyScale(componentWidth / 1.4),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.17,
        },
      },
    });

    this.add(progressBar, {
      key: 'asteroid_progress_bar',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: -this.applyScale(23) * 1.1,
      expand: false,
    });

    this.layout();
  }

  private applyScale(length: number) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('asteroidComponent', function (x: number, y: number, data: Asteroid, scale: number) {
  // @ts-ignore
  return this.displayList.add(
    // @ts-ignore
    new AsteroidComponent(this.scene, x, y, data, scale),
  );
});
