import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';
import util from 'util';

export default class UserService {
  private static _instance: UserService = new UserService();

  private _userData: any = [];
  private _userName: string = '';
  private _isWhitelisted;
  private _isNewUser: boolean = false;

  constructor() {
    if (UserService._instance) {
      throw new Error('Error: Instantiation failed: Use UserService.getInstance() instead of new.');
    }
    UserService._instance = this;
    this._isWhitelisted = false;
  }

  public static getInstance(): UserService {
    return UserService._instance;
  }

  public isWhitelisted() {
    return this._isWhitelisted;
  }

  public getIsNewUser() {
    let returnValue = this._isNewUser;
    if (returnValue) this._isNewUser = false;
    return returnValue;
  }

  public getUserName(): string {
    return this._userName;
  }

  public setUserName(value: string) {
    this._userName = value;
  }

  private setUserData(value): void {
    this._userData = value;
  }

  public getUserData(forceUpdate: boolean = false, override_wallet_address?: string) {
    return new Promise((resolve, reject) => {
      if (this._userData && !forceUpdate) {
        return resolve(this._userData);
      } else {
        return resolve(this.fetchUserData(override_wallet_address));
      }
    });
  }

  public getUserDataSync() {
    return this._userData;
  }

  public postUserData(name: string) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('user', [], { name: name, wallet: wallet })
      .then(res => {
        this._isNewUser = true;
        this._userData = res;
        return res;
      });
  }

  private fetchUserData(override_wallet_address?: string) {
    const wallet = override_wallet_address ? override_wallet_address : WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('user', [wallet], [])
      .then(res => {
        this._userData = res;
        this._userName = res.name;
        return res;
      });
  }

  public recordLogin() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .post('login', [], { user_address: wallet })
      .then(res => {
        this._isWhitelisted = res.whitelisted;
        return res;
      });
  }

  public handleDiscordConnectAction(pointer) {
    // TODO: Implement
  }

  public getUserDataIdempotent() {
    return new Promise((resolve, reject) => {
      return resolve(this.fetchUserDataIdempotent());
    });
  }

  private fetchUserDataIdempotent() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('user', [wallet], [])
      .then(res => {
        return res;
      });
  }
}
