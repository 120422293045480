import Phaser from 'phaser';

export default class LoginFailedScene extends Phaser.Scene {
  
  constructor() {
    super({
      key: 'LoginFailedScene'
    });
  }

  create() {
    // this.cameras.main.fadeIn(500, 0, 0, 0);
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.bitmapText(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 90, 'cc_outline', 'This wallet is\nnot on the whitelist.', 26, 0).setOrigin(0.5, 0).setCenterAlign();
    
    this.add.sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 30, 'party_blob').setOrigin(0.5, 0.5); 
  }
}