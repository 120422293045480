import Phaser from 'phaser';
import { LoadImageAssets } from '~/utils/AssetManager';

export default class AssetPreloaderScene extends Phaser.Scene {
  private _originatingScene;
  private _assets;

  constructor() {
    super('AssetPreloaderScene');
  }

  init(data: any) {
    this._originatingScene = data.originating_scene;
    this._assets = data.assets;
  }

  preload() {
    Object.keys(this._assets).forEach(key => {
      this.load.image(key, this._assets[key]);
    });
  }

  create() {
    this.scene.get(this._originatingScene)?.onLoaded();
    this.scene.stop();
  }
}
