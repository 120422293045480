import Phaser, { Scene } from 'phaser';
import { Subscription, unsubscribe } from 'rxjs';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import RoundImageButton from '../../../components/buttons/RoundImageButton';
import util from 'util';

export default class SquadMemberComponent extends FixWidthSizer {
  private _squadMember;
  private _squadMemberImage;
  private _scale;
  private _squadMemberLabel;
  private _hpLabel;
  private _yieldLabel;
  private _useOverScale;
  private _overScale;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    data: any,
    paneScale: number,
    useOverScale: boolean = true,
    overScale?: number,
    config?: any,
  ) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          width: paneScale * 150,
          space: {
            left: paneScale * 13,
            right: paneScale * 13,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._squadMember = data;
    this._useOverScale = useOverScale;
    this._overScale = overScale || 1.04;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Image button
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, this._squadMember.image_key);
    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 8;

    this._squadMemberImage = new RoundImageButton(scene, 0, 0, this._squadMember.image_key, rectConfig, this._useOverScale, this._overScale);
    this._squadMemberImage.displayHeight = this.applyScale(149);
    this._squadMemberImage.scaleX = this._squadMemberImage.scaleY;

    if (this._squadMember.disabled === true) {
      this._squadMemberImage.disableInteractive();
    }
    smImageData.destroy();
    scene.add.existing(this._squadMemberImage);

    const labelSizer = new FixWidthSizer(this.scene, {
      width: this._scale * 149,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    this._squadMemberLabel = scene.add.bitmapText(0, 0, 'cc_outline', this.getDisplayName().toUpperCase(), this.applyScale(16), 0).setOrigin(0, 0.5);

    labelSizer
      .add(this._squadMemberLabel, {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'squad_member_label',
      })
      .layout();

    this.add(this._squadMemberImage, {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'squad_member_image',
    });

    this.add(labelSizer, {
      padding: { left: 0, right: 0, top: this.applyScale(15), bottom: 0 },
      key: 'squad_member_label_sizer',
    });

    this._hpLabel = scene.add.bitmapText(0, 0, 'cc_outline', `HP: ${this._squadMember.hp?.toString() || '0'}`, this.applyScale(14), 0);
    this._hpLabel.alpha = 0.4;

    this.add(this._hpLabel, {
      padding: { left: 0, right: 0, top: this.applyScale(3), bottom: 0 },
      key: 'hp_label',
    });

    if (!data.show_hp) {
      this._hpLabel.setAlpha(0);
    }

    // Add Yield display (optional)
    this._yieldLabel = scene.add.bitmapText(
      0,
      0,
      'cc_outline',
      `Yield: ${this._squadMember.yield?.toString() || '0'} Stardust/day`,
      this.applyScale(14),
      0,
    );
    this._yieldLabel.alpha = 0.4;

    this.add(this._yieldLabel, {
      padding: { left: 0, right: 0, top: this.applyScale(3), bottom: 0 },
      key: 'yield_label',
    });

    if (!data.show_yield) {
      this._yieldLabel.setAlpha(0);
    }
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getDisplayName() {
    if (this._squadMember.nft_collection) {
      switch (this._squadMember.nft_collection) {
        case 'Star Wolvez Genesis':
          return `Genesis Wolf #${this._squadMember.nft_id}`;
        case 'GalacticApes':
          return `Galactic Ape #${parseInt(this._squadMember.nft_id) + 1}`;
        case 'Satoshi Runners Genesis':
          return `Satoshi Run... #${this._squadMember.nft_id}`;
        default:
          return this._squadMember.display_name;
      }
    }
    return this._squadMember.display_name;
  }

  setSquadMemberData(data) {
    this._squadMember = data;
    this.updateImage();
    this.updateText();
    this.setVisibility();
    this.setEnabled();
    this.layout();
  }

  updateImage() {
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(this.scene, 0, 0, this._squadMember.image_key);
    const smImageWidth = smImageData.width;

    rectConfig.radius = smImageWidth / 8;

    this._squadMemberImage.setTexture(this._squadMember.image_key, rectConfig);
    if (this._squadMember.disabled) {
      this._squadMemberImage.displayHeight = this.applyScale(149);
    } else {
      this._squadMemberImage.displayHeight = this.applyScale(149);
    }
    this._squadMemberImage.scaleX = this._squadMemberImage.scaleY;
    smImageData.destroy();
  }

  updateText() {
    this._squadMemberLabel.setText(this.getDisplayName().toUpperCase());
    this._hpLabel.setText(`HP: ${this._squadMember.hp?.toString() || '0'}`);
    this._yieldLabel.setText(`Yield: ${this._squadMember.yield?.toString() || '0'} Stardust/day`);
  }

  setVisibility() {
    this._hpLabel.setAlpha(this._squadMember.show_hp ? 0.4 : 0);
    this._yieldLabel.setAlpha(this._squadMember.show_yield ? 0.4 : 0);
  }

  setEnabled() {
    if (this._squadMember.disabled === true) {
      this._squadMemberImage.disableInteractive();
    } else {
      this._squadMemberImage.setInteractive();
    }
  }

  onClick() {
    return this._squadMemberImage.clickSubject.asObservable();
  }
}

Phaser.GameObjects.GameObjectFactory.register('squadMemberComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new SquadMemberComponent(this.scene, x, y));
});
