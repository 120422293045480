import ApiProxyService from './ApiProxyService';
import ConfigurationService from './ConfigurationService';
import util from 'util';
import WalletConnectService from './WalletConnectService';

export default class NFTService {
  private static _instance: NFTService = new NFTService();

  private _config: any;

  constructor() {
    if (NFTService._instance) {
      throw new Error('Error: Instantiation failed: Use NFTService.getInstance() instead of new.');
    }
    NFTService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): NFTService {
    return NFTService._instance;
  }

  public getNFTs(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number) {
    return this.fetchNFTs(nftIds, collection_slugs, startIndex, numRequested);
  }

  private fetchNFTs(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();

    const queryParams = nftIds && nftIds.length > 0 ? [`ids=${nftIds.toString()}`] : [];
    if (collection_slugs) queryParams.push(`collection_slug=${collection_slugs.toString()}`);
    if (startIndex !== undefined && startIndex !== null) queryParams.push(`start_index=${startIndex}`);
    if (numRequested !== undefined && numRequested !== null) queryParams.push(`count=${numRequested}`);

    return ApiProxyService.getInstance()
      .get('nfts', [wallet, this._config.api.endpoints.nfts.cache_route], queryParams)
      .then(res => {
        return this.enrichImageData(res);
      });
  }

  public getNFTsFromMarketplace(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number) {
    return this.fetchNFTsFromMarketplace(nftIds, collection_slugs, startIndex, numRequested);
  }

  private fetchNFTsFromMarketplace(nftIds?: any, collection_slugs?: any, startIndex?: number, numRequested?: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();

    const queryParams = nftIds && nftIds.length > 0 ? [`ids=${nftIds.toString()}`] : [];
    if (collection_slugs) queryParams.push(`collection_slug=${collection_slugs.toString()}`);
    if (startIndex !== undefined && startIndex !== null) queryParams.push(`start_index=${startIndex}`);
    if (numRequested !== undefined && numRequested !== null) queryParams.push(`count=${numRequested}`);

    return ApiProxyService.getInstance()
      .get('nfts', [wallet, this._config.api.endpoints.nfts.refresh_route], queryParams)
      .then(res => {
        return this.enrichImageData(res);
      });
  }

  private enrichImageData(data: any) {
    data.nfts = data.nfts.map(nft => {
      nft.image_key = `nft_${nft.id}`;
      return nft;
    });
    return data;
  }
}
