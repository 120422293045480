import Phaser from "phaser";
import { OverlapSizer } from "phaser3-rex-plugins/templates/ui/ui-components.js";

const rewardContainerHeight = 43;
const rewardContainerWidth = 213;
const rewardContainerRadius = 11;
const rewardContainerColor = 0x201425;
const rewardContainerAlpha = 0.7;
const rewardContainerBorderColor = 0x383d57;
const rewardContainerBorderWidth = 1;

const rewardGlareHeight = 6;
const rewardGlareWidth = 203;
const rewardGlareRadius = 4;
const rewardGlareColor = 0xffffff;
const rewardGlareAlpha = 0.14;

export default class RewardLabel extends OverlapSizer {
    private _scale: number;
    private _currency: string | undefined;
    private _text: string;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        paneScale: number,
        currency: string | undefined,
        text: string,
        config?: any
    ) {
        let conf = config
            ? config
            : {
                  x: x,
                  y: y,
                  width: rewardContainerWidth * paneScale,
                  height: rewardContainerHeight * paneScale,
                  space: {
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      item: 0,
                      line: 0
                  },
                  align: 0
              };

        super(scene, x, y, conf);

        this._scale = paneScale;
        this._currency = currency;
        this._text = text;

        this.createRewardLabel(scene, x, y);
    }

    createRewardLabel(scene: Phaser.Scene, x: number, y: number) {
        this.add(
            scene.add
                .rexRoundRectangle(
                    0, // x
                    0, // y
                    scene.applyScale(rewardContainerWidth), // width
                    scene.applyScale(rewardContainerHeight), // height
                    scene.applyScale(rewardContainerRadius), // radius
                    rewardContainerColor, // fillColor
                    rewardContainerAlpha // alpha
                )
                .setStrokeStyle(
                    rewardContainerBorderWidth,
                    rewardContainerBorderColor,
                    rewardContainerAlpha
                ),
            {
                key: "reward_container",
                align: "center",
                offsetX: 0,
                offsetY: 0,
                expand: false
            }
        )
            .add(
                scene.add.rexRoundRectangle(
                    0, // x
                    0, // y
                    scene.applyScale(rewardGlareWidth), // width
                    scene.applyScale(rewardGlareHeight), // height
                    scene.applyScale(rewardGlareRadius), // radius
                    rewardGlareColor, // fillColor
                    rewardGlareAlpha // alpha
                ),
                {
                    key: "reward_glare",
                    align: "center-top",
                    offsetX: 0,
                    offsetY: scene.applyScale(3),
                    expand: false
                }
            )
            .add(
                scene.rexUI.add.label({
                    x: 0,
                    y: 0,
                    width: scene.applyScale(200),
                    height: scene.applyScale(30),
                    orientation: "x",
                    rtl: false,
                    icon: this._currency
                        ? scene.add
                              .image(0, 0, this.getIconKey())
                              .setScale(this.getIconScale())
                        : null,
                    text: scene.add.bitmapText(
                        0,
                        0,
                        "cc_outline",
                        this._text.toLocaleString("en-us"),
                        scene.applyScale(20),
                        0
                    ),
                    space: {
                        icon: 8
                    },
                    align: "center"
                }),
                {
                    key: "reward_text",
                    align: "center",
                    offsetX: 0,
                    offsetY: 2,
                    expand: false
                }
            )
            .layout();
    }

    private getIconKey() {
        switch (this._currency) {
            case 'xp':
                return 'trophy_icon_small';
            default:
                return `${this._currency}_icon`;
        }
    }

    private getIconScale() {
        switch (this._currency) {
            case 'xp':
                return this.scene.applyScale(0.9);
            default:
                return this.scene.applyScale(0.5);
        }
    }
}

Phaser.GameObjects.GameObjectFactory.register(
    "rewardLabel",
    function (x: number, y: number, config: any) {
        // @ts-ignore
        return this.displayList.add(new RewardLabel(this.scene, x, y, config));
    }
);
