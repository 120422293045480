import HudScene from '~/scenes/hud/hudScene';
import UserService from '~/services/UserService';
import BaseRaidService from '~/services/BaseRaidService';
import Button from '../../../components/buttons/Button';
import { Base_Raid_Hud_Scene_Assets, Shop_Assets } from '~/utils/AssetLoader';
import SquadComponent from '~/components/hud/squadComponent';
import ProgressBar from '~/components/progressBar/ProgressBar';
import RaidLootInfoComponent from '~/components/baseRaid/RaidLootInfoComponent';
import RaidUnitsInfoComponent from '~/components/baseRaid/RaidUnitsInfoComponent';
import RaidClockComponent from '~/components/baseRaid/RaidClockComponent';
import ScaleService from '~/services/ScaleService';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const defaultTimeUntilAttack = 30; // seconds

const minCanvasWidth = 1330;
const minCanvasHeight = 860;

export default class BaseRaidHudScene extends HudScene {
  private _squad;
  private _defenderUserAddress;
  private _defenderMachines;
  private _defenderProgressBar;
  private _defenderHpLabel;
  private _attackerProgressBar;
  private _attackerHpLabel;
  private _baseRaidViewInfo;
  private _nextButton;
  private _attackButton;
  private _backToHomeButton;
  private _surrenderButton;
  private _raidClock;
  private _isAttacking;
  private _timeoutId;
  private _raidUnitsInfoComponent;
  private _self = this;

  constructor() {
    super({ key: 'BaseRaidHudScene' });
  }

  init(data) {
    console.log(data);
    this._defenderUserAddress = data.base_raid_view_info.defender_user?.user_address;
    this._defenderMachines = data.base_raid_view_info.defender_machines;
    this._baseRaidViewInfo = data.base_raid_view_info;
    this._isAttacking = data.is_attacking || false;
  }

  preload() {
    LoadImageAssets(this, Base_Raid_Hud_Scene_Assets);

    Object.keys(Shop_Assets).forEach(sa_key => {
      if (sa_key.includes('shop_machines')) this.load.image(sa_key, Shop_Assets[sa_key]);
    });

    super.preload();
  }

  destroy() {
    clearInterval(this._timeoutId);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(minCanvasWidth, minCanvasHeight);
    this.input.keyboard.enabled = true;
    clearInterval(this._timeoutId);

    UserService.getInstance()
      .getUserData(true, this._defenderUserAddress)
      .then(data => {
        this._self._userData = data;
        this._allowProgressBarClicks = false;
        this.addLevelBar(this);
        this.addResourcesComponent(this._defenderMachines);
      });

    // Countdown Timer
    this._raidClock = new RaidClockComponent(
      this,
      this.sys.canvas.width / 2,
      this.applyScale(43),
      { isAttacking: this._isAttacking, timeRemaining: this._isAttacking ? this.getTimeRemaining(this._baseRaidViewInfo) : defaultTimeUntilAttack },
      this._scale,
    );
    this.add.existing(this._raidClock);

    // Loot Info
    const lootInfoComponent = new RaidLootInfoComponent(
      this,
      this.applyScale(84),
      this.applyScale(230),
      this._baseRaidViewInfo,
      this._scale,
    ).setOrigin(0, 0);
    this.add.existing(lootInfoComponent);

    // Bottom Info Bar
    this.addBottomInfoBar();

    // Action Buttons
    this.addButtons();

    // Set status check interval
    this.addStatusCheckTimer();

    // Raid Units Component
    if (this._isAttacking) {
      this._raidUnitsInfoComponent = new RaidUnitsInfoComponent(
        this,
        this.applyScale(155),
        this.sys.canvas.height - this.applyScale(360),
        this._baseRaidViewInfo,
        this._scale,
      ).setOrigin(0, 0);

      this.add.existing(this._raidUnitsInfoComponent);
    }

    this.addKeyBindings();
  }

  private addBottomInfoBar() {
    const sizer = this.rexUI.add
      .overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height - this.applyScale(50), {
        width: this.sys.canvas.width,
        height: this.applyScale(100),
        space: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          item: 0,
          line: 0,
        },
        align: 0,
      })
      .addBackground(this.rexUI.add.roundRectangle(0, 0, 0, 0, 0, 0x350926, 0.8));

    this.add.existing(sizer);

    this._squad = new SquadComponent(this, 0, 0, this._scale, {
      display_bg: false,
      allow_clicks: false,
    });

    sizer.add(this._squad, {
      key: 'squad_component',
      align: 'left-center',
      offsetX: this.applyScale(7),
      offsetY: 0,
      expand: false,
    });

    const attacker_info_bar = this.rexUI.add
      .overlapSizer({
        width: this.applyScale(380),
        height: this.applyScale(65),
      })
      .addBackground(this.rexUI.add.roundRectangle(0, 0, 0, 0, this.applyScale(16), 0x350926, 0.9))
      .add(
        this.rexUI.add.label({
          text: this.add.bitmapText(0, 0, 'cc_outline', "YOUR SQUAD'S REMAINING HP", this.applyScale(16)).setAlpha(0.3),
        }),
        {
          align: 'left-top',
          padding: { left: this.applyScale(10), right: 0, top: this.applyScale(5), bottom: 0 },
          expand: false,
        },
      );

    this._attackerHpLabel = this.rexUI.add.label({
      text: this.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          `${this._baseRaidViewInfo.attacker_hp}/${this._baseRaidViewInfo.attacker_hp_beginning || this._baseRaidViewInfo.attacker_hp}`,
          this.applyScale(16),
        )
        .setAlpha(1),
    });

    attacker_info_bar.add(this._attackerHpLabel, {
      align: 'right-top',
      padding: { left: 0, right: this.applyScale(10), top: this.applyScale(5), bottom: 0 },
      expand: false,
    });

    this._attackerProgressBar = new ProgressBar(attacker_info_bar.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: 360,
        height: 22,
        radius: 6,
        color: 0x00a6d3,
        alpha: 1,
        backgroundColor: 0xb48dc7,
        backgroundAlpha: 0.4,
        minValue: 1,
        maxValue: this._baseRaidViewInfo.attacker_hp_beginning || this._baseRaidViewInfo.attacker_hp,
        progressValue: this._baseRaidViewInfo.attacker_hp,
        glare: {
          height: 6,
          width: 360 - 10,
          radius: 3,
          color: 0xffffff,
          alpha: 0.17,
        },
      },
    });

    attacker_info_bar.add(this._attackerProgressBar, {
      key: 'attacker_progress_bar',
      align: 'left-center',
      offsetX: this.applyScale(10),
      offsetY: this.applyScale(10),
      expand: false,
    });

    sizer.add(attacker_info_bar, {
      key: 'attacker_info_bar',
      align: 'left-center',
      offsetX: this.applyScale(260),
      offsetY: 0,
      expand: false,
    });

    const dmg_per_sec = this.rexUI.add
      .overlapSizer({
        width: this.applyScale(165),
        height: this.applyScale(65),
      })
      .addBackground(this.rexUI.add.roundRectangle(0, 0, 0, 0, this.applyScale(16), 0x350926, 0.9))
      .add(
        this.rexUI.add.label({
          text: this.add.bitmapText(0, 0, 'cc_outline', 'YOUR DMG PER SEC', this.applyScale(16)).setAlpha(0.3),
        }),
        {
          align: 'left-top',
          padding: { left: this.applyScale(10), right: 0, top: this.applyScale(5), bottom: 0 },
          expand: false,
        },
      )
      .add(
        this.rexUI.add.label({
          text: this.add.bitmapText(0, 0, 'cc_outline', Math.floor(this._baseRaidViewInfo.attacker_dps).toString(), this.applyScale(20)).setAlpha(1),
        }),
        {
          align: 'left-center',
          padding: { left: this.applyScale(10), right: 0, top: this.applyScale(15), bottom: 0 },
          expand: false,
        },
      );

    sizer.add(dmg_per_sec, {
      key: 'dmg_per_sec',
      align: 'left-center',
      offsetX: this.applyScale(655),
      offsetY: 0,
      expand: false,
    });

    const defender_info_bar = this.rexUI.add
      .overlapSizer({
        width: this.applyScale(380),
        height: this.applyScale(65),
      })
      .addBackground(this.rexUI.add.roundRectangle(0, 0, 0, 0, this.applyScale(16), 0x350926, 0.9))
      .add(
        this.rexUI.add.label({
          text: this.add.bitmapText(0, 0, 'cc_outline', 'HOMEBASE HP REMAINING', this.applyScale(16)).setAlpha(0.3),
        }),
        {
          align: 'left-top',
          padding: { left: this.applyScale(10), right: 0, top: this.applyScale(5), bottom: 0 },
          expand: false,
          key: 'defender_homebase_hp_label',
        },
      );

    this._defenderHpLabel = this.rexUI.add.label({
      text: this.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          `${this._baseRaidViewInfo.defender_hp}/${this._baseRaidViewInfo.defender_hp_beginning || this._baseRaidViewInfo.defender_hp}`,
          this.applyScale(16),
        )
        .setAlpha(1),
    });

    defender_info_bar.add(this._defenderHpLabel, {
      align: 'right-top',
      padding: { left: 0, right: this.applyScale(10), top: this.applyScale(5), bottom: 0 },
      expand: false,
      key: 'defender_hp_label',
    });

    this._defenderProgressBar = new ProgressBar(defender_info_bar.scene, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: '',
        fontStyle: null,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: 360,
        height: 22,
        radius: 6,
        color: 0xd30059,
        alpha: 1,
        backgroundColor: 0xb48dc7,
        backgroundAlpha: 0.4,
        minValue: 1,
        maxValue: this._baseRaidViewInfo.defender_hp_beginning || this._baseRaidViewInfo.defender_hp,
        progressValue: this._baseRaidViewInfo.defender_hp,
        glare: {
          height: 6,
          width: 360 - 10,
          radius: 3,
          color: 0xffffff,
          alpha: 0.17,
        },
      },
    });

    defender_info_bar.add(this._defenderProgressBar, {
      key: 'attacker_progress_bar',
      align: 'left-center',
      offsetX: this.applyScale(10),
      offsetY: this.applyScale(10),
      expand: false,
    });

    sizer.add(defender_info_bar, {
      key: 'defender_info_bar',
      align: 'right-center',
      offsetX: this.applyScale(-7),
      offsetY: 0,
      expand: false,
    });

    sizer.layout();
  }

  private addButtons() {
    // Back to Home Button
    this._backToHomeButton = new Button(
      this.scene.scene,
      this.applyScale(20),
      this.sys.canvas.height - this.applyScale(150),
      this._isAttacking ? 'base_raid_back_to_home_btn_gray' : 'base_raid_back_to_home_btn',
      false,
    )
      .setScrollFactor(0)
      .setScale(this._scale)
      .setOrigin(0, 0.5);

    this._backToHomeButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
      this.scene.scene.sound.play('hud-button-hover');
    });

    this._backToHomeButton.onClick().subscribe(() => {
      this.backToHomeButtonHandler();
    });

    this.add.existing(this._backToHomeButton);

    // Check attack state for which buttons to draw
    if (this._isAttacking) {
      // Surrender Button
      this._surrenderButton = new Button(
        this.scene.scene,
        this.sys.canvas.width - this.applyScale(20),
        this.sys.canvas.height - this.applyScale(150),
        'base_raid_surrender_btn',
        false,
      )
        .setScrollFactor(0)
        .setScale(this._scale)
        .setOrigin(1, 0.5);

      this._surrenderButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
        this.scene.scene.sound.play('hud-button-hover');
      });

      this._surrenderButton.onClick().subscribe(() => {
        this.surrenderButtonClickHandler();
      });

      this.add.existing(this._surrenderButton);
    } else {
      // Attack Button
      this._attackButton = new Button(
        this.scene.scene,
        this.sys.canvas.width - this.applyScale(300),
        this.sys.canvas.height - this.applyScale(150),
        'base_raid_attack_btn',
        false,
      )
        .setScrollFactor(0)
        .setScale(this._scale)
        .setOrigin(0, 0.5);

      this._attackButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
        this.scene.scene.sound.play('hud-button-hover');
      });

      this._attackButton.onClick().subscribe(() => {
        this.startAttack();
      });

      this.add.existing(this._attackButton);

      // View Next Button
      this._nextButton = new Button(
        this.scene.scene,
        this.sys.canvas.width - this.applyScale(20),
        this.sys.canvas.height - this.applyScale(150),
        'base_raid_next_btn',
        false,
      )
        .setScrollFactor(0)
        .setScale(this._scale)
        .setInteractive({ cursor: 'pointer' })
        .setOrigin(1, 0.5);

      this._nextButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
        this.scene.scene.sound.play('hud-button-hover');
      });

      this._nextButton.onClick().subscribe(() => {
        this.nextButtonClickHandler();
      });

      this.add.existing(this._nextButton);
    }
  }

  backToHomeButtonHandler() {
    clearInterval(this._timeoutId);

    UserService.getInstance()
      .getUserData(true)
      .then(data => {
        this.unloadBaseRaidAssets();
        this.scene.stop();
        this.scene.stop('BaseRaidDetailsScene');
        this.scene.stop('BaseRaidHudScene');
        this.scene.start('HomebaseScene');
      });
  }

  nextButtonClickHandler() {
    this._nextButton.disableInteractive();

    this.scene.stop();
    this.scene.stop('BaseRaidDetailsScene');
    this.scene.stop('BaseRaidHudScene');
    this.scene.start('BaseRaidLoadingScene', { next: 1 });
  }

  surrenderButtonClickHandler() {
    clearInterval(this._timeoutId);

    // Disable homebase/hud
    this.scene.get('BaseRaidDetailsScene').input.enabled = false;
    this.input.enabled = false;
    this.input.keyboard.enabled = false;

    // Show confirmation modal
    this.scene.launch('BaseRaidConfirmSurrenderScene');
    this.scene.bringToTop('BaseRaidConfirmSurrenderScene');
  }

  private addKeyBindings() {
    this.input.keyboard.on('keydown-ENTER', event => {
      if (!this._isAttacking) {
        this.startAttack();
      }
    });

    this.input.keyboard.on('keydown-ESC', event => {
      this.backToHomeButtonHandler();
    });

    this.input.keyboard.on('keydown-SPACE', event => {
      if (!this._isAttacking) {
        this.nextButtonClickHandler();
      }
    });

    this.input.keyboard.on('keydown-END', event => {
      if (this._isAttacking) {
        this.surrenderButtonClickHandler();
      }
    });
  }

  public startAttack() {
    BaseRaidService.getInstance()
      .startAttack(this._defenderUserAddress)
      .then(data => {
        console.log(data);
        this.switchToAttackMode(data);
      });
  }

  public handleAttackFinished() {
    BaseRaidService.getInstance()
      .viewAttack()
      .then(res => {
        // Check if attack is finished on back end, display result scene
        if (res.base_raid_id !== 0 && res.completed) {
          // Clear time intervals
          this.time.removeAllEvents();

          // Show results of previous raid
          const resultData = {
            isAttacker: true,
            neon_gold: res.attacker_possible_loot.neon_gold,
            space_elixir: res.attacker_possible_loot.space_elixir,
            stardust: res.attacker_possible_loot.stardust,
            xp: res.is_win ? res.attacker_win_xp : res.attacker_lose_xp,
            isVictory: res.is_win,
          };
          this.scene.stop('BaseRaidDetailsScene');
          this.scene.stop('BaseRaidHudScene');
          this.scene.start('BaseRaidResultScene', resultData);
          this.scene.bringToTop('BaseRaidResultScene');
        } else {
          // Attack must not have finished on back end yet, wait  seconds and try again
          setTimeout(this.handleAttackFinished.bind(this), 2000);
        }
      });
  }

  private switchToAttackMode(attackData: any) {
    // Update base info
    this._baseRaidViewInfo = Object.assign({}, this._baseRaidViewInfo, attackData);

    // Set isAttacking flag
    this._isAttacking = true;

    // Change buttons
    this._backToHomeButton.setAlpha(0).destroy();
    this._nextButton.setAlpha(0).destroy();
    this._attackButton.setAlpha(0).destroy();
    this.addButtons();

    // Set clock time
    this.setAttackTimer(attackData);

    // Set attack update timer
    this.addStatusCheckTimer();

    // Add Troops Component
    this._raidUnitsInfoComponent = new RaidUnitsInfoComponent(
      this,
      this.applyScale(155),
      this.sys.canvas.height - this.applyScale(360),
      this._baseRaidViewInfo,
      this._scale,
    ).setOrigin(0, 0);
    this.add.existing(this._raidUnitsInfoComponent);
  }

  private setAttackTimer(attackData: any) {
    const timeRemaining = this.getTimeRemaining(attackData);

    this._raidClock.setClockData({ timeRemaining: timeRemaining, isAttacking: this._isAttacking });
  }

  private getTimeRemaining(attackData: any) {
    const unixNow = Math.floor(Date.now() / 1000);
    const lastAttackStep = attackData.base_raid_execution[attackData.base_raid_execution.length - 1];
    var timeRemaining;

    if (!lastAttackStep) {
      // Attack must have finished
      timeRemaining = 0;
    } else {
      // Attack is ongoing
      const endTime = lastAttackStep.end_time;
      timeRemaining = endTime - unixNow;
    }

    return timeRemaining;
  }

  private getCurrentStepTimeRemaining(attackData: any) {
    if (attackData && attackData.base_raid_execution) {
      const unixNow = Math.floor(Date.now() / 1000);
      const currentAttackStep = attackData.base_raid_execution[0];
      var timeRemaining;

      if (!currentAttackStep) {
        // Attack must have finished
        timeRemaining = 0;
      } else {
        // Attack is ongoing
        const endTime = currentAttackStep.end_time;
        timeRemaining = endTime - unixNow;
      }

      return timeRemaining;
    }

    return 0;
  }

  private checkAttackStatus() {
    if (this._baseRaidViewInfo.base_raid_execution) {
      const nextStepFinishTime = this._baseRaidViewInfo.base_raid_execution[0]?.end_time || 0;
      const timeNow = Math.floor(Date.now() / 1000);

      // console.log(`timeNow: ${timeNow}`);
      // console.log(`nextStepFinishTime: ${nextStepFinishTime}`);

      if (timeNow >= nextStepFinishTime + 1) {
        this.updateRaidStatus(this);
      }
    }
  }

  private updateRaidStatus(this: any) {
    BaseRaidService.getInstance()
      .viewAttack()
      .then(attackRes => {
        if (attackRes.success) {
          this._baseRaidViewInfo = attackRes;

          // Update labels and progress bars
          this._defenderHpLabel?.setText(`${attackRes.defender_hp}/${attackRes.defender_hp_beginning}`);
          this._defenderProgressBar?.setProgress(this, attackRes.defender_hp);

          this._attackerHpLabel?.setText(`${attackRes.attacker_hp}/${attackRes.attacker_hp_beginning}`);
          this._attackerProgressBar?.setProgress(this, attackRes.attacker_hp);

          // Update Raid Units Component
          this._raidUnitsInfoComponent?.updateData(this._baseRaidViewInfo);

          // Update machines on homebase
          this.scene.get('BaseRaidDetailsScene')?.disableDestroyedMachines(this._baseRaidViewInfo);

          clearInterval(this._timeoutId);
          this.addStatusCheckTimer();
        }
      });
  }

  private addStatusCheckTimer() {
    if (this._isAttacking && this._baseRaidViewInfo.base_raid_execution.length > 1) {
      this._timeoutId = setInterval(this.checkAttackStatus.bind(this), 2000);
    }
  }

  public restartScene() {
    clearInterval(this._timeoutId);
    this.scene.restart({ base_raid_view_info: this._baseRaidViewInfo, is_attacking: this._isAttacking });
  }

  public getScale() {
    return this._scale;
  }

  unloadBaseRaidAssets() {
    UnloadImages(this, Object.keys(Base_Raid_Hud_Scene_Assets));
    UnloadImages(
      this,
      Object.keys(Shop_Assets).filter(sa_key => sa_key.includes('shop_machines')),
    );
  }
}
