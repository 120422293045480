import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import MachineService from '~/services/MachineService';
import ScaleService from '~/services/ScaleService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';
import { PVP_Scene_Assets } from '~/utils/AssetLoader';
import { UnloadImages } from '~/utils/AssetManager';
import { getHomebaseTraits } from '~/utils/TraitUtils';

const modalHeight = 455;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class ShopPurchaseConfirmScene extends Phaser.Scene {
  private _scale;
  private _item;
  private _isUpgrade;
  private _homebaseTraits;

  init(data: any) {
    this._item = data.item;
    this._isUpgrade = data.isUpgrade;
  }

  constructor() {
    super({
      key: 'ShopPurchaseConfirmScene',
    });
  }

  preload() {
    this.load.image('pvp_confirm_button', PVP_Scene_Assets.pvp_confirm_button);
    this.load.image('pvp_cancel_button', PVP_Scene_Assets.pvp_cancel_button);
  }

  create() {
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    this._homebaseTraits = getHomebaseTraits(SquadService.getInstance().getTraitsSync(), MachineService.getInstance().getHomebaseMachineMultiplier());

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add
      .rexRoundRectangle(
        this.sys.canvas.width / 2, // x
        this.sys.canvas.height / 2, // y
        this.applyScale(modalWidth), // width
        this.applyScale(modalHeight), // height
        this.applyScale(modalRadius), // radius
        modalColor, // fillColor
        modalAlpha, // alpha
      )
      .setInteractive({ cursor: 'default' })
      .setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add Icons
    this.add.image(this.sys.canvas.width / 2, modalBg.getTopLeft().y, 'neon_gold_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 - this.applyScale(74), modalBg.getTopLeft().y, 'space_elixir_icon_large').setScale(this._scale);
    this.add.image(this.sys.canvas.width / 2 + this.applyScale(80), modalBg.getTopLeft().y, 'stardust_icon_large').setScale(this._scale);

    // Add Sizer for content
    var sizer = this.rexUI.add.overlapSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(300),
      height: this.applyScale(320),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    sizer.add(
      this.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          this._isUpgrade ? `Purchase ${this._item.display_name} upgrade? Cost:` : `Purchase ${this._item.display_name}? Cost:`,
          this.applyScale(24),
          1,
        )
        .setMaxWidth(300),
      {
        key: 'title_text',
        align: 'center-top',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      },
    );

    // Add Fee Labels
    const space_elixir_label = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', this._item.cost_space_elixir.toLocaleString('en-us'));

    const neon_gold_label = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', this._item.cost_neon_gold.toLocaleString('en-us'));

    const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', this._item.cost_stardust.toLocaleString('en-us'));

    sizer.add(this.add.existing(space_elixir_label), {
      key: 'space_elixir_label',
      align: 'center',
      offsetX: 0,
      offsetY: this.applyScale(-50),
      expand: false,
    });

    sizer.add(this.add.existing(neon_gold_label), {
      key: 'neon_gold_label',
      align: 'center',
      offsetX: 0,
      offsetY: this.applyScale(5),
      expand: false,
    });

    sizer.add(this.add.existing(stardust_label), {
      key: 'stardust_label',
      align: 'center',
      offsetX: 0,
      offsetY: this.applyScale(60),
      expand: false,
    });

    // Add Note
    sizer.add(
      this.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          this._isUpgrade
            ? `Note: Upgrading this machine will\ntake ${this.secondsToTime(this._item.next_level_build_total_time)}`
            : 'Note: Machines will be automatically\nadded to homebase upon purchase.',
          this.applyScale(20),
          1,
        )
        .setAlpha(0.6),
      {
        key: 'note_text',
        align: 'center-bottom',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      },
    );

    sizer.layout();

    // Add Cancel Button
    const cancelButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      false,
    ).setScale(this._scale);
    cancelButton.onClick().subscribe(pointer => {
      if (this._isUpgrade) {
        this.scene.get('HomebaseScene').input.enabled = true;
        this.scene.resume('HudScene');
      } else {
        this.scene.resume('ShopModalScene');
      }
      this.scene.stop();
    });
    this.add.existing(cancelButton);

    // Add Confirm Button
    const confirmButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(75),
      modalBg.getBottomLeft().y,
      'pvp_confirm_button',
      false,
    ).setScale(this._scale);

    confirmButton.onClick().subscribe(pointer => {
      confirmButton.setDisabled(true);
      if (this._isUpgrade) {
        MachineService.getInstance()
          .upgradeMachine(this._item.user_machine_id, this._homebaseTraits.upgradeBuildTime * 100, this._homebaseTraits.reduceCost * 100)
          .then(result => {
            if (result.success) {
              this.scene.scene.sound.play('machines-button');
              this.scene.get('HomebaseScene').updateMachines();
            }
            this.unloadAssets();
            this.scene.start('ShopPurchaseResultScene', {
              item: this._item,
              itemType: 'machine_upgrade',
              purchaseResult: result,
            });
          });
      } else {
        MachineService.getInstance()
          .purchaseMachine(this._item.machine_id, this._homebaseTraits.machineBuildTime * 100, this._homebaseTraits.reduceCost * 100)
          .then(result => {
            if (result.success) {
              this.scene.scene.sound.play('machines-button');
              this.scene.get('HomebaseScene').updateMachines();
              //this.scene.get('HomebaseScene').addMachine(Object.assign({}, this._item, result.machine_info), 0, 0, true);
            }
            this.unloadAssets();
            this.scene.start('ShopPurchaseResultScene', {
              item: this._item,
              itemType: 'machine',
              purchaseResult: result,
            });
          });
      }
    });
    this.add.existing(confirmButton);

    var userData = UserService.getInstance().getUserDataSync();

    if (
      userData.space_elixir < this._item.cost_space_elixir ||
      userData.neon_gold < this._item.cost_neon_gold ||
      userData.stardust < this._item.cost_stardust
    ) {
      confirmButton.setDisabled(true);
      confirmButton.setAlpha(0.5);
    }

    // sizer.onClickOutside((co, go, pointer) => {
    //     if (pointer.getDistance() > 20) {
    //       return;
    //     }
    //     if (this._isUpgrade) {
    //         this.scene.get("HomebaseScene").input.enabled = true
    //         this.scene.resume("HudScene")
    //     } else {
    //         this.scene.resume("ShopModalScene")
    //     }
    //     this.scene.stop()
    // });
  }

  private secondsToTime(seconds: number) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var hDisplay = h > 0 ? `${h} ${h > 1 ? 'hours' : 'hour'}` : '';
    var mDisplay = m > 0 ? `${m} ${m > 1 ? 'minutes' : 'minute'}` : '';
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    if (h === 0 && m === 0 && s > 0) {
      return '< 1m';
    }
    return h > 0 ? `${hDisplay} ${mDisplay}` : mDisplay;
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, ['pvp_confirm_button', 'pvp_cancel_button']);
  }
}
