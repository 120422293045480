import Phaser from 'phaser';
import _ from 'lodash';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import TrainableTroopComponent from '~/components/troops/trainableTroopComponent';
import TroopService from '~/services/TroopService';
import MachineService from '~/services/MachineService';
import Button from '~/components/buttons/Button';

const paneHeight = 377;
const paneWidth = 1009;
const cellWidth = 323;
const cellHeight = 188;

const levelFilters = ['all', 'level_1', 'level_2', 'level_3', 'level_4', 'level_5', 'level_6', 'level_7', 'level_8', 'level_9', 'level_10'];

export default class TrainableTroopsContentPane extends OverlapSizer {
  private _title: any;
  private _trainableTroops;
  private _levelTabs;
  private _selectedLevelTab;
  private _table;
  private _machine;
  private _filterTabSizer;

  private _all_tab;
  private _level_1_tab;
  private _level_2_tab;
  private _level_3_tab;
  private _level_4_tab;
  private _level_5_tab;
  private _level_6_tab;
  private _level_7_tab;
  private _level_8_tab;
  private _level_9_tab;
  private _level_10_tab;

  private _scale;
  private _noTroopsToTrainLabel;

  constructor(scene: Phaser.Scene, x: number, y: number, machine: any, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          height: paneHeight * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._machine = machine;

    TroopService.getInstance()
      .getTrainableTroopsByMachine(this._machine.machine_id, false)
      .then(res => {
        this._trainableTroops = _.sortBy(
          res.filter(troop => troop.machine_unit_id !== null),
          tr => {
            return !this.isTroopEnabled(tr);
          },
        );
        this.createComponent(scene, x, y);
      });
  }

  createComponent(scene: Phaser.Scene, x: number, y: number) {
    // Filter Tabs
    this.addLevelTabs();

    // Trainable Troops table
    this.createTable(scene, this._trainableTroops, 0, 0, this._scale);

    // this._noTroopsToTrainLabel = scene.add.bitmapText(
    //     x - this.scene.applyScale(259),
    //     y + this.scene.applyScale(113),
    //     'cc_outline',
    //     'You have no available troops to train.',
    //     this.scene.applyScale(20),
    //     0
    // ).setOrigin(0).setAlpha(0);

    //this.add(this._noTroopsToTrainLabel);
    this.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  private addLevelTabs() {
    // Initialize array
    this._levelTabs = [];

    // Add sizer for buttons
    this._filterTabSizer = this.scene.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(paneWidth),
      height: this.applyScale(41),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Create buttons, add to sizer, add to array with key
    levelFilters.forEach(lf => {
      this[`_${lf}_tab`] = new Button(this.scene, 0, 0, `${lf}_tab_inactive`, false).setOrigin(0, 0.5).setScale(this._scale);

      this._filterTabSizer.add(this.scene.add.existing(this[`_${lf}_tab`]), {
        padding: { left: 0, right: lf === 'level_10' ? 0 : this.scene.applyScale(14), top: 0, bottom: 0 },
        key: `${lf}_tab`,
      });

      const level = parseInt(lf === 'all' ? '-1' : lf.slice(6));
      this._levelTabs.push({ key: `_${lf}_tab`, level: level, button: this[`_${lf}_tab`] });
    });

    this._levelTabs.forEach(lt => {
      const clickHandler = _.curry(this.tabClickHandler)(this, lt.level, this[lt.key]);
      this[lt.key].onClick().subscribe(clickHandler);
    });

    // Set 'All' tab as active tab
    this._all_tab.setTexture('all_tab_active');
    this._selectedLevelTab = this._all_tab;

    // Add button sizer to component
    this.add(this._filterTabSizer, {
      key: 'filter_tab_sizer',
      align: 'center-top',
      offsetX: this.scene.applyScale(0),
      offsetY: this.scene.applyScale(0),
      expand: false,
    });
  }

  tabClickHandler(contentPane, level, tab, pointer) {
    if (contentPane._selectedLevelTab === tab) {
      return;
    }

    contentPane._selectedLevelTab = tab;

    contentPane.setActiveTab(tab);
    contentPane.applyTabFilter(level);
  }

  setActiveTab(tab) {
    this._levelTabs.forEach(lt => {
      if (lt.button === tab) {
        lt.button.setTexture(`${lt.key.slice(1)}_active`);
      } else {
        lt.button.setTexture(`${lt.key.slice(1)}_inactive`);
      }
    });
  }

  applyTabFilter(level) {
    var filteredData;

    if (level === -1) {
      // 'All' selected - No filter
      filteredData = this._trainableTroops;
    } else {
      // Level filter selected, filter data
      filteredData = this._trainableTroops.filter(tt => tt.level === level);
    }

    // Redraw table
    this.removeTable();
    this.createTable(this.scene, filteredData, 0, 0, this._scale);
    this.layout();
  }

  removeTable() {
    this._table.setAlpha(0);
    this.remove(this._table, true); // true = destroy table
    this._table = undefined;
  }

  createTable(scene, data, x, y, paneScale) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.scene.applyScale(paneWidth),
      height: this.scene.applyScale(paneHeight - 64),
      items: data,

      table: {
        cellWidth: this.scene.applyScale(cellWidth + 20),
        cellHeight: this.scene.applyScale(cellHeight + 20),
        columns: 3,
        clamplTableOXY: false,
        reuseCellContainer: true,
        scrollMode: 0,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        var machine = scene.getMachine();
        var ttComp = new TrainableTroopComponent(scene, x, y, machine, item, scene.getScale());

        // if (ttComp.isTroopEnabled()) {
        //   ttComp.onClick((button, gameObject, pointer, event) => {
        //     ttComp.disableInteractive();
        //     const userMachineId =
        //       machine.user_machine_id === 0
        //         ? MachineService.getInstance().getUserMachineFromMachineId(item.machine_id).user_machine_id
        //         : machine.user_machine_id;
        //     TroopService.getInstance()
        //       .trainTroop(userMachineId, item.level)
        //       .then(res => {
        //         TroopService.getInstance()
        //           .getArmy(true)
        //           .then(armyRes => {
        //             if (res.success) {
        //               // Update training troops component
        //               scene.updateTrainingPane();
        //               scene.updateCapacityBar();
        //             } else {
        //               // Show training failed message
        //               scene.scene.pause();
        //               scene.scene.launch('MessageScene', {
        //                 title: 'Failed to train troop!',
        //                 message: 'Your Cadet Dorms are at max capacity.',
        //                 scene: 'ArmyModalScene',
        //               });
        //             }
        //             ttComp.setInteractive();
        //           });
        //       });
        //   }, this);

        //   ttComp.on('child.pressstart', (child, pointer, event) => {
        //     // Show training failed message
        //     scene.scene.pause();
        //     scene.scene.launch('MessageScene', {
        //       title: 'Failed to train troop!',
        //       message: 'Your Cadet Dorms are at max capacity.',
        //       scene: 'ArmyModalScene',
        //     });
        //   });
        //}

        // ttComp.onClick().subscribe(pointer => {
        //     scene.addSquadMember(item);
        // });

        return scene.add.existing(ttComp);
      },
    });

    table.setItems(data).scrollToTop();
    table;

    this._table = table.layout();

    this.add(this._table, {
      key: 'trainable_troops_table',
      align: 'center-top',
      offsetX: this.scene.applyScale(0),
      offsetY: this.scene.applyScale(64),
      expand: false,
    });
  }

  private isTroopEnabled(troopItem: any) {
    // Check capacity
    const capacity = MachineService.getInstance().getCadetDormsCapacity();
    const troopCapacityUsed = TroopService.getInstance().getTroopCapacityUsed();

    if (troopCapacityUsed >= capacity) {
      return false;
    }

    // Check machine level
    if (this._machine.level) {
      return troopItem.base_machine_level_required <= this._machine.level;
    }

    const machineLevel = MachineService.getInstance().getUserMachineLevel(troopItem.machine_id);
    return troopItem.base_machine_level_required <= machineLevel;
  }
}

Phaser.GameObjects.GameObjectFactory.register('trainableTroopsContentPane', function (x: number, y: number, playersData: any) {
  // @ts-ignore
  return this.displayList.add(new TrainableTroopsContentPane(this.scene, x, y, playersData));
});
