import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import RewardLabel from '~/components/labels/rewardLabel';

// Pane Details
const paneHeight = 300;
const paneWidth = 500;
const paneRadius = 25;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

export default class BossFightResultsPayoutPane extends OverlapSizer {
  private _scale: number;

  private _payout;
  private _spaceElixirBar;
  private _neonGoldBar;
  private _starDustBar;
  private _xpBar;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._payout = data;
    this._scale = paneScale;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    if (this._payout != undefined) {
      // Add Sizer for content
      var sizer = scene.rexUI.add.overlapSizer({
        width: scene.applyScale(paneWidth),
        height: scene.applyScale(paneHeight),
        space: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          item: 0,
          line: 0,
        },
        align: 0,
      });

      // Add Divider Top
      const dividerTop = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

      sizer.add(dividerTop, {
        key: 'br-divider-top',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(0),
        expand: false,
      });

      // Add Payout Text
      var resultsText = scene.add.bitmapText(0, 0, 'cc_outline', 'Payout', scene.applyScale(30), 0);
      sizer.add(resultsText, {
        key: `payout-title`,
        align: 'center-top',
        offsetX: scene.applyScale(0),
        offsetY: scene.applyScale(20),
        expand: false,
      });

      // Add Divider Below
      const dividerBelow = scene.add.image(0, 0, 'battleroyale_results_divider').setScale(this._scale);

      sizer.add(dividerBelow, {
        key: 'br-divider-below',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(70),
        expand: false,
      });

      // Payout Details
      this._spaceElixirBar = new RewardLabel(scene, 0, 0, this._scale, 'space_elixir', this._payout.spaceElixir.toLocaleString('en-us'));

      sizer.add(this._spaceElixirBar, {
        key: 'br-se-bar',
        align: 'left-top',
        offsetX: this.applyScale(25),
        offsetY: this.applyScale(120),
        expand: false,
      });

      this._neonGoldBar = new RewardLabel(scene, 0, 0, this._scale, 'neon_gold', this._payout.neonGold.toLocaleString('en-us'));

      sizer.add(this._neonGoldBar, {
        key: 'br-ng-bar',
        align: 'left-top',
        offsetX: this.applyScale(262),
        offsetY: this.applyScale(120),
        expand: false,
      });

      this._starDustBar = new RewardLabel(scene, 0, 0, this._scale, 'stardust', this._payout.starDust.toLocaleString('en-us'));

      sizer.add(this._starDustBar, {
        key: 'bossfight-sd-bar',
        align: 'left-top',
        offsetX: this.applyScale(25),
        offsetY: this.applyScale(180),
        expand: false,
      });

      this._xpBar = new RewardLabel(scene, 0, 0, this._scale, undefined, /*`${this._payout.xp} xp`*/ '500 xp');

      sizer.add(this._xpBar, {
        key: 'bossfight-xp-bar',
        align: 'left-top',
        offsetX: this.applyScale(262),
        offsetY: this.applyScale(180),
        expand: false,
      });

      this.add(sizer, {
        key: 'resources-panel',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(0),
        expand: false,
      });
    }
    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('bossFightResultsPayoutPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightResultsPayoutPane(this.scene, x, y));
});
