import util from 'util';
import { arbitrum, mainnet } from '@wagmi/core/chains'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import ConfigurationService from './ConfigurationService';

const projectId = 'a85cfb9ade7953280d3008188d57bd54'

// 2. Create wagmiConfig
const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata: {
    name: 'Star Wolvez',
    description: 'Star Wolvez is the future',
    url: ConfigurationService.getInstance().getConfig().url
  }
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeMode: 'light' })

export default class WalletConnectService {

    private static _instance:WalletConnectService = new WalletConnectService();
    private events: Phaser.Events.EventEmitter;
    private _walletAddress = '';

    constructor() {
        if(WalletConnectService._instance){
            throw new Error("Error: Instantiation failed: Use WalletConnectService.getInstance() instead of new.");
        }
        WalletConnectService._instance = this;
        this.events = new Phaser.Events.EventEmitter();

        web3modal.subscribeState(state => {
            if (state.open == false) {
                this.events.emit('walletconnect-closed');
            }
        })

        // This captures when wallet connect successfully connects an account
        web3modal.subscribeEvents(event => {
            if(event.data.type === 'track' && event.data.event == 'CONNECT_SUCCESS') {
                this.setWalletAddress(ethereumClient.getAccount().address)
                this.events.emit('wallet-connected');
              }
      })
        
    }

    public static getInstance():WalletConnectService {
        return WalletConnectService._instance;
    }

    public connectWallet() {
        if (ethereumClient.getAccount().isConnected) {
            this.setWalletAddress(ethereumClient.getAccount().address)
            this.events.emit('wallet-connected');
        } else {
            web3modal.open()
        }
      }

    onWalletConnected(cb: () => void, context?: any) {
        this.events.on('wallet-connected', cb, context);
    }

    onWalletConnectClosed(cb: () => void, context?: any) {
        this.events.on('walletconnect-closed', cb, context);
    }

    stopWalletConnectListener() {
        this.events.removeAllListeners();
    }

    private setWalletAddress(value):void {
        console.log('Wallet Address set! ' + value);
        this._walletAddress = value;
    }

    public getWalletAddress(): string {
        return this._walletAddress;
    }

    public isEthAddress(address) {
        console.log(address);
        var isAddress = isAddress(address.toString());
        console.log(`isAddress: ${util.inspect(isAddress)}`);
        return isAddress;
    }

}
