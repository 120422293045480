import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import BaseRaidService from '~/services/BaseRaidService';
import BattleRoyaleServerService from '~/services/BattleRoyaleServerService';
import MiningService from '~/services/MiningService';
import { capitalizeFirstLetter } from '~/utils/string';

const data = [
  {
    order: 3,
    type: 'battles',
    name: 'pvp',
    scene: 'PvpEntryScene',
    bg_image_key: 'pvp_button_bg',
    label_image_key: 'pvp_button_text',
    enabled: true,
    isModal: false,
  },
  {
    order: 1,
    type: 'mining',
    name: 'mining',
    scene: 'MiningModalScene',
    fallbackScene: 'MiningNotEnoughResourcesDialogScene',
    bg_image_key: 'mining_mission_button_bg',
    label_image_key: 'mining_mission_button_text',
    label_offset: 24,
    logo_image_key: 'mining_mission_logo_image',
    logo_offset: -18,
    enabled: true,
    isModal: true,
  },
  {
    order: 4,
    type: 'battles',
    name: 'pve',
    scene: 'BotMatchingScene',
    bg_image_key: 'pve_button_bg',
    label_image_key: 'pve_button_text',
    enabled: true,
  },
  {
    order: 6,
    type: 'battles',
    name: 'battle_royale',
    scene: 'BattleRoyaleScene',
    bg_image_key: 'battle_royale_button_bg',
    label_image_key: 'battle_royale_button_text',
    enabled: true,
    isModal: false,
    data: {},
  },
  {
    order: 5,
    type: 'battles',
    name: 'boss_fight',
    scene: 'BossFightScene',
    bg_image_key: 'boss_fight_button_bg',
    label_image_key: 'boss_fight_button_text',
    enabled: true,
    isModal: false,
    data: {},
  },
  {
    order: 2,
    type: 'mining',
    name: 'base_raid',
    scene: 'BaseRaidConfirmNextBaseLoadScene',
    fallbackScene: 'BaseRaidResultScene',
    bg_image_key: 'base_raid_button_bg',
    label_image_key: 'base_raid_button_text',
    enabled: true,
    isModal: false,
    data: { next: 0 },
  },
];

const contentTypeMapper = {
  all: data.sort((a, b) => a.order - b.order),
  battles: data.filter(mission => mission.type === 'battles'),
  mining: data.filter(mission => mission.type === 'mining'),
};

type ContentType = keyof typeof contentTypeMapper;

export default class MissionsContentPane extends OverlapSizer {
  private _table;
  private _scale;
  private _title;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, type: ContentType, config?: any) {
    const conf = config
      ? config
      : {
          width: 566 * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;

    // Title
    this._title = scene.add
      .bitmapText(x - this.applyScale(259), y - this.applyScale(234), 'cc_outline', capitalizeFirstLetter(type), this.applyScale(22), 0)
      .setOrigin(0);

    // Missions table
    this._table = this.createTable(scene, contentTypeMapper[type], x, y + this.applyScale(5)).layout();

    this.add(this._title);
    this.add(this._table);
    this.setDirty(false);
  }

  applyScale(length) {
    return length * this._scale;
  }

  updateTable(data?: any) {
    if (data) {
      this._table.setItems(data);
    }
    this._table.layout();
  }

  updateContentType(type: ContentType) {
    this._title.setText(capitalizeFirstLetter(type));
    this._table.setItems(contentTypeMapper[type]);
  }

  createTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(530),
      height: this.applyScale(392),
      items: data,

      table: {
        cellWidth: this.applyScale(265),
        cellHeight: this.applyScale(180),
        columns: 2,
        clamplTableOXY: false,
        reuseCellContainer: true,
        scrollMode: 0,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          item = cell.item;

        var rectConfig = {
          maskType: 'roundRectangle',
          radius: scene.applyScale(25),
        };

        var itemBg = scene.add.rexCircleMaskImage(0, 0, item.bg_image_key, '', rectConfig);
        itemBg.displayHeight = scene.applyScale(145);
        itemBg.scaleX = itemBg.scaleY;

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(235),
            height: scene.applyScale(145),
            space: {
              left: scene.applyScale(15),
              right: scene.applyScale(0),
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .add(itemBg, {
            key: 'mission_bg',
            align: 'center',
            expand: false,
          });

        if (item.logo_image_key) {
          sizer.add(scene.add.image(0, 0, item.logo_image_key).setScale(scene.getScale()), {
            key: 'mission_logo',
            align: 'center',
            offsetY: scene.applyScale(item.logo_offset),
            expand: false,
          });
        }
        sizer
          .add(scene.add.image(0, 0, item.label_image_key).setScale(scene.getScale()), {
            key: 'mission_label',
            align: 'center',
            offsetY: item.logo_image_key ? scene.applyScale(item.label_offset) : 0,
            expand: false,
          })
          .layout();

        if (item.enabled) {
          sizer.setInteractive({ cursor: 'pointer' });
          //sizer.enableClick() // Deprecated

          sizer.setChildrenInteractive({
            click: { mode: 'release', clickInterval: 100 },
            over: true,
            tap: {
              time: 250,
              tapInterval: 200,
              threshold: 9,
              tapOffset: 10,
              taps: undefined,
              minTaps: undefined,
              maxTaps: undefined,
            },
            press: { time: 251, threshold: 9 },
            swipe: {
              threshold: 10,
              velocityThreshold: 1000,
              dir: '8dir',
            },
            inputEventPrefix: 'child.',
          });

          sizer.on('child.over', (child, pointer, event) => {
            sizer.getElement('mission_bg').setScale(sizer.getElement('mission_bg').scaleX * 1.04);
            sizer.getElement('mission_label').setScale(sizer.getElement('mission_bg').scaleX * 1.03);
            if (sizer.getElement('mission_logo')) {
              sizer.getElement('mission_logo').setScale(sizer.getElement('mission_logo').scaleX * 1.04);
            }
          });
          sizer.on('child.out', (child, pointer, event) => {
            sizer.getElement('mission_bg').setScale(sizer.getElement('mission_bg').scaleX / 1.04);
            sizer.getElement('mission_label').setScale(sizer.getElement('mission_bg').scaleX / 1.03);
            if (sizer.getElement('mission_logo')) {
              sizer.getElement('mission_logo').setScale(sizer.getElement('mission_logo').scaleX / 1.04);
            }
          });
        } else {
          sizer.disableInteractive();
          //sizer.disableClick() // Deprecated
          sizer.setAlpha(0.3);
        }

        sizer.onClick(() => {
          //scene.sound.play('mission-button');

          if (item.isModal) {
            switch (item.name) {
              case 'mining':
                MiningService.getInstance()
                  .getSixRandomAsteroids()
                  .then(asteroids => {
                    if (asteroids.length) {
                      scene.scene.start(item.scene, {
                        asteroids: asteroids,
                      });
                    } else {
                      scene.scene.start(item.fallbackScene);
                    }
                  });
                break;
              default:
                scene.scene.start(item.scene);
                break;
            }
          } else {
            switch (item.name) {
              case 'base_raid':
                BaseRaidService.getInstance()
                  .viewAttack()
                  .then(res => {
                    if (res.base_raid_id !== 0 && res.completed) {
                      // Show results of previous raid
                      const resultData = {
                        isAttacker: true,
                        neon_gold: res.attacker_possible_loot.neon_gold,
                        space_elixir: res.attacker_possible_loot.space_elixir,
                        stardust: res.attacker_possible_loot.stardust,
                        xp: res.is_win ? res.attacker_win_xp : res.attacker_lose_xp,
                        isVictory: res.is_win,
                      };
                      scene.scene.stop('HomebaseScene');
                      scene.scene.stop('HudScene');
                      scene.scene.start('BaseRaidResultScene', resultData);
                      scene.scene.bringToTop('BaseRaidResultScene');
                    } else {
                      scene.scene.stop('MissionsModalScene');
                      scene.scene.start(item.scene, { isFirst: true });
                      scene.scene.bringToTop(item.scene);
                    }
                  });
                break;
              case 'battle_royale':
                BattleRoyaleServerService.getInstance()
                  .updateMetaData()
                  .then(() => {
                    scene.scene.stop('HomebaseScene');
                    scene.scene.stop('HudScene');
                    scene.scene.start(item.scene, item.data);
                  });
                break;
              default:
                scene.scene.stop('HomebaseScene');
                scene.scene.stop('HudScene');
                scene.scene.start(item.scene, item.data);
                break;
            }
          }
        }, this);

        return scene.add.existing(sizer);
      },
    });

    table.setItems(data).scrollToTop();

    this._table = table;
    return table;
  }

  setCategory(data: any) {
    // TODO: Implement when mining is available
  }
}

Phaser.GameObjects.GameObjectFactory.register('missionsContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new MissionsContentPane(this.scene, x, y));
});
