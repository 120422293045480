import ConfigurationService from "./ConfigurationService";

export default class ScaleService {

    private static _instance:ScaleService = new ScaleService();

    private _scale;
    private _config: any;

    constructor() {
        if(ScaleService._instance){
            throw new Error("Error: Instantiation failed: Use StakingService.getInstance() instead of new.");
        }
        ScaleService._instance = this;
        this._config = ConfigurationService.getInstance().getConfig();
    }

    public static getInstance():ScaleService {
        return ScaleService._instance;
    }

    public getScale(modalWidth, modalHeight, multiplier?) {
        const vertRatio = window.innerHeight / modalHeight;
        const horzRatio = window.innerWidth / modalWidth;
        const lowest = vertRatio > horzRatio ? horzRatio : vertRatio;

        const ratio = multiplier ? multiplier : 0.9;
    
        return lowest <= 1 ? lowest * ratio : 1;
    }

    public getMobileScale(modalWidth, modalHeight, multiplier?) {
        const vertRatio = window.innerHeight / modalHeight;
        const horzRatio = window.innerWidth / modalWidth;
        const lowest = vertRatio > horzRatio ? horzRatio : vertRatio;
        
        const ratio = multiplier ? multiplier : 0.9;

        return lowest <= 1 ? 1 : lowest * ratio;
    }
}