import { Schema, ArraySchema } from "@colyseus/schema"
import Player from "./Player"

export enum GameState {
    GameEmpty,
    WaitingForOpponent,
    Playing,
    Tie,
    IdleTie,
    Player1Win,
    Player2Win,
}

export enum RoundState {
    Start,
    Complete,
}

export enum MatchRoundState {
    Start,
    Complete,
}

export enum Rank {
    Undefined,
    Bronze,
    Silver,
    Gold,
    Platinum,
    Diamond,
}

export interface IPVEState extends Schema {
    players: ArraySchema<Player>
    gameState: GameState
    roundState: RoundState
    matchRoundState: MatchRoundState
    rank: Rank
    round: number
    matchRound: number
    neonGold: number
    spaceElixir: number
    starDust: number
    battleId: number
}

export default IPVEState
