import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import ConfigurationService from '~/services/ConfigurationService';
import ProgressBar from '../progressBar/ProgressBar';

const componentWidth = 984;
const componentHeight = 83;
const componentRadius = 8;
const componentBgColor = 0x350926;
const componentAlpha = 1;
const componentBorderColor = 0x3d4361;
const componentBorderWidth = 1;

const imageHeight = 67;
const imageWidth = 67;
const imageRadius = 8;

const fontColor = 0xffffff;
const secondaryFontAlpha = 0.3;
const redTextColor = 0xeb5757;
const greenTextColor = 0x6fcf97;

const pinkProgressBarColor = 0xd30059;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0x65526f;
const backgroundAlpha = 0.4;
const radius = 7;
const progressBarBorderColor = 0x383d57;
const progressBarBorderWidth = 1;

const progressBarHeight = 22;
const progressBarWidth = 319;
const progressBarMin = 0;

const spaceElixirIcon = 'space_elixir_icon';
const neonGoldIcon = 'neon_gold_icon';
const darkMatterIcon = 'dark_matter_icon';

const logTypes = {
  defense: 'defense',
  attack: 'attack',
};

const lootTypes = {
  darkMatter: 'dark_matter',
  neonGold: 'neon_gold',
  spaceElixir: 'space_elixir',
};

export default class RaidHistoryItemComponent extends OverlapSizer {
  private _scale;
  private _raidHistoryItem;
  private _logType;
  private _levelBadges;

  constructor(scene: Phaser.Scene, x: number, y: number, raidHistoryItem: any, logType: string, paneScale?: number, config?: any) {
    let conf = {
      width: componentWidth * (paneScale || 1),
      height: componentHeight * (paneScale || 1),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._raidHistoryItem = raidHistoryItem;
    this._logType = logType;
    this._levelBadges = ConfigurationService.getInstance().getConfig().userBadges;

    this.createComponent(scene, x, y);
  }

  private createComponent(scene: Phaser.Scene, x: number, y: number) {
    this.addBg(scene);
    this.addOpponentImage(scene);
    this.addTitle(scene);
    this.addLootInfo(scene);
    this.addProgressBar(scene);

    return this.layout();
  }

  private addBg(scene: Phaser.Scene) {
    const bg = scene.add.rexRoundRectangle(
      0,
      0,
      scene.applyScale(componentWidth), // width
      scene.applyScale(componentHeight), // height
      scene.applyScale(componentRadius), // radius
      componentBgColor, // fillColor
      componentAlpha, // alpha
    );

    bg.setStrokeStyle(scene.applyScale(componentBorderWidth), componentBorderColor, componentAlpha);

    this.addBackground(bg);
  }

  private addOpponentImage(scene: Phaser.Scene) {
    const imageKey = this.getOpponentImageKey();

    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const oppImageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);
    const oppImageWidth = oppImageData.width;
    rectConfig.radius = oppImageWidth / 8;

    const oppImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

    oppImage.displayHeight = scene.applyScale(66);
    oppImage.scaleX = oppImage.scaleY;
    oppImageData.destroy();

    this.add(oppImage, {
      key: 'opponent_image',
      align: 'left-top',
      offsetX: scene.applyScale(9),
      offsetY: scene.applyScale(9),
      expand: false,
    });
  }

  private addTitle(scene: Phaser.Scene) {
    const titleLabel = scene.rexUI.add.label({
      orientation: 'x',
      rtl: false,
      icon: scene.add.image(0, 0, this.getLevelImageKey()).setScale(scene.applyScale(0.3)),
      text: scene.add.bitmapText(0, 0, 'cc_outline', this.getTitleText(), scene.applyScale(16), 0),
      space: {
        icon: 6,
      },
    });

    const tintColor = this._raidHistoryItem.winner === this._raidHistoryItem.attacker_user_address ? greenTextColor : redTextColor;

    const tintPosition = this.getTextTintPosition();

    titleLabel.getElement('text').setCharacterTint(tintPosition.startIndex, tintPosition.length, false, tintColor);

    this.add(titleLabel, {
      key: 'raid_history_item_title_label',
      align: 'left-top',
      offsetX: scene.applyScale(88),
      offsetY: scene.applyScale(15),
      expand: false,
    });
  }

  private addLootInfo(scene: Phaser.Scene) {
    // Unit Title/Level Sizer
    const lootSizer = scene.rexUI.add.fixWidthSizer(0, 0, {
      width: scene.applyScale(450),
      align: 0,
    });

    lootSizer.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, spaceElixirIcon).setScale(scene.applyScale(0.5)),
        text: scene.add.bitmapText(0, 0, 'cc_outline', this.getLootValue(lootTypes.spaceElixir), scene.applyScale(16), 0),
        space: {
          icon: 4,
        },
      }),
      {
        padding: { left: 0, right: scene.applyScale(21), top: 0, bottom: 0 },
        key: 'space_elixir_loot',
      },
    );

    lootSizer.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, neonGoldIcon).setScale(scene.applyScale(0.5)),
        text: scene.add.bitmapText(0, 0, 'cc_outline', this.getLootValue(lootTypes.neonGold), scene.applyScale(16), 0),
        space: {
          icon: 4,
        },
      }),
      {
        padding: { left: 0, right: scene.applyScale(21), top: 0, bottom: 0 },
        key: 'neon_gold_loot',
      },
    );

    lootSizer.add(
      scene.rexUI.add.label({
        orientation: 'x',
        rtl: false,
        icon: scene.add.image(0, 0, darkMatterIcon).setScale(scene.applyScale(0.5)),
        text: scene.add.bitmapText(0, 0, 'cc_outline', this.getLootValue(lootTypes.darkMatter), scene.applyScale(16), 0),
        space: {
          icon: 4,
        },
      }),
      {
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        key: 'dark_matter_loot',
      },
    );

    this.add(lootSizer.layout(), {
      key: 'loot_sizer',
      align: 'left-top',
      offsetX: scene.applyScale(88),
      offsetY: scene.applyScale(49),
      expand: false,
    });
  }

  private addProgressBar(scene: Phaser.Scene) {
    // HP Description Text
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', 'HOMEBASE HP REMAINING', scene.applyScale(14), 0).setAlpha(0.3), {
      key: 'hp_desc_text',
      align: 'left-top',
      offsetX: scene.applyScale(651),
      offsetY: scene.applyScale(19),
      expand: false,
    });

    // HP Value Text
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', this.getHpValueText(), scene.applyScale(14), 0), {
      key: 'hp_value_text',
      align: 'right-top',
      offsetX: scene.applyScale(-14),
      offsetY: scene.applyScale(18),
      expand: false,
    });

    // Progress Bar
    this.add(this.createProgressBar(scene), {
      key: 'hp_prog_bar',
      align: 'left-top',
      offsetX: scene.applyScale(651),
      offsetY: scene.applyScale(40),
      expand: false,
    });
  }

  private getOpponentImageKey() {
    switch (this._logType) {
      case logTypes.defense:
        return this._raidHistoryItem.attacker_pfp ? `${this._raidHistoryItem.attacker_name}_pfp` : `starwolf_silhouette`;
      case logTypes.attack:
        return this._raidHistoryItem.defender_pfp ? `${this._raidHistoryItem.defender_name}_pfp` : `starwolf_silhouette`;
    }
  }

  private getTitleText() {
    const name1 = this._logType === logTypes.defense ? this._raidHistoryItem.attacker_name : 'you';
    const name2 = this._logType === logTypes.defense ? 'you' : this._raidHistoryItem.defender_name;
    const successText = this._raidHistoryItem.winner === this._raidHistoryItem.attacker_user_address ? 'successfully' : 'unsuccessfully';
    return `${name1} ${successText} raided ${name2}.`.toUpperCase();
  }

  private getLevelImageKey() {
    const level = this._logType === logTypes.defense ? this._raidHistoryItem.attacker_user_level : this._raidHistoryItem.defender_user_level;

    var badge = this._levelBadges.find(lb => {
      if (lb.maxLevel === null) {
        return level >= lb.minLevel;
      } else {
        return level >= lb.minLevel && level <= lb.maxLevel;
      }
    });

    if (badge) {
      return badge.badgeImageKey;
    }

    return this._levelBadges[0].badgeImageKey;
  }

  private getTextTintPosition() {
    const name1 = this._logType === logTypes.defense ? this._raidHistoryItem.attacker_name : 'you';
    const successLength = 'successfully'.length;
    const failLength = 'unsuccessfully'.length;
    const tintLength = this._raidHistoryItem.winner === this._raidHistoryItem.attacker_user_address ? successLength : failLength;

    return { startIndex: name1.length + 1, length: tintLength };
  }

  private getLootValue(lootType) {
    if (this._raidHistoryItem.winner === this._raidHistoryItem.attacker_user_address) {
      const responseValue =
        lootType === lootTypes.darkMatter
          ? this._raidHistoryItem.attacker_loot.dark_matter
          : lootType === lootTypes.neonGold
          ? this._raidHistoryItem.attacker_loot.neon_gold
          : this._raidHistoryItem.attacker_loot.space_elixir;

      return Math.floor(responseValue || 0).toLocaleString('en-us');
    }

    return '0';
  }

  private getEndHpValue() {
    return this._raidHistoryItem.defender_end_hp
      ? this._raidHistoryItem.defender_end_hp
      : this._raidHistoryItem.winner === this._raidHistoryItem.attacker_user_address
      ? 0
      : this._raidHistoryItem.defender_start_hp;
  }

  private getHpValueText() {
    return `${this.getEndHpValue().toLocaleString('en-us')}/${this._raidHistoryItem.defender_start_hp.toLocaleString('en-us')}`;
  }

  private createProgressBar(scene) {
    const progBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: '',
          offset: 2,
          fontSize: 20,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: pinkProgressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressBarMin,
          maxValue: this._raidHistoryItem.defender_start_hp === undefined ? 1 : this._raidHistoryItem.defender_start_hp,
          progressValue: this.getEndHpValue(),
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
        border: {
          alpha: backgroundAlpha,
          color: progressBarBorderColor,
          width: progressBarBorderWidth,
        },
      },
    );

    return scene.add.existing(progBar);
  }
}

Phaser.GameObjects.GameObjectFactory.register('raidHistoryItemComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new RaidHistoryItemComponent(this.scene, x, y));
});
