import Phaser, { Game } from 'phaser';
import BattleRoyaleServer from '../../../services/BattleRoyaleServerService';
import ScaleService from '~/services/ScaleService';
import Button from '~/components/buttons/Button';
import BattleRoyaleBattlePane from './battleRoyaleBattlePane';
import { BR_Scene_Assets } from '~/utils/AssetLoader';
import BattleRoyaleDetailsPane from './battleRoyaleDetailPane';
import UserService from '~/services/UserService';
import WalletConnectService from '~/services/WalletConnectService';
import { GameState } from '../../../../../API/BattleRoyale/src/types/IBattleRoyaleState';
import BattleRoyaleServerService from '../../../services/BattleRoyaleServerService';
import SquadService from '~/services/SquadService';
import BattleRoyaleFeedPane from './battleRoyaleFeedPane';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const width = 1512;
const height = 982;

export default class BattleRoyaleScene extends Phaser.Scene {
  private _scale;
  private _server!: BattleRoyaleServer;
  private _battleRoyaleBattlePane;
  private _battleRoyaleDetailPane;
  private _battleRoyaleFeedPane;
  private _backButton;
  private _player;

  // Data to pass to Battle Pane
  private _battleData;

  constructor() {
    super({
      key: 'BattleRoyaleScene',
    });
  }

  preload() {
    this._server = BattleRoyaleServer.getInstance();
    this._battleData = this._server.getRoomData();

    // Assets
    LoadImageAssets(this, BR_Scene_Assets);

    // Loads Player PFP Assets
    this._battleData.pfpData.forEach(pfp => {
      this.load.image(pfp.playerImageKey, pfp.playerImageUrl);
    });
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    if (this._server.isServerAlive()) {
      // Server has been started

      // Determine if player has already joined the server as participant or viewer
      this._player = this._server.getPlayerFromMetaParticipants(WalletConnectService.getInstance().getWalletAddress());
      this._battleData.player = this._player; // questionable variable

      if (this._player != undefined) {
        if (this._player.isParticipant) {
          this._server.join(this.getPlayerAndSquadData(), true);
          this.enableBattleRoyaleListeners();
        } else {
          this._server.join(this.getPlayerAndSquadData(), false);
          this.enableBattleRoyaleViewerListeners();
        }

        // Updating battle data with participants from game server not metadata
        this._battleData.participants = this._server.getParticipantsList();

        switch (this._server.getGameState() as Number) {
          case GameState.PreGame:
            break;
          case GameState.BattleRoyale:
          case GameState.BRIntermission:
            this._battleData.messages = this._server.getMessages();
            break;
          case GameState.GameOver:
            this._server.disableBattleRoyaleListeners();
            this.scene.stop('BattleRoyaleScene');
            this.scene.launch('BattleRoyaleGameOverScene', {
              participants: this._server.getParticipantsList(),
            });
            break;
          case GameState.Failed:
            this._server.disableBattleRoyaleListeners();
            this.scene.stop('BattleRoyaleScene');
            this.scene.launch('BattleRoyaleFailedScene');
            break;
        }
      } else {
        // Player has NOT joined the game either as a participant or viewer
        // Use room data to fill up the UI
        switch (this._battleData.gameState as Number) {
          case GameState.PreGame:
            break;
          case GameState.BattleRoyale:
          case GameState.BRIntermission:
            // join player to server as a Viewer
            this._server.join(this.getPlayerAndSquadData(), false);
            this.enableBattleRoyaleViewerListeners();
            break;
          case GameState.GameOver:
            // Launch Game Over Scene
            this.scene.stop('BattleRoyaleScene');
            this.scene.launch('BattleRoyaleGameOverScene', {
              participants: this._battleData.previousResults,
            });
            break;
          case GameState.Failed:
            this.scene.stop('BattleRoyaleScene');
            this.scene.launch('BattleRoyaleFailedScene');
            break;
        }
      }

      this.createComponent();

      this.events.on('resume', (scene, data) => {
        if (data !== undefined && data.joined !== undefined) {
          // Updates state of join button
          this.enableBattleRoyaleListeners();
          this._battleRoyaleDetailPane.updateButton(data.joined);
        }
      });
    } else {
      // show UI to notify users game server is done
      this.scene.start('BattleRoyaleServerUnavailableScene');
    }
  }

  createComponent() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: width * this._scale,
      height: height * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Battle Pane
    this._battleRoyaleBattlePane = new BattleRoyaleBattlePane(this, 0, 0, this._battleData, this._scale);

    sizer.add(this._battleRoyaleBattlePane, {
      key: 'bossfight-pane',
      align: 'left-top',
      offsetX: this.applyScale(336),
      offsetY: this.applyScale(77),
      expand: false,
    });

    // Detail Pane
    this._battleRoyaleDetailPane = new BattleRoyaleDetailsPane(this, 0, 0, this._battleData, this._scale);

    sizer.add(this._battleRoyaleDetailPane, {
      key: 'battleroyale-detail-pane',
      align: 'left-top',
      offsetX: this.applyScale(906),
      offsetY: this.applyScale(77),
      expand: false,
    });

    // Feed Pane
    this._battleRoyaleFeedPane = new BattleRoyaleFeedPane(this, 0, 0, this._battleData.messages, this._scale);

    sizer.add(this._battleRoyaleFeedPane, {
      key: 'battleroyale-feed-pane',
      align: 'left-top',
      offsetX: this.applyScale(906),
      offsetY: this.applyScale(416),
      expand: false,
    });

    // Add Back Button
    this._backButton = new Button(this, 0, 0, 'battleroyale_back_button', true, 1.05).setScale(this._scale);

    this._backButton.onClick().subscribe(pointer => {
      this.onBackButtonPressed();
    });

    this.add.existing(this._backButton);

    sizer.add(this._backButton, {
      key: 'back-button',
      align: 'left-top',
      offsetX: this.applyScale(24),
      offsetY: this.applyScale(896),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  onBackButtonPressed() {
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.stop();
    this._server.disableBattleRoyaleListeners();
  }

  public onTabbedOut() {
    this._server.disableBattleRoyaleListeners();
    this._battleRoyaleDetailPane.stopTimer();
  }

  public async onTabbedIn() {
    var roomData = await this._server.getRoomData();

    switch (roomData.gameState) {
      case GameState.PreGame:
        if (roomData.isParticipant) {
          this.enableBattleRoyaleListeners();
          this._battleData = this._server.getBattleData();
          this._battleRoyaleDetailPane.updateDetailPane(this._battleData);
          this._battleRoyaleBattlePane.updateParticipantsList(this._battleData.participants);
        } else {
          this._battleRoyaleDetailPane.updateDetailPane(roomData);
          this._battleRoyaleBattlePane.updateParticipantsList(roomData.participants);
        }
        break;

      case GameState.BattleRoyale:
      case GameState.BRIntermission:
        if (roomData.isParticipant) {
          this.enableBattleRoyaleListeners();
          this._battleData = this._server.getBattleData();
          this._battleRoyaleDetailPane.updateDetailPane(this._battleData);
          this._battleRoyaleBattlePane.updateMessageDisplay(this._battleData);
        } else if (roomData.isViewer) {
          this.enableBattleRoyaleViewerListeners();
          this._battleData = this._server.getBattleData();
          this._battleRoyaleDetailPane.updateDetailPane(this._battleData);
          this._battleRoyaleBattlePane.updateMessageDisplay(this._battleData);
        } else {
          // join player to server as a Viewer
          this._server.join(this.getPlayerAndSquadData(), false);
          this._battleRoyaleDetailPane.updateReadyButtonViewer();
          // Boss Fight Service Listeners
          this.enableBattleRoyaleViewerListeners();

          this._battleData = this._server.getBattleData();
          this._battleRoyaleDetailPane.updateDetailPane(this._battleData);
          this._battleRoyaleBattlePane.updateMessageDisplay(this._battleData);
        }
        break;

      case GameState.GameOver:
        this._server.disableBattleRoyaleListeners();
        this.scene.stop('BattleRoyaleScene');
        this.scene.launch('BattleRoyaleGameOverScene', {
          participants: roomData.previousResults,
        });
        break;

      case GameState.Failed:
        this.scene.stop('BattleRoyaleScene');
        this.scene.launch('BattleRoyaleFailedScene');
        break;
    }
  }

  // Listeners

  enableBattleRoyaleViewerListeners() {
    this._server.onMessageRecieved(this.handleBattleRoyaleMessageReceived, this);
    this._server.onGameStateChanged(this.handleGameStateChanged, this);
  }

  enableBattleRoyaleListeners() {
    this._server.onParticipantsUpdated(this.handleParticipantsUpdated, this);
    this._server.onMessageRecieved(this.handleBattleRoyaleMessageReceived, this);
    this._server.onGameStateChanged(this.handleGameStateChanged, this);
  }

  handleParticipantsUpdated() {
    if ((this._battleData.gameState as Number) === GameState.PreGame) {
      var texturesToLoad = this.getTextureLoadingList();
      if (texturesToLoad.length > 0) {
        this.load.once('complete', () => {
          this._battleRoyaleBattlePane.updateParticipantsList(this._server.getBattleData());
        });

        texturesToLoad.forEach(texture => {
          if (!this.textures.exists(texture.key)) {
            this.load.image(texture.key, texture.url);
          }
        });

        this.load.start();
      } else {
        this._battleRoyaleBattlePane.updateParticipantsList(this._server.getParticipantsList());
      }
    }
  }

  getTextureLoadingList() {
    var texturesToLoad: { key: string; url: string }[] = [];
    this._battleData.participants?.forEach(participant => {
      if (!this.textures.exists(participant.playerImageKey)) {
        texturesToLoad.push({
          key: participant.playerImageKey,
          url: participant.playerImageUrl,
        });
      }
    });
    return texturesToLoad;
  }

  handleBattleRoyaleMessageReceived() {
    this._battleRoyaleBattlePane.updateMessageDisplay(this._server.getBattleData());
    this._battleRoyaleFeedPane.updateFeed(this._server.getBattleData().messages);

    // get most rescent message
    var lastIndex = (this._server.getBattleData().messages?.length as number) - 1;
    var message = this._server.getBattleData().messages?.at(lastIndex);

    // check if a player was killed or committed suicide
    var isSuicide = message?.player1 == message?.player2;

    // if true display Eliminated Scene
    // if (
    //   WalletConnectService.getInstance().getWalletAddress() == this._server.getBattleData().messages?.at(lastIndex).eliminatedWallet &&
    //   (this._server.getBattleData().participantsRemaining as number) > 2
    // ) {
    //   //this.scene.pause("BattleRoyaleScene")
    //   // this.scene.start("BattleRoyaleEliminatedScene", {
    //   // 	//participants: participants
    //   // })
    // }
  }

  handleGameStateChanged() {
    switch (this._server.getGameState()) {
      case GameState.PreGame:
      case GameState.BattleRoyale:
      case GameState.BRIntermission:
        if (this._server.getBattleData().round === 1 && this._server.getGameState() === 1) {
          this._battleRoyaleBattlePane.switchDisplayTable(this._server.getBattleData());
        }
        this._battleRoyaleDetailPane.updateDetailPane(this._server.getBattleData());
        break;
      case GameState.Failed:
        this.scene.stop('BattleRoyaleScene');
        this.scene.launch('BattleRoyaleFailedScene');
        break;
      case GameState.GameOver:
        var participants = this._server.getParticipantsList();
        this._server.disableBattleRoyaleListeners();
        this.scene.stop('BattleRoyaleScene');
        this.scene.launch('BattleRoyaleGameOverScene', {
          participants: participants,
        });
        break;
    }
  }

  handleCountdownFinished() {
    // 1st time if player isn't in
    if (!this._server.hasPlayerJoinedGame(WalletConnectService.getInstance().getWalletAddress())) {
      // join player to server as a Viewer
      this._server.join(this.getPlayerAndSquadData(), false);

      this._battleRoyaleDetailPane.updateReadyButtonViewer();

      // Boss Fight Service Listeners
      this.enableBattleRoyaleViewerListeners();

      return true;
    }
    return false;
  }

  createSquadMemberImage(scene, imageKey) {
    // Squad Member Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const smImageData = new Phaser.GameObjects.Image(scene, 0, 0, imageKey);

    const smImageWidth = smImageData.width;
    rectConfig.radius = smImageWidth / 3;
    smImageData.destroy();

    var squadMemberImage = scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);

    squadMemberImage.displayHeight = scene.applyScale(48);
    squadMemberImage.scaleX = squadMemberImage.scaleY;

    return squadMemberImage;
  }

  // Gets Tint colour for battle royal message display. Gold when players is you!
  getTintColor(name: string) {
    return name === UserService.getInstance().getUserName() ? 0xdea827 : 0x2fe2a2;
  }

  getTintIndex(name: string, message: string, last: boolean) {
    var index = last ? message.lastIndexOf(name) : message.indexOf(name);
    if (index == 0 || index < 59) {
      return index;
    } else {
      return index - 1;
    }
  }

  getPlayerAndSquadData() {
    var playerData = UserService.getInstance().getUserDataSync();
    playerData.squadMembers = SquadService.getInstance().getSquadSync();
    return playerData;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BR_Scene_Assets));
    UnloadImages(this, this._server.getPlayerImageKeys());
  }
}
