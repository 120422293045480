import Phaser from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import ObjectiveService from '~/services/ObjectiveService';
import BattlePassService from '~/services/BattlePassService';
import util from 'util';
import Button from '~/components/buttons/Button';

const paneWidth = 660;
const paneHeight = 515;

const completedItemColor = 0xa6ffc4;

const cellWidth = 630;
const cellHeight = 69;
const cellRadius = 8;
const cellBgColor = 0x262637;
const cellAlpha = 1;

const verticalCellPadding = 13;
const horizontalCellPadding = 13;

const currencyColors = {
  neon_gold: 0xffe100,
  space_elixir: 0x00d4f7,
  stardust: 0xff87ac,
  xp: 0xa6ffc4,
};

const objectiveTypes = {
  staking: 'staking',
  quick_quests: 'quick_quests',
  partner_quests: 'partner_quests',
  milestones: 'milestones',
};

const battlepassObjectiveTypes = {
  battle_pass_daily: 'battle_pass_daily',
  battle_pass_weekly: 'battle_pass_weekly',
  battle_pass_monthly: 'battle_pass_monthly',
};

export default class ObjectivesListContentPane extends FixWidthSizer {
  private _scale;
  private _objectiveType: string;
  private _objectives: any;
  private _table: any;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    paneScale: number,
    objectiveType: string,
    paneWidth?: number,
    paneHeight?: number,
    config?: any,
  ) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 2,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._objectiveType = objectiveType;

    this.createComponent(scene);
  }

  private createComponent(scene: Phaser.Scene) {
    if (Object.keys(battlepassObjectiveTypes).includes(this._objectiveType)) {
      this._objectives = this.sortObjectives(this.getBattlepassObjectives());
    } else {
      this._objectives = ObjectiveService.getInstance().getObjectivesSync();
      this._objectives = this.sortObjectives(this.filterObjectivesData());
    }

    this._table = this.createTable(scene, this._objectives, 0, 0).layout();

    this.add(this._table, {
      key: 'objectives_table',
      padding: { right: 0, left: 0, top: 0, bottom: 0 },
    });
  }

  createTable(scene, data, x, y) {
    if (!data || data.lenght === 0) return;

    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: scene.applyScale(cellWidth),
      height: scene.applyScale((cellHeight + 10) * data.length > paneHeight ? paneHeight : (cellHeight + 10) * data.length + 5),
      items: data,

      table: {
        cellWidth: scene.applyScale(cellWidth),
        cellHeight: scene.applyScale(cellHeight + 10),
        columns: 1,
        clamplTableOXY: false,
        scrollMode: 0,
        mask: {
          padding: 2,
        },
      },
      space: {
        top: scene.applyScale(3),
        bottom: scene.applyScale(3),

        table: {
          top: scene.applyScale(11),
          bottom: scene.applyScale(11),
        },
      },

      mouseWheelScroller: {
        focus: false,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        const rewardString = item.currency === 'xp' ? `${item.reward?.toLocaleString('en-US')} XP` : item.reward?.toLocaleString('en-US');

        var sizer = scene.rexUI.add
          .overlapSizer(0, 0, {
            width: scene.applyScale(cellWidth),
            height: scene.applyScale(cellHeight),
            space: {
              left: scene.applyScale(horizontalCellPadding),
              right: scene.applyScale(horizontalCellPadding),
              top: scene.applyScale(verticalCellPadding),
              bottom: scene.applyScale(verticalCellPadding - 2),
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .addBackground(
            scene.rexUI.add.roundRectangle(
              0,
              0,
              scene.applyScale(cellWidth),
              scene.applyScale(cellHeight),
              scene.applyScale(cellRadius),
              cellBgColor,
              cellAlpha,
            ),
            {},
            'obj_bg',
          )
          .add(scene.add.bitmapText(0, 0, 'cc_outline', item.title, scene.applyScale(18), 0), {
            key: 'title_text',
            align: 'left-top',
            offsetX: 0,
            offsetY: 0,
            expand: false,
          })
          .add(scene.add.bitmapText(0, 0, 'cc_outline', item.description, scene.applyScale(16), 0).setAlpha(0.6), {
            key: 'description_text',
            align: 'left-bottom',
            offsetX: 0,
            offsetY: 0,
            expand: false,
          })
          .add(
            scene.rexUI.add.label({
              height: scene.applyScale(24),
              orientation: 'x',
              rtl: true,
              icon: item.currency === 'xp' ? undefined : scene.add.image(0, 0, `${item.currency}_icon`).setScale(scene.applyScale(0.45)),
              text: scene.add
                .bitmapText(0, 0, 'cc_outline', rewardString, scene.applyScale(18), 0)
                .setCharacterTint(0, rewardString.length, true, currencyColors[item.currency]),
              space: {
                icon: 4,
              },
            }),
            {
              key: 'reward_label',
              align: 'right-top',
              offsetX: item.currency === 'xp' ? scene.applyScale(0) : scene.applyScale(5),
              offsetY: -2,
              expand: false,
            },
          )
          .add(
            scene.add
              .bitmapText(
                0,
                0,
                'cc_outline',
                `${item.action?.value?.toLocaleString('en-us') || 0} of ${item.action?.target_value?.toLocaleString('en-us') || 1}`,
                scene.applyScale(18),
                0,
              )
              .setAlpha(0.6),
            {
              key: 'progress_text',
              align: 'right-bottom',
              offsetX: 0,
              offsetY: scene.applyScale(1),
              expand: false,
            },
          );

        function addObjectiveActionDisplay(scene: Phaser.Scene, sizer, item, x, y) {
          // Show 'Completed' label if objective is completed
          if (item.status === 'complete') {
            addCompletedDisplay(scene, sizer, item, x, y);
            return;
          }

          switch (item.action.type) {
            case 'connect':
              addConnectActionDisplay(scene, sizer, item, x, y);
              break;
            case 'count':
              // Do nothing.  Count is displayed by default.
              break;
            case 'twitter':
              addTwitterActionDisplay(scene, sizer, item, x, y);
              break;
            case 'link':
              addLinkActionDisplay(scene, sizer, item, x, y);
              break;
            case 'medium':
              addLinkActionDisplay(scene, sizer, item, x, y);
              break;
            case 'youtube':
              addLinkActionDisplay(scene, sizer, item, x, y);
              break;
          }
        }

        function addConnectActionDisplay(scene: Phaser.Scene, sizer, item, x, y) {
          let connectButton;

          switch (item.action.sub_type) {
            case 'discord':
              connectButton = new Button(scene, 0, 0, 'objective_connect_button', true, 1.05);
              connectButton.onClick().subscribe(pointer => {
                //ObjectiveService.getInstance().handleDiscordConnectAction(pointer);
              });
              break;
            case 'metamask':
              let fixedItem = {};
              fixedItem = Object.assign(fixedItem, item);
              fixedItem.action = {
                type: 'count',
                sub_type: 'consecutive_days',
                target: 'login',
                target_value: item.action.target_value,
                value: item.action.value,
              };
              // TODO: Impelement
              break;
          }

          if (connectButton) {
            scene.add.existing(connectButton.setScale(scene.getScale()));

            sizer.add(connectButton, {
              key: 'connect_button',
              align: 'right-center',
              offsetX: scene.applyScale(-90),
              offsetY: 0,
              expand: false,
            });
          }
        }

        function addTwitterActionDisplay(scene: Phaser.Scene, sizer, item, x, y) {
          var twitterButton;

          switch (item.action.sub_type?.toLowerCase()) {
            case 'follow':
              twitterButton = new Button(scene, 0, 0, 'objective_follow_button', true, 1.05);
              twitterButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
            case 'rt':
              twitterButton = new Button(scene, 0, 0, 'objective_retweet_button', true, 1.05);
              twitterButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
            case 'tweet':
              twitterButton = new Button(scene, 0, 0, 'objective_tweet_button', true, 1.05);
              twitterButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
            case 'like':
              twitterButton = new Button(scene, 0, 0, 'objective_like_button', true, 1.05);
              twitterButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
          }

          if (twitterButton) {
            scene.add.existing(twitterButton.setScale(scene.getScale()));

            sizer.add(twitterButton, {
              key: `twitter_button`,
              align: 'right-center',
              offsetX: scene.applyScale(-90),
              offsetY: 0,
              expand: false,
            });
          }
        }

        function addLinkActionDisplay(scene: Phaser.Scene, sizer, item, x, y) {
          var linkButton;

          switch (item.action.sub_type) {
            case 'read':
              linkButton = new Button(scene, 0, 0, 'objective_read_button', false);
              linkButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
            case 'watch':
              linkButton = new Button(scene, 0, 0, 'objective_view_button', false);
              linkButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
            default:
              linkButton = new Button(scene, 0, 0, 'objective_open_button', false);
              linkButton.onClick().subscribe(pointer => {
                handleLinkAction(scene, sizer, item, x, y);
              });
              break;
          }

          if (linkButton) {
            scene.add.existing(linkButton.setScale(scene.getScale()));

            sizer.add(linkButton, {
              key: `link_button`,
              align: 'right-center',
              offsetX: scene.applyScale(-90),
              offsetY: 0,
              expand: false,
            });
          }
        }

        function addCompletedDisplay(scene: Phaser.Scene, sizer, item, x, y) {
          sizer.add(
            scene.add
              .bitmapText(0, 0, 'cc_outline', 'Completed', scene.applyScale(18), 0)
              .setCharacterTint(0, 'Completed'.length, true, completedItemColor),
            {
              key: 'completed_text_label',
              align: 'right-center',
              offsetX: scene.applyScale(-90),
              offsetY: 0,
              expand: false,
            },
          );

          disableObjective(sizer, item);
        }

        function setComplete(scene: Phaser.Scene, item, newActionValue) {
          return ObjectiveService.getInstance()
            .updateObjective(item.objective_id, newActionValue)
            .then(res => {
              scene.events.emit('objective_completed');
            });
        }

        function disableObjective(sizer, item) {
          sizer.setAlpha(0.6);
        }

        function getTextAlpha(nftCount) {
          return 1; // nftCount === 0 ? 0.6 : 1;
        }

        function handleLinkAction(scene: Phaser.Scene, sizer, item, x, y) {
          window.open(item.action.target, '_blank')?.focus();
          const newActionValue = 1;
          return setComplete(scene, item, newActionValue);
        }

        addObjectiveActionDisplay(scene, sizer, item, x, y);

        return sizer.layout();
      },
    });

    table.setItems(data).scrollToTop();

    return table;
  }

  private filterObjectivesData(): any {
    const partnerQuests = this._objectives.filter(
      obj => obj.action.sub_type === 'NFTs' && obj.action.target !== 'Star Wolvez' && obj.action.target !== 'Star Wolvez Genesis',
    );

    const milestones = this._objectives.filter(
      obj =>
        (obj.action.type === 'count' &&
          (obj.action.sub_type !== 'NFTs' || obj.action.target === 'Star Wolvez' || obj.action.target === 'Star Wolvez Genesis')) ||
        obj.action.sub_type === 'level',
    );

    switch (this._objectiveType) {
      case objectiveTypes.partner_quests:
        return partnerQuests;
      case objectiveTypes.milestones:
        return milestones;
      case objectiveTypes.quick_quests:
        return this._objectives.filter(obj => {
          return (
            !partnerQuests.map(pq => pq.objective_id).includes(obj.objective_id) && !milestones.map(ms => ms.objective_id).includes(obj.objective_id)
          );
        });
      default:
        return [];
    }
  }

  sortObjectives(objectives) {
    return objectives.sort((a, b) => {
      const aComplete = a.status === 'complete';
      const bComplete = b.status === 'complete';

      if (!aComplete && bComplete) {
        return -1;
      }
      if (aComplete && !bComplete) {
        return 1;
      }
      return 1;
    });
  }

  private getBattlepassObjectives() {
    var objectives = [];
    var objectiveType = '';

    switch (this._objectiveType) {
      case battlepassObjectiveTypes.battle_pass_daily:
        objectives = BattlePassService.getInstance().getDailyObjectivesSync();
        objectiveType = battlepassObjectiveTypes.battle_pass_daily;
        break;
      case battlepassObjectiveTypes.battle_pass_weekly:
        objectives = BattlePassService.getInstance().getWeeklyObjectivesSync();
        objectiveType = battlepassObjectiveTypes.battle_pass_weekly;
        break;
      case battlepassObjectiveTypes.battle_pass_monthly:
        objectives = BattlePassService.getInstance().getMonthlyObjectivesSync();
        objectiveType = battlepassObjectiveTypes.battle_pass_monthly;
        break;
      default:
        objectives = [];
    }

    const finalObjs = objectives.map(obj => {
      return {
        objective_id: obj.objective_id,
        title: obj.name,
        description: obj.description,
        type: 'beginner',
        currency_id: 1,
        action: {
          value: obj.num_completed,
          target: '',
          sub_type: 'none',
          target_value: obj.completion_number,
          type: 'count',
        },
        status: obj.is_completed === 1 ? 'complete' : 'incomplete',
        currency: 'xp',
        check_on_login: 0,
        objective_outcome_id: 0,
        reward: obj.xp_reward,
      };
    });

    return finalObjs;
  }
}

Phaser.GameObjects.GameObjectFactory.register('objectivesListContentPane', function (x: number, y: number, objectivesData: any) {
  // @ts-ignore
  return this.displayList.add(new ObjectivesListContentPane(this.scene, x, y, objectivesData));
});
