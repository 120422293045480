import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { Shop_Assets, Squad_Assets } from '~/utils/AssetLoader';
import { UnloadImages } from '~/utils/AssetManager';

const modalHeight = 440;
const modalWidth = 520;
const modalRadius = 25;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;
const componentRadius = 16;
const subtitleTint = 0x75758b;
const messageColor = 0xffffff;
const submitButtonImage = 'squad_confirm_button';

const itemTypes = {
  speed_up_upgrade: 'speed_up_upgrade',
  machine: 'machine',
  shop_item: 'shop_item',
  machine_upgrade: 'machine_upgrade',
  machine_activation: 'machine_activation',
  resource_box: 'resource_box',
  machine_claim: 'machine_claim',
};

export default class ShopPurchaseResultScene extends Phaser.Scene {
  private _purchaseResult;
  private _item;
  private _scale;
  private _itemImage;
  private _itemType;

  init(data) {
    this._purchaseResult = data.purchaseResult;
    this._item = data.item;
    this._itemType = data.itemType || 'shop_item'; // machine or shop_item
  }

  constructor() {
    super({
      key: 'ShopPurchaseResultScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50); // 50 accounts for button extending beyond modal box
  }

  preload() {
    this.load.image(`shop_machines_${this._item.key}`, Shop_Assets[`shop_machines_${this._item.key}`]);
    this.load.image('squad_confirm_button', Squad_Assets.squad_confirm_button);
  }

  create() {
    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - this.applyScale(15), {
      width: this.applyScale(300),
      height: this.applyScale(340),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this.getTitleText(), this.applyScale(28), 1).setMaxWidth(300), {
      key: 'title_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: 5,
      expand: false,
    });

    // Item Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: componentRadius,
    };

    let itemImageKey;

    switch (this._itemType) {
      case itemTypes.shop_item:
        itemImageKey = `shop_${this._item.key}_image`;
        break;
      case itemTypes.machine:
        itemImageKey = this._item.shopImageKey;
        break;
      case itemTypes.machine_upgrade:
        itemImageKey = `shop_machines_${this._item.key}`;
        break;
      case itemTypes.speed_up_upgrade:
        itemImageKey = `shop_machines_${this._item.key}`;
        break;
      case itemTypes.machine_activation:
        itemImageKey = `shop_machines_${this._item.key}`;
        break;
      default:
        itemImageKey = `shop_machines_${this._item.key}`;
        break;
    }

    this._itemImage = this.add.rexCircleMaskImage(0, 0, itemImageKey, '', rectConfig).setScale(this._scale * 0.5);

    sizer.add(this._itemImage, {
      key: 'item_image',
      align: 'center-top',
      offsetX: 0,
      offsetY: 60,
      expand: false,
    });

    // Add Description Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this.getDescriptionText(), this.applyScale(24), 1).setTint(subtitleTint).setMaxWidth(300), {
      key: 'desc_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: 260,
      expand: false,
    });

    sizer.layout();

    // Add Return Home Button
    const returnHomeButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, submitButtonImage, false).setScale(this._scale);
    returnHomeButton.onClick().subscribe(pointer => {
      UserService.getInstance()
        .getUserData(true)
        .then(res => {
          if (this._itemType === itemTypes.machine_upgrade || this._itemType === itemTypes.speed_up_upgrade) {
            this.scene.get('HomebaseScene').input.enabled = true;
            this.scene.resume('HudScene');
          } else if (this._itemType === itemTypes.machine_claim) {
            this.scene.get('HomebaseScene').updateMachines(true);
            this.scene.get('HomebaseScene').input.enabled = true;
            this.scene.resume('HudScene');
          } else if (this._itemType === itemTypes.machine_activation) {
            this.scene.get('HomebaseScene').input.enabled = true;
            this.scene.resume('HudScene');
            this.scene.get('HomebaseScene').updateMachines();
          } else {
            this.scene.resume('ShopModalScene');
          }
          this.unloadAssets();
          this.scene.stop();
        });
    });
    this.add.existing(returnHomeButton);
  }

  private getTitleText() {
    if (this._purchaseResult.success) {
      return 'Success!';
    } else {
      if (this._itemType === itemTypes.machine_activation) {
        return 'Activation Failed...';
      } else if (this._itemType === itemTypes.machine_claim) {
        return 'Claim Failed...';
      }
      return 'Purchase Failed...';
    }
  }

  private getDescriptionText() {
    switch (this._itemType) {
      case itemTypes.shop_item:
        if (this._purchaseResult.success) {
          if (this._item.type === 'whitelist') {
            return `You purchased a spot on the ${this.getDisplayName()}.  Admins have been notified.`;
          } else {
            return `You purchased a ${this.getDisplayName()}.`;
          }
        } else {
          if (this._purchaseResult.msg === 'Whitelist exists') {
            return 'You have already purchased this WL.';
          } else if (this._purchaseResult.msg === 'Out of inventory') {
            return 'Sorry, this WL is sold out.';
          } else {
            return `${this._purchaseResult.msg}.`;
          }
        }
      case itemTypes.machine:
        if (this._purchaseResult.success) {
          return `You purchased a ${this._item.display_name} machine.`;
        } else {
          return `${this._purchaseResult.msg}.`;
        }
      case itemTypes.machine_upgrade:
        if (this._purchaseResult.success) {
          return `You purchased a ${this._item.display_name} machine upgrade.`;
        } else {
          return `${this._purchaseResult.msg}.`;
        }
      case itemTypes.machine_claim:
        if (this._purchaseResult.success) {
          return `You successfully claimed ${this._purchaseResult.claimed.value} ${this._purchaseResult.claimed.currency_name}.`;
        } else {
          return `${this._purchaseResult.msg}.`;
        }
      case itemTypes.speed_up_upgrade:
        if (this._purchaseResult.success) {
          return `Your ${this._item.display_name} machine upgrade has been sped up.`;
        } else {
          return `${this._purchaseResult.msg}.`;
        }
      case itemTypes.machine_activation:
        if (this._purchaseResult.success) {
          return `Your ${this._item.display_name} has been activated.`;
        } else {
          if (this._purchaseResult.msg === 'Machine is still cooling down') {
            return `${this._purchaseResult.msg}.\n\nTime Left: ${this.getTimeRemaining()}`;
          }
          return `${this._purchaseResult.msg}.`;
        }
      case itemTypes.resource_box:
        if (this._purchaseResult.success) {
          return `Your ${this._item.display_name} machine upgrade has been sped up.`;
        } else {
          return `${this._purchaseResult.msg}.`;
        }
      default:
        return '';
    }
  }

  private getDisplayName() {
    if (this._item.type === 'whitelist') {
      return this._item.name.slice(-2) === 'WL' ? this._item.name : `${this._item.name} WL`;
    } else {
      return this._item.name;
    }
  }

  private getTimeRemaining() {
    let hours = Math.floor(this._purchaseResult.seconds_left / 3600);
    let minutes = Math.floor((this._purchaseResult.seconds_left - hours * 3600) / 60);
    let seconds = this._purchaseResult.seconds_left % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  private applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, [`shop_machines_${this._item.key}`, 'squad_confirm_button']);
  }
}
