import Phaser from 'phaser';
import _ from 'lodash';
import { FixWidthSizer, Sizer } from 'phaser3-rex-plugins/templates/ui/ui-components';

const componentHeight = 20;
const maxPagesVisible = 10;

export default class PaginationControls extends Sizer {
  private _scale: number;
  private _totalCount: number;
  private _pageSize: number;
  private _currentPage: number;
  private _numPages: number;
  private _prevButton: any;
  private _nextButton: any;
  private _numberedButtons: any;
  private _currentFirstPage: number;
  private _currentLastPage: number;
  private _prevPageSetButton: any;
  private _nextPageSetButton: any;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, width: number, scale?: number, config?: any) {
    //Data = { totalCount: number, pageSize: number }
    let conf = config
      ? config
      : {
          width: scene.applyScale(width),
          height: scene.applyScale(componentHeight),
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 1,
        };
    super(scene, x, y, conf);

    this._totalCount = data.totalCount;
    this._pageSize = data.pageSize;
    this._numPages = Math.ceil(this._totalCount / this._pageSize);
    this._currentPage = 1;
    this._currentFirstPage = 1;
    this._currentLastPage = this._numPages < maxPagesVisible ? this._numPages : maxPagesVisible;
    this._scale = scale || 1;

    return this.createComponent(scene);
  }

  private createComponent(scene: Phaser.Scene) {
    this.addPrevButton(scene);
    this.addNumberedButtons(scene);
    this.addNextButton(scene);
    this.updateButtonAlpha();

    return scene.add.existing(this.layout());
  }

  private addPrevButton(scene: Phaser.Scene) {
    this._prevButton = scene.add.bitmapText(0, 0, 'cc_outline', '<', scene.applyScale(20), 0);

    this._prevButton.setInteractive({ cursor: 'pointer' });
    this._prevButton.on(
      'pointerdown',
      pointer => {
        this.prevButtonClickHandler(pointer);
      },
      this,
    );

    this.add(this._prevButton, {
      key: 'prev_button',
      padding: { left: 0, top: 0, right: 0, bottom: 0 },
    });

    this.addSpace();
  }

  private addNumberedButtons(scene: Phaser.Scene) {
    if (this._numberedButtons && this._numberedButtons.length > 0) {
      while (this._numberedButtons.length > 0) this._numberedButtons.pop();
    } else {
      this._numberedButtons = [];
    }

    if (this._numPages > maxPagesVisible && !this._prevPageSetButton) {
      this.addPrevPageSetButton(scene);
    }

    console.log(`First page: ${this._currentFirstPage}`);
    console.log(`Last page: ${this._currentLastPage}`);

    for (let i = this._currentFirstPage; i <= this._currentLastPage; i++) {
      this._numberedButtons.push(scene.add.bitmapText(0, 0, 'cc_outline', `${i}`, scene.applyScale(20), 0));

      this._numberedButtons[i - this._currentFirstPage].setInteractive({ cursor: 'pointer' });
      this._numberedButtons[i - this._currentFirstPage].on(
        'pointerdown',
        pointer => {
          if (this._currentPage === i) return;

          this.disableButtons();

          this._currentPage = i;
          this.updateNumberedButtons();
          this.scene.updatePage(this._currentPage);
        },
        this,
      );

      this.add(this._numberedButtons[i - this._currentFirstPage], {
        key: `page_${i}_button`,
        padding: { left: 0, top: 0, right: scene.applyScale(10), bottom: 0 },
      });
    }

    if (this._numPages > maxPagesVisible && !this._nextPageSetButton) {
      this.addNextPageSetButton(scene);
    }

    this.addSpace();
  }

  private addNextButton(scene: Phaser.Scene) {
    this._nextButton = scene.add.bitmapText(0, 0, 'cc_outline', '>', scene.applyScale(20), 0);

    this._nextButton.setInteractive({ cursor: 'pointer' });
    this._nextButton.on(
      'pointerdown',
      pointer => {
        this.nextButtonClickHandler(pointer);
      },
      this,
    );

    this.add(this._nextButton, {
      key: 'next_button',
      padding: { left: 0, top: 0, right: 0, bottom: 0 },
    });
  }

  private addPrevPageSetButton(scene: Phaser.Scene) {
    this._prevPageSetButton = scene.add.bitmapText(0, 0, 'cc_outline', `<<`, scene.applyScale(20), 0);
    this._prevPageSetButton.setInteractive({ cursor: 'pointer' });

    this._prevPageSetButton.on(
      'pointerdown',
      pointer => {
        this.prevPageSetButtonClickHandler(pointer);
      },
      this,
    );

    this.add(this._prevPageSetButton, {
      key: `prev_page_set_button`,
      padding: { left: 0, top: 0, right: scene.applyScale(20), bottom: 0 },
    });
  }

  private addNextPageSetButton(scene: Phaser.Scene) {
    this._nextPageSetButton = scene.add.bitmapText(0, 0, 'cc_outline', `>>`, scene.applyScale(20), 0);
    this._nextPageSetButton.setInteractive({ cursor: 'pointer' });

    this._nextPageSetButton.on(
      'pointerdown',
      pointer => {
        this.nextPageSetButtonClickHandler(pointer);
      },
      this,
    );

    this.add(this._nextPageSetButton, {
      key: `next_page_set_button`,
      padding: { left: scene.applyScale(10), top: 0, right: 0, bottom: 0 },
    });
  }

  private prevButtonClickHandler(pointer: any) {
    if (this._currentPage === 1) return;

    this.disableButtons();

    this._currentPage = this._currentPage - 1;
    this.updateNumberedButtons();
    this.updatePageData();
  }

  private nextButtonClickHandler(pointer: any) {
    if (this._currentPage === this._numPages) return;

    this.disableButtons();

    this._currentPage = this._currentPage + 1;
    this.updateNumberedButtons();
    this.updatePageData();
  }

  private prevPageSetButtonClickHandler(pointer: any) {
    if (this._currentFirstPage === 1) return;

    this.disableButtons();

    const newFirstPage = this.getFirstPage(this._currentFirstPage - 1);
    this._currentPage = newFirstPage;
    this.updateNumberedButtons();
    this.updatePageData();
  }

  private nextPageSetButtonClickHandler(pointer: any) {
    if (this._currentLastPage === this._numPages) return;

    this.disableButtons();

    const newFirstPage = this.getFirstPage(this._currentLastPage + 1);
    this._currentPage = newFirstPage;

    if (this._numPages - this._currentPage <= maxPagesVisible) {
      this._currentPage = this.getLastPage(this._currentLastPage + 1);
    }
    this.updateNumberedButtons();
    this.updatePageData();
  }

  private updatePageData() {
    this.scene.updatePage(this._currentPage);
  }

  private updateButtonAlpha() {
    this._prevButton.setAlpha(this._currentPage === 1 ? 0.4 : 1);

    if (this._prevPageSetButton) {
      this._prevPageSetButton.setAlpha(this._currentFirstPage === 1 ? 0.4 : 1);
    }

    for (let i = this._currentFirstPage; i <= this._currentLastPage; i++) {
      const pageIndex = i - this._currentFirstPage;
      if (this._currentPage === i) {
        this._numberedButtons[pageIndex].setAlpha(1);
      } else {
        this._numberedButtons[pageIndex].setAlpha(0.4);
      }
    }

    if (this._nextPageSetButton) {
      this._nextPageSetButton.setAlpha(this._currentLastPage === this._numPages ? 0.4 : 1);
    }

    this._nextButton.setAlpha(this._currentPage === this._numPages ? 0.4 : 1);
  }

  private updateNumberedButtons() {
    // New page is within range of numbered buttons already displayed, just set alpha
    if (this._currentPage >= this._currentFirstPage && this._currentPage <= this._currentLastPage) {
      this.updateButtonAlpha();
      return;
    }

    // New page is outside of range of displayed numbered buttons, update numbered button set
    this.removeNextButton();
    this.removeNumberedButtons();
    this.removePrevButton();
    this.getAllChildren().forEach(child => this.remove(child, true));

    this._currentFirstPage = this.getFirstPage(this._currentPage);
    this._currentLastPage = this.getLastPage(this._currentPage);

    this.addPrevButton(this.scene);
    this.addNumberedButtons(this.scene);
    this.addNextButton(this.scene);
    this.updateButtonAlpha();
  }

  private removeNextButton() {
    this._nextButton.setAlpha(0);
    this.remove(this._nextButton, true);
    this._nextButton = undefined;
  }

  private removePrevButton() {
    this._prevButton.setAlpha(0);
    this.remove(this._prevButton, true);
    this._prevButton = undefined;
  }

  private removeNumberedButtons() {
    this._prevPageSetButton?.setAlpha(0);
    this.remove(this._prevPageSetButton, true);
    this._prevPageSetButton = undefined;

    for (let i = this._currentFirstPage; i <= this._currentLastPage; i++) {
      const pageIndex = i - this._currentFirstPage;
      this._numberedButtons[pageIndex].setAlpha(0);
      this.remove(this._numberedButtons[pageIndex], true);
      this._numberedButtons[pageIndex] = undefined;
    }
    this._numberedButtons = undefined;

    this._nextPageSetButton?.setAlpha(0);
    this.remove(this._nextPageSetButton, true);
    this._nextPageSetButton = undefined;
  }

  private getFirstPage(currentPage: number) {
    if (currentPage <= maxPagesVisible) {
      // Current page is in the first group
      return 1;
    } else if (currentPage + maxPagesVisible > this._numPages) {
      // Current page is in the last group
      return this._numPages - maxPagesVisible + 1;
    } else {
      // Current page is not in the first or last group
      return Math.floor(currentPage / 10) * maxPagesVisible + 1;
    }
  }

  private getLastPage(currentPage: number) {
    if (currentPage <= maxPagesVisible) {
      // Current page is in the first group
      return this._numPages < maxPagesVisible ? this._numPages : maxPagesVisible;
    } else if (currentPage + maxPagesVisible > this._numPages) {
      // Current page is in the last group
      return this._numPages;
    } else {
      // Current page is not in the first or last group
      return Math.ceil(currentPage / 10) * maxPagesVisible;
    }
  }

  enableButtons() {
    this._nextButton?.setInteractive({ cursor: 'pointer' });
    this._nextPageSetButton?.setInteractive({ cursor: 'pointer' });
    this._prevButton?.setInteractive({ cursor: 'pointer' });
    this._prevPageSetButton?.setInteractive({ cursor: 'pointer' });
    this._numberedButtons.forEach(nb => nb.setInteractive({ cursor: 'pointer' }));
  }

  disableButtons() {
    this._nextButton?.disableInteractive();
    this._nextPageSetButton?.disableInteractive();
    this._prevButton?.disableInteractive();
    this._prevPageSetButton?.disableInteractive();
    this._numberedButtons.forEach(nb => nb.disableInteractive());
  }
}

Phaser.GameObjects.GameObjectFactory.register('paginationControls', function (x: number, y: number, config: any) {
  // @ts-ignore
  return this.displayList.add(new PaginationControls(this.scene, x, y, config));
});
