import ConfigurationService from './ConfigurationService';
import util from 'util';

export default class CollectionService {
  private static _instance: CollectionService = new CollectionService();

  private _collections: any[];
  private _config: any;

  constructor() {
    if (CollectionService._instance) {
      throw new Error('Error: Instantiation failed: Use CollectionService.getInstance() instead of new.');
    }
    CollectionService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
    this._collections = this._config.collections;
  }

  public static getInstance(): CollectionService {
    return CollectionService._instance;
  }

  public getCollections() {
    return this._collections;
  }

  public getCollectionsWithAll() {
    // Append 'All' option to collections
    const colClone = JSON.parse(JSON.stringify(this._collections));
    const colsWithAll = colClone.unshift({
      // Adds element to beginning of array clone
      displayName: 'All',
      collectionSlugs: this.getAllCollectionSlugs(),
      isSelected: true,
    });
    return colClone;
  }

  public getAllCollectionSlugs() {
    return this._collections.flatMap(colInfo => {
      return colInfo.collectionSlugs;
    });
  }
}
