import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';

type EnemyUser = {
  user_address: string;
  display_name: string;
};

type Machine = {
  user_machine_id: number;
  user_address: string;
  machine_id: number;
  level: number;
  time_until_next_level: number;
  x: number;
  y: number;
};

export type Base = {
  enemy_user: EnemyUser;
  machines: Machine[];
};

export default class BaseRaidService {
  private static _instance: BaseRaidService = new BaseRaidService();
  private attackInfo: any;
  private raidHistory: any;

  constructor() {
    if (BaseRaidService._instance) {
      throw new Error(`Error: Instatiation failed: use ${BaseRaidService.name}.getInstance() instead of new.`);
    }
    BaseRaidService._instance = this;
  }

  public static getInstance(): BaseRaidService {
    return BaseRaidService._instance;
  }

  public getRandomBase(next: number): any {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .post('base_raid', [address, 'view_bases'], { next: next })
      .then(res => {
        return res;
      });
  }

  public startAttack(defender_address: string): any {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .post('base_raid', [address, 'attack', defender_address], {})
      .then(res => {
        return res;
      });
  }

  public surrenderAttack(): any {
    const address = WalletConnectService.getInstance().getWalletAddress();

    return ApiProxyService.getInstance()
      .post('base_raid', [address, 'surrender'], {})
      .then(res => {
        return res;
      });
  }

  public viewAttack(userViewed: boolean = false): any {
    const address = WalletConnectService.getInstance().getWalletAddress();

    if (userViewed) {
      return ApiProxyService.getInstance()
        .post('base_raid', [address, 'view_attack'], {}, [`user_viewed=${userViewed}`])
        .then(res => {
          return res;
        });
    }

    return ApiProxyService.getInstance()
      .post('base_raid', [address, 'view_attack'], {})
      .then(res => {
        this.attackInfo = res;
        return res;
      });
  }

  public viewAttackSync(): any {
    return this.attackInfo;
  }

  public getRaidHistory(maxResults?: number) {
    const address = WalletConnectService.getInstance().getWalletAddress();
    const numRequested = maxResults || 100;

    return ApiProxyService.getInstance()
      .get('base_raid', [address, 'history', numRequested.toString()], {})
      .then(res => {
        this.raidHistory = res.base_raid_results;
        return res;
      });
  }

  public getRaidHistorySync() {
    return this.raidHistory;
  }
}
