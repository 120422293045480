import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import Button from '~/components/buttons/Button';
import RoundImageButton from '~/components/buttons/RoundImageButton';
import ConfigurationService from '~/services/ConfigurationService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';

const labels = {
  title: 'Your Settings',
  level: 'User Level',
  levelDesc: 'Keep grinding those levels, young wolf!',
  profilePic: 'User Profile Pic',
  profilePicDesc: 'The first NFT in your Active Squad will be assigned as your PFP.',
  username: 'Username',
  usernameDesc: 'This cannot be changed at this time.',
  squadName: 'Squad Name',
  squadNameDesc: 'This can only be changed 3 times.',
  twitter: 'Twitter',
  twitterDesc: 'Paste your Twitter ID, e.g. @wolflover',
  connectAccounts: 'Connect Accounts & Wallets',
  connectedWallets: 'Connected Wallets',
  other: 'Other',
};

const imageKeys = {
  connectWalletButton: 'connect_wallet_button',
  disconnectButton: 'disconnect_button',
  musicButtonActive: 'music_button_active',
  musicButtonInactive: 'music_button_active',
  soundsEffectsButtonActive: 'sound_effects_button_active',
  soundsEffectsButtonInActive: 'sound_effects_button_inactive',
};

const defaultVertOffset = 11;
const badgeVertOffset = 17;
const descTextVertOffset = -1;
const buttonSectionVertOffset = 30;
const buttonHorizontalOffset = 4;
const profilePicVertOffset = 6;

const inputTextTopPadding = 5;
const inputTextFontSize = 14;
const inputTextWidth = 518;
const inputTextHeight = 36;

export default class ProfileContentPane extends ScrollablePanel {
  private _userData;
  private _sizer;
  private _usernameTextBox;
  public _squadNameTextBox;
  public _twitterTextBox;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          x: x,
          y: y,
          width: scene.applyScale(566),
          height: scene.applyScale(642),
          scrollMode: 0,
          //background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, COLOR_PRIMARY),
          panel: {
            child: scene.rexUI.add.fixWidthSizer({
              space: {
                left: scene.applyScale(21),
                right: scene.applyScale(21),
                top: 0,
                bottom: scene.applyScale(21),
                item: scene.applyScale(10),
                line: 0,
              },
            }),

            mask: {
              padding: 1,
            },
          },

          mouseWheelScroller: {
            focus: true,
            speed: 0.2,
          },

          space: {
            left: 0,
            right: 0,
            top: scene.applyScale(21),
            bottom: 0,
            panel: 0,
          },
        };

    super(scene, conf);

    this._scale = paneScale;
    this._userData = UserService.getInstance().getUserDataSync();

    this.layout();
    this.createComponent();
  }

  createComponent() {
    this._sizer = this.getElement('panel');
    const scene = this.scene;

    // Title
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.title, scene.applyScale(20)), {
        key: 'title_label',
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    // User Level
    this.addLabelAndDesc(scene, 'user_level_label', labels.level, 'user_level_desc', labels.levelDesc);

    // Level Bar and Badge
    this._sizer
      .add(scene.add.image(0, 0, this.getLevelImageKey()).setScale(this._scale), {
        key: 'level_badge',
        padding: {
          left: 0,
          top: scene.applyScale(badgeVertOffset),
          right: scene.applyScale(20),
          bottom: scene.applyScale(badgeVertOffset - defaultVertOffset),
        },
      })
      .addNewLine();

    // User Profile Pic
    this.addLabelAndDesc(scene, 'profile_pic_label', labels.profilePic, 'profile_pic_desc', labels.profilePicDesc);

    // Pfp
    this.addPfpImage();

    console.log(this._userData);
    // Username
    this.addLabelAndDesc(scene, 'username_label', labels.username, 'username_desc', labels.usernameDesc);
    this._usernameTextBox = this.createTextBox(scene, 50, 'Username', true, this._userData.name?.toUpperCase());

    // Squad Name
    this.addLabelAndDesc(scene, 'squad_name_label', labels.squadName, 'squad_name_desc', labels.squadNameDesc);
    this._squadNameTextBox = this.createTextBox(scene, 50, 'Username', false, this._userData.squad_name?.toUpperCase());

    // Twitter
    this.addLabelAndDesc(scene, 'twitter_label', labels.twitter, 'twitter_desc', labels.twitterDesc);
    this._twitterTextBox = this.createTextBox(scene, 50, 'Username', false, this._userData.twitter?.toUpperCase());

    // Connect Accounts
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.connectAccounts, scene.applyScale(16)), {
        key: 'connect_accounts_label',
        padding: { left: 0, top: scene.applyScale(buttonSectionVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    //   Connect Wallet Button
    const connectWalletButton = new Button(scene, 0, 0, 'settings_connect_wallet_button', false);
    connectWalletButton.onClick().subscribe(this.connectWalletButtonHandler);

    this._sizer
      .add(scene.add.existing(connectWalletButton), {
        key: 'connect_wallet_button',
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    // Connected Wallets
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.connectedWallets, scene.applyScale(16)), {
        key: 'connected_wallets_label',
        padding: { left: 0, top: scene.applyScale(buttonSectionVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    // Other settings
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.other, scene.applyScale(16)), {
        key: 'other_settings_label',
        padding: { left: 0, top: scene.applyScale(buttonSectionVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    //   Music Button
    const musicButton = new Button(scene, 0, 0, 'music_button_active', false);
    musicButton.onClick().subscribe(this.musicButtonHandler);

    this._sizer.add(scene.add.existing(musicButton), {
      key: 'music_button',
      padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: scene.applyScale(buttonSectionVertOffset) },
    });

    //   Sounds Effects Button
    const soundEffectsButton = new Button(scene, 0, 0, 'sound_effects_button_active', false);
    soundEffectsButton.onClick().subscribe(this.soundEffectsButtonHandler);

    this._sizer
      .add(scene.add.existing(soundEffectsButton), {
        key: 'sound_effects_button',
        padding: {
          left: scene.applyScale(buttonHorizontalOffset),
          top: scene.applyScale(defaultVertOffset),
          right: 0,
          bottom: scene.applyScale(buttonSectionVertOffset),
        },
      })
      .addNewLine();

    // Layout Sizer
    this.layout();

    // Removes focus from inputTexts when user clicks outside of them
    scene.input.on(
      'pointerdown',
      function () {
        scene.getProfilePane()?._squadNameTextBox?.setBlur();
        scene.getProfilePane()?._twitterTextBox?.setBlur();
      },
      this,
    );
  }

  private addLabelAndDesc(scene: Phaser.Scene, labelKey: string, labelText: string, descKey: string, descText: string) {
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labelText, scene.applyScale(16)), {
        key: labelKey,
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', descText, scene.applyScale(16)).setAlpha(0.3), {
        key: descKey,
        padding: { left: 0, top: scene.applyScale(descTextVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();
  }

  private addPfpImage() {
    const scene = this.scene;
    const squad = SquadService.getInstance().getSquadSync();

    let pfpKey = !squad || squad.length === 0 ? 'starwolf_silhouette' : squad[0].image_key;
    let rectConfig = { maskType: 'roundRectangle', radius: 0 };

    const pfpImageData = new Phaser.GameObjects.Image(scene, 0, 0, pfpKey);
    const pfpImageWidth = pfpImageData.width;
    rectConfig.radius = pfpImageWidth / 15;

    var pfpImage = new RoundImageButton(scene, 0, 0, pfpKey, rectConfig, false);
    pfpImage.displayHeight = scene.applyScale(100);
    pfpImage.scaleX = pfpImage.scaleY;

    pfpImageData.destroy();
    pfpImage.disableInteractive();

    this._sizer
      .add(scene.add.existing(pfpImage).setScale(this._scale * pfpImage.scaleY), {
        key: 'pfp_image',
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: scene.applyScale(profilePicVertOffset) },
      })
      .addNewLine();
  }

  createTextBox(scene: Phaser.Scene, maxLength: number, placeholderText: string, readOnly: boolean, textValue?: string) {
    // Wallet Input Text
    const inputTextConfig = {
      x: 0,
      y: 0,
      width: undefined,
      height: undefined,
      type: 'text', // 'text'|'password'|'textarea'|'number'|'color'|...
      id: undefined,
      text: textValue,
      maxLength: maxLength,
      minLength: undefined,
      placeholder: placeholderText,
      tooltip: undefined,
      readOnly: readOnly,
      spellCheck: false,
      autoComplete: 'off',
      align: 'left',
      paddingLeft: `${scene.applyScale(10)}px`,
      paddingRight: undefined,
      paddingTop: `${scene.applyScale(2)}px`,
      paddingBottom: undefined,
      fontFamily: 'ccwhatchamacallitbold',
      fontSize: `${scene.applyScale(inputTextFontSize)}px`,
      color: '#ffffff',
      border: 'none',
      backgroundColor: '#E6E6FF0D',
      borderColor: undefined,
      outline: 'none',
      direction: 'ltr',
      selectAll: false,
    };

    var inputText = scene.add.rexInputText(0, 0, scene.applyScale(inputTextWidth), scene.applyScale(inputTextHeight), inputTextConfig);
    inputText.setStyle('border-radius', '8px');

    if (textValue) {
      inputText.setText(textValue);
    }

    this._sizer.add(inputText, {
      key: `${placeholderText}_input_text`,
      padding: { left: 0, top: scene.applyScale(inputTextTopPadding), right: 0, bottom: 0 },
    });

    // inputText.on('wheel', (pointer, dx, dy, dz, eventInfo) => {
    //   this.emit('wheel', [pointer, dx, dy, dz, eventInfo]);
    // });

    return inputText;
  }

  private connectWalletButtonHandler(pointer) {}

  private musicButtonHandler(pointer) {}

  private soundEffectsButtonHandler(pointer) {}

  private getLevelImageKey() {
    const levelBadges = ConfigurationService.getInstance().getConfig().userBadges;
    const level = this._userData.level;

    var badge = levelBadges.find(lb => {
      if (lb.maxLevel === null) {
        return level >= lb.minLevel;
      } else {
        return level >= lb.minLevel && level <= lb.maxLevel;
      }
    });

    if (badge) {
      return badge.badgeImageKey;
    }

    return levelBadges[0].badgeImageKey;
  }
}

Phaser.GameObjects.GameObjectFactory.register('profileContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ProfileContentPane(this.scene, x, y));
});
