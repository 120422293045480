import Phaser, { Scene } from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import { secondsToTime } from '~/utils/TimeUtils';

const paneWidth = 110;
const defaultTimeUntilAttack = 30; // seconds

export default class RaidClockComponent extends FixWidthSizer {
  private _scale;
  private _clockData;
  private _timerText;
  private _timeRemaining;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: scene.applyScale(paneWidth),
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 2,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._clockData = data;
    this._timeRemaining = this.getInitialTimeRemaining();

    this.createComponent(scene);

    this.setTimeEvent();

    return this.layout();
  }

  private createComponent(scene: Phaser.Scene) {
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', this.getHeaderText(), scene.applyScale(16), 1), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'header_text',
    });

    this._timerText = scene.add.bitmapText(0, 0, 'cc_outline', this.getTimerText(), scene.applyScale(20), 1);
    this.add(this._timerText, {
      padding: { left: 0, right: 0, top: scene.applyScale(3), bottom: 0 },
      key: 'timer_text',
    });
  }

  private setTimeEvent() {
    this.scene.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        this.updateTimeHandler();
      },
    });
  }

  private getHeaderText() {
    return this._clockData.isAttacking ? 'Remaining Time:' : 'Raid starts in: ';
  }

  private getTimerText() {
    return this._timeRemaining < 10 ? ` ${secondsToTime(this._timeRemaining)}` : secondsToTime(this._timeRemaining);
  }

  private getInitialTimeRemaining() {
    return this._clockData.isAttacking ? this._clockData.timeRemaining : defaultTimeUntilAttack;
  }

  private updateTimeHandler() {
    if (this._timeRemaining > 0) {
      this._timeRemaining = this._timeRemaining - 1;
      this._timerText.text = this.getTimerText();
    } else {
      this._timeRemaining = 0;
      this._timerText.text = this.getTimerText();
      this.timerFinishedHandler();
    }
  }

  private timerFinishedHandler() {
    // Remove timer update event
    this.scene.time.removeAllEvents();

    if (this._clockData.isAttacking) {
      // Handle attack finished
      this.scene.scene.get('BaseRaidHudScene').handleAttackFinished();
    } else {
      // Start attack
      this.scene.scene.get('BaseRaidHudScene').startAttack();
    }
  }

  public setClockData(clockData: any) {
    if (clockData) {
      // Update data
      this._clockData = clockData;
      this._timeRemaining = clockData.timeRemaining;

      // Remove old timer events
      this.scene.time.removeAllEvents();

      // Set Header text
      this.getElement('header_text').setText(this.getHeaderText());

      // Add new timer event
      this.updateTimeHandler();
      this.setTimeEvent();
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register('raidClockComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new RaidClockComponent(this.scene, x, y));
});
