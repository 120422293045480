import Phaser from 'phaser';
import UserService from '~/services/UserService';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { Loading_Assets } from '~/utils/AssetLoader';

export default class NameScene extends Phaser.Scene {
  private nameInput;
  private returnKey;
  private confirmButton;

  constructor() {
    super({
      key: 'NameScene',
    });
  }

  preload() {
    LoadImageAssets(this, Loading_Assets);

    this.load.html('name_form', 'assets/nameForm.html');
  }

  create() {
    // background
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'name_rect');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'name_text');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 163, 'name_btn_disabled');

    this.confirmButton = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 163, 'name_btn_enabled').setInteractive();
    this.confirmButton.visible = false;
    this.confirmButton.input.enabled = false;

    this.input.on('gameobjectover', this.hoverHandler, this);
    this.input.on('gameobjectout', this.hoverOutHandler, this);
    this.input.on('gameobjectup', this.clickHandler, this);

    this.nameInput = this.add.dom(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 60).createFromCache('name_form');
    this.returnKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

    this.returnKey.on('down', event => {
      this.handleEnter();
    });

    this.input.keyboard.on('keydown', event => {
      let name = this.nameInput.getChildByName('name');
      if (name.value != '' && name.value.length <= 16) {
        this.confirmButton.visible = true;
        this.confirmButton.input.enabled = true;
      } else {
        this.confirmButton.visible = false;
        this.confirmButton.input.enabled = false;
      }
    });
  }

  hoverHandler(pointer, button) {
    button.setScale(1.05);
  }

  hoverOutHandler(pointer, button) {
    button.setScale(1);
  }

  clickHandler(pointer, button) {
    this.handleEnter();
  }

  private handleEnter() {
    let name = this.nameInput.getChildByName('name');
    this.confirmButton.visible = false;
    this.confirmButton.input.enabled = false;

    if (name.value != '' && name.value.length <= 16) {
      UserService.getInstance()
        .postUserData(name.value)
        .then(userData => {
          UserService.getInstance()
            .recordLogin()
            .then(res => {
              if (res && res.success) {
                this.scene.start('HomebaseLoadingScene');
              } else {
                // Show a failed login screen
                this.scene.start('LoginFailedScene');
              }
            });
        });
    }
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Loading_Assets));
  }
}
