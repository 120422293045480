import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';

const modalHeight = 386;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;
const componentRadius = 16;
const subtitleTint = 0x75758b;
const messageColor = 0xffffff;
const submitButtonImage = 'squad_confirm_button';

const itemTypes = {
  resource_box: 'resource_box',
};

export default class ShopRewardScene extends Phaser.Scene {
  private _purchaseResult;
  private _item;
  private _scale;
  private _itemType;

  init(data) {
    this._purchaseResult = data.purchaseResult;
    this._item = data.item;
    this._itemType = data.itemType || 'shop_item'; // machine or shop_item
  }

  constructor() {
    super({
      key: 'ShopRewardScene',
    });

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 50); // 50 accounts for button extending beyond modal box
  }

  preload() {}

  create() {
    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer(this.sys.canvas.width / 2, modalBg.getTopLeft().y + this.applyScale(177), {
      width: this.applyScale(260),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add Title Text
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', this.getTitleText(), this.applyScale(28), 1).setMaxWidth(this.applyScale(260)), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'title_text',
    });

    if (this._purchaseResult.success) {
      const seReward = this._purchaseResult.reward_info.find(ri => ri.currency_id === 2);
      const ngReward = this._purchaseResult.reward_info.find(ri => ri.currency_id === 1);

      // Add Reward Labels
      const seBar = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', seReward.value.toLocaleString('en-us'));
      const ngBar = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', ngReward.value.toLocaleString('en-us'));

      sizer.add(this.add.existing(seBar), {
        padding: { left: 0, right: 0, top: this.applyScale(27), bottom: 0 },
        key: 'se_bar',
      });

      sizer.add(this.add.existing(ngBar), {
        padding: { left: 0, right: 0, top: this.applyScale(18), bottom: 0 },
        key: 'ng_bar',
      });
    }

    sizer.layout();

    // Add Return Home Button
    const returnHomeButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, submitButtonImage, false).setScale(this._scale);
    returnHomeButton.onClick().subscribe(pointer => {
      UserService.getInstance()
        .getUserData(true)
        .then(res => {
          this.scene.resume('ShopModalScene');
          this.scene.stop();
        });
    });
    this.add.existing(returnHomeButton);
  }

  private getTitleText() {
    if (this._purchaseResult.success) {
      return 'You gained the following resources:';
    } else {
      return `Purchase Failed.  ${this._purchaseResult.msg}.`;
    }
  }

  private applyScale(length) {
    return length * this._scale;
  }
}
