import ApiProxyService from "./ApiProxyService"
import WalletConnectService from "./WalletConnectService"
import util from "util"

export default class PVPBattleService {
    private static _instance: PVPBattleService = new PVPBattleService()

    private _leaderData: any

    constructor() {
        if (PVPBattleService._instance) {
            throw new Error(
                "Error: Instantiation failed: Use PVPBattleService.getInstance() instead of new.",
            )
        }
        PVPBattleService._instance = this
    }

    public static getInstance(): PVPBattleService {
        return PVPBattleService._instance
    }

    public getLeaderData(forceUpdate: boolean = false) {
        return new Promise((resolve, reject) => {
            if (this._leaderData && !forceUpdate) {
                return resolve(this._leaderData)
            } else {
                return resolve(this.fetchLeaderData())
            }
        })
    }

    public getLeaderDataSync() {
        return this._leaderData
    }

    private fetchLeaderData() {
        const wallet = WalletConnectService.getInstance().getWalletAddress()
        return ApiProxyService.getInstance()
            .get("pvp_battle", ["leaderboard"], [])
            .then(res => {
                this._leaderData = res
                return res
            })
    }

}
