import Phaser, { Game, Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import { GameState } from '~/../../API/BossFight/src/types/IBossFightState';
import Button from '~/components/buttons/Button';
import TimerProgressBarComponent from '~/components/missions/timerProgressBarComponent';
import { getNextBossFightCountdown } from '~/utils/TimeUtils';

// Pane Details
const paneHeight = 184;
const paneWidth = 258;
const paneRadius = 26;
const paneColor = 0xa0116f;
const paneAlpha = 0.9;

export default class BossFightCardPane extends OverlapSizer {
  private _scale: number;
  private _scene;
  private _roomData;
  private _countDownProgressBar;
  private _bossFightText;
  private _readyUpButton;
  private _internalTimer;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scene = scene;
    this._scale = paneScale;
    this._roomData = data;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Panel
    var panel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Timer Progress Bar
    this._countDownProgressBar = new TimerProgressBarComponent(scene, 0, 0, this._scale, getNextBossFightCountdown());
    this._countDownProgressBar.setVisible(false);

    panel.add(this._countDownProgressBar, {
      key: 'progress_bar',
      align: 'left-top',
      offsetX: this.applyScale(28),
      offsetY: this.applyScale(-16),
      expand: false,
    });

    // BossFight Text
    this._bossFightText = this.scene.add.bitmapText(0, 0, 'cc_outline', '', scene.applyScale(18), 1);
    panel.add(this._bossFightText, {
      key: 'bossfight-text',
      align: 'left-top',
      offsetX: this.applyScale(34),
      offsetY: this.applyScale(40),
      expand: true,
    });

    // Ready up Button
    this._readyUpButton = new Button(scene, 0, 0, 'bossfight_hud_go_to_battle_button', false).setScale(this._scale);

    this._readyUpButton.onClick().subscribe(pointer => {
      this._scene.startBossFightScene();
    });

    panel.add(scene.add.existing(this._readyUpButton), {
      key: 'ready-up-button',
      align: 'left-top',
      offsetX: this.applyScale(28),
      offsetY: this.applyScale(102),
      expand: false,
    });

    this.add(panel, {
      key: 'panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public updateCard(battleData) {
    var gameState = this.getGameStateEnum(battleData.gameState);
    var cardText = '';

    switch (gameState) {
      case GameState.PreGame:
        this._countDownProgressBar.setVisible(true);
        this.startCountDownUI(getNextBossFightCountdown());
        cardText = 'Boss Fight Game Status\nPre Game';
        if (getNextBossFightCountdown() > 600) {
          // Display Join Button
          this._readyUpButton.setVisible(false);
          cardText = 'Boss Fight Game Status\nPreparing Server';
        }

        break;
      case GameState.PreRound:
      case GameState.Round:
        cardText = 'Boss Fight Game Status\n Active';
        break;
      case GameState.ProcessResults:
        cardText = 'Boss Fight Game Status\n Processing Results...';
        break;
      case GameState.DisplayResults:
        cardText = 'Boss Fight Game Status\nPost Game';
        break;
      case GameState.Finished:
        cardText = 'Boss Fight Game Status\nCompleted';
        break;
    }

    // update the text box based on the round number
    this._bossFightText.setText(cardText);
  }

  getGameStateEnum(number: Number) {
    switch (number) {
      case 0:
        return GameState.PreGame;
      case 1:
        return GameState.PreRound;
      case 2:
        return GameState.Round;
      case 3:
        return GameState.ProcessResults;
      case 4:
        return GameState.DisplayResults;
      case 5:
        return GameState.Finished;
    }
    return GameState.PreGame;
  }

  private startCountDownUI(countDown: number) {
    // Implements Countdown
    var remainingSeconds = countDown;

    // Resets Countdown Bar Values
    this._countDownProgressBar.roundTimerReset(this, remainingSeconds);

    clearInterval(this._internalTimer);
    this._internalTimer = setInterval(() => {
      //var _timeRemainingInterval = setInterval(() => {
      try {
        if (remainingSeconds <= 0) return clearInterval(this._internalTimer);
        remainingSeconds--;
        this._countDownProgressBar.updateDisplay(this, remainingSeconds);

        if (remainingSeconds <= 600) {
          this._readyUpButton.setVisible(true);
          this._bossFightText.setText('Boss Fight Game Status\nPre Game');
        }
      } catch (error) {
        return clearInterval(this._internalTimer);
      }
    }, 1000);
  }

  public stopTimer() {
    clearInterval(this._internalTimer);
  }
}

Phaser.GameObjects.GameObjectFactory.register('hudBossFightCardPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightCardPane(this.scene, x, y));
});
