import Phaser from 'phaser';
import ProgressBar from '~/components/progressBar/ProgressBar';
import UserService from '~/services/UserService';
import Button from '../../components/buttons/Button';
import SquadComponent from '~/components/hud/squadComponent';
import PVPBattleService from '../../services/PVPBattleService';
import MiningService, { Asteroid, asteroidRarityLabelMapper, asteroidRarityColorMapper } from '../../services/MiningService';

import { convertSecondsToCountdown } from '~/utils/GameUtils';
import BossFightServer from '../../services/BossFightServerService';
import SquadService from '~/services/SquadService';
import MachineService from '~/services/MachineService';
import ConfigurationService from '~/services/ConfigurationService';
import LevelProgressBarComponent from '~/components/hud/levelProgressBarComponent';
import { BossFight_Assets, Hud_Assets } from '~/utils/AssetLoader';
import BossFightCardPane from './hudBossFightCardPane';
import ResourcesComponent from '~/components/hud/resourcesComponent';
import BaseRaidService from '~/services/BaseRaidService';
import NotificationService from '~/services/NotificationService';
import HudNotificationComponent from '~/components/hud/hudNotificationComponent';
import TroopService from '~/services/TroopService';
import BattleRoyaleServerService from '~/services/BattleRoyaleServerService';
import { getNextBattleRoyaleCountdown, getNextBossFightCountdown } from '~/utils/TimeUtils';
import BattleRoyaleCardPane from './hudBattleRoyaleCardPane';
import { GameState } from '../../../../API/BattleRoyale/src/types/IBattleRoyaleState';
import ScaleService from '~/services/ScaleService';
import StakingService from '~/services/StakingService';
import BattlePassService from '~/services/BattlePassService';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const leftMargin = 24;
const topMargin = 24;

const progressBarHoverAlpha = 0.8;
const tintColor = 0xcdcdcd;
const minCanvasWidth = 1330;
const minCanvasHeight = 800;
const circleFillColor = 0xea1616;
const minDaysHeldToStake = 7;

const HudHoverAudio = require('url:../../../public/assets/audios/hud-button-hover.wav');

export default class HudScene extends Phaser.Scene {
  protected _allowProgressBarClicks = true;
  protected _userData;
  private _levelProgressBar;
  private _resourcesComponent;
  private _objectivesButton;
  private _leaderboardButton;
  private _helpdeskButton;
  private _armyButton;
  private _raidHistoryButton;
  private _battlePassButton;
  private _buildButton;
  private _shopButton;
  private _squadComponent;
  private _miningStatus;
  private _baseRaidInfo;
  private _bossFightCardPane;
  private _battleRoyaleCardPane;
  private _reward;
  private _missionWidget;
  protected _scale;
  private _missionsWidget = {};
  private _notificationData;
  private _notifications;
  private _stakingAlert;

  constructor(key) {
    if (!key) {
      key = { key: 'HudScene' };
    }

    super(key);
  }

  preload() {
    LoadImageAssets(this, Hud_Assets);

    // User Badge Assets
    const userBadges = ConfigurationService.getInstance().getConfig().userBadges;

    if (userBadges && userBadges.length > 0) {
      userBadges.forEach(badge => {
        this.load.image(badge.badgeImageKey, badge.badgeImagePath);
      });
    }

    // Squad PFPs
    const squad = SquadService.getInstance().getSquadSync();

    if (squad && squad.length > 0) {
      squad.forEach(sm => {
        this.load.image(sm.image_key, sm.image_thumbnail_url);
      });
    }

    this.load.image('bossfight_hud_go_to_battle_button', BossFight_Assets.bossfight_hud_go_to_battle_button);
    this.load.audio('hud-button-hover', HudHoverAudio);
    this.load.image('bossfight_clock_icon', BossFight_Assets.bossfight_clock_icon);
  }

  async create() {
    this._scale = ScaleService.getInstance().getScale(minCanvasWidth, minCanvasHeight);

    this._userData = UserService.getInstance().getUserDataSync();
    if (Array.isArray(this._userData.user)) {
      this._userData = this._userData.user[0];
      this._userData.brawlerCage = false;
    }

    this._userData.squadMembers = SquadService.getInstance().getSquadSync();
    this._userData.storage = MachineService.getInstance().getStorageCapacities();
    this._miningStatus = MiningService.getInstance().getMiningStatusSync();
    this._baseRaidInfo = BaseRaidService.getInstance().viewAttackSync();
    this._reward = MiningService.getInstance().getLastMinedRewardSync();

    // BossFight
    await BossFightServer.getInstance().isServerAvailable();

    // Battle Royale
    await BattleRoyaleServerService.getInstance().isServerAvailable();

    // Check for mining mission activity/rewards
    if (Object.keys(this._reward).length) {
      this.addLastMinedRewardMissionButton();
    } else if (this._miningStatus.length) {
      this.addMissionProgressButton(this._miningStatus[0]);
    } else {
      // Check for base raid activity/rewards
      if (this._baseRaidInfo.base_raid_id !== 0 && !this._baseRaidInfo.completed) {
        // Base raid ongoing, show status
        this.addBaseRaidProgressButton(this._baseRaidInfo);
      } else if (this._baseRaidInfo.base_raid_id !== 0 && this._baseRaidInfo.completed) {
        // Base raid finished, show results
        this.addLastBaseRaidRewardMissionButton();
      } else {
        this.addMissionLaunchButton();
      }
    }

    // Quests button
    this._objectivesButton = new Button(this.scene.scene, this.applyScale(68), this.applyScale(140), 'hud_quests_button', false)
      .setScale(this._scale)
      .setScrollFactor(0);

    this._objectivesButton.onClick().subscribe(pointer => {
      this.objectivesButtonClickHandler(pointer);
    });
    this._objectivesButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.objectivesButtonOnHover);
    this._objectivesButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.objectivesButtonOnOut);

    this.add.existing(this._objectivesButton);
    this.add.image(this.applyScale(68), this.applyScale(183), 'quests_text').setOrigin(0.5, 0.5).setScale(this._scale);
    this._stakingAlert = this.add.circle(this.applyScale(105), this.applyScale(101), this.applyScale(8), circleFillColor, 1);
    this.setStakingAlertVisible();

    // Leaderboard button
    this._leaderboardButton = new Button(this.scene.scene, this.applyScale(68), this.applyScale(255), 'hud_leaderboard_button', false)
      .setScale(this._scale)
      .setScrollFactor(0);

    this._leaderboardButton.onClick().subscribe(pointer => {
      this.leaderboardButtonClickHandler(pointer);
    });
    this._leaderboardButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.leaderboardButtonOnHover);
    this._leaderboardButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.leaderboardButtonOnOut);

    this.add.existing(this._leaderboardButton);
    this.add.image(this.applyScale(68), this.applyScale(299), 'leaders_text').setOrigin(0.5, 0.5).setScale(this._scale);

    // Helpdesk Button
    this._helpdeskButton = new Button(this.scene.scene, this.applyScale(68), this.applyScale(370), 'hud_helpdesk_button', false)
      .setScale(this._scale)
      .setScrollFactor(0);

    this._helpdeskButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.helpdeskButtonOnHover);

    this._helpdeskButton.onClick().subscribe(pointer => {
      this.helpdeskButtonClickHandler(pointer);
    });

    this.add.existing(this._helpdeskButton);
    this.add.image(this.applyScale(68), this.applyScale(415), 'help_text').setOrigin(0.5, 0.5).setScale(this._scale);

    // Raid History Button
    this._raidHistoryButton = new Button(this.scene.scene, this.applyScale(68), this.applyScale(485), 'hud_raid_history_button', false)
      .setScale(this._scale)
      .setScrollFactor(0);

    this._raidHistoryButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.raidHistoryButtonOnHover);

    this._raidHistoryButton.onClick().subscribe(pointer => {
      this.raidHistoryButtonClickHandler(pointer);
    });

    this.add.existing(this._raidHistoryButton);
    this.add.image(this.applyScale(68), this.applyScale(529), 'raid_history_text').setOrigin(0.5, 0.5).setScale(this._scale);

    // Battle Pass Button
    // this._battlePassButton = new Button(this.scene.scene, this.applyScale(68), this.applyScale(600), 'hud_battlepass_button', false)
    //   .setScale(this._scale)
    //   .setScrollFactor(0);

    // this._battlePassButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.battlePassButtonOnHover);

    // this._battlePassButton.onClick().subscribe(pointer => {
    //   this.battlePassButtonClickHandler(pointer);
    // });

    // this.add.existing(this._battlePassButton);
    // this.add.image(this.applyScale(68), this.applyScale(642), 'battlepass_text').setOrigin(0.5, 0.5).setScale(this._scale);

    // Squad display
    this._squadComponent = new SquadComponent(this, this.applyScale(155), this.sys.canvas.height - this.applyScale(80), this._scale);
    this.add
      .image(this.applyScale(78), this.sys.canvas.height - this.applyScale(129), 'your_squad_text')
      .setOrigin(0.5, 0.5)
      .setScale(this._scale);

    // Army Button
    this._armyButton = new Button(this.scene.scene, this.applyScale(347), this.sys.canvas.height - this.applyScale(80), 'hud_army_button', false);
    this._armyButton.displayHeight = 100;
    this._armyButton.scaleX = this._armyButton.scaleY;
    this._armyButton.setScale(this._armyButton.scale * this._scale).setScrollFactor(0);

    this._armyButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.armyButtonOnHover);

    this._armyButton.onClick().subscribe(pointer => {
      this.armyButtonClickHandler(pointer);
    });

    this.add.existing(this._armyButton);
    this.add
      .image(this.applyScale(334), this.sys.canvas.height - this.applyScale(129), 'troops_text')
      .setOrigin(0.5, 0.5)
      .setScale(this._scale);

    // Build button
    this._buildButton = new Button(this.scene.scene, this.applyScale(458), this.sys.canvas.height - this.applyScale(80), 'hud_build_button', false);
    this._buildButton.displayHeight = 100;
    this._buildButton.scaleX = this._buildButton.scaleY;
    this._buildButton.setScale(this._buildButton.scale * this._scale).setScrollFactor(0);

    this._buildButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.buildButtonOnHover);

    this._buildButton.onClick().subscribe(pointer => {
      this.buildButtonClickHandler(pointer);
    });

    this.add.existing(this._buildButton);
    this.add
      .image(this.applyScale(442), this.sys.canvas.height - this.applyScale(129), 'build_text')
      .setOrigin(0.5, 0.5)
      .setScale(this._scale);

    // Shop button
    this._shopButton = new Button(this.scene.scene, this.applyScale(567), this.sys.canvas.height - this.applyScale(80), 'hud_shop_button', false);
    this._shopButton.displayHeight = 100;
    this._shopButton.scaleX = this._shopButton.scaleY;
    this._shopButton.setScale(this._shopButton.scale * this._scale).setScrollFactor(0);

    this._shopButton.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.shopButtonOnHover);

    this._shopButton.onClick().subscribe(pointer => {
      this.shopButtonClickHandler(pointer);
    });

    this.add.existing(this._shopButton);
    this.add
      .image(this.applyScale(549), this.sys.canvas.height - this.applyScale(129), 'shop_text')
      .setOrigin(0.5, 0.5)
      .setScale(this._scale);

    // BossFight Widget
    this._bossFightCardPane = new BossFightCardPane(
      this,
      this.sys.canvas.width - this.applyScale(159),
      this.sys.canvas.height - this.applyScale(350),
      //this.sys.canvas.height - 80,
      {},
      this._scale,
    );
    this.add.existing(this._bossFightCardPane);
    this._bossFightCardPane.setVisible(false);
    this.launchBossFightAlerts();

    // Battle Royale Widget
    this._battleRoyaleCardPane = new BattleRoyaleCardPane(
      this,
      this.sys.canvas.width - this.applyScale(159),
      this.sys.canvas.height - this.applyScale(350),
      {},
      this._scale,
    );
    this.add.existing(this._battleRoyaleCardPane);
    this._battleRoyaleCardPane.setVisible(false);
    this.launchBattleRoyaleAlerts();

    this.addLevelBar(this);

    this.addResourcesComponent(MachineService.getInstance().getUserMachinesSync());

    // Notifications for onboarding
    if (this._notifications) {
      this._notifications = undefined;
    }
    this.displayOnboardingNotifications();

    this.events.on('resume', (scene, data) => {
      if (data != undefined && data.squadUpdated) {
        this._squadComponent.updateSquad(true);
      }
      this.updateProgressBars(true);
    });

    if (UserService.getInstance().getIsNewUser()) {
      this.scene.pause('HomebaseScene');
      this.scene.pause('HudScene');
      this.scene.launch('SquadModalScene');
    }
  }

  private missionsButtonClickHandler(this, pointer) {
    this.scene.pause('HomebaseScene');
    this.scene.pause('HudScene');
    this.scene.launch('MissionsModalScene');
  }

  private buildButtonClickHandler(this, pointer) {
    this.scene.pause();
    this.scene.get('HomebaseScene').input.enabled = false;
    this.scene.launch('ShopModalScene', { active_tab: 'machines' });
  }

  private shopButtonClickHandler(this, pointer) {
    this.scene.pause();
    this.scene.get('HomebaseScene').input.enabled = false;
    this.scene.launch('ShopModalScene', { active_tab: 'item_shop' });
  }

  launchBattleRoyaleAlerts() {
    // Check if Server is up and running

    if (BattleRoyaleServerService.getInstance().isServerAlive()) {
      var roomData = BattleRoyaleServerService.getInstance().getRoomData();
      var brCountdown = getNextBattleRoyaleCountdown();

      switch (roomData.gameState as GameState) {
        case GameState.PreGame:
          // Show Small BR Notification
          if (brCountdown < 1800 /*&& brCountdown > 60*/) {
            // BR Starts in less than 30 minutes. Show UI to player
            this._battleRoyaleCardPane.setVisible(true);
            this._battleRoyaleCardPane.updateCard(roomData);
          }

          // Show the Battle Royale Game Alert Notification - PreGame
          if (brCountdown < 60) {
            this.battleRoyaleAlertHandler(roomData.isParticipant);
          }

          break;
        default:
          // show blue notification. change display based on gamestate and if player has joined or not
          this._battleRoyaleCardPane.setVisible(true);
          this._battleRoyaleCardPane.updateCard(roomData);
          break;
      }
    }
  }

  launchBossFightAlerts() {
    // Check if Server is up and running

    if (BossFightServer.getInstance().isServerAlive()) {
      var roomData = BossFightServer.getInstance().getRoomMetaData();
      var bfCountdown = getNextBossFightCountdown();

      switch (roomData.gameState as number) {
        case 0: //PreGame
          //Show Small BF Notification
          if (bfCountdown < 1800) {
            // BF Starts in less than 30 minutes. Show UI to player
            this._bossFightCardPane.setVisible(true);
            this._bossFightCardPane.updateCard(roomData);
          }

          // Show the Battle Royale Game Alert Notification - PreGame
          if (bfCountdown < 60) {
            this.bossFightAlertHandler();
          }
          break;
        case 1: //PreRound
        case 2: //Round
        case 3: //Processing Results
        case 4: //Displaying Results
        case 5: //Finished
          this._bossFightCardPane.setVisible(true);
          this._bossFightCardPane.updateCard(roomData);
          break;
      }
    }
  }

  private battleRoyaleAlertHandler(hasPlayerJoined: boolean) {
    this.scene.pause('HomebaseScene');
    this.scene.pause('HudScene');
    this.scene.launch('HudBattleRoyaleAlertScene', {
      hasPlayerJoined: hasPlayerJoined,
    });
  }

  private bossFightAlertHandler() {
    this.scene.pause('HomebaseScene');
    this.scene.pause('HudScene');
    this.scene.launch('HudBossFightAlertScene');
  }

  private objectivesButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
    this.scene._objectivesButton.setTint(tintColor);
  }

  private objectivesButtonOnOut(this, pointer) {
    this.scene._objectivesButton.clearTint();
  }

  private leaderboardButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
    this.scene._leaderboardButton.setTint(tintColor);
  }

  private helpdeskButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private armyButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private raidHistoryButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private battlePassButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private shopButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private buildButtonOnHover(this, pointer) {
    this.scene.sound.play('hud-button-hover');
  }

  private leaderboardButtonOnOut(this, pointer) {
    this.scene._leaderboardButton.clearTint();
  }

  private objectivesButtonClickHandler(this, pointer) {
    const config = ConfigurationService.getInstance().getConfig();
    this.scene.pause();
    this.scene.pause('HomebaseScene');
    this.scene.launch(config.features.objectives_modal ? 'ObjectivesModalScene' : 'DashboardScene');
  }

  private leaderboardButtonClickHandler(this, pointer) {
    this.scene.pause();
    this.scene.pause('HomebaseScene');
    this.scene.launch('LeaderboardScene');
  }

  private helpdeskButtonClickHandler(this, pointer) {
    this.scene.pause();
    this.scene.pause('HomebaseScene');
    this.scene.launch('HelpdeskModalScene');
  }

  private armyButtonClickHandler(this, pointer) {
    this.scene.get('HomebaseScene').input.enabled = false;
    this.scene.pause('HudScene');
    this.scene.launch('ArmyModalScene', {
      machine: { user_machine_id: 0, machine_id: 0 },
    });
  }

  private raidHistoryButtonClickHandler(this, pointer) {
    this.scene.stop('HomebaseScene');
    this.scene.stop('HudScene');
    this.scene.start('BaseRaidHistoryScene', { tab: 'defense_log' });
  }

  private battlePassButtonClickHandler(this, pointer) {
    var hasPass = this._userData.has_battlepass === 1 ? true : false;
    if (hasPass) {
      this.scene.stop('HomebaseScene');
      this.scene.stop('HudScene');
      this.scene.start('BattlePassScene', {
        userData: this._userData,
      });
    } else {
      // Takes user to purchase scene
      this.scene.stop('HomebaseScene');
      this.scene.stop('HudScene');
      this.scene.start('BattlePassBuyScene' /*, {}*/);
    }
  }

  private stardustButtonClickHandler(this, pointer) {
    this.scene.pause();
    this.scene.pause('HomebaseScene');
    this.scene.launch('ShopModalStardustStationScene');
  }

  applyScale(number) {
    return number * this._scale;
  }

  protected addLevelBar(this) {
    if (this._levelProgressBar) {
      this._levelProgressBar.destroy();
    }

    this._levelProgressBar = new LevelProgressBarComponent(this, this.applyScale(132), this.applyScale(43), this._scale, this._userData);

    if (this._allowProgressBarClicks) {
      this._levelProgressBar
        .setInteractive({ cursor: 'pointer' })
        .on(
          Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
          pointer => {
            this.scene.scene.sound.play('hud-button-hover');
            this._levelProgressBar.setAlpha(progressBarHoverAlpha);
          },
          this,
        )
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
          this._levelProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
          this._levelProgressBar.setAlpha(1);
        })
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
          this._levelProgressBar.setAlpha(progressBarHoverAlpha);
          if (pointer.getDistance() > 16) {
            return;
          }
          const config = ConfigurationService.getInstance().getConfig();

          this.scene.get('HomebaseScene').input.enabled = false;
          this.scene.launch(config.features.profile_modal ? 'ProfileModalScene' : 'UserLevelModalScene', {
            userData: this._userData,
          });
          this.scene.pause();
        });
    }

    this.add.existing(this._levelProgressBar);
  }

  protected addResourcesComponent(userMachines) {
    this._resourcesComponent = new ResourcesComponent(
      this,
      this.sys.canvas.width - this.applyScale(144),
      this.applyScale(174),
      this._userData,
      userMachines,
      this._allowProgressBarClicks,
      this._scale,
    );

    this.add.existing(this._resourcesComponent);
  }

  public updateProgressBars(forceUpdate = true) {
    if (forceUpdate) {
      UserService.getInstance()
        .getUserData(true)
        .then(data => {
          this._userData = data;
          this.updateProgressBars(false);
        });
    } else {
      if (this._resourcesComponent) {
        const userMachines = MachineService.getInstance().getUserMachinesSync();
        this._resourcesComponent.updateProgressBars(userMachines, this._userData);
      }
    }
  }

  private addMissionLaunchButton() {
    this._missionsWidget.rect = this.add
      .image(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(122), 'mission_rect')
      .setScale(this._scale)
      .setScrollFactor(0);
    this._missionsWidget.defaultText = this.add
      .image(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(160), 'mission_default_text')
      .setScale(this._scale)
      .setScrollFactor(0);
    this._missionsWidget.space_elixir_icon = this.add
      .image(this.sys.canvas.width - this.applyScale(194), this.sys.canvas.height - this.applyScale(214), 'space_elixir_icon')
      .setScale(0.8 * this._scale)
      .setScrollFactor(0);
    this._missionsWidget.neon_gold_icon = this.add
      .image(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(214), 'neon_gold_icon')
      .setScale(0.8 * this._scale)
      .setScrollFactor(0);
    this._missionsWidget.stardust_icon = this.add
      .image(this.sys.canvas.width - this.applyScale(120), this.sys.canvas.height - this.applyScale(214), 'stardust_icon')
      .setScale(0.8 * this._scale)
      .setScrollFactor(0);
    this._missionsWidget.button = new Button(
      this.scene.scene,
      this.sys.canvas.width - this.applyScale(159),
      this.sys.canvas.height - this.applyScale(80),
      'mission_btn_enabled',
      false,
    )
      .setScale(this._scale)
      .setScrollFactor(0);

    this.add.existing(this._missionsWidget.button);

    this._missionsWidget.button.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
      this.scene.scene.sound.play('hud-button-hover');
    });

    this._missionsWidget.button.onClick().subscribe(pointer => {
      this.missionsButtonClickHandler(pointer);
    });
  }

  private addLastMinedRewardMissionButton() {
    const background = this.add.rexRoundRectangle(0, 0, this.applyScale(258), this.applyScale(184), this.applyScale(26), 0x350926, 0.9);

    const progressBar = new ProgressBar(this, 0, 0, 1, {
      rtl: false,
      title: {
        text: '',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: 'Done',
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(169),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 100,
        glare: {
          width: this.applyScale(169 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    const button = new Button(this.scene.scene, 0, 0, 'view_mission_btn', false).setScale(this._scale).setScrollFactor(0);

    this.add.existing(button);

    button.onClick().subscribe(() => {
      this.scene.start('MiningResultScene');
      this.scene.stop('HomebaseScene');
      this.scene.stop();
    });

    const label = this.add.bitmapText(0, 0, 'cc_outline', ['Ready to view', 'mining mission reward'], this.applyScale(18), 1);

    this._missionWidget = this.rexUI.add
      .sizer(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(122), {
        orientation: 'y',
        width: this.applyScale(258),
        height: this.applyScale(184),
        space: this.applyScale(20),
      })
      .addBackground(background)
      .add(progressBar, {
        padding: { top: -this.applyScale(27) },
      })
      .addSpace()
      .add(label)
      .addSpace()
      .add(button)
      .layout();
  }

  private addMissionProgressButton(asteroid: Asteroid) {
    const background = this.add.rexRoundRectangle(0, 0, this.applyScale(258), this.applyScale(184), this.applyScale(26), 0x350926, 0.9);

    const startTime = new Date(asteroid.user_mining_started_time * 1000).getTime();
    const endTime = new Date(asteroid.estimated_mine_time_finished * 1000).getTime();

    let nowSeconds = Math.floor(Date.now() / 1000);
    let startSeconds = Math.floor(startTime / 1000);
    let endSeconds = Math.floor(endTime / 1000);
    let remainingSeconds = Math.floor(endSeconds - nowSeconds) + 1;

    const progressBar = new ProgressBar(this, 0, 0, 1, {
      rtl: false,
      title: {
        text: '',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: convertSecondsToCountdown(remainingSeconds),
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(169),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: startSeconds,
        maxValue: endSeconds,
        progressValue: startSeconds + remainingSeconds,
        glare: {
          width: this.applyScale(169 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    let refreshStatus = () => {
      clearInterval(progressBarInterval);

      MiningService.getInstance()
        .getMiningStatus()
        .then(response => {
          const isMiningComplete = !response.length;

          if (isMiningComplete) {
            this._missionWidget.clear(true);

            MiningService.getInstance()
              .getLastMinedReward()
              .then(() => this.create());
          } else {
            const newAsteroid = response[0];

            const newEndTime = new Date(newAsteroid.estimated_mine_time_finished * 1000).getTime();

            remainingSeconds = Math.floor(newEndTime / 1000) - Math.floor(Date.now() / 1000);
            var minimumSeconds = 10;

            progressBarInterval = setInterval(progressBarIntervalFunction, remainingSeconds > 10 ? remainingSeconds * 1000 : minimumSeconds * 1000);
          }
        });
    };

    let progressBarIntervalFunction = () => {
      try {
        if (remainingSeconds <= 0) {
          refreshStatus();
        } else {
          remainingSeconds--;

          remainingSeconds = remainingSeconds < 0 ? 0 : remainingSeconds;

          progressBar.setProgressBarText(convertSecondsToCountdown(remainingSeconds));
          progressBar.setProgressValue(this, startSeconds + remainingSeconds);
        }
      } catch (error) {
        clearInterval(progressBarInterval);
      }
    };

    let progressBarInterval = setInterval(progressBarIntervalFunction, 1000);

    const button = new Button(this.scene.scene, 0, 0, 'view_mission_btn', false).setScale(this._scale).setScrollFactor(0);

    this.add.existing(button);

    button.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
      this.scene.scene.sound.play('hud-button-hover');
    });

    button.onClick().subscribe(() => {
      this.scene.stop('HomebaseScene');
      this.scene.stop();

      MiningService.getInstance()
        .getMiningStatus()
        .then(asteroids => {
          if (asteroids.length) {
            this.scene.start('MiningStatusScene', {
              asteroidId: asteroid.asteroid_id,
            });
          } else {
            MiningService.getInstance()
              .getLastMinedReward()
              .then(() => this.scene.start('MiningResultScene'));
          }
        });
    });

    const label = this.add
      .bitmapText(
        0,
        0,
        'cc_outline',
        ['Currently mining', `a ${asteroidRarityLabelMapper[asteroid.asteroid_rarity_id]} asteroid`],
        this.applyScale(18),
        1,
      )
      .setWordTint(
        asteroidRarityLabelMapper[asteroid.asteroid_rarity_id],
        1,
        false,
        asteroid.asteroid_rarity_id === 1 ? 0x75758f : asteroidRarityColorMapper[asteroid.asteroid_rarity_id],
      );

    this._missionWidget = this.rexUI.add
      .sizer(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(122), {
        orientation: 'y',
        width: this.applyScale(258),
        height: this.applyScale(184),
        space: this.applyScale(20),
      })
      .addBackground(background)
      .add(progressBar, {
        padding: { top: -this.applyScale(27) },
      })
      .addSpace()
      .add(label)
      .addSpace()
      .add(button)
      .layout();
  }

  private addLastBaseRaidRewardMissionButton() {
    const background = this.add.rexRoundRectangle(0, 0, this.applyScale(258), this.applyScale(184), this.applyScale(26), 0x350926, 0.9);

    const progressBar = new ProgressBar(this, 0, 0, 1, {
      rtl: false,
      title: {
        text: '',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: 'Done',
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(169),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: 0,
        maxValue: 100,
        progressValue: 100,
        glare: {
          width: this.applyScale(169 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    const button = new Button(this.scene.scene, 0, 0, 'view_mission_btn', false).setScale(this._scale).setScrollFactor(0);

    this.add.existing(button);

    button.onClick().subscribe(() => {
      const resultData = {
        isAttacker: true,
        neon_gold: this._baseRaidInfo.attacker_possible_loot.neon_gold,
        space_elixir: this._baseRaidInfo.attacker_possible_loot.space_elixir,
        stardust: this._baseRaidInfo.attacker_possible_loot.stardust,
        xp: this._baseRaidInfo.is_win ? this._baseRaidInfo.attacker_win_xp : this._baseRaidInfo.attacker_lose_xp,
        isVictory: this._baseRaidInfo.is_win,
      };
      this.scene.start('BaseRaidResultScene', resultData);
      this.scene.stop('HomebaseScene');
      this.scene.stop();
    });

    const label = this.add.bitmapText(0, 0, 'cc_outline', ['Ready to view', 'base raid results'], this.applyScale(18), 1);

    this._missionWidget = this.rexUI.add
      .sizer(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(122), {
        orientation: 'y',
        width: this.applyScale(258),
        height: this.applyScale(184),
        space: this.applyScale(20),
      })
      .addBackground(background)
      .add(progressBar, {
        padding: { top: -this.applyScale(27) },
      })
      .addSpace()
      .add(label)
      .addSpace()
      .add(button)
      .layout();
  }

  private addBaseRaidProgressButton(raidInfo: any) {
    const background = this.add.rexRoundRectangle(0, 0, this.applyScale(258), this.applyScale(184), this.applyScale(26), 0x350926, 0.9);

    const executionSteps = this._baseRaidInfo.base_raid_execution;
    const startTime = this._baseRaidInfo.attack_start_time;
    const endTime = executionSteps[executionSteps.length - 1].end_time;

    let nowSeconds = Math.floor(Date.now() / 1000);
    let remainingSeconds = Math.floor(endTime - nowSeconds) + 1;

    const progressBar = new ProgressBar(this, 0, 0, 1, {
      rtl: false,
      title: {
        text: '',
      },
      icon: undefined,
      border: {
        width: 1,
        color: 0x383d57,
      },
      progressBar: {
        text: convertSecondsToCountdown(remainingSeconds),
        textAlign: 'center',
        fontStyle: null,
        width: this.applyScale(169),
        height: this.applyScale(22),
        radius: this.applyScale(7),
        color: 0x00a6d3,
        backgroundColor: 0x201425,
        backgroundAlpha: 0.4,
        minValue: startTime,
        maxValue: endTime,
        progressValue: startTime + remainingSeconds,
        glare: {
          width: this.applyScale(169 - 10),
          height: this.applyScale(6),
          radius: this.applyScale(3),
          color: 0xffffff,
          alpha: 0.14,
        },
      },
    });

    let refreshStatus = () => {
      clearInterval(progressBarInterval);

      BaseRaidService.getInstance()
        .viewAttack()
        .then(response => {
          this._baseRaidInfo = response;
          const isRaidComplete = response.completed;

          if (isRaidComplete) {
            this._missionWidget.clear(true);
            this.create();
          } else {
            const newEndTime = response.base_raid_execution[response.base_raid_execution.length - 1].end_time;

            remainingSeconds = Math.floor(newEndTime / 1000) - Math.floor(Date.now() / 1000);
            var minimumSeconds = 10;

            progressBarInterval = setInterval(progressBarIntervalFunction, remainingSeconds > 10 ? remainingSeconds * 1000 : minimumSeconds * 1000);
          }
        });
    };

    let progressBarIntervalFunction = () => {
      try {
        if (remainingSeconds <= 0) {
          refreshStatus();
        } else {
          remainingSeconds--;

          remainingSeconds = remainingSeconds < 0 ? 0 : remainingSeconds;

          progressBar.setProgressBarText(convertSecondsToCountdown(remainingSeconds));
          progressBar.setProgressValue(this, startTime + remainingSeconds);
        }
      } catch (error) {
        clearInterval(progressBarInterval);
      }
    };

    let progressBarInterval = setInterval(progressBarIntervalFunction, 1000);

    const button = new Button(this.scene.scene, 0, 0, 'visit_mission_btn', false).setScale(this._scale).setScrollFactor(0);

    this.add.existing(button);

    button.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
      this.scene.scene.sound.play('hud-button-hover');
    });

    button.onClick().subscribe(() => {
      this.scene.stop('HomebaseScene');
      this.scene.stop();

      BaseRaidService.getInstance()
        .viewAttack()
        .then(attackRes => {
          this.scene.start('BaseRaidDetailsScene', Object.assign({}, attackRes, { is_attacking: true }));
        });
    });

    const label = this.add.bitmapText(
      0,
      0,
      'cc_outline',
      ['Currently Raiding', `${this._baseRaidInfo.defender_user.display_name}'s base.`],
      this.applyScale(18),
      1,
    );

    this._missionWidget = this.rexUI.add
      .sizer(this.sys.canvas.width - this.applyScale(159), this.sys.canvas.height - this.applyScale(122), {
        orientation: 'y',
        width: this.applyScale(258),
        height: this.applyScale(184),
        space: this.applyScale(20),
      })
      .addBackground(background)
      .add(progressBar, {
        padding: { top: -this.applyScale(27) },
      })
      .addSpace()
      .add(label)
      .addSpace()
      .add(button)
      .layout();
  }

  startBossFightScene() {
    this.scene.stop('HomebaseScene');
    this.scene.stop('HudScene');
    this.scene.start('BossFightScene');
  }

  startBattleRoyaleScene() {
    BattleRoyaleServerService.getInstance()
      .updateMetaData()
      .then(() => {
        this.scene.get('HomebaseScene').scene.stop();
        this.scene.get('HudScene').scene.stop();
        this.scene.start('BattleRoyaleScene');
      });
  }

  displayOnboardingNotifications() {
    this._notificationData = NotificationService.getInstance().getNotificationsSync();

    this._notificationData.forEach(note => {
      if (this._userData[`modal${note.number}`] === 1) {
        // User hasn't closed notication yet, display it
        if (!this._notifications) {
          this._notifications = [];
        }

        if (note.number === 3 && this.sys.canvas.width < minCanvasWidth) {
          return;
        }

        const coords = this.getNotificationCoords(note);

        const handPointer = this.add.image(coords.handX, coords.handY, note.pointerImageKey);
        const noteComponent = new HudNotificationComponent(this, coords.noteX, coords.noteY, {
          title: note.title,
          description: note.description,
          number: note.number,
        });
        this.add.existing(noteComponent);

        this._notifications.push({ noteNumber: note.number, pointerComponent: handPointer, noteComponent: noteComponent });
      }
    });
  }

  getNotificationCoords(note: any) {
    switch (note.anchorCorner) {
      case 'top-left':
        return {
          noteX: note.noteX,
          noteY: note.noteY,
          handX: note.handX,
          handY: note.handY,
        };
      case 'bottom-left':
        return {
          noteX: note.noteX,
          noteY: this.sys.canvas.height + note.noteY,
          handX: note.handX,
          handY: this.sys.canvas.height + note.handY,
        };
      case 'top-right':
        return {
          noteX: this.sys.canvas.width + note.noteX,
          noteY: note.noteY,
          handX: this.sys.canvas.width + note.handX,
          handY: note.handY,
        };
      case 'bottom-right':
        return {
          noteX: this.sys.canvas.width + note.noteX,
          noteY: this.sys.canvas.height + note.noteY,
          handX: this.sys.canvas.width + note.handX,
          handY: this.sys.canvas.height + note.handY,
        };
      default:
        return {
          noteX: note.noteX,
          noteY: note.noteY,
          handX: note.handX,
          handY: note.handY,
        };
    }
  }

  closeNotification(notificationNumber: any) {
    const note = this._notifications.find(notePointer => {
      return notePointer.noteNumber === notificationNumber;
    });
    if (note) {
      note.pointerComponent.setAlpha(0);
      note.pointerComponent.destroy();

      note.noteComponent.clear(true);

      this._notifications.filter(item => item.noteNumber !== note.noteNumber);
    }
  }

  private getUnclaimedStakes() {
    const stakingInfo = StakingService.getInstance().getStakingInfoSync();
    return stakingInfo.nfts.filter(nft => {
      return nft.days_held >= minDaysHeldToStake && !nft.staking_reward_info;
    });
  }

  private setStakingAlertVisible() {
    const unclaimedStakes = this.getUnclaimedStakes();
    if (unclaimedStakes.length > 0) {
      this._stakingAlert.setAlpha(1);
    } else {
      this._stakingAlert.setAlpha(0);
    }
  }

  destroy() {
    this._levelProgressBar.destroy();
    this._objectivesButton.destroy();
    this._leaderboardButton.destroy();
    this._squadComponent.destroy();
    this._bossFightCardPane.destroy();
    this._notifications.forEach(note => {
      note.pointerComponent.destroy();
      note.noteComponent.destroy();
    });
    this._notifications = undefined;
  }

  public onTabbedOut() {
    this._bossFightCardPane.stopTimer();
    this._bossFightCardPane.setVisible(false);
    this._battleRoyaleCardPane.stopTimer();
    this._battleRoyaleCardPane.setVisible(false);
  }

  public onTabbedIn() {
    this.launchBattleRoyaleAlerts();
    this.launchBossFightAlerts();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Hud_Assets));
  }
}
