import ApiProxyService from './ApiProxyService';
import util from 'util';
import WalletConnectService from './WalletConnectService';
import SquadService from './SquadService';
import { getHomebaseTraits } from '~/utils/TraitUtils';

const cadetDormCapacities = [
  0, // Level 0
  3, // Level 1
  5, // Level 2
  10, // Level 3
  15, // Level 4
  20, // Level 5
  25, // Level 6
  35, // Level 7
  50, // Level 8
  65, // Level 9
  80, // Level 10
];

const dimXBoosts = [
  0, // Level 0
  5, // Level 1
  10, // Level 2
  15, // Level 3
];

const doublePointMachineKeys = ['neon_gold_mine', 'neon_gold_storage', 'space_elixir_mine', 'space_elixir_storage'];

export default class MachineService {
  private static _instance: MachineService = new MachineService();

  private _machines;
  private _availableMachines;
  private _userMachines;

  constructor() {
    if (MachineService._instance) {
      throw new Error('Error: Instantiation failed: Use MachineService.getInstance() instead of new.');
    }
    MachineService._instance = this;
  }

  public static getInstance(): MachineService {
    return MachineService._instance;
  }

  // All machines
  private setMachines(value): void {
    this._machines = value;
  }

  public getMachines(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._machines && !forceUpdate) {
        return resolve(this._machines);
      } else {
        return resolve(this.fetchMachines());
      }
    });
  }

  public getMachinesSync() {
    return this._machines;
  }

  private fetchMachines() {
    return ApiProxyService.getInstance()
      .get('machines', ['all'], [])
      .then(res => {
        this._machines = res.machines;
        return this._machines;
      });
  }

  public getAvailableMachines(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._availableMachines && !forceUpdate) {
        return resolve(this._availableMachines);
      } else {
        return resolve(this.fetchAvailableMachines());
      }
    });
  }

  public getAvailableMachinesSync() {
    return this._availableMachines;
  }

  private fetchAvailableMachines() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('machines', [wallet, 'all'], [])
      .then(res => {
        this._availableMachines = res.machines;
        this.applyMachineTraits(false);
        return this._availableMachines;
      });
  }

  // User Machines
  public getUserMachines(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._userMachines && !forceUpdate) {
        return resolve(this._userMachines);
      } else {
        return resolve(this.fetchUserMachines());
      }
    });
  }

  public getUserMachinesSync() {
    return this._userMachines;
  }

  private fetchUserMachines() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('machines', [wallet], [])
      .then(res => {
        this._userMachines = this.enrichMachinesData(res.machines);
        this.applyMachineTraits(true);
        return this._userMachines.map(machine => {
          // if (machine.key === 'star_laboratory') {
          //     machine.time_until_inactive = 1000;
          // }
          // machine.level = 10;
          // machine.time_until_next_level = 0;
          // machine.next_level_build_total_time = 0;
          // machine.next_level_storage_neon_gold = 0;
          // machine.next_level_storage_space_elixir = 0;
          // machine.next_level_produce_per_hour_neon_gold = 0;
          // machine.next_level_produce_per_hour_space_elixir = 0;
          // machine.cost_stardust = 0;
          // machine.cost_neon_gold = 0;
          // machine.cost_space_elixir = 0;
          return machine;
        });
      });
  }

  // Purchase Machine
  public purchaseMachine(machine_id: any, timeReductionPercent: number, costReductionPercent: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    const params = {
      decreaseBuildTime: timeReductionPercent,
      decreaseResources: costReductionPercent,
    };
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'purchase', machine_id], params)
      .then(res => {
        return res;
      });
  }

  // Upgrade Machine
  public upgradeMachine(user_machine_id: any, timeReductionPercent: number, costReductionPercent: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    const params = {
      decreaseBuildTime: timeReductionPercent,
      decreaseResources: costReductionPercent,
    };
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'upgrade', user_machine_id], params)
      .then(res => {
        console.log(res);
        return res;
      });
  }

  // Speed Up Upgrade
  public speedUpUpgrade(user_machine_id: any, stardust_amount: any) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'upgrade', user_machine_id], {
        stardust: stardust_amount.toString(),
      })
      .then(res => {
        console.log(res);
        return res;
      });
  }

  // Activate Machine
  public activateMachine(user_machine_id: any) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'activate', user_machine_id], {})
      .then(res => {
        console.log(res);
        return res;
      });
  }

  // Set Position
  public updateMachinePosition(user_machine_id: any, x: number, y: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'locations'], {
        locations: [{ user_machine_id: user_machine_id, x: x, y: y }],
      })
      .then(res => {
        console.log(res);
        return res;
      });
  }

  // Applies Machine Trait Values
  private applyMachineTraits(isUpgrade: boolean) {
    var squadTraits = SquadService.getInstance().getTraitsSync();
    var machineMultiplier = MachineService.getInstance().getHomebaseMachineMultiplier();

    if (!squadTraits) {
      squadTraits = [];
    }

    var homebaseTraits = getHomebaseTraits(squadTraits, machineMultiplier);

    if (isUpgrade) {
      // Applies Traits to Upgrade Machine costs
      this._userMachines.forEach(machine => {
        machine.cost_neon_gold = this.calculateTraitReduction(machine.cost_neon_gold, homebaseTraits.reduceCost);
        machine.cost_space_elixir = this.calculateTraitReduction(machine.cost_space_elixir, homebaseTraits.reduceCost);
        machine.cost_stardust = this.calculateTraitReduction(machine.cost_stardust, homebaseTraits.reduceCost);
        machine.next_level_build_total_time = this.calculateTraitReduction(machine.next_level_build_total_time, homebaseTraits.upgradeBuildTime);
      });
    } else {
      // Applies Traits to New Machine Costs
      this._availableMachines.forEach(machine => {
        machine.cost_neon_gold = this.calculateTraitReduction(machine.cost_neon_gold, homebaseTraits.reduceCost);
        machine.cost_space_elixir = this.calculateTraitReduction(machine.cost_space_elixir, homebaseTraits.reduceCost);
        machine.cost_stardust = this.calculateTraitReduction(machine.cost_stardust, homebaseTraits.reduceCost);
        machine.next_level_build_total_time = this.calculateTraitReduction(machine.next_level_build_total_time, homebaseTraits.machineBuildTime);
      });
    }
  }

  private calculateTraitReduction(value: number, reductionValue: number) {
    var reductionTotal = (value / 100) * reductionValue * 100;
    return value - reductionTotal;
  }

  // Claim machine resources
  public claimResources(user_machine_id: any) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('machines', [wallet, 'claim', user_machine_id], {})
      .then(res => {
        return res;
      });
  }

  public getStorageCapacities() {
    var spaceElixir = 0;
    this._userMachines.forEach(mach => {
      if (mach['storage_space_elixir'] && mach['storage_space_elixir'] > 0) {
        spaceElixir = spaceElixir + mach['storage_space_elixir'];
      }
    });

    var neonGold = 0;
    this._userMachines.forEach(mach => {
      if (mach['storage_neon_gold'] && mach['storage_neon_gold'] > 0) {
        neonGold = neonGold + mach['storage_neon_gold'];
      }
    });

    return { spaceElixir: spaceElixir, neonGold: neonGold };
  }

  private enrichMachinesData(machinesData: any) {
    var tempData = machinesData.map(mach => {
      return Object.assign({}, mach, {
        numberAvailable: this.getNumberOfMachinesAvailable(machinesData, mach),
        maxNumber: this.getMaxNumberOfMachines(mach),
      });
    });

    return tempData;
  }

  private getNumberOfMachinesAvailable(machinesData: any, shopMachine: any) {
    var max = this.getMaxNumberOfMachines(shopMachine);

    var totalUserMachines = 0;
    machinesData
      .filter(machine => machine.key === shopMachine.key)
      .forEach(mach => {
        if (mach.level > 10 && doublePointMachineKeys.includes(mach.key)) {
          totalUserMachines += 2;
        } else {
          totalUserMachines++;
        }
      });

    return max - totalUserMachines < 0 ? 0 : max - totalUserMachines;
  }

  private getMaxNumberOfMachines(shopMachine: any) {
    var max = shopMachine.max_allowed_from_mf_level;
    return max && max > 0 ? max : 1;
  }

  public getKaijuMachineMultiplier() {
    var multiplier = 0;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1029) {
        switch (mach.level) {
          case 1:
            multiplier = 0.15;
            break;
          case 2:
            multiplier = 0.25;
            break;
          case 3:
            multiplier = 0.5;
            break;
        }
      }
    });
    return multiplier;
  }

  public getHomebaseMachineMultiplier() {
    var multiplier = 0;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1039) {
        switch (mach.level) {
          case 1:
            multiplier = 0.025;
            break;
          case 2:
            multiplier = 0.05;
            break;
          case 3:
            multiplier = 0.075;
            break;
          case 4:
            multiplier = 0.1;
            break;
        }
      }
    });
    return multiplier;
  }

  public ownsBrawlerCage() {
    var hasCage = false;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1021) {
        hasCage = true;
      }
    });
    return hasCage;
  }

  public ownsGLFHersArcade() {
    var hasArcade = false;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1060) {
        hasArcade = true;
      }
    });
    return hasArcade;
  }

  public ownsPNWoodenGalleon() {
    var hasGalleon = false;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1061) {
        hasGalleon = true;
      }
    });
    return hasGalleon;
  }

  public ownsPNGoldenGalleon() {
    var hasGalleon = false;
    this._userMachines.forEach(mach => {
      if (mach.machine_id === 1062) {
        hasGalleon = true;
      }
    });
    return hasGalleon;
  }

  public getUserMachineLevel(machineId) {
    const machine = this._userMachines.find(mach => mach.machine_id === machineId);
    if (!machine) return 0;
    return machine.level;
  }

  public getUserMachineFromMachineId(machineId) {
    return this._userMachines.find(userMach => userMach.machine_id === machineId);
  }

  public getCadetDormsCapacity() {
    const cadetDorms = this._userMachines.filter(mach => mach.machine_id === 1034); // 1034 = Cadet Dorms
    if (!cadetDorms || cadetDorms.length === 0) {
      return 0;
    }

    var capacity = 0;
    cadetDorms.forEach(dorm => {
      capacity += cadetDormCapacities[dorm.level];
    });

    return capacity;
  }

  public getCadetDormCapacity(level: number) {
    return cadetDormCapacities[level];
  }

  public getDimensionXBoost() {
    const dimX = this._userMachines.find(mach => mach.machine_id === 1046); // 1034 = Cadet Dorms
    if (!dimX || dimX.length === 0) {
      return 0;
    }

    return dimXBoosts[dimX.level] || 0;
  }
}
