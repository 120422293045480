import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import LevelProgressBarComponent from '~/components/hud/levelProgressBarComponent';
import ConfigurationService from '~/services/ConfigurationService';

const modalWidth = 494;
// Battle Pass Model Height
//const modalHeight = 469;
const modalHeight = 366;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const goldProgressBarColor = 0xdea827;
const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.14;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const xpBarOffset = 40;
const borderWidth = 1;
const borderColor = 0x383d57;
const borderAlpha = 1;
const backgroundColor = 0x201425;
const backgroundAlpha = 0.4;
const radius = 11;

const progressBarHeight = 43;
const progressBarWidth = 240;

const resources = {
  space_elixir: 'space_elixir',
  neon_gold: 'neon_gold',
  stardust: 'stardust',
};

export default class UserLevelModalScene extends Phaser.Scene {
  private _userData;
  private _scale;

  init(data: any) {
    this._userData = data.userData;
  }

  constructor() {
    super({
      key: 'UserLevelModalScene',
    });
  }

  preload() {}

  create() {
    this.input.setDefaultCursor('default');
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    const badges = ConfigurationService.getInstance().getConfig().userBadges;
    const levelBadgeImageKey = this.getBadgeImageKey(this._userData, badges);

    // Add Icons
    this.add.image(modalBg.getTopCenter().x, modalBg.getTopLeft().y, levelBadgeImageKey).setScale(this._scale);

    // Add sizer for content
    var sizer = this.rexUI.add.overlapSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // User level progress bar
    const userLevelBar = new LevelProgressBarComponent(this, 0, 0, this._scale, this._userData, 1);
    userLevelBar.setIcon('level_icon');

    sizer.add(userLevelBar, {
      align: 'center-top',
      offsetX: this.applyScale(-12),
      offsetY: this.applyScale(82),
      expand: false,
      key: 'user_level_bar',
    });

    // 'Total XP Earned' label
    sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Total XP Earned', this.applyScale(18), 1), {
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(164),
      expand: false,
      key: 'xp_earned_label',
    });

    // XP Progress Bar
    const xpBar = new ProgressBar(this, 0, 0, this._scale, {
      rtl: false,
      title: {
        text: this._userData.xp,
        fontSize: 16,
        offset: 2,
      },
      icon: undefined,
      border: {
        width: borderWidth,
        color: borderColor,
        alpha: borderAlpha,
      },
      progressBar: {
        text: '',
        fontStyle: null,
        width: progressBarWidth - xpBarOffset,
        height: progressBarHeight,
        radius: radius,
        color: goldProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 0,
        maxValue: 1,
        progressValue: 0,
        glare: {
          height: glareHeight,
          width: progressBarWidth - xpBarOffset - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
          offset: -15,
        },
      },
    });
    this.add.existing(xpBar);
    xpBar.setProgressBarText('');

    sizer.add(xpBar, {
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(194),
      expand: false,
      key: 'xp_progress_bar',
    });

    // 'Total Battle Pass XP Earned' label
    // sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Battle Pass XP', this.applyScale(18), 1), {
    //   align: 'center-top',
    //   offsetX: 0,
    //   offsetY: this.applyScale(284),
    //   expand: false,
    //   key: 'bp_ xp_earned_label',
    // });

    // XP Progress Bar
    // const bpXPBar = new ProgressBar(this, 0, 0, this._scale, {
    //   rtl: false,
    //   title: {
    //     text: this._userData.battlepass_xp,
    //     fontSize: 16,
    //     offset: 2,
    //   },
    //   icon: undefined,
    //   border: {
    //     width: borderWidth,
    //     color: borderColor,
    //     alpha: borderAlpha,
    //   },
    //   progressBar: {
    //     text: '',
    //     fontStyle: null,
    //     width: progressBarWidth - xpBarOffset,
    //     height: progressBarHeight,
    //     radius: radius,
    //     color: goldProgressBarColor,
    //     alpha: progressBarAlpha,
    //     backgroundColor: backgroundColor,
    //     backgroundAlpha: backgroundAlpha,
    //     minValue: 0,
    //     maxValue: 1,
    //     progressValue: 0,
    //     glare: {
    //       height: glareHeight,
    //       width: progressBarWidth - xpBarOffset - glareOffset,
    //       radius: glareRadius,
    //       color: glareColor,
    //       alpha: glareAlpha,
    //       offset: -15,
    //     },
    //   },
    // });
    // this.add.existing(bpXPBar);
    // bpXPBar.setProgressBarText('');

    // sizer.add(bpXPBar, {
    //   align: 'center-top',
    //   offsetX: 0,
    //   offsetY: this.applyScale(315),
    //   expand: false,
    //   key: 'bp_xp_progress_bar',
    // });

    sizer.layout();

    // Add Cancel Button
    const closeButton = new Button(this, modalBg.getBottomCenter().x, modalBg.getBottomLeft().y, 'modal_close_button', false).setScale(this._scale);
    closeButton.onClick().subscribe(pointer => {
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
      this.scene.stop();
    });
    this.add.existing(closeButton);

    sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this.scene.stop();
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
    });
  }

  private getBadgeImageKey(userData: any, userBadges: any) {
    var badge = userBadges.find(ub => {
      if (ub.maxLevel === null) {
        return userData.level >= ub.minLevel;
      } else {
        return userData.level >= ub.minLevel && userData.level <= ub.maxLevel;
      }
    });
    return badge.badgeImageKey;
  }

  applyScale(length) {
    return length * this._scale;
  }
}
