import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import BattlePassService from '~/services/BattlePassService';
import ScaleService from '~/services/ScaleService';

// Model Details
const modalWidth = 494;
const modalHeight = 494;
const modalRadius = 50;
const modalColor = 0x7d57e8;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

// Image Background Details
const imageWidth = 116;
const imageHeight = 146;
const imageRadius = 7;
const imageColor = 0xb48dc7;
const imageBorderAlpha = 0.4;
const imageBorderColor = 0xffffff;
const imageBorderWidth = 1;

export default class BattlePassItemClaimScene extends Phaser.Scene {
  private _scale;
  private _rewardData;
  private _claimed;
  private _rewardLocked;
  private _battlepassXP;

  // UI
  private _sizer;
  private _claimButton;
  private _itemImage;
  private _rewardTitle;
  private _rewardMessage;
  private _rewardName;

  init(data: any) {
    this._rewardData = data.rewardData;
    this._claimed = (this._rewardData.claimed as string) == '0' ? false : true;
    this._rewardLocked = this._rewardData.level > data.level;
    this._battlepassXP = data.battlepassXP;
  }

  constructor() {
    super({
      key: 'BattlePassItemClaimScene',
    });
  }

  preload() {}

  create() {
    this.input.setDefaultCursor('default');
    this.createComponent();

    // Update UI based on Reward Locked, Claimed, Unclaimed
    this.updateUIBasedOnRewardState();
  }

  createComponent() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 60);

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
    );
    modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // Add sizer for content
    this._sizer = this.rexUI.add.overlapSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    var imageSizer = this.createImageComponent();
    this._sizer.add(imageSizer, {
      key: 'image-sizer',
      align: 'left-top',
      offsetX: this.applyScale(189),
      offsetY: this.applyScale(44),
      expand: false,
    });

    // Text Section
    this._rewardTitle = this.add.bitmapText(0, 0, 'cc_outline', 'Reward unlocks in:', this.applyScale(22), 1).setAlpha(0.8);
    this._sizer.add(this.add.existing(this._rewardTitle), {
      key: 'reward-title',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(238),
      expand: false,
    });

    this._rewardMessage = this.add.bitmapText(0, 0, 'cc_outline', '0 xp', this.applyScale(22), 1).setAlpha(0.8);
    this._sizer.add(this.add.existing(this._rewardMessage), {
      key: 'reward-message',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(265),
      expand: false,
    });

    this._rewardName = this.add.bitmapText(0, 0, 'cc_outline', this._rewardData.rewardDescription as string, this.applyScale(22), 0);
    this._sizer.add(this.add.existing(this._rewardName), {
      key: 'reward-name',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(308),
      expand: false,
    });

    // Button
    this._claimButton = new Button(this, 0, 0, 'battlepass_claim_button_inactive', false).setScale(this._scale);
    this._claimButton.disableInteractive();
    this._claimButton.onClick().subscribe(pointer => {
      BattlePassService.getInstance()
        .claimBattlePassRewards(1, this._rewardData.level)
        .then(res => {
          if (res.success) {
            // if loot pack reward type
            if (res.is_loot_pack === 1) {
              // trigger the loot pack opening scene.
              this.scene.stop('BattlePassScene');
              this.scene.start('PackOpeningScene', { packType: this._rewardData.reward_type, loot_pack_info: res.loot_pack_info });
            } else {
              // Trigger Successful claim scene
              this.scene.start('BattlePassClaimResultScene', { claimResult: res, reward: this._rewardData });
            }
          }
        });
    });

    this._sizer.add(this.add.existing(this._claimButton), {
      key: 'claim-button',
      align: 'left-top',
      offsetX: this.applyScale(108),
      offsetY: this.applyScale(398),
      expand: false,
    });

    this._sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this.scene.stop();
      this.scene.get('BattlePassScene').input.enabled = true;
      this.scene.resume('BattlePassScene');
    });

    this._sizer.layout();
  }

  createImageComponent() {
    var imageSizer = this.rexUI.add.overlapSizer({
      x: 0,
      y: 0,
      width: this.applyScale(imageWidth),
      height: this.applyScale(imageHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    const imageBackground = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(imageWidth), // width
      this.applyScale(imageHeight), // height
      this.applyScale(imageRadius), // radius
      imageColor, // fillColor
    );
    imageBackground.setStrokeStyle(imageBorderWidth, imageBorderColor, imageBorderAlpha);

    imageSizer.add(imageBackground, {
      key: 'image-bg',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._itemImage = this.add.image(0, 0, this._rewardData.imageKey).setScale(this._scale);

    imageSizer.add(this._itemImage, {
      key: 'bp-image',
      align: 'center',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    return imageSizer;
  }

  updateUIBasedOnRewardState() {
    // Reward has been claimed by user
    if (this._claimed) {
      this._claimButton.disableInteractive();
      this._claimButton.setTexture('battlepass_claimed_button');
      this.recreateTextUI('Congratulations!', 'You have earned:');
    }
    // Reward is locked
    if (this._rewardLocked) {
      this._claimButton.disableInteractive();
      this._claimButton.setTexture('battlepass_claim_button_inactive');
      this.recreateTextUI('Reward unlocks in:', this.calculateXPTillUnlock(this._rewardData.level, this._battlepassXP));
    }

    // Reward is Unlocked and has yet to be claimed
    if (!this._claimed && !this._rewardLocked) {
      this._claimButton.setInteractive();
      this._claimButton.setTexture('battlepass_claim_button_active');
      this.recreateTextUI('Congratulations!', 'You have earned:');
    }

    this._sizer.layout();
  }

  recreateTextUI(rewardTitle: string, rewardMessage: string) {
    // Reward Title
    if (this._rewardTitle) {
      this._sizer.remove(this._rewardTitle, true); //destroys object
    }

    this._rewardTitle = this.add.bitmapText(0, 0, 'cc_outline', rewardTitle, this.applyScale(22), 1).setAlpha(0.8);
    this._sizer.add(this.add.existing(this._rewardTitle), {
      key: 'reward-title',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(238),
      expand: false,
    });

    // Reward Message
    if (this._rewardMessage) {
      this._sizer.remove(this._rewardMessage, true); //destroys object
    }

    this._rewardMessage = this.add.bitmapText(0, 0, 'cc_outline', rewardMessage, this.applyScale(22), 1).setAlpha(0.8);
    this._sizer.add(this.add.existing(this._rewardMessage), {
      key: 'reward-message',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(265),
      expand: false,
    });
  }

  calculateXPTillUnlock(level: number, xpTotal: number) {
    var xpToNextLevel = (level - 1) * 3000 - xpTotal;
    return xpToNextLevel.toLocaleString('en-us') + ' XP';
  }

  applyScale(length) {
    return length * this._scale;
  }
}
