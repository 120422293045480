import Phaser from 'phaser';
import _ from 'lodash';
import HomebaseMachine from '~/components/machines/homebaseMachine';
import util from 'util';
import MachineService from '~/services/MachineService';
import BlobService from '~/services/BlobService';
import ConfigurationService from '~/services/ConfigurationService';
import BaseRaidService from '~/services/BaseRaidService';
import { UnloadImages } from '~/utils/AssetManager';
import { Homebase_Assets } from '~/utils/AssetLoader';

const cellWidth = 64;
const halfCellWidth = 32;
const starLabMachineId = 1007;
const starLabMultipliers = [
  0, // Level 0
  1.5, // Level 1
  3, // Level 2
  4.5, // Level 3
  6, // Level 4
  7.5, // Level 5
  10, // Level 6
];

export default class HomebaseScene extends Phaser.Scene {
  private sizer;
  private parent;
  protected _map;
  protected _tileset;
  protected _baseLayer;
  protected _blobLayer;
  private _objectLayer;
  private _isDragging;
  private _dragOverCell;
  private _dragStartCell;
  public sceneRunning = null;
  protected gameScene;
  protected _userMachines;
  protected _machineObjects;
  private _machineGroup;
  private _blobRoomDoor;
  private _exitBlobRoomDoor;
  private _zoomMin;
  private _zoomMax;
  private _blob;
  private _inBlobRoom;
  protected _machineConfigs;

  constructor(key) {
    if (!key) key = 'HomebaseScene';

    super(key);
  }

  preload() {}

  create() {
    this.createComponent();
  }

  createComponent() {
    this.input.mouse.disableContextMenu();

    // Set Background
    document.body.style.backgroundImage = 'none';

    this.events.on('shutdown', () => {
      // Set Background
      //document.body.style.backgroundImage = "url('../assets/sw_bg.png')";
      document.body.style.backgroundRepeat = 'repeat';
      document.body.style.backgroundSize = 'auto';
    });

    this._zoomMin = 0.4;
    this._zoomMax = 1.6;

    this._inBlobRoom = false;

    this._map = this.make.tilemap({ key: 'homebase_map' });
    this._tileset = this._map.addTilesetImage('homebase_tileset_64', 'tiles', 64, 64, 1, 2);
    this._blobLayer = this._map.createLayer('Blob Layer', this._tileset);
    this._blobLayer.setAlpha(0.5);
    this._baseLayer = this._map.createLayer('Tile Layer 1', this._tileset);

    this.addBlobRoomDoor();

    this._userMachines = MachineService.getInstance().getUserMachinesSync();
    this._machineObjects = [];
    this._machineGroup = this.physics.add.group();

    this._machineConfigs = ConfigurationService.getInstance().getConfig().machines;

    this.createMachines();

    this.cameras.main.setBounds(-200, 440, this._map.widthInPixels + 400, this._map.heightInPixels - 200);

    // Handle keyboard arrows
    this.cursors = this.input.keyboard.createCursorKeys();

    // Handle homebase drag
    this.input.on(
      'pointermove',
      function (p) {
        if (!p.isDown || this._isDragging) return;

        this.cameras.main.scrollX -= (p.x - p.prevPosition.x) / this.cameras.main.zoom;
        this.cameras.main.scrollY -= (p.y - p.prevPosition.y) / this.cameras.main.zoom;
      },
      this,
    );

    // Handle machine drag
    this.input.on(
      'dragstart',
      (pointer, gameObject, dragX, dragY) => {
        this._isDragging = true;
        const widthInTiles = this.getWidthInTiles(gameObject.getMachine());
        const heightInTiles = this.getHeightInTiles(gameObject.getMachine());

        this._dragStartCell = this._map.getTileAtWorldXY(
          gameObject.getBottomCenter().x - halfCellWidth * (widthInTiles - 1),
          gameObject.getBottomCenter().y - halfCellWidth,
        );

        for (var i = 0; i < widthInTiles; i++) {
          for (var h = 0; h < heightInTiles; h++) {
            var coveredTile = this._map.getTileAt(this._dragStartCell.x + i, this._dragStartCell.y - h);
            if (coveredTile && coveredTile.properties.buildable) {
              coveredTile.properties.isOccupied = false;
            }
          }
        }
      },
      this,
    );

    this.input.on(
      'drag',
      (pointer, gameObject, dragX, dragY) => {
        gameObject.x = dragX;
        gameObject.y = dragY;

        const widthInTiles = this.getWidthInTiles(gameObject.getMachine());
        const heightInTiles = this.getHeightInTiles(gameObject.getMachine());

        const tile = this._map.getTileAtWorldXY(
          gameObject.getBottomCenter().x - halfCellWidth * (widthInTiles - 1),
          gameObject.getBottomCenter().y - halfCellWidth,
        );

        if (tile) {
          gameObject.setDepth(tile.y);
        }

        this._dragOverCell = this._dragOverCell || tile;

        if (!tile || !this._dragOverCell || (this._dragOverCell.x === tile.x && this._dragOverCell.y === tile.y)) {
          return;
        }

        if (this._dragOverCell.properties.buildable) {
          this._dragOverCell.index = 31;

          // Check for all tiles covered
          for (var i = 0; i < widthInTiles; i++) {
            for (var h = 0; h < heightInTiles; h++) {
              var coveredTile = this._map.getTileAt(this._dragOverCell.x + i, this._dragOverCell.y - h);
              if (coveredTile && coveredTile.properties.buildable) {
                coveredTile.index = 31;
              }
            }
          }
        }

        if (tile.properties.buildable && !this.isTileOccupied(tile, gameObject.getMachine())) {
          // Check for extra tiles for machines that are more than 1 tile wide
          var allTilesCovered: any = [];
          for (var i = 0; i < widthInTiles; i++) {
            for (var h = 0; h < heightInTiles; h++) {
              var coveredTile = this._map.getTileAt(tile.x + i, tile.y - h);
              allTilesCovered.push(coveredTile);
            }
          }
          var allBuildable = true;
          allTilesCovered.forEach(coveredTile => {
            if (!coveredTile.properties.buildable || this.isTileOccupied(coveredTile, gameObject.getMachine())) {
              allBuildable = false;
            }
          });

          // All tiles are buildable
          if (allBuildable) {
            tile.index = 27;
            this._dragOverCell = tile;

            allTilesCovered.forEach(coveredTile => {
              coveredTile.index = 27;
            });
          }
        }
      },
      this,
    );

    this.input.on(
      'dragend',
      (pointer, gameObject, dragX, dragY) => {
        this._isDragging = false;
        const widthInTiles = this.getWidthInTiles(gameObject.getMachine());
        const heightInTiles = this.getHeightInTiles(gameObject.getMachine());

        const endTile = this._map.getTileAtWorldXY(
          gameObject.getBottomCenter().x - halfCellWidth * (widthInTiles - 1),
          gameObject.getBottomCenter().y - halfCellWidth,
        );
        var coveredTiles: any = [];

        var allBuildable = true;

        if (endTile) {
          for (var i = 0; i < widthInTiles; i++) {
            for (var h = 0; h < heightInTiles; h++) {
              var coveredTile = this._map.getTileAt(endTile.x + i, endTile.y - h);
              if (coveredTile) {
                coveredTiles.push(coveredTile);
                if (!coveredTile.properties.buildable || this.isTileOccupied(coveredTile, gameObject.getMachine())) {
                  allBuildable = false;
                }
              }
            }
          }
        }

        if (endTile && endTile.properties.buildable && allBuildable) {
          endTile.index = 31;
          endTile.properties.isOccupied = true;

          coveredTiles.forEach(coveredTile => {
            if (coveredTile && coveredTile.properties.buildable) {
              coveredTile.index = 31;
              coveredTile.properties.isOccupied = true;
            }
          });

          gameObject.x = cellWidth * endTile.x + (cellWidth * widthInTiles) / 2;
          gameObject.setCell(endTile.x, endTile.y);
          gameObject.alignBottom(cellWidth * endTile.y + cellWidth);
          gameObject.setDepth(endTile.y);

          const machine = gameObject.getMachine();

          MachineService.getInstance().updateMachinePosition(machine.user_machine_id, endTile.x, endTile.y);

          let userMachine = this._userMachines.find(um => um.user_machine_id === machine.user_machine_id);
          if (userMachine) {
            userMachine.x = endTile.x;
            userMachine.y = endTile.y;
          }
        } else {
          gameObject.x = cellWidth * this._dragStartCell.x + (cellWidth * widthInTiles) / 2;
          gameObject.alignBottom(cellWidth * this._dragStartCell.y + cellWidth);

          for (var i = 0; i < widthInTiles; i++) {
            for (var h = 0; h < heightInTiles; h++) {
              var coveredTile = this._map.getTileAt(this._dragStartCell.x + i, this._dragStartCell.y - h);
              if (coveredTile && coveredTile.properties.buildable) {
                coveredTile.properties.isOccupied = true;
              }
            }
          }

          this._dragStartCell = undefined;
        }
      },
      this,
    );

    // Handle scroll/zoom
    this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
      if (deltaY > 0) {
        var newZoom = this.cameras.main.zoom - 0.1;
        if (newZoom > this._zoomMin) {
          this.cameras.main.setZoom(newZoom);
        }
      }

      if (deltaY < 0) {
        var newZoom = this.cameras.main.zoom + 0.1;
        if (newZoom < this._zoomMax) {
          this.cameras.main.setZoom(newZoom);
        }
      }
    });

    // Handles homebase resume event
    this.events.on('resume', (scene, data) => {
      this.updateMachines(true);
    });

    // Display HUD
    BaseRaidService.getInstance()
      .viewAttack()
      .then(res => {
        this.launchScene('HudScene', undefined);
      });
  }

  update() {
    if (this.cursors.up.isDown) {
      this.cameras.main.scrollY -= 10 / this.cameras.main.zoom;
    } else if (this.cursors.down.isDown) {
      this.cameras.main.scrollY += 10 / this.cameras.main.zoom;
    }

    if (this.cursors.left.isDown) {
      this.cameras.main.scrollX -= 10 / this.cameras.main.zoom;
    } else if (this.cursors.right.isDown) {
      this.cameras.main.scrollX += 10 / this.cameras.main.zoom;
    }
  }

  protected getWidthInTiles(machine: any) {
    var machineConfig = this._machineConfigs.find(conf => conf.key === machine.key && conf.level === machine.level);

    if (!machineConfig) {
      var machinesOfSameType = this._machineConfigs.filter(conf => conf.key === machine.key);
      if (machinesOfSameType.length > 0) {
        machineConfig = machine.level === 0 ? machinesOfSameType[0] : machinesOfSameType[machinesOfSameType.length - 1];
      }
    }

    return machineConfig ? machineConfig.baseCellWidth : 1;
  }

  protected getHeightInTiles(machine: any) {
    var machineConfig = this._machineConfigs.find(conf => conf.key === machine.key && conf.level === machine.level);

    if (!machineConfig) return 1;

    return machineConfig.baseCellHeight;
  }

  addMachine(machine: any, cellX: number, cellY: number, buildProgressMultiplier: number, isNew?: boolean) {
    if (cellX === 0 || cellY === 0) {
      var openTile = this.getBuildableTile();
      cellX = openTile.x;
      cellY = openTile.y;
      machine.x = cellX;
      machine.y = cellY;
      openTile.properties.isOccupied = true;
      MachineService.getInstance().updateMachinePosition(machine.user_machine_id, cellX, cellY);
    }
    const widthInTiles = this.getWidthInTiles(machine);

    var newMachine = new HomebaseMachine(this, cellX, cellY, machine, buildProgressMultiplier, widthInTiles);
    this.add.existing(newMachine);
    newMachine.setDepth(cellY);

    this._machineObjects.push(newMachine);
    this._machineGroup.add(newMachine);

    // newMachine.x = cellWidth * cellX + (cellWidth * widthInTiles / 2);
    // newMachine.y = cellWidth * cellY + (cellWidth);

    if (isNew) {
      this._userMachines.push(machine);
      this.scene.get('HudScene').updateProgressBars(true);
    }
  }

  createMachines(force = true) {
    if (!this._machineConfigs) {
      this._machineConfigs = ConfigurationService.getInstance().getConfig().machines;
    }

    MachineService.getInstance()
      .getUserMachines(force)
      .then(userMachs => {
        if (!userMachs || userMachs.length === 0) {
          return;
        }
        // Hack for base raids
        if (!force && this._userMachines && this._userMachines.length > 0) {
          userMachs = this._userMachines;
        }
        this._userMachines = userMachs.sort((a, b) => {
          if (a.y < b.y) {
            return -1;
          }
          if (a.y > b.y) {
            return 1;
          }
          return 0;
        });
        this.setOccupiedCells(true);

        this.clearMachines();

        // Get build progress multiplier from star lab
        const starLab = this._userMachines.find(mach => {
          return mach.machine_id === starLabMachineId;
        });
        let buildProgressMultiplier = starLab && parseInt(starLab.time_until_inactive) > 0 ? starLabMultipliers[parseInt(starLab.level)] : 1;

        // Add image keys to machine data, add new machine objects to UI and object list
        this._userMachines.forEach(mach => {
          mach.imageKey = `machines_${mach.key}`;
          this.addMachine(mach, mach.x, mach.y, buildProgressMultiplier);
        });

        this.updateMachines(true);
      });
  }

  updateMachines(force = true) {
    if (!this._machineObjects || this._machineObjects.length === 0) {
      this.createMachines(true);
      return;
    }
    MachineService.getInstance()
      .getUserMachines(force)
      .then(userMachs => {
        // Hack for base raids
        if (!force && this._userMachines && this._userMachines.length > 0) {
          userMachs = this._userMachines;
        }
        this.setOccupiedCells(false);

        // Get build progress multiplier from star lab
        const starLab = userMachs.find(mach => {
          return mach.machine_id === starLabMachineId && parseInt(mach.time_until_inactive) > 0;
        });
        let buildProgressMultiplier = starLab && parseInt(starLab.time_until_inactive) > 0 ? starLabMultipliers[parseInt(starLab.level)] : 1;

        // Check for changes in machine data and apply changes, add new machines
        userMachs.forEach(mach => {
          mach.imageKey = `machines_${mach.key}`;
          var existingMachine = this._userMachines.find(userMachine => userMachine.user_machine_id === mach.user_machine_id);

          if (!existingMachine) {
            // Must be a new machine, add it to the tilemap and this._userMachines
            this.addMachine(mach, mach.x, mach.y, buildProgressMultiplier, true);
          } else {
            // Machine exists

            // Update machine object properties with latest data
            Object.assign(existingMachine, mach);

            // Update machine UI component
            var homebaseMachine = this._machineObjects.find(mo => mo.getMachine().user_machine_id === mach.user_machine_id);
            if (homebaseMachine) {
              homebaseMachine.handleUpdate(mach, buildProgressMultiplier, this._isDragging);
            }
          }
        });

        // Check for removed machines
        this._userMachines
          .filter(um => {
            return userMachs.find(userMach => userMach.user_machine_id === um.user_machine_id) === undefined;
          })
          .forEach(ghostMachine => this.removeMachine(ghostMachine.user_machine_id));

        this.setOccupiedCells(true);

        // Update resource totals
        this.scene.get('HudScene').updateProgressBars(true);
      });
  }

  removeMachine(userMachineId) {
    // Find old machine object
    var oldMach = this._machineObjects.find(machObj => {
      return machObj.getMachine().user_machine_id === userMachineId;
    });

    if (oldMach) {
      var index = this._machineObjects.indexOf(oldMach);

      // Clear and destroy old machine
      oldMach.setAlpha(0);
      oldMach.destroy();

      // Remove old machine remnant from machines list
      this._machineObjects.splice(index, 1);
    }

    _.remove(this._userMachines, um => um.user_machine_id === userMachineId);
  }

  clearMachines() {
    this.setOccupiedCells(false);
    if (this._machineGroup) {
      this._machineGroup.clear(true);
    }

    if (this._machineObjects) {
      // Destroy old machines
      this._machineObjects.forEach(machObj => {
        machObj.setAlpha(0);
        machObj.destroy();
      });

      // Remove old machines from list
      while (this._machineObjects.length > 0) {
        this._machineObjects.pop();
      }
    }
  }

  addBlobRoomDoor() {
    var aniConfig = {
      key: 'blob_room_door_animation',
      frames: this.anims.generateFrameNumbers('blob_room_door', {
        start: 0,
        end: 22,
        first: 0,
      }),
      numFrames: 23,
      frameRate: 10,
      frameWidth: 168,
      frameHeight: 64,
      repeat: 0,
      repeatDelay: 0,
    };

    this.anims.create(aniConfig);

    var x = cellWidth * 24;
    var y = cellWidth * 10;

    var postFxPlugin = this.plugins.get('rexGlowFilterPipeline');

    this._blobRoomDoor = this.physics.add.sprite(x, y, 'blob_room_door').setOrigin(0.5, 0).setInteractive({ cursor: 'pointer' });

    var pipeline = postFxPlugin.add(this._blobRoomDoor);
    this._blobRoomDoor.glowTask = this._blobRoomDoor.scene.tweens.add({
      targets: pipeline,
      intensity: 0.011,
      ease: 'Linear',
      duration: 1200,
      repeat: -1,
      yoyo: true,
    });

    this._blobRoomDoor
      .on(
        Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
        pointer => {
          // Remove postfx pipeline
          postFxPlugin.remove(this._blobRoomDoor);
          this._blobRoomDoor.glowTask.stop();
          this._blobRoomDoor.glowTask = null;

          this._blobRoomDoor.setTint(0xfffbae);
        },
        this,
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
        this._blobRoomDoor.clearTint();

        var pipeline = postFxPlugin.add(this._blobRoomDoor);
        this._blobRoomDoor.glowTask = this._blobRoomDoor.scene.tweens.add({
          targets: pipeline,
          intensity: 0.011,
          ease: 'Linear',
          duration: 1200,
          repeat: -1,
          yoyo: true,
        });
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
        this._blobRoomDoor.clearTint();
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
        BlobService.getInstance().getBlobInfo(true);
        this._blobRoomDoor.clearTint();
        if (pointer.getDistance() > 16) {
          return;
        }
        this.scene.stop('HudScene');
        this._inBlobRoom = true;
        this._blobRoomDoor.play(`blob_room_door_animation`);
        this.clearMachines();
        this.cameras.main.setZoom(this.sys.canvas.height > 800 ? 1 : 0.8);
        this.cameras.main.centerOn(24 * 64, 11 * 64);
        this.input.enabled = false;
      })
      .on('animationcomplete', this.handleBlobDoorClick, this);
  }

  handleBlobDoorClick(currentAnim, currentFrame, sprite) {
    const blobInfo = BlobService.getInstance().getBlobInfoSync();
    if (!blobInfo) {
      window.setTimeout(this.handleBlobDoorClick, 250, this);
      return;
    }
    this.input.enabled = true;

    // Reset camera bounds and zoom
    this.cameras.main.setBounds(-200, -200, this._map.widthInPixels + 800, this._map.heightInPixels - 440);
    this._zoomMin = 0.7;
    this._zoomMax = 1.2;

    // Center camera on blob room
    this.cameras.main.pan(24 * cellWidth, 6 * cellWidth, 800);

    // Update layer alpha
    this._blobLayer.setAlpha(1);
    this._baseLayer.setAlpha(0.3);

    // Hide blob room door
    this._blobRoomDoor.setAlpha(0);
    this._blobRoomDoor.disableInteractive();
    this._blobRoomDoor.destroy();

    // Clear machines from homebase
    this.clearMachines();

    // Set active map layer
    this._map.setLayer('Blob Layer');

    // Add exit door
    this.addBlobRoomExitDoor();

    // Add blob
    this.addBlob();

    // Start Blob HUD
    window.setTimeout(() => {
      this.scene.launch('BlobHudScene');
    }, 800);
  }

  addBlobRoomExitDoor() {
    var aniConfig = {
      key: 'exit_room_door_animation',
      frames: this.anims.generateFrameNumbers('exit_room_door', {
        start: 0,
        end: 22,
        first: 0,
      }),
      numFrames: 23,
      frameRate: 10,
      frameWidth: 168,
      frameHeight: 64,
      repeat: 0,
      repeatDelay: 0,
    };

    this.anims.create(aniConfig);

    var x = cellWidth * 24;
    var y = cellWidth * 9;

    this._exitBlobRoomDoor = this.physics.add
      .sprite(x, y, 'exit_room_door')
      .setOrigin(0.5, 0)
      .setInteractive({ cursor: 'pointer' })
      .on(
        Phaser.Input.Events.GAMEOBJECT_POINTER_OVER,
        pointer => {
          this._exitBlobRoomDoor.setTint(0xfffbae);
        },
        this,
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, pointer => {
        this._exitBlobRoomDoor.clearTint();
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, pointer => {
        this._exitBlobRoomDoor.clearTint();
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
        this._exitBlobRoomDoor.clearTint();
        if (pointer.getDistance() > 16) {
          return;
        }
        this.scene.stop('BlobHudScene');
        this._exitBlobRoomDoor.play(`exit_room_door_animation`);
        this.cameras.main.setZoom(1);
        this.cameras.main.centerOn(24 * 64, 6 * 64);
        this.input.setDefaultCursor('default');
        this.input.enabled = false;
      })
      .on('animationcomplete', this.handleBlobExitDoorClick, this);
  }

  handleBlobExitDoorClick(currentAnim, currentFrame, sprite) {
    // Center camera on homebase
    //this.cameras.main.pan(17 * 64, 20 * 64, 800, undefined, true, () => {
    // Reset camera bounds and zoom
    this.cameras.main.setBounds(-200, 440, this._map.widthInPixels + 400, this._map.heightInPixels - 200);
    this._zoomMin = 0.6;
    this._zoomMax = 1.6;
    //});

    this.input.enabled = true;

    // Update layer alpha
    this._blobLayer.setAlpha(0.5);
    this._baseLayer.setAlpha(1);

    // Hide exit blob room door
    this._exitBlobRoomDoor.setAlpha(0);
    this._exitBlobRoomDoor.disableInteractive();
    this._exitBlobRoomDoor.destroy();

    // Hide blob
    this._blob.setAlpha(0);
    this._blob.destroy();

    // Update machines on homebase
    this.createMachines(true);

    // Create Blob Room Door
    this.addBlobRoomDoor();

    // Set active map layer
    this._map.setLayer('Tile Layer 1');

    // Start homebase HUD
    this.scene.launch('HudScene');
    this._inBlobRoom = false;
  }

  addBlob() {
    var aniConfig = {
      key: 'green_blob_animation',
      frames: this.anims.generateFrameNumbers('blob', {
        start: 0,
        end: 7,
        first: 0,
      }),
      numFrames: 8,
      frameRate: 10,
      frameWidth: 51,
      frameHeight: 42,
      repeat: -1,
      repeatDelay: 0,
    };

    this.anims.create(aniConfig);

    var x = cellWidth * 24;
    var y = cellWidth * 5 + halfCellWidth;

    this._blob = this.physics.add.sprite(x, y, 'blob').setOrigin(0.5, 0.5).play('green_blob_animation');
  }

  isInBlobRoom() {
    return this._inBlobRoom;
  }

  getBuildableTile() {
    return (
      this._map.getTilesWithin(0, 0, 2000, 2000).find(tile => {
        return tile.properties.buildable && !this.isTileOccupied(tile);
      }) || { x: 5, y: 13 }
    );
  }

  isTileOccupied(tile: any, machine?: any) {
    let machineOnTile = this._userMachines.find(um => {
      let widthInTiles = this.getWidthInTiles(um);
      let heightInTiles = this.getHeightInTiles(um);
      let isOccupied = false;

      for (var i = 0; i < widthInTiles; i++) {
        for (var h = 0; h < heightInTiles; h++) {
          var coveredTile = this._map.getTileAt(um.x + i, um.y - h);
          if (coveredTile && coveredTile.x === tile.x && coveredTile.y === tile.y) {
            isOccupied = true;
          }
        }
      }

      return isOccupied;
    });
    return machineOnTile && machineOnTile.user_machine_id !== machine?.user_machine_id ? true : false;
  }

  setOccupiedCells(isOccupied: boolean) {
    this._userMachines.forEach(mach => {
      var builtTile = this._map.getTileAt(mach.x, mach.y);
      if (builtTile) {
        builtTile.properties.isOccupied = isOccupied;

        const widthInTiles = this.getWidthInTiles(mach);
        for (var i = 1; i < widthInTiles; i++) {
          var extraTile = this._map.getTileAt(builtTile.x + i, builtTile.y);
          if (extraTile && extraTile.properties.buildable) {
            extraTile.properties.isOccupied = isOccupied;
          }
        }
      }
    });
  }

  launchScene(scene, data) {
    this.scene.launch(scene, data);
    this.gameScene = this.scene.get(scene);
  }

  updateResize(scene) {
    scene.scale.on('resize', this.resize, scene);

    const scaleWidth = scene.scale.gameSize.width;
    const scaleHeight = scene.scale.gameSize.height;

    scene.parent = new Phaser.Structs.Size(scaleWidth, scaleHeight);
    scene.sizer = new Phaser.Structs.Size(scene.width, scene.height, Phaser.Structs.Size.FIT, scene.parent);

    scene.parent.setSize(scaleWidth, scaleHeight);
    scene.sizer.setSize(scaleWidth, scaleHeight);

    this.updateCamera(scene);
  }

  resize(gameSize) {
    // 'this' means to the current scene that is running
    if (!this.sceneStopped) {
      const width = gameSize.width;
      const height = gameSize.height;

      this.parent.setSize(width, height);
      this.sizer.setSize(width, height);

      const camera = this.cameras.main;
      const scaleX = this.sizer.width / this.game.screenBaseSize.width;
      const scaleY = this.sizer.height / this.game.screenBaseSize.height;

      camera.setZoom(Math.max(scaleX, scaleY));
      camera.centerOn(this.game.screenBaseSize.width / 2, this.game.screenBaseSize.height / 2);
    }
  }

  updateCamera(scene) {
    const camera = scene.cameras.main;
    const scaleX = scene.sizer.width / this.game.screenBaseSize.width;
    const scaleY = scene.sizer.height / this.game.screenBaseSize.height;

    camera.setZoom(Math.max(scaleX, scaleY));
    camera.centerOn(this.game.screenBaseSize.width / 2, this.game.screenBaseSize.height / 2);
  }

  applyScale(length) {
    return length;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Homebase_Assets));
  }
}
