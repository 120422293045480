import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import { GameState } from '~/../../API/BattleRoyale/src/types/IBattleRoyaleState';

// Pane Details
const paneHeight = 972;
const paneWidth = 540;
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;

const itemWidth = 500,
  itemHeight = 73,
  itemRadius = 13,
  itemColor = 0x262637,
  itemAlpha = 1;

const messageItemHeight = 90;

const itemGlareHeight = 7,
  itemGlareRadius = 4,
  itemGlareColor = 0xffffff,
  itemGlareAlpha = 0.08;

export default class BattleRoyaleBattlePane extends OverlapSizer {
  x;
  y;

  private _scale: number;
  private _battlePanel;
  private _battleRoyaleImage;
  private _participantsText;
  private _participantTable;
  private _battleData;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this.x = x;
    this.y = y;

    this._scale = paneScale;
    this._battleData = data;

    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Battle Panel
    this._battlePanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(120),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    const battleRoyaleBadge = this.scene.add.image(0, 0, 'battleroyale_badge').setScale(this._scale);

    this._battlePanel.add(battleRoyaleBadge, {
      key: 'battleroyale_badge',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(-55),
      expand: false,
    });

    // Add Title Text
    const battleRoyaleTitle = this.scene.add.image(0, 0, 'battleroyale_title_text').setScale(this._scale);

    this._battlePanel.add(battleRoyaleTitle, {
      key: 'battleroyale_title_text',
      align: 'center-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(55),
      expand: false,
    });

    // Top Divider
    const bossFightDividerTop = this.scene.add.image(0, 0, 'battleroyale_divider').setScale(this._scale);

    this._battlePanel.add(bossFightDividerTop, {
      key: 'br-divider-top',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(165),
      expand: false,
    });

    // Add Boss Image
    this._battleRoyaleImage = this.scene.add.image(0, 0, 'battleroyale_game_bg').setScale(this._scale);

    this._battlePanel.add(this._battleRoyaleImage, {
      key: 'bossfight_boss',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(166),
      expand: false,
    });

    // Bottom Divider
    const bossFightDividerBottom = this.scene.add.image(0, 0, 'battleroyale_divider').setScale(this._scale);

    this._battlePanel.add(bossFightDividerBottom, {
      key: 'bf-divider-bottom',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(166 + this._battleRoyaleImage.height + 1),
      expand: false,
    });

    // Participants Text
    var participantCount = this._battleData.participants == undefined ? 0 : this._battleData.participants.length;
    this._participantsText = this.scene.add.bitmapText(
      0,
      0,
      'cc_outline',
      participantCount.toLocaleString('en-us') + ' participants have joined...',
      this.applyScale(18),
      0,
    );
    this._battlePanel.add(this._participantsText, {
      key: 'participants-text',
      align: 'left-top',
      offsetX: this.applyScale(30),
      offsetY: this.applyScale(166 + this._battleRoyaleImage.height + 1 + 20),
      expand: false,
    });

    // Determines what to display in the Pane Table based on gamestate
    if (this._battleData.hasGameStarted) {
      this._participantTable = this.createGameplayTable(this.scene, this._battleData.messages, x, y, this.scale).layout();
      this._battlePanel.add(this._participantTable, {
        key: 'participants-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(166 + this._battleRoyaleImage.height + 1 + 80),
        expand: false,
      });
    } else {
      this._participantTable = this.createPlayerTable(this.scene, this._battleData.participants, x, y, this.scale).layout();
      this._battlePanel.add(this._participantTable, {
        key: 'participants-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(166 + this._battleRoyaleImage.height + 1 + 80),
        expand: false,
      });
    }

    this.add(this._battlePanel, {
      key: 'battle-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private createPlayerTable(scene, data, x, y, scale) {
    var tableHeight = paneHeight - (166 + this._battleRoyaleImage.height + 1 + 100);
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(paneWidth),
        height: scene.applyScale(tableHeight),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(itemWidth),
          cellHeight: this.applyScale(itemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            participant = cell.item;

          const participantName = participant.playerName;
          const participantLevel = participant.playerLevel;
          const participantPfp = participant.playerImageKey;

          const rectConfig = {
            maskType: 'roundRectangle',
            radius: scene.applyScale(40),
          };

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(20),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(itemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alpha
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )
            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth - 15), // width
                scene.applyScale(itemGlareHeight), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'center-top',
                offsetY: scene.applyScale(7),
                expand: false,
              },
            )
            .add(
              scene.rexUI.add
                .badgeLabel({
                  x: 0,
                  y: 0,
                  space: { left: 0, right: -5, top: 0, bottom: 0 },
                  main: scene.rexUI.add.label({
                    orientation: 'x',
                    rtl: false,
                    icon: scene.createSquadMemberImage(scene, participantPfp).setScale(scene.applyScale(0.3)),
                    iconWidth: scene.applyScale(38),
                    iconHeight: scene.applyScale(38),
                    text: scene.add.bitmapText(0, 0, 'cc_outline', participantName, scene.applyScale(20), 0),
                    space: {
                      icon: scene.applyScale(10),
                      right: scene.applyScale(22),
                    },
                  }),
                  rightCenter: participant.hasBattlePass ? scene.add.image(0, 0, 'battle_pass_icon') : undefined,
                })
                .layout(),
              {
                key: 'item_name_label',
                align: 'left-top',
                offsetX: scene.applyScale(21),
                offsetY: scene.applyScale(24),
                expand: false,
              },
            )

            // Level
            .add(scene.add.bitmapText(0, 0, 'cc_outline', 'Level ' + participantLevel, scene.applyScale(18), 0), {
              key: `level_label`,
              align: 'right-top',
              offsetX: scene.applyScale(-21),
              offsetY: scene.applyScale(32),
              expand: false,
            });

          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  private createGameplayTable(scene, data, x, y, scale) {
    var tableHeight = paneHeight - (166 + this._battleRoyaleImage.height + 1 + 100);
    var table = scene.rexUI.add
      .gridTable({
        width: scene.applyScale(paneWidth),
        height: scene.applyScale(tableHeight),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: scene.applyScale(itemWidth),
          cellHeight: scene.applyScale(messageItemHeight + 7),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            brmessage = cell.item;

          // Stores BR Message info
          const message = brmessage.message;
          const player1 = brmessage.player1;
          const player1Pfp = brmessage.player1Pfp;
          const player2 = brmessage.player2;

          const rectConfig = {
            maskType: 'roundRectangle',
            radius: scene.applyScale(40),
          };

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(itemWidth),
              space: {
                left: scene.applyScale(20),
                right: 0,
                top: 0,
                bottom: scene.applyScale(7),
                item: 0,
                line: 0,
              },
              align: 0,
            })

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(itemWidth), // width
                scene.applyScale(messageItemHeight), // height
                scene.applyScale(itemRadius), // radius
                itemColor, // fillColor
                itemAlpha, // alphamessage
              ),
              {
                key: 'staking_item_bg',
                align: 'center',
              },
            )

            .add(
              scene.add.rexRoundRectangle(
                0,
                0,
                scene.applyScale(470), // width
                scene.applyScale(4.6), // height
                scene.applyScale(itemGlareRadius), // radius
                itemGlareColor, // fillColor
                itemGlareAlpha, // alpha
              ),
              {
                key: 'item_glare',
                align: 'left-top',
                offsetX: scene.applyScale(15),
                offsetY: scene.applyScale(7.7),
                expand: false,
              },
            )
            .add(
              scene.rexUI.add.label({
                orientation: 'x',
                rtl: false,
                icon: scene.createSquadMemberImage(scene, player1Pfp).setScale(scene.applyScale(0.3)),
                iconWidth: scene.applyScale(38),
                iconHeight: scene.applyScale(38),
                //icon: scene.add.rexCircleMaskImage(0, 0, , '', rectConfig).setScale(scene.applyScale(0.3)),
                text: scene.add
                  .bitmapText(0, 0, 'cc_outline', message, scene.applyScale(15), 0)
                  .setMaxWidth(scene.applyScale(418))
                  .setCharacterTint(
                    //message.indexOf(player1),
                    scene.getTintIndex(player1, message, false),
                    player1.length,
                    true,
                    scene.getTintColor(player1),
                  )
                  .setCharacterTint(
                    //message.indexOf(player2),
                    scene.getTintIndex(player2, message, false),
                    player2.length,
                    true,
                    scene.getTintColor(player2),
                  )
                  .setCharacterTint(
                    //message.indexOf(player2, message.indexOf(player2) + player2.length),
                    scene.getTintIndex(player2, message, true),
                    player2.length,
                    true,
                    scene.getTintColor(player2),
                  ),
                space: {
                  icon: scene.applyScale(10),
                  right: scene.applyScale(10),
                },
              }),
              {
                key: 'item_name_label',
                align: 'left-top',
                offsetX: scene.applyScale(21),
                offsetY: scene.applyScale(24),
                expand: false,
              },
            );

          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    table.scrollToBottom();
    return table;
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public switchDisplayTable(battleData: any) {
    // Change from Participants List to Battle Royale Message Log
    if (battleData.gameState === GameState.BattleRoyale) {
      var gameObject = this._battlePanel.getElement('participants-table');
      this._battlePanel.remove(gameObject, true);

      //this._participantTable.setItems();
      this._participantTable.alpha = 0;
      this._participantTable.destroy();
      this._participantTable = undefined;
      this._participantTable = this.createGameplayTable(this.scene, this._battleData.messages, this.x, this.y, this.scale).layout();

      this._battlePanel.add(this._participantTable, {
        key: 'participants-table',
        align: 'left-top',
        offsetX: this.applyScale(0),
        offsetY: this.applyScale(166 + this._battleRoyaleImage.height + 1 + 80),
        expand: false,
      });

      this.layout();
    }
  }

  public updateMessageDisplay(battleData: any) {
    this._participantTable.setItems();
    this._participantTable.setItems(battleData.messages);
    this._participantTable.scrollToBottom();
    this._participantsText.setText(battleData.participantsRemaining + ' participants remaining...');
  }

  public updateParticipantsList(participants) {
    this._participantTable.setItems();
    this._participantTable.setItems(participants);
    this._participantTable.scrollToBottom();
    this._participantsText.setText(participants.length + ' participants have joined...');
  }
}

Phaser.GameObjects.GameObjectFactory.register('battleRoyaleBattlePane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BossFightBattlePane(this.scene, x, y));
});
