const helpOptions = [
  {
    name: 'game_guide',
    type: 'guides',
    target_url: 'https://starwolvez.notion.site/Star-Wolvez-Game-Guide-5bd3c15b4f3f465bbf24aaa265d3e9c4',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'guide_game_text',
    enabled: true,
  },
  {
    name: 'changelog',
    type: 'guides',
    target_url: 'https://starwolvez.notion.site/Changelog-63d570a408864aae9aecb78c6484b070',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'guide_changelog_text',
    enabled: true,
  },
  {
    name: 'wolf_e_dex',
    type: 'guides',
    target_url: 'https://dex.starwolvez.com/',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'guide_wolf_e_dex_text',
    enabled: true,
  },
  {
    name: 'medium',
    type: 'guides',
    target_url: 'https://medium.com/@starwolvez',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'guide_medium_text',
    enabled: true,
  },
  {
    name: 'gameplay_basics',
    type: 'litepapers',
    target_url: 'https://starwolvez.medium.com/the-game-litepaper-001-gameplay-basics-5dc17a4a8b8d',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'litepaper_1_gameplay_text',
    enabled: true,
  },
  {
    name: 'tokenomics',
    type: 'litepapers',
    target_url: 'https://starwolvez.medium.com/the-game-litepaper-002-tokenomics-9337a5c03c64',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'litepaper_2_tokenomics_text',
    enabled: true,
  },
  {
    name: 'traits',
    type: 'traits_and_partners',
    target_url: 'https://docs.google.com/spreadsheets/d/15j4HqQOvWkgQ-VukKuixV5Vo8FSFez7HRi1RyuptYnY/edit#gid=0',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'traits_text',
    enabled: true,
  },
  {
    name: 'partners',
    type: 'traits_and_partners',
    target_url: 'https://starwolvez.notion.site/Partner-Projects-757feec064be49a3bb8e148df0367a24',
    bg_image_key: 'helpdesk_option_bg',
    label_image_key: 'partners_text',
    enabled: true,
  },
];

const helpTypes = [
  {
    key: 'guides',
    displayName: 'Guides',
    isSelected: true,
  },
  {
    key: 'litepapers',
    displayName: 'Litepapers',
    isSelected: false,
  },
  {
    key: 'traits_and_partners',
    displayName: 'Traits and Partners',
    isSelected: false,
  },
];

export default class HelpDeskService {
  private static _instance: HelpDeskService = new HelpDeskService();
  private _helpOptions: any;
  private _helpTypes: any;

  constructor() {
    if (HelpDeskService._instance) {
      throw new Error('Error: Instantiation failed: Use HelpDeskService.getInstance() instead of new.');
    }
    HelpDeskService._instance = this;
    this._helpOptions = helpOptions;
    this._helpTypes = helpTypes;
  }

  public static getInstance(): HelpDeskService {
    return HelpDeskService._instance;
  }

  public getHelpOptions() {
    return this._helpOptions;
  }

  public getHelpTypes() {
    return this._helpTypes;
  }
}
