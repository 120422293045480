import Phaser from 'phaser';
import CircleMaskImagePlugin from 'phaser3-rex-plugins/plugins/circlemaskimage-plugin.js';
import RoundRectanglePlugin from 'phaser3-rex-plugins/plugins/roundrectangle-plugin.js';
import GridTablePlugin from 'phaser3-rex-plugins/plugins/gridtable-plugin.js';
import InputTextPlugin from 'phaser3-rex-plugins/plugins/inputtext-plugin.js';
import DynamicTextPlugin from 'phaser3-rex-plugins/plugins/dynamictext-plugin.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import GlowFilterPipelinePlugin from 'phaser3-rex-plugins/plugins/glowfilterpipeline-plugin.js';
import DissolvePipelinePlugin from 'phaser3-rex-plugins/plugins/dissolvepipeline-plugin.js';
import TransitionImagePlugin from 'phaser3-rex-plugins/plugins/transitionimage-plugin.js';
//import SpinnerPlugin from "phaser3-rex-plugins/templates/spinner/spinner-plugin.js";
//import ClickOutsidePlugin from 'phaser3-rex-plugins/plugins/clickoutside-plugin.js';

import Preloader from './scenes/preloader';
import MiningTestingScene from './scenes/missions/mining/miningTestingScene';
import LoginScene from './scenes/login/loginScene';
import LoadingScene from './scenes/login/loadingScene';
import NameScene from './scenes/login/nameScene';
import HomebaseLoadingScene from './scenes/homebase/homebaseLoadingScene';
import HomebaseScene from './scenes/homebase/homebaseScene';
import HudScene from './scenes/hud/hudScene';
import SquadModalScene from './scenes/modals/squad_modal/squadModalScene';
import ShopModalScene from './scenes/modals/shop_modal/shopModalScene';
import ShopItemInfoScene from './scenes/modals/shop_modal/item_info/shopItemInfoScene';
import LoginFailedScene from './scenes/login/loginFailedScene';
import DailyChestModalScene from './scenes/modals/objectives_modal/dailyChestModalScene';
import RewardModalScene from './scenes/modals/objectives_modal/rewardModalScene';
import ErrorScene from './scenes/errorScene';
import PenaltyScene from './scenes/modals/objectives_modal/penaltyScene';
import PvPScene from './scenes/missions/pvp/pvpScene';
import MatchmakingScene from './scenes/missions/pvp/matchmakingScene';
import PvpGameOverScene from './scenes/missions/pvp/pvpGameOverScene';
import PvpEntryScene from './scenes/missions/pvp/pvpEntryScene';
import PvpRewardsScene from './scenes/missions/pvp/pvpRewardsScene';
import PvEScene from './scenes/missions/pve/pveScene';
import PveRewardsScene from './scenes/missions/pve/pveRewardsScene';
import PveGameOverScene from './scenes/missions/pve/pveGameOverScene';
import BotMatchingScene from './scenes/missions/pve/botMatchingScene';
import MissionsModalScene from './scenes/modals/missions_modal/missionsModalScene';
import MiningModalScene from './scenes/modals/mining_modal/MiningModalScene';
import MiningLoadingScene from './scenes/missions/mining/miningLoadingScene';
import MiningDetailsScene from './scenes/missions/mining/miningDetailsScene';
import MiningStatusScene from './scenes/missions/mining/miningStatusScene';
import MiningResultScene from './scenes/missions/mining/miningResultScene';
import MiningCancelDialogScene from './scenes/missions/mining/miningCancelDialogScene';
import MiningNotEnoughResourcesDialogScene from './scenes/missions/mining/miningNotEnoughResourcesDialogScene';
import BaseRaidLoadingScene from './scenes/missions/base_raid/baseRaidLoadingScene';
import BaseRaidDetailsScene from './scenes/missions/base_raid/baseRaidDetailsScene';
import BaseRaidHudScene from './scenes/missions/base_raid/baseRaidHudScene';
import LeaderboardScene from './scenes/leaderboard/leaderboardScene';
import PvpSurrenderScene from './scenes/missions/pvp/pvpSurrenderScene';
import ShopPurchaseResultScene from './scenes/modals/shop_modal/shopPurchaseResultScene';
import ShopWalletScene from './scenes/modals/shop_modal/item_shop/shopWalletScene';
import ShopPurchaseConfirmScene from './scenes/modals/shop_modal/shopPurchaseConfirmScene';
import StartScene from './scenes/login/startScene';
import MachineInfoModalScene from './scenes/modals/machine_modal/machineInfoModalScene';
import ResourceModalScene from './scenes/modals/resource_modal/resourceModalScene';
import MachineSpeedUpModalScene from './scenes/modals/machine_modal/machineSpeedUpModalScene';
import HelpdeskModalScene from './scenes/modals/helpdesk_modal/helpdeskModalScene';
import BossFightScene from './scenes/missions/boss-fight/bossFightScene';
import UserLevelModalScene from './scenes/modals/user_level_modal/userLevelModalScene';
import HudBossFightAlertScene from './scenes/hud/hudBossFightAlertScene';
import HudBossFightMissedAlertScene from './scenes/hud/hudBossFightMissedAlertScene';
import BossFightVictoryScene from './scenes/missions/boss-fight/bossFightVictoryScene';
import BossFightDefeatScene from './scenes/missions/boss-fight/bossFightDefeatScene';
import ShopRewardScene from './scenes/modals/shop_modal/shopRewardScene';
import BattleRoyaleScene from './scenes/missions/battle-royale/battleRoyaleScene';
import BattleRoyaleEntryScene from './scenes/missions/battle-royale/battleRoyaleEntryScene';
import BattleRoyaleEliminatedScene from './scenes/missions/battle-royale/battleRoyaleEliminatedScene';
import BattleRoyaleGameOverScene from './scenes/missions/battle-royale/battleRoyaleGameOverScene';
import BlobHudScene from './scenes/hud/blobHudScene';
import UpgradeBlobModalScene from './scenes/modals/upgrade_blob_modal/upgradeBlobModalScene';
import ShopDiscordScene from './scenes/modals/shop_modal/item_shop/shopDiscordScene';
import MachineUpgradeModalScene from './scenes/modals/machine_modal/machineUpgradeModalScene';
import BaseRaidConfirmNextBaseLoadScene from '~/scenes/missions/base_raid/baseRaidConfirmNextBaseLoadScene';
import HpModalScene from './scenes/modals/resource_modal/hpModalScene';
import BaseRaidResultScene from './scenes/missions/base_raid/baseRaidResultScene';
import BaseRaidConfirmSurrenderScene from './scenes/missions/base_raid/BaseRaidConfirmSurrenderScene';
import BattleRoyaleServerUnavailableScene from './scenes/missions/battle-royale/battleRoyaleServerUnavailableScene';
import BattleRoyaleResultsScene from './scenes/missions/battle-royale/battleRoyaleResultsScene';
import BossFightResultsScene from './scenes/missions/boss-fight/bossFightResultsScene';
import ArmyModalScene from './scenes/modals/army_modal/armyModalScene';
import MessageScene from './scenes/message/messageScene';
import HudBattleRoyaleAlertScene from './scenes/hud/hudBattleRoyaleAlertScene';
import BattleRoyaleFailedScene from './scenes/missions/battle-royale/battleRoyaleFailedScene';
import BossFightServerUnavailableScene from './scenes/missions/boss-fight/bossFightServerUnavailableScene';
import BossFightLobbyClosedScene from './scenes/missions/boss-fight/bossFightLobbyClosedScene';
import BattlePassBuyScene from './scenes/battlepass/battlePassBuyScene';
import BattlePassBuySuccessScene from './scenes/battlepass/battlePassBuySuccessScene';
import BattlePassScene from './scenes/battlepass/battlePassScene';
import BaseRaidHistoryScene from './scenes/missions/base_raid/baseRaidHistoryScene';
import PackOpeningScene from './scenes/packs/packOpeningScene';
import ProfileModalScene from './scenes/modals/profile_modal/profileModalScene';
import ObjectivesModalScene from './scenes/modals/objectives_modal/objectivesModalScene';
import UserBattlePassModalScene from './scenes/modals/user_battle_pass_modal/userBattlePassModalScene';
import BattlePassItemClaimScene from './scenes/battlepass/battlepassItemClaimScene';
import BattlePassClaimResultScene from './scenes/battlepass/battlePassClaimResultScene';
import NftLoaderScene from './scenes/loaders/nftLoaderScene';
import NftPageLoaderScene from './scenes/loaders/nftPageLoaderScene';
import AssetPreloaderScene from './scenes/loaders/assetPreloaderScene';

const divId = 'game';

// const isMetamaskIphone = /(iPhone|iPod|MetaMaskMobile)/i.test(window.navigator.userAgent);

const game = new Phaser.Game({
  type: Phaser.AUTO, //isMetamaskIphone ? Phaser.CANVAS : Phaser.AUTO,
  parent: divId,
  transparent: true,
  width: window.innerWidth,
  height: window.innerHeight,
  backgroundColor: 0x0f0f1f,
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 0 },
    },
  },
  scale: {
    parent: divId,
    mode: Phaser.Scale.NONE,
    // width: SIZE_WIDTH_SCREEN,
    // height: SIZE_HEIGHT_SCREEN,
    // min: {
    //   width: MIN_SIZE_WIDTH_SCREEN,
    //   height: MIN_SIZE_HEIGHT_SCREEN
    // },
    // max: {
    //     width: MAX_SIZE_WIDTH_SCREEN,
    //     height: MAX_SIZE_HEIGHT_SCREEN
    // }
  },
  plugins: {
    scene: [
      {
        key: 'rexUI',
        plugin: UIPlugin,
        mapping: 'rexUI',
      },
    ],
    global: [
      {
        key: 'rexCircleMaskImagePlugin',
        plugin: CircleMaskImagePlugin,
        start: true,
      },
      {
        key: 'rexRoundRectanglePlugin',
        plugin: RoundRectanglePlugin,
        start: true,
      },
      {
        key: 'rexGridTablePlugin',
        plugin: GridTablePlugin,
        start: true,
      },
      {
        key: 'rexInputTextPlugin',
        plugin: InputTextPlugin,
        start: true,
      },
      {
        key: 'rexDynamicTextPlugin',
        plugin: DynamicTextPlugin,
        start: true,
      },
      {
        key: 'rexGlowFilterPipeline',
        plugin: GlowFilterPipelinePlugin,
        start: true,
      },
      {
        key: 'rexDissolvePipeline',
        plugin: DissolvePipelinePlugin,
        start: true,
      },
      {
        key: 'rexTransitionImagePlugin',
        plugin: TransitionImagePlugin,
        start: true,
      },
      // {
      //   key: "rexSpinner",
      //   plugin: SpinnerPlugin,
      //   mapping: "rexSpinner",
      // },
      // {
      //   key: 'rexClickOutsiden',
      //   plugin: ClickOutsidePlugin,
      //   start: true
      // }
    ],
  },
  dom: {
    createContainer: true,
  },
  input: {
    mouse: {
      target: divId,
    },
    // touch: {
    //   target: divId
    // }
  },
  scene: [
    StartScene,
    Preloader,
    NftLoaderScene,
    NftPageLoaderScene,
    AssetPreloaderScene,
    LoginScene,
    LoadingScene,
    NameScene,
    LoginFailedScene,
    ErrorScene,
    HomebaseLoadingScene,
    HomebaseScene,
    HudScene,
    BlobHudScene,
    UpgradeBlobModalScene,
    HudBossFightAlertScene,
    HudBossFightMissedAlertScene,
    PvPScene,
    PvEScene,
    PvpEntryScene,
    MatchmakingScene,
    BotMatchingScene,
    PvpGameOverScene,
    PvpRewardsScene,
    PvpSurrenderScene,
    PveRewardsScene,
    PveGameOverScene,
    BossFightScene,
    BossFightVictoryScene,
    BossFightDefeatScene,
    BattleRoyaleScene,
    BattleRoyaleEntryScene,
    BattleRoyaleEliminatedScene,
    MissionsModalScene,
    MiningModalScene,
    MiningLoadingScene,
    MiningDetailsScene,
    MiningStatusScene,
    MiningCancelDialogScene,
    MiningNotEnoughResourcesDialogScene,
    MiningResultScene,
    BaseRaidConfirmNextBaseLoadScene,
    BaseRaidLoadingScene,
    BaseRaidDetailsScene,
    BaseRaidHudScene,
    SquadModalScene,
    ShopModalScene,
    ShopWalletScene,
    ShopDiscordScene,
    ShopItemInfoScene,
    ShopPurchaseResultScene,
    ShopPurchaseConfirmScene,
    ShopRewardScene,
    MachineInfoModalScene,
    MachineUpgradeModalScene,
    MachineSpeedUpModalScene,
    UserLevelModalScene,
    LeaderboardScene,
    HelpdeskModalScene,
    ResourceModalScene,
    MiningTestingScene,
    HpModalScene,
    BaseRaidResultScene,
    BaseRaidConfirmSurrenderScene,
    BattleRoyaleGameOverScene,
    BattleRoyaleServerUnavailableScene,
    BattleRoyaleResultsScene,
    BossFightResultsScene,
    ArmyModalScene,
    HudBattleRoyaleAlertScene,
    BattleRoyaleFailedScene,
    BossFightServerUnavailableScene,
    BossFightLobbyClosedScene,
    BattlePassBuyScene,
    BattlePassBuySuccessScene,
    BaseRaidHistoryScene,
    PackOpeningScene,
    ProfileModalScene,
    BattlePassScene,
    ObjectivesModalScene,
    PenaltyScene,
    DailyChestModalScene,
    RewardModalScene,
    UserBattlePassModalScene,
    BattlePassItemClaimScene,
    BattlePassClaimResultScene,
    MessageScene,
  ],
});

// function resize() {
//   var canvas = document.querySelector("canvas");
//   var windowWidth = window.innerWidth;
//   var windowHeight = window.innerHeight;
//   var windowRatio = windowWidth / windowHeight;
//   var gameRatio = parseInt(game.config.width.toString()) / parseInt(game.config.height.toString());

//   if (!canvas) return;

//   if(windowRatio < gameRatio){
//       canvas.style.width = windowWidth + "px";
//       canvas.style.height = (windowWidth / gameRatio) + "px";
//   }
//   else{
//       canvas.style.width = (windowHeight * gameRatio) + "px";
//       canvas.style.height = windowHeight + "px";
//   }
// }

// resize();
// window.addEventListener("resize", resize, false);

const { ethereum } = window;
let currentAddress;

// Handle active wallet changes after connecting
if (ethereum && ethereum.isMetaMask) {
  currentAddress = ethereum.selectedAddress;

  ethereum.on('accountsChanged', function (accounts) {
    if (currentAddress === undefined || currentAddress === null) {
      currentAddress = accounts[0];
    }
    if ((currentAddress && currentAddress !== accounts[0]) || accounts.length === 0) {
      // User disconnected metamask or changed active wallets.  Force refresh.
      window.location.reload();
    }
  });
}

export default game;
