import Phaser from 'phaser';
import PVEServer from '~/services/PVEServerService';
import UserService from '~/services/UserService';
import SquadService from '~/services/SquadService';
import BotService from '~/services/BotService';
import _ from 'lodash';

import ScaleService from '~/services/ScaleService';
import { PVE_Bot_Matching_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 380;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

export default class BotMatchingScene extends Phaser.Scene {
  private server!: PVEServer;
  private _player;
  private _scale;

  constructor() {
    super({
      key: 'BotMatchingScene',
    });
  }

  preload() {
    UserService.getInstance().getUserData();

    // Grabs Bot Data from the server incase its needed
    BotService.getInstance()
      .getBot(true)
      .then(res => {
        this.server.joinBot();
      });

    LoadImageAssets(this, PVE_Bot_Matching_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100);
    this.server = PVEServer.getInstance();
    this._player = UserService.getInstance().getUserDataSync();
    this._player.squadMembers = SquadService.getInstance().getSquadSync();

    // UI Elements
    const bg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    bg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Matchmaking Text to display
    this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 - this.applyScale(120),
        'cc_outline',
        'Loading Bot Match \n Please stand by...',
        this.applyScale(26),
        0,
      )
      .setOrigin(0.5, 0)
      .setCenterAlign();

    // blob animation
    var animationConfig = {
      key: 'blob_animation',
      frames: this.anims.generateFrameNumbers('party_blob', {
        start: 0,
        end: 7,
        first: 7,
      }),
      frameRate: 15,
      repeat: -1,
    };

    this.anims.create(animationConfig);
    this.add
      .sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + this.applyScale(30), 'party_blob')
      .setOrigin(0.5, 0.5)
      .play('blob_animation');

    return this.server.join(this._player).then(room => {
      // handles incoming server signals for PVE
      const handleGameStartedCurried = _.curry(this.handleGameStarted)(room.id);
      this.server.onGameStarted(handleGameStartedCurried, this);
    });
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  onCancel() {
    this.server.leave();
    this.unloadAssets();
    this.scene.start('HomebaseScene');
    this.scene.launch('HudScene');
    this.scene.stop();
  }

  handleGameStarted(roomId, data) {
    this.scene.start('PvEScene', {});
    this.unloadAssets();
    this.scene.stop();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(PVE_Bot_Matching_Scene_Assets));
  }
}
