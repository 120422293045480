import Phaser from 'phaser';
import util from 'util';
import Button from '../../../components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import MachineService from '~/services/MachineService';
import ItemShopContentPane from './item_shop/shopItemShopPane';
import MachinesContentPane from './machines_shop/shopMachinesPane';
import ShopService from '~/services/ShopService';
import ShopItemInfoContentPane from './item_info/shopItemInfoPane';
import { Shop_Assets } from '~/utils/AssetLoader';
import ResourcesShopPane from './resources_shop/shopResourcesPane';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalWidth = 1008;
const modalHeight = 513;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;
const modalBorderColor = 0x6262de;
const modalBorderWidth = 3;

const tabs = {
  machines: 'machines',
  itemShop: 'item_shop',
  resources: 'resources',
};

export default class ShopModalScene extends Phaser.Scene {
  private _scale;
  private _machinesTab;
  private _machinesContentPane;
  private _itemShopTab;
  private _itemShopContentPane;
  private _resourcesTab;
  private _resourcesContentPane;
  private _startingTab;
  private _selectedTab;
  private _loadingIndicator;
  private _sizer;

  init(data) {
    this._startingTab = data.active_tab;
  }

  constructor() {
    super({
      key: 'ShopModalScene',
    });
  }

  preload() {
    // Images loaded in AssetPreloaderScene
  }

  create() {
    this.createComponent();

    this.scene.launch('AssetPreloaderScene', { originating_scene: 'ShopModalScene', assets: Shop_Assets });
  }

  createComponent() {
    const self = this;
    if (this.game.input.touch && this.game.input.touch.preventDefault) {
      this.game.input.touch.preventDefault = false;
      this.game.input.touch.enabled = true;
    }

    this.input.dragDistanceThreshold = 16;
    this.input.dragTimeThreshold = 300;

    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 70);

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      height: this.applyScale(583),
      width: this.applyScale(modalWidth - 8),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    background.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    this._sizer.add(background, {
      key: 'shop_bg',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    this._sizer.layout();

    //this._sizer.drawBounds(this.add.graphics(), 0x00ff00);

    this._sizer.onClickOutside((co, go, pointer) => {
      if (pointer.getDistance() > 20) {
        return;
      }
      this._selectedTab = '';
      this.removePanes();
      this.unloadAssets();
      this.scene.stop();
      // this.scene.resume('HomebaseScene');
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer
      .add(this._loadingIndicator, {
        key: 'loading_indicator',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      })
      .layout();
  }

  onLoaded() {
    this._loadingIndicator.setAlpha(0);
    this._sizer.remove(this._loadingIndicator);
    this._loadingIndicator = undefined;

    //Tab Sizer
    const tabSizer = this.rexUI.add.fixWidthSizer(0, 0, {
      width: modalWidth,
      align: 2,
      name: 'tab_sizer',
      draggable: false,
    });

    // Tabs
    this._machinesTab = new Button(this.scene.scene, 0, 0, 'machines_tab_active', false).setOrigin(1, 1).setScale(this._scale);
    this._itemShopTab = new Button(this.scene.scene, 0, 0, 'item_shop_tab_inactive', false).setOrigin(0, 1).setScale(this._scale);
    this._resourcesTab = new Button(this.scene.scene, 0, 0, 'resources_tab_inactive', false).setOrigin(0, 1).setScale(this._scale);

    this._machinesTab.onClick().subscribe(pointer => {
      this.machinesTabClickHandler(pointer);
    });

    this._itemShopTab.onClick().subscribe(pointer => {
      this.itemShopTabClickHandler(pointer);
    });

    this._resourcesTab.onClick().subscribe(pointer => {
      this.resourcesTabClickHandler(pointer);
    });

    tabSizer.add(this.add.existing(this._machinesTab), {
      key: 'machines_tab',
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    });

    tabSizer.add(this.add.existing(this._itemShopTab), {
      key: 'item_shop_tab',
      padding: { left: this.applyScale(14), right: 0, top: 0, bottom: 0 },
    });

    tabSizer.add(this.add.existing(this._resourcesTab), {
      key: 'resources_tab',
      padding: { left: this.applyScale(14), right: 0, top: 0, bottom: 0 },
    });

    this._sizer.add(tabSizer, {
      key: 'tab_sizer',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    switch (this._startingTab) {
      case tabs.machines:
        this.machinesTabClickHandler(self);
        break;
      case tabs.itemShop:
        this.itemShopTabClickHandler(self);
        break;
      case tabs.resources:
        this.resourcesTabClickHandler(self);
        break;
      default:
        this.machinesTabClickHandler(self);
        break;
    }
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  private machinesTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.machines) {
      return;
    }

    this.removePanes();

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer
      .add(this._loadingIndicator, {
        key: 'loading_indicator',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      })
      .layout();

    this._selectedTab = tabs.machines;
    this._machinesTab.setTexture('machines_tab_active');
    this._itemShopTab.setTexture('item_shop_tab_inactive');
    this._resourcesTab.setTexture('resources_tab_inactive');

    MachineService.getInstance()
      .getAvailableMachines(true)
      .then(res => {
        this._loadingIndicator.setAlpha(0);
        this._sizer.remove(this._loadingIndicator);
        this._loadingIndicator = undefined;

        this._machinesContentPane = new MachinesContentPane(this, 0, 0, this._scale);
        this._sizer
          .add(this.add.existing(this._machinesContentPane), {
            key: 'machines_pane',
            align: 'center-top',
            offsetX: 0,
            offsetY: this.applyScale(68),
            expand: false,
          })
          .layout();
      });

    //this._machinesContentPane.drawBounds(this.add.graphics(), 0x00ff00);
  }

  private itemShopTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.itemShop) {
      return;
    }

    this.removePanes();

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer
      .add(this._loadingIndicator, {
        key: 'loading_indicator',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        expand: false,
      })
      .layout();

    this._selectedTab = tabs.itemShop;
    this._machinesTab.setTexture('machines_tab_inactive');
    this._itemShopTab.setTexture('item_shop_tab_active');
    this._resourcesTab.setTexture('resources_tab_inactive');

    ShopService.getInstance()
      .getShopItems(true)
      .then(() => {
        ShopService.getInstance()
          .getRewardBoxes(true)
          .then(() => {
            this._loadingIndicator.setAlpha(0);
            this._sizer.remove(this._loadingIndicator);
            this._loadingIndicator = undefined;

            this._itemShopContentPane = new ItemShopContentPane(this, 0, 0, this._scale);

            this._sizer
              .add(this.add.existing(this._itemShopContentPane), {
                key: 'item_shop_pane',
                align: 'center-top',
                offsetX: 0,
                offsetY: this.applyScale(68),
                expand: false,
              })
              .layout();
          });
      });
  }

  private resourcesTabClickHandler(this, pointer) {
    if (this._selectedTab === tabs.resources) {
      return;
    }

    this.removePanes();

    this._selectedTab = tabs.resources;
    this._machinesTab.setTexture('machines_tab_inactive');
    this._itemShopTab.setTexture('item_shop_tab_inactive');
    this._resourcesTab.setTexture('resources_tab_active');

    this._resourcesContentPane = new ResourcesShopPane(this, 0, 0, this._scale);
    this._sizer
      .add(this.add.existing(this._resourcesContentPane), {
        key: 'resources_pane',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(68),
        expand: false,
      })
      .layout();
  }

  removePanes() {
    if (this._machinesContentPane) {
      this._machinesContentPane.hidePane();
      this._sizer.remove(this._machinesContentPane, true); //destroys object
      this._machinesContentPane = null;
    }

    if (this._itemShopContentPane) {
      this._itemShopContentPane.hidePane();
      this._sizer.remove(this._itemShopContentPane, true); //destroys object
      this._itemShopContentPane = null;
    }

    if (this._resourcesContentPane) {
      this._resourcesContentPane.hidePane();
      this._sizer.remove(this._resourcesContentPane, true); //destroys object
      this._resourcesContentPane = null;
    }

    if (this._loadingIndicator) {
      this._loadingIndicator.setAlpha(0);
      this._sizer.remove(this._loadingIndicator);
      this._loadingIndicator = undefined;
    }
  }

  destroy() {
    this._itemShopTab.destroy();
    this._machinesTab.destroy();
    this._resourcesTab.destroy();
    this._selectedTab.destroy();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Shop_Assets));
  }
}
