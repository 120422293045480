import Phaser from 'phaser';
import ConfigurationService from '~/services/ConfigurationService';
import ScaleService from '~/services/ScaleService';
import { Mining_Assets, Pack_Assets } from '~/utils/AssetLoader';
import TweenHelper from '~/utils/TweenHelper';
import PackItemComponent from '~/components/packs/packItemComponent';
import Button from '~/components/buttons/Button';
import { VirtualAction } from 'rxjs';
import UserService from '~/services/UserService';
import { LoadImageAssets, UnloadImages, UnloadSounds } from '~/utils/AssetManager';

const contentWidth = 800;
const contentHeight = 800;
const packScale = 0.7;
const packOffset = 0;
const openTextOffset = 0;

const packTypes = [
  'none', // 0
  'bronze_pack', // 1
  'silver_pack', // 2
  'golden_pack', // 3
  'rare_pack', // 4
  'ultimate_rare_pack', // 5
  'rare_legendary_pack', // 6
  'ultimate_legendary_pack', // 7
];

const RewardTypes = [
  'none', // 0
  'XP', // 1
  'Currency', // 2
  'bronze_pack', // 3
  'ShopItem', // 4
  'silver_pack', // 5
  'golden_pack', // 6
  'Machine', // 7
  'rare_pack', // 8
  'legendary_pack', // 9
  'mythical_pack', // 10
  'epic_pack', // 11
  'NFT', // 12
  'MachineSkin', // 13
];

/*
  "loot_pack_info": [
    {
      "loot_pack_reward_id": 1,
      "rarity_type": 1,
      "currency_id": 1,
      "currency_value": 50000,
      "shop_item_id": 0,
      "nft_id": 0,
      "prob_start": "0.00",
      "prob_end": "20.00",
      "reward_name": "50000 Neon Gold"
    },
    {
      "loot_pack_reward_id": 2,
      "rarity_type": 1,
      "currency_id": 2,
      "currency_value": 50000,
      "shop_item_id": 0,
      "nft_id": 0,
      "prob_start": "20.00",
      "prob_end": "40.00",
      "reward_name": "50000 Space Elixir"
    }
  ]
*/

export default class PackOpeningScene extends Phaser.Scene {
  private _packInfo;
  private _packImage;
  private _openText;
  private _backButton;
  private _transitionVideo;
  private _itemSizer;
  private _packItems;
  private _bgMusic;
  private _openPackSound;
  private _itemRevealSound;
  private _packEndSound;
  private _scale;

  constructor() {
    super({
      key: 'PackOpeningScene',
    });
  }

  init(data: any) {
    this._packInfo = data;
  }

  preload() {
    // Packs
    LoadImageAssets(this, Pack_Assets);

    this.load.image('mining_return_home_button', Mining_Assets.mining_return_home_button);

    //const config = ConfigurationService.getInstance().getConfig();

    this.load.audio('open_pack_background_music', 'assets/packs/open_pack_background_music.mp3');
    this.load.audio('open_pack_whoosh', 'assets/packs/open_pack_whoosh.mp3');
    this.load.audio('open_pack_item_reveal', 'assets/packs/open_pack_item_reveal.wav');
    this.load.audio('open_pack_end_sound', 'assets/packs/open_pack_end_sound.wav');
  }

  create() {
    // Get Scale
    this._scale = ScaleService.getInstance().getScale(contentWidth, contentHeight);

    this._packItems = [];

    // Add sounds
    this._bgMusic = this.sound.add('open_pack_background_music', { loop: true });
    this._openPackSound = this.sound.add('open_pack_whoosh', { loop: false });
    this._itemRevealSound = this.sound.add('open_pack_item_reveal', { loop: false });
    this._packEndSound = this.sound.add('open_pack_end_sound', { loop: false });

    this._bgMusic.play();

    // Background
    this.add.rectangle(this.sys.canvas.width / 2, this.sys.canvas.height / 2, this.sys.canvas.width, this.sys.canvas.height, 0x000000, 1);

    // Pack Image
    this._packImage = this.add
      .image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + this.applyScale(packOffset), this.getPackImageKey())
      .setScale(this._scale * packScale)
      .setAlpha(0);
    TweenHelper.fadeElement(this, this._packImage, undefined, undefined, 2000, 1000);

    // Open Text
    this._openText = this.add
      .bitmapText(this.sys.canvas.width / 2, this._packImage.getBottomLeft().y + this.applyScale(openTextOffset), 'cc_outline', 'OPEN', 24, 1)
      .setOrigin(0.5, 0.5)
      .setAlpha(0);

    this.time.addEvent({
      delay: 1000,
      loop: false,
      callback: () => {
        TweenHelper.flashElement(this, this._openText, true, 'none', 1000, 1000); //, 1000);
      },
    });

    this._packImage.setInteractive().on('pointerdown', this.packOpenClick, this);
    this._openText.setInteractive().on('pointerdown', this.packOpenClick, this);
  }

  private getPackImageKey() {
    return RewardTypes[this._packInfo.packType];
  }

  packOpenClick(this, pointer) {
    // Play Transition Video
    this.playTransitionVideo(this);
  }

  private playTransitionVideo(this) {
    var video = document.getElementById('pack-opening-vid');

    if (!video) {
      console.log('Video not found!');
      self.removePackScreen(this);
      return;
    }

    var self = this;
    video.hidden = false;
    video.play();
    this._openPackSound.play();

    video.onended = () => {
      video.hidden = true;
      self.removePackScreen(this);
    };
  }

  private removePackScreen(this) {
    this._packImage.setAlpha(0);
    this._packImage.disableInteractive();
    // this._packImage.destroy();
    // this._packImage = undefined;

    this._openText.setAlpha(0);
    this._openText.destroy();
    this._openText = undefined;

    // Reveal Contents
    this.revealPackContents(this);
  }

  private revealPackContents(this) {
    // Sizer for pack items
    this._itemSizer = this.rexUI.add.fixWidthSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: this.sys.canvas.width,
      space: { left: 0, right: 0, top: 0, bottom: 0, item: 0, line: 0 },
      align: 2,
    });

    this.add.existing(this._itemSizer);

    // Add and reveal first item
    this.revealFirstItem(this);
  }

  private revealFirstItem(this) {
    // Create Item
    const firstItem = new PackItemComponent(this, 0, 0, this._packInfo.loot_pack_info[0], true, this._scale);

    this._itemSizer.add(this.add.existing(firstItem), {
      key: 'first_item',
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    });

    this._packItems.push(firstItem);

    this._itemSizer.layout();
  }

  public revealAdditionalItems(this) {
    if (this._packItems.length === this._packInfo.loot_pack_info.length) {
      this.postRevealAnimation(this);
      return;
    }

    this.revealNextItem(this);
  }

  private scaleUpItems(this) {
    // Grow existing items to ~1.5x scale
    var tween = this.tweens
      .add({
        targets: this._itemSizer,
        scale: 1.5,
        ease: 'Linear',
        duration: 60,
        yoyo: true,
      })
      .on(
        'complete',
        function () {
          // Add new item at 1.5x scale
          setTimeout(() => {
            this.revealAdditionalItems();
          }, 1000);
        },
        this,
      );
  }

  private revealNextItem(this) {
    // Create Item
    const nextItem = new PackItemComponent(this, 0, 0, this._packInfo.loot_pack_info[this._packItems.length], false, this._scale);

    this._itemSizer.add(this.add.existing(nextItem), {
      key: `item_${this._packItems.length + 1}`,
      padding: { left: this.applyScale(30), right: 0, top: 0, bottom: 0 },
    });

    this._packItems.push(nextItem);
    this._itemSizer.layout();

    this.scaleUpItems(this);
  }

  private postRevealAnimation(this) {
    // Animation Steps:
    // -Slowly slide items down ~25px - DONE
    // -Rotate items -5 degrees - DONE
    // -Quickly slide items up ~300px - DONE
    // -Add Back button on the way up - DONE
    // -Bounce items back to OG position - DONE
    // -Add 'glimmer' on the way down to OG position
    // -Repeat glimmer
    this._packEndSound.play();
    this.slideDownStart(this);
  }

  private slideDownStart(this) {
    this.tweens
      .add({
        targets: this._packItems,
        props: {
          y: {
            value: `+=${this.applyScale(25)}`,
            ease: 'Quad',
            duration: 300,
            yoyo: false,
          },
        },
      })
      .on(
        'complete',
        function () {
          // Rotate items after slide down
          this.rotateItems(this);
        },
        this,
      );
  }

  private slideUp(this) {
    this.tweens
      .add({
        targets: this._packItems,
        delay: 600,
        ease: 'Quad',
        duration: 50,
        yoyo: false,
        y: `-=${this.applyScale(300)}`,
      })
      .on(
        'complete',
        function () {
          // Add back button and slide back down after slide up
          this.addBackButton(this);
          this.slideDownFinish(this);
        },
        this,
      );
  }

  private slideDownFinish(this) {
    this.tweens
      .add({
        targets: this._packItems.concat(this._backButton),
        ease: 'Quad',
        duration: 50,
        yoyo: false,
        y: `+=${this.applyScale(275)}`,
      })
      .on(
        'complete',
        function () {
          this.addGlimmer(this);
        },
        this,
      );
  }

  private rotateItems(this, rotationDegrees, duration) {
    this.tweens.add({
      targets: this._packItems,
      angle: -1,
      ease: 'Quad',
      duration: 300,
      yoyo: true,
      hold: 200,
    });

    this.slideUp(this);
  }

  private addBackButton(this) {
    //mining_return_home_button
    this._backButton = new Button(this, this.sys.canvas.width / 2, this._packItems[0].y + this.applyScale(350), 'mining_return_home_button', false);
    this._backButton.onClick().subscribe(pointer => {
      const userData = UserService.getInstance().getUserDataSync();
      this._bgMusic.stop();
      this.scene.start('BattlePassScene', {
        userData: userData,
      });
    });

    this.add.existing(this._backButton);
  }

  private addGlimmer(this) {
    this._packItems.forEach(pi => {
      pi.addGlimmer(this);
    });

    // Button Glimmer
    const numButtonStars = 2;

    for (let i = 0; i < numButtonStars; i++) {
      console.log(this._backButton.getTopLeft().x);
      const x = Phaser.Math.Between(this._backButton.getTopLeft().x, this._backButton.getTopRight().x);
      const y = Phaser.Math.Between(this._backButton.getTopLeft().y, this._backButton.getBottomLeft().y);
      this.addStar(this, x, y);
    }
  }

  private addStar(this, x, y) {
    console.log('I got hit.');
    let maxRad = Phaser.Math.Between(20, 50);

    var starBase = this.add.star(x, y, 8, maxRad / 5, maxRad, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starBase.setAlpha(0.5);

    var starMiddle = this.add.star(x, y, 8, (maxRad * 0.6) / 5, maxRad * 0.6, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starMiddle.setAlpha(0.5);

    var starTop = this.add.star(x, y, 8, (maxRad * 0.2) / 5, maxRad * 0.2, 0xffffff); // x, y, points, innerRadius, outerRadius, fillColor
    starTop.setAlpha(0.5);

    starBase.setDepth(0);

    this.tweens.add({
      targets: [starBase, starMiddle, starTop],
      scale: 0.1,
      duration: 1500,
      ease: 'Quad',
      yoyo: true,
      repeat: -1,
    });
  }

  playPackItemRevealSound() {
    this._itemRevealSound.play();
  }

  applyScale(value: number) {
    return value * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Pack_Assets));
    UnloadSounds(this, ['open_pack_background_music', 'open_pack_whoosh', 'open_pack_item_reveal', 'open_pack_end_sound']);
  }
}
