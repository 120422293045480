import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '~/components/buttons/Button';
import { GameState } from '../../../../API/BattleRoyale/src/types/IBattleRoyaleState';
import TimerProgressBarComponent from '~/components/missions/timerProgressBarComponent';
import { getNextBattleRoyaleCountdown } from '~/utils/TimeUtils';

// Pane Details
const paneHeight = 184;
const paneWidth = 258;
const paneRadius = 26;
const paneColor = 0x053165;
const paneAlpha = 0.9;

export default class BattleRoyaleCardPane extends OverlapSizer {
  private _scale: number;
  private _scene;
  private _roomData;
  private _countDownProgressBar;
  private _battleRoyaleText;
  private _readyUpButton;
  private _internalTimer;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };

    super(scene, x, y, conf);

    this._scene = scene;
    this._scale = paneScale;
    this._roomData = data;
    this.createComponent(scene, x, y, 0);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(paneWidth), // width
      scene.applyScale(paneHeight), // height
      scene.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Panel
    var panel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Timer Progress Bar
    this._countDownProgressBar = new TimerProgressBarComponent(scene, 0, 0, this._scale, getNextBattleRoyaleCountdown());
    this._countDownProgressBar.setVisible(false);

    panel.add(this._countDownProgressBar, {
      key: 'progress_bar',
      align: 'left-top',
      offsetX: this.applyScale(24),
      offsetY: this.applyScale(-16),
      expand: false,
    });

    // BattleRoyale Text
    this._battleRoyaleText = this.scene.add.bitmapText(0, 0, 'cc_outline', '', scene.applyScale(18), 1);

    panel.add(this._battleRoyaleText, {
      key: 'battleroyale-text',
      align: 'left-top',
      offsetX: this.applyScale(24),
      offsetY: this.applyScale(40),
      expand: true,
    });

    // Ready up Button
    this._readyUpButton = new Button(scene, 0, 0, 'hud_battle_royale_blue_ready_up_button', false).setScale(this._scale);

    this._readyUpButton.onClick().subscribe(pointer => {
      this._scene.startBattleRoyaleScene();
    });

    panel.add(scene.add.existing(this._readyUpButton), {
      key: 'ready-up-button',
      align: 'left-top',
      offsetX: this.applyScale(17),
      offsetY: this.applyScale(102),
      expand: false,
    });

    this.add(panel, {
      key: 'panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  public updateCard(roomData) {
    var playerHasJoined = roomData.isParticipant;
    var cardText = '';
    var cardButtonTexture = 'hud_battle_royale_blue_view_button';

    switch (roomData.gameState) {
      case GameState.PreGame:
        this._countDownProgressBar.setVisible(true);
        this.startCountDownUI(getNextBattleRoyaleCountdown());
        //cardText = "Battle Royale is coming\n up shortly..."
        cardText = 'Battle Royale Game Status\nPre Game';
        cardButtonTexture = playerHasJoined ? 'hud_battle_royale_blue_view_button' : 'hud_battle_royale_blue_ready_up_button';
        break;
      case GameState.BattleRoyale:
      case GameState.BRIntermission:
        cardText = 'Battle Royale Game Status\n Active';
        //cardText = "Battle Royale is\nunderway..."
        break;
      case GameState.Failed:
        //cardText = "Battle Royale failed\nto start"
        cardText = 'Battle Royale Game Status\nFailed to start';
        break;
      case GameState.GameOver:
        cardText = 'Battle Royale Game Status\nPost Game';
        break;
    }

    this._battleRoyaleText.setText(cardText);
    this._readyUpButton.setTexture(cardButtonTexture);
  }

  private startCountDownUI(countDown: number) {
    // Implements Countdown
    var remainingSeconds = countDown;

    // Resets Countdown Bar Values
    this._countDownProgressBar.roundTimerReset(this, remainingSeconds);

    clearInterval(this._internalTimer);
    this._internalTimer = setInterval(() => {
      //var _timeRemainingInterval = setInterval(() => {
      try {
        if (remainingSeconds <= 0) return clearInterval(this._internalTimer);
        remainingSeconds--;
        this._countDownProgressBar.updateDisplay(this, remainingSeconds);
      } catch (error) {
        return clearInterval(this._internalTimer);
      }
    }, 1000);
  }

  public stopTimer() {
    clearInterval(this._internalTimer);
  }
}

Phaser.GameObjects.GameObjectFactory.register('hudBattleRoyaleCardPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new BattleRoyaleCardPane(this.scene, x, y));
});
