import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import HelpOptionComponent from '~/components/helpDesk/helpOptionComponent';
import HelpDeskService from '~/services/HelpDeskService';

const labels = {
  title: 'Help Desk',
  litepapers: 'Litepapers',
};

const imageKeys = {
  disconnectButton: 'disconnect_button',
  kickButton: 'kick_button',
};

const mainHelpOptions = [
  {
    name: 'help_channel',
    type: 'main',
    target_url: 'https://starwolvez.notion.site/Partner-Projects-757feec064be49a3bb8e148df0367a24',
    bg_image_key: 'help_channel_image',
    enabled: true,
  },
  {
    name: 'interplanetary',
    type: 'main',
    target_url: 'https://starwolvez.notion.site/Partner-Projects-757feec064be49a3bb8e148df0367a24',
    bg_image_key: 'inter_planetary_image',
    enabled: true,
  },
];

const defaultVertOffset = 10;
const rightOptionOffset = 20;

export default class HelpContentPane extends ScrollablePanel {
  private _sizer;
  private _helpOptions;
  private _helpTypes;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          x: x,
          y: y,
          width: scene.applyScale(570),
          height: scene.applyScale(642),
          scrollMode: 0,
          //background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, COLOR_PRIMARY),
          panel: {
            child: scene.rexUI.add.fixWidthSizer({
              space: {
                left: scene.applyScale(21),
                right: scene.applyScale(21),
                top: 0,
                bottom: scene.applyScale(21),
                item: scene.applyScale(10),
                line: 0,
              },
            }),

            mask: {
              padding: 1,
            },
          },

          mouseWheelScroller: {
            focus: true,
            speed: 0.2,
          },

          space: {
            left: 0,
            right: 0,
            top: scene.applyScale(21),
            bottom: 0,
            panel: 0,
          },
        };

    super(scene, conf);

    this._scale = paneScale;
    this._helpOptions = HelpDeskService.getInstance().getHelpOptions();
    this._helpTypes = HelpDeskService.getInstance().getHelpTypes();

    this.layout();
    this.createComponent();
  }

  createComponent() {
    this._sizer = this.getElement('panel');
    const scene = this.scene;

    // Title
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.title, scene.applyScale(20)), {
        key: 'title_label',
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    // TODO: Add main help tiles
    mainHelpOptions.forEach(helpOption => {
      this._sizer.add(scene.add.existing(new HelpOptionComponent(scene, 0, 0, helpOption, this._scale)), {
        key: helpOption.name,
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: scene.applyScale(defaultVertOffset) },
      });
    });

    // Add each help type and associated help options (with dividers)
    this._helpTypes.forEach(helpType => {
      // TODO: Add divider
      this._sizer.add(scene.add.image(0, 0, 'help_pane_divider'), {
        key: `${helpType}_divider`,
        padding: { left: 0, top: scene.applyScale(defaultVertOffset * 2), right: 0, bottom: scene.applyScale(defaultVertOffset * 1.5) },
      });

      // Add type title
      this._sizer
        .add(scene.add.bitmapText(0, 0, 'cc_outline', helpType.displayName, scene.applyScale(20)), {
          key: helpType.key,
          padding: { left: 0, top: scene.applyScale(defaultVertOffset * 2), right: 0, bottom: 0 },
        })
        .addNewLine();

      // Add help options
      this._helpOptions
        .filter(opt => opt.type === helpType.key)
        .forEach(helpOption => {
          this._sizer.add(scene.add.existing(new HelpOptionComponent(scene, 0, 0, helpOption, this._scale)), {
            key: helpOption.name,
            padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: scene.applyScale(defaultVertOffset) },
          });
        });
    });

    // Layout Sizer
    this.layout();
  }
}

Phaser.GameObjects.GameObjectFactory.register('helpContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new HelpContentPane(this.scene, x, y));
});
