import Phaser from 'phaser';
import { OverlapSizer, Tabs } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import TroopService from '~/services/TroopService';
import RaidUnitComponent from './RaidUnitComponent';

const panelWidth = 264;
const panelHeight = 270;
const panelColor = 0x350926;
const panelAlpha = 0.9;
const panelRadius = 8;

const tabHeight = 27;
const troopsTabWidth = 71;
const machinesTabWidth = 90;
const tabRadius = 10;
const activeTabColor = 0x6262de;
const inactiveTabColor = 0x32324d;
const tabFontSize = 18;

export default class RaidUnitsInfoComponent extends Tabs {
  private _scale: number;
  private _table: any;
  private _selectedTabIndex: number;
  private _troopData: any;
  private _machineData: any;
  private _baseRaidAttackInfo: any;

  constructor(scene: Phaser.Scene, x: number, y: number, baseRaidAttackInfo: any, paneScale: number, config?: any) {
    var createButton = function (scene, text, width, color) {
      const btn = scene.rexUI.add.label({
        background: scene.rexUI.add.roundRectangle(
          0,
          0,
          scene.applyScale(width),
          scene.applyScale(tabHeight),
          { tl: scene.applyScale(tabRadius), tr: scene.applyScale(tabRadius) },
          color,
        ),
        text: scene.add.bitmapText(0, 0, 'cc_outline', text, scene.applyScale(tabFontSize), 0),
        space: {
          left: scene.applyScale(10),
          right: scene.applyScale(10),
          top: scene.applyScale(10),
          bottom: scene.applyScale(10),
        },
      });

      btn.setInteractive({ cursor: 'pointer' });

      return btn;
    };

    let conf = config
      ? config
      : {
          x: x,
          y: y,

          background: scene.rexUI.add.roundRectangle(0, 0, scene.applyScale(10), scene.applyScale(10), 0, 0x333333).setAlpha(0),
          panel: scene.rexUI.add
            .fixWidthSizer(0, 0, {
              width: scene.applyScale(panelWidth),
              height: scene.applyScale(panelHeight),
              space: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                item: 0,
                line: 0,
              },
              align: 0,
            })
            .addBackground(
              scene.rexUI.add.roundRectangle(
                0,
                0,
                scene.applyScale(panelWidth),
                scene.applyScale(panelHeight),
                scene.applyScale(panelRadius),
                panelColor,
                panelAlpha,
              ),
            ),

          topButtons: [
            // Troops Tab
            createButton(scene, 'Troops', troopsTabWidth, activeTabColor),
            // Machines Tab
            createButton(scene, 'Machines', machinesTabWidth, inactiveTabColor),
          ],

          space: {
            left: scene.applyScale(20),
            right: scene.applyScale(20),
            top: scene.applyScale(20),
            bottom: scene.applyScale(20),
            topButtonsOffset: scene.applyScale(20),
            topButton: scene.applyScale(10),
          },
        };

    super(scene, conf);

    this._baseRaidAttackInfo = baseRaidAttackInfo;
    this._scale = paneScale;
    this._selectedTabIndex = 0;
    this._troopData = baseRaidAttackInfo.base_raid_execution[0]?.attacker_squadtroops;
    this._machineData = baseRaidAttackInfo.base_raid_execution[0]?.defender_machines;
    this.enrichData();
    this.createComponent(scene, x, y);
  }

  createComponent(scene: Phaser.Scene, x: number, y: number) {
    this.addButtonEvents(scene, x, y);

    this.createTable(scene, this.getTableData());
    if (this._table) {
      this.getElement('panel')?.add(this._table);
    }

    this.layout();
  }

  private createTable(scene, data) {
    if (!data || data.length === 0) return;

    var table = scene.rexUI.add.gridTable({
      width: scene.applyScale(panelWidth),
      height: scene.applyScale(70 * data.length > panelHeight ? panelHeight : 70 * data.length - 1),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: scene.applyScale(panelWidth),
        cellHeight: scene.applyScale(70),
        columns: 1,
        clamplTableOXY: true,
        mask: {
          padding: 2,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      space: {
        table: {},
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        return scene.add.existing(new RaidUnitComponent(scene, 0, 0, item, scene.getScale()));
      },
    });

    this._table = table.layout();
  }

  private addButtonEvents(scene: Phaser.Scene, x: number, y: number) {
    this.on('button.click', (button, groupName, index, pointer) => {
      if (this._selectedTabIndex === index) return;

      // Set selected tab
      this._selectedTabIndex = index;

      this._troopData = this._baseRaidAttackInfo.base_raid_execution[0]?.attacker_squadtroops;
      this._machineData = this._baseRaidAttackInfo.base_raid_execution[0]?.defender_machines;
      this.enrichData();
      this.refreshPanel(this.scene);
    });

    this.on('button.over', (button, groupName, index, pointer) => {
      if (this._selectedTabIndex === index) return;
      button.setAlpha(0.8);
    });

    this.on('button.out', (button, groupName, index, pointer) => {
      button.setAlpha(1);
    });
  }

  private refreshPanel(scene) {
    // Set tab colors
    this.forEachTopButton((button, index, buttons) => {
      if (index === this._selectedTabIndex) {
        button.getElement('background').setFillStyle(activeTabColor, 1);
      } else {
        button.getElement('background').setFillStyle(inactiveTabColor, 1);
      }
    }, this);

    // Clear panel
    this.getElement('panel')?.remove(this._table, true);
    this._table = undefined;

    // Add table
    this.createTable(scene, this.getTableData());

    if (this._table) {
      this.getElement('panel')?.add(this._table);
    }

    this.getElement('panel')?.layout();
  }

  public updateData(baseRaidAttackInfo: any) {
    this._baseRaidAttackInfo = baseRaidAttackInfo;
    this._troopData = baseRaidAttackInfo.base_raid_execution[0]?.attacker_squadtroops;
    this._machineData = baseRaidAttackInfo.base_raid_execution[0]?.defender_machines;

    this.enrichData();
    this.refreshPanel(this.scene);
  }

  private enrichData() {
    const enrichedMachineData = this._machineData.map(mach => {
      const fullData = this._baseRaidAttackInfo.defender_machines.find(def_mach => def_mach.user_machine_id === mach.user_machine_id);
      const mergedData = Object.assign({}, fullData, mach);
      return mergedData;
    });

    const enrichedTroopData = this._troopData
      .filter(tr => !!tr.machine_unit_id)
      .map(troop => {
        return this.enrichTroopData(troop);
      });

    this._machineData = enrichedMachineData;
    this._troopData = enrichedTroopData;
  }

  private enrichTroopData(troop) {
    troop.display_name = TroopService.getInstance().getTroopDisplayName(troop.machine_unit_id);
    return troop;
  }

  private getTableData() {
    return this._selectedTabIndex === 0 ? this._troopData : this._machineData;
  }
}

Phaser.GameObjects.GameObjectFactory.register('raidUnitsInfoComponent', function (x: number, y: number, config: any) {
  // @ts-ignore
  return this.displayList.add(new RaidUnitsInfoComponent(this.scene, x, y, config));
});
