import Phaser from 'phaser';
import Button from '../../../components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import util from 'util';
import HelpdeskContentPane from './helpdeskContentPane';
import { HelpDesk_Assets } from '~/utils/AssetLoader';
import HelpDeskService from '~/services/HelpDeskService';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 468;
const modalWidth = 814;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

const spineWidth = 248;
const spineColor = 0xe6e6ff;
const spineAlpha = 0.05;

export default class HelpdeskModalScene extends Phaser.Scene {
  private _cancelButton;
  private _helpdeskContentPane;
  private _scale: number;
  private _helpTypes;
  private _spineTable;

  constructor() {
    super({
      key: 'HelpdeskModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, HelpDesk_Assets);
  }

  create() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this._helpTypes = HelpDeskService.getInstance().getHelpTypes();

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    const spineBackground = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(spineWidth), // width
      this.applyScale(modalHeight), // height
      {
        tl: this.applyScale(modalRadius),
        tr: 0,
        bl: this.applyScale(modalRadius),
        br: 0,
      }, // radius
      spineColor, // fillColor
      spineAlpha, // alpha
    );

    // Title text
    const titleText = this.add.image(0, 0, 'helpdesk_text').setOrigin(0).setScale(this._scale);

    // Add spine here
    this._spineTable = this.createSpineTable(this, this._helpTypes, 0, 0).setOrigin(0.5, 0.5);

    this._cancelButton = new Button(this.scene.scene, 0, 0, 'cancel_btn_purple', true, 1.04).setScale(this._scale);
    this.add.existing(this._cancelButton);

    this._cancelButton.onClick().subscribe(pointer => {
      this.cancelButtonClickHandler(pointer);
    });

    this._helpdeskContentPane = new HelpdeskContentPane(this, 0, 0, this._helpTypes[0], this._scale);
    this.add.existing(this._helpdeskContentPane);

    sizer.add(background, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });
    sizer.add(spineBackground, {
      key: 'spineBackground',
      align: 'center',
      offsetX: this.applyScale(-(modalWidth / 2 - spineWidth / 2)),
      offsetY: 0,
      expand: false,
    });
    sizer.add(titleText, {
      key: 'titleText',
      align: 'left-top',
      offsetX: this.applyScale(17),
      offsetY: this.applyScale(31),
      expand: false,
    });
    sizer.add(this._spineTable, {
      key: 'table',
      align: 'left-top',
      offsetX: this.applyScale(15),
      offsetY: this.applyScale(60),
      expand: false,
    });
    sizer.add(this._cancelButton, {
      key: 'confirmButton',
      align: 'left-bottom',
      offsetX: this.applyScale(14),
      offsetY: this.applyScale(-18),
      expand: false,
    });
    sizer.add(this._helpdeskContentPane, {
      key: 'helpdesk-content-pane',
      align: 'left-top',
      offsetX: this.applyScale(532),
      offsetY: this.applyScale(263),
      expand: false,
    });

    sizer.layout();

    this.setIsSelected(this._helpTypes[0]);

    sizer.onClickOutside(() => {
      this.unloadAssets();
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  destroy() {
    this._helpTypes = undefined;
    this._helpdeskContentPane = undefined;
  }

  private createSpineTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(220),
      height: this.applyScale(450),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: this.applyScale(220),
        cellHeight: this.applyScale(32),
        columns: 1,
        clamplTableOXY: false,
        reuseCellContainer: true,
        interactive: true,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        // Template
        let rectConfig = {
          maskType: 'roundRectangle',
          radius: 0,
        };

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(220),
            space: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .setInteractive({ useHandCursor: true })
          .add(
            scene.add
              .rexRoundRectangle(
                0, // x
                0, // y
                scene.applyScale(220), // width
                scene.applyScale(32), // height
                scene.applyScale(6), // radius
                0xe6e6ff, // fillColor
                0.05, // alpha
              )
              .setAlpha(item.isSelected ? 1 : 0),
            {
              key: 'menu_option_bg',
              align: 'center',
              offsetX: 0,
              offsetY: 0,
              expand: false,
            },
          )
          .add(scene.add.bitmapText(0, 0, 'cc_outline', item.displayName, scene.applyScale(16), 0).setAlpha(item.isSelected ? 1 : 0.4), {
            key: 'menu_option_text',
            align: 'left',
            offsetX: scene.applyScale(10),
            offsetY: 0,
            expand: false,
          });

        sizer.setChildrenInteractive({
          click: { mode: 'release', clickInterval: 100 },
          over: true,
          tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
          press: { time: 251, threshold: 9 },
          swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
          inputEventPrefix: 'child.',
        });

        sizer.on('child.over', (child, pointer, event) => {
          sizer.getElement('menu_option_text').setAlpha(1);
        });

        sizer.on('child.out', (child, pointer, event) => {
          if (!item.isSelected) {
            sizer.getElement('menu_option_text').setAlpha(0.4);
          }
        });

        sizer.on('child.click', (child, pointer, event) => {
          scene.setIsSelected(item);
        });

        return sizer;
      },
    });

    table.setItems(data).scrollToTop();
    //table.getElement('table').on('')
    return table;
  }

  setIsSelected(item: any) {
    if (item.isSelected === true) return;

    this._helpTypes = this._helpTypes.map(mt => {
      mt.isSelected = mt.displayName === item.displayName ? true : false;
      return mt;
    });

    this._spineTable.setItems(this._helpTypes);
    this._helpdeskContentPane.setCategory(item);
  }

  private cancelButtonClickHandler(this, pointer) {
    this.unloadAssets();
    this.scene.resume('HomebaseScene');
    this.scene.resume('HudScene');
    this.scene.stop();
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(HelpDesk_Assets));
  }
}
