import Phaser from 'phaser';

import MiningService from '~/services/MiningService';
import ScaleService from '~/services/ScaleService';
import { Mining_Modal_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import AsteroidContentPane from './asteroidContentPane';

const modalWidth = 1008;
const modalHeight = 513;
const modalRadius = 16;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

export default class MiningModalScene extends Phaser.Scene {
  private _scale;
  private _sizer;
  private _asteroids;

  init(data: any) {
    this._asteroids = data.asteroids;
  }

  constructor() {
    super({
      key: 'MiningModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, Mining_Modal_Scene_Assets);
  }

  create() {
    this.input.topOnly = false;

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);

    // @ts-ignore
    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      height: this.applyScale(modalHeight),
      space: this.applyScale(40),
      sizerEvents: true,
    });

    // @ts-ignore
    // Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    this._sizer.add(background, {
      key: 'mining_mission_availability_bg',
      align: 'center-bottom',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    const title = this.add.bitmapText(0, 0, 'cc_outline', 'Currently Available Mining Missions', this.applyScale(28), 0).setOrigin(0);

    this._sizer.add(title, {
      key: 'mining_mission_title',
      align: 'left-top',
      padding: {
        top: this.applyScale(23),
        left: this.applyScale(23),
      },
      expand: false,
    });

    this._sizer.layout();

    const asteroidContentPane = new AsteroidContentPane(this, 0, 0, this._asteroids, this._scale);

    this.add.existing(asteroidContentPane);

    this._sizer
      .add(asteroidContentPane, {
        key: 'asteroid_content_pane',
        align: 'center-top',
        offsetX: 0,
        offsetY: this.applyScale(68),
        expand: false,
      })
      .layout();

    this._sizer.onClickOutside(() => {
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.unloadAssets();
      this.scene.stop();
    });
  }

  getScale() {
    return this._scale;
  }

  applyScale(length: number) {
    return length * this._scale;
  }

  destroy() {
    this.unloadAssets();
    this.scene.stop();
  }

  public unloadAssets() {
    UnloadImages(this, Object.keys(Mining_Modal_Scene_Assets));
  }
}
