import Button from "../buttons/Button"
import HomebaseMachine from "./homebaseMachine"

const machineTypes = {
    mainframe: "mainframe",
    space_elixir_storage: "space_elixir_storage",
    space_elixir_mine: "space_elixir_mine",
    neon_gold_storage: "neon_gold_storage",
    neon_gold_mine: "neon_gold_mine",
    star_lab: "star_laboratory",
}

export default class BaseRaidMachine extends HomebaseMachine {
    protected getActions(scene: Phaser.Scene) {
        return []
    }

    protected createDialog(scene, x, y) {
        this._dialog = scene.rexUI.add
            .dialog({
                x: x,
                y: y,
                //width: 200,

                background: scene.rexUI.add
                    .roundRectangle(0, 0, 100, 100, 16, 0x201425)
                    .setAlpha(0.7)
                    .setStrokeStyle(2, 0x000000, 0.9)
                    .setInteractive(),

                title: this.createTitle(scene, this.getMachineTitle()),

                //content: this.createContent(scene),//this.createTitle(scene, `Level: ${this._machine.level}\n\nYield: N/A\n\nStorage: N/A`, 18, 0.4),

                //description: this.createTitle(scene, 'Yield: N/A\nStorage: N/A', 18, 0.4),

                actions: this.getActions(
                    scene,
                    this._machine.key === machineTypes.star_lab,
                ),

                // actions: [
                //     this.createButton(scene, 'Action0'),
                //     this.createButton(scene, 'Action1')
                // ],

                space: {
                    left: this._machineInfoSpacing.left,
                    right: this._machineInfoSpacing.right,
                    top: this._machineInfoSpacing.top,
                    bottom: 0,

                    title: 10,
                    // titleLeft: 30,
                    //titleBottom: 20,
                    //content: 20,
                    //contentLeft: 10,
                    //description: 20,
                    //descriptionLeft: 10,
                    // descriptionRight: 20,
                    //descriptionBottom: 20,
                    //choices: 20,
                    actions: 10,

                    //toolbarItem: 5,
                    //choice: 15,
                    action: 10,
                },

                expand: {
                    title: false,
                    // content: false,
                    // description: true,
                    choices: false,
                    actions: false,
                },

                align: {
                    title: "center",
                    content: "left",
                    description: "left",
                    choices: "center",
                    actions: "center", // 'center'|'left'|'right'
                },

                click: {
                    mode: "release",
                },
            })
            .setOrigin(0.5, 1)
            .setDepth(1000)
            //.setDraggable('background')   // Draggable-background
            .layout()
            // .drawBounds(this.add.graphics(), 0xff0000)
            .popUp(200)

        var tween = scene.tweens.add({
            targets: this._dialog,
            scaleX: 1,
            scaleY: 1,
            ease: "Bounce", // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 200,
            repeat: 0, // -1: infinity
            yoyo: false,
        })

        scene.input.on(
            "pointerdown",
            function (pointer) {
                if (this._dialog && !this._dialog.isInTouching(pointer)) {
                    this._dialog.scaleDownDestroy(100)
                    this._dialog = undefined
                }
            },
            this,
        )

        // var clickOutside = scene.plugins.get('rexClickOutsiden').add(dialog, {
        //     enable: true,
        //     mode: 1,
        //     clickInterval: 100
        // });

        // clickOutside.on('clickoutside', function (clickoutside, gameObject, pointer) {
        //     dialog.scaleDownDestroy(100);
        //     this._dialog = undefined;
        // }, scene);
    }
}
