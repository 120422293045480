import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import BattlePassItemRankComponent from './battlePassItemRankComponent';
import BattlePassItemImageComponent from './battlePassItemImageComponent';

const paneHeight = 257;
const paneWidth = 130;
const paneRadius = 7;
const borderWidth = 1;

// Item
const itemHeight = 146;
const itemWidth = 116;
const itemBackgroundColor = 0xb48dc7;
const itemAlpha = 40;

// Rank
const rankHeight = 48;
const rankWidth = 116;
const rankBackgroundColor = 0x3dff50;
const rankAlpha = 60;
const rankUnlockedBorderColor = 0x383d57;
const rankLockedBorderColor = 0xffffff;
const rankLockedBorderColorAlpha = 40;
const currentLevel = 51;

enum RewardType {
  XP = 1,
  Currency = 2,
  BronzePack = 3,
  ShopItem = 4,
  SilverPack = 5,
  GoldPack = 6,
  Machine = 7,
  RarePack = 8,
  LegendaryPack = 9,
  MythicPack = 10,
  EpicPack = 11,
  NFT = 12,
  MachineSkin = 13,
}

export default class BattlePassItemComponent extends OverlapSizer {
  private _level;
  private _battlepassXP;
  private _itemData;
  private _scale;

  // Item
  private _itemImageComponent;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._level = data.level;
    this._battlepassXP = data.battlepassXP;
    this._itemData = data.item;
    this._itemData.imageKey = this.getImageKey();
    this._itemData.rewardDescription = this.getRewardDescription();

    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Enables Interaction
    sizerPanel.setInteractive({ cursor: 'pointer' }).on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, pointer => {
      scene.scene.get('BattlePassScene').input.enabled = false;
      scene.scene.launch('BattlePassItemClaimScene', {
        rewardData: this._itemData,
        level: this._level,
        battlepassXP: this._battlepassXP,
      });
      scene.scene.pause();
    });

    // Item
    this._itemImageComponent = new BattlePassItemImageComponent(scene, 0, 0, data, scene.getScale());
    sizerPanel.add(this._itemImageComponent, {
      key: 'item-component',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Rank
    var itemRankComponent = new BattlePassItemRankComponent(scene, 0, 0, data, scene.getScale());
    sizerPanel.add(itemRankComponent, {
      key: 'rank-component',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(209),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }

  private getImageKey() {
    var imageKey = '';

    switch (this._itemData.reward_type) {
      case RewardType.XP:
        imageKey = 'xp_icon';
        break;
      case RewardType.Currency:
        switch (this._itemData.currency_name) {
          case 'neon_gold':
            imageKey = 'bp_neon_gold_icon';
            break;
          case 'space_elixir':
            imageKey = 'bp_space_elixir_icon';
            break;
          case 'stardust':
            imageKey = 'bp_stardust_icon';
            break;
          case 'dark_matter':
            imageKey = 'dark_matter_icon';
            break;
        }
        break;
      case RewardType.BronzePack:
        imageKey = 'bronze_pack_icon';
        break;
      case RewardType.ShopItem:
        switch (this._itemData.shop_item_name) {
          case 'BK-55 Chip':
            imageKey = 'bk55_icon';
            break;
          case 'Animation Chip':
            imageKey = 'nm8_icon';
            break;
          case 'Star Wolvez Hoodie':
            imageKey = 'sw_hoodie_icon';
            break;
          case 'FUR-69':
            imageKey = 'fur_chip_icon';
            break;
        }
        break;
      case RewardType.SilverPack:
        imageKey = 'silver_pack_icon';
        break;
      case RewardType.GoldPack:
        imageKey = 'gold_pack_icon';
        break;
      case RewardType.Machine:
        switch (this._itemData.machine_name) {
          case 'Battle Pass Blob Cosmetic':
            imageKey = 'blob_consmetic_icon';
            break;
          case 'Ice Storm Tracker Cosmetic':
            imageKey = 'ice_storm_tracker_cosmetic';
            break;
          case 'Fire Storm Tracker Cosmetic':
            imageKey = 'fire_storm_tracker_cosmetic';
            break;
          case 'Water Cooler Cosmetic':
            imageKey = 'water_cooler_cosmetic_icon';
            break;
          case 'A Piece of Home Cosmetic':
            imageKey = 'little_piece_of_home_cosmetic';
            break;
          case 'Star Wolvez Helmet Cosmetic':
            imageKey = 'star_wolvez_helmet_cosmetic';
            break;
        }
        break;
      case RewardType.RarePack:
        imageKey = 'rare_pack_icon';
        break;
      case RewardType.EpicPack:
        imageKey = 'epic_pack_icon';
        break;
      case RewardType.LegendaryPack:
        imageKey = 'legendary_pack_icon';
        break;
      case RewardType.MythicPack:
        imageKey = 'mythical_pack_icon';
        break;
      case RewardType.NFT:
        imageKey = '';
        break;
      case RewardType.MachineSkin:
        imageKey = 'gold_plasma_cannon_skin';
        break;
    }
    return imageKey;
  }

  private getRewardDescription() {
    var description = '';

    switch (this._itemData.reward_type) {
      case RewardType.XP:
        description = this._itemData.value.toLocaleString('en-us') + ' XP';
        break;
      case RewardType.Currency:
        switch (this._itemData.currency_name) {
          case 'neon_gold':
            description = this._itemData.value.toLocaleString('en-us') + ' NEON GOLD';
            break;
          case 'space_elixir':
            description = this._itemData.value.toLocaleString('en-us') + ' SPACE ELIXIR';
            break;
          case 'stardust':
            description = this._itemData.value.toLocaleString('en-us') + ' STARDUST';
            break;
          case 'dark_matter':
            description = this._itemData.value.toLocaleString('en-us') + ' DARK MATTER';
            break;
        }
        break;
      case RewardType.BronzePack:
        description = 'BRONZE PACK';
        break;
      case RewardType.ShopItem:
        switch (this._itemData.shop_item_name) {
          case 'BK-55 Chip':
            description = 'BK-55 CHIP';
            break;
          case 'Animation Chip':
            description = 'ANIMATION CHIP';
            break;
          case 'Star Wolvez Hoodie':
            description = 'STAR WOLVEZ HOODIE';
            break;
          case 'FUR-69':
            description = 'FUR-69 CHIP';
            break;
        }
        break;
      case RewardType.SilverPack:
        description = 'SILVER PACK';
        break;
      case RewardType.GoldPack:
        description = 'GOLD PACK';
        break;
      case RewardType.Machine:
        switch (this._itemData.machine_name) {
          case 'Battle Pass Blob Cosmetic':
            description = 'BATTLE PASS BLOB COSMETIC';
            break;
          case 'Ice Storm Tracker Cosmetic':
            description = 'ICE STORM TRACKER COSMETIC';
            break;
          case 'Fire Storm Tracker Cosmetic':
            description = 'FIRE STORM TRACKER COSMETIC';
            break;
          case 'Water Cooler Cosmetic':
            description = 'WATER COOLER COSMETIC';
            break;
          case 'A Piece of Home Cosmetic':
            description = 'A PIECE OF HOME COSMETIC';
            break;
          case 'Star Wolvez Helmet Cosmetic':
            description = 'STAR WOLVEZ HELMET COSMETIC';
            break;
        }
        break;
      case RewardType.RarePack:
        description = 'RARE PACK';
        break;
      case RewardType.EpicPack:
        description = 'EPIC PACK';
        break;
      case RewardType.LegendaryPack:
        description = 'LEGENDARY PACK';
        break;
      case RewardType.MythicPack:
        description = 'MYTHICAL PACK';
        break;
      case RewardType.NFT:
        description = '';
        break;
      case RewardType.MachineSkin:
        description = 'GOLD PLASMA CANNON SKIN';
        break;
    }
    return description;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassItemComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new BattlePassItemComponent(this.scene, x, y, data));
});
