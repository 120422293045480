import Phaser from 'phaser';
import Homebase_tiles from '../../public/tiles/homebase_tiles-export64_v2.png';
import { Leaderboard_Assets, Mining_Assets, Modal_Assets, Hud_Assets, Army_Assets, Shared_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets } from '~/utils/AssetManager';

const tileJSON = require('../tiles/homebase_map_64_blob_test.json');

export default class Preloader extends Phaser.Scene {
  constructor() {
    super('Preloader');
  }

  preload() {
    // Font
    this.load.bitmapFont('cc_outline', 'assets/fonts/cc_outline_0.png', 'assets/fonts/cc_outline.xml');

    // Map assets
    this.load.image('tiles', Homebase_tiles);
    this.load.tilemapTiledJSON('homebase_map', tileJSON);

    // Shared Assets
    const ignoreList = ['party_blob'];
    LoadImageAssets(this, Shared_Assets, ignoreList);

    // Loading Indicator Sprite
    this.load.spritesheet('party_blob', Shared_Assets.party_blob, {
      frameWidth: 180,
      frameHeight: 180,
      endFrame: 7,
    });

    // Modals
    this.load.image('mining_return_home_button', Mining_Assets.mining_return_home_button);
    this.load.image('leaderboard_icon', Leaderboard_Assets.leaderboard_icon);
    this.load.image('time_icon', Hud_Assets.time_icon);
    this.load.image('hp_icon', Hud_Assets.hp_icon);
    this.load.image('dps_icon', Hud_Assets.dps_icon);
    this.load.image('trophy_icon_small', Hud_Assets.trophy_icon_small);
    this.load.image('modal_close_button', Modal_Assets.modal_close_button);

    this.load.image('troops_ars', Army_Assets.troops_ars);
    this.load.image('troops_healer', Army_Assets.troops_healer);
    this.load.image('troops_mercenary', Army_Assets.troops_mercenary);
    this.load.image('troops_super_swole', Army_Assets.troops_super_swole);

    this.load.image('troops_ars_small', Army_Assets.troops_ars_small);
    this.load.image('troops_healer_small', Army_Assets.troops_healer_small);
    this.load.image('troops_mercenary_small', Army_Assets.troops_mercenary_small);
    this.load.image('troops_super_swole_small', Army_Assets.troops_super_swole_small);
  }

  create() {
    window.addEventListener(
      'resize',
      () => {
        var canvas = document.querySelector('canvas');
        if (canvas) {
          canvas.style.width = window.innerWidth + 'px';
          canvas.style.height = window.innerHeight + 'px';
          this.game.scale.resize(window.innerWidth, window.innerHeight);
        }
        if (this.scene.get('HudScene').scene.isActive()) {
          this.scene.get('HudScene').scene.restart();
        }
        if (this.scene.get('BaseRaidHudScene').scene.isActive()) {
          this.scene.get('BaseRaidHudScene').restartScene();
        }
      },
      false,
    );

    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.visibilityState === 'hidden') {
          if (this.scene.get('HudScene').scene.isActive()) {
            this.scene.get('HudScene').onTabbedOut();
          }

          if (this.scene.get('BossFightScene').scene.isActive()) {
            this.scene.get('BossFightScene').onTabbedOut();
          }

          if (this.scene.get('BattleRoyaleScene').scene.isActive()) {
            this.scene.get('BattleRoyaleScene').onTabbedOut();
          }
        } else {
          // Update Machines
          if (this.scene.get('HomebaseScene').scene.isActive() && !this.scene.get('HomebaseScene').isInBlobRoom()) {
            this.scene.get('HomebaseScene').updateMachines(true);
          }

          if (this.scene.get('BaseRaidHudScene').scene.isActive()) {
            this.scene.get('BaseRaidHudScene').restartScene();
          }

          if (this.scene.get('HudScene').scene.isActive()) {
            this.scene.get('HudScene').onTabbedIn();
          }

          if (this.scene.get('BossFightScene').scene.isActive()) {
            this.scene.get('BossFightScene').onTabbedIn();
          }

          if (this.scene.get('BattleRoyaleScene').scene.isActive()) {
            this.scene.get('BattleRoyaleScene').onTabbedIn();
          }
        }
      },
      false,
    );

    this.scene.stop('StartScene');
    this.scene.start('LoginScene');
    // this.scene.start('PackOpeningScene', {
    //   packType: 'rare_pack',
    //   loot_pack_info: [
    //     {
    //       loot_pack_reward_id: 1,
    //       rarity_type: 1,
    //       currency_id: 1,
    //       currency_value: 50000,
    //       shop_item_id: 0,
    //       nft_id: 0,
    //       prob_start: '0.00',
    //       prob_end: '20.00',
    //       reward_name: '50000 Neon Gold',
    //     },
    //     {
    //       loot_pack_reward_id: 2,
    //       rarity_type: 1,
    //       currency_id: 2,
    //       currency_value: 50000,
    //       shop_item_id: 0,
    //       nft_id: 0,
    //       prob_start: '20.00',
    //       prob_end: '40.00',
    //       reward_name: '50000 Space Elixir',
    //     },
    //   ],
    // });
  }
}
