import Phaser from 'phaser';
import { UnloadImages } from '~/utils/AssetManager';
import util from 'util';
import SquadService from '~/services/SquadService';

export default class NftPageLoaderScene extends Phaser.Scene {
  private _originatingScene;
  private _nfts;
  private _oldNfts;

  constructor() {
    super('NftPageLoaderScene');
  }

  init(data: any) {
    this._originatingScene = data.originating_scene;
    this._nfts = data.nfts;
    this._oldNfts = data.old_nfts;
  }

  preload() {
    this._nfts.forEach(visibleNft => {
      this.load.image(visibleNft.image_key, visibleNft.image_thumbnail_url);
    });
  }

  create() {
    // Unload images that aren't in squad or new data
    const nftsToUnload = this._oldNfts
      .filter(oldNft => !this._nfts.map(nft => nft.id || nft.marketplace_nft_id).includes(oldNft.id || oldNft.marketplace_nft_id))
      .filter(
        oldNft =>
          !SquadService.getInstance()
            .getSquadSync()
            .map(nft => nft.id || nft.marketplace_nft_id)
            .includes(oldNft.id || oldNft.marketplace_nft_id),
      );

    UnloadImages(
      this.scene.get(this._originatingScene),
      nftsToUnload.map(nftToUnload => nftToUnload.image_key),
    );

    this.scene.get(this._originatingScene).onPageLoaded();
    this.scene.stop();
  }
}
