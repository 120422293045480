import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import util from 'util';
import Button from '~/components/buttons/Button';
import RoundImageButton from '~/components/buttons/RoundImageButton';
import ConfigurationService from '~/services/ConfigurationService';
import SquadService from '~/services/SquadService';
import UserService from '~/services/UserService';

const labels = {
  title: 'Alliance Settings',
  allianceName: 'Alliance Name',
  allianceNameDesc: 'Coming soon...',
  allianceMembers: 'Alliance Members',
  allianceMembersDesc: 'Coming soon...',
};

const imageKeys = {
  disconnectButton: 'disconnect_button',
  kickButton: 'kick_button',
};

const defaultVertOffset = 10;
const descTextVertOffset = -1;
const memberSectionVertOffset = 30;

const inputTextTopPadding = 5;
const inputTextFontSize = 14;
const inputTextWidth = 518;
const inputTextHeight = 36;

export default class AllianceContentPane extends ScrollablePanel {
  private _userData;
  private _sizer;
  private _allianceNameTextBox;
  private _allianceMembers;
  private _scale;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, config?: any) {
    const conf = config
      ? config
      : {
          x: x,
          y: y,
          width: scene.applyScale(566),
          height: scene.applyScale(642),
          scrollMode: 0,
          //background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, COLOR_PRIMARY),
          panel: {
            child: scene.rexUI.add.fixWidthSizer({
              space: {
                left: scene.applyScale(21),
                right: scene.applyScale(21),
                top: 0,
                bottom: scene.applyScale(21),
                item: scene.applyScale(10),
                line: 0,
              },
            }),

            mask: {
              padding: 1,
            },
          },

          mouseWheelScroller: {
            focus: true,
            speed: 0.2,
          },

          space: {
            left: 0,
            right: 0,
            top: scene.applyScale(21),
            bottom: 0,
            panel: 0,
          },
        };

    super(scene, conf);

    this._scale = paneScale;
    this._userData = UserService.getInstance().getUserDataSync();

    this.layout();
    this.createComponent();
  }

  createComponent() {
    this._sizer = this.getElement('panel');
    const scene = this.scene;

    // Title
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labels.title, scene.applyScale(20)), {
        key: 'title_label',
        padding: { left: 0, top: scene.applyScale(defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    // Alliance Name
    this.addLabelAndDesc(scene, 'alliance_name_label', labels.allianceName, 'alliance_name_desc', labels.allianceNameDesc);
    this._allianceNameTextBox = this.createTextBox(scene, 50, '-', true, this._userData.allianceName?.toUpperCase());

    // Alliance Members
    this.addLabelAndDesc(
      scene,
      'alliance_members_label',
      labels.allianceMembers,
      'alliance_members_desc',
      labels.allianceMembersDesc,
      memberSectionVertOffset,
    );

    // Layout Sizer
    this.layout();
  }

  private addLabelAndDesc(scene: Phaser.Scene, labelKey: string, labelText: string, descKey: string, descText: string, vertOffset?: number) {
    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', labelText, scene.applyScale(16)), {
        key: labelKey,
        padding: { left: 0, top: scene.applyScale(vertOffset || defaultVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();

    this._sizer
      .add(scene.add.bitmapText(0, 0, 'cc_outline', descText, scene.applyScale(16)).setAlpha(0.3), {
        key: descKey,
        padding: { left: 0, top: scene.applyScale(descTextVertOffset), right: 0, bottom: 0 },
      })
      .addNewLine();
  }

  createTextBox(scene: Phaser.Scene, maxLength: number, placeholderText: string, readOnly: boolean, textValue?: string) {
    // Wallet Input Text
    const inputTextConfig = {
      x: 0,
      y: 0,
      width: undefined,
      height: undefined,
      type: 'text', // 'text'|'password'|'textarea'|'number'|'color'|...
      id: undefined,
      text: textValue,
      maxLength: maxLength,
      minLength: undefined,
      placeholder: placeholderText,
      tooltip: undefined,
      readOnly: readOnly,
      spellCheck: false,
      autoComplete: 'off',
      align: 'left',
      paddingLeft: `${scene.applyScale(10)}px`,
      paddingRight: undefined,
      paddingTop: `${scene.applyScale(2)}px`,
      paddingBottom: undefined,
      fontFamily: 'ccwhatchamacallitbold',
      fontSize: `${scene.applyScale(inputTextFontSize)}px`,
      color: '#ffffff',
      border: 'none',
      backgroundColor: '#E6E6FF0D',
      borderColor: undefined,
      outline: 'none',
      direction: 'ltr',
      selectAll: false,
    };

    var inputText = scene.add.rexInputText(0, 0, scene.applyScale(inputTextWidth), scene.applyScale(inputTextHeight), inputTextConfig);
    inputText.setStyle('border-radius', '8px');

    if (textValue) {
      inputText.setText(textValue);
    }

    this._sizer.add(inputText, {
      key: `${placeholderText}_input_text`,
      padding: { left: 0, top: scene.applyScale(inputTextTopPadding), right: 0, bottom: 0 },
    });

    return inputText;
  }
}

Phaser.GameObjects.GameObjectFactory.register('allianceContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new AllianceContentPane(this.scene, x, y));
});
