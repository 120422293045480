import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import ProgressBar from '~/components/progressBar/ProgressBar';
import MachineService from '~/services/MachineService';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { PVP_Scene_Assets } from '~/utils/AssetLoader';
import { UnloadImages } from '~/utils/AssetManager';

const modalHeight = 325;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const progressBarColor = 0xd3007f;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.14;
const glareRadius = 4;
const glareHeight = 8;
const glareOffset = 8;
const backgroundColor = 0x201425;
const backgroundAlpha = 0.7;
const radius = 11;

const progressBarHeight = 43;
const progressBarWidth = 213;

const progressBarBorderColor = 0x383d57;
const progressBarBorderWidth = 1;

const progressMin = 0;
const progressUpdateDelay = 1; //seconds

export default class MachineSpeedUpModalScene extends Phaser.Scene {
  private _scale;
  private _machine;
  private _homebaseMachine;
  private _progressBar;
  private _speedUpButton;
  private _blob;
  private _calculatingLabel;
  private _modalBg;
  private _sizer;

  init(data: any) {
    this._machine = data.machine;
    this._homebaseMachine = data.homebaseMachine;
  }

  constructor() {
    super({
      key: 'MachineSpeedUpModalScene',
    });
  }

  preload() {
    this.load.image('pvp_confirm_button', PVP_Scene_Assets.pvp_confirm_button);
    this.load.image('pvp_cancel_button', PVP_Scene_Assets.pvp_cancel_button);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100);

    // Add Background
    this._modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    this._modalBg.setStrokeStyle(modalBorderWidth, modalBorderColor, modalAlpha);

    // blob animation
    var animationConfig = {
      key: 'blob_animation',
      frames: this.anims.generateFrameNumbers('party_blob', {
        start: 0,
        end: 7,
        first: 7,
      }),
      frameRate: 15,
      repeat: -1,
    };

    this.anims.create(animationConfig);
    this._blob = this.add
      .sprite(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - this.applyScale(45), 'party_blob')
      .setOrigin(0.5, 0.5)
      .setScale(0.7 * this._scale)
      .play('blob_animation');

    this._calculatingLabel = this.add
      .bitmapText(
        this.sys.canvas.width / 2,
        this.sys.canvas.height / 2 + this.applyScale(45),
        'cc_outline',
        'Calculating cost...',
        this.applyScale(24),
        2,
      )
      .setOrigin(0.5, 0.5)
      .setAlpha(0.7);

    MachineService.getInstance()
      .getUserMachines(true)
      .then(res => {
        var machine = res.find(mach => {
          return mach.user_machine_id === this._machine.user_machine_id;
        });

        if (machine) {
          this._machine = machine;
        }

        this.createComponent();
      });
  }

  private createComponent() {
    // Hide Blob and Calculating label
    this._blob.setAlpha(0);
    this._blob.destroy();
    this._blob = undefined;
    this._calculatingLabel.setAlpha(0);
    this._calculatingLabel.destroy();
    this._calculatingLabel = undefined;

    // Add Sizer for content
    this._sizer = this.rexUI.add.overlapSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(300),
      height: this.applyScale(240),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Add Title Text
    this._sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Time remaining to\nupgrade machine:', this.applyScale(20), 1).setMaxWidth(300), {
      key: 'title_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Add Cost Title
    this._sizer.add(this.add.bitmapText(0, 0, 'cc_outline', 'Speed up cost:', this.applyScale(20), 1), {
      key: 'speed_up_cost_text',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(128),
      expand: false,
    });

    // Add Fee Labels
    const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', this._machine.stardust_upgrade_cost.toLocaleString('en-us'));

    this._sizer.add(this.add.existing(stardust_label), {
      key: 'stardust_label',
      align: 'center',
      offsetX: 0,
      offsetY: this.applyScale(60),
      expand: false,
    });

    this.createProgressBar(this);

    this._sizer.layout();

    // Add close Button
    const closeButton = new Button(
      this,
      this.sys.canvas.width / 2 - this.applyScale(94),
      this._modalBg.getBottomLeft().y,
      'pvp_cancel_button',
      false,
    ).setScale(this._scale);

    closeButton.onClick().subscribe(pointer => {
      this.scene.get('HomebaseScene').input.enabled = true;
      this.scene.resume('HudScene');
      this.unloadAssets();
      this.scene.stop();
    });
    this.add.existing(closeButton);

    // Add Confirm Button
    this._speedUpButton = new Button(
      this,
      this.sys.canvas.width / 2 + this.applyScale(75),
      this._modalBg.getBottomLeft().y,
      'pvp_confirm_button',
      false,
    ).setScale(this._scale);

    this._speedUpButton.onClick().subscribe(pointer => {
      this._speedUpButton.setDisabled(true);
      MachineService.getInstance()
        .speedUpUpgrade(this._machine.user_machine_id, this._machine.stardust_upgrade_cost)
        .then(result => {
          if (result.success) {
            this._homebaseMachine.removeTimeEvents();
            this.scene.get('HomebaseScene').updateMachines();
          }
          this.unloadAssets();
          this.scene.start('ShopPurchaseResultScene', { item: this._machine, itemType: 'speed_up_upgrade', purchaseResult: result });
        });
    });
    this.add.existing(this._speedUpButton);

    var userData = UserService.getInstance().getUserDataSync();

    if (userData.stardust < this._machine.stardust_upgrade_cost) {
      this._speedUpButton.setDisabled(true);
      this._speedUpButton.setAlpha(0.5);
    }

    this.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        this.updateTimeHandler();
      },
    });
  }

  private createProgressBar(scene) {
    let progressValue = this._machine.next_level_build_total_time - this._machine.star_lab_adjusted_time_until_next_level;

    // Progress Bar
    this._progressBar = new ProgressBar(
      scene,
      0, // x
      0, // y
      1,
      {
        rtl: false,
        title: {
          text: this.secondsToTime(this._machine.star_lab_adjusted_time_until_next_level),
          offset: 2,
          fontSize: 20,
        },
        progressBar: {
          text: '',
          fontStyle: null,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: radius,
          color: progressBarColor,
          alpha: progressBarAlpha,
          backgroundColor: backgroundColor,
          backgroundAlpha: backgroundAlpha,
          minValue: progressMin,
          maxValue: this._machine.next_level_build_total_time,
          progressValue: progressValue > 0 ? progressValue : 1,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
        border: {
          alpha: backgroundAlpha,
          color: progressBarBorderColor,
          width: progressBarBorderWidth,
        },
      },
    );
    scene.add.existing(this._progressBar);

    this._sizer.add(this._progressBar, {
      key: 'machine_progress_bar',
      align: 'center-top',
      offsetX: 0,
      offsetY: this.applyScale(57),
      expand: false,
    });
  }

  private secondsToTime(seconds: number) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var hDisplay = h > 0 ? `${h}h` : '';
    var mDisplay = m > 0 ? `${m}m` : '';
    var sDisplay = s > 0 ? `${s}s` : '';

    return h > 0 ? `${hDisplay} ${mDisplay} ${sDisplay}` : `${mDisplay} ${sDisplay}`;
  }

  private updateTimeHandler() {
    this._machine.star_lab_adjusted_time_until_next_level = this._machine.star_lab_adjusted_time_until_next_level - progressUpdateDelay;
    this.updateProgress();

    if (this._machine.star_lab_adjusted_time_until_next_level <= 0) {
      this._speedUpButton.setDisabled(true);
      this._speedUpButton.setAlpha(0.5);
    }
  }

  private updateProgressText() {
    this._progressBar.setTitle(this.secondsToTime(this._machine.star_lab_adjusted_time_until_next_level));
  }

  private updateProgress() {
    let progressValue = this._machine.next_level_build_total_time - this._machine.star_lab_adjusted_time_until_next_level;
    this._progressBar.setProgress(this, progressValue > 0 ? progressValue : 1);
    this.updateProgressText();
  }

  applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, ['pvp_confirm_button', 'pvp_cancel_button']);
  }
}
