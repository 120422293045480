import Phaser, { Game } from 'phaser';
import ScaleService from '~/services/ScaleService';
import Button from '~/components/buttons/Button';
import BattlePassService from '~/services/BattlePassService';
import MachineService from '~/services/MachineService';
import { BP_Buy_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const width = 1512;
const height = 982;

export default class BattlePassBuyScene extends Phaser.Scene {
  private _scale;

  // Buttons
  private _backButton;
  private _exploreButton;
  private _buyButton;

  // Images
  private _backgroundImage;
  private _seasonText;
  private _seasonCard;

  constructor() {
    super({
      key: 'BattlePassBuyScene',
    });
  }

  preload() {
    LoadImageAssets(this, BP_Buy_Scene_Assets);
  }

  create() {
    this.setBackgroundColour('#5631FF');
    this.createComponent();
  }

  createComponent() {
    this._scale = ScaleService.getInstance().getScale(width, height);

    // this._backgroundImage = this.add.image(
    // 	this.sys.canvas.width / 2,
    // 	this.sys.canvas.height / 2,
    // 	"battlepass_background_image"
    // ).setScale(this._scale)

    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: width * this._scale,
      height: height * this._scale,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Image
    this._backgroundImage = this.add.image(0, 0, 'battlepass_background_image').setScale(this._scale);

    sizer.add(this._backgroundImage, {
      key: 'bp-background',
      align: 'left-center',
      offsetX: this.applyScale(266),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Buttons
    this._backButton = new Button(this, 0, 0, 'battlepass_back_button', true, 1.05).setScale(this._scale);
    this._backButton.onClick().subscribe(pointer => {
      this.setBackgroundColour('#0f0f1f');
      this.unloadAssets();
      this.scene.stop();
      this.scene.start('HomebaseScene');
    });

    this.add.existing(this._backButton);

    sizer.add(this._backButton, {
      key: 'back-button',
      align: 'left-top',
      offsetX: this.applyScale(42),
      offsetY: this.applyScale(48),
      expand: false,
    });

    this._exploreButton = new Button(this, 0, 0, 'battlepass_explore_button', true, 1.05).setScale(this._scale);
    this._exploreButton.onClick().subscribe(pointer => {
      window.open('https://www.starwolvez.com/battle-pass', '_blank')?.focus();
    });

    this.add.existing(this._exploreButton);

    sizer.add(this._exploreButton, {
      key: 'explore-button',
      align: 'left-top',
      offsetX: this.applyScale(47),
      offsetY: this.applyScale(851),
      expand: false,
    });

    var buyImage = MachineService.getInstance().ownsGLFHersArcade() ? 'battlepass_buy_button_glfhers' : 'battlepass_buy_button';
    this._buyButton = new Button(this, 0, 0, buyImage, true, 1.05).setScale(this._scale);
    this._buyButton.onClick().subscribe(pointer => {
      BattlePassService.getInstance()
        .purchaseBattlePass()
        .then(res => {
          if (res.success === 1) {
            // Purchase Successful
            this.unloadAssets();
            this.scene.stop();
            this.scene.start('BattlePassBuySuccessScene');
          } else {
            // User has already purchased a battle pass
          }
        });
    });

    this.add.existing(this._buyButton);

    sizer.add(this._buyButton, {
      key: 'buy-button',
      align: 'left-top',
      offsetX: this.applyScale(303),
      offsetY: this.applyScale(851),
      expand: false,
    });

    // Images
    this._seasonText = this.add.image(0, 0, 'battlepass_season_text').setScale(this._scale);

    sizer.add(this._seasonText, {
      key: 'bp-text',
      align: 'left-top',
      offsetX: this.applyScale(42),
      offsetY: this.applyScale(587),
      expand: false,
    });

    this._seasonCard = this.add.image(0, 0, 'battlepass_season_image').setScale(this._scale);

    sizer.add(this._seasonCard, {
      key: 'bp-season-card',
      align: 'left-top',
      offsetX: this.applyScale(815),
      offsetY: this.applyScale(170),
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  applyScale(length) {
    return length * this._scale;
  }

  setBackgroundColour(colour: string) {
    document.body.style.backgroundImage = 'none';
    document.body.style.backgroundColor = colour;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(BP_Buy_Scene_Assets));
  }
}
