import Phaser from 'phaser';
import CollectionService from '~/services/CollectionService';
import ConfigurationService from '~/services/ConfigurationService';
import MachineService from '~/services/MachineService';
import MiningService from '~/services/MiningService';
import NFTService from '~/services/NFTService';
import ObjectiveService from '~/services/ObjectiveService';
import SquadService from '~/services/SquadService';
import StakingService from '~/services/StakingService';
import { Homebase_Assets, Loading_Assets, Squad_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import { StartScene } from '~/utils/SceneLoader';
import TweenHelper from '~/utils/TweenHelper';

export default class HomebaseLoadingScene extends Phaser.Scene {
  constructor() {
    super({
      key: 'HomebaseLoadingScene',
    });
  }

  preload() {
    const ignoreList = ['blob_room_door', 'exit_room_door', 'blob'];
    LoadImageAssets(this, Homebase_Assets, ignoreList);

    this.load.spritesheet('blob_room_door', Homebase_Assets.blob_room_door, { frameWidth: 168, frameHeight: 64, endFrame: 22 });
    this.load.spritesheet('exit_room_door', Homebase_Assets.exit_room_door, { frameWidth: 168, frameHeight: 64, endFrame: 22 });
    this.load.spritesheet('blob', Homebase_Assets.blob, {
      frameWidth: 51,
      frameHeight: 42,
      endFrame: 7,
    });

    const config = ConfigurationService.getInstance().getConfig();

    config.machines.forEach(machConfig => {
      if (machConfig.type === 'animation') {
        this.load.spritesheet(`${machConfig.key}_level_${machConfig.level}`, machConfig.filePath, {
          frameWidth: machConfig.frameWidth,
          frameHeight: machConfig.frameHeight,
          endFrame: machConfig.numFrames - 1,
        });
      }
    });

    this.load.audio('machines-button', 'assets/audios/machines-button.wav');
  }

  create() {
    // Using this pattern to avoid blank screen delay between loading screens
    this.scene.stop('LoadingScene');

    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 'loading_rect');
    this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_bar');

    const text1 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 - 60, 'homebase_loading_text');

    const prog1 = this.add.image(this.sys.canvas.width / 2 - 122, this.sys.canvas.height / 2 + 50, 'loading_prog_1');
    const prog2 = this.add.image(this.sys.canvas.width / 2 - 73, this.sys.canvas.height / 2 + 50, 'loading_prog_2');
    const prog3 = this.add.image(this.sys.canvas.width / 2 - 34, this.sys.canvas.height / 2 + 50, 'loading_prog_3');
    const prog4 = this.add.image(this.sys.canvas.width / 2 - 13, this.sys.canvas.height / 2 + 50, 'loading_prog_4');
    const prog5 = this.add.image(this.sys.canvas.width / 2, this.sys.canvas.height / 2 + 50, 'loading_prog_5');
    prog1.alpha = 0;
    prog2.alpha = 0;
    prog3.alpha = 0;
    prog4.alpha = 0;
    prog5.alpha = 0;

    Promise.all([
      MachineService.getInstance().getUserMachines(),
      MiningService.getInstance().getLastMinedReward(),
      MiningService.getInstance().getMiningStatus(),
      SquadService.getInstance().getSquadFromMarketplace(),
      StakingService.getInstance().getStakingInfo(undefined, undefined, 0, 30),
      ObjectiveService.getInstance().getObjectives(),
    ]).then(() => StartScene(this, 'HomebaseScene'));

    this.time.addEvent({
      delay: 300,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog1,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 600,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog1,
            alpha: 0,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );

        this.tweens.add(
          {
            targets: prog2,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 900,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog3,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 1200,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog4,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });

    this.time.addEvent({
      delay: 1500,
      loop: false,
      callback: () => {
        this.tweens.add(
          {
            targets: prog5,
            alpha: 1,
            duration: 1000,
            ease: 'Power2',
          },
          this,
        );
      },
    });
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Loading_Assets));
  }
}
