import Phaser from 'phaser';
import BossFightCardPane from '~/scenes/hud/hudBossFightCardPane';
import ScaleService from '~/services/ScaleService';

const modalHeight = 400;
const modalWidth = 689;

export default class MiningTestingScene extends Phaser.Scene {
  private _bossFightCardPane;
  private _scale;
  private _player;

  constructor() {
    super({
      key: 'MiningTestingScene',
    });
  }

  preload() {}

  init() {}

  create() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this.createComponent();
  }

  private createComponent() {
    var sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: modalWidth,
      height: modalHeight,
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    this._bossFightCardPane = new BossFightCardPane(this, 0, 0, this._player, this._scale);
    this.add.existing(this._bossFightCardPane);

    sizer.add(this._bossFightCardPane, {
      key: 'card-pane',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Layout the Scene
    sizer.layout();
  }

  applyScale(length) {
    return this._scale * length;
  }
}
