import { generateRandomBotName } from '~/utils/GameUtils';
import ApiProxyService from './ApiProxyService';
import ConfigurationService from './ConfigurationService';

const noMember = {
  id: 0,
  nft_collection: 'Star Wolvez Generative',
  display_name: 'None Selected       ',
  image_key: 'starwolf_silhouette',
  hp: 0,
  show_yield: false,
  show_hp: false,
  disabled: true,
};

export default class BotService {
  private static _instance: BotService = new BotService();

  private _bot;
  private _botData = {
    wallet: '0x0',
    name: generateRandomBotName(),
    xp: 0,
    level: 0,
    last_opensea_api_check: 1670224658,
    discord_airdrop: 0,
    tourno_one_airdrop: 0,
    neon_gold: 0,
    space_elixir: 0,
    stardust: '0',
  };

  private _squad;
  private _nfts;
  private _traits;
  private _config: any;

  constructor() {
    if (BotService._instance) {
      throw new Error('Error: Instantiation failed: Use OpponentService.getInstance() instead of new.');
    }
    BotService._instance = this;
    this._config = ConfigurationService.getInstance().getConfig();
  }

  public static getInstance(): BotService {
    return BotService._instance;
  }

  public getBotSync() {
    return this._bot;
  }

  public getTraitsSync() {
    return this._traits;
  }

  public getBot(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._bot && !forceUpdate) {
        return resolve(this._bot);
      } else {
        return resolve(this.fetchBot());
      }
    });
  }

  private fetchBot() {
    this._bot = this._botData;
    return this.getSquad(true).then(squad => {
      this._bot.squadMembers = squad;
      return this._bot;
    });
  }

  public getSquad(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._squad && !forceUpdate) {
        return resolve(this._squad);
      } else {
        return resolve(this.fetchRandomSquad());
      }
    });
  }

  private fetchRandomSquad() {
    return ApiProxyService.getInstance()
      .get('squad', ['random'], [])
      .then(res => {
        return this.enrichSquadData(res.squad).then(enrichedSquad => {
          this._squad = enrichedSquad;
          this._traits = this.generateTraitsList(this._squad);
          return this._squad;
        });
      });
  }

  public getBotSquadSync() {
    return this._squad;
  }

  private enrichSquadData(squadData) {
    return this.getNFTs(squadData, true).then(nfts => {
      if (squadData && squadData.length > 0) {
        const enrichedSquad = squadData.map(squadMember => {
          var nftData = nfts.find(nft => {
            return nft.id === squadMember.marketplace_nft_id;
          });
          if (nftData) {
            return Object.assign({}, { position: squadMember.position, squad_type_id: squadMember.squad_type_id }, nftData);
          }
          return undefined;
        });

        var squadArray: any[] = [];

        var position = 0;
        enrichedSquad.forEach(sqMem => {
          if (sqMem) {
            squadArray[position] = sqMem;
            position++;
          }
        });

        for (var i = 0; i < 3; i++) {
          if (!squadArray[i]) {
            squadArray[i] = Object.assign({}, noMember, { position: i });
          }
        }

        return squadArray;
      } else {
        return [Object.assign({}, noMember), Object.assign({}, noMember), Object.assign({}, noMember)];
      }
    });
  }

  public getNFTs(squad, forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._nfts && this._nfts.length > 0 && !forceUpdate) {
        return resolve(this._nfts);
      } else {
        return resolve(this.fetchNFTs(squad));
      }
    });
  }

  private fetchNFTs(squad) {
    var promises: any[] = [];

    // ToDo Change when Chance updates API call to get NFTs without Walletid
    squad.forEach(squadMember => {
      promises.push(ApiProxyService.getInstance().get('nfts', [squadMember.partner_contract_address, squadMember.marketplace_nft_id], []));
    });

    this._nfts = [];

    return Promise.all(promises).then(responses => {
      responses.forEach(res => {
        this._nfts.push(this.enrichImageData(res.nfts[0]));
      });
      return this._nfts;
    });
  }

  private enrichImageData(nft: any) {
    nft.image_key = `nft_${nft.id}`;
    return nft;
  }

  private generateTraitsList(squad) {
    var traits: Array<Trait> = new Array<Trait>();
    squad.forEach(nft => {
      if (nft.traits !== undefined) {
        nft.traits.forEach(trait => {
          traits.push({ collection: nft.nft_collection, id: nft.nft_id, type: trait.trait_type, trait: trait.value });
        });
      }
    });
    return traits;
  }
}

export interface Trait {
  collection: string;
  id: string;
  type: string;
  trait: string;
}
