const config = {
  config: {
    api: {
      base: 'https://api.starwolvez.com',
      endpoints: {
        base_raid: {
          path: '/base_raid/',
        },
        user: {
          path: '/user/',
        },
        nfts: {
          path: '/nfts/',
          refresh_route: 'from_marketplace',
          cache_route: 'all',
        },
        objectives: {
          path: '/objectives/',
        },
        login: {
          path: '/login/',
        },
        staking: {
          path: '/staking/',
        },
        machines: {
          path: '/machines/',
        },
        squad: {
          path: '/squad/',
        },
        pvp_battle: {
          path: '/pvp_battle/',
        },
        shop: {
          path: '/shop/',
        },
        mining: {
          path: '/mining/',
        },
        rewards: {
          path: '/rewards/',
        },
        blobs: {
          path: '/blobs/',
        },
        battle_pass: {
          path: '/battle_pass/',
        },
        resources: {
          path: '/resources/',
        },
      },
    },
    websockets: {
      base: 'wss://api.starwolvez.com',
      pvp: {
        path: '/pvp',
      },
      pve: {
        path: '/pve',
      },
      bossfight: {
        path: '/bossfight',
      },
      battleroyale: {
        path: '/battleroyale',
      },
    },
    collections: [
      { displayName: 'Star Wolvez', collectionSlugs: ['starwolvez-generative', 'starwolves'] },
      { displayName: 'Galactic Apes', collectionSlugs: ['gapes-nft', 'galacticapes', 'galacticapesgenesis'] },
      { displayName: 'Galactic Monkes', collectionSlugs: ['galacticmonkes'] },
      { displayName: '0N1 Force', collectionSlugs: ['0n1-force'] },
      { displayName: 'a KID called BEAST', collectionSlugs: ['akidcalledbeast'] },
      { displayName: 'acrocalypse', collectionSlugs: ['acrocalypse'] },
      { displayName: 'AIC', collectionSlugs: ['aicgenesis', 'operatives'] },
      { displayName: 'Anonymice', collectionSlugs: ['anonymice'] },
      { displayName: 'Ape Gang', collectionSlugs: ['ape-gang'] },
      { displayName: 'Ape Reunion', collectionSlugs: ['apereunion'] },
      { displayName: 'Azuki', collectionSlugs: ['azuki'] },
      { displayName: 'Azuki Elementals', collectionSlugs: ['azukielementals'] },
      { displayName: 'BASTARD GAN PUNKS V2', collectionSlugs: ['bastard-gan-punks-v2'] },
      { displayName: 'Bears Deluxe', collectionSlugs: ['bears-deluxe'] },
      { displayName: 'BearX', collectionSlugs: ['bearxlabs'] },
      { displayName: 'BEASTS Genesis', collectionSlugs: ['beastsgenesis'] },
      { displayName: 'Bored Ape Kennel Club', collectionSlugs: ['bored-ape-kennel-club'] },
      { displayName: 'Bored Ape Yacht Club', collectionSlugs: ['boredapeyachtclub'] },
      { displayName: 'Brawler Bearz', collectionSlugs: ['brawler-bearz'] },
      { displayName: 'Casual Sloths Official', collectionSlugs: ['casual-sloths-official'] },
      { displayName: 'Chain Runners', collectionSlugs: ['chain-runners-nft'] },
      { displayName: 'Chimpers', collectionSlugs: ['chimpersnft'] },
      { displayName: 'Chompies', collectionSlugs: ['chompiesnft'] },
      { displayName: 'Creepz by OVERLORD', collectionSlugs: ['genesis-creepz'] },
      { displayName: 'Cryptoadz', collectionSlugs: ['cryptoadz-by-gremplin'] },
      { displayName: 'Cyber Turtles', collectionSlugs: ['cyberturtles-genesis'] },
      { displayName: 'CyberKongz (Babies)', collectionSlugs: ['cyberkongz-babies'] },
      { displayName: 'CyberKongz VX', collectionSlugs: ['cyberkongz-vx'] },
      { displayName: 'Dimension X', collectionSlugs: ['dimensionx'] },
      { displayName: 'Dizzy Demons', collectionSlugs: ['dizzydemons'] },
      { displayName: 'Dizzy Dragons', collectionSlugs: ['dizzydragons'] },
      { displayName: 'Doodles Original Collection', collectionSlugs: ['doodles-official'] },
      { displayName: 'Dormant Dragons', collectionSlugs: ['official-dormant-dragons'] },
      { displayName: 'DystoApes', collectionSlugs: ['dystoapez-official'] },
      { displayName: 'Ether Orcs', collectionSlugs: ['ether-orcs'] },
      { displayName: 'EtherOrcsOfficial', collectionSlugs: ['etherorcsallies'] },
      { displayName: 'Ethlizards', collectionSlugs: ['ethlizards'] },
      { displayName: 'FishBallerz', collectionSlugs: ['fishballerz'] },
      { displayName: 'Forgotten Runes Wizards Cult', collectionSlugs: ['forgottenruneswizardscult'] },
      { displayName: 'Freckle Forever Pass', collectionSlugs: ['freckle-forever-pass'] },
      { displayName: 'Freckle Gold Pass', collectionSlugs: ['freckle-gold-pass'] },
      { displayName: 'Genuine Undead', collectionSlugs: ['genuine-undead'] },
      { displayName: 'Ghost Child: Bones', collectionSlugs: ['ghostchildbones'] },
      { displayName: 'GLHFers', collectionSlugs: ['glhfers'] },
      { displayName: 'goblintown.wtf', collectionSlugs: ['goblintownwtf'] },
      { displayName: 'Habibiz', collectionSlugs: ['thehabibiz'] },
      { displayName: 'Kaiju Kingz', collectionSlugs: ['kaiju-kingz'] },
      { displayName: 'Kaiju Mutants NFT', collectionSlugs: ['kaijumutant'] },
      { displayName: 'KingFrogs', collectionSlugs: ['kingfrogs'] },
      { displayName: 'Koin Games Dev Squad', collectionSlugs: ['kgds'] },
      { displayName: 'KPR', collectionSlugs: ['kprverse'] },
      { displayName: 'Kuma Cub', collectionSlugs: ['kumacub'] },
      { displayName: 'Kuma Toys', collectionSlugs: ['kumatoys'] },
      { displayName: 'Kumaverse', collectionSlugs: ['kumaverse'] },
      { displayName: 'Lil Pudgys', collectionSlugs: ['lilpudgys'] },
      { displayName: 'Little Lemon Friends', collectionSlugs: ['little-lemon-friends'] },
      { displayName: 'Llamaverse Genesis', collectionSlugs: ['llamaverse-genesis'] },
      { displayName: 'Magna', collectionSlugs: ['magna-1'] },
      { displayName: 'Master Cats NFT', collectionSlugs: ['mastercatsnft'] },
      { displayName: 'MetaHero: Generative Identities', collectionSlugs: ['metahero-generative'] },
      { displayName: 'Metaverse HQ', collectionSlugs: ['metaverse-hq'] },
      { displayName: 'Moonbirds', collectionSlugs: ['proof-moonbirds'] },
      { displayName: 'Mooncats', collectionSlugs: ['acclimatedmooncats'] },
      { displayName: 'Mooncatz', collectionSlugs: ['mooncatz'] },
      { displayName: 'Moonrunners', collectionSlugs: ['moonrunnersnft'] },
      { displayName: 'Moonrunners Dragonhorde Official', collectionSlugs: ['moonrunners-dragonhorde-official'] },
      { displayName: 'Murakami Flowers', collectionSlugs: ['murakami-flowers-2022-official'] },
      { displayName: 'Mutant Ape Yacht Club', collectionSlugs: ['mutant-ape-yacht-club'] },
      { displayName: 'My Pet Hooligan', collectionSlugs: ['mypethooligan'] },
      { displayName: 'Nakamigos', collectionSlugs: ['nakamigos'] },
      { displayName: 'Neo Tokyo Citizens', collectionSlugs: ['neotokyo-citizens'] },
      { displayName: 'Neo Tokyo Outer Citizens', collectionSlugs: ['neotokyo-outer-citizens'] },
      { displayName: 'NEOZEN - ALPHA EX', collectionSlugs: ['neozen'] },
      { displayName: 'Oceans of Terra: Origins', collectionSlugs: ['oot-origins'] },
      { displayName: 'OnChainMonkey', collectionSlugs: ['onchainmonkey'] },
      { displayName: 'Oogaverse', collectionSlugs: ['oogaverse'] },
      { displayName: 'Opepen Edition', collectionSlugs: ['opepen-edition'] },
      { displayName: "Pirate Nation - Founder's Pirates", collectionSlugs: ['piratenation'] },
      { displayName: 'Pixel Vampire Syndicate', collectionSlugs: ['pixel-vampire-syndicate'] },
      { displayName: 'PixelTigers', collectionSlugs: ['pixeltigers-official'] },
      { displayName: 'Planet Degen', collectionSlugs: ['planetdegennft'] },
      { displayName: 'Potatoz', collectionSlugs: ['thepotatoz'] },
      { displayName: 'Primal Beast', collectionSlugs: ['primalbeast'] },
      { displayName: 'Project Godjira Genesis', collectionSlugs: ['projectgodjiragenesis'] },
      { displayName: 'Project Metrograd Gen 2', collectionSlugs: ['metrograd-gen-2'] },
      { displayName: 'Project Yin Yang', collectionSlugs: ['projectyinyang'] },
      { displayName: 'Pudgy Penguins', collectionSlugs: ['pudgypenguins'] },
      { displayName: 'Punks2023', collectionSlugs: ['ul-punks2023'] },
      { displayName: 'Pxl Fangs', collectionSlugs: ['pxlfangs'] },
      { displayName: 'PxMythics', collectionSlugs: ['pxmythics-genesis'] },
      { displayName: 'PxQuest', collectionSlugs: ['pxquest'] },
      { displayName: 'reepz', collectionSlugs: ['reepz'] },
      { displayName: 'Sappy Seals', collectionSlugs: ['sappy-seals'] },
      { displayName: 'Satoshi Runners', collectionSlugs: ['satoshirunnersofficial'] },
      { displayName: 'Saved Souls', collectionSlugs: ['savedsouls'] },
      { displayName: 'Sofa Vision', collectionSlugs: ['sofa-vision'] },
      { displayName: 'Space Riders NFT', collectionSlugs: ['spaceridersnft'] },
      { displayName: 'SpaceYetis', collectionSlugs: ['space-yetis-official'] },
      { displayName: 'Spritely', collectionSlugs: ['spritely-genesis'] },
      { displayName: 'Sproto Gremlins', collectionSlugs: ['sproto-gremlins'] },
      { displayName: 'Squeakers', collectionSlugs: ['squeakersnft'] },
      { displayName: 'Stumble upon Rumble: Boxing Gloves', collectionSlugs: ['sur-boxing-gloves'] },
      { displayName: 'SupDucks', collectionSlugs: ['supducks'] },
      { displayName: 'Swamp Souls', collectionSlugs: ['swampsouls'] },
      { displayName: 'Swampverse', collectionSlugs: ['swampverseofficial'] },
      { displayName: 'Teruko Labs Genesis', collectionSlugs: ['teruko-genesis'] },
      { displayName: 'The 8102: Yachts', collectionSlugs: ['the-8102-yachts'] },
      { displayName: 'The Captainz', collectionSlugs: ['thecaptainz'] },
      { displayName: 'The Dreamers', collectionSlugs: ['thedreamers'] },
      { displayName: 'The Grapes', collectionSlugs: ['the-grapes'] },
      { displayName: 'The Littles', collectionSlugs: ['thelittlesnft'] },
      { displayName: 'The Plague NFT', collectionSlugs: ['the-plague'] },
      { displayName: 'Tiny Dinos', collectionSlugs: ['tiny-dinos-eth'] },
      { displayName: 'Tycoon Tigers Club', collectionSlugs: ['tycoontigersclub'] },
      { displayName: 'Tyranz Genesis', collectionSlugs: ['tyranzgenesis'] },
      { displayName: 'Utility Wen', collectionSlugs: ['utilitywen'] },
      { displayName: 'Valhalla', collectionSlugs: ['valhalla'] },
      { displayName: 'Verb: Chapter One', collectionSlugs: ['verblabsofficial'] },
      { displayName: 'Wave Catchers', collectionSlugs: ['wavecatchersofficial'] },
      { displayName: 'We All Survived Death', collectionSlugs: ['weallsurviveddeath'] },
      { displayName: 'Wulfz', collectionSlugs: ['wulfz-official'] },
      { displayName: 'XTREME PIXELS', collectionSlugs: ['xtreme-pixels'] },
      { displayName: 'Yeti Town', collectionSlugs: ['yeti-town'] },
      { displayName: 'ZenApe', collectionSlugs: ['zenapenft'] },
    ],
    features: {
      profile_modal: false,
      objectives_modal: true,
    },
    userBadges: [
      {
        badgeType: 'Bronze',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/bronze_level_1.png',
        badgeImageKey: 'bronze_level_1_badge',
        minLevel: 0,
        maxLevel: 10,
      },
      {
        badgeType: 'Bronze',
        badgeLevel: 2,
        badgeImagePath: 'assets/hud/badges/bronze_level_2.png',
        badgeImageKey: 'bronze_level_2_badge',
        minLevel: 11,
        maxLevel: 20,
      },
      {
        badgeType: 'Bronze',
        badgeLevel: 3,
        badgeImagePath: 'assets/hud/badges/bronze_level_3.png',
        badgeImageKey: 'bronze_level_3_badge',
        minLevel: 21,
        maxLevel: 30,
      },
      {
        badgeType: 'Silver',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/silver_level_1.png',
        badgeImageKey: 'silver_level_1_badge',
        minLevel: 31,
        maxLevel: 40,
      },
      {
        badgeType: 'Silver',
        badgeLevel: 2,
        badgeImagePath: 'assets/hud/badges/silver_level_2.png',
        badgeImageKey: 'silver_level_2_badge',
        minLevel: 41,
        maxLevel: 50,
      },
      {
        badgeType: 'Silver',
        badgeLevel: 3,
        badgeImagePath: 'assets/hud/badges/silver_level_3.png',
        badgeImageKey: 'silver_level_3_badge',
        minLevel: 51,
        maxLevel: 60,
      },
      {
        badgeType: 'Gold',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/gold_level_1.png',
        badgeImageKey: 'gold_level_1_badge',
        minLevel: 61,
        maxLevel: 70,
      },
      {
        badgeType: 'Gold',
        badgeLevel: 2,
        badgeImagePath: 'assets/hud/badges/gold_level_2.png',
        badgeImageKey: 'gold_level_2_badge',
        minLevel: 71,
        maxLevel: 80,
      },
      {
        badgeType: 'Gold',
        badgeLevel: 3,
        badgeImagePath: 'assets/hud/badges/gold_level_3.png',
        badgeImageKey: 'gold_level_3_badge',
        minLevel: 81,
        maxLevel: 90,
      },
      {
        badgeType: 'Master',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/master_level_1.png',
        badgeImageKey: 'master_level_1_badge',
        minLevel: 91,
        maxLevel: 100,
      },
      {
        badgeType: 'Master',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/master_level_2.png',
        badgeImageKey: 'master_level_2_badge',
        minLevel: 101,
        maxLevel: 110,
      },
      {
        badgeType: 'Master',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/master_level_3.png',
        badgeImageKey: 'master_level_3_badge',
        minLevel: 111,
        maxLevel: 120,
      },
      {
        badgeType: 'Hero',
        badgeLevel: 1,
        badgeImagePath: 'assets/hud/badges/hero_level_1.png',
        badgeImageKey: 'hero_level_1_badge',
        minLevel: 121,
        maxLevel: null,
      },
    ],
    machines: [
      // MAINFRAMES
      {
        key: 'mainframe',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_1_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 2,
        type: 'image',
        imageKey: 'machines_mainframe_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_3_sprite_v2.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_4_sprite_v2.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 104,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_5_sprite_v2.png',
        numFrames: 9,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_6_sprite.png',
        numFrames: 80,
        frameRate: 12,
        frameWidth: 128,
        frameHeight: 146,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_6_sprite.png',
        numFrames: 80,
        frameRate: 12,
        frameWidth: 128,
        frameHeight: 146,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_6_sprite.png',
        numFrames: 80,
        frameRate: 12,
        frameWidth: 128,
        frameHeight: 146,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/mainframe_level_6_sprite.png',
        numFrames: 80,
        frameRate: 12,
        frameWidth: 128,
        frameHeight: 146,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 10,
        type: 'image',
        imageKey: 'machines_mainframe_level_7',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 11,
        type: 'image',
        imageKey: 'machines_mainframe_level_7',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 12,
        type: 'image',
        imageKey: 'machines_mainframe_level_7',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 13,
        type: 'image',
        imageKey: 'machines_mainframe_level_8',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 14,
        type: 'image',
        imageKey: 'machines_mainframe_level_8',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'mainframe',
        level: 15,
        type: 'image',
        imageKey: 'machines_mainframe_level_9',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      // SPACE ELIXIR MINES
      {
        key: 'space_elixir_mine',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_1_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_2_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_3_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_4_sprite.png',
        numFrames: 18,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_5_sprite.png',
        numFrames: 18,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 74,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_6_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 120,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_7_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 123,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_8_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 137,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_mine_level_9_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 137,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 10,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_10',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 11,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_11',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 12,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 13,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 14,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_mine',
        level: 15,
        type: 'image',
        imageKey: 'machines_space_elixir_mine_level_13',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // SPACE ELIXIR STORAGE
      {
        key: 'space_elixir_storage',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_1_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_2_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 76,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_3_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 68,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_4_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 86,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_5_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 106,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_6_sprite.png',
        numFrames: 20,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 115,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_7_sprite.png',
        numFrames: 20,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 135,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_8_sprite.png',
        numFrames: 20,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 153,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/space_elixir_storage_level_9_sprite.png',
        numFrames: 20,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 153,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 10,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_10',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 11,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_11',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 12,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 13,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 14,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_storage',
        level: 15,
        type: 'image',
        imageKey: 'machines_space_elixir_storage_level_13',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // NEON GOLD MINES
      {
        key: 'neon_gold_mine',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_1_sprite.png',
        numFrames: 8,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_2_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_3_sprite.png',
        numFrames: 8,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_4_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 68,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_5_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 78,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_6_sprite.png',
        numFrames: 35,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 108,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_7_sprite.png',
        numFrames: 32,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_8_sprite.png',
        numFrames: 40,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_mine_level_9_sprite.png',
        numFrames: 40,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 108,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 10,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_10',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 11,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_11',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 12,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 13,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 14,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_mine',
        level: 15,
        type: 'image',
        imageKey: 'machines_neon_gold_mine_level_13',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      // NEON GOLD STORAGE
      {
        key: 'neon_gold_storage',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_1_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_2_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 77,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_3_sprite.png',
        numFrames: 22,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 68,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_4_sprite.png',
        numFrames: 26,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 75,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_5_sprite.png',
        numFrames: 26,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 82,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_6_sprite.png',
        numFrames: 47,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 110,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_7_sprite.png',
        numFrames: 47,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 122,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_8_sprite.png',
        numFrames: 47,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 135,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/neon_gold_storage_level_9_sprite.png',
        numFrames: 49,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 134,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 10,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_10',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 11,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_11',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 12,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 13,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 14,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_12',
        baseCellWidth: 2,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_storage',
        level: 15,
        type: 'image',
        imageKey: 'machines_neon_gold_storage_level_13',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // Stardust Mine
      {
        key: 'stardust_mine',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_1_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'stardust_mine',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_2_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 128,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'stardust_mine',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_3_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 128,
        frameHeight: 144,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'stardust_mine',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_4_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 128,
        frameHeight: 144,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'stardust_mine',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_5_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 128,
        frameHeight: 144,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'stardust_mine',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/stardust_mine_level_5_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 128,
        frameHeight: 144,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // Star Laboratories
      {
        key: 'star_laboratory',
        level: 1,
        type: 'image',
        imageKey: 'machines_star_laboratory_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'star_laboratory',
        level: 2,
        type: 'image',
        imageKey: 'machines_star_laboratory_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'star_laboratory',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/star_laboratory_level_3_sprite.png',
        numFrames: 42,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 118,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'star_laboratory',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/star_laboratory_level_3_sprite.png',
        numFrames: 42,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 118,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'star_laboratory',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/star_laboratory_level_3_sprite.png',
        numFrames: 42,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 118,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'star_laboratory',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/star_laboratory_level_3_sprite.png',
        numFrames: 42,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 118,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Technician Bunkers
      {
        key: 'technician_bunker',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/technician_bunker_level_1_sprite.png',
        numFrames: 56,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 116,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Accelerators
      {
        key: 'neon_gold_accelerator',
        level: 1,
        type: 'image',
        imageKey: 'machines_neon_gold_accelerator_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_accelerator',
        level: 2,
        type: 'image',
        imageKey: 'machines_neon_gold_accelerator_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'neon_gold_accelerator',
        level: 3,
        type: 'image',
        imageKey: 'machines_neon_gold_accelerator_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_accelerator',
        level: 1,
        type: 'image',
        imageKey: 'machines_space_elixir_accelerator_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_accelerator',
        level: 2,
        type: 'image',
        imageKey: 'machines_space_elixir_accelerator_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_elixir_accelerator',
        level: 3,
        type: 'image',
        imageKey: 'machines_space_elixir_accelerator_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // COSMETICS
      {
        key: 'discord_airdrop_trophy',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/discord_airdrop_trophy_level_1_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'tournament_one_competitor_trophy',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/tournament_one_competitor_trophy_level_1_sprite.png',
        numFrames: 16,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'genesis_holder_trophy',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/genesis_holder_trophy_level_1_sprite_v2.png',
        numFrames: 21,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 128,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'christmas_tree_trophy',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/Christmas_tree_trophy_level_1_sprite.png',
        numFrames: 64,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 96,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'beer_machine',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/beer_machine_level_1_sprite.png',
        numFrames: 68,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 106,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'raijus_grave',
        level: 1,
        type: 'image',
        imageKey: 'machines_raijus_grave_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'linkins_armory',
        level: 1,
        type: 'image',
        imageKey: 'machines_linkins_armory_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bench_press',
        level: 1,
        type: 'image',
        imageKey: 'machines_bench_press_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'valentines_picnic',
        level: 1,
        type: 'image',
        imageKey: 'machines_valentines_picnic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gooh_refinery',
        level: 1,
        type: 'image',
        imageKey: 'machines_gooh_refinery_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gooh_refinery',
        level: 2,
        type: 'image',
        imageKey: 'machines_gooh_refinery_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gooh_refinery',
        level: 3,
        type: 'image',
        imageKey: 'machines_gooh_refinery_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gooh_refinery',
        level: 4,
        type: 'image',
        imageKey: 'machines_gooh_refinery_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gooh_refinery',
        level: 5,
        type: 'image',
        imageKey: 'machines_gooh_refinery_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'alchemist_lab',
        level: 1,
        type: 'image',
        imageKey: 'machines_alchemist_lab_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'brawler_cage',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/brawler_cage_level_1_sprite.png',
        numFrames: 52,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 87,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mother_wolf_statue',
        level: 1,
        type: 'image',
        imageKey: 'machines_mother_wolf_statue_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'porcelain_throne',
        level: 1,
        type: 'image',
        imageKey: 'machines_porcelain_throne_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'others_receiver',
        level: 1,
        type: 'image',
        imageKey: 'machines_others_receiver_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'space_cactus',
        level: 1,
        type: 'image',
        imageKey: 'machines_space_cactus_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: '1st_birthday_cake',
        level: 1,
        type: 'image',
        imageKey: 'machines_1st_birthday_cake_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // COLLAB PROJECT MACHINES
      // Gapes - Gasa Embassy
      {
        key: 'gasa_embassy',
        level: 1,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gasa_embassy',
        level: 2,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gasa_embassy',
        level: 3,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gasa_embassy',
        level: 4,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gasa_embassy',
        level: 5,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'gasa_embassy',
        level: 6,
        type: 'image',
        imageKey: 'machines_gasa_embassy_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Kaiju Kingmaker
      {
        key: 'kaiju_kingmaker',
        level: 1,
        type: 'image',
        imageKey: 'machines_kaiju_kingmaker_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'kaiju_kingmaker',
        level: 2,
        type: 'image',
        imageKey: 'machines_kaiju_kingmaker_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'kaiju_kingmaker',
        level: 3,
        type: 'image',
        imageKey: 'machines_kaiju_kingmaker_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Little Lemon Trees
      {
        key: 'little_lemon_tree',
        level: 1,
        type: 'image',
        imageKey: 'machines_little_lemon_tree_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'little_lemon_tree',
        level: 2,
        type: 'image',
        imageKey: 'machines_little_lemon_tree_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'little_lemon_tree',
        level: 3,
        type: 'image',
        imageKey: 'machines_little_lemon_tree_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // AIC Labs
      {
        key: 'aic_lab',
        level: 1,
        type: 'image',
        imageKey: 'machines_aic_lab_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'aic_lab',
        level: 2,
        type: 'image',
        imageKey: 'machines_aic_lab_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'aic_lab',
        level: 3,
        type: 'image',
        imageKey: 'machines_aic_lab_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // MVHQ
      {
        key: 'mvhq',
        level: 1,
        type: 'image',
        imageKey: 'machines_mvhq_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mvhq',
        level: 2,
        type: 'image',
        imageKey: 'machines_mvhq_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mvhq',
        level: 3,
        type: 'image',
        imageKey: 'machines_mvhq_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mvhq',
        level: 4,
        type: 'image',
        imageKey: 'machines_mvhq_level_4',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Kuma Ancestor Shrine
      {
        key: 'kuma_ancestor_shrine',
        level: 1,
        type: 'image',
        imageKey: 'machines_kuma_ancestor_shrine_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'kuma_ancestor_shrine',
        level: 2,
        type: 'image',
        imageKey: 'machines_kuma_ancestor_shrine_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'kuma_ancestor_shrine',
        level: 3,
        type: 'image',
        imageKey: 'machines_kuma_ancestor_shrine_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Dimension X
      {
        key: 'dimension_x',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/dimension_x_level_1_sprite.png',
        numFrames: 5,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'dimension_x',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/dimension_x_level_2_sprite.png',
        numFrames: 5,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'dimension_x',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/dimension_x_level_3_sprite.png',
        numFrames: 5,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Defensive Machines
      {
        key: 'plasma_cannon',
        level: 1,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 2,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 3,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 4,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 5,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 6,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 7,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 8,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 9,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_cannon',
        level: 10,
        type: 'image',
        imageKey: 'machines_plasma_cannon_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Lightning Tower
      {
        key: 'lightning_tower',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_1_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_1_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_1_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_2_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_2_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_2_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_3_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 112,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_3_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 112,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_3_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 112,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'lightning_tower',
        level: 10,
        type: 'animation',
        filePath: 'assets/homebase/machines/lightning_tower_level_3_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 112,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Barracks
      {
        key: 'barracks',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_1_sprite.png',
        numFrames: 6,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_1_sprite.png',
        numFrames: 6,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_1_sprite.png',
        numFrames: 6,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_2_sprite.png',
        numFrames: 7,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_2_sprite.png',
        numFrames: 7,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_2_sprite.png',
        numFrames: 7,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_3_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 120,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_3_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 120,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_3_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 120,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'barracks',
        level: 10,
        type: 'animation',
        filePath: 'assets/homebase/machines/barracks_level_3_sprite.png',
        numFrames: 12,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 120,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Cadet dorms
      {
        key: 'cadet_dorms',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_1_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_1_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_1_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 4,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_2_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 5,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_2_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 6,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_2_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 7,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_3_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 125,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 8,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_3_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 125,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 9,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_3_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 125,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'cadet_dorms',
        level: 10,
        type: 'animation',
        filePath: 'assets/homebase/machines/cadet_dorms_level_3_sprite.png',
        numFrames: 14,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 125,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Mercenary Wolvez Hideout
      {
        key: 'mercenary_wolvez_hideout',
        level: 1,
        type: 'image',
        imageKey: 'machines_mercenary_wolvez_hideout_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mercenary_wolvez_hideout',
        level: 2,
        type: 'image',
        imageKey: 'machines_mercenary_wolvez_hideout_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mercenary_wolvez_hideout',
        level: 3,
        type: 'image',
        imageKey: 'machines_mercenary_wolvez_hideout_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'mercenary_wolvez_hideout',
        level: 4,
        type: 'image',
        imageKey: 'machines_mercenary_wolvez_hideout_level_4',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Super Swole Squad Department
      {
        key: 'super_swole_squad_department',
        level: 1,
        type: 'image',
        imageKey: 'machines_super_swole_squad_department_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'super_swole_squad_department',
        level: 2,
        type: 'image',
        imageKey: 'machines_super_swole_squad_department_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'super_swole_squad_department',
        level: 3,
        type: 'image',
        imageKey: 'machines_super_swole_squad_department_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Medical Academy
      {
        key: 'medical_academy',
        level: 1,
        type: 'image',
        imageKey: 'machines_medical_academy_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'medical_academy',
        level: 2,
        type: 'image',
        imageKey: 'machines_medical_academy_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'medical_academy',
        level: 3,
        type: 'image',
        imageKey: 'machines_medical_academy_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'medical_academy',
        level: 4,
        type: 'image',
        imageKey: 'machines_medical_academy_level_4',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Anti-Rat Squad Department
      {
        key: 'antirat_squad_department',
        level: 1,
        type: 'image',
        imageKey: 'machines_antirat_squad_department_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'antirat_squad_department',
        level: 2,
        type: 'image',
        imageKey: 'machines_antirat_squad_department_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'antirat_squad_department',
        level: 3,
        type: 'image',
        imageKey: 'machines_antirat_squad_department_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'antirat_squad_department',
        level: 4,
        type: 'image',
        imageKey: 'machines_antirat_squad_department_level_4',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Laser Tower
      {
        key: 'laser_cannon',
        level: 1,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 2,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 3,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 4,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 5,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 6,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'laser_cannon',
        level: 7,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_3',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'laser_cannon',
        level: 8,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_3',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'laser_cannon',
        level: 9,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_3',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      {
        key: 'laser_cannon',
        level: 10,
        type: 'image',
        imageKey: 'machines_laser_cannon_level_4',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // Plasma Mortar
      {
        key: 'plasma_mortar',
        level: 1,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 2,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 3,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 4,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 5,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 6,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'plasma_mortar',
        level: 7,
        type: 'image',
        imageKey: 'machines_plasma_mortar_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Shield Battery
      {
        key: 'shield_battery',
        level: 1,
        type: 'image',
        imageKey: 'machines_shield_battery_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 2,
        type: 'image',
        imageKey: 'machines_shield_battery_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 3,
        type: 'image',
        imageKey: 'machines_shield_battery_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 4,
        type: 'image',
        imageKey: 'machines_shield_battery_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 5,
        type: 'image',
        imageKey: 'machines_shield_battery_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 6,
        type: 'image',
        imageKey: 'machines_shield_battery_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'shield_battery',
        level: 7,
        type: 'image',
        imageKey: 'machines_shield_battery_level_3',
        baseCellWidth: 1,
        baseCellHeight: 2,
      },
      // Photon Shield
      {
        key: 'photon_shield',
        level: 1,
        type: 'image',
        imageKey: 'machines_photon_shield_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 2,
        type: 'image',
        imageKey: 'machines_photon_shield_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 3,
        type: 'image',
        imageKey: 'machines_photon_shield_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 4,
        type: 'image',
        imageKey: 'machines_photon_shield_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 5,
        type: 'image',
        imageKey: 'machines_photon_shield_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 6,
        type: 'image',
        imageKey: 'machines_photon_shield_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'photon_shield',
        level: 7,
        type: 'image',
        imageKey: 'machines_photon_shield_level_3',
        baseCellWidth: 2,
        baseCellHeight: 2,
      },
      // Saved Souls
      {
        key: 'saved_souls',
        level: 1,
        type: 'image',
        imageKey: 'machines_saved_souls_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'saved_souls',
        level: 2,
        type: 'image',
        imageKey: 'machines_saved_souls_level_2',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'saved_souls',
        level: 3,
        type: 'image',
        imageKey: 'machines_saved_souls_level_3',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'sunday_skirmish_trophy',
        level: 1,
        type: 'image',
        imageKey: 'machines_sunday_skirmish_trophy_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'sunday_skirmish_trophy2',
        level: 1,
        type: 'image',
        imageKey: 'machines_sunday_skirmish_trophy2_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // Zen Garden
      {
        key: 'zen_garden',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/zen_garden_level_1_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'zen_garden',
        level: 2,
        type: 'animation',
        filePath: 'assets/homebase/machines/zen_garden_level_2_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 64,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'zen_garden',
        level: 3,
        type: 'animation',
        filePath: 'assets/homebase/machines/zen_garden_level_3_sprite.png',
        numFrames: 10,
        frameRate: 10,
        frameWidth: 64,
        frameHeight: 88,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'christmas_tree_2023',
        level: 1,
        type: 'image',
        imageKey: 'machines_christmas_tree_2023_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'community_nominee_2023',
        level: 1,
        type: 'image',
        imageKey: 'machines_community_nominee_2023_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'community_hero_2023',
        level: 1,
        type: 'image',
        imageKey: 'machines_community_hero_2023_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'nine_lives_lounge',
        level: 1,
        type: 'image',
        imageKey: 'machines_nine_lives_lounge_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'glfhers_arcade',
        level: 1,
        type: 'image',
        imageKey: 'machines_glfhers_arcade_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'pirate_nation_golden_galleon',
        level: 1,
        type: 'image',
        imageKey: 'machines_pirate_nation_golden_galleon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'pirate_nation_wood_galleon',
        level: 1,
        type: 'image',
        imageKey: 'machines_pirate_nation_wood_galleon_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      // {
      //   key: 'bp_blob_cosmetic',
      //   level: 1,
      //   type: 'image',
      //   imageKey: 'machines_bp_blob_cosmetic_level_1',
      //   baseCellWidth: 1,
      //   baseCellHeight: 1,
      // },
      {
        key: 'bp_blob_cosmetic',
        level: 1,
        type: 'animation',
        filePath: 'assets/homebase/machines/machines_bp_blob_cosmetic_level_1_sprite.png',
        numFrames: 8,
        frameRate: 10,
        frameWidth: 51,
        frameHeight: 42,
        repeat: -1,
        repeatDelay: 0,
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bp_ice_storm_tracker_cosmetic',
        level: 1,
        type: 'image',
        imageKey: 'machines_bp_ice_storm_tracker_cosmetic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bp_water_cooler_cosmetic',
        level: 1,
        type: 'image',
        imageKey: 'machines_bp_water_cooler_cosmetic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bp_fire_storm_tracker_cosmetic',
        level: 1,
        type: 'image',
        imageKey: 'machines_bp_fire_storm_tracker_cosmetic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bp_star_wolvez_helmet_cosmetic',
        level: 1,
        type: 'image',
        imageKey: 'machines_bp_star_wolvez_helmet_cosmetic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
      {
        key: 'bp_piece_of_home_cosmetic',
        level: 1,
        type: 'image',
        imageKey: 'machines_bp_piece_of_home_cosmetic_level_1',
        baseCellWidth: 1,
        baseCellHeight: 1,
      },
    ],
    url: 'https://api.starwolvez.com/',
  },
};

export default config;
