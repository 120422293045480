import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

const paneHeight = 257;
const paneWidth = 130;
const paneRadius = 7;
const borderWidth = 1;

// Item
const itemHeight = 146;
const itemWidth = 116;
const itemBackgroundColor = 0xb48dc7;
const itemAlpha = 40;
const itemLockedImageAlpha = 50;

export default class BattlePassItemImageComponent extends OverlapSizer {
  private _scale;
  private _battlePassLevel;
  private _itemData;
  private _claimed;

  // Item
  private _itemBackground;
  private _itemImage;
  private _raffleRock;
  private _unlockedTick;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this._itemData = data.item;
    this._battlePassLevel = data.level;

    // Determines if Reward has been claimed by player
    this._claimed = (this._itemData.claimed as string) == '0' ? false : true;

    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    //var unlocked = this._battlePassLevel >= this._itemData.level ? true : false;

    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(paneWidth),
      height: scene.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Item
    this._itemBackground = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(itemWidth), // width
      scene.applyScale(itemHeight), // height
      scene.applyScale(paneRadius), // radius
      itemBackgroundColor, // fillColor
      itemAlpha, // alpha
    );

    this.add(this._itemBackground, {
      key: 'item-background',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(49),
      expand: false,
    });

    this._unlockedTick = scene.add.image(0, 0, 'battlepass_unlocked').setScale(this._scale);
    this._unlockedTick.visible = this._claimed;

    sizerPanel.add(this._unlockedTick, {
      key: 'bp-tick',
      align: 'left-top',
      offsetX: this.applyScale(88),
      offsetY: this.applyScale(33),
      expand: false,
    });

    this._itemImage = scene.add.image(0, 0, this._itemData.imageKey).setScale(this._scale);

    sizerPanel.add(this._itemImage, {
      key: 'bp-image',
      align: 'center',
      offsetX: this.applyScale(-6),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassItemImageComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new BattlePassItemImageComponent(this.scene, x, y, data));
});
