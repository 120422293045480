import { Schema, ArraySchema } from '@colyseus/schema';
import BRMessage from './BRMessage';
import BRParticipant from './BRParticipant';


export enum GameState
{
	PreGame,
	BattleRoyale,
	BRIntermission,
	PVP,
	GameOver,
	Failed
}

export interface IBattleRoyaleState extends Schema {
  gameState: GameState;
  countDown: number;
  round: number;
  participantsRemaining: number;
  participants: ArraySchema<BRParticipant>;
  gameParticipants: ArraySchema<BRParticipant>;
  viewers: ArraySchema<BRParticipant>;
  messages: ArraySchema<BRMessage>;
}

export default IBattleRoyaleState;
