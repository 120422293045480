import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import Button from '../buttons/Button';

const backgroundHeight = 399;
const backgroundWidth = 532;
const backgroundRadius = 17;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 40;
const borderWidth = 1;

export default class BattlePassRaffleRocksComponent extends OverlapSizer {
  private _scale;
  private _background;
  private _titleText;
  private _titleSubtext;
  private _raffleRocksTitle;
  private _raffleRock;
  private _raffleRockCount;
  private _assignButton;
  private _assignAllButton;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          x: x,
          y: y,
          width: paneScale * 150,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);

    this._scale = paneScale;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Sizer for entrie width and height
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: scene.applyScale(backgroundWidth),
      height: scene.applyScale(backgroundHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    // Background
    const background = scene.add.rexRoundRectangle(
      0, // x
      0, // y
      scene.applyScale(backgroundWidth), // width
      scene.applyScale(backgroundHeight), // height
      scene.applyScale(backgroundRadius), // radius
      backgroundColor, // fillColor
      backgroundAlpha, // alpha
    );

    this.add(background, {
      key: 'background',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Text
    this._titleText = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Assign your Raffle Rocks', this.applyScale(18), 0);
    sizerPanel.add(this._titleText, {
      key: 'title-text',
      align: 'left-top',
      offsetX: this.applyScale(40),
      offsetY: this.applyScale(34),
      expand: false,
    });

    this._titleSubtext = this.scene.add.bitmapText(
      0,
      0,
      'cc_outline',
      'Each Raffle Rock that you have collected acts as ONE raffle ticket. These can be earned in game or bought from the store.',
      this.applyScale(14),
      0,
    );
    sizerPanel.add(this._titleSubtext, {
      key: 'title-sub-text',
      align: 'left-top',
      offsetX: this.applyScale(40),
      offsetY: this.applyScale(76),
      expand: false,
    });

    // Text
    this._raffleRocksTitle = this.scene.add.bitmapText(0, 0, 'cc_outline', 'AVAILABLE RAFFLE ROCKS', this.applyScale(18), 0);
    sizerPanel.add(this._raffleRocksTitle, {
      key: 'raffle-text',
      align: 'left-top',
      offsetX: this.applyScale(40),
      offsetY: this.applyScale(184),
      expand: false,
    });

    // Image
    this._raffleRock = scene.add.image(0, 0, 'battlepass_mini_rock').setScale(this._scale);

    sizerPanel.add(this._raffleRock, {
      key: 'raffle-rock',
      align: 'left-top',
      offsetX: this.applyScale(40),
      offsetY: this.applyScale(212),
      expand: false,
    });

    this._raffleRockCount = this.scene.add.bitmapText(0, 0, 'cc_outline', '5', this.applyScale(26), 0);
    sizerPanel.add(this._raffleRockCount, {
      key: 'count-text',
      align: 'left-top',
      offsetX: this.applyScale(82),
      offsetY: this.applyScale(212),
      expand: false,
    });

    // Buttons
    this._assignButton = new Button(scene, 0, 0, 'battlepass_assign_button', true, 1.05).setScale(this._scale);
    this._assignButton.onClick().subscribe(pointer => {});
    scene.add.existing(this._assignButton);
    sizerPanel.add(this._assignButton, {
      key: 'asign-button',
      align: 'left-top',
      offsetX: this.applyScale(40),
      offsetY: this.applyScale(306),
      expand: false,
    });

    this._assignAllButton = new Button(scene, 0, 0, 'battlepass_assign_all_button', true, 1.05).setScale(this._scale);
    this._assignAllButton.onClick().subscribe(pointer => {});
    scene.add.existing(this._assignAllButton);
    sizerPanel.add(this._assignAllButton, {
      key: 'asign-all-button',
      align: 'left-top',
      offsetX: this.applyScale(277),
      offsetY: this.applyScale(306),
      expand: false,
    });

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('battlePassWeeklyRaffleComponent', function (x: number, y: number, data: any) {
  // @ts-ignore
  return this.displayList.add(new BattlePassWeeklyRaffleComponent(this.scene, x, y, data));
});
