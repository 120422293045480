import ApiProxyService from './ApiProxyService';
import WalletConnectService from './WalletConnectService';
import util from 'util';

const objectiveTypes = [
  {
    key: 'staking',
    displayName: 'Soft Staking',
    isSelected: true,
  },
  {
    key: 'quick_quests',
    displayName: 'Quick Quests',
    isSelected: false,
  },
  {
    key: 'partner_quests',
    displayName: 'Partner Quests',
    isSelected: false,
  },
  {
    key: 'milestones',
    displayName: 'Milestones',
    isSelected: false,
  },
  // {
  //   key: 'battle_pass_daily',
  //   displayName: 'Battle Pass Daily',
  //   isSelected: false,
  // },
  // {
  //   key: 'battle_pass_weekly',
  //   displayName: 'Battle Pass Weekly',
  //   isSelected: false,
  // },
  // {
  //   key: 'battle_pass_monthly',
  //   displayName: 'Battle Pass Monthly',
  //   isSelected: false,
  // },
];

export default class ObjectiveService {
  private static _instance: ObjectiveService = new ObjectiveService();

  private _objectives: any;

  constructor() {
    if (ObjectiveService._instance) {
      throw new Error('Error: Instantiation failed: Use ObjectiveService.getInstance() instead of new.');
    }
    ObjectiveService._instance = this;
  }

  public static getInstance(): ObjectiveService {
    return ObjectiveService._instance;
  }

  private setObjectives(value): void {
    this._objectives = value;
  }

  public getObjectives(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._objectives && !forceUpdate) {
        return resolve(this._objectives);
      } else {
        return resolve(this.fetchObjectives());
      }
    });
  }

  public getObjectivesSync() {
    return this._objectives;
  }

  public updateObjective(objectiveId, newActionValue) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('objectives', [wallet, objectiveId, newActionValue], {})
      .then(res => {
        return res;
      });
  }

  private fetchObjectives() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('objectives', [wallet, 'all'], [])
      .then(res => {
        this._objectives = res;
        return res;
      });
  }

  public getObjectiveTypes() {
    return objectiveTypes;
  }
}
