import Phaser, { Scene } from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import { secondsToTime } from '~/utils/TimeUtils';
import MachineService from '~/services/MachineService';
import TroopService from '~/services/TroopService';
import Button from '../buttons/Button';
import UserService from '~/services/UserService';

const componentWidth = 323;
const componentHeight = 188;
const backgroundColor = 0x2b2b45;
const backgroundRadius = 16;
const backgroundAlpha = 1;

const imageHeight = 115;
const imageWidth = 115;
const imageRadius = 16;

const costLabelHeight = 24;
const costLabelColor = 0x000000;
const costLabelAlpha = 0.4;
const costIconScale = 0.4;

const fontColor = 0xffffff;
const secondaryFontColor = 0x866b7d;

const hoverAlpha = 0.8;
const disabledAlpha = 0.4;

const currencyColors = {
  neon_gold: 0xffe100,
  space_elixir: 0x00d4f7,
  stardust: 0xff87ac,
};

const currencyIcons = {
  neon_gold: 'neon_gold_icon',
  space_elixir: 'space_elixir_icon',
  stardust: 'stardust_icon',
};

const machineUnitIds = {
  ars: 2,
  healer: 4,
  mercenary: 1,
  super_swole: 3,
};

export default class TrainableTroopComponent extends OverlapSizer {
  private _scale;
  private _troopItem;
  private _machine;
  private _currency;

  // UI
  private _train1Button;
  private _train5Button;
  private _train10Button;
  private _train20Button;
  private _train50Button;
  private _train100Button;

  constructor(scene: Phaser.Scene, x: number, y: number, machine: any, troopItem: any, paneScale?: number, config?: any) {
    let conf = {
      width: componentWidth * (paneScale || 1),
      height: componentHeight * (paneScale || 1),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._machine = machine;
    this._troopItem = troopItem;
    this._currency = 'space_elixir';

    this.createComponent(scene, x, y);
  }

  private createComponent(scene: Phaser.Scene, x: number, y: number) {
    // Background
    const bg = scene.add.rexRoundRectangle(
      scene.sys.canvas.width / 2, // x
      scene.sys.canvas.height / 2, // y
      scene.applyScale(componentWidth), // width
      scene.applyScale(componentHeight), // height
      scene.applyScale(backgroundRadius), // radius
      backgroundColor, // fillColor
      backgroundAlpha, // alpha
    );

    this.add(bg, {
      key: 'trainable_troop_bg',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });

    // Troop Image
    this.addTroopImage();

    // Cost Bar
    this.addCostBar();

    // Troop Title
    this.add(scene.add.bitmapText(0, 0, 'cc_outline', this.getDisplayName(), scene.applyScale(18), 0), {
      key: 'troop_title',
      align: 'left-top',
      offsetX: scene.applyScale(145),
      offsetY: this.scene.applyScale(23),
      expand: false,
    });

    // Health Label
    const healthLabel = this.createPropertyLabel('health_icon_small', `${this._troopItem.unit_hitpoints || 0}`);
    this.add(healthLabel, {
      key: 'health_label',
      align: 'left-top',
      offsetX: scene.applyScale(148),
      offsetY: scene.applyScale(78),
      expand: false,
    });

    // Time to Build Label
    const timeToBuildLabel = this.createPropertyLabel('time_icon_small', `${secondsToTime(this._troopItem.unit_time_to_build)}`);
    this.add(timeToBuildLabel, {
      key: 'time_to_build_label',
      align: 'left-top',
      offsetX: scene.applyScale(228),
      offsetY: scene.applyScale(78),
      expand: false,
    });

    // DPS/HPS Label
    if (this._troopItem.machine_unit_id === machineUnitIds.healer) {
      // Show HPS
      const hpsLabel = this.createPropertyLabel('hps_icon_small', `${this._troopItem.unit_heal_per_second || 0}`);
      this.add(hpsLabel, {
        key: 'hps_label',
        align: 'left-top',
        offsetX: scene.applyScale(148),
        offsetY: scene.applyScale(105),
        expand: false,
      });
    } else {
      // Show DPS
      const dpsLabel = this.createPropertyLabel('dps_icon_small', `${this._troopItem.unit_dps || 0}`);
      this.add(dpsLabel, {
        key: 'dps_label',
        align: 'left-top',
        offsetX: scene.applyScale(148),
        offsetY: scene.applyScale(105),
        expand: false,
      });
    }

    // Capacity Label
    const capacityLabel = this.createPropertyLabel(
      'capacity_icon_small',
      TroopService.getInstance().getTroopCapacity(this._troopItem.machine_unit_id),
    );
    this.add(capacityLabel, {
      key: 'capacity_label',
      align: 'left-top',
      offsetX: scene.applyScale(228),
      offsetY: scene.applyScale(105),
      expand: false,
    });

    this.addTrainingButtons();

    this.setEnabled();
  }

  private addTroopImage() {
    const troopImage = this.scene.add
      .rexCircleMaskImage(0, 0, this.getTroopImageKey(), '', {
        maskType: 'roundRectangle',
        radius: imageRadius,
      })
      .setScale(this._scale);

    this.add(troopImage, {
      key: 'troop_image',
      align: 'left-top',
      offsetX: this.scene.applyScale(15),
      offsetY: this.scene.applyScale(12),
      expand: false,
    });
  }

  private addCostBar() {
    const costBarBg = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.scene.applyScale(imageWidth), // width
      this.scene.applyScale(costLabelHeight), // height
      {
        tl: 0,
        tr: 0,
        bl: this.scene.applyScale(imageRadius),
        br: this.scene.applyScale(imageRadius),
      }, // radius
      costLabelColor, // fillColor
      costLabelAlpha, // alpha
    );

    const costLabel = this.scene.rexUI.add.label({
      x: 0,
      y: 0,
      width: this.scene.applyScale(imageWidth),
      height: this.scene.applyScale(costLabelHeight),
      orientation: 'x',
      rtl: false,
      icon: this._currency ? this.scene.add.image(0, 0, currencyIcons[this._currency]).setScale(this.scene.applyScale(costIconScale)) : undefined,
      text: this.scene.add
        .bitmapText(
          0,
          0,
          'cc_outline',
          this._troopItem.unit_cost_to_build ? this._troopItem.unit_cost_to_build.toLocaleString('en-us') : 0,
          this.scene.applyScale(14),
          0,
        )
        .setTint(currencyColors[this._currency]),
      space: {
        icon: this.scene.applyScale(6),
      },
      align: 'center',
    });

    this.add(costBarBg, {
      key: 'cost_bar_bg',
      align: 'left-top',
      offsetX: this.scene.applyScale(15),
      offsetY: this.scene.applyScale(103),
      expand: false,
    });

    this.add(costLabel, {
      key: 'cost_label',
      align: 'left-top',
      offsetX: this.scene.applyScale(15),
      offsetY: this.scene.applyScale(103),
      expand: false,
    });
  }

  private addTrainingButtons() {
    this._train1Button = this.addTrainingButton(1, 15);
    this._train5Button = this.addTrainingButton(5, 51);
    this._train10Button = this.addTrainingButton(10, 88);
    this._train20Button = this.addTrainingButton(20, 134);
    this._train50Button = this.addTrainingButton(50, 182);
    this._train100Button = this.addTrainingButton(100, 230);
  }

  private addTrainingButton(count: number, offsetX: number) {
    const button = new Button(this.scene, 0, 0, `train_${count}_inactive`, false).setScale(this._scale);

    button.onClick().subscribe(pointer => {
      button.disableInteractive();
      this.troopTrainingClickListener(button, count);
    });

    this.scene.add.existing(button);
    this.add(button, {
      key: `train${count}-button`,
      align: 'left-top',
      offsetX: this.scene.applyScale(offsetX),
      offsetY: this.scene.applyScale(142),
      expand: false,
    });

    return button;
  }

  // private getTroopTraitsText() {
  //   return this._troopItem.machine_unit_id === machineUnitIds.healer
  //     ? `${this._troopItem.unit_heal_per_second || 0} heal per sec\n${this._troopItem.unit_hitpoints || 0} HP`
  //     : `${this._troopItem.unit_dps || 0} dmg per sec\n${this._troopItem.unit_hitpoints || 0} HP`;
  // }

  private getDisplayName() {
    if (this._troopItem && this._troopItem.machine_unit_name && this._troopItem.level) {
      return `${this._troopItem.machine_unit_name?.toUpperCase()} LV. ${this._troopItem.level}`;
    } else {
      return 'UNKONWN LV. 0';
    }
  }

  private getTroopImageKey() {
    switch (this._troopItem.machine_unit_id) {
      case machineUnitIds.ars:
        return 'troops_ars_small';
      case machineUnitIds.healer:
        return 'troops_healer_small';
      case machineUnitIds.mercenary:
        return 'troops_mercenary_small';
      case machineUnitIds.super_swole:
        return 'troops_super_swole_small';
      default:
        return 'troops_mercenary_small';
    }
  }

  private createPropertyLabel(iconImageKey, propertyValueText) {
    return this.scene.rexUI.add.label({
      x: 0,
      y: 0,
      width: this.scene.applyScale(70),
      height: this.scene.applyScale(20),
      orientation: 'x',
      rtl: false,
      icon: this.scene.add.image(0, 0, iconImageKey).setScale(this._scale),
      text: this.scene.add.bitmapText(0, 0, 'cc_outline', propertyValueText, this.scene.applyScale(18), 0).setTint(secondaryFontColor),
      space: {
        //top: this.scene.applyScale(-2),
        icon: this.scene.applyScale(6),
      },
      align: 'left',
    });
  }

  public isTroopEnabled() {
    // Check capacity
    const capacity = MachineService.getInstance().getCadetDormsCapacity();
    const troopCapacityUsed = TroopService.getInstance().getTroopCapacityUsed();

    if (troopCapacityUsed >= capacity) {
      return false;
    }

    // Check machine level
    if (this._machine.level) {
      return this._troopItem.base_machine_level_required <= this._machine.level;
    }

    const machineLevel = MachineService.getInstance().getUserMachineLevel(this._troopItem.machine_id);
    return this._troopItem.base_machine_level_required <= machineLevel;
  }

  public setEnabled() {
    // Enable/Disable component, set/disable interactive
    if (this.isTroopEnabled()) {
      //this.setInteractive({ cursor: 'pointer' });

      this.setChildrenInteractive({
        //click: { mode: 'release', clickInterval: 100 },
        over: true,
        //tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
        //press: { time: 500, threshold: 9 },
        swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
        inputEventPrefix: 'child.',
      });

      this.on('child.over', (child, pointer, event) => {
        this.setAlpha(hoverAlpha);
      });

      this.on('child.out', (child, pointer, event) => {
        this.setAlpha(1);
      });

      // this.on('child.down', (child, pointer, event) => {
      //   this.setAlpha(1);
      // });

      // this.on('child.up', (child, pointer, event) => {
      //   this.setAlpha(hoverAlpha);
      // });

      this.setupActiveTrainingButtons();
    } else {
      this.disableInteractive();
      this.setAlpha(disabledAlpha);

      // update interativity of troop training buttons
      this.disableTroopTrainingButtons();
    }
    this.layout();
  }

  setupActiveTrainingButtons() {
    const capacity = MachineService.getInstance().getCadetDormsCapacity();
    const troopCapacityUsed = TroopService.getInstance().getTroopCapacityUsed();
    const spaceElixirTotal = UserService.getInstance().getUserDataSync().space_elixir;
    const troopCapacity = TroopService.getInstance().getTroopCapacity(this._troopItem.machine_unit_id);
    const troopTrainCost = this._troopItem.unit_cost_to_build;
    const remainingTroopCapacity = capacity - troopCapacityUsed;

    // Reusable function to set button properties
    const setButtonProperties = (button, activeSuffix, inactiveSuffix, count) => {
      const requiredCapacity = count * troopCapacity;
      const requiredTrainCost = troopTrainCost * count;

      if (remainingTroopCapacity >= requiredCapacity && requiredTrainCost <= spaceElixirTotal) {
        button.setTexture(`train_${count}_${activeSuffix}`);
        button.setInteractive();
      } else {
        button.setTexture(`train_${count}_${inactiveSuffix}`);
        button.disableInteractive();
      }
    };

    // Train 1 Button
    setButtonProperties(this._train1Button, 'active', 'inactive', 1);

    // Train 5 Button
    setButtonProperties(this._train5Button, 'active', 'inactive', 5);

    // Train 10 Button
    setButtonProperties(this._train10Button, 'active', 'inactive', 10);

    // Train 20 Button
    setButtonProperties(this._train20Button, 'active', 'inactive', 20);

    // Train 50 Button
    setButtonProperties(this._train50Button, 'active', 'inactive', 50);

    // Train 100 Button
    setButtonProperties(this._train100Button, 'active', 'inactive', 100);
  }

  troopTrainingClickListener(button: Button, units: number) {
    if (this.isTroopEnabled()) {
      const userMachineId =
        this._machine.user_machine_id === 0
          ? MachineService.getInstance().getUserMachineFromMachineId(this._troopItem.machine_id).user_machine_id
          : this._machine.user_machine_id;

      TroopService.getInstance()
        .trainTroops(userMachineId, this._troopItem.level, units)
        .then(res => {
          TroopService.getInstance()
            .getArmy(true)
            .then(armyRes => {
              if (res.success) {
                // Update training troops component
                this.scene.updateTrainingPane();
                this.scene.updateCapacityBar();
              } else {
                // Show training failed message
                this.scene.scene.pause();
                this.scene.scene.launch('MessageScene', {
                  title: 'Failed to train troop!',
                  message: 'Your Cadet Dorms are at max capacity.',
                  scene: 'ArmyModalScene',
                });
              }

              button.setInteractive();
            });
        });
    }
  }

  disableTroopTrainingButtons() {
    this._train1Button.disableInteractive();
    this._train5Button.disableInteractive();
    this._train10Button.disableInteractive();
    this._train20Button.disableInteractive();
    this._train50Button.disableInteractive();
    this._train100Button.disableInteractive();
  }
}

Phaser.GameObjects.GameObjectFactory.register('trainableTroopComponent', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new TrainableTroopComponent(this.scene, x, y));
});
