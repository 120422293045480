import Phaser, { Scene } from 'phaser';
import { FixWidthSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import PvPSquadMemberComponent from './pvpSquadMemberComponent';
import ProgressBar from '~/components/progressBar/ProgressBar';
import { isPVPTournament } from '~/utils/GameUtils';
import { createPvpTraitsDisplayList } from '~/utils/TraitUtils';

const paneWidth = 294;
const paneHeight = 822 - 94; // Special Traits section is 94px - to be added later
const paneRadius = 16;
const paneColor = 0x1c1c2c;
const paneAlpha = 1;
const paneCellHeight = 60;

const pfpWidth = 262;
const pfpRadius = 20;

const progressBarWidth = 238;
const progressBarHeight = 22;
const progressBarRadius = 7;
const progressBarBackgroundColor = 0xb48dc7;
const progressBarBackgroundAlpha = 0.48;

const hpProgressBarColor = 0x00a6d3;
const hpProgressBarAlpha = 1;

const apProgressBarColor = 0x9f0a76;
const apProgressBarAlpha = 1;

const glareHeight = 6;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareOffset = 6;
const glareRadius = 3;

export default class PvpPlayerPane extends FixWidthSizer {
  private _pfpImage;
  private _scale;
  private _playerData;
  private _hpProgressBar;
  private _apProgressBar;
  private _maxHp;
  private _currentHp;
  private _maxAp;
  private _currentAp;
  private _traits;

  constructor(scene: Phaser.Scene, x: number, y: number, data: any, paneScale: number, config?: any) {
    // data { image_key, display_name, hp, show_yield, show_hp, yield? }
    let conf = config
      ? config
      : {
          width: paneScale * paneWidth,
          height: paneScale * paneHeight,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
        };
    super(scene, x, y, conf);
    this._scale = paneScale;
    this._playerData = data;
    this._traits = data.traits;
    this.createComponent(scene, x, y, data);
  }

  private createComponent(scene, x, y, data) {
    // Background
    const background = this.scene.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(paneWidth), // width
      this.applyScale(paneHeight), // height
      this.applyScale(paneRadius), // radius
      paneColor, // fillColor
      paneAlpha, // alpha
    );

    this.addBackground(background);

    // PFP Image
    let rectConfig = {
      maskType: 'roundRectangle',
      radius: 0,
    };

    const pfpImageData = new Phaser.GameObjects.Image(scene, 0, 0, this.getImageKey(this._playerData.squadMembers[0]));
    const pfpImageWidth = pfpImageData.width;
    rectConfig.radius = pfpImageWidth / 8;

    // Sets PFP based on if Player is a bot or not.
    var imageKey = this.getImageKey(this._playerData.squadMembers[0]);
    this._pfpImage = this.scene.add.rexCircleMaskImage(0, 0, imageKey, '', rectConfig);
    this._pfpImage.displayWidth = this.applyScale(pfpWidth);
    this._pfpImage.scaleY = this._pfpImage.scaleX;
    pfpImageData.destroy();

    this.add(this._pfpImage, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: this.applyScale(18),
        bottom: this.applyScale(18),
      },
      key: 'pfp_image',
    });

    // Player Label
    const usernameLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', this.getUserName(this._playerData), this.applyScale(18), 0);

    this.add(usernameLabel, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: 0,
        bottom: this.applyScale(18),
      },
      key: 'username-label',
    });

    // Divider
    const divider = this.scene.add.image(0, 0, 'pvp_player_divider').setScale(this._scale);

    this.add(divider, {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'upper-divider',
    });

    // Progress Bars (HP/AP)

    // HP
    this._maxHp = 1000;
    if (!isPVPTournament) {
      this._maxHp = this._playerData?.squadMembers
        .map(sm => {
          return sm.hp || 0;
        })
        .reduce((previous, current) => {
          return previous + current;
        });
    }

    if (this._playerData.health !== undefined) {
      this._maxHp = this._playerData.health;
    }

    this._currentHp = this._maxHp;

    this._hpProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      this._scale,
      {
        rtl: false,
        title: {
          text: '',
        },
        icon: {
          key: 'pvp_hp_icon',
          space: 8,
          scale: 0.5,
        },
        progressBar: {
          text: `${this._currentHp} / ${this._maxHp}`,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: progressBarRadius,
          color: hpProgressBarColor,
          alpha: hpProgressBarAlpha,
          backgroundColor: progressBarBackgroundColor,
          backgroundAlpha: progressBarBackgroundAlpha,
          minValue: 0,
          maxValue: this._maxHp,
          progressValue: this._currentHp,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
    this.scene.add.existing(this._hpProgressBar);

    this.add(this._hpProgressBar, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: this.applyScale(18),
        bottom: 0,
      },
      key: 'hp-progress-bar',
    });

    // AP
    this._maxAp = 1000;
    this._currentAp = 100;

    this._apProgressBar = new ProgressBar(
      this.scene,
      0, // x
      0, // y
      this._scale,
      {
        rtl: false,
        title: {
          text: '',
        },
        icon: {
          key: 'pvp_ap_icon',
          space: 8,
          scale: 0.5,
        },
        progressBar: {
          text: this._currentAp,
          width: progressBarWidth,
          height: progressBarHeight,
          radius: progressBarRadius,
          color: apProgressBarColor,
          alpha: apProgressBarAlpha,
          backgroundColor: progressBarBackgroundColor,
          backgroundAlpha: progressBarBackgroundAlpha,
          minValue: 0,
          maxValue: this._maxAp,
          progressValue: this._currentAp,
          glare: {
            height: glareHeight,
            width: progressBarWidth - glareOffset,
            radius: glareRadius,
            color: glareColor,
            alpha: glareAlpha,
          },
        },
      },
    );
    this.scene.add.existing(this._apProgressBar);

    this.add(this._apProgressBar, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: this.applyScale(18),
        bottom: 0,
      },
      key: 'hp-progress-bar',
    });

    // Divider
    const lowerDivider = this.scene.add.image(0, 0, 'pvp_player_divider').setScale(this._scale);

    this.add(lowerDivider, {
      padding: {
        left: 0,
        right: 0,
        top: this.applyScale(18),
        bottom: this.applyScale(18),
      },
      key: 'lower-divider',
    });

    // Active Squad Pane
    const activeSquadLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Active Squad', this.applyScale(17), 0).setAlpha(0.5);

    this.add(activeSquadLabel, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: 0,
        bottom: this.applyScale(12),
      },
      key: 'active-squad-label',
    });

    const sm1 = new PvPSquadMemberComponent(
      this.scene,
      0,
      0,
      this._playerData?.squadMembers[0] || {
        display_name: 'Genesis Wolf #001',
        image_key: 'starwolf_silhouette',
        hp: 1000,
      },
      this._scale,
    );
    const sm2 = new PvPSquadMemberComponent(
      this.scene,
      0,
      0,
      this._playerData?.squadMembers[1] || {
        display_name: 'Star Wolf #101',
        image_key: 'starwolf_silhouette',
        hp: 1000,
      },
      this._scale,
    );
    const sm3 = new PvPSquadMemberComponent(
      this.scene,
      0,
      0,
      this._playerData?.squadMembers[2] || {
        display_name: 'Star Wolf #102',
        image_key: 'starwolf_silhouette',
        hp: 1000,
      },
      this._scale,
    );
    this.scene.add.existing(sm1);
    this.scene.add.existing(sm2);
    this.scene.add.existing(sm3);

    this.add(sm1, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: 0,
        bottom: this.applyScale(10),
      },
      key: 'squad-member-1',
    });

    this.add(sm2, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: 0,
        bottom: this.applyScale(10),
      },
      key: 'squad-member-2',
    });

    this.add(sm3, {
      padding: { left: this.applyScale(16), right: 0, top: 0, bottom: 0 },
      key: 'squad-member-3',
    });

    // divider
    const lowestDivider = this.scene.add.image(0, 0, 'pvp_player_divider').setScale(this._scale);

    this.add(lowestDivider, {
      padding: {
        left: 0,
        right: 0,
        top: this.applyScale(18),
        bottom: this.applyScale(18),
      },
      key: 'lowest-divider',
    });

    // Special Traits Pane
    const specialTraitsLabel = this.scene.add.bitmapText(0, 0, 'cc_outline', 'Special Traits', this.applyScale(17), 0).setAlpha(0.5);

    this.add(specialTraitsLabel, {
      padding: {
        left: this.applyScale(16),
        right: 0,
        top: 0,
        bottom: this.applyScale(12),
      },
      key: 'special-traits-label',
    });

    const traitsList = createPvpTraitsDisplayList(this._traits);
    const table = this.createTraitsTable(this.scene, traitsList, x, y).layout();
    this.add(table, {
      padding: { left: this.applyScale(16), right: 0, top: 0, bottom: 0 },
      key: 'traits_table',
    });

    this.layout();
  }

  getImageKey(squadMem): string {
    if (squadMem.image_thumbnail_url && squadMem.image_thumbnail_url.length > 0 && squadMem.image_key && squadMem.image_key.length > 0) {
      return squadMem.image_key;
    } else {
      return 'starwolf_silhouette';
    }
  }

  getUserName(user) {
    if (user && user.name && user.name.length > 0) {
      if (user.name.toUpperCase() === 'LUKE' || user.name.toUpperCase() === 'LUKEH') {
        var hasImages = this._playerData.squadMembers.find(mem => mem.image_thumbnail_url.length > 0);
        if (!hasImages) {
          return 'PvP Bot';
        }
      }
      return user.name.toUpperCase();
    } else {
      return 'Unknown';
    }
  }

  createTraitsTable(scene, data, x, y) {
    var table = scene.rexUI.add
      .gridTable({
        width: this.applyScale(paneWidth),
        height: this.applyScale(paneCellHeight * 2),
        items: data,
        scrollMode: 0,

        table: {
          cellWidth: this.applyScale(269),
          cellHeight: this.applyScale(60),
          columns: 1,
          clamplTableOXY: true,
          mask: {
            padding: 2,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.2,
        },

        createCellContainerCallback: function (cell) {
          var scene = cell.scene,
            trait = cell.item;

          // Sizer variables
          const componentWidth = 261,
            componentHeight = 49,
            componentRadius = 12,
            componentColor = 0x262637,
            componentAlpha = 1;

          var sizer = scene.rexUI.add
            .overlapSizer({
              width: scene.applyScale(componentWidth),
              height: scene.applyScale(componentHeight),
              space: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                item: 0,
                line: 0,
              },
              align: 0,
            })
            // Background
            .add(
              scene.add.rexRoundRectangle(
                0, // x
                0, // y
                scene.applyScale(componentWidth),
                scene.applyScale(componentHeight),
                scene.applyScale(componentRadius),
                componentColor,
                componentAlpha,
              ),
              {
                key: 'trait_cell_bg',
                align: 'center',
              },
            )

            // Special Trait Label
            .add(scene.add.bitmapText(0, 0, 'cc_outline', trait.trait?.toUpperCase() + ' #' + trait.id, scene.applyScale(15), 0), {
              key: `special-trait-label`,
              align: 'left-top',
              offsetX: scene.applyScale(9),
              offsetY: scene.applyScale(7),
              expand: false,
            })

            // Special Trait Description Label
            .add(scene.add.bitmapText(0, 0, 'cc_outline', trait.description?.toString(), scene.applyScale(14), 0).setAlpha(0.4), {
              key: `special-trait-description-label`,
              align: 'left-bottom',
              offsetX: scene.applyScale(9),
              offsetY: scene.applyScale(-7),
              expand: false,
            });
          return sizer;
        },
      })
      .setOrigin(0.5, 0);

    return table;
  }

  public updateProgressBars(hp: number, ap: number) {
    if (hp) {
      this._currentHp = hp;
      this._hpProgressBar.setProgress(this, hp);
    }
    if (ap || ap === 0) {
      this._currentAp = ap;
      this._apProgressBar.setProgress(this, ap);
    }
  }

  public resetProgressBars(hp: number, ap: number) {
    if (hp) {
      this._currentHp = hp;
      this._hpProgressBar.updateMaxValue(this, hp);
      this._hpProgressBar.setProgress(this, hp);
    }
    if (ap) {
      this._currentAp = ap;
      this._apProgressBar.setProgress(this, ap);
    }
  }

  private applyScale(length) {
    return length * this._scale;
  }
}

Phaser.GameObjects.GameObjectFactory.register('pvpPlayerPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new PvpPlayerPane(this.scene, x, y));
});
