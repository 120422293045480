import Phaser, { Scene } from 'phaser';
import { ObjectUnsubscribedError, Subscription } from 'rxjs';
import util from 'util';
import ConfigurationService from '~/services/ConfigurationService';
import ProgressBar from '../progressBar/ProgressBar';

const blueProgressBarColor = 0x00a6d3;
const progressBarAlpha = 1;
const glareColor = 0xffffff;
const glareAlpha = 0.17;
const glareRadius = 3;
const glareHeight = 6;
const glareOffset = 10;
const backgroundColor = 0xb48dc7;
const backgroundAlpha = 0.4;
const radius = 7;

const progressBarHeight = 22;
const levelBarWidth = 215;

export default class LevelProgressBarComponent extends ProgressBar {
  private _config;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, userData: any, iconScale: number = 0.7) {
    super(scene, x, y, paneScale, {
      rtl: false,
      title: {
        text: userData.name.toString().toUpperCase(),
        fontStyle: null,
        icon: userData.has_battlepass ? 'battle_pass_icon' : undefined,
        iconScale: userData.has_battlepass ? 1 : undefined,
        iconSpace: userData.has_battlepass ? 5 : undefined,
      },
      icon: {
        key: 'bronze_level_1_badge',
        space: 8,
        scale: iconScale,
      },
      progressBar: {
        text: userData.level.toString(),
        fontStyle: null,
        width: levelBarWidth,
        height: progressBarHeight,
        radius: radius,
        color: blueProgressBarColor,
        alpha: progressBarAlpha,
        backgroundColor: backgroundColor,
        backgroundAlpha: backgroundAlpha,
        minValue: 1,
        maxValue: 10,
        progressValue: userData.xp,
        glare: {
          height: glareHeight,
          width: levelBarWidth - glareOffset,
          radius: glareRadius,
          color: glareColor,
          alpha: glareAlpha,
        },
      },
    });

    this._config = ConfigurationService.getInstance().getConfig();

    this.setValues(scene, userData);
  }

  updateValues(scene: Phaser.Scene, userData: any) {
    if (userData) {
      this.setValues(scene, userData);
    }
  }

  private setValues(scene: Phaser.Scene, userData: any) {
    var levelMinXP = this.getLevelMinXP(userData);
    var levelMaxXP = this.getLevelMaxXP(userData);
    var badgeImageKey = this.getBadgeImageKey(userData, this._config.userBadges);

    this.updateMinValue(scene, levelMinXP);
    this.updateMaxValue(scene, levelMaxXP);
    this.setProgressValue(scene, userData.xp);
    this.setProgressBarText(userData.level.toString());
    this.setIcon(badgeImageKey);
  }

  private getLevelMinXP(userData: any) {
    var level = parseInt(userData.level);

    if (level === 0) {
      return 0;
    } else if (level === 1) {
      return 300;
    } else if (level >= 2 && level <= 15) {
      return (level - 1) * 500;
    } else if (level >= 16 && level <= 30) {
      return (level - 1) * 500 + 250;
    } else if (level >= 31 && level <= 45) {
      return (level - 1) * 500 + 450;
    } else if (level >= 46 && level <= 60) {
      return (level - 1) * 500 + 650;
    } else if (level >= 61 && level <= 75) {
      return (level - 1) * 500 + 1000;
    } else if (level >= 76 && level <= 100) {
      return (level - 1) * 500 + 1450;
    } else if (level >= 101) {
      return (level - 1) * 500 + 2000;
    } else {
      return 1;
    }
  }

  private getLevelMaxXP(userData: any) {
    var nextLevelMinXP = this.getLevelMinXP({ level: parseInt(userData.level) + 1 });
    return nextLevelMinXP - 1;
  }

  private getBadgeImageKey(userData: any, userBadges: any) {
    var badge = userBadges.find(ub => {
      if (ub.maxLevel === null) {
        return userData.level >= ub.minLevel;
      } else {
        return userData.level >= ub.minLevel && userData.level <= ub.maxLevel;
      }
    });
    return badge.badgeImageKey;
  }
}

Phaser.GameObjects.GameObjectFactory.register('levelProgressBarComponent', function (x: number, y: number, userData: any) {
  // @ts-ignore
  return this.displayList.add(new LevelProgressBarComponent(this.scene, x, y, userData));
});
