import ApiProxyService from "./ApiProxyService";
import WalletConnectService from "./WalletConnectService";
import util from 'util';

const defaultNotifications = [
    {
        number: 1,
        title: 'Grab some starter resources',
        description: "Go to the Objectives panel to view all the available objectives and Soft Staking screen. You'll need some!",
        pointerImageKey: 'hand_pointing_10_oclock',
        anchorCorner: 'top-left',
        noteX: 394,
        noteY: 208,
        handX: 165,
        handY: 177
    },
    {
        number: 2,
        title: 'Select your Squad',
        description: "Go here to add your NFTs to your active squad - you'll need them to launch Missions. Pick your rarest collectibles!",
        pointerImageKey: 'hand_pointing_7_oclock',
        anchorCorner: 'bottom-left',
        noteX: 319,
        noteY: -258,
        handX: 120,
        handY: -170
    },
    {
        number: 3,
        title: 'Build your Mainframe',
        description: "Your Mainframe will unlock all new machines that yield, store, and power your Squad's future existence.",
        pointerImageKey: 'hand_pointing_9_oclock',
        anchorCorner: 'bottom-left',
        noteX: 835,
        noteY: -100,
        handX: 615,
        handY: -90
    },
];

export default class NotificationService {

    private static _instance:NotificationService = new NotificationService();

    private _notifications: any;

    constructor() {
        if(NotificationService._instance){
            throw new Error("Error: Instantiation failed: Use NotificationService.getInstance() instead of new.");
        }
        NotificationService._instance = this;
        this.setNotifications(defaultNotifications);
    }

    public static getInstance():NotificationService
    {
        return NotificationService._instance;
    }

    private setNotifications(value):void
    {
        this._notifications = value;
    }

    public getNotifications(forceUpdate: boolean = false)
    {
        // return new Promise((resolve, reject) => {
        //     if (this._notifications && !forceUpdate) {
        //         return resolve(this._notifications);
        //     } else {
        //         return resolve(this.fetchNotifications());
        //     }
        // });
        return this._notifications;
    }

    public getNotificationsSync() {
        return this._notifications;
    }

    public setNotificationClosed(notificationNumber) {
        const wallet = WalletConnectService.getInstance().getWalletAddress();
        return ApiProxyService.getInstance().post('user', [wallet, 'modal', notificationNumber], {})
            .then(res => {
                return res;
            });
    }

    // private fetchNotifications() {
    //     const wallet = WalletConnectService.getInstance().getWalletAddress();
    //     return ApiProxyService.getInstance().get('objectives', [wallet, 'all'], [])
    //         .then(res => {
    //             this._notifications = res;
    //             return res;
    //         });
    // }
}