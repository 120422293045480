import Phaser from 'phaser';
import Button from '../../../components/buttons/Button';
import ScaleService from '~/services/ScaleService';
import util from 'util';
import { Army_Assets, Objective_Assets } from '~/utils/AssetLoader';
import ObjectivesStakingPane from './objectivesStakingPane';
import ObjectiveService from '~/services/ObjectiveService';
import BattlePassService from '~/services/BattlePassService';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';
import NFTService from '~/services/NFTService';
import SquadService from '~/services/SquadService';
import ObjectivesContentPane from './ObjectivesContentPane';
import StakingService from '~/services/StakingService';

const OpenAllAudio = require('url:../../../../public/assets/audios/open-all-button.wav');

const Random = Phaser.Math.Between;

const modalHeight = 595;
const modalWidth = 914;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

const spineWidth = 248;
const spineColor = 0xe6e6ff;
const spineAlpha = 0.05;

const stakingCollectionNames = ['starwolvez-generative', 'starwolves', 'gapes-nft', 'galacticapes', 'galacticapesgenesis', 'galacticmonkes'];

export default class ObjectivesModalScene extends Phaser.Scene {
  private _scale: number;
  private _userData;
  private _closeButton;
  private _stakingContentPane;
  private _objectivesContentPane;
  private _objectiveTypes;
  private _spineTable;
  private _loadingIndicator;
  private _sizer;

  init(data) {
    this._userData = data.userData;
  }
  constructor() {
    super({
      key: 'ObjectivesModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, Objective_Assets);

    this.load.image('army_modal_close_button', Army_Assets.army_modal_close_button);

    this.load.audio('open-all-button', OpenAllAudio);
  }

  create() {
    this.createComponent();

    // BattlePassService.getInstance()
    //   .getObjectives(true)
    //   .then(res => {
    StakingService.getInstance().getStakingInfo(undefined, stakingCollectionNames, 0, 30, true)
      .then(res => {
        this.scene.launch('NftLoaderScene', { originating_scene: 'ObjectivesModalScene', nfts: res.nfts });
      });
      // });
  }

  createComponent() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this._objectiveTypes = ObjectiveService.getInstance().getObjectiveTypes();

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      width: this.applyScale(modalWidth),
      height: this.applyScale(modalHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      sizerEvents: true,
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    this._sizer.addBackground(background, {
      key: 'background',
    });

    const spineBackground = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(spineWidth), // width
      this.applyScale(modalHeight), // height
      {
        tl: this.applyScale(modalRadius),
        tr: 0,
        bl: this.applyScale(modalRadius),
        br: 0,
      }, // radius
      spineColor, // fillColor
      spineAlpha, // alpha
    );

    // Title text
    const titleText = this.add.image(0, 0, 'objectives_text').setOrigin(0).setScale(this._scale);

    // Add spine here
    this._spineTable = this.createSpineTable(this, this._objectiveTypes, 0, 0);

    this._closeButton = new Button(this.scene.scene, 0, 0, 'army_modal_close_button', false).setScale(this._scale);
    this.add.existing(this._closeButton);

    this._closeButton.onClick().subscribe(pointer => {
      this.closeButtonClickHandler(pointer);
    });

    this._sizer.add(spineBackground, {
      key: 'spineBackground',
      align: 'left-top',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });
    this._sizer.add(titleText, {
      key: 'titleText',
      align: 'left-top',
      offsetX: this.applyScale(17),
      offsetY: this.applyScale(31),
      expand: false,
    });
    this._sizer.add(this._spineTable, {
      key: 'table',
      align: 'left-top',
      offsetX: this.applyScale(15),
      offsetY: this.applyScale(60),
      expand: false,
    });
    this._sizer.add(this._closeButton, {
      key: 'closeButton',
      align: 'left-bottom',
      offsetX: this.applyScale(14),
      offsetY: this.applyScale(-18),
      expand: false,
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer.add(this._loadingIndicator, {
      key: 'loading_indicator',
      align: 'center',
      offsetX: this.applyScale(100),
      offsetY: 0,
      expand: false,
    });

    this._sizer.layout();

    this._sizer.onClickOutside(() => {
      this.unloadAssets();

      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });

    this.events.on('resume', () => {
      if (this._stakingContentPane) {
        this._stakingContentPane.getStakingPane().updateTable(true);
        this.events.emit('staking_reward_claimed');
        this._stakingContentPane.setOpenAllEnabled();
      }
    });
  }

  onLoaded() {
    this._loadingIndicator.setAlpha(0);
    this._sizer.remove(this._loadingIndicator, true);
    this._loadingIndicator = undefined;

    this._stakingContentPane = new ObjectivesStakingPane(this, 0, 0, this._scale);

    this._sizer.add(this._stakingContentPane, {
      key: 'staking-content-pane',
      align: 'left-top',
      offsetX: this.applyScale(251),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._sizer.layout();

    this.setIsSelected(this._objectiveTypes[0], true);

    this._stakingContentPane.setOpenAllEnabled();
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  destroy() {
    this._objectiveTypes = undefined;
    this._stakingContentPane = undefined;
    this._objectivesContentPane = undefined;
    this._closeButton = undefined;
    this._spineTable = undefined;
    this._sizer = undefined;
  }

  private createSpineTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(220),
      height: this.applyScale(450),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: this.applyScale(220),
        cellHeight: this.applyScale(32),
        columns: 1,
        clamplTableOXY: false,
        reuseCellContainer: true,
        interactive: true,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        // Template
        let rectConfig = {
          maskType: 'roundRectangle',
          radius: 0,
        };

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(220),
            space: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .setInteractive({ useHandCursor: true })
          .add(
            scene.add
              .rexRoundRectangle(
                0, // x
                0, // y
                scene.applyScale(220), // width
                scene.applyScale(32), // height
                scene.applyScale(6), // radius
                0xe6e6ff, // fillColor
                0.05, // alpha
              )
              .setAlpha(item.isSelected ? 1 : 0),
            {
              key: 'menu_option_bg',
              align: 'center',
              offsetX: 0,
              offsetY: 0,
              expand: false,
            },
          )
          .add(
            scene.rexUI.add.badgeLabel({
              x: 0,
              y: 0,
              space: { left: 0, right: -5, top: 0, bottom: 0 },
              main: scene.rexUI.add.label({
                orientation: 'x',
                rtl: false,
                text: scene.add.bitmapText(0, 0, 'cc_outline', item.displayName, scene.applyScale(16), 0).setAlpha(item.isSelected ? 1 : 0.4),
                space: {
                  icon: scene.applyScale(10),
                  right: scene.applyScale(25),
                },
              }),
              rightCenter: item.displayName.includes('Battle Pass') ? scene.add.image(0, 0, 'battle_pass_icon') : undefined,
            }),
            {
              key: 'menu_option_text',
              align: 'left',
              offsetX: scene.applyScale(10),
              offsetY: scene.applyScale(0),
              expand: false,
            },
          );

        sizer.setChildrenInteractive({
          click: { mode: 'release', clickInterval: 100 },
          over: true,
          tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
          press: { time: 251, threshold: 9 },
          swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
          inputEventPrefix: 'child.',
        });

        sizer.on('child.over', (child, pointer, event) => {
          if (!item.isSelected) {
            sizer.getElement('menu_option_text')?.getElement('main')?.getElement('text')?.setAlpha(1);
          }
        });

        sizer.on('child.out', (child, pointer, event) => {
          if (!item.isSelected) {
            sizer.getElement('menu_option_text')?.getElement('main')?.getElement('text')?.setAlpha(0.4);
          }
        });

        sizer.on('child.click', (child, pointer, event) => {
          scene.setIsSelected(item);
        });

        return sizer;
      },
    });

    table.setItems(data).scrollToTop();
    //table.getElement('table').on('')
    return table;
  }

  setIsSelected(item: any, bypassCheck: boolean) {
    if (!bypassCheck && item.isSelected === true) return;

    this._objectiveTypes = this._objectiveTypes.map(ot => {
      ot.isSelected = ot.displayName === item.displayName ? true : false;
      return ot;
    });

    this._spineTable.setItems(this._objectiveTypes);
    this.setContentPane(item);
  }

  setContentPane(item: any) {
    var contentPane;
    var paneKey;

    if (this._stakingContentPane) {
      this._stakingContentPane.setAlpha(0);
      this._sizer.remove(this._stakingContentPane, true);
      this._stakingContentPane = undefined;
    }
    if (this._objectivesContentPane) {
      this._objectivesContentPane.setAlpha(0);
      this._sizer.remove(this._objectivesContentPane, true);
      this._objectivesContentPane = undefined;
    }

    switch (item.key) {
      case 'staking':
        contentPane = this._stakingContentPane = new ObjectivesStakingPane(this, 0, 0, this._scale, this._sizer);
        this._stakingContentPane.updatePage(1);
        paneKey = 'parent_staking_content_pane';
        break;
      default:
        contentPane = this._objectivesContentPane = new ObjectivesContentPane(this, 0, 0, this._scale, item.key, this._sizer);
        paneKey = `${item.key}_content_pane`;
    }

    this._sizer.add(contentPane, {
      key: paneKey,
      align: 'left-top',
      offsetX: this.applyScale(251),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._sizer.layout();
  }

  private closeButtonClickHandler(this, pointer) {
    this.unloadAssets();

    this.scene.resume('HomebaseScene');
    this.scene.resume('HudScene');
    this.scene.stop();
  }

  updatePage(pageNum: number) {
    this._stakingContentPane.updatePage(pageNum);
  }

  onPageLoaded() {
    this._stakingContentPane.onPageLoaded();
  }

  unloadAssets() {
    this._objectiveTypes = this._objectiveTypes.map(ot => {
      ot.isSelected = ot.displayName === 'Soft Staking' ? true : false;
      return ot;
    });

    UnloadImages(this, Object.keys(Objective_Assets));
    // this._stakingContentPane.unloadImages();
  }
}
