import Phaser from 'phaser';
import { Subject, Observable } from 'rxjs';

const defaultOverScale = 1.01;
const useOverScaleDefault = true;
const tintColor = 0xcdcdcd;

declare global
{
	interface IButton extends Phaser.GameObjects.GameObject, Phaser.GameObjects.Components.Transform
	{
		onClick(): Observable<Phaser.Input.Pointer>

		setUpTexture(texture: string): this
		setDownTexture(texture: string): this
		setOverTexture(texture: string): this
		setDisabledTexture(texture: string): this
		setOverScale(scale: number): this
		setUseOverScaling(useOverGrowth: boolean): this
		width: number
		height: number
		getTopLeft()
		getTopRight()
		getBottomLeft()
		getBottomRight()
		setTint(tint: number)
		clearTint()
		setAlpha(alpha: number)
		setDisabled(disabled: boolean): this
	}
}

export default class Button extends Phaser.GameObjects.Image
{
	private upTexture: string;
	private downTexture: string;
	private overTexture: string;
	private disabledTexture: string;
	private overScale: number;
	private _mask;
	private button;
	private useOverScaling: boolean;
	private _cursor;
	private _previousCursor;
	private _disabled;
    private clickSubject: Subject<Phaser.Input.Pointer> = new Subject();

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		texture: string,
		useOverScaling?: boolean,
		overScale?: number,
		cursor?: string,
		previousCursor?: string
	) {
		super(scene, x, y, texture)

		this.upTexture = texture;
		this.downTexture = texture;
		this.overTexture = texture;
		this.disabledTexture = texture;
		this.useOverScaling = useOverScaling === undefined || useOverScaling === null ? useOverScaleDefault : useOverScaling;
		this.overScale = overScale === undefined || overScale === null ? defaultOverScale : overScale;
		this._cursor = cursor || 'pointer';
		this._previousCursor = previousCursor || 'default';
		this._disabled = false;

		this.setInteractive()
			.on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.handleUp, this)
			.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.handleOut, this)
			.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.handleDown, this)
			.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.handleOver, this);
	}

    destroy(fromScene: boolean = false)
	{
		this.clickSubject.complete()

		super.destroy(fromScene)
	}

	onClick()
	{
		return this.clickSubject.asObservable()
	}

	setUpTexture(texture: string)
	{
		this.upTexture = texture;
		return this;
	}

	setDownTexture(texture: string)
	{
		this.downTexture = texture;
		return this;
	}

	setOverTexture(texture: string)
	{
		this.overTexture = texture;
		return this;
	}

	setDisabledTexture(texture: string)
	{
		this.disabledTexture = texture;
		return this;
	}

	setDisabled(disabled: boolean)
	{
		if (disabled)
		{
			this.setTexture(this.disabledTexture);
			this.disableInteractive();
			this._disabled = disabled;
			return this;
		}

		this.setTexture(this.upTexture);
		this.setInteractive();
		this._disabled = disabled;

		return this;
	}

	setOverScale(scale: number) {
		this.overScale = scale;
		return this;
	}

	setUseOverScaling(useOverGrowth: boolean) {
		this.useOverScaling = useOverGrowth;
		return this;
	}

	private handleUp(pointer: Phaser.Input.Pointer)
	{
		if (this._disabled) return;
		this.handleOver(pointer);
		this.clickSubject.next(pointer);
	}

	private handleOut(pointer: Phaser.Input.Pointer)
	{
		if (this._disabled) return;
		this.scene.input.manager.canvas.style.cursor = this._previousCursor;
		this.handleDown(pointer);
	}

	private handleDown(pointer: Phaser.Input.Pointer)
	{
		if (this._disabled) return;
		if (this.useOverScaling) {
			this.setScale(this.scaleX / this.overScale);
		} else {
			this.setAlpha(1);
			this.clearTint();
		}
	}

	private handleOver(pointer: Phaser.Input.Pointer)
	{
		if (this._disabled) return;
		this.scene.input.manager.canvas.style.cursor = this._cursor;

		if (this.useOverScaling) {
			this.setScale(this.scaleX * this.overScale);
		} else {
			this.setTint(tintColor);
		}
	}
}
