import ApiProxyService from './ApiProxyService';
import util from 'util';
import WalletConnectService from './WalletConnectService';

export default class BattlePassService {
  private static _instance: BattlePassService = new BattlePassService();

  private _objectives: any = [];
  private _daily: any = [];
  private _weekly: any = [];
  private _monthly: any = [];

  constructor() {
    if (BattlePassService._instance) {
      throw new Error('Error: Instantiation failed: Use BattlePassService.getInstance() instead of new.');
    }
    BattlePassService._instance = this;
    this._objectives = [];
    this._daily = [];
    this._weekly = [];
    this._monthly = [];
  }

  public static getInstance(): BattlePassService {
    return BattlePassService._instance;
  }

  public getObjectives(forceUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this._objectives && !forceUpdate) {
        return resolve(this._objectives);
      } else {
        return resolve(this.fetchActiveSeasonObjectives());
      }
    });
  }

  public getObjectivesSync() {
    return this._objectives;
  }

  public getDailyObjectivesSync() {
    return this._daily;
  }

  public getWeeklyObjectivesSync() {
    return this._weekly;
  }

  public getMonthlyObjectivesSync() {
    return this._monthly;
  }

  public fetchActiveSeasonObjectives() {
    ///battle_pass/objectives/active/:user_address
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('battle_pass', ['objectives', 'active', wallet], [])
      .then(res => {
        this._objectives = res.objectives;
        this.splitObjectivesIntoCategories();
        return res.objectives;
      });
  }

  private splitObjectivesIntoCategories() {
    // Clear Lists so no duplicated
    this._daily = [];
    this._weekly = [];
    this._monthly = [];

    // Seperate Objectives into Category
    this._objectives.forEach(objective => {
      switch (objective.expires_after_days) {
        case 1:
          this._daily.push(objective);
          break;
        case 7:
          this._weekly.push(objective);
          break;
        case 30:
          this._monthly.push(objective);
          break;
      }
    });
  }

  public getBattlePassData() {
    ///battle_pass/objectives/active/:user_address
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('battle_pass', ['objectives', 'active', wallet], [])
      .then(res => {
        return res;
      });
  }

  public purchaseBattlePass() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('battle_pass', ['purchase', wallet], {})
      .then(res => {
        return res;
      });
  }

  public getAllSeasonObjectives() {
    ///battle_pass/objectives/all/:user_address/:season?
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('battle_pass', ['objectives', 'all', wallet, '1'], [])
      .then(res => {
        return res;
      });
  }

  public getCurrentBattlePassRewards() {
    ///battle_pass/rewards/:season?
    return ApiProxyService.getInstance()
      .get('battle_pass', ['rewards'], [])
      .then(res => {
        return res;
      });
  }

  public getRemainingBattlePassCount() {
    ///battle_pass/remaining/
    return ApiProxyService.getInstance()
      .get('battle_pass', ['remaining'], [])
      .then(res => {
        return res;
      });
  }

  public getUnclaimedBattlePassRewards() {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .get('battle_pass', ['rewards', 'unclaimed', wallet], [])
      .then(res => {
        return res;
      });
  }

  public claimBattlePassRewards(season: number, level: number) {
    const wallet = WalletConnectService.getInstance().getWalletAddress();
    return ApiProxyService.getInstance()
      .post('battle_pass', ['rewards', 'unclaimed', wallet, 'claim', level, 'season', season], {})
      .then(res => {
        return res;
      });
  }
}
