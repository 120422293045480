import MachineService from "~/services/MachineService"
import { Trait } from "~/services/SquadService"

export type MiningTraits = {
    // Mining Success Variables
    resourceBoost: number
    xpBoost: number
    stardustBoost: number
    spaceElixirBoost: number
    neonGoldBoost: number

    // Mining Gameplay Variables
    dphBoost: number
    dphCommonBoost: number
    dphEpicBoost: number
    miningEfficiencyBoost: number
}

export function getMiningTraits(traitList : Array<Trait>) {
	
	var resourceBoost = 0,
		xpBoost = 0,
		stardustBoost = 0,
		spaceElixirBoost = 0,
		neonGoldBoost = 0,
		dphBoost = 0,
		dphCommonBoost = 0,
		dphEpicBoost = 0,
		miningEfficiencyBoost = 0;

	traitList.forEach(trait => {
		switch(trait.collection) {

			// Genesis Collections
			case 'Star Wolvez Genesis':
				resourceBoost += 0.2
				xpBoost += 0.2
				break;

			// No Homebase Buffs for Galactic Ape Genesis
			case 'GalacticApes Genesis':
				resourceBoost += 0.1
				xpBoost += 0.1
				break;
	
			// Collections
			case 'Star Wolvez Generative':
				switch(trait.trait) {
						// Increase XP an Resource Payout
					case "Cybernetic":
					case "Elder of the Light":
					case "Frozen Magma":
					case "Galaxy":
					case "Glow in the Dark":
					case "Gold Skully":
					case "Gold Spike":
					case "Goldstone":
					case "Shadow Samurai":
					case "Spike":
						resourceBoost += 0.08
						xpBoost += 0.08
						break;
					
					// Increase XP Payout
					case "Relic Hunter Helmet Blue":
					case "Relic Hunter Helmet Brown":
						xpBoost += 0.1
						//xpBoost += 3.4
						break;
		
					// Increase Stardust Payout
					case "Deep Core Armor":
					case "Stardust Hunter Goggles":
						stardustBoost += 0.02
						break;
					case "X Beam 880":
					case "Face Mask White":
					case "Face Mask Blue":
						stardustBoost += 0.03
						break;
					case "Face Mask V2":
						stardustBoost += 0.04
						break;
					
					// Increase Space Elixir Payout
					case "Undergrounder Helmet":
						spaceElixirBoost += 0.05
						break;
					case "Space Hurl":
						spaceElixirBoost += 0.08
						break;
					case "Space Elixir Blob":
						spaceElixirBoost += 0.15
						break;
		
					// Increase Neon Gold Payout
					case "Golden Stash":
						neonGoldBoost += 0.08
						break;
					case "Space Elixir Body":
						neonGoldBoost += 0.12
						break;
					case "Neon Gold Blob":
						neonGoldBoost += 0.15
						break;
		
					// Increase All mining resources
					case "Mine Specialist Goggles":
						stardustBoost += 0.03
						spaceElixirBoost += 0.03
						neonGoldBoost += 0.03
						break
					case "Deep Mine Goggles":
						stardustBoost += 0.04
						spaceElixirBoost += 0.04
						neonGoldBoost += 0.04
						break
		
					// Damage per hour Boost
					case "Deep Search Goggles Blue":
						dphBoost += 0.05
						break
					case "Deep Search Goggles Gold":
						dphBoost += 0.08
						break
					case "Star Wolvez Lightning":
					case "Miner Safety Helmet Mauve":
						dphBoost += 0.1
						break
					case "Miner Safety Helmet Red":
						dphBoost += 0.12
						break
					
					// Damage per hour COMMON Boost
					case "Water Guard Armor":
					case "Hardened Brown":
						dphCommonBoost += 0.05
						break
					case "Hardened Green":
					case "Hardened Red":
					case "Hardened Pink":
					case "Shaggy Earth Fallout":
					case "Grizzled Earth Fallout":
					case "Lupine Earth Fallout":
						dphCommonBoost += 0.07
						break
					case "Hardened Blue":
						dphCommonBoost += 0.09
						break
					case "Hardened Mauve":
					case "Hardened Teal":
						dphCommonBoost += 0.1
						break
					case "Hardened Sage":
					case "Hardened Yellow":
					case "Hardened White":
						dphCommonBoost += 0.12
						break
		
					// Damage per hour EPIC Boost
					case "Grizzled Water Fallout":
					case "Lupine Water Fallout":
					case "Shaggy Water Fallout":
						dphEpicBoost += 0.07
						break
					case "Water Bearer":
					case "Young Elder Green":
					case "Young Elder Sage":
					case "Young Elder Teal":
					case "Young Elder White":
					case "Young Elder Blue":
					case "Young Elder Brown":
					case "Mineral Core Armor":
					case "Young Elder Mauve":
					case "Young Elder Yellow":
					case "Young Elder Red":			
						dphEpicBoost += 0.1
						break
		
					// Damage per hour COMMON & EPIC boost
					case "Gold Body":	
						dphCommonBoost += 0.1		
						dphEpicBoost += 0.1
						break
		
					// Increase mining effifiency per hour
					case "Blob":
						miningEfficiencyBoost += 0.1
						break
				}
				break;

			case 'GalacticApes':
				switch (trait.type) {
					case 'Companion':
						switch(trait.trait) {
							case 'Little King Ape':
								spaceElixirBoost += 0.1
								neonGoldBoost += 0.1
								break
						}
						break;

					case 'Eye Gear':
						switch(trait.trait) {
							case 'Nerd':
								stardustBoost += 0.03
								spaceElixirBoost += 0.03
								neonGoldBoost += 0.03
								break
							case 'Scientist':
								stardustBoost += 0.04
								spaceElixirBoost += 0.04
								neonGoldBoost += 0.04
								break
							case 'Pilot Goggles':
								stardustBoost += 0.05
								spaceElixirBoost += 0.05
								neonGoldBoost += 0.05
								break
							case 'Cyberpunk VR':
								dphBoost += 0.08
								break
							case 'Rainbow VR':
								dphBoost += 0.09
								break
							case 'Scout':
								dphBoost += 0.1
								break
						}
						break;

					case 'Eyes':
						switch(trait.trait) {
							case 'Alien':
								stardustBoost += 0.03
								spaceElixirBoost += 0.03
								neonGoldBoost += 0.03
								break
							case 'Reptile':
								stardustBoost += 0.04
								spaceElixirBoost += 0.04
								neonGoldBoost += 0.04
								break
							case 'Robot':
								stardustBoost += 0.03
								break
						}
						break;

					case 'Head':
						switch(trait.trait) {
							case 'Goggles':
								dphBoost += 0.05
								break
							case 'Crystal':
								dphBoost += 0.06
								break
							case 'Pilot':
								dphBoost += 0.07
								break
							case 'Blob':
								stardustBoost += 0.05
								spaceElixirBoost += 0.05
								neonGoldBoost += 0.05
								break
							case 'Optics':
								dphBoost += 0.09
								break
							case 'Cosmic Cat':
								stardustBoost += 0.06
								spaceElixirBoost += 0.06
								neonGoldBoost += 0.06
								break
							case 'Rainbow Horn':
								stardustBoost += 0.07
								spaceElixirBoost += 0.07
								neonGoldBoost += 0.07
								break
							case 'T-Rex':
								dphBoost += 0.1
								break
						}
						break;

					case 'Mouth':
						switch(trait.trait) {
							case 'Robot':
								stardustBoost += 0.03
								break
							case 'Oni Mask':
								stardustBoost += 0.03
								break
							case 'Galactic Mask':
								stardustBoost += 0.04
								break
							case 'Cyber Oni Mask':
								stardustBoost += 0.04
								break
						}
						break;

					case 'Type':
						switch(trait.trait) {
							case 'Steampunk':
							case 'Rune':
								dphBoost += 0.05
								break
							case 'Slime':
							case 'Explorer':
								dphBoost += 0.06
								break
							case 'Neon':
								dphBoost += 0.07
								break
							case 'Shroom':
							case 'Rainbow':
								dphBoost += 0.08
								break
							case 'Gold':
							case 'Racer':
							case 'Terraform':	
								dphBoost += 0.1
								break
							case 'Intellibot':
								stardustBoost += 0.06
								spaceElixirBoost += 0.06
								neonGoldBoost += 0.06
								break
							case 'Dystopian':
								stardustBoost += 0.07
								spaceElixirBoost += 0.07
								neonGoldBoost += 0.07
								break
						}
						break;
				break
				}
		}
	})

	var traits: MiningTraits = { 
		resourceBoost: resourceBoost,
		xpBoost: xpBoost,
		stardustBoost: stardustBoost,
		spaceElixirBoost: spaceElixirBoost,
		neonGoldBoost: neonGoldBoost,
		dphBoost: dphBoost,
		dphCommonBoost: dphCommonBoost,
		dphEpicBoost: dphEpicBoost,
		miningEfficiencyBoost: miningEfficiencyBoost 
	};

	return traits
}

export function boostDamagePerHour(dph: number, rarity, traits: MiningTraits) {
	var boostPercentage = traits.dphBoost;
	switch (rarity) {
		case "Common":
			boostPercentage += traits.dphCommonBoost
			break
		case "Epic":
			boostPercentage += traits.dphEpicBoost
			break;
	}

	var boostValue = dph * boostPercentage
	return dph + Math.round(boostValue)
}

export function createMiningTraitsDisplayList(traitList: any) {
	var returnList: {trait: string, description: string, id: string}[] = []
	traitList.forEach(trait => {
		var traitObject = miningTraits.find(item => item.collection === trait.collection && item.type == trait.type && item.trait == trait.trait)
		if (traitObject !== undefined) {
			returnList.push({trait: traitObject.trait, description: traitObject.description, id: trait.id})
		}
	})
	return returnList
}

export function applyXPBoost(xp: number, boostValue: number) {
	var returnValue = xp
	if (boostValue > 0) {
		var boost = returnValue * boostValue
		returnValue += Math.round(boost)
		console.log(
			"XP Boost Value: " +
				Math.round(boost) +
				" Total XP for Win: " +
				returnValue,
		)
	}
	return returnValue
}

export type HomebaseTraits = {
    upgradeBuildTime: number
	machineBuildTime: number
	reduceCost: number
}

export function getHomebaseTraits(traitList : Array<Trait>, machineMultiplier: number) {

	var upgradeBuildTime = 0,
        machineBuildTime = 0,
        reduceCost = 0;

	// Traits
	traitList.forEach(trait => {
		switch(trait.collection) {

			// Genesis Collections
			case 'Star Wolvez Genesis':
				upgradeBuildTime += 0.05
				machineBuildTime += 0.05
				reduceCost += 0.05
				break;

			// No Homebase Buffs for Galactic Ape Genesis
			case 'GalacticApes Genesis':
				break;
	
			// Collections
			case 'Star Wolvez Generative':
				switch(trait.trait) {
					// Machine Build and Upgrade Time Improvement
					case "Only Up Goggles Blue":
						upgradeBuildTime += 0.04
						machineBuildTime += 0.04
						break;
					case "AM III Goggles Silver":
						upgradeBuildTime += 0.05
						machineBuildTime += 0.05
						break;
					case "Only Up Frames Yellow":
					case "AM III Goggles Purple":
					case "AM III Goggles Green":
						upgradeBuildTime += 0.06
						machineBuildTime += 0.06
						break;
					case "Binary Clip Goggles":
						upgradeBuildTime += 0.07
						machineBuildTime += 0.07
						break;
					case "Technicolor Goggles":
						upgradeBuildTime += 0.08
						machineBuildTime += 0.08
						break;

					// Upgrade Time Improvement
					case "Dark Elder":
						upgradeBuildTime += 0.04
						break

					// Cost of Building and Upgrade Reduction
					case "Senator Crown":
						reduceCost += 0.05
						break
				}
				break;

			case 'GalacticApes':

				switch (trait.type) {
					case 'Eye Gear':
						switch (trait.trait) {
							case 'LED Glasses':
							case 'Bright Tech':
							case 'Voggles':
								upgradeBuildTime += 0.04
								machineBuildTime += 0.04
								break
							case 'Emergency VR':
								upgradeBuildTime += 0.05
								machineBuildTime += 0.05
								break
							case 'Engineer':
								upgradeBuildTime += 0.06
								machineBuildTime += 0.06
								break
						}
						break
					case 'Eyes':
						switch (trait.trait) {
							case 'One':
								upgradeBuildTime += 0.05
								machineBuildTime += 0.05
								break
						}
						break
					case 'Head':
						switch (trait.trait) {
							case 'Crown':
								upgradeBuildTime += 0.06
								break
							case 'Dark Crown':
								reduceCost += 0.05
								break
							case 'Mecha':
								upgradeBuildTime += 0.08
								break
						}
						break
				}
		}
	})

	// Add Homebase Machine multiplier
	upgradeBuildTime += machineMultiplier
	machineBuildTime += machineMultiplier
	reduceCost += machineMultiplier

	var traits: HomebaseTraits = { 
		upgradeBuildTime: upgradeBuildTime,
		machineBuildTime: machineBuildTime,
		reduceCost: reduceCost,
	};

	return traits
}

export function calculateResourcePaymentReduction(value: number, reductionValue: number) {
	var reductionTotal = ((value / 100) * reductionValue) * 100
	return value - reductionTotal;
}

// Test for GAPE Genesis
export function createPvpTraitsDisplayList(traits: any) {
	var traitsList: {trait: string, description: string, id: string}[] = []
	traits.forEach(trait => {
		var traitObject = pvpTraits.find(item => item.collection === trait.collection && item.type == trait.type && item.trait == trait.trait)
		if (traitObject !== undefined) {
			traitsList.push({trait: traitObject.trait, description: traitObject.description, id: trait.id})
		}
	})
	return traitsList
}

// Refactor this and add Gape Mining Traits
export var miningTraits = [

	// Starwolvez Genesis
	{"collection": "Star Wolvez Genesis", "trait": "Genesis", "type": "Origin", "description": "Increases XP and resource payout for successful mission by 20%."},

	// Starwolvez Generative
		// Background
		{"collection": "Star Wolvez Generative", "trait": "Star Wolvez Lightning", "type": "Background", "description": "10% boost to damage per hour."},

		// Eyes:Headwear:Eyewear
		{"collection": "Star Wolvez Generative", "trait": "Mine Specialist Goggles", "type": "Eyes:Headwear:Eyewear", "description": "+3% boost to all mining resources earned."},
		{"collection": "Star Wolvez Generative", "trait": "Deep Mine Goggles", "type": "Eyes:Headwear:Eyewear", "description": "4% boost to all mining resources earned."},
		{"collection": "Star Wolvez Generative", "trait": "Deep Search Goggles Blue", "type": "Eyes:Headwear:Eyewear", "description": "5% boost to damage per hour."},
		{"collection": "Star Wolvez Generative", "trait": "Stardust Hunter Goggles", "type": "Eyes:Headwear:Eyewear", "description": "2% increase of Stardust per successful mining mission."},
		{"collection": "Star Wolvez Generative", "trait": "Deep Search Goggles Gold", "type": "Eyes:Headwear:Eyewear", "description": "8% boost to damage per hour."},
		{"collection": "Star Wolvez Generative", "trait": "Miner Safety Helmet Mauve", "type": "Eyes:Headwear:Eyewear", "description": "10% boost to damage per hour."},
		{"collection": "Star Wolvez Generative", "trait": "X Beam 880", "type": "Eyes:Headwear:Eyewear", "description": "3% increase of Stardust per successful mining mission."},
		{"collection": "Star Wolvez Generative", "trait": "Miner Safety Helmet Red", "type": "Eyes:Headwear:Eyewear", "description": "12% boost to damage per hour."},
		{"collection": "Star Wolvez Generative", "trait": "Relic Hunter Helmet Blue", "type": "Eyes:Headwear:Eyewear", "description": "10% more XP per successful mining mission."},
		{"collection": "Star Wolvez Generative", "trait": "Undergrounder Helmet", "type": "Eyes:Headwear:Eyewear", "description": "5% increase in Space Elixir per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Relic Hunter Helmet Brown", "type": "Eyes:Headwear:Eyewear", "description": "10% more XP per successful mining mission."},
		{"collection": "Star Wolvez Generative", "trait": "Space Elixir Blob", "type": "Eyes:Headwear:Eyewear", "description": "15% increase in Space Elixir won in all game modes."},
		{"collection": "Star Wolvez Generative", "trait": "Neon Gold Blob", "type": "Eyes:Headwear:Eyewear", "description": "15% increase in Neon Gold won in all game modes."},

		// Mouth
		{"collection": "Star Wolvez Generative", "trait": "Face Mask White", "type": "Mouth", "description": "3% increase in Stardust per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Space Hurl", "type": "Mouth", "description": "8% increase in Space Elixir per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Face Mask Blue", "type": "Mouth", "description": "3% increase in Stardust per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Golden Stash", "type": "Mouth", "description": "8% increase in Neon Gold per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Face Mask V2", "type": "Mouth", "description": "4% increase in Stardust per successful mission."},

		// Head
		{"collection": "Star Wolvez Generative", "trait": "Grizzled Water Fallout", "type": "Head", "description": "7% increase in damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Shaggy Earth Fallout", "type": "Head", "description": "7% increase in damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Lupine Water Fallout", "type": "Head", "description": "7% increase in damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Shaggy Water Fallout", "type": "Head", "description": "7% increase in damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Grizzled Earth Fallout", "type": "Head", "description": "7% increase in damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Water Bearer", "type": "Head", "description": "10% increase in damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Lupine Earth Fallout", "type": "Head", "description": "7% increase in damage per hour on Common mining missions.	"},

		// Body
		{"collection": "Star Wolvez Generative", "trait": "Water Guard Armor", "type": "Body", "description": "5% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Brown", "type": "Body", "description": "5% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Green", "type": "Body", "description": "7% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Red", "type": "Body", "description": "7% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Pink", "type": "Body", "description": "7% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Deep Core Armor", "type": "Body", "description": "2% increase in Stardust per successful mission."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Blue", "type": "Body", "description": "9% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Mauve", "type": "Body", "description": "10% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Teal", "type": "Body", "description": "10% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Sage", "type": "Body", "description": "12% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened Yellow", "type": "Body", "description": "12% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Hardened White", "type": "Body", "description": "12% more damage per hour on Common mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Green", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Sage", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Teal", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder White", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Blue", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Brown", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Mineral Core Armor", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Mauve", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Yellow", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Young Elder Red", "type": "Body", "description": "10% more damage per hour on Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Gold Body", "type": "Body", "description": "10% more damage per hour on Common and Epic mining missions."},
		{"collection": "Star Wolvez Generative", "trait": "Space Elixir Body", "type": "Body", "description": "12% increase in Neon Gold per successful mission."},

		// Special
		{"collection": "Star Wolvez Generative", "trait": "Cybernetic", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Elder of the Light", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Frozen Magma", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Galaxy", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Glow in the Dark", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Gold Skully", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Gold Spike", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Goldstone", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Shadow Samurai", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Spike", "type": "Special", "description": "Increases XP and resource payout for successful mission by 8%."},

	// GalacticApes Genesis
	{"collection": "GalacticApes Genesis", "trait": "Genesis", "type": "", "description": "Increases XP and resource payout for successful mission by 10%."},

	// GalacticApes

		// Companion
		{"collection": "GalacticApes", "trait": "Little King Ape", "type": "Companion", "description": "Increases off-chain resource payout by 10%."},

		// Eye Gear
		{"collection": "GalacticApes", "trait": "Nerd", "type": "Eye Gear", "description": "+3% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Scientist", "type": "Eye Gear", "description": "+4% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Pilot Goggles", "type": "Eye Gear", "description": "+5% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Cyberpunk VR", "type": "Eye Gear", "description": "8% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Rainbow VR", "type": "Eye Gear", "description": "9% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Scout", "type": "Eye Gear", "description": "10% boost to damage per hour."},

		// Eyes
		{"collection": "GalacticApes", "trait": "Alien", "type": "Eyes", "description": "+3% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Reptile", "type": "Eyes", "description": "+4% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Robot", "type": "Eyes", "description": "+5% boost to all mining resources earned."},

		// Head
		{"collection": "GalacticApes", "trait": "Goggles", "type": "Head", "description": "5% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Crystal", "type": "Head", "description": "6% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Pilot", "type": "Head", "description": "7% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Blob", "type": "Head", "description": "+5% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Optics", "type": "Head", "description": "9% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Cosmic Cat", "type": "Head", "description": "+6% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Rainbow Horn", "type": "Head", "description": "+7% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "T-Rex", "type": "Head", "description": "10% boost to damage per hour."},

		// Mouth
		{"collection": "GalacticApes", "trait": "Robot", "type": "Mouth", "description": "3% increase in Stardust per successful mission."},
		{"collection": "GalacticApes", "trait": "Oni Mask", "type": "Mouth", "description": "3% increase in Stardust per successful mission."},
		{"collection": "GalacticApes", "trait": "Galactic Mask", "type": "Mouth", "description": "4% increase in Stardust per successful mission."},
		{"collection": "GalacticApes", "trait": "Cyber Oni Mask", "type": "Mouth", "description": "4% increase in Stardust per successful mission."},

		// Type
		{"collection": "GalacticApes", "trait": "Steampunk", "type": "Type", "description": "5% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Rune", "type": "Type", "description": "5% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Slime", "type": "Type", "description": "6% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Explorer", "type": "Type", "description": "6% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Neon", "type": "Type", "description": "7% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Shroom", "type": "Type", "description": "8% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Rainbow", "type": "Type", "description": "8% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Gold", "type": "Type", "description": "10% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Racer", "type": "Type", "description": "10% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Terraform", "type": "Type", "description": "10% boost to damage per hour."},
		{"collection": "GalacticApes", "trait": "Intellibot", "type": "Type", "description": "+6% boost to all mining resources earned."},
		{"collection": "GalacticApes", "trait": "Dystopian", "type": "Type", "description": "+7% boost to all mining resources earned."},

]

export var pvpTraits = [

	// Starwolvez Genesis
	{"collection": "Star Wolvez Genesis", "trait": "Genesis", "type": "Origin", "description": "Heavy Attack +15. 20% Resource Boost."},

	// Starwolvez Generative

		// Accessories
		{"collection": "Star Wolvez Generative", "trait": "Plasma Rifle Mark I", "type": "Accessories", "description": "Heavy Attack does 8 more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Plasma Rifle Mark II", "type": "Accessories", "description": "Heavy Attack does 10 more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Plasma Rifle Mark Gold", "type": "Accessories", "description": "Heavy Attack does 12 more damage."},

		// Eyes:Headwear:Eyewear
		{"collection": "Star Wolvez Generative", "trait": "Synthwave Goggles", "type": "Eyes:Headwear:Eyewear", "description": "5% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "SW Force Goggles Yellow", "type": "Eyes:Headwear:Eyewear", "description": "+5% chance to stun opponent."},
		{"collection": "Star Wolvez Generative", "trait": "Rogue Runner Glasses", "type": "Eyes:Headwear:Eyewear", "description": "Light attacks deal 8 more hit points."},
		{"collection": "Star Wolvez Generative", "trait": "SW Force Goggles Blue", "type": "Eyes:Headwear:Eyewear", "description": "+5% chance to stun opponent."},
		{"collection": "Star Wolvez Generative", "trait": "Replicant Vision Goggles", "type": "Eyes:Headwear:Eyewear", "description": "12% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "SW Force Goggles White", "type": "Eyes:Headwear:Eyewear", "description": "+6% chance to stun opponent."},
		{"collection": "Star Wolvez Generative", "trait": "AM III V2 Helmet", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 4% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Red Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Blue Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Orange Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Cyclops Yellow", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Pink Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Teal Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Green Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Yellow Eyes", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Cyclops Red", "type": "Eyes:Headwear:Eyewear", "description": "5% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Cyclops Blue", "type": "Eyes:Headwear:Eyewear", "description": "5% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Mohawk Blue", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 7% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Cyclops Galaxy", "type": "Eyes:Headwear:Eyewear", "description": "8% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Round Table Helmet", "type": "Eyes:Headwear:Eyewear", "description": "12% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Spacetime Helmet", "type": "Eyes:Headwear:Eyewear", "description": "15% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Mohawk Pink", "type": "Eyes:Headwear:Eyewear", "description": "Regenerates 10% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Trip Eyes", "type": "Eyes:Headwear:Eyewear", "description": "10% less likely to be stunned."},
		{"collection": "Star Wolvez Generative", "trait": "Laser Beams", "type": "Eyes:Headwear:Eyewear", "description": "Increased chance of critial hit by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Space Elixir Blob", "type": "Eyes:Headwear:Eyewear", "description": "15% increase in Space Elixir won."},
		{"collection": "Star Wolvez Generative", "trait": "Neon Gold Blob", "type": "Eyes:Headwear:Eyewear", "description": "15% increase in Neon Gold won."},
		{"collection": "Star Wolvez Generative", "trait": "Bone Head", "type": "Eyes:Headwear:Eyewear", "description": "Reduces Opponent Heavy Attack by 15%"},

		// Mouth
		{"collection": "Star Wolvez Generative", "trait": "Plasma Blast", "type": "Mouth", "description": "4% more likely to deal a critical hit."},
		
		// Head
		{"collection": "Star Wolvez Generative", "trait": "Gold", "type": "Head", "description": "8% increase in Neon Gold won."},
		{"collection": "Star Wolvez Generative", "trait": "Grizzled Magma", "type": "Head", "description": "Increased chance of critial hit by 5%."},
		{"collection": "Star Wolvez Generative", "trait": "Lupine Magma", "type": "Head", "description": "Increased chance of critial hit by 5%."},
		{"collection": "Star Wolvez Generative", "trait": "Space Elixir", "type": "Head", "description": "8% increase in Space Elixir won."},
		{"collection": "Star Wolvez Generative", "trait": "Skully", "type": "Head", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Shaggy Magma", "type": "Head", "description": "Increased chance of critial hit by 5%."},
		{"collection": "Star Wolvez Generative", "trait": "Sandstone", "type": "Head", "description": "Reduces Opponent Heavy Attack by 10%"},

		// Body
		{"collection": "Star Wolvez Generative", "trait": "Sulfur Runner Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Dark Trooper Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Red Centauri Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Elite Guard Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Ground Guard Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Plasma Recon Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Vanguard Bronze Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 5%"},
		{"collection": "Star Wolvez Generative", "trait": "Council Person Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 7%"},
		{"collection": "Star Wolvez Generative", "trait": "Royal Artillery Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 7%"},
		{"collection": "Star Wolvez Generative", "trait": "Elite Trooper Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 7%"},
		{"collection": "Star Wolvez Generative", "trait": "Space Senator Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Cyberpunk Armor Red", "type": "Body", "description": "Reduces Opponent Heavy Attack by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Vanguard Blue Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 8%."},
		{"collection": "Star Wolvez Generative", "trait": "Oxygen Garden Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 8%"},
		{"collection": "Star Wolvez Generative", "trait": "Medic Armor", "type": "Body", "description": "Regenerates 10% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Blue Centauri Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 10%"},
		{"collection": "Star Wolvez Generative", "trait": "Green Warhead Armor", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Cyberpunk Armor Blue", "type": "Body", "description": "Reduces Opponent Heavy Attack by 10%"},
		{"collection": "Star Wolvez Generative", "trait": "Swole Green", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Morning Watch Armor", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Pink", "type": "Body", "description": "Regenerates 10% HP every 5 rounds."},
		{"collection": "Star Wolvez Generative", "trait": "Carbon Guard Armor", "type": "Body", "description": "Reduces Opponent Heavy Attack by 10%"},
		{"collection": "Star Wolvez Generative", "trait": "Swole Sage", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole White", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Pink Warhead Armor", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Brown", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Blue", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Mauve", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Yellow", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Magma Body", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Red", "type": "Body", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "Star Wolvez Generative", "trait": "Swole Teal", "type": "Body", "description": "Heavy Attacks do 10% more damage."},

		// Special
		{"collection": "Star Wolvez Generative", "trait": "Neon Gold Blob", "type": "Special", "description": "Recovers 10 HP per turn."},
		{"collection": "Star Wolvez Generative", "trait": "Space Elixir Blob", "type": "Special", "description": "Recovers 10 HP per turn."},

	// GalacticApes Genesis
	{"collection": "GalacticApes Genesis", "trait": "Genesis", "type": "", "description": "Heavy Attacks do 7.5% more damage."},

	// GalacticApes

		// Companion
		{"collection": "GalacticApes", "trait": "Little King Ape", "type": "Companion", "description": "Increases off-chain resource payout by 10%."},
		
		// Eye Gear
		{"collection": "GalacticApes", "trait": "Glasses", "type": "Eye Gear", "description": "5% less likely to be stunned."},
		{"collection": "GalacticApes", "trait": "Sunglasses", "type": "Eye Gear", "description": "6% less likely to be stunned."},
		{"collection": "GalacticApes", "trait": "Tribal", "type": "Eye Gear", "description": "+5% chance to stun opponent."},

		// Eyes
		{"collection": "GalacticApes", "trait": "Bored", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Happy", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Curious", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Standard", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Angry", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Closed", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Scarred", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Tattoo", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Damaged", "type": "Eyes", "description": "Regenerates 2% HP every 5 rounds."},
		{"collection": "GalacticApes", "trait": "Thorny", "type": "Eyes", "description": "5% less likely to be stunned."},
		{"collection": "GalacticApes", "trait": "Cute", "type": "Eyes", "description": "5% less likely to be stunned."},
		{"collection": "GalacticApes", "trait": "War Paint", "type": "Eyes", "description": "+5% chance to stun opponent."},
		{"collection": "GalacticApes", "trait": "Four", "type": "Eyes", "description": "10% less likely to be stunned."},
		{"collection": "GalacticApes", "trait": "Laser", "type": "Eyes", "description": "Heavy Attacks do 8% more damage."},

		// Head
		{"collection": "GalacticApes", "trait": "Bald", "type": "Head", "description": "Reduces Opponent Heavy Attack by 9%."},
		{"collection": "GalacticApes", "trait": "Royal Guard", "type": "Head", "description": "Reduces Opponent Heavy Attack by 9%."},
		{"collection": "GalacticApes", "trait": "Beam", "type": "Head", "description": "Heavy Attacks do 5% more damage."},
		{"collection": "GalacticApes", "trait": "Chemical", "type": "Head", "description": "8% increase in Neon Gold won."},
		{"collection": "GalacticApes", "trait": "Wing Band", "type": "Head", "description": "8% increase in Neon Gold won."},
		{"collection": "GalacticApes", "trait": "Creature Tech", "type": "Head", "description": "8% increase in Space Elixir won."},
		{"collection": "GalacticApes", "trait": "Plasma Crown", "type": "Head", "description": "8% increase in Space Elixir won."},
		{"collection": "GalacticApes", "trait": "Crybernetics", "type": "Head", "description": "Reduces Opponent Heavy Attack by 8%."},
		{"collection": "GalacticApes", "trait": "Robo Beast", "type": "Head", "description": "Reduces Opponent Heavy Attack by 9%."},
		{"collection": "GalacticApes", "trait": "Dragon", "type": "Head", "description": "Reduces Opponent Heavy Attack by 10%."},
		{"collection": "GalacticApes", "trait": "Little Ape", "type": "Head", "description": "Reduces Opponent Heavy Attack by 10%."},

		// Mouth
		{"collection": "GalacticApes", "trait": "Laser", "type": "Mouth", "description": "Heavy Attacks do 6% more damage."},

		// Type
		{"collection": "GalacticApes", "trait": "Scrapper", "type": "Type", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "GalacticApes", "trait": "Spark", "type": "Type", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "GalacticApes", "trait": "Stone Guardian", "type": "Type", "description": "Reduces Opponent Heavy Attack by 10%."},
		{"collection": "GalacticApes", "trait": "Thorny", "type": "Type", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "GalacticApes", "trait": "Battleworn", "type": "Type", "description": "Heavy Attacks do 5% more damage."},
		{"collection": "GalacticApes", "trait": "Brawler", "type": "Type", "description": "Heavy Attacks do 7% more damage."},
		{"collection": "GalacticApes", "trait": "Specialist", "type": "Type", "description": "Reduces Opponent Heavy Attack by 10%."},
		{"collection": "GalacticApes", "trait": "Magma", "type": "Type", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "GalacticApes", "trait": "Punk", "type": "Type", "description": "Heavy Attacks do 10% more damage."},
		{"collection": "GalacticApes", "trait": "Guardian", "type": "Type", "description": "Reduces Opponent Heavy Attack by 10%."},
		{"collection": "GalacticApes", "trait": "Cyberpunk", "type": "Type", "description": "Heavy Attacks do 7% more damage."},
		{"collection": "GalacticApes", "trait": "Zombie", "type": "Type", "description": "Heavy Attacks do 8% more damage."},
		{"collection": "GalacticApes", "trait": "Ranger", "type": "Type", "description": "Reduces Opponent Heavy Attack by 10%."},
		{"collection": "GalacticApes", "trait": "Gedi", "type": "Type", "description": "Reduces Opponent Heavy Attack by 10%."},
]

// Not used to display
export var homebaseTraits = [
	{"trait": "Only Up Goggles Blue", "description": "Improves machine build and upgrade time by 4%."},
	{"trait": "AM III Goggles Silver", "description": "Improves machine build and upgrade time by 5%."},
	{"trait": "Only Up Frames Yellow", "description": "Improves machine build and upgrade time by 6%."},
	{"trait": "AM III Goggles Purple", "description": "Improves machine build and upgrade time by 6%."},
	{"trait": "AM III Goggles Green", "description": "Improves machine build and upgrade time by 6%."},
	{"trait": "Binary Clip Goggles", "description": "Improves machine build and upgrade time by 7%."},
	{"trait": "Senator Crown", "description": "Cost of building and upgrading decreased by 5%."},
	{"trait": "Technicolor Goggles", "description": "Improves machine build and upgrade time by 8%."},
	{"trait": "Dark Elder", "description": "Improves upgrade times on Machines by 6%."}
]