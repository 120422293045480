import Phaser from 'phaser';
import Button from '../../../components/buttons/Button';
import SquadContentPane from '~/scenes/modals/squad_modal/squadContentPane';
import ScaleService from '~/services/ScaleService';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components';
import NFTService from '~/services/NFTService';
import SquadService from '~/services/SquadService';
import util from 'util';
import CollectionService from '~/services/CollectionService';
import LoadingIndicator from '~/components/loading/loadingIndicator';
import { Shared_Assets, Squad_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 803;
const modalWidth = 814;
const modalRadius = 25;
const modalColor = 0x1c1c2c;
const modalAlpha = 1;

const spineWidth = 248;
const spineColor = 0xe6e6ff;
const spineAlpha = 0.05;

export default class SquadModalScene extends Phaser.Scene {
  private _confirmButton;
  private _squadContentPane;
  private _scale;
  private _collections;
  private _spineTable;
  private _nfts;
  private _squad;
  private _sizer;
  private _loadingIndicator;
  private _selectedCollection;
  private _totalNftCount;

  constructor() {
    super({
      key: 'SquadModalScene',
    });
  }

  preload() {
    LoadImageAssets(this, Squad_Assets);
  }

  create() {
    this.createComponent();

    NFTService.getInstance()
      .getNFTs(undefined, undefined, 0, 30)
      .then(res => {
        this._nfts = res.nfts;
        this._totalNftCount = res.max_count_returned;
        this.scene.launch('NftLoaderScene', { originating_scene: 'SquadModalScene', nfts: res.nfts });
      });
  }

  createComponent() {
    this.input.topOnly = false;
    this.input.setDefaultCursor('default');

    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight);
    this._collections = CollectionService.getInstance().getCollectionsWithAll();
    this._squad = SquadService.getInstance().getSquadSync();

    this._sizer = this.rexUI.add.overlapSizer(this.sys.canvas.width / 2, this.sys.canvas.height / 2, {
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
      sizerEvents: true,
    });

    // Background Shade
    const shade = this.add.rectangle(0, 0, this.sys.canvas.width, this.sys.canvas.height, 0x1c1c1c);
    shade.setOrigin(0);
    shade.setAlpha(0.5);

    // Modal Background
    const background = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );

    const spineBackground = this.add.rexRoundRectangle(
      0, // x
      0, // y
      this.applyScale(spineWidth), // width
      this.applyScale(modalHeight), // height
      {
        tl: this.applyScale(modalRadius),
        tr: 0,
        bl: this.applyScale(modalRadius),
        br: 0,
      }, // radius
      spineColor, // fillColor
      spineAlpha, // alpha
    );

    // Title text
    const titleText = this.add.image(0, 0, 'squad_text').setOrigin(0).setScale(this._scale);

    // Add spine here
    this._spineTable = this.createSpineTable(this, this._collections, 0, 0).setOrigin(0.5, 0.5);

    this._confirmButton = new Button(this.scene.scene, 0, 0, 'squad_confirm_button', true, 1.04).setScale(this._scale);
    this.add.existing(this._confirmButton);

    this._confirmButton.onClick().subscribe(pointer => {
      this.confirmButtonClickHandler(pointer);
    });

    this._selectedCollection = this._collections[0];

    this._sizer.add(background, {
      key: 'background',
      align: 'center',
      offsetX: 0,
      offsetY: 0,
      expand: false,
    });
    this._sizer.add(spineBackground, {
      key: 'spineBackground',
      align: 'center',
      offsetX: this.applyScale(-(modalWidth / 2 - spineWidth / 2)),
      offsetY: 0,
      expand: false,
    });
    this._sizer.add(titleText, {
      key: 'titleText',
      align: 'left-top',
      offsetX: this.applyScale(17),
      offsetY: this.applyScale(31),
      expand: false,
    });
    this._sizer.add(this._spineTable, {
      key: 'table',
      align: 'left-top',
      offsetX: this.applyScale(15),
      offsetY: this.applyScale(60),
      expand: false,
    });
    this._sizer.add(this._confirmButton, {
      key: 'confirmButton',
      align: 'left-bottom',
      offsetX: this.applyScale(14),
      offsetY: this.applyScale(-18),
      expand: false,
    });

    this._loadingIndicator = new LoadingIndicator(this, 0, 0, 0.8 * this._scale);

    this._sizer.add(this._loadingIndicator, {
      key: 'loading_indicator',
      align: 'center-top',
      offsetX: this.applyScale(100),
      offsetY: this.applyScale(300),
      expand: false,
    });

    this._sizer.layout();

    this._sizer.onClickOutside(() => {
      this.unloadAssets();
      this.scene.resume('HomebaseScene');
      this.scene.resume('HudScene');
      this.scene.stop();
    });
  }

  onLoaded() {
    this._loadingIndicator.setAlpha(0);
    this._sizer.remove(this._loadingIndicator);
    this._loadingIndicator = undefined;

    this._squadContentPane = new SquadContentPane(
      this,
      0,
      0,
      { nfts: this._nfts, totalNftCount: this._totalNftCount, selectedCollection: this._selectedCollection },
      this._scale,
    );
    this.add.existing(this._squadContentPane);

    this._sizer.add(this._squadContentPane, {
      key: 'squad-content-pane',
      align: 'left-top',
      offsetX: this.applyScale(262),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this._sizer.layout();

    this.setIsSelected(this._selectedCollection);
  }

  applyScale(length) {
    return length * this._scale;
  }

  getScale() {
    return this._scale;
  }

  updatePage(pageNum: number) {
    this._squadContentPane.updatePage(pageNum);
  }

  onPageLoaded() {
    this._squadContentPane.onPageLoaded();
  }

  addSquadMember(squadMember) {
    this._squadContentPane.addSquadMember(this, squadMember, this.getScale());
  }

  removeSquadMember(index) {
    this._squadContentPane.removeSquadMember(this, index, this.getScale());
  }

  destroy() {
    this._collections = undefined;
  }

  private createSpineTable(scene, data, x, y) {
    var table = scene.rexUI.add.gridTable({
      x: x,
      y: y,
      width: this.applyScale(220),
      height: this.applyScale(643),
      items: data,
      scrollMode: 0,

      table: {
        cellWidth: this.applyScale(220),
        cellHeight: this.applyScale(32),
        columns: 1,
        clamplTableOXY: false,
        reuseCellContainer: true,
        interactive: true,
        mask: {
          padding: 0,
        },
      },

      mouseWheelScroller: {
        focus: true,
        speed: 0.2,
      },

      createCellContainerCallback: function (cell) {
        var scene = cell.scene,
          width = cell.width,
          height = cell.height,
          item = cell.item,
          index = cell.index;

        // Template
        let rectConfig = {
          maskType: 'roundRectangle',
          radius: 0,
        };

        var sizer = scene.rexUI.add
          .overlapSizer({
            width: scene.applyScale(220),
            space: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              item: 0,
              line: 0,
            },
            align: 0,
            sizerEvents: true,
          })
          .setInteractive({ useHandCursor: true })
          .add(
            scene.add
              .rexRoundRectangle(
                0, // x
                0, // y
                scene.applyScale(220), // width
                scene.applyScale(32), // height
                scene.applyScale(6), // radius
                0xe6e6ff, // fillColor
                0.05, // alpha
              )
              .setAlpha(item.isSelected ? 1 : 0),
            {
              key: 'menu_option_bg',
              align: 'center',
              offsetX: 0,
              offsetY: 0,
              expand: false,
            },
          )
          .add(scene.add.bitmapText(0, 0, 'cc_outline', item.displayName, scene.applyScale(16), 0).setAlpha(item.isSelected ? 1 : 0.4), {
            key: 'menu_option_text',
            align: 'left',
            offsetX: scene.applyScale(10),
            offsetY: 0,
            expand: false,
          });

        sizer.setChildrenInteractive({
          click: { mode: 'release', clickInterval: 100 },
          over: true,
          tap: { time: 250, tapInterval: 200, threshold: 9, tapOffset: 10, taps: undefined, minTaps: undefined, maxTaps: undefined },
          press: { time: 251, threshold: 9 },
          swipe: { threshold: 10, velocityThreshold: 1000, dir: '8dir' },
          inputEventPrefix: 'child.',
        });

        sizer.on('child.over', (child, pointer, event) => {
          sizer.getElement('menu_option_text').setAlpha(1);
        });

        sizer.on('child.out', (child, pointer, event) => {
          if (!item.isSelected) {
            sizer.getElement('menu_option_text').setAlpha(0.4);
          }
        });

        sizer.on('child.click', (child, pointer, event) => {
          scene.setSelectedCollection(item);
          scene.setIsSelected(item);
        });

        return sizer;
      },
    });

    table.setItems(data).scrollToTop();
    return table;
  }

  setIsSelected(item: any) {
    if (item.isSelected === true) return;

    this._collections = this._collections.map(collection => {
      collection.isSelected = collection.displayName === item.displayName ? true : false;
      return collection;
    });

    this._spineTable.setItems(this._collections);
    this._squadContentPane.setCollection(this._selectedCollection);
  }

  setSelectedCollection(selectedCollection) {
    if (selectedCollection) {
      this._selectedCollection = selectedCollection;
    }
  }

  private confirmButtonClickHandler(this, pointer) {
    const currentSquad = this._squadContentPane.getCurrentSquad();
    const currentSquadIds = currentSquad
      .slice(0, 3)
      .filter(member => member.id !== 0)
      .map(nft => {
        return { nft_id: nft.id };
      });

    SquadService.getInstance()
      .saveSquad({ squad: currentSquadIds })
      .then(res => {
        this.unloadAssets();
        this.scene.resume('HomebaseScene');
        this.scene.resume('HudScene', {
          squadUpdated: true,
        });
        this.scene.stop();
      });
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Squad_Assets));

    //Don't unload squad images, unload all others
    const squadImages = SquadService.getInstance()
      .getSquadSync()
      .map(sm => sm.marketplace_nft_id);
    UnloadImages(
      this,
      this._nfts.filter(nf => !squadImages.includes(nf.marketplace_nft_id)).map(nft => nft.image_key),
    );
  }
}
