import Phaser from 'phaser';
import Button from '~/components/buttons/Button';
import RewardLabel from '~/components/labels/rewardLabel';
import BaseRaidService from '~/services/BaseRaidService';
import ScaleService from '~/services/ScaleService';
import UserService from '~/services/UserService';
import { Base_Raid_Result_Scene_Assets } from '~/utils/AssetLoader';
import { LoadImageAssets, UnloadImages } from '~/utils/AssetManager';

const modalHeight = 400;
const modalWidth = 494;
const modalRadius = 50;
const modalColor = 0x350926;
const modalAlpha = 1;
const modalBorderColor = 0x3d4361;
const modalBorderWidth = 1;

const okayButtonImageKey = 'okay_button';
const bannerImageKey = 'raid_result_banner';
const victoryTextImageKey = 'thank_heavens_text';
const lossTextImageKey = 'oh_no_text';

const defendVictoryMessage = 'While you were gone, someone raided you, but they were unsuccessful.\n\nWell done, commander.';
const defendLossMessage = 'While you were gone, someone raided you.\n\nYou lost the following resources:';

const attackVictoryMessage = 'Your raid was successful.  Well done, commander.\n\nYou gained the following resources:';
const attackLossMessage = 'Your raid was unsuccessful.\n\nYou lost the following resources:';

export default class BaseRaidResultScene extends Phaser.Scene {
  private _result;
  private _isAttacker;
  private _isVictory;
  private _scale;

  init(data) {
    this._result = data;
  }

  constructor() {
    super({
      key: 'BaseRaidResultScene',
    });
  }

  preload() {
    LoadImageAssets(this, Base_Raid_Result_Scene_Assets);
  }

  create() {
    this._scale = ScaleService.getInstance().getScale(modalWidth, modalHeight + 100); // 100 extra to account for button
    this._isVictory = this._result.isVictory;
    this._isAttacker = this._result.isAttacker;

    // Add Background
    const modalBg = this.add.rexRoundRectangle(
      this.sys.canvas.width / 2, // x
      this.sys.canvas.height / 2, // y
      this.applyScale(modalWidth), // width
      this.applyScale(modalHeight), // height
      this.applyScale(modalRadius), // radius
      modalColor, // fillColor
      modalAlpha, // alpha
    );
    modalBg.setStrokeStyle(this.applyScale(modalBorderWidth), modalBorderColor, modalAlpha);

    // Add Sizer for content
    var sizer = this.rexUI.add.fixWidthSizer({
      x: this.sys.canvas.width / 2,
      y: this.sys.canvas.height / 2,
      width: this.applyScale(modalWidth),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: this.applyScale(60),
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add background to sizer
    sizer.addBackground(modalBg);

    // Add banner
    sizer.add(this.add.image(0, 0, bannerImageKey).setScale(this._scale), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'modal_banner',
    });

    // Add Sizer for centered content
    var contentSizer = this.rexUI.add.fixWidthSizer({
      x: 0,
      y: 0,
      width: this.applyScale(325),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 2,
    });

    // Add Title Text image
    contentSizer.add(this.add.image(0, 0, this.getTitleTextImageKey()), {
      padding: { left: 0, right: 0, top: this.applyScale(this.getTitleTextVerticalOffset()), bottom: 0 },
      key: 'title_text_image',
    });

    // Add Message Text
    contentSizer.add(this.add.bitmapText(0, 0, 'cc_outline', this.getMessageText(), this.applyScale(24), 1).setMaxWidth(this.applyScale(325)), {
      padding: { left: 0, right: 0, top: this.applyScale(23), bottom: 0 },
      key: 'message_text',
    });

    if (this._isVictory) {
      // Add Resource Labels
      if (this._result.space_elixir && this._result.space_elixir > 0) {
        const space_elixir_label = new RewardLabel(this, 0, 0, this._scale, 'space_elixir', this._result.space_elixir.toLocaleString('en-us') || '0');
        contentSizer.add(this.add.existing(space_elixir_label), {
          padding: { left: 0, right: 0, top: this.applyScale(40), bottom: 0 },
          key: 'space_elixir_label',
        });
      }

      if (this._result.neon_gold && this._result.neon_gold > 0) {
        const neon_gold_label = new RewardLabel(this, 0, 0, this._scale, 'neon_gold', this._result.neon_gold.toLocaleString('en-us') || '0');
        contentSizer.add(this.add.existing(neon_gold_label), {
          padding: { left: 0, right: 0, top: this.applyScale(18), bottom: 0 },
          key: 'neon_gold_label',
        });
      }

      if (this._result.stardust && this._result.stardust > 0) {
        const stardust_label = new RewardLabel(this, 0, 0, this._scale, 'stardust', this._result.stardust.toLocaleString('en-us') || '0');
        contentSizer.add(this.add.existing(stardust_label), {
          padding: { left: 0, right: 0, top: this.applyScale(18), bottom: 0 },
          key: 'stardust_label',
        });
      }
    }

    if (this._result.xp && this._result.xp !== 0) {
      const xp_label = new RewardLabel(this, 0, 0, this._scale, 'xp', `${this._result.xp.toLocaleString('en-us')}xp` || '0xp');
      contentSizer.add(this.add.existing(xp_label), {
        padding: { left: 0, right: 0, top: this.applyScale(18), bottom: 0 },
        key: 'xp_label',
      });
    }

    sizer.add(contentSizer.layout(), {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      key: 'content_size',
    });

    sizer.layout();

    // Add Okay Button
    const okayButton = new Button(this, this.sys.canvas.width / 2, modalBg.getBottomLeft().y, okayButtonImageKey, false).setScale(this._scale);
    okayButton.onClick().subscribe(pointer => {
      this.okayButtonClickHandler();
    });
    this.add.existing(okayButton);

    // Add key bindings
    this.input.keyboard.on('keydown-ENTER', event => {
      this.okayButtonClickHandler();
    });
  }

  okayButtonClickHandler() {
    BaseRaidService.getInstance()
      .viewAttack(true)
      .then(attackRes => {
        if (this._isAttacker) {
          // Close modal, go to HomebaseScene
          UserService.getInstance()
            .getUserData(true)
            .then(res => {
              this.scene.start('HomebaseScene');
              this.scene.stop();
              this.unloadAssets();
            });
        } else {
          // Close modal, resume homebase and hud
          UserService.getInstance()
            .getUserData(true)
            .then(res => {
              this.scene.resume('HomebaseScene');
              this.scene.resume('HudScene');
              this.scene.stop();
              this.unloadAssets();
            });
        }
      });
  }

  private getTitleTextImageKey() {
    return this._isVictory ? victoryTextImageKey : lossTextImageKey;
  }

  private getTitleTextVerticalOffset() {
    return this._isVictory ? -38 : -26; // Half the height of the image, negative
  }

  private getMessageText() {
    return this._isAttacker
      ? this._isVictory
        ? attackVictoryMessage
        : attackLossMessage
      : this._isVictory
      ? defendVictoryMessage
      : defendLossMessage;
  }

  private applyScale(length) {
    return length * this._scale;
  }

  unloadAssets() {
    UnloadImages(this, Object.keys(Base_Raid_Result_Scene_Assets));
  }
}
