import Phaser from 'phaser';
import { OverlapSizer } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import BattlePassService from '~/services/BattlePassService';

const paneHeight = 90;
const refillTextColor = 0xa3a3b9;

export default class ObjectivesHeaderContentPane extends OverlapSizer {
  private _title;
  private _countdownTimer;
  private _paneWidth: number;
  private _scale: number;
  private _objectiveType: string;

  constructor(scene: Phaser.Scene, x: number, y: number, paneScale: number, objectiveType: string, paneWidth: number, config?: any) {
    let conf = config
      ? config
      : {
          width: paneWidth * paneScale,
          space: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            item: 0,
            line: 0,
          },
          align: 0,
          sizerEvents: true,
        };

    super(scene, x, y, conf);

    this._scale = paneScale;
    this._objectiveType = objectiveType;
    this._paneWidth = paneWidth;

    this.createComponent(scene, x, y);
  }

  createComponent(scene: Phaser.Scene, x: number, y: number) {
    // Sizer
    var sizerPanel = scene.rexUI.add.overlapSizer({
      width: this.applyScale(this._paneWidth),
      height: this.applyScale(paneHeight),
      space: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        item: 0,
        line: 0,
      },
      align: 0,
    });

    this._title = scene.add.bitmapText(0, 0, 'cc_outline', this.getHeaderTitle(), this.applyScale(22), 1);

    sizerPanel.add(this._title, {
      key: 'title-text',
      align: 'left-top',
      offsetX: this.applyScale(16),
      offsetY: this.applyScale(0),
      expand: false,
    });

    // Claim Countdown Timer
    if (this._objectiveType.includes('battle_pass')) {
      this._countdownTimer = scene.add
        .bitmapText(0, 0, 'cc_outline', 'Quests refresh in 0h 0m', this.applyScale(16), 1)
        .setOrigin(1, 0.5)
        .setTint(refillTextColor);

      sizerPanel.add(this._countdownTimer, {
        key: 'countdown-timer-text',
        align: 'right-top',
        offsetX: this.applyScale(-16),
        offsetY: this.applyScale(6),
        expand: false,
      });
    }

    this.add(sizerPanel, {
      key: 'sizer-panel',
      align: 'left-top',
      offsetX: this.applyScale(0),
      offsetY: this.applyScale(0),
      expand: false,
    });

    this.layout();

    this.updateTimeHandler();

    // scene.time.addEvent({
    //   delay: 15000, //1 minute
    //   loop: true,
    //   callback: () => {
    //     this.updateTimeHandler();
    //   },
    // });
  }

  applyScale(length) {
    return this.scene.applyScale(length);
  }

  private updateTimeHandler() {
    if (this._objectiveType.includes('battle_pass')) {
      var timerString = '';
      if (this._objectiveType === 'battle_pass_daily') {
        timerString = this.calculateTimeDifference(this.getNextDayString());
      } else if (this._objectiveType === 'battle_pass_weekly') {
        timerString = this.calculateTimeDifference(BattlePassService.getInstance().getWeeklyObjectivesSync()[0].week_end_date);
      } else {
        timerString = this.calculateTimeDifference(BattlePassService.getInstance().getMonthlyObjectivesSync()[0].month_end_date);
      }
      this._countdownTimer.setText(timerString);
    }
  }

  private getHeaderTitle() {
    switch (this._objectiveType) {
      case 'quick_quests':
        return 'Quick Quests';
      case 'partner_quests':
        return 'Partner Quests';
      case 'milestones':
        return 'Milestones';
      case 'battle_pass_daily':
        return 'Battle Pass Daily Quests';
      case 'battle_pass_weekly':
        return 'Battle Pass Weekly Quests';
      case 'battle_pass_monthly':
        return 'Battle Pass Monthly Quests';
    }
  }

  getNextDayString() {
    const nextDay = new Date();
    nextDay.setUTCHours(0, 0, 0, 0);
    nextDay.setUTCDate(nextDay.getUTCDate() + 1);

    const year = nextDay.getUTCFullYear();
    const month = String(nextDay.getUTCMonth() + 1).padStart(2, '0');
    const day = String(nextDay.getUTCDate()).padStart(2, '0');
    const hours = '00';
    const minutes = '00';
    const seconds = '00';

    const nextDayString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return nextDayString;
  }

  calculateTimeDifference(endTimeString: string) {
    const endTime = new Date(endTimeString + ' UTC');
    const currentTime = new Date();
    const timeDifference = endTime.getTime() - currentTime.getTime();

    // Calculate days, hours, and minutes
    const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return `Quests refresh in ${daysRemaining}d ${hoursRemaining}h ${minutesRemaining}m`;
  }
}

Phaser.GameObjects.GameObjectFactory.register('objectivesHeaderContentPane', function (x: number, y: number) {
  // @ts-ignore
  return this.displayList.add(new ObjectivesHeaderContentPane(this.scene, x, y));
});
