import BaseRaidMachine from '~/components/machines/baseRaidMachine';
import HomebaseScene from '~/scenes/homebase/homebaseScene';
import TroopService from '~/services/TroopService';

const cellWidth = 64;

export default class BaseRaidDetailsScene extends HomebaseScene {
  private _base!: Base;

  constructor() {
    super({
      key: 'BaseRaidDetailsScene',
    });
  }

  preload() {}

  init(base: Base) {
    this._base = base;
  }

  create() {
    this.input.mouse.disableContextMenu();

    // Set Background
    document.body.style.backgroundImage = 'none';

    this._map = this.make.tilemap({ key: 'homebase_map' });
    this._tileset = this._map.addTilesetImage('homebase_tileset_64', 'tiles', 64, 64, 1, 2);

    this._blobLayer = this._map.createLayer('Blob Layer', this._tileset);
    this._blobLayer.setAlpha(0.5);
    this._baseLayer = this._map.createLayer('Tile Layer 1', this._tileset);

    this._userMachines = this._base.defender_machines;

    this.createMachines(false);

    this.cameras.main.setBounds(-200, 440, this._map.widthInPixels + 400, this._map.heightInPixels - 200);

    // Handle keyboard arrows
    this.cursors = this.input.keyboard.createCursorKeys();

    // Handle homebase drag
    this.input.on(
      'pointermove',
      function (p) {
        if (!p.isDown || this._isDragging) return;

        this.cameras.main.scrollX -= (p.x - p.prevPosition.x) / this.cameras.main.zoom;
        this.cameras.main.scrollY -= (p.y - p.prevPosition.y) / this.cameras.main.zoom;
      },
      this,
    );

    // Handle scroll/zoom
    this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
      if (deltaY > 0) {
        var newZoom = this.cameras.main.zoom - 0.1;
        if (newZoom > 0.6) {
          this.cameras.main.setZoom(newZoom);
        }
      }

      if (deltaY < 0) {
        var newZoom = this.cameras.main.zoom + 0.1;
        if (newZoom < 1.6) {
          this.cameras.main.setZoom(newZoom);
        }
      }

      // this.cameras.main.centerOn(pointer.worldX, pointer.worldY);
      // this.cameras.main.pan(pointer.worldX, pointer.worldY, 2000, "Power2");
    });

    // Display HUD
    TroopService.getInstance()
      .getArmy()
      .then(res => {
        this.launchScene('BaseRaidHudScene', {
          base_raid_view_info: this._base,
          is_attacking: this._base.is_attacking,
        });
      });
  }

  launchScene(scene, data) {
    this.scene.launch(scene, data);
    this.gameScene = this.scene.get(scene);
  }

  addMachine(machine: any, cellX: number, cellY: number) {
    const widthInTiles = this.getWidthInTiles(machine);

    var newMachine = new BaseRaidMachine(this, cellX, cellY, machine, 1, widthInTiles);

    if (!this._machineObjects) this._machineObjects = [];
    this._machineObjects.push(newMachine);

    //this.add.existing(newMachine);

    // newMachine.x = cellWidth * cellX + (cellWidth * widthInTiles) / 2
    // newMachine.y = cellWidth * cellY + cellWidth
  }

  applyScale(length) {
    return length;
  }

  updateMachines() {
    // Add image keys to machine data, add new machine objects to UI and object list
    this._userMachines.forEach(mach => {
      mach.imageKey = `machines_${mach.key}`;
      this.addMachine(mach, mach.x, mach.y);
    });
  }

  disableDestroyedMachines(baseRaidViewInfo: any) {
    var remainingDefenderMachineIds: any = [];

    baseRaidViewInfo.base_raid_execution[0].defender_machines.forEach(dm => {
      remainingDefenderMachineIds.push(dm.user_machine_id);
    });

    const destroyedMachines = this._machineObjects.filter(mo => !remainingDefenderMachineIds.includes(mo.getMachine().user_machine_id));

    destroyedMachines.forEach(dm => {
      dm.setDestroyed(true);
    });
  }
}
